import { Action, Inject } from '@nori/di'
import { isErr } from '@nori/result'
import { createLogger } from '~/infra/create-logger'
import { SkuService } from '~/modules/sku'
import { SubscriptionService } from '~/modules/subscription'

import { ContactSubscriptionStore } from '../store/contact-subscription.store'

import type { ContactEntity } from '~/modules/contacts/core'

const logger = createLogger('contact-subscription.action')

@Action()
export class ContactSubscriptionAction {
  constructor(
    @Inject()
    private readonly subscriptionService: SubscriptionService,
    @Inject() private readonly skuService: SkuService,
    @Inject()
    private readonly contactSubscriptionStore: ContactSubscriptionStore
  ) {}

  async handleLoadNextOrder({
    customerId,
    isPreferredDsr,
  }: Pick<ContactEntity, 'customerId' | 'isPreferredDsr'>): Promise<void> {
    if (!customerId || !isPreferredDsr) return

    this.contactSubscriptionStore.setIsLoading(customerId, true)
    this.contactSubscriptionStore.setError(customerId, undefined)

    await this.loadNextOrder(customerId)

    this.contactSubscriptionStore.setIsLoading(customerId, false)
  }

  private async loadNextOrder(id: number): Promise<void> {
    const result = await this.subscriptionService.getNextOrder(id)

    if (isErr(result)) {
      logger.error(result.error.key, result.error)
      this.contactSubscriptionStore.setError(id, result.error)
      return
    }

    this.contactSubscriptionStore.setNextOrder(id, result.data)

    await this.loadSubscriptionSkus(id, [
      ...new Set(result.data.subscriptions.map((s) => s.code)),
    ])
  }

  private async loadSubscriptionSkus(
    orderId: number,
    codes: string[]
  ): Promise<void> {
    const result = await this.skuService.getSkus(codes)

    if (isErr(result)) {
      logger.error(result.error.key, result.error)
      this.contactSubscriptionStore.setError(orderId, result.error)
      return
    }

    this.contactSubscriptionStore.setSkus(orderId, result.data)
  }
}
