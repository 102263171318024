import type { UpdateNotePayloadValueObject } from '../../core/update-note.value-object'

export class UpdateNoteRequestMapper {
  static toInput(data: UpdateNotePayloadValueObject): any {
    return {
      id: data.id,
      content: data.content,
      created_at: data.createdAt,
      updated_at: data.updatedAt,
    }
  }
}
