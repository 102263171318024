import { Service } from '@nori/di'

import { OrderErrors } from '../../core/order.errors'

import type { OrderErrorInstance } from '../../core/order.errors'

@Service()
export class OrderErrorMapper {
  toGetOrderError(status: number, data: any): OrderErrorInstance {
    switch (status) {
      case 400:
        return new OrderErrors.GetOrderBadRequest(data.error.message)

      default:
        return new OrderErrors.GetOrderUnknownError(data.error.message)
    }
  }

  toCancelOrderError(status: number, data: any): OrderErrorInstance {
    switch (status) {
      case 400:
        return new OrderErrors.CancelOrderBadRequest(data.error.message)

      default:
        return new OrderErrors.CancelOrderUnknownError(data.error.message)
    }
  }

  toGetPartyOrdersRetailSalesError(
    status: number,
    data: any
  ): OrderErrorInstance {
    switch (status) {
      case 400:
        return new OrderErrors.GetPartyOrdersRetailSalesBadRequest(
          data.error.message
        )

      default:
        return new OrderErrors.GetPartyOrdersRetailSalesUnknownError(
          data.error.message
        )
    }
  }
}
