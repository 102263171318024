import {
  BraintreeService,
  PaymentMethodService,
  UserStore,
} from '@nori/app-kit'
import { Action, Inject } from '@nori/di'
import { getBlock } from '@nori/lang-i18n'
import { NotifyAction } from '@nori/notify'
import { isErr } from '@nori/result'
import { CreatePaymentMethodService } from '~/modules/payment-methods/interface/service/create-payment-method.service'

import { ConsultantPaymentMethodStore } from '../store/consultant-payment-method.store'

import type { PaymentMethodValueObject } from '@nori/app-kit'

@Action()
export class PaymentMethodAction {
  constructor(
    @Inject()
    private readonly paymentMethodStore: ConsultantPaymentMethodStore,
    @Inject() private readonly paymentMethodService: PaymentMethodService,
    @Inject() private readonly braintreeService: BraintreeService,
    @Inject()
    private readonly createPaymentMethodService: CreatePaymentMethodService,
    @Inject() private readonly notifyAction: NotifyAction,
    @Inject() private readonly userStore: UserStore
  ) {}

  get fieldsHandlers() {
    const { creditCardHolderValidator, billingAddressValidator } =
      this.paymentMethodStore

    return {
      creditCardValidator: creditCardHolderValidator.handlers,
      billingAddressValidator: billingAddressValidator.handlers,
    }
  }

  async handleAddPayPal(): Promise<void> {
    this.paymentMethodStore.payPalToken.setIsLoading(true)

    await this.addPayPal()

    this.paymentMethodStore.payPalToken.setIsLoading(false)
  }

  async handleInitialLoad(): Promise<void> {
    this.paymentMethodStore.setIsLoading(true)

    await this.initialLoad()

    this.paymentMethodStore.setIsLoading(false)
  }

  handleSelectNewPaymentMethod(paymentMethod: PaymentMethodValueObject): void {
    this.paymentMethodStore.setSelectedNewPaymentMethod(paymentMethod)
  }

  private async initialLoad(): Promise<void> {
    await this.braintreeService.init()
    await this.loadPaymentMethods()
    await this.handleCreateHostedFields()
  }

  async handleCreateHostedFields(): Promise<void> {
    if (this.paymentMethodStore.hostedFields.isLoading) return
    this.paymentMethodStore.hostedFields.setIsLoading(true)

    await this.createHostedFields()

    this.paymentMethodStore.hostedFields.setIsLoading(false)
  }

  private async createHostedFields(): Promise<void> {
    const hostedFields = await this.braintreeService.createHostedFields()
    if (isErr(hostedFields)) {
      this.paymentMethodStore.hostedFields.setError(hostedFields.error)
      return
    }
    this.paymentMethodStore.hostedFields.setData(hostedFields.data)
  }

  async handleCreatePaymentMethod(
    type: PaymentMethodValueObject
  ): Promise<void> {
    await this.createPaymentMethodService.handleCreatePaymentMethod(type)
  }

  async handlePrimaryMethodChange(id: number): Promise<void> {
    this.paymentMethodStore.setIsLoading(true)
    await this.paymentMethodService.changePrimaryMethod(id)
    this.paymentMethodStore.setIsLoading(false)
  }

  async handleDeleteMethod(id: number): Promise<void> {
    this.paymentMethodStore.setIsLoading(true)
    await this.paymentMethodService.deleteMethod(id)
    this.paymentMethodStore.setIsLoading(false)
  }

  private async loadPaymentMethods(): Promise<void> {
    await this.paymentMethodService.loadPaymentMethods()
  }

  private async addPayPal(): Promise<void> {
    const payPalResult = await this.braintreeService.tokenizePaypal({
      flow: 'vault',
      enableShippingAddress: false,
    })

    if (isErr(payPalResult)) {
      this.notifyAction.handleAddNotify({
        type: 'danger',
        title: getBlock('appkit.braintree.errors')(
          'unexpectedTokenizePaypalError'
        ),
      })
      return
    }

    if (payPalResult.data.status === 'PAYPAL_POPUP_CLOSED') return

    this.paymentMethodStore.payPalToken.setData({
      email: payPalResult.data.value.details.email,
      token: payPalResult.data.value.nonce,
      isSaved: false,
      firstName: payPalResult.data.value.details.firstName,
      lastName: payPalResult.data.value.details.lastName,
    })
  }

  handleSetIsFormOpen(value: boolean): void {
    this.paymentMethodStore.resetForm()
    this.paymentMethodStore.setIsFormOpen(value)
  }
}
