import { Inject, Service } from '@nori/di'
import { isErr } from '@nori/result'
import { createLogger } from '~/infra/create-logger'
import { CartStore } from '~/modules/cart/interface/store/cart-store/cart.store'

import { ProductCartAdapter } from '../../infra/product-cart.adapter'
import { ProductCartStore } from '../store/product-cart.store'

import type { CartBundleEntity, CartItemEntity } from '@nori/app-kit'

const logger = createLogger('remove-from-cart.service')

@Service()
export class RemoveFromCartService {
  constructor(
    @Inject() private readonly cartStore: CartStore,
    @Inject() private readonly productCartStore: ProductCartStore,
    @Inject() private readonly productCartAdapter: ProductCartAdapter
  ) {}

  async handleRemoveFromCart(product: CartItemEntity): Promise<void> {
    this.cartStore.cartData.setError(undefined)
    this.productCartStore.setIsRemovingFromCart(true)

    await this.removeFromCart(product)

    this.productCartStore.setIsRemovingFromCart(false)
  }

  async handleRemoveBundleFromCart(bundle: CartBundleEntity): Promise<void> {
    this.cartStore.cartData.setError(undefined)
    this.productCartStore.setIsRemovingFromCart(true)

    await this.removeBundleFromCart(bundle)

    this.productCartStore.setIsRemovingFromCart(false)
  }

  private async removeBundleFromCart(bundle: CartBundleEntity): Promise<void> {
    const { cart } = this.cartStore

    if (!cart) return

    const result = await this.productCartAdapter.removeBundleFromCart(
      cart.id,
      bundle
    )

    if (isErr(result)) {
      logger.error(result.error.key, result.error)
      this.cartStore.cartData.setError(result.error)

      return
    }

    this.cartStore.cartData.setData(result.data)
  }

  private async removeFromCart(product: CartItemEntity): Promise<void> {
    const { cart } = this.cartStore

    if (!cart) return

    const result = await this.productCartAdapter.removeProductFromCart(
      cart.id,
      product
    )

    if (isErr(result)) {
      logger.error(result.error.key, result.error)
      this.cartStore.cartData.setError(result.error)

      return
    }

    this.cartStore.cartData.setData(result.data)
  }
}
