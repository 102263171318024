/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable camelcase */

import { StoreEnum } from '@nori/app-kit'

export const FEATURE_FLAGS_LIST = {
  'bo-settings-tax-information-access-stores': [StoreEnum.US],
  'bo-reports-menu-access': false,
  'bo-move-order-enabled': false,
  'bo-multiple-language-support-enabled': false,
  'bo-address-autocomplete-enabled': false,
  charges_and_fees: false,
  'manage-catalog-mailing': true,
  'bo-signup-enabled': false,
}

export type FeatureFlagListValueObject = typeof FEATURE_FLAGS_LIST
