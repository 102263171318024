import { toMoneyValueObject } from '@nori/app-kit'
import { Inject, Service } from '@nori/di'
import { OrderMapperService } from '~/modules/order/interface/services/order-mapper.service'

import type {
  MoveOrderInput,
  OrderEntity,
  OrdersListValueObject,
} from '../../core'
import type orderListWithStatusMock from '../mocks/order-list-with-status.mock.json'
import type { OrdersListInput } from '../types/orders-list/orders-list.input'

@Service()
export class OrdersListMapper {
  constructor(
    @Inject() private readonly orderMapperService: OrderMapperService
  ) {}

  toMoveOrderRequest({ publicOrderId, eventId }: MoveOrderInput): {
    id: number
    party_id: number
  } {
    return {
      id: publicOrderId,
      party_id: eventId,
    }
  }

  toOrderListRequest(input: OrdersListInput): string {
    const query = new URLSearchParams()

    query.append('page', `${input.page}`)
    query.append('ordering', `${input.ordering}`)
    query.append('per_page', `${input.perPage}`)
    query.append('search_filter', `${input.searchFilter}`)
    if (input.searchParam) query.append('search_param', `${input.searchParam}`)
    if (input.from) query.append('from', `${input.from}`)
    if (input.to) query.append('to', `${input.to}`)
    query.append('sort_by', `${input.sortBy}`)
    query.append('_', `${Date.now()}`)

    input.filters?.forEach((filter) => {
      query.append(filter.filterKey, String(filter.value))
    })

    return query.toString()
  }

  toOrderListValueObject(
    data: typeof orderListWithStatusMock
  ): OrdersListValueObject {
    const orders = data.objects?.map((order): OrderEntity => {
      return {
        id: order.id,
        publicId: order.public_order_id,
        contactId: order.contact_id ?? undefined,
        customerFirstName: order.customer_first_name,
        customerSecondName: order.customer_last_name,
        createdAt: order.created_at,
        placedAt: order.placed_at,
        totalPQV: order.total_pqv,
        totalPcvCents: order.total_pcv_cents || 0,
        totalDiscountEarned: order.total_earned_cents,
        totalEarnedRedeemed: order.total_earned_redeemed,
        netDiscountEarned: order.net_discount_earned?.cents,
        shopOfferFee: toMoneyValueObject(order.shop_offers_fee),
        publicStatus:
          typeof order.public_status === 'string' ? order.public_status : '',
        partyName: order.party_name || '---',
        totalPrice: order.total,
        retailTotal: order.retail_total,
        publicRetailTotal: order.public_retail_total,
        publicSubtotal: order.public_subtotal,
        orderTypeName: this.getOrderTypeName(order.type_public_name),
        isFirstOrder: order.first_order,
        earnings: order.earnings_total,
        retailSales: order.retail_sales_cents ?? 0,
        dsr: this.orderMapperService.toDsrEntity(order.dsr),
        orderMoveEligibility: order.order_move_eligibility
          ? {
              isEligible: order.order_move_eligibility.eligible,
              reason: order.order_move_eligibility.reason,
            }
          : undefined,
      }
    })
    const pagination = {
      page: data.pagination.current_page,
      perPage: data.pagination.per_page,
      totalCount: data.pagination.total_count,
      totalPages: data.pagination.total_pages,
    }

    return { orders, pagination }
  }

  private getOrderTypeName(
    // TODO: figure out when it's object or string
    typePublicName: string | { [key: string]: any }
  ): string {
    if (typeof typePublicName === 'object') {
      return typePublicName.dsr
    }
    return typePublicName
  }
}
