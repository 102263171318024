import Script from 'next/script'

import { useAppcuesContext } from '../interface/appcues.context'

import { isEnabled } from './is-appcues-enabled'

const APPCUES_ID = 93727

export const AppcuesScript = () => {
  const { appcuesAction } = useAppcuesContext()

  if (!isEnabled()) {
    return null
  }

  if (typeof window !== 'undefined') {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    window.AppcuesSettings = {
      enableURLDetection: true,
    }
  }

  return (
    <Script
      src={`//fast.appcues.com/${APPCUES_ID}.js`}
      onLoad={appcuesAction.handleAppcuesLoaded}
    />
  )
}
