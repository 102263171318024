import {
  CartService,
  getBaseUrl,
  RequestService,
  SERVICE_NAMES,
} from '@nori/app-kit'
import { Adapter, Inject } from '@nori/di'
import { resultErr, resultOk } from '@nori/result'

import { ProductCartErrors } from '../core/product-cart.errors'

import { ProductCartErrorMapper } from './mappers/product-cart-error.mapper'
import { ProductCartRequestMapper } from './mappers/product-cart-request.mapper'

import type {
  CartBundleEntity,
  CartEntity,
  CartItemEntity,
} from '@nori/app-kit'
import type { PromiseResult } from '@nori/result'
import type { CartErrorInstance } from '~/modules/cart/core'
import type { ProductCartErrorsInstance } from '../core/product-cart.errors'
import type { SwitchProductRequest } from './types/switch-product.request'

@Adapter()
export class ProductCartAdapter {
  baseUrl = getBaseUrl(SERVICE_NAMES.CART_SERVICE)

  public constructor(
    @Inject() private readonly requestService: RequestService,
    @Inject() private readonly cartService: CartService
  ) {}

  async switchProductInCart(
    cartId: number,
    product: CartItemEntity,
    quantity: number
  ): PromiseResult<CartEntity, ProductCartErrorsInstance> {
    try {
      const url = `${this.baseUrl}/carts/${cartId}/switch_skus`
      const input = ProductCartRequestMapper.switchRequest(product, quantity)

      const response =
        await this.requestService.secured.patch<SwitchProductRequest>(url, {
          body: input,
        })

      if (response.ok) {
        return resultOk(this.cartService.toCartEntity(response.json))
      }

      return resultErr(
        ProductCartErrorMapper.toErrors(response.status, response.json)
      )
    } catch (err: unknown) {
      return resultErr(new ProductCartErrors.SwitchUnexpectedError(err))
    }
  }

  async removeBundleFromCart(
    cartId: number,
    bundle: CartBundleEntity
  ): PromiseResult<CartEntity, CartErrorInstance> {
    const url = `${this.baseUrl}/carts/${cartId}/remove_bundle`
    const input = ProductCartRequestMapper.removeBundleRequest(bundle)

    try {
      const response = await this.requestService.secured.delete<any>(url, {
        body: input,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      })

      if (response.ok) {
        return resultOk(this.cartService.toCartEntity(response.json))
      }

      return resultErr(
        new ProductCartErrors.RemoveBundleBadRequest(
          response.status,
          response.json
        )
      )
    } catch (err: unknown) {
      return resultErr(new ProductCartErrors.RemoveBundleUnexpectedError(err))
    }
  }

  async removeProductFromCart(
    cartId: number,
    product: CartItemEntity
  ): PromiseResult<CartEntity, CartErrorInstance> {
    const url = `${this.baseUrl}/carts/${cartId}/remove_sku`
    const input = ProductCartRequestMapper.removeRequest(product)

    try {
      const response = await this.requestService.secured.delete<any>(url, {
        body: input,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      })

      if (response.ok) {
        return resultOk(this.cartService.toCartEntity(response.json))
      }

      return resultErr(
        new ProductCartErrors.BadRequest(response.status, response.json)
      )
    } catch (err: unknown) {
      return resultErr(new ProductCartErrors.UnexpectedError(err))
    }
  }
}
