import { Action, Inject } from '@nori/di'
import { FindOrCreateContactStore } from '~/modules/contacts/interface/store/find-or-create-contact.store'

import { FindOrCreateContactService } from '../services/find-or-create-contact.service'

@Action()
export class FindOrCreateContactAction {
  constructor(
    @Inject()
    private readonly findOrCreateContactStore: FindOrCreateContactStore,
    @Inject()
    private readonly findOrCreateContactService: FindOrCreateContactService
  ) {}

  get createContactFieldHandlers() {
    return this.findOrCreateContactStore.contactValidator.handlers
  }

  async handleContactSubmit(): Promise<void> {
    await this.findOrCreateContactService.submitContact()
  }

  handleContactWithoutEmailToggle(): void {
    this.findOrCreateContactStore.contactWithoutEmailToggle.handleToggle()
    this.findOrCreateContactStore.updateContactValidator()
  }

  handleResetContactForm(): void {
    this.findOrCreateContactStore.setIsEditContact(false)
    this.findOrCreateContactStore.resetAddressValidator()
    this.findOrCreateContactStore.resetContactValidator()
    this.findOrCreateContactStore.contactWithoutEmailToggle.handleClose()
  }
}
