import { toMoneyValueObject } from '@nori/app-kit'
import { Service } from '@nori/di'

import type { CheckoutTotalEntity } from '@nori/app-kit'

@Service()
export class CartTotalMapper {
  toCheckoutTotalEntity(data: any): CheckoutTotalEntity {
    return {
      isTaxCalculated: data.tax_calculated,
      total: toMoneyValueObject(data.total),
      donationAmountTotal: toMoneyValueObject(data.donation_amount_total),
      tax: toMoneyValueObject(data.tax),
      fullyPaidByStoreCredits: data.fully_paid_by_store_credits,
      fullyPaidByCreditsAndDiscounts:
        data.fully_paid_by_store_credits_and_discounts,
      storeCreditsTotal: toMoneyValueObject(data.store_credits_total),
      maxStoreCredits: toMoneyValueObject(data.max_store_credits),
      availableTotal: toMoneyValueObject(data.available_total),
      backorderTotal: toMoneyValueObject(data.backorder_total),
      giftCardTotal: toMoneyValueObject(data.gift_card_total),
      storeCredits: {
        tax: data.store_credits.tax,
        itemsPrice: data.store_credits.items_price,
        shippingPrice: data.store_credits.shipping_price,
      },
      checkoutStatus: {
        taxCalculated: data.checkout_status.tax_calculated,
        eligible: data.checkout_status.eligible,
        ineligibleReasons: data.checkout_status.ineligible_reasons,
      },
      productCreditsTotal: toMoneyValueObject(data.product_credits_total),
      hostessCreditsTotal: toMoneyValueObject(data.hostess_credits_total),
      promotionalCreditsTotal: toMoneyValueObject(
        data.promotional_credits_total
      ),
      combinedCreditsTotal: toMoneyValueObject(data.combined_credits_total),
      totalPqv: data.total_pqv,
      earningsTotal: toMoneyValueObject(data.store_earnings_total),
      totalNetEarned: toMoneyValueObject(data.total_net_earned),
      totalEarned: toMoneyValueObject(data.total_earned),
      couponFee: toMoneyValueObject(data.shop_offers_fee),
      consultantDiscountTotal: toMoneyValueObject(data.total_earned_redeemed),
      publicRetailTotal: toMoneyValueObject(data.public_retail_total),
      isConsultantDiscountApplied: data.earned_redeemed,
      retailDeliveryFee: toMoneyValueObject(data.retail_delivery_fee),
      retailSales: toMoneyValueObject(data.retail_sales),
    }
  }
}
