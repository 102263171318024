import { HistoryService } from '@nori/app-kit'
import { Action, Inject } from '@nori/di'
import { isErr } from '@nori/result'
import { FailedValidationError } from '@nori/validator'
import { createLogger } from '~/infra/create-logger'
import { ContactAdapter } from '~/modules/contacts/infra/contact.adapter'
import { Routes } from '~/modules/routes'

import { ContactFormService } from '../services/contact-form.service'
import { CreateContactStore } from '../store/create-contact.store'

const logger = createLogger('create-contact-action')

@Action()
export class CreateContactAction {
  constructor(
    @Inject() private readonly createContactStore: CreateContactStore,
    @Inject() private readonly contactAdapter: ContactAdapter,
    @Inject() private readonly historyService: HistoryService,
    @Inject() private readonly contactFormService: ContactFormService
  ) {}

  handleContactWithoutEmailToggle(): void {
    this.createContactStore.contactWithoutEmailToggle.handleToggle()
    this.createContactStore.updateContactValidator()
  }

  handleCancel(): void {
    this.historyService.push(Routes.contacts.path())
  }

  async handleSubmit(): Promise<void> {
    this.createContactStore.setIsLoading(true)
    this.createContactStore.setError(undefined)
    await this.submit()
    this.createContactStore.setIsLoading(false)
  }

  private async submit(): Promise<void> {
    const addressValues = this.createContactStore.addressValidator.values
    const contactValues = this.createContactStore.contactValidator.values

    // Required to pass optional validation (since empty string is not considered `no value`)
    this.createContactStore.contactValidator.handlers.phone(
      contactValues.phone || undefined
    )

    const isValid = await this.validate()
    if (!isValid) return
    const email = this.createContactStore.contactWithoutEmailToggle.isOpen
      ? undefined
      : contactValues.email

    const isEmailProvided =
      !this.createContactStore.contactWithoutEmailToggle.isOpen

    const result = await this.contactAdapter.createContact({
      addressAttributes: {
        address1: addressValues.address1,
        address2: addressValues.address2,
        city: addressValues.city,
        country: addressValues.country,
        state: addressValues.state,
        zipCode: addressValues.zipCode,
      },
      birth: {
        day: contactValues.day,
        month: contactValues.month,
      },
      email: email,
      isEmailProvided,
      firstName: contactValues.firstName,
      generateReferralLink: false,
      hasWishList: false,
      lastName: contactValues.lastName,
      notesAttributes: [{}],
      phone: contactValues.phone,
      potentialHostess: contactValues.isPotentialHost,
      potentialStylist: contactValues.isPotentialConsultant,
      preferredCustomer: false,
      selected: false,
      showQuiz: false,
      skinQuizConfig: { version: 2 },
    })

    if (isErr(result)) {
      logger.error(result.error.key, result.error)
      this.createContactStore.setError(result.error)
      return
    }

    if (result.data.type === 'validationFailed') {
      FailedValidationError.handleValidatorErrors({
        errors: result.data.data,
        validator: this.createContactStore.addressValidator,
      })
      FailedValidationError.handleValidatorErrors({
        errors: result.data.data,
        validator: this.createContactStore.contactValidator,
      })
      return
    }

    await this.historyService.push(Routes.contacts.path())
  }

  private async validate(): Promise<boolean> {
    try {
      this.createContactStore.addressValidator.resetErrors()

      const isContactValid = await this.validateContactForm()
      const isAddressValid = await this.contactFormService.validateAddressForm(
        this.createContactStore.addressValidator
      )

      return isAddressValid && isContactValid
    } catch (error: unknown) {
      return false
    }
  }

  private async validateContactForm(): Promise<boolean> {
    const result = await this.createContactStore.contactValidator.submit()

    return result.isValid
  }

  setMonth(month: number): void {
    this.createContactStore.contactValidator.handlers.day(0)
    this.createContactStore.contactValidator.handlers.month(month)
  }
}
