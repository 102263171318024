import { useEffect, useState } from 'react';
import { formatPhone } from '@nori/phone';
export const usePhoneMask = ({ initialValue ='' , onChange  })=>{
    const [maskedValue, setMaskedValue] = useState('');
    useEffect(()=>{
        if (!initialValue) {
            return;
        }
        const { formattedPhoneNumber  } = formatPhone(initialValue);
        handleChange(formattedPhoneNumber);
    }, []);
    const handleChange = (value = '')=>{
        const { formattedPhoneNumber , rawValue  } = formatPhone(value);
        onChange(rawValue);
        setMaskedValue(formattedPhoneNumber);
    };
    return {
        maskedValue,
        handleChange
    };
};
