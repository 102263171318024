import { Action, Inject } from '@nori/di'

import { LoadContactsService } from '../services/contact.service'
import { ContactsManageMailingService } from '../services/contacts-manage-mailing.service'
import { ContactManageMailingStore } from '../store/contact-manage-mailing.store'

@Action()
export class ContactManageMailingAction {
  constructor(
    @Inject()
    private readonly contactManageMailingStore: ContactManageMailingStore,
    @Inject()
    private readonly contactsManageMailingService: ContactsManageMailingService,
    @Inject()
    private readonly loadContactsService: LoadContactsService
  ) {}

  async handleChangeSearchQuery(value: string): Promise<void> {
    this.contactManageMailingStore.setIsLoading(true)

    this.contactManageMailingStore.setSearchQuery(value)
    await this.contactsManageMailingService.searchContacts({
      searchQuery: value,
    })

    this.contactManageMailingStore.setIsLoading(false)
  }

  async handleChangeDataTableType(value: 'opt-in' | 'all'): Promise<void> {
    this.contactManageMailingStore.setIsLoading(true)

    this.contactManageMailingStore.setDataTableType(value)
    this.contactManageMailingStore.setContactsToReceiveCatalogToDefault()

    await this.contactsManageMailingService.loadContacts({})

    this.contactManageMailingStore.setIsLoading(false)
  }

  async handleLoadContactsManageMailingData(): Promise<void> {
    this.contactManageMailingStore.setIsLoading(true)

    await this.contactsManageMailingService.loadContacts({})

    this.contactManageMailingStore.setIsLoading(false)
  }

  async handleLoadNextPage(): Promise<void> {
    this.contactManageMailingStore.setIsLoadingNextPage(true)
    const nextPage = this.contactManageMailingStore.currentPage + 1

    await this.contactsManageMailingService.loadNextPage(nextPage)

    this.contactManageMailingStore.setIsLoadingNextPage(false)
  }

  async handleSaveContactsManageMailingData(): Promise<void> {
    this.contactManageMailingStore.setIsLoading(true)

    await this.contactsManageMailingService.setNotesForContacts()
    await this.contactsManageMailingService.setContactsToReceiveCatalog()
    await this.contactsManageMailingService.loadContacts({})
    await this.loadContactsService.loadContacts({})

    this.contactManageMailingStore.setIsLoading(false)
  }

  handleSelectContact({ id, value }: { id: number; value: boolean }): void {
    this.contactManageMailingStore.setContactsToReceiveCatalog(id, value)
  }

  handleResetContactsManageMailingData(): void {
    this.contactManageMailingStore.setAllDataToDefault()
  }

  handleToggleContactManageMailingModal(): void {
    this.contactManageMailingStore.contactManageMailingModal.handleToggle()
  }
}
