import { errorFactory } from '@nori/errors'

import type { KeyOfErrors } from '@nori/errors'

export const { OrderErrors } = errorFactory('OrderErrors', {
  GetPartyOrdersRetailSalesBadRequest:
    'Bad Request Get Party Orders Retail Sales',
  GetPartyOrdersRetailSalesUnexpectedError:
    'Unexpected Error Get Party Orders Retail Sales',
  GetPartyOrdersRetailSalesUnknownError:
    'Unknown Error Get Party Orders Retail Sales',

  GetOrderBadRequest: 'Bad Request Get Order',
  GetOrderUnexpectedError: 'Unexpected Error Get Order',
  GetOrderUnknownError: 'Unknown Error Get Order',

  CancelOrderBadRequest: 'Bad Request Cancel Order',
  CancelOrderUnexpectedError: 'Unexpected Error Cancel Order',
  CancelOrderUnknownError: 'Unknown Error Cancel Order',

  GetOrderPDFUnknownError: 'Unknown Error Get Order PDF',
  GetOrderPDFUnexpectedError: 'Unexpected Error Get Order PDF',
})

export type OrderErrorInstance = InstanceType<KeyOfErrors<typeof OrderErrors>>
