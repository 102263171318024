import { dateFormat } from '@nori/app-kit'
import { useTranslations } from '@nori/lang-i18n'
import { Icon } from '@nori/ui-kit'

import { partyIconMap } from '../party-icon.mapper'

import type { PartyEntity } from '~/modules/parties/core/entities/party.entity'

type Props = {
  party?: PartyEntity
}

export function PartyDropdownItem({ party }: Props) {
  const t = useTranslations('cart.summary.shareModal')

  const partyIcon = party?.location
    ? partyIconMap[party?.location]
    : 'CalendarOutline'

  const partyName = party ? party?.partyName : t('noPartySelected')

  const partyLocation =
    party?.location === 'online'
      ? party.location
      : `${party?.address?.city}, ${party?.address?.state}`

  const partyDetails =
    party?.partyAt &&
    `${dateFormat(party.partyAt, 'ccc MMM dd, yyyy / hh:mma')}`

  return (
    <div className="flex items-center">
      <div className="flex h-10 w-10 items-center justify-center rounded-full bg-primary-900 text-white">
        <Icon name={partyIcon} />
      </div>
      <div className="ml-2">
        <p className="text-sm font-bold text-primary-900">{partyName}</p>
        {party && (
          <span className="flex flex-wrap">
            <p className="text-sm font-normal text-gray-500">
              {`${partyDetails}`}
            </p>
            {partyLocation && (
              <p className="text-sm font-normal text-gray-500">
                {' / '}
                {`${partyLocation}`}
              </p>
            )}
          </span>
        )}
      </div>
    </div>
  )
}
