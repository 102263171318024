import type { RewardProductEntity } from '../../core/entity/reward.entity'
import type rewardMock from '../mocks/reward.mock.json'

export class RewardItemMapper {
  static toValueObject(response: typeof rewardMock): RewardProductEntity[] {
    return response.map((item): RewardProductEntity => {
      return {
        ...item,
        style: {
          ...item.style,
          shortDescription: item.style.short_description,
          swatchColor: item.style.swatch_color,
        },
        product: {
          ...item.product,
          skuVariation: item.product.sku_variation,
          styleType: item.product.style_type,
        },
        currentRetailPrice: item.current_retail_price,
        currentSalePrice: item.current_sale_price,
        images: item.images.map((picture) => {
          return {
            alternate: picture.alternate,
            fullSizeURL: picture.full_size_url,
            id: picture.id,
            ipadLarge: picture.ipad_large,
            ipadMedium: picture.ipad_medium,
            ipadThumbnail: picture.ipad_thumbnail,
            iphoneLarge: picture.iphone_large,
            iphoneMedium: picture.iphone_medium,
            iphoneThumbnail: picture.iphone_thumbnail,
            position: picture.position,
            thumbnailLargeURL: picture.thumbnail_large_url,
            thumbnailMedURL: picture.thumbnail_med_url,
            thumbnailSmallURL: picture.thumbnail_small_url,
          }
        }),
      }
    })
  }
}
