import { useEffect } from 'react'

import { CommonProductList } from '@nori/app-kit'
import { observer } from '@nori/di'
import { Loader } from '@nori/ui-kit'
import { useProductsLoungeContext } from '~/modules/products/interface/use-products-context'

import { ProductListItem } from './product-list-item'

import type { ProductListType } from '@nori/app-kit'

type Props = {
  type: ProductListType
  id: string
}

export const ProductList = observer(({ type, id }: Props) => {
  const { productsAction, productsStore } = useProductsLoungeContext()

  const { error, isLoading } = productsStore

  useEffect(() => {
    productsAction.handleInitialLoad({
      id,
      type: type,
    })
  }, [id, productsAction, type])

  if (isLoading) return <Loader />
  if (error) return <div>Error while loading products!</div>
  if (!productsStore.list) return <div>Empty list!</div>

  return (
    <CommonProductList
      hasInfiniteScroll
      isLastPage={productsStore.isLastPage}
      isLoadingNextPage={productsStore.isLoadingNextPage}
      handleLoadNextPage={productsAction.handleLoadNextItems}
      list={productsStore.list}
      onClick={productsAction.handleOpenProduct}
      renderItem={ProductListItem}
    />
  )
})
