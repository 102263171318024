import { useEffect } from 'react'

import { useI18nContext } from '@nori/app-kit'
import { observer } from '@nori/di'
import { useTranslations } from '@nori/lang-i18n'
import { Button, Modal } from '@nori/ui-kit'
import { TERMS_CONDITIONS_LINKS } from '~/modules/enrollment/core'

import { useAgreementContext } from '../../interface/context/use-agreement-context'

export const AgreementModal = observer(() => {
  const t = useTranslations('agreementModal')
  const { i18nStore } = useI18nContext()
  const { agreementStore, agreementAction } = useAgreementContext()
  const { agreementVersion, isCurrentAgreementAccepted } = agreementStore

  useEffect(() => {
    if (agreementVersion.isLoading) return
    if (!isCurrentAgreementAccepted)
      agreementStore.agreementModalToggle.handleOpen()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [agreementVersion.isLoading])

  return (
    <Modal
      data-test-id="dsr-agreement-modal"
      size="6xl"
      isOpen={agreementStore.agreementModalToggle.isOpen}
    >
      <Modal.Header headerType="only-close-button" />
      <Modal.Body isFullHeight>
        <div className="flex h-full flex-col items-end">
          <p className="mb-7 text-base text-gray-900">
            {t.rich('updatedTerms', {
              policies: () => (
                <a
                  className="font-bold text-primary-900 underline"
                  href={
                    TERMS_CONDITIONS_LINKS.policiesAndProcedures[
                      i18nStore.currentLocale
                    ]
                  }
                  rel="noreferrer"
                  target="_blank"
                >
                  {t('policiesProcedures')}
                </a>
              ),
              consultantAgreement: () => (
                <a
                  className="font-bold text-primary-900 underline"
                  href={
                    TERMS_CONDITIONS_LINKS.norwexConsultantAgreement[
                      i18nStore.currentLocale
                    ]
                  }
                  rel="noreferrer"
                  target="_blank"
                >
                  {t('consultantAgreement')}
                </a>
              ),
              mediaPolicies: () => (
                <a
                  className="font-bold text-primary-900 underline"
                  href={
                    TERMS_CONDITIONS_LINKS.mediaPolicy[i18nStore.currentLocale]
                  }
                  rel="noreferrer"
                  target="_blank"
                >
                  {t('mediaPolicies')}
                </a>
              ),
              successBuilder: () => (
                <a
                  className="font-bold text-primary-900 underline"
                  href={
                    TERMS_CONDITIONS_LINKS.successBuilder[
                      i18nStore.currentLocale
                    ]
                  }
                  rel="noreferrer"
                  target="_blank"
                >
                  {t('successBuilder')}
                </a>
              ),
            })}
          </p>
          <div className="w-full max-md:h-full max-md:flex-1 md:h-[428px]">
            <div className="h-full">
              <iframe
                src={
                  TERMS_CONDITIONS_LINKS.agreementPreview[
                    i18nStore.currentLocale
                  ]
                }
                width="100%"
                height="100%"
              />
            </div>
          </div>
          <div className="ml-auto mt-6 max-md:w-full md:w-60">
            <Button
              data-test-id="accept-dsr-agreement-button"
              size="xl"
              isLoading={agreementStore.isLoadingAcceptance}
              disabled={agreementStore.isLoadingAcceptance}
              widthType="full"
              onClick={agreementAction.handleAcceptAgreement}
            >
              {t('acceptBtn')}
            </Button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  )
})
