import {
  AutoSaveBadge,
  getSkuName,
  getStyleItemName,
  ItemPrice,
  ProductImage,
} from '@nori/app-kit'
import { useTranslations } from '@nori/lang-i18n'

import type { CartItemEntity } from '@nori/app-kit'

type Props = {
  item: CartItemEntity
}

export function CartItem({ item }: Props) {
  const t = useTranslations('shopForMyself.orderSummary.cartItem')

  const isAutoshipItem = !!item.autoShip?.id

  return (
    <div
      className="flex max-w-[335px] flex-row border-b py-5"
      data-test-id="order-summary-item"
    >
      <ProductImage
        src={item.thumbnailUrl}
        width={80}
        height={80}
        alt={item.productName}
        styleName="h-full flex-shrink-0 flex-grow-0"
      />
      <div className="ml-4 flex w-full flex-col justify-between">
        <div>
          <div className="mb-0.5 flex items-start justify-between gap-2  text-sm font-bold text-black">
            <p
              data-test-id="order-summary-item-name"
              className="mb-1 w-32 break-words"
            >
              {getStyleItemName({
                productName: item.productName,
                styleName: item.styleName,
              })}
            </p>
            <div className="flex flex-col gap-1">
              <ItemPrice
                price={item.price}
                retailPrice={item.retailPrice}
                salePrice={item.salePrice}
                data-test-id="order-summary"
              />
            </div>
          </div>
          <div
            className="text-xs font-medium text-gray-900"
            data-test-id="order-summary-item-sku-name"
          >
            {getSkuName({
              name: item.skuName,
              variation: item.skuVariation,
            })}
          </div>
          <div
            className="mb-1 text-xs font-medium text-gray-900"
            data-test-id="order-summary-item-sku-code"
          >
            {item.skuCode}
          </div>
        </div>

        {isAutoshipItem && <AutoSaveBadge />}

        {item.autoShip.name && (
          <div
            data-test-id="order-summary-autoship-name"
            className="text-xs font-medium text-gray-900"
          >
            {t('autosaveFrequency')} {item.autoShip.name}
          </div>
        )}
        <div
          className="text-xs font-medium text-gray-900"
          data-test-id="order-summary-item-qty"
        >
          {t('qty')} {item.quantity}
        </div>
      </div>
    </div>
  )
}
