import { UserStore } from '@nori/app-kit'
import { Action, Inject } from '@nori/di'
import { getBlock } from '@nori/lang-i18n'
import { NotifyAction } from '@nori/notify'
import { isErr } from '@nori/result'
import { createLogger } from '~/infra/create-logger'
import { SearchProductsService } from '~/modules/search-products'
import { SubscriptionService } from '~/modules/subscription'

import { AutosaveService } from '../service/autosave.service'
import { NextOrderStore } from '../store/next-order.store'
import { SubscriptionsAddProductStore } from '../store/subscriptions-add-product.store'

import type { AddSubscriptionInput } from '~/modules/subscription'
import type { AddProductInputValueObject } from '../../core/add-product-input.value-object'

const MIN_SEARCH_QUERY_LENGTH = 3
const NEXT_PAGE_COUNTER = 1
const INITIAL_PAGE_NUMBER = 1
const ITEMS_PER_PAGE = 10

const logger = createLogger('subscriptions-add-product.action')

@Action()
export class SubscriptionsAddProductAction {
  constructor(
    @Inject() private readonly searchProductsService: SearchProductsService,
    @Inject()
    private readonly subscriptionsAddProductStore: SubscriptionsAddProductStore,
    @Inject() private readonly subscriptionService: SubscriptionService,
    @Inject() private readonly autosaveService: AutosaveService,
    @Inject() private readonly notifyAction: NotifyAction,
    @Inject() private readonly userStore: UserStore,
    @Inject() private readonly nextOrderStore: NextOrderStore
  ) {}

  async handleSearchQuery(query: string): Promise<void> {
    const searchQuery = query.trim()
    if (searchQuery === this.subscriptionsAddProductStore.searchQuery) return

    this.subscriptionsAddProductStore.setSearchQuery(searchQuery)

    if (searchQuery.length < MIN_SEARCH_QUERY_LENGTH) {
      this.subscriptionsAddProductStore.products.setData(undefined)
      return
    }

    this.subscriptionsAddProductStore.products.setIsLoading(true)

    await this.loadProducts()

    this.subscriptionsAddProductStore.products.setIsLoading(false)
  }

  async handleLoadMore(): Promise<void> {
    if (!this.subscriptionsAddProductStore.hasMore) return
    if (this.subscriptionsAddProductStore.isLoadingMore) return

    this.subscriptionsAddProductStore.setIsLoadingMore(true)

    await this.loadProducts(
      this.subscriptionsAddProductStore.page + NEXT_PAGE_COUNTER
    )

    this.subscriptionsAddProductStore.setIsLoadingMore(false)
  }

  async handleAddProduct(input: AddProductInputValueObject): Promise<void> {
    const isLoading =
      this.subscriptionsAddProductStore.isLoadingById[input.product.id]
    if (isLoading) return

    this.subscriptionsAddProductStore.setIsProductLoading(
      input.product.id,
      true
    )

    await this.addProduct(input)

    this.subscriptionsAddProductStore.setIsProductLoading(
      input.product.id,
      false
    )
  }

  private async addProduct(input: AddProductInputValueObject): Promise<void> {
    const t = getBlock('autosaveSettings')

    const result = await this.subscriptionService.addSubscription(
      this.getAddProductInput(input)
    )

    if (isErr(result)) {
      this.notifyAction.handleAddNotify({
        title: t('errors.addItemError'),
        type: 'danger',
      })
      return
    }

    this.notifyAction.handleAddNotify({
      title: t('addProductSuccess'),
      type: 'success',
    })
    await this.autosaveService.loadSubscriptions()
    this.handleClose()
  }

  private getAddProductInput(
    input: AddProductInputValueObject
  ): AddSubscriptionInput {
    const product = input.product
    const base = {
      oneOff: !!input.oneOff,
      quantity: 1,
      cycleId: input.subscriptionId || product.defaultProductSubscription.id,
      shippingAddress: {
        ...this.userStore.currentUser.shippingAddress,
        firstName: this.userStore.currentUser.firstName,
        lastName: this.userStore.currentUser.lastName,
      },
      nextCartAt: !input.oneOff
        ? this.nextOrderStore
            .getNextOrderPopupDate(product.id)
            ?.formatInTZ('yyyy-MM-dd')
        : undefined,
    }

    switch (product.typeCode) {
      case 'bundle':
        return {
          ...base,
          bundleCode: product.code,
          skuIds: product.components?.flatMap((component) =>
            component.products.flatMap((product) =>
              product.skus.map((sku) => sku.id)
            )
          ),
        }
      case 'style':
        return {
          ...base,
          skuId: product.skus?.[0]?.id ?? product.id,
        }
      default:
        return base
    }
  }

  private async loadProducts(page = 1): Promise<void> {
    this.subscriptionsAddProductStore.products.setError(undefined)

    const result = await this.searchProductsService.searchProducts({
      searchQuery: this.subscriptionsAddProductStore.searchQuery,
      pagination: {
        page,
        perPage: ITEMS_PER_PAGE,
      },
      isCustomerSearch: false,
      noShippingRestricted: true,
    })

    if (isErr(result)) {
      logger.error(result.error.key, result.error)
      this.subscriptionsAddProductStore.products.setError(result.error)
      return
    }

    this.subscriptionsAddProductStore.products.setData({
      filters: result.data.filters,
      pagination: result.data.pagination,
      items:
        result.data.pagination.page > INITIAL_PAGE_NUMBER
          ? [
              ...(this.subscriptionsAddProductStore.products?.data?.items ??
                []),
              ...result.data.items,
            ]
          : result.data.items,
    })
  }

  handleOpen(): void {
    this.subscriptionsAddProductStore.setIsOpen(true)
  }

  handleClose(): void {
    this.subscriptionsAddProductStore.setSearchQuery('')
    this.subscriptionsAddProductStore.products.setData(undefined)
    this.subscriptionsAddProductStore.setIsOpen(false)
  }
}
