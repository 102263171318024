import { observer } from '@nori/di'
import { useTranslations } from '@nori/lang-i18n'
import { InfiniteScroll, Loader, Swap } from '@nori/ui-kit'
import { useAutosaveContext } from '~/modules/autosave/interface/use-autosave-context'

import { AddItemTableDesktop } from './add-item-table-desktop/add-item-table-desktop'
import { AddItemTableMobile } from './add-item-table-mobile/add-item-table-mobile'

export const AddItemSearchResults = observer(() => {
  const t = useTranslations('autosaveSettings.addItemModal')
  const { nextOrderAddItemStore, nextOrderAddItemAction } = useAutosaveContext()

  if (nextOrderAddItemStore.isLoading) return <Loader />
  if (nextOrderAddItemStore.error) {
    return <div>{nextOrderAddItemStore.error.message}</div>
  }

  return (
    <>
      {!!nextOrderAddItemStore.products?.items?.length && (
        <InfiniteScroll
          next={nextOrderAddItemAction.handleLoadMore}
          dataLength={nextOrderAddItemStore.products?.items?.length ?? 0}
          loader={
            nextOrderAddItemStore.isLoadingMore && (
              <div className="m-4">
                <Loader />
              </div>
            )
          }
          hasMore={nextOrderAddItemStore.hasMore}
          scrollThreshold={0.5}
          scrollableTarget="autosave-settings-add-item-modal"
        >
          <div className="hidden lg:block">
            <AddItemTableDesktop />
          </div>

          <div className="lg:hidden">
            <AddItemTableMobile />
          </div>
        </InfiniteScroll>
      )}
    </>
  )
})
