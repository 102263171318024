import { NoriDate } from '@nori/date'
import { useTranslations } from '@nori/lang-i18n'
import { Button, DatePickerTZ, Dropdown, Input, useToggle } from '@nori/ui-kit'

import type { ProductSubscriptionEntity } from '@nori/app-kit'

const MIN_FUTURE_DAYS = 1

type Props = {
  itemQty: string
  onItemQtyChange?: (qty: string) => void
  productSubscriptions?: ProductSubscriptionEntity[]
  cycleName?: string
  onFrequencyChange?: (value: number) => void
  itemDate?: NoriDate
  onDateChange?: (date: NoriDate) => void
  handleSave?: () => void
}

export const AutoSaveOrderRowEditor = ({
  itemQty,
  productSubscriptions,
  itemDate,
  onFrequencyChange,
  onItemQtyChange,
  onDateChange,
  handleSave,
  cycleName,
}: Props) => {
  const t = useTranslations('autosaveSettings')

  const {
    isOpen: isCalendarOpen,
    handleClose: handleCalendarClose,
    handleToggle: handleCalendarToggle,
  } = useToggle()

  const minDate = new NoriDate().addDays(MIN_FUTURE_DAYS)

  return (
    <div className="flex flex-wrap gap-x-5 gap-y-6">
      <div className="flex w-full flex-col gap-6 lg:flex-row lg:gap-2">
        <div className="w-full max-w-full lg:max-w-[100px]">
          <Input
            label={t('autosaveTable.qty')}
            type="integer"
            min={1}
            value={itemQty}
            onChange={onItemQtyChange}
            hideHelperBlock
            data-test-id={'quantity-input'}
          />
        </div>
        <div className="flex-1">
          <Dropdown
            hideHelperBlock
            label={t('autosaveTable.frequency')}
            title={t('autosaveTable.cycleDuration', { cycle: cycleName })}
          >
            {productSubscriptions?.map((option) => {
              return (
                <div
                  key={option.id}
                  className="cursor-pointer p-2 text-sm font-normal text-gray-900 hover:bg-gray-50"
                  onClick={() => {
                    onFrequencyChange?.(option.id)
                  }}
                >
                  {t('autosaveTable.cycleDuration', { cycle: option.name })}
                </div>
              )
            })}
          </Dropdown>
        </div>
      </div>
      {itemDate && (
        <div className="w-full">
          <DatePickerTZ
            value={itemDate}
            isOpen={isCalendarOpen}
            onClose={handleCalendarClose}
            minDate={minDate}
            onChange={([date]) => date && onDateChange?.(date)}
            withClear={false}
            withToday={false}
          >
            <Input
              label={t('autosaveTable.subscriptionDate')}
              value={itemDate.formatInTZ('MM-dd-yyyy')}
              hideHelperBlock
              data-test-id={'date-input'}
              iconLeft="CalendarOutline"
              onFocus={handleCalendarToggle}
            />
          </DatePickerTZ>
        </div>
      )}
      <Button widthType="full" size="xl" onClick={handleSave}>
        {t('autosaveTable.save')}
      </Button>
      <span className="w-full text-center text-sm font-normal text-primary-900">
        {t('autosaveTable.changesApplied')}
      </span>
    </div>
  )
}
