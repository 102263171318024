import { useEffect, useState } from 'react'
import Link from 'next/link'
import Script from 'next/script'

import { useProfileContext } from '@nori/app-kit'
import { observer } from '@nori/di'
import { useTranslations } from '@nori/lang-i18n'
import { useMount } from '@nori/ui-kit'

import {
  LIVE_CHAT_CONFIGS,
  LIVE_CHAT_SCRIPT_SRC,
  systemGroupRanks,
} from './live-chat-config'

export const LiveChatLink = observer(() => {
  const t = useTranslations('header.help')
  const { userStore } = useProfileContext()
  const { currentUser, store } = userStore
  const config = LIVE_CHAT_CONFIGS[store === 'us' ? 'US' : 'CA']
  const linkClass =
    'lg:block lg:px-2 lg:py-2 lg:text-sm lg:hover:bg-primary-50 font-bold text-primary-900 lg:bg-white'
  const linkClassMobile =
    'flex flex-row justify-between border-primary-700 bg-primary-50 px-4 py-3'

  const poc = systemGroupRanks.includes(userStore.currentUser.careerRankCode)
    ? config.systemGroup
    : config.systemGroupAll
  const liveChatConfig = {
    serverHost: config.url,
    // eslint-disable-next-line camelcase
    bus_no: config.id,
    poc,
    params: [currentUser.firstName, currentUser.lastName],
    customChatButton: true,
  }

  function createCloseIcon(): HTMLElement {
    const closeButton: HTMLElement = document.createElement('div')
    closeButton.innerHTML = 'X'
    closeButton.style.cssText = `
    top: 8px;
    position: absolute;
    z-index: 40000;
    right: 15px;
    cursor: pointer;
    color: #638D92;
    font: bold;
    `
    closeButton.onclick = () => {
      // @ts-expect-error This is necessary because the chat client initialization is not typed
      icPatronChat.setStateWindowToggle()
    }
    return closeButton
  }

  function setupObserver(): void {
    const observer: MutationObserver = new MutationObserver(
      (mutationsList: MutationRecord[]) => {
        for (const mutation of mutationsList) {
          if (mutation.type === 'childList') {
            const addedNodes: NodeList = mutation.addedNodes
            addedNodes.forEach((node: Node) => {
              if (
                node instanceof HTMLElement &&
                node.classList.contains('ie-div-position-customer-chat')
              ) {
                node.childNodes[0]?.appendChild(createCloseIcon())
                observer.disconnect()
              }
            })
          }
        }
      }
    )

    observer.observe(document.body, { childList: true, subtree: true })
  }

  const handleOpenLiveChat = () => {
    // @ts-expect-error This is necessary because the chat client initialization is not typed
    icPatronChat.setStateWindowToggle()
  }

  useMount(() => {
    setupObserver()
  })

  return (
    <>
      <Script
        src={LIVE_CHAT_SCRIPT_SRC}
        strategy="lazyOnload"
        onLoad={() => {
          if (!document.querySelector('.ie-div-position-customer-chat')) {
            // @ts-expect-error This is necessary because the chat client initialization is not typed
            icPatronChat.init(liveChatConfig)
          }
        }}
      />

      <Link
        onClick={handleOpenLiveChat}
        className={`${linkClass} ${linkClassMobile}`}
        data-test-id="live-chat-option"
        href={'#'}
      >
        {t('liveChat')}
      </Link>
    </>
  )
})
