import { LocalStorageItem } from '@nori/app-kit'
import { Store } from '@nori/di'

const PERSONAL_CART_COUNTER_ID_KEY = '@nori/personal-cart-counter'

@Store()
export class HeaderStore {
  isUserMenuVisible = false
  isMenuVisible = false
  nestedMenuVisibility: Record<string, boolean | undefined> = {}

  private personalCartCounter = LocalStorageItem<number>(
    PERSONAL_CART_COUNTER_ID_KEY,
    {
      initialValue: 0,
    }
  )

  setUserMenuVisible(value: boolean): void {
    this.isUserMenuVisible = value
  }

  setMenuVisible(value: boolean): void {
    this.isMenuVisible = value
  }

  setNestedMenuVisible(key: string, value: boolean): void {
    this.nestedMenuVisibility[key] = value
  }

  get personalCartItemsCounter(): number {
    return Number(this.personalCartCounter.value)
  }

  setPersonalCartItemsCounter(value: number): void {
    this.personalCartCounter.set(value)
  }
}
