import { useTranslations } from '@nori/lang-i18n'
import { Icon } from '@nori/ui-kit'

import type { PayPalMethodValueObject } from '~/modules/payment-methods'
import type { PayPalTokenValueObject } from '../../core'

type Props = {
  paypalAccount: PayPalTokenValueObject | PayPalMethodValueObject
  onChange: () => void
}

export function PaypalAccount({ paypalAccount, onChange }: Props) {
  const t = useTranslations('billingForm')

  return (
    <div className="max-xl:flex max-xl:flex-col">
      <div className="flex items-center justify-between">
        <div className="mr-1 flex items-center">
          <div className="mr-3.5">
            <Icon name="PaypalCard" size="lg" />
          </div>
          <span
            className="text-base font-bold text-gray-700"
            data-test-id="paypal-title"
          >
            {t('payPalAccount')}
          </span>
        </div>
        <div className="mr-1 whitespace-pre text-base font-normal text-gray-700 max-xl:hidden">
          {paypalAccount.email}
        </div>
        <div>
          <button
            onClick={onChange}
            className="flex items-center gap-1 text-sm font-bold text-primary-900"
            data-test-id="change-button"
          >
            <Icon name="PencilAlt" size="xs" />
            {t('change')}
          </button>
        </div>
      </div>
      <div className="mt-4 overflow-hidden text-ellipsis whitespace-pre text-base font-normal text-gray-700 xl:hidden">
        {paypalAccount.email}
      </div>
    </div>
  )
}
