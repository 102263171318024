import { getMoneyWithIsoCurrency, ProductImage } from '@nori/app-kit'
import { useTranslations } from '@nori/lang-i18n'
import { Icon } from '@nori/ui-kit'

import { Row } from './row'

import type { CommonBundleEntity } from '@nori/app-kit'

type Props = {
  item: CommonBundleEntity
}

export function BundleRow({ item }: Props) {
  const t = useTranslations('autosaveSettings.addItemModal')

  return (
    <Row item={item}>
      <div className="flex">
        <ProductImage
          alt="No Image Available"
          width={80}
          height={80}
          styleName="mr-3 h-20 w-20"
          src={item.pictures[0]?.thumbnailMedURL}
        />
        <div className="flex flex-col gap-1">
          <div className="break-all text-base font-bold text-gray-700">
            {item.name ?? item.brandName}
          </div>
          <div className="text-sm font-medium text-gray-500">{item.code}</div>
        </div>
      </div>
      <div className="flex flex-col gap-1">
        {item.maxDsrPrice && (
          <div className="mt-4 text-sm text-primary-900">
            <div className="text-xs font-normal">{t('subscription')}</div>
            <div className="mt-1 flex gap-1 font-normal">
              {getMoneyWithIsoCurrency({
                cents: item.maxDsrPrice.cents,
                currency: item.maxDsrPrice.currency,
              })}
              <Icon name="Refresh" />
            </div>
          </div>
        )}
        {item.maxPrice && (
          <div className="text-base text-primary-900">
            <div className="text-xs font-normal">{t('oneTime')}</div>
            <div className="font-normal">
              {getMoneyWithIsoCurrency({
                cents: item.maxPrice.cents,
                currency: item.maxPrice.currency,
              })}
            </div>
          </div>
        )}
      </div>
    </Row>
  )
}
