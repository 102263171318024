import { observer } from '@nori/di'
import { useContactContext } from '~/modules/contacts/interface/use-contact-context'

import { EventSummary } from './event-summary'
import { ReferralSummary } from './referral-summary'

export const ReferralSummarySection = observer(() => {
  const { contactDetailsStore } = useContactContext()
  const contact = contactDetailsStore.contact
  const rewards = contactDetailsStore.rewards.data

  if (!rewards?.referralEarningPeriod.id) return null
  if (!contact?.isPreferredDsr) return null

  if (contact?.events.length && rewards?.referralEarningPeriod.partyId) {
    return (
      <>
        {contact.events.map((event) => {
          const type = event.isCurrentEvent ? 'booked' : 'upcoming'
          const earningPeriod = event.isCurrentEvent
            ? rewards?.referralEarningPeriod
            : rewards?.upcomingParty

          if (!earningPeriod || earningPeriod.partyId !== event.id) return null

          return (
            <div className="mt-5" key={event.id}>
              <EventSummary
                partyAt={event.partyAt}
                openOn={event.openOn}
                closedOn={event.closedOn}
                referralOrdersCount={earningPeriod.referralOrdersCount}
                totalReferralCreditsEarned={
                  earningPeriod?.totalReferralCreditsEarned
                }
                totalRetailSalesCents={earningPeriod?.totalRetailSalesCents}
                currency={earningPeriod?.currency}
                partyId={earningPeriod?.partyId}
                partyPath={event.partyPath}
                type={type}
              />
            </div>
          )
        })}
      </>
    )
  }

  return (
    <div className="mt-5">
      <ReferralSummary />
    </div>
  )
})
