import { getBaseUrl, RequestService, SERVICE_NAMES } from '@nori/app-kit'
import { Adapter, Inject } from '@nori/di'
import { resultErr, resultOk } from '@nori/result'

import {
  type AcceptAgreementErrorInstance,
  AcceptAgreementErrors,
} from '../core/accept-agreement.errors'
import { GetAgreementVersionErrors } from '../core/get-agreement-version.errors'

import { AcceptAgreementRequestMapper } from './mappers/accept-agreement-request.mapper'
import { AgreementVersionMapper } from './mappers/agreement-version.mapper'
import { AcceptAgreementErrorMapper } from './mappers/error/accept-agreement-error.mapper'
import { GetAgreementVersionErrorMapper } from './mappers/error/get-agreement-version-error.mapper'

import type { PromiseResult } from '@nori/result'
import type { AgreementVersionEntity } from '../core/entities/agreement-version.entity'
import type { GetAgreementVersionErrorInstance } from '../core/get-agreement-version.errors'
import type { AcceptAgreementResponse } from './types/accept-agreement.response'
import type { GetAgreementVersionResponse } from './types/get-agreement-version.response'

@Adapter()
export class AgreementAdapter {
  userServiceUrl = getBaseUrl(SERVICE_NAMES.USER_SERVICE)

  constructor(@Inject() private readonly requestService: RequestService) {}

  public async getAgreementVersion(): PromiseResult<
    AgreementVersionEntity,
    GetAgreementVersionErrorInstance
  > {
    const url = `${this.userServiceUrl}/dsr_agreements/info`

    try {
      const response =
        await this.requestService.secured.get<GetAgreementVersionResponse>(url)

      if (response.ok) {
        return resultOk(
          AgreementVersionMapper.toAgreementVersionEntity(response.json)
        )
      }

      return resultErr(
        GetAgreementVersionErrorMapper.toErrors(response.status, response.json)
      )
    } catch (err: unknown) {
      return resultErr(new GetAgreementVersionErrors.UnexpectedError(err))
    }
  }

  public async acceptAgreement(): PromiseResult<
    unknown,
    AcceptAgreementErrorInstance
  > {
    const url = `${this.userServiceUrl}/dsr_agreements`

    try {
      const response =
        await this.requestService.secured.post<AcceptAgreementResponse>(url, {
          body: AcceptAgreementRequestMapper.toRequestBody(),
        })

      if (response.ok) {
        return resultOk(undefined)
      }

      return resultErr(
        AcceptAgreementErrorMapper.toErrors(response.status, response.json)
      )
    } catch (err: unknown) {
      return resultErr(new AcceptAgreementErrors.UnexpectedError(err))
    }
  }
}
