import { Action, Inject } from '@nori/di'

import { NotesService } from './notes.service'
import { NotesStore } from './notes.store'

import type { NoteEntity } from '../core/note.entity'

@Action()
export class NotesAction {
  constructor(
    @Inject() private readonly notesService: NotesService,
    @Inject() private readonly notesStore: NotesStore
  ) {}

  async handleCreateNote(contactId: number): Promise<void> {
    await this.notesService.handleCreateNote(contactId)
  }

  async handleUpdateNote(contactId: number): Promise<void> {
    await this.notesService.handleUpdateNote(contactId)
  }

  async handleDeleteNote(contactId: number, noteId: number): Promise<void> {
    await this.notesService.handleDeleteNote(contactId, noteId)
  }

  handleShowEditMode(contactId: number, note: NoteEntity): void {
    this.notesStore.setContent(contactId, note.content)
    this.notesStore.setEditedNote(contactId, note)
  }

  handleHideEditMode(contactId: number): void {
    this.notesStore.setContent(contactId, '')
    this.notesStore.setEditedNote(contactId, undefined)
  }

  handleChangeContent(contactId: number, value: string): void {
    this.notesStore.setContent(contactId, value)
  }
}
