import type { FailedValidationValueObject } from '../../core/failed-validation.value-object'

export class ValidationMapper {
  static toValidationErrors(data: any): FailedValidationValueObject {
    return {
      email: data.errors['email']?.[0],
      firstName: data.errors['first_name']?.[0],
      lastName: data.errors['last_name']?.[0],
      phone: data.errors['phone']?.[0],
      customerId: data.errors['customer_id']?.[0],
      address1: data.errors['address.address1']?.[0],
      address2: data.errors['address.address2']?.[0],
      city: data.errors['address.city']?.[0],
      country: data.errors['address.country']?.[0],
      state: data.errors['address.state']?.[0],
      zipCode: data.errors['address.zip_code']?.[0],
    }
  }
}
