import { DataState, Store } from '@nori/di'

import type { MoneyValueObject } from '@nori/app-kit'
import type { ArchivedOrderErrorInstance } from '../../core'
import type { ArchivedOrderValueObject } from '../../core/archived-order.value-object'

@Store()
export class ArchivedOrderStore {
  order = new DataState<ArchivedOrderValueObject, ArchivedOrderErrorInstance>({
    isLoading: false,
  })

  get isPersonalOrder(): boolean {
    return this.order.data?.typeCode === 'dsr'
  }

  get totalQuantity(): number | undefined {
    return this.order.data?.items.reduce((acc, item) => {
      return acc + item.quantity
    }, 0)
  }

  get isTrackingLink(): boolean {
    return !!this.order.data?.shipments?.some((link) => link.trackingUrl)
  }

  get totalPriceAfterProductCredits(): MoneyValueObject | undefined {
    if (!this.order.data) return undefined

    const { eligibleItemTotal, productCreditsTotal } = this.order.data

    if (!eligibleItemTotal || !productCreditsTotal) return undefined

    return {
      currency: this.order.data.currency,
      cents: eligibleItemTotal.cents - productCreditsTotal.cents,
    }
  }

  get errorTitle(): string {
    if (!this.order.error) return ''

    switch (true) {
      default:
        return 'Sorry. Something went wrong.'
    }
  }
}
