import { HistoryService } from '@nori/app-kit'
import { Action, Inject } from '@nori/di'
import { getBlock } from '@nori/lang-i18n'
import { NotifyAction } from '@nori/notify'
import { isErr } from '@nori/result'
import { FailedValidationError } from '@nori/validator'
import { createLogger } from '~/infra/create-logger'
import { Routes } from '~/modules/routes'
import { SubscriptionService } from '~/modules/subscription'

import { AutosaveService } from '../service/autosave.service'
import { SubscriptionsStore } from '../store/subscriptions.store'

import type { AddressValueObject } from '@nori/app-kit'
import type { UpdateSubscriptionsInput } from '~/modules/subscription/infra/types/update-subscriptions/update-subscriptions.input'
import type { AutosaveTabsLabelValueObject } from '../../core/autosave-tabs-label.value-object'
import type { UpdateShippingAddressInput } from '../../infra/types/update-shipping-address.input'

const logger = createLogger('subscriptions.action')

@Action()
export class SubscriptionsAction {
  constructor(
    @Inject() private readonly subscriptionsStore: SubscriptionsStore,
    @Inject() private readonly subscriptionService: SubscriptionService,
    @Inject() private readonly autosaveService: AutosaveService,
    @Inject() private readonly notifyAction: NotifyAction,
    @Inject() private readonly historyService: HistoryService
  ) {}

  async handleInitialLoad(userId?: number): Promise<void> {
    this.subscriptionsStore.subscriptions.setIsLoading(true)

    await this.autosaveService.loadSubscriptions(userId)

    this.subscriptionsStore.subscriptions.setIsLoading(false)
  }

  async handleSaveShipping(input: UpdateShippingAddressInput): Promise<void> {
    this.subscriptionsStore.subscriptions.setIsLoading(true)

    await this.saveShipping(input)

    this.subscriptionsStore.subscriptions.setIsLoading(false)
  }

  async handleUpdateSubscription(
    input: UpdateSubscriptionsInput
  ): Promise<void> {
    this.subscriptionsStore.subscriptions.setIsLoading(true)

    await this.updateSubscription(input)

    this.subscriptionsStore.subscriptions.setIsLoading(false)
  }

  async handleUpdateStatus({
    id,
    userId,
    activeStatus,
  }: {
    id?: number
    userId?: number
    activeStatus: boolean
  }): Promise<void> {
    if (!id) return

    this.subscriptionsStore.subscriptions.setIsLoading(true)

    await this.updateSubscriptionStatus({ id, userId, activeStatus })

    this.subscriptionsStore.subscriptions.setIsLoading(false)
  }

  handleTabChange(
    tab: AutosaveTabsLabelValueObject,
    isContactDetailsPage?: boolean,
    userId?: number
  ): void {
    if (isContactDetailsPage) {
      this.historyService.push(
        Routes.contacts.contactDetails.path({
          id: Number(userId),
          tab: tab,
        })
      )
    } else {
      const url =
        tab === 'next-order'
          ? Routes.settings.nextOrder.path()
          : Routes.settings.autosave.path()
      this.historyService.push(url)
    }
  }

  private fillShipping(address?: AddressValueObject): void {
    if (!address) return
  }

  async handleSkipSubscription({
    id,
    userId,
  }: {
    id?: number
    userId?: number
  }): Promise<void> {
    this.subscriptionsStore.subscriptions.setIsLoading(true)

    await this.skipSubscription({ id, userId })

    this.subscriptionsStore.subscriptions.setIsLoading(false)
  }

  private async updateSubscriptionStatus({
    id,
    userId,
    activeStatus,
  }: {
    id: number
    userId?: number
    activeStatus: boolean
  }): Promise<void> {
    this.subscriptionsStore.subscriptions.setError(undefined)
    if (!id) return

    const result = await this.subscriptionService.updateSubscriptionStatus(
      id,
      activeStatus
    )
    if (isErr(result)) {
      logger.error(result.error.key, result.error)
      this.subscriptionsStore.subscriptions.setError(result.error)
      return
    }

    await this.autosaveService.loadSubscriptions(userId)
  }

  private async updateSubscription(
    input: UpdateSubscriptionsInput
  ): Promise<void> {
    this.subscriptionsStore.subscriptions.setError(undefined)
    if (!input.ids) return

    const result = await this.subscriptionService.updateSubscriptions(input)

    if (isErr(result)) {
      logger.error(result.error.key, result.error)
      this.subscriptionsStore.subscriptions.setError(result.error)
      return
    }

    if (result.data.type === 'validationFailed') return

    await this.autosaveService.loadSubscriptions(input.userId)
  }

  private async saveShipping(input: UpdateShippingAddressInput): Promise<void> {
    if (!input.id || !input.shippingAddress) return

    const validationResult = this.subscriptionsStore.validateAddress()

    if (!validationResult.isValid) return

    const result = await this.subscriptionService.updateSubscriptions({
      ids: [input.id],
      shippingAddress: {
        address1: validationResult.address.address1,
        address2: validationResult.address.address2,
        city: this.subscriptionsStore.shippingAddressConfig
          .shippingAddressValidator.values.city,
        country: validationResult.address.country,
        state: validationResult.address.state,
        zipCode: validationResult.address.zipCode,
        firstName: input.shippingAddress.firstName,
        lastName: input.shippingAddress.lastName,
        phoneNumber: validationResult.address.phoneNumber,
      },
    })
    if (isErr(result)) {
      logger.error(result.error.key, result.error)
      this.notifyAction.handleAddNotify({
        type: 'danger',
        title: getBlock('autosaveSettings.errors')('saveShippingError'),
      })
      return
    }

    if (result.data.type === 'validationFailed') {
      FailedValidationError.handleValidatorErrors({
        errors: result.data.data,
        validator:
          this.subscriptionsStore.shippingAddressConfig
            .shippingAddressValidator,
      })
      return
    }

    await this.autosaveService.loadSubscriptions(input.userId)
  }

  private async skipSubscription({
    id,
    userId,
  }: {
    id?: number
    userId?: number
  }): Promise<void> {
    this.subscriptionsStore.subscriptions.setError(undefined)
    if (!id) return

    const result = await this.subscriptionService.skipSubscription(id)

    if (isErr(result)) {
      logger.error(result.error.key, result.error)
      this.notifyAction.handleAddNotify({
        type: 'danger',
        title: getBlock('autosaveSettings.errors')('skipSubscriptionError'),
      })
      return
    }

    await this.autosaveService.loadSubscriptions(userId)
  }
}
