import { Routes } from '~/modules/routes'

export const UNAUTHORIZED_ROUTES = [
  Routes.auth.signIn.path(),
  Routes.auth.signUp.path(),
  Routes.auth.forgotPassword.path(),
  Routes.auth.resetPassword.path(),
  Routes.version.path(),
  Routes.auth.enrollment.path(),
  Routes.auth.enrollmentConfirmation.path(),
]
