import { errorFactory } from '@nori/errors'

import type { KeyOfErrors } from '@nori/errors'

export const { PendingOrdersErrors } = errorFactory('PendingOrdersErrors', {
  PendingOrdersUnknownError: 'Error while getting pending orders',
  UnexpectedPendingOrdersError: 'Unexpected error while getting pending orders',
})

export type PendingOrdersErrorInstance = InstanceType<
  KeyOfErrors<typeof PendingOrdersErrors>
>
