import { Inject, Service } from '@nori/di'

import { SkuAdapter } from '../../infra/sku.adapter'

import type { PromiseResult } from '@nori/result'
import type { SkuEntity, SkuErrorInstance } from '~/modules/sku/core'

@Service()
export class SkuService {
  constructor(@Inject() private readonly skuAdapter: SkuAdapter) {}

  async getSkus(codes: string[]): PromiseResult<SkuEntity[], SkuErrorInstance> {
    return this.skuAdapter.getSkus(codes)
  }
}
