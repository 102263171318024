import { centsToMainUnit } from '@nori/app-kit'
import { Action, Inject, observe } from '@nori/di'
import { CartStore } from '~/modules/cart/interface/store/cart-store/cart.store'

import { BillingFormSplitPaymentStore } from '../store/billing-form-split-payment.store'

import type { CreditCardPriorityValueObject } from '../../core'

const NUMBER_OF_CREDIT_CARDS = 2
const MIN_SPLIT_PAYMENT_AMOUNT = 1

@Action()
export class BillingFormSplitPaymentAction {
  constructor(
    @Inject()
    private readonly billingFormSplitPaymentStore: BillingFormSplitPaymentStore,
    @Inject() private readonly cartStore: CartStore
  ) {
    observe(this.cartStore, 'cart', (cart) => {
      this.handleCheckoutSplitTotal(cart.newValue?.total?.cents)
    })
  }

  handleAmountBlur(card: CreditCardPriorityValueObject): void {
    this.handleSplitCardAmount(card)
  }

  handleAmountChange(card: CreditCardPriorityValueObject, value: number): void {
    this.billingFormSplitPaymentStore.setCardAmount({
      type: card,
      cents: value,
    })
  }

  private handleCheckoutSplitTotal(total?: number): void {
    if (!total) return

    const secondAmount = Math.floor(total / NUMBER_OF_CREDIT_CARDS)
    const firstAmount = total - secondAmount

    this.billingFormSplitPaymentStore.setCardAmount({
      type: 'main',
      cents: firstAmount,
    })
    this.billingFormSplitPaymentStore.setCardAmount({
      type: 'second',
      cents: secondAmount,
    })
  }

  private handleSplitCardAmount(card: CreditCardPriorityValueObject): void {
    if (!this.cartStore.cart?.total) return

    const result = this.calculateSplitCardsAmounts({
      amount: this.billingFormSplitPaymentStore.cardAmountMap[card],
      total: this.cartStore.cart?.total?.cents,
    })

    const secondAmountCard = card === 'main' ? 'second' : 'main'

    this.billingFormSplitPaymentStore.setCardAmount({
      type: card,
      cents: result.first,
    })
    this.billingFormSplitPaymentStore.setCardAmount({
      type: secondAmountCard,
      cents: result.second,
    })
  }

  private calculateSplitCardsAmounts({
    amount,
    total,
  }: {
    amount: number
    total: number
  }): {
    first: number
    second: number
  } {
    if (amount < MIN_SPLIT_PAYMENT_AMOUNT) {
      return {
        first: Math.round(MIN_SPLIT_PAYMENT_AMOUNT),
        second: Math.round(total - MIN_SPLIT_PAYMENT_AMOUNT),
      }
    }

    if (amount > total) {
      return {
        first: Math.round(total - MIN_SPLIT_PAYMENT_AMOUNT),
        second: Math.round(MIN_SPLIT_PAYMENT_AMOUNT),
      }
    }

    return {
      first: Math.round(amount),
      second: Math.round(total - amount),
    }
  }
}
