import { Store } from '@nori/di'

import type { CartItemEntity, MoneyValueObject } from '@nori/app-kit'
import type { ContactDetailsEntity } from '~/modules/contacts'
import type { OrderEntity } from '../../core/order.entity'
import type { OrderErrorInstance } from '../../core/order.errors'

@Store()
export class OrderStore {
  isLoading = false
  order?: OrderEntity = undefined
  referrerContact?: ContactDetailsEntity = undefined
  error?: OrderErrorInstance = undefined

  get hostessDiscountPercentage(): number | undefined {
    // TODO: request BE to populate cart response with hostessDiscountPercentage
    return this.order?.items.find((item) => item.hostessDiscountPercentage)
      ?.hostessDiscountPercentage
  }

  get isFreeShipping(): boolean {
    const shippingCost = this.order?.shippingTotal?.cents ?? 0
    return shippingCost === 0
  }

  get isPersonalOrder(): boolean {
    return this.order?.typeCode === 'dsr'
  }

  get totalQuantity(): number {
    return (
      this.order?.items.reduce((total, item) => {
        return total + item.quantity
      }, 0) ?? 0
    )
  }

  get itemsEligibleForProductCredits(): CartItemEntity[] {
    if (!this.order) return []

    return this.order.items.filter(
      (item): item is CartItemEntity =>
        !item.disallowProductCredits && item.type === 'sku'
    )
  }

  get itemsNotEligibleForProductCredits(): CartItemEntity[] {
    if (!this.order) return []

    return this.order.items.filter(
      (item): item is CartItemEntity =>
        item.disallowProductCredits && item.type === 'sku'
    )
  }

  get itemsNotEligibleForProductCreditsTotal(): MoneyValueObject | undefined {
    if (!this.order) return undefined

    return this.itemsNotEligibleForProductCredits.reduce(
      (total, item) => {
        if (!item.productCredits) return total

        return {
          ...total,
          cents: total.cents + Number(item.productCredits.cents),
        }
      },
      { currency: this.order.currency, cents: 0 }
    )
  }

  get totalPriceAfterProductCredits(): MoneyValueObject | undefined {
    if (!this.order) return undefined

    const { eligibleItemTotal, productCreditsTotal } = this.order

    if (!eligibleItemTotal || !productCreditsTotal) return undefined

    return {
      currency: this.order.currency,
      cents: eligibleItemTotal.cents - productCreditsTotal.cents,
    }
  }

  get errorTitle(): string {
    if (!this.error) return ''

    switch (true) {
      default:
        return 'Sorry. Something went wrong.'
    }
  }

  getProductTotalPrice(product: CartItemEntity): MoneyValueObject | undefined {
    if (!this.order) return undefined

    if (product.totalPrice === 0) {
      const cents = product.retailPrice?.cents ?? 0

      return {
        currency: this.order.currency,
        cents,
      }
    }

    return { currency: this.order.currency, cents: product.totalPrice }
  }

  setIsLoading(value: boolean): void {
    this.isLoading = value
  }

  setOrder(value?: OrderEntity): void {
    this.order = value
  }

  setReferrerContact(value?: ContactDetailsEntity): void {
    this.referrerContact = value
  }

  setError(value?: OrderErrorInstance): void {
    this.error = value
  }
}
