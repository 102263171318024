import { observer } from '@nori/di'
import { useRootAppStore } from '~/modules/root-app/interface/use-root-app-store'

import { AuthenticatedHeader } from './authenticated-header'
import { UnauthenticatedHeader } from './unauthenticated-header'

export const Header = observer(() => {
  const { rootAppStore } = useRootAppStore()

  if (rootAppStore.appStartSettings.value?.isHeaderVisible === false)
    return <></>

  if (rootAppStore.isDataLoaded) return <AuthenticatedHeader />
  return <UnauthenticatedHeader />
})
