import { createAddressValidator } from '@nori/app-kit'
import { Store } from '@nori/di'

import { createContactValidator } from '../create-contact-validator'

import type {
  ContactDetailsEntity,
  ContactErrorInstance,
} from '~/modules/contacts/core'

@Store()
export class EditContactStore {
  isLoading = false
  isSaving = false
  isDeleting = false
  isAvailableToEdit = true
  error?: ContactErrorInstance = undefined
  contact?: ContactDetailsEntity = undefined

  contactValidator = createContactValidator()
  addressValidator = createAddressValidator()

  get isAvailableToDelete(): boolean {
    return !this.contact?.hasBeenHostess && !this.contact?.isMyself
  }

  get validationErrors(): { field: string; error: string }[] {
    return [this.contactValidator.errors, this.addressValidator.errors].flatMap(
      (error) =>
        Object.entries(error).map((error) => ({
          field: error[0],
          error: error[1],
        }))
    )
  }

  setContact(contact: ContactDetailsEntity): void {
    this.contact = contact
  }

  setError(error?: ContactErrorInstance): void {
    this.error = error
  }

  setIsLoading(value: boolean): void {
    this.isLoading = value
  }

  setIsSaving(value: boolean): void {
    this.isSaving = value
  }

  setIsDeleting(value: boolean): void {
    this.isDeleting = value
  }

  setIsAvailableToEdit(value: boolean): void {
    this.isAvailableToEdit = value
  }
}
