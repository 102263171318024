import type { UserCreditsEntity } from '../../core'

export class UserCreditsMapper {
  static toUserCreditsEntity(data: any): UserCreditsEntity {
    return {
      storeCredits: data.store_credits,
      hostessCredits: data.hostess_credits,
      hostessDiscountsHash: {
        count: data.hostess_discounts_hash.count,
        percentage: data.hostess_discounts_hash.percentage,
      },
      productCredits: data.product_credits,
      promotionalCredits: data.promotional_credits,
      combinedCredits: data.combined_credits,
      loyaltySavingsCents: data.loyalty_savings_cents,
      currency: data.currency,
      otherStoresWithHostessRewards: data.other_stores_with_hostess_rewards,
      accountBalance: data.earnings,
    }
  }
}
