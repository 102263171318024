import { useCallback, useMemo, useState } from 'react'

import { observer } from '@nori/di'
import { useToggle } from '@nori/ui-kit'
import { AutoSaveOrderActionsEnum } from '~/modules/autosave/core/autosave-order-actions.enum'
import { isSingleProduct } from '~/modules/autosave/interface/is-single-product'
import { useAutosaveContext } from '~/modules/autosave/interface/use-autosave-context'

import { AutoSaveOrderRow } from '../../autosave-order-row/autosave-order-row'
import { AutoSaveOrderRowActions } from '../../autosave-order-row/autosave-order-row-actions'
import { AutoSaveUnavailableMessage } from '../../autosave-order-row/autosave-unavailable-message'

import type { SubscriptionEntity } from '~/modules/subscription'
import type { ComponentProps } from 'react'

type Props = {
  subscription: SubscriptionEntity
  isContactDetailsPage?: boolean
  onChangeStatus: (skip?: boolean) => void
}

export const NextOrderAutoSaveItem = observer(
  ({ subscription, onChangeStatus, isContactDetailsPage }: Props) => {
    const { nextOrderStore, nextOrderAction } = useAutosaveContext()

    const [itemQuantity, setItemQuantity] = useState(`${subscription.quantity}`)
    const [itemDate, setItemDate] = useState(subscription.nextCartAt)
    const [itemCycleId, setItemCycleId] = useState(subscription.cycleId)
    const editorToggle = useToggle()

    const productInfo = useMemo(
      () => nextOrderStore.getSubscriptionProductInfo(subscription),
      [nextOrderStore, subscription]
    )

    const styleName = useMemo(
      () =>
        productInfo && isSingleProduct(productInfo)
          ? productInfo.styles.find((style) =>
              style.skus.find((sku) => sku.code === subscription.skuCode)
            )?.name
          : undefined,
      [productInfo, subscription.skuCode]
    )

    const skuInfo = useMemo(() => {
      if (!productInfo) return
      if (!('styles' in productInfo)) return

      return productInfo.styles
        .map((style) => style.skus)
        .flat()
        .find((sku) => sku.code === subscription.skuCode)
    }, [productInfo, subscription.skuCode])

    const isAvailable =
      skuInfo?.available &&
      productInfo?.canBeAutoshipped &&
      productInfo?.available

    const handleSave = useCallback(() => {
      editorToggle.handleClose()
      nextOrderAction.handleUpdateSubscription({
        ids: [subscription.id],
        quantity: Number(itemQuantity),
        nextCartAt: itemDate,
        cycleId: itemCycleId,
      })
    }, [
      editorToggle,
      nextOrderAction,
      subscription.id,
      itemQuantity,
      itemDate,
      itemCycleId,
    ])

    const cycleName = useMemo(
      () =>
        productInfo?.productSubscriptions.find(
          (item) => item.id === itemCycleId
        )?.name,
      [productInfo?.productSubscriptions, itemCycleId]
    )

    const actionsSlotProps = useMemo<
      ComponentProps<typeof AutoSaveOrderRowActions>
    >(() => {
      if (!productInfo?.available || !subscription.active) {
        return {
          availableActions: AutoSaveOrderActionsEnum.None,
        }
      }

      if (!productInfo?.canBeAutoshipped) {
        return {
          availableActions: AutoSaveOrderActionsEnum.CancelOnly,
          handleChangeStatus: onChangeStatus,
        }
      }

      return {
        availableActions: AutoSaveOrderActionsEnum.EditSkip,
        handleChangeStatus: onChangeStatus,
        isEditMode: editorToggle.isOpen,
        handleEditMode: editorToggle.handleOpen,
        canBeSkipped: subscription.canBeSkipped,
      }
    }, [
      editorToggle.handleOpen,
      editorToggle.isOpen,
      onChangeStatus,
      productInfo?.available,
      subscription.active,
      subscription.canBeSkipped,
      productInfo?.canBeAutoshipped,
    ])

    return (
      <AutoSaveOrderRow
        subscription={subscription}
        productInfo={productInfo}
        itemQty={itemQuantity}
        itemDate={itemDate}
        onItemQtyChange={setItemQuantity}
        onItemCycleIdChange={setItemCycleId}
        onDateChange={setItemDate}
        handleSave={handleSave}
        styleName={styleName}
        isContactDetailsPage={isContactDetailsPage}
        cycleName={cycleName}
        retailPrice={subscription.retailPrice}
        subscriptionPrice={subscription.subscriptionPrice}
        price={subscription.price}
        isEditMode={editorToggle.isOpen}
        actionsSlot={<AutoSaveOrderRowActions {...actionsSlotProps} />}
        isAvailable={isAvailable}
      />
    )
  }
)
