import {
  getColumnVisibility,
  getTableCount,
  LocalStorageItem,
} from '@nori/app-kit'
import { DataState, Store } from '@nori/di'
import { useFeatureFlagsContext } from '~/modules/feature-flags/use-feature-flags'

import { contactListTableCellsKeys } from '../contact-list-table-cells-key'

import type {
  PaginationValueObject,
  TableCountValueObject,
} from '@nori/app-kit'
import type { DomainError } from '@nori/errors'
import type { SortDirectionValueObject } from '~/core'
import type {
  ContactErrorInstance,
  ContactListItemEntity,
  ContactPerksValueObject,
} from '~/modules/contacts/core'
import type { ContactListColumnNamesValueObject } from '../../core/contact-list-column-names.value-object'
import type { ContactListColumnsSelectionMapValueObject } from '../../core/contact-list-columns-selection-map.value-object'
import type { ContactSort } from '../../core/contact-sort'

const LS_COLUMNS_ID_KEY = '@nori/contact-list-columns'
type LocalStorageValue = Record<ContactListColumnNamesValueObject, boolean>

@Store()
export class ContactsStore {
  isLoading = false
  error?: ContactErrorInstance = undefined

  searchQuery = ''

  contactsList?: ContactListItemEntity[] = undefined
  pagination?: PaginationValueObject = undefined

  sortBy: ContactSort = 'first_name'
  sortDirection: SortDirectionValueObject = 'asc'

  searchFilter = 'customer_name_email_address' as const

  selectedContactIds: Set<number> = new Set()
  abortController: AbortController = new AbortController()
  contactPerksById = new Map<
    number,
    DataState<ContactPerksValueObject, DomainError>
  >()
  multipleLanguageSupportEnabled =
    useFeatureFlagsContext().featureFlagsStore.getFlag(
      'bo-multiple-language-support-enabled'
    )

  private selectedColumns = LocalStorageItem<Partial<LocalStorageValue>>(
    LS_COLUMNS_ID_KEY,
    {
      initialValue: {
        // eslint-disable-next-line camelcase
        norwex_credits: false,
        // eslint-disable-next-line camelcase
        item_discount: false,
        // eslint-disable-next-line camelcase
        has_free_shipping: false,
        // eslint-disable-next-line camelcase
        preferred_language: false,
      },
    }
  )

  getColVisibility(key: ContactListColumnNamesValueObject): boolean {
    return getColumnVisibility(
      this.selectedColumns,
      key,
      contactListTableCellsKeys
    )
  }

  abortPreviousRequest(): void {
    this.abortController.abort()
    this.abortController = new AbortController()
  }

  get availableColumns(): ContactListColumnsSelectionMapValueObject {
    return contactListTableCellsKeys
      .filter((cell) => {
        return (
          this.multipleLanguageSupportEnabled ||
          cell.id !== 'preferred_language'
        )
      })
      .reduce((acc, cell) => {
        return {
          ...acc,
          [cell.id]: {
            translationKey: cell.translationKey,
            isChecked: this.getColVisibility(cell.id),
            isDisabled: cell.isDisabled,
          },
        }
      }, {} as ContactListColumnsSelectionMapValueObject)
  }

  get visibleColumns() {
    return contactListTableCellsKeys.filter((cell) => {
      if (!this.availableColumns[cell.id]) return false
      if (!this.getColVisibility(cell.id)) return false
      return true
    })
  }

  applyColumnsVisibility(
    value: ContactListColumnsSelectionMapValueObject
  ): void {
    const keys = Object.keys(value) as ContactListColumnNamesValueObject[]
    const visibilityMap = keys.reduce((acc, key) => {
      return {
        ...acc,
        [key]: value[key].isChecked,
      }
    }, {} as LocalStorageValue)

    this.selectedColumns.set(visibilityMap)
  }

  getSelectedSort(type?: ContactSort): SortDirectionValueObject | undefined {
    if (type === this.sortBy) {
      return this.sortDirection
    }
  }

  get selectedContactEmails(): string[] {
    if (!this.contactsList) return []

    const emails: string[] = []
    for (const contact of this.contactsList) {
      if (this.selectedContactIds.has(contact.id)) {
        emails.push(contact.email)
      }
    }

    return emails
  }

  get isAllContactsSelected(): boolean {
    if (!this.contactsList) return false
    return this.selectedContactIds.size === this.contactsList.length
  }

  get tableCount(): TableCountValueObject {
    return getTableCount(this.pagination)
  }

  setSortType(type: ContactSort): void {
    this.sortBy = type
  }

  setSortDirection(direction: SortDirectionValueObject): void {
    this.sortDirection = direction
  }

  toggleSelectedContactId(id: number): void {
    if (!this.selectedContactIds.has(id)) {
      this.selectedContactIds.add(id)
      return
    }

    this.selectedContactIds.delete(id)
  }

  setSelectedContactIds(ids: Iterable<number>): void {
    this.selectedContactIds = new Set(ids)
  }

  setContacts(contacts: ContactListItemEntity[]): void {
    this.contactsList = contacts
  }

  setPagination(pagination: PaginationValueObject): void {
    this.pagination = pagination
  }

  setSearchQuery(value: string): void {
    this.searchQuery = value
  }

  setIsLoading(value: boolean): void {
    this.isLoading = value
  }

  setError(value?: ContactErrorInstance): void {
    this.error = value
  }

  resetFilters(): void {
    this.setSearchQuery('')
    this.setSelectedContactIds([])
  }

  getOrCreateContactPerks(
    id: number
  ): DataState<ContactPerksValueObject, DomainError>
  getOrCreateContactPerks(
    id?: number
  ): DataState<ContactPerksValueObject, DomainError> | undefined
  getOrCreateContactPerks(
    id?: number
  ): DataState<ContactPerksValueObject, DomainError> | undefined {
    if (!id) return
    return this.contactPerksById.get(id) || new DataState()
  }

  setContactPerks(
    id: number,
    value: DataState<ContactPerksValueObject, DomainError>
  ): void {
    this.contactPerksById.set(id, value)
  }
}
