import { errorFactory } from '@nori/errors'

import type { KeyOfErrors } from '@nori/errors'

export const { ProductCartErrors } = errorFactory('ProductCartErrors', {
  BadRequest: 'Remove from cart. Bad Request',
  UnexpectedError: 'Unexpected Error',

  RemoveBundleBadRequest: 'Remove bundle from cart. Bad Request',
  RemoveBundleUnexpectedError: 'Unexpected Error',

  SwitchBadRequest: 'Switch from cart. Bad Request',
  SwitchUnexpectedError: 'Unexpected Error',
  SwitchUnprocessableEntity: 'Unprocessable Entity',
})

export type ProductCartErrorsInstance = InstanceType<
  KeyOfErrors<typeof ProductCartErrors>
>
