import { useMemo } from 'react'

import { getSkuName } from '@nori/app-kit'
import { useTranslations } from '@nori/lang-i18n'

type Props = {
  productName: string
  styleName?: string
  skuVariation?: string
  skuName?: string
  skuCode?: string
  itemQuantity?: number
}

export function ProductItemNameMobile({
  productName,
  styleName,
  skuVariation,
  skuName,
  skuCode,
  itemQuantity,
}: Props) {
  const t = useTranslations('cart.productList')
  const subtitle = useMemo(() => {
    return [
      getSkuName({
        variation: skuVariation,
        name: skuName,
      }),
      skuCode,
    ]
      .filter(Boolean)
      .join(', ')
  }, [skuVariation, skuName, skuCode])

  return (
    <div data-test-id="item-name" className="flex flex-col gap-1">
      {productName && (
        <div className="text-base font-bold text-gray-700">{productName}</div>
      )}
      {styleName && (
        <div className="text-sm font-normal capitalize text-primary-900">
          {styleName}
        </div>
      )}
      {subtitle && (
        <div className="text-sm font-normal text-primary-900">{subtitle}</div>
      )}
      {itemQuantity && (
        <div className="text-sm font-normal text-primary-900">
          {t('quantitySmall')}: {itemQuantity}
        </div>
      )}
    </div>
  )
}
