import { UserStore } from '@nori/app-kit'
import { Action, Inject } from '@nori/di'
import { getBlock } from '@nori/lang-i18n'
import { NotifyAction } from '@nori/notify'
import { AddToCartService } from '~/modules/cart/interface/services/add-to-cart.service'
import { LoadCartService } from '~/modules/cart/interface/services/create-cart.service'
import { CartStore } from '~/modules/cart/interface/store/cart-store/cart.store'

import { WishListService } from '../service/wish-list.service'
import { WishListStore } from '../store/wish-list.store'

import type { WishListInput } from '../../core/input/wishlist.input'

const PAGE_START = 1
const PER_PAGE = 24

@Action()
export class WishListAction {
  constructor(
    @Inject() private readonly wishListStore: WishListStore,
    @Inject() private readonly wishListService: WishListService,
    @Inject() private readonly loadCartService: LoadCartService,
    @Inject() private readonly addToCartService: AddToCartService,
    @Inject() private readonly cartStore: CartStore,
    @Inject() private readonly notifyAction: NotifyAction,
    @Inject() private readonly userStore: UserStore
  ) {}

  async handleInitialLoad(): Promise<void> {
    this.wishListStore.wishlist.setIsLoading(true)

    this.wishListService.dropWishListStore()
    await this.wishListService.loadWishList({
      page: PAGE_START,
      perPage: PER_PAGE,
      customerCode: undefined,
    })
    this.wishListStore.setWishlistShareLinkObject({
      referrerPersonalLink: this.userStore.currentUser.pwsUrlSuffix,
      urlCode: this.userStore.currentUser.urlCode,
    })

    this.wishListStore.wishlist.setIsLoading(false)
  }

  async handleLoadMore(nextPage: number): Promise<void> {
    if (!this.wishListStore.wishlist.data) return

    this.wishListStore.setIsLoadingMore(true)

    await this.wishListService.loadWishList({
      page: nextPage,
      perPage: this.wishListStore.wishlist.data.pagination.perPage,
      customerCode: undefined,
    })

    this.wishListStore.setIsLoadingMore(false)
  }

  async handleAddItemToWishList(skuId: number): Promise<void> {
    await this.wishListService.addItemToWishList({ skuId })

    await this.wishListService.reloadWishList()
  }

  async handleAddItemToBag(
    skuId: number,
    isFromWishList: boolean
  ): Promise<void> {
    this.wishListStore.setProcessingItem(skuId, true)
    this.wishListStore.setIsAddingToCart(true)

    if (isFromWishList && !this.cartStore.cart?.id) {
      await this.loadCartService.createCart({ typeCode: 'dsr' })
    }

    if (!this.cartStore.cart?.id) {
      this.wishListStore.setProcessingItem(skuId, false)
      this.wishListStore.setIsAddingToCart(false)
      return
    }

    await this.addToCartService.addStyleToCart({
      cartId: this.cartStore.cart.id,
      quantity: 1,
      skuId,
    })

    await this.loadCartService.loadCartById({})

    this.wishListStore.setProcessingItem(skuId, false)
    this.wishListStore.setIsAddingToCart(false)
    this.notifyAction.handleAddNotify({
      title: getBlock('wishList')('itemAddedSuccess'),
      type: 'success',
    })
  }

  async handleRemoveItemFromWishList(skuId: number): Promise<void> {
    await this.wishListService.removeItemFromWishList({ skuId })
    await this.wishListService.reloadWishList()
  }
}
