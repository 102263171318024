import { type PropsWithChildren, useState } from 'react'

import { observer } from '@nori/di'
import { useTranslations } from '@nori/lang-i18n'
import { Button, useToggle } from '@nori/ui-kit'
import { useAutosaveContext } from '~/modules/autosave/interface/use-autosave-context'
import { AddItemProductSubscription } from '~/modules/product-subscription/ui/add-item-product-subscription'

import type { CommonBundleEntity, CommonStyleEntity } from '@nori/app-kit'

export const Row = observer(
  ({
    item,
    subscriptionId,
    children,
  }: PropsWithChildren<{
    item: CommonStyleEntity | CommonBundleEntity
    subscriptionId: number
  }>) => {
    const t = useTranslations('basic')
    const { subscriptionsAddProductStore, subscriptionsAddProductAction } =
      useAutosaveContext()
    const subscriptionToggle = useToggle(item.canBeAutoShipped)
    const [selectedSubscriptionId, setSelectedSubscriptionId] = useState(
      item.defaultProductSubscription.id
    )
    return (
      <div className="border-b border-gray-200 py-6">
        <div className="flex justify-between">{children}</div>
        {!!item.canBeAutoShipped && (
          <div className="mt-6">
            <AddItemProductSubscription
              subscriptions={item.productSubscriptions}
              selectedSubscriptionId={selectedSubscriptionId}
              isSubscriptionSelected={subscriptionToggle.isOpen}
              onSubscriptionOptionChange={(id: number) =>
                setSelectedSubscriptionId(id)
              }
              onSubscriptionOpen={subscriptionToggle.handleOpen}
              onSubscriptionClose={subscriptionToggle.handleClose}
              itemId={item.id}
              isCanBeAutoshipped={item.canBeAutoShipped}
            />
          </div>
        )}
        <div className="mt-7">
          <Button
            onClick={() =>
              subscriptionsAddProductAction.handleAddProduct({
                product: item,
                subscriptionId: selectedSubscriptionId
                  ? selectedSubscriptionId
                  : subscriptionId,
                oneOff: !subscriptionToggle.isOpen,
              })
            }
            isLoading={!!subscriptionsAddProductStore.isLoadingById[item.id]}
            size="lg"
            rounded="unset"
            widthType="full"
          >
            {t('add')}
          </Button>
        </div>
      </div>
    )
  }
)
