import { getMoneyWithIsoCurrency } from '@nori/app-kit'
import { observer } from '@nori/di'
import { useTranslations } from '@nori/lang-i18n'
import { Divider } from '@nori/ui-kit'

import { useBundleContext } from '../interface/use-bundle.context'

import { ProductItem } from './product-item'

export const SummaryPageBundle = observer(() => {
  const t = useTranslations('productList.bundleModal')

  const { customizeBundleStore } = useBundleContext()

  const { selectedProducts } = customizeBundleStore

  return (
    <>
      <div className="mt-2 w-full text-center text-3xl font-bold text-primary-900">
        {t('review')}
      </div>
      <div className="mt-2 w-full text-center text-3xl font-bold uppercase text-primary-900">
        {t('subtotal')}
        {getMoneyWithIsoCurrency(customizeBundleStore.summaryPrice)}
      </div>
      {!!selectedProducts.length && (
        <>
          <div className="mt-7">
            <Divider />
          </div>
          <div className="mt-12 flex flex-wrap justify-around overflow-auto lg:max-h-[500px]">
            {selectedProducts.map((product) => {
              return (
                product.product.visible && (
                  <ProductItem
                    key={`${product.product.id}-${product.style.name}`}
                    product={product}
                  />
                )
              )
            })}
          </div>
        </>
      )}
    </>
  )
})
