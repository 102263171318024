import { useTranslations } from '@nori/lang-i18n'

export function SubscriptionTableHead() {
  const t = useTranslations(
    'contacts.list.table.extended.subscription.tableHead'
  )

  return (
    <thead className="hidden text-xs font-bold text-gray-500 lg:table-header-group">
      <tr>
        <th className="w-2/5 text-start">{t('item')}</th>
        <th className="w-1/5 text-end">{t('quantity')}</th>
        <th className="w-1/5 text-end">{t('autoshipAndSavePrice')}</th>
        <th className="w-1/5 text-end">{t('total')}</th>
      </tr>
    </thead>
  )
}
