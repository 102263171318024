import { getBaseUrl, RequestService, SERVICE_NAMES } from '@nori/app-kit'
import { Adapter, Inject } from '@nori/di'
import { resultErr, resultOk } from '@nori/result'

import { CartErrors } from '../core'

import { CartTotalMapper } from './mappers/cart-total.mapper'

import type { CheckoutTotalEntity } from '@nori/app-kit'
import type { PromiseResult } from '@nori/result'
import type { CartErrorInstance } from '../core'

@Adapter()
export class CartTotalAdapter {
  baseUrl = getBaseUrl(SERVICE_NAMES.CART_SERVICE)

  public constructor(
    @Inject() private readonly requestService: RequestService,
    @Inject() private readonly cartTotalMapper: CartTotalMapper
  ) {}

  async getCartTotal({
    cartId,
  }: {
    cartId: number
  }): PromiseResult<CheckoutTotalEntity, CartErrorInstance> {
    const url = `${this.baseUrl}/carts/${cartId}/total`

    try {
      const { ok, json, status } = await this.requestService.secured.get(url)

      if (ok) return resultOk(this.cartTotalMapper.toCheckoutTotalEntity(json))

      return resultErr(new CartErrors.UnknownCartTotalError(json))
    } catch (err: unknown) {
      return resultErr(new CartErrors.UnexpectedCartTotalError(err))
    }
  }
}
