import { HistoryService } from '@nori/app-kit'
import { Inject, Service } from '@nori/di'
import { getBlock } from '@nori/lang-i18n'
import { NotifyAction } from '@nori/notify'
import { isErr } from '@nori/result'
import { createLogger } from '~/infra/create-logger'
import { CartAdapter } from '~/modules/cart/infra/cart.adapter'
import { CartStore } from '~/modules/cart/interface/store/cart-store/cart.store'
import { OrderWizardService } from '~/modules/order-wizard/interface/service/order-wizard.service'
import { Routes } from '~/modules/routes'

import { CartErrors } from '../../core'

const logger = createLogger('drop-cart.service')

@Service()
export class DropCartService {
  constructor(
    @Inject() private readonly cartStore: CartStore,
    @Inject() private readonly cartAdapter: CartAdapter,
    @Inject() private readonly historyService: HistoryService,
    @Inject() private readonly notifyAction: NotifyAction,
    @Inject() private readonly orderWizardService: OrderWizardService
  ) {}

  async handleDropCart(): Promise<void> {
    if (!this.cartStore.cart) return
    const { isPersonalCart, cart } = this.cartStore

    const result = await this.cartAdapter.deleteCart(cart.id)
    if (isErr(result) && !(result.error instanceof CartErrors.NotFound)) {
      this.notifyAction.handleAddNotify({
        type: 'danger',
        title: getBlock('cart.errors')('dropCartFailed'),
      })
      logger.error(result.error.key, result.error)
      return
    }

    this.cartStore.dropCart()

    if (isPersonalCart) {
      await this.historyService.push(Routes.shop.isPersonal.path())
      return
    }

    await this.orderWizardService.initNewCustomerOrder()
  }
}
