import { observer } from '@nori/di'
import { Checkbox, TableCell, TableRow } from '@nori/ui-kit'

import { useContactManageMailingContext } from '../../interface/use-contact-manage-mailing-context'

import type { ContactEntity } from '../../core'

type Props = {
  contact: ContactEntity
}

export const ContactManageMailingModalTableRow = observer(
  ({ contact }: Props) => {
    const { сontactManageMailingStore, contactManageMailingAction } =
      useContactManageMailingContext()

    return (
      <TableRow>
        <TableCell noPadding fixedWidth={50}>
          <div className="flex flex-col items-center">
            <Checkbox
              onChange={(value) =>
                contactManageMailingAction.handleSelectContact({
                  id: contact.id,
                  value,
                })
              }
              value={
                сontactManageMailingStore.contactsToReceiveCatalog[
                  contact.id
                ] ?? contact.shouldReceivePrintCatalog
              }
            />
          </div>
        </TableCell>
        <TableCell
          noPadding
        >{`${contact.firstName} ${contact.lastName}`}</TableCell>
        <TableCell noPadding>{contact.email}</TableCell>
        <TableCell noPadding>{contact.phone}</TableCell>
        <TableCell noPadding>{`${contact.currentAddress.city ?? ' - '} ${
          contact.currentAddress.state ?? ' - '
        }`}</TableCell>
      </TableRow>
    )
  }
)
