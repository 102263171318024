import { useMemo } from 'react'

import type { CreditsInfoValueObject } from '@nori/app-kit'
import type { ContactDetailsEntity, ContactListItemEntity } from '../../core'

export function usePerksBlock({
  contact,
  credits,
  hasFreeShipping,
}: {
  contact: ContactListItemEntity | ContactDetailsEntity
  credits?: CreditsInfoValueObject
  hasFreeShipping?: boolean
}) {
  const sanitizedCredits = useMemo(() => {
    if (!credits) return null

    const contactType =
      ('customerType' in contact && contact.customerType) ||
      ('userType' in contact && contact.userType)

    switch (contactType) {
      case 'consultant':
        return {
          combinedCredits: 0,
          expiringHostessCredits: undefined,
          itemDiscounts: 0,
          itemDiscountsExpiringAt: undefined,
          storeCredits: 0,
          hasFreeShipping: false,
        } as const
      default:
        return {
          combinedCredits: credits.combinedCredits,
          expiringHostessCredits: credits.expiringHostessCredits
            ? {
                cents: credits.expiringHostessCredits.cents,
                expiringAt: credits.expiringHostessCredits.expiringAt,
              }
            : undefined,
          itemDiscounts: credits.hostessDiscountsHash.count,
          itemDiscountsExpiringAt: credits.expiringHostessDiscounts,
          storeCredits: credits.storeCredits,
          hasFreeShipping,
        } as const
    }
  }, [contact, credits, hasFreeShipping])

  return { sanitizedCredits }
}
