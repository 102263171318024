// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable camelcase */ import { loggerConfigManager } from '../config';
function getEnvironment() {
    if (typeof window === 'undefined') {
        return process.env.NODE_ENV;
    }
    if (!loggerConfigManager.loggerConfig.environment) {
        return window.location.host;
    }
    return loggerConfigManager.loggerConfig.environment;
}
const ROLLBAR_SOURCE_MAP_ENVIRONMENTS = [
    'production',
    'staging'
];
function getPayload() {
    const ENVIRONMENT = getEnvironment();
    if (!process.env.CI_COMMIT_SHA || !ENVIRONMENT || !ROLLBAR_SOURCE_MAP_ENVIRONMENTS.includes(ENVIRONMENT)) {
        return;
    }
    return {
        client: {
            javascript: {
                source_map_enabled: true,
                code_version: process.env.CI_COMMIT_SHA,
                guess_uncaught_frames: true
            }
        }
    };
}
export const ROLLBAR_CONFIG = {
    captureUncaught: true,
    captureUnhandledRejections: true,
    environment: getEnvironment(),
    payload: getPayload()
};
