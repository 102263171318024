import { AutosavePanelNextOrderDate } from './autosave-panel-next-order-date'

import type { PropsWithChildren } from 'react'

type Props = PropsWithChildren<{
  isContactDetailsPage?: boolean
}>

export const NextOrderContainer = ({
  isContactDetailsPage,
  children,
}: Props) => {
  return (
    <div className="flex flex-col">
      <div className="flex rounded-t-sm bg-primary-900 px-4 py-3 lg:px-7 lg:py-6">
        <AutosavePanelNextOrderDate
          isContactDetailsPage={isContactDetailsPage}
        />
      </div>
      <div className="rounded-b-sm border border-primary-900 p-3 lg:px-10 lg:py-7">
        {children}
      </div>
    </div>
  )
}
