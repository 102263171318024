import { useEffect } from 'react'

import { observer } from '@nori/di'
import { useContactContext } from '~/modules/contacts/interface/use-contact-context'

import { CreditHistory } from './credit-history/credit-history'
import { AvailableCredits } from './available-credits'
import { NorwexPerksLogo } from './norwex-perks-logo'
import { PerksSummary } from './perks-summary'
import { ReferralSummarySection } from './referral-summary-section'

export const NorwexPerks = observer(() => {
  const { contactDetailsStore, contactDetailsAction } = useContactContext()

  useEffect(() => {
    contactDetailsAction.handleLoadRewards()
  }, [contactDetailsAction])

  if (contactDetailsStore.isConsultant) return null
  return (
    <div>
      <div className="mt-5 flex w-full items-center justify-center">
        <NorwexPerksLogo />
      </div>

      <div className="mt-5 lg:mt-9">
        <PerksSummary />
      </div>

      <ReferralSummarySection />

      <div className="mt-5 lg:mt-9">
        <AvailableCredits />
      </div>

      <div className="mt-8">
        <CreditHistory />
      </div>

      <div className="mt-20" />
    </div>
  )
})
