import _extends from "@swc/helpers/src/_extends.mjs";
import Rollbar from 'rollbar';
import { loggerConfigManager } from '../config';
import { checkHostInBlacklist } from './blacklist-domains';
import { ROLLBAR_CONFIG } from './rollbar.config';
import { checkHostInWhitelist } from './whitelist-domains';
let rollbar;
export function createRollbarLogger() {
    const accessToken = loggerConfigManager.loggerConfig.rollbarAccessToken;
    if (checkHostInBlacklist()) return undefined;
    if (!checkHostInWhitelist()) return undefined;
    if (!accessToken) return undefined;
    if (!rollbar) {
        rollbar = new Rollbar(_extends({}, ROLLBAR_CONFIG, {
            accessToken
        }));
    }
    return rollbar;
}
