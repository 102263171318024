import { Adapter } from '@nori/di'

declare global {
  interface Window {
    dataLayer: any[]
  }
}

@Adapter()
export class GtmAdapter {
  public track<T extends { event: string }>(data: T): void {
    if (window.dataLayer) {
      window.dataLayer.push(data)
    }
  }
}
