import { RequestService } from '@nori/app-kit'
import { Adapter, Inject } from '@nori/di'
import { resultOk } from '@nori/result'
import { createLogger } from '~/infra/create-logger'

import type { ResultOk } from '@nori/result'

const DEFAULT_RUNTIME_ENVIRONMENT: NonNullable<
  NodeJS.ProcessEnv['NEXT_PUBLIC_ENVIRONMENT_CONFIG']
> = 'qaworkflows'

const logger = createLogger('root-app.adapter')

@Adapter()
export class RootAppAdapter {
  public constructor(
    @Inject() private readonly requestService: RequestService
  ) {}

  public async getRuntimeEnvironment(): Promise<
    ResultOk<{
      runtimeEnvironment: string
    }>
  > {
    try {
      const url = '/api/get-runtime-variables'

      const { ok, json } = await this.requestService.secured.get<{
        runtimeEnvironment: string
      }>(url)

      if (!ok) {
        return resultOk({ runtimeEnvironment: DEFAULT_RUNTIME_ENVIRONMENT })
      }

      return resultOk(json)
    } catch (err: unknown) {
      logger.error('There was en error fetching /api/runtime-environment', err)

      return resultOk({ runtimeEnvironment: DEFAULT_RUNTIME_ENVIRONMENT })
    }
  }
}
