import type { LangType } from '@nori/ui-kit'
import type { LinkWithChildren } from './header-link.type'

const RESOURCE_LINK_URLS: Record<LangType, string> = {
  'en-US': 'https://theresource.norwex.com/',
  'es-US': 'https://theresource.norwex.com/es_us/',
  'en-CA': 'https://theresource.norwex.com/en_ca/',
  'fr-CA': 'https://theresource.norwex.com/fr_ca/',
}

export function getResourceLinks(lang: LangType): LinkWithChildren {
  return {
    dataTestId: 'product-info-dropdown',
    translationKey: 'header.bottomNav.theResource',
    href: RESOURCE_LINK_URLS[lang],
  }
}
