import { useTranslations } from '@nori/lang-i18n'
import { Icon, Link } from '@nori/ui-kit'
import { useWishListContext } from '~/modules/wishlist/interface/use-wish-list.context'

export function ShareTooltipContent() {
  const t = useTranslations('wishList')
  const { wishListStore } = useWishListContext()
  const {
    wishlistShareMailUrl,
    wishlistShareTwitterUrl,
    wishlistSharePinterestUrl,
    wishlistShareFacebookUrl,
  } = wishListStore

  return (
    <div className="flex flex-col items-start gap-3.5 p-3.5">
      {/* https://norwex.atlassian.net/browse/NOOK-1210 */}
      {/* <Link target="_blank" isUnderline={false} href={wishlistShareFacebookUrl}>
        <div className="flex gap-1">
          <Icon name="FacebookOutline" />
          <span className="text-sm font-bold">{t('facebook')}</span>
        </div>
      </Link> */}
      <Link target="_blank" isUnderline={false} href={wishlistShareTwitterUrl}>
        <div className="flex gap-1">
          <Icon name="Twitter" />
          <span className="text-sm font-bold">{t('twitter')}</span>
        </div>
      </Link>
      <Link
        target="_blank"
        isUnderline={false}
        href={wishlistSharePinterestUrl}
      >
        <div className="flex gap-1">
          <Icon name="Pinterest" />
          <span className="text-sm font-bold">{t('pinterest')}</span>
        </div>
      </Link>
      <Link isUnderline={false} href={wishlistShareMailUrl}>
        <div className="flex gap-1">
          <Icon name="Mail" />
          <span className="text-sm font-bold">{t('mail')}</span>
        </div>
      </Link>
    </div>
  )
}
