import { getMoneyWithIsoCurrency } from '@nori/app-kit'
import { observer } from '@nori/di'
import { useTranslations } from '@nori/lang-i18n'
import { Button, Icon, Input, Link, Tooltip } from '@nori/ui-kit'
import { useCartContext } from '~/modules/cart'

import type { MoneyValueObject } from '@nori/app-kit'

type Props = {
  isShowApplyCredits?: boolean
  isLoading: boolean
  creditAmount?: MoneyValueObject
  appliedCredits?: MoneyValueObject
  applicableCredits?: MoneyValueObject
  creditInputValue?: string
  error?: string
  titleKey: 'yourProductCredit' | 'yourNorwexCredit'
  onRemove: () => void
  onChange: (value: string) => void
  onApply: (value: number) => void
}

export const ApplyCredits = observer(
  ({
    isShowApplyCredits,
    isLoading,
    creditAmount,
    appliedCredits,
    applicableCredits,
    creditInputValue,
    error,
    onApply,
    onChange,
    onRemove,
    titleKey,
  }: Props) => {
    const t = useTranslations('cartApplyCredits')
    const { cartStore } = useCartContext()

    const toolTipText = cartStore.isPersonalCart
      ? t('tooltipPCMessage')
      : t('tooltipNCMessage')

    if (!isShowApplyCredits) return null
    return (
      <div className="border px-6 pb-2 pt-6">
        <div className="flex flex-col border-b pb-4">
          <div className="flex justify-between text-base font-medium text-gray-700">
            <span>{t(titleKey)}</span>
            <span className="font-bold" data-test-id="amount-credits">
              {getMoneyWithIsoCurrency(creditAmount)}
            </span>
          </div>
          {appliedCredits && appliedCredits.cents > 0 && (
            <div className="mt-2 flex justify-between text-xs font-normal text-gray-500">
              <span data-test-id="applied-credits">
                {t('applied', {
                  amount: getMoneyWithIsoCurrency(appliedCredits),
                })}
              </span>
              <Link onClick={onRemove}>{t('removeCredit')}</Link>
            </div>
          )}
        </div>

        <div className="flex flex-col pt-4">
          <div className="flex justify-between text-sm font-normal text-gray-600">
            <p data-test-id="applicable-credits" className="max-w-[223px]">
              {t('youCanApply')}
              <b>{getMoneyWithIsoCurrency(applicableCredits)}</b>
              {t('toOrder')}
            </p>
            <Tooltip
              place="left"
              slot={<div className="min-w-32">{toolTipText}</div>}
            >
              <span className="text-gray-400">
                <Icon name="InformationCircleFill" />
              </span>
            </Tooltip>
          </div>
          <div className="mt-3 flex justify-between">
            <Input
              type="money"
              min={0}
              size="small"
              value={creditInputValue}
              onChange={onChange}
              error={error}
              data-test-id="input-credits"
              placeholder={t('creditAmount')}
              withNumberHandles={false}
            />
            <div className="ml-2">
              <Button
                size="base"
                data-test-id={'apply-button'}
                isLoading={isLoading}
                onClick={() => onApply(Number(creditInputValue))}
              >
                {t('apply')}
              </Button>
            </div>
          </div>
        </div>
      </div>
    )
  }
)
