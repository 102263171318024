import { observer } from '@nori/di'
import { useTranslations } from '@nori/lang-i18n'
import { DropdownSearch, Modal, useDebounce } from '@nori/ui-kit'

import { useAutosaveContext } from '../../../interface/use-autosave-context'

import { AddProductSearchResults } from './add-product-search-results'

export const AddProductModal = observer(() => {
  const { subscriptionsAddProductStore, subscriptionsAddProductAction } =
    useAutosaveContext()
  const t = useTranslations('autosaveSettings')

  const { value, onChangeValue } = useDebounce({
    initialValue: subscriptionsAddProductStore.searchQuery,
    onChangeDebounce: subscriptionsAddProductAction.handleSearchQuery,
  })

  return (
    <Modal
      id="autosave-settings-add-product-modal"
      size="3xl"
      isOpen={subscriptionsAddProductStore.isOpen}
      onClose={subscriptionsAddProductAction.handleClose}
    >
      <Modal.Header
        onClose={subscriptionsAddProductAction.handleClose}
        headerType="only-close-button"
      />
      <Modal.Body>
        <div className="max-md:w-full lg:px-5 lg:py-9">
          <h1 className="text-center text-2xl font-bold text-primary-900">
            {t('addItems')}
          </h1>
          <div className="my-8 w-full">
            <DropdownSearch
              value={value}
              onChange={onChangeValue}
              hideHelperBlock
              placeholder={t('searchProducts')}
            />
          </div>
          <AddProductSearchResults />
        </div>
      </Modal.Body>
    </Modal>
  )
})
