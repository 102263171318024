import { errorFactory } from '@nori/errors'

import type { KeyOfErrors } from '@nori/errors'

export const { BundleErrors } = errorFactory('BundleErrors', {
  UnexpectedError: 'Unexpected Error',
  UnknownError: 'Unknown Error',

  ReplaceBundleUnexpectedError: 'Replace Bundle Unexpected Error',
  ReplaceBundleUnknownError: 'Replace Bundle Unknown Error',
})

export type BundleErrorInstance = InstanceType<KeyOfErrors<typeof BundleErrors>>
