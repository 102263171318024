import { useCallback } from 'react';
const viewDateMap = {
    days: (date, dx)=>date.addMonths(dx),
    months: (date, dx)=>date.addYears(dx),
    years: (date, dx)=>date.addYears(dx * 10)
};
export function useChangeView({ viewType , viewDate , onChangeDate  }) {
    const handlePrev = useCallback(()=>{
        const nextDate = viewDateMap[viewType](viewDate, -1);
        onChangeDate(nextDate);
    }, [
        viewDate,
        viewType,
        onChangeDate
    ]);
    const handleNext = useCallback(()=>{
        const nextDate = viewDateMap[viewType](viewDate, 1);
        onChangeDate(nextDate);
    }, [
        viewDate,
        viewType,
        onChangeDate
    ]);
    return {
        handleNext,
        handlePrev
    };
}
