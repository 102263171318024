import { Inject, Store } from '@nori/di'
import { getBlock } from '@nori/lang-i18n'
import { CartStore } from '~/modules/cart/interface/store/cart-store/cart.store'
import { ShareCartErrors } from '~/modules/share/core/share-cart.errors'

import type { ShareCartErrorInstance } from '~/modules/share/core/share-cart.errors'
import type { CartShareLinkValueObject } from '../../core/share-link.value-object'

const FB_APP_ID = process.env.FB_APP_ID

@Store()
export class ShareStore {
  isCreatingShareCartLink = false
  cartShareLinkObject?: CartShareLinkValueObject = undefined
  createShareLinkError?: ShareCartErrorInstance = undefined

  constructor(@Inject() private readonly cartStore: CartStore) {}

  get errorTitle(): string {
    if (!this.createShareLinkError) return ''
    const t = getBlock('cart.errors')

    switch (true) {
      case this.createShareLinkError instanceof ShareCartErrors.BadRequest:
      case this.createShareLinkError instanceof ShareCartErrors.NotFound:

      default:
        return t('somethingWrong')
    }
  }

  get shareCartErrorTitle(): string {
    if (!this.createShareLinkError) return ''
    const t = getBlock('cart.errors')

    switch (true) {
      case this.createShareLinkError instanceof ShareCartErrors.BadRequest:
      case this.createShareLinkError instanceof ShareCartErrors.NotFound:
        return t('unableToShareLink')
      default:
        return t('somethingWrong')
    }
  }

  get cartShareLink(): string {
    const personalLink = this.cartShareLinkObject?.referrerPersonalLink
      ? `?r=${this.cartShareLinkObject?.referrerPersonalLink}`
      : ''

    const siteDomain = window.location.origin.replace('my', 'www')
    return `${siteDomain}/c/${this.cartShareLinkObject?.urlCode}${personalLink}`
  }

  get cartShareMailUrl(): string {
    const contactEmail = this.cartStore.cart?.customerEmail ?? ''
    return `mailto:${contactEmail}?subject=${getBlock('checkout.shareStore')(
      'subject'
    )}&body=${getBlock('checkout.shareStore')('body')} ${this.cartShareLink}`
  }

  get cartShareMessengerUrl(): string {
    return `http://www.facebook.com/dialog/send?app_id=${FB_APP_ID}&amp;link=${this.cartShareLink}`
  }

  setCreateShareLinkError(error?: ShareCartErrorInstance): void {
    this.createShareLinkError = error
  }

  setIsCreatingShareLink(value: boolean): void {
    this.isCreatingShareCartLink = value
  }

  setCartShareLinkObject(value: CartShareLinkValueObject): void {
    this.cartShareLinkObject = value
  }
}
