import type { LinkWithChildren } from './header-link.type'

export const HELP_LINKS: LinkWithChildren = {
  dataTestId: 'help-dropdown',
  translationKey: 'header.bottomNav.help',
  href: '#',
  children: [
    {
      translationKey: 'header.help.contactConsultantCare',
      href: '#',
      dataTestId: 'contact-consultant-care-option',
    },
    {
      translationKey: 'header.help.liveChat',
      href: '#',
      dataTestId: 'live-chat-option',
    },
  ],
}
