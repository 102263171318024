import { Inject, Service } from '@nori/di'
import { isErr } from '@nori/result'
import { createLogger } from '~/infra/create-logger'

import { OrderAdapter } from '../../infra/order.adapter'
import { OrderStore } from '../store/order.store'

const logger = createLogger('order-init-load.service')

@Service()
export class OrderCancelationService {
  constructor(
    @Inject() private readonly orderStore: OrderStore,
    @Inject() private readonly orderAdapter: OrderAdapter
  ) {}

  async handleOrderCancel(orderId: number): Promise<void> {
    this.orderStore.setError(undefined)
    this.orderStore.setIsLoading(true)

    await this.cancelOrder(orderId)

    this.orderStore.setIsLoading(false)
  }

  private async cancelOrder(orderId: number): Promise<void> {
    const result = await this.orderAdapter.cancelOrder(orderId)

    if (isErr(result)) {
      logger.error(result.error.key, result.error)
      this.orderStore.setError(result.error)
    }
  }
}
