import { getMoneyWithIsoCurrency } from '@nori/app-kit'
import { useTranslations } from '@nori/lang-i18n'
import { Swap } from '@nori/ui-kit'

import { NextOrderAddonActions } from './next-order-addon-actions'

import type { MoneyValueObject } from '@nori/app-kit'

type Props = {
  retailPrice?: MoneyValueObject
  salePrice?: MoneyValueObject
  consultantPrice?: MoneyValueObject
  totalPrice?: MoneyValueObject
  isEditMode: boolean
  onRemove: () => void
  onEdit: () => void
  isUnavailable?: boolean
  quantity?: number
}

export const NextOrderAddonPricing = ({
  retailPrice,
  salePrice,
  consultantPrice,
  isEditMode,
  onRemove,
  onEdit,
  totalPrice,
  isUnavailable,
  quantity,
}: Props) => {
  const t = useTranslations('autosaveSettings')
  const availabilityClass = isUnavailable ? 'opacity-40' : ''

  return (
    <div className="flex h-full flex-col items-end justify-between">
      <div
        className={`${availabilityClass} flex flex-col gap-1 max-lg:items-baseline lg:mb-6 lg:items-end`}
      >
        <Swap
          is={!!totalPrice}
          isSlot={
            <div className="max-lg:flex max-lg:flex-col">
              <span className="text-sm font-medium text-primary-900 lg:text-xl lg:font-semibold lg:text-gray-600">
                {getMoneyWithIsoCurrency(totalPrice)}
              </span>
            </div>
          }
        >
          <div className="max-lg:flex max-lg:flex-col">
            <span className="text-xs font-normal text-gray-500 lg:text-sm">
              {t('retailPrice')}
            </span>{' '}
            <span className="text-sm font-medium text-gray-500 line-through lg:text-base">
              {getMoneyWithIsoCurrency(retailPrice, {
                quantity,
              })}
            </span>
          </div>
          <Swap
            is={!!salePrice?.cents}
            isSlot={
              <div className="max-lg:flex max-lg:flex-col">
                <span className="lg;text-gray-600 text-xs font-normal text-gray-500 lg:text-sm">
                  {t('salePrice')}
                </span>{' '}
                <span className="text-sm font-semibold text-gray-600 lg:text-xl">
                  {getMoneyWithIsoCurrency(salePrice, {
                    quantity,
                  })}
                </span>
              </div>
            }
          >
            <div className="max-lg:flex max-lg:flex-col">
              <span className="lg;text-gray-600 text-xs font-normal text-gray-500 lg:text-sm">
                {t('consultantPrice')}
              </span>{' '}
              <span className="text-sm font-semibold text-gray-600 lg:text-xl">
                {getMoneyWithIsoCurrency(consultantPrice, {
                  quantity,
                })}
              </span>
            </div>
          </Swap>
        </Swap>
      </div>
      {!isUnavailable && (
        <div className="max-lg:hidden">
          <NextOrderAddonActions
            isEditMode={isEditMode}
            onRemove={onRemove}
            onEdit={onEdit}
          />
        </div>
      )}
    </div>
  )
}
