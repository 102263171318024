import {
  getMoneyWithIsoCurrency,
  LocalStorageItem,
  StoreEnum,
} from '@nori/app-kit'
import { DataState, Inject, observe, Store } from '@nori/di'
import { getBlock } from '@nori/lang-i18n'
import { HeaderStore } from '~/modules/header/interface/store/header.store'

import { AddToCartErrors, CartErrors } from '../../../core'

import type {
  CartAddressEntity,
  CartEntity,
  CartItemEntity,
  CartItemsUnion,
  CartShippingRateEntity,
  CheckoutTotalEntity,
  MoneyValueObject,
} from '@nori/app-kit'
import type { CartErrorInstance } from '../../../core'
import type { AddToCartStatus } from './cart-store.types'

const LS_CART_ID_KEY = '@nori/cart-id'

@Store()
export class CartStore {
  constructor(@Inject() private readonly headerStore: HeaderStore) {
    observe(this.cartData, 'data', (cart) => {
      const currentCartId = cart.object.get()?.id

      if (currentCartId !== this.cartIdStorage.value) {
        this.cartIdStorage.set(cart.object.get()?.id)
      }

      if (cart.object.get()?.typeCode === 'dsr') {
        const itemTotal =
          cart.object
            .get()
            ?.items.reduce((total, item) => total + item.quantity, 0) || 0
        this.headerStore.setPersonalCartItemsCounter(itemTotal)
      }
    })
  }

  private cartIdStorage = LocalStorageItem<number | undefined>(LS_CART_ID_KEY, {
    initialValue: undefined,
  })

  addToCartStatusById: Record<string, AddToCartStatus | undefined> = {}
  isCancellingOrder = false
  addingToCartState: Record<number, boolean> = {}
  cartData = new DataState<CartEntity, CartErrorInstance>()
  taxCalculationData = new DataState<undefined, CartErrorInstance>({
    isLoading: false,
  })

  get cart(): CartEntity | undefined {
    return this.cartData?.data
  }

  get isFreeShipping(): boolean {
    const shippingCost = this.cart?.shippingTotal?.cents ?? 0
    return shippingCost === 0
  }

  get isTaxCalculated(): boolean {
    return !!this.cart?.isTaxCalculated
  }

  get isCustomerSelected(): boolean {
    return !!this.cart?.customerId
  }

  get isCustomerCart(): boolean {
    return !this.isPersonalCart
  }

  get isTotalNetEarned(): boolean {
    if (!this.cart?.totalNetEarned?.cents) {
      return false
    }

    return this.cart.totalNetEarned.cents > 0
  }

  get isPersonalCart(): boolean {
    return this.cart?.typeCode === 'dsr'
  }

  get shippingAddress(): CartAddressEntity | undefined {
    return this.cart?.shippingAddress
  }

  get isShippingAddressDefined(): boolean {
    if (!this.shippingAddress) return false
    return Object.values(this.shippingAddress).some(Boolean)
  }

  get customerId(): number | undefined {
    return this.cart?.customerId
  }

  get saveAsContactAddress(): boolean {
    return this.cart?.saveAsContactAddress || false
  }

  get availableShippingRate(): CartShippingRateEntity | undefined {
    return this.cart?.shippingRate ?? this.cart?.allowedShippingRates[0]
  }

  get totalItemsCount(): number | undefined {
    return this.cart?.items.reduce((total, item) => total + item.quantity, 0)
  }

  get cartType(): string | undefined {
    return this.cart?.typeCode
  }

  get errorTitle(): string {
    if (!this.cartData.error) return ''

    switch (true) {
      case this.cartData.error instanceof CartErrors.BadRequest:
      case this.cartData.error instanceof CartErrors.NotFound:
      case this.cartData.error instanceof CartErrors.UnproceccedEntity:
        return 'Unable to add product to cart.'
      case this.cartData.error instanceof AddToCartErrors.MaximumQuantityError:
      case this.cartData.error instanceof AddToCartErrors.CartLimitError:
      case this.cartData.error instanceof AddToCartErrors.NotAvailableError:
        return ''
      default:
        return 'Sorry. Something went wrong.'
    }
  }

  get addToCartErrorTitle(): string {
    if (!this.cartData.error) return ''

    const t = getBlock('cart.errors')
    const error = this.cartData.error.error as string

    switch (true) {
      case this.cartData.error instanceof AddToCartErrors.MaximumQuantityError:
        return t('maximumQuantityError', {
          error: error,
        })
      case this.cartData.error instanceof AddToCartErrors.CartLimitError:
        return t('cartLimitError', {
          error: error,
        })
      case this.cartData.error instanceof AddToCartErrors.NotAvailableError:
        return error
      default:
        return ''
    }
  }

  get isCartHaveAutoshipItem(): boolean {
    if (!this.cart?.items.length) return false
    return this.cart?.items.some((item: CartItemsUnion) => {
      const cartItem = item as CartItemEntity
      return !!cartItem?.autoShip?.id
    })
  }
  get persistCartId(): number | undefined | null {
    return this.cartIdStorage.value
  }

  get storeType(): StoreEnum {
    return this.cart?.store || StoreEnum.US
  }

  get isCartExists(): boolean {
    if (!this.cartIdStorage.value) {
      return false
    }

    return !!this.cart
  }

  get isCartImported(): boolean {
    return this.cart?.status === 'Imported'
  }

  get discountsVisibility(): {
    isAnyDiscount: boolean
    isStoreCredits: boolean
    isAccountBalance: boolean
    isDsrDiscount: boolean
  } {
    const discounts = {
      isStoreCredits: !!this.cart?.storeCreditsTotal?.cents,
      isAccountBalance: !!this.cart?.earningsTotal?.cents,
      isDsrDiscount: !!this.cart?.dsrDiscountTotal?.cents,
      isConsultantDiscount: !!this.cart?.consultantDiscountTotal?.cents,
    }

    const isAnyDiscount = Object.values(discounts).some(Boolean)

    return {
      isAnyDiscount,
      ...discounts,
    }
  }

  get hostessDiscountPercentage(): number | undefined {
    // TODO: request BE to populate cart response with hostessDiscountPercentage
    return this.cart?.items.find((item) => item.hostessDiscountPercentage)
      ?.hostessDiscountPercentage
  }

  updateCartTotals(total: CheckoutTotalEntity): void {
    if (!this.cartData.data) {
      return
    }

    this.cartData.setData({
      ...this.cartData.data,
      ...total,
    })
  }

  setIsAddingToCart(id?: number): void {
    if (!id) return

    if (this.addingToCartState[id]) {
      const newAddingToCartState = this.addingToCartState
      delete newAddingToCartState[id]

      this.addingToCartState = { ...newAddingToCartState }

      return
    }

    this.addingToCartState = {
      ...this.addingToCartState,
      [id]: true,
    }
  }

  setAddToCartStatus(id: string, value: AddToCartStatus): void {
    this.addToCartStatusById[id] = value
  }

  setIsCancellingOrder(value: boolean): void {
    this.isCancellingOrder = value
  }

  dropCart(): void {
    this.cartData.setData(undefined)
    this.cartIdStorage.remove()
  }

  setSaveAsContactAddress(value: boolean): void {
    if (!this.cart) return

    this.cart.saveAsContactAddress = value
  }

  get consultantDiscountAmount(): MoneyValueObject | undefined {
    return this.cart?.isConsultantDiscountApplied
      ? this.cart.totalEarnedRedeemed
      : this.cart?.totalNetEarned
  }

  get dsrId(): number | undefined {
    return this.cart?.dsrId
  }

  get isConsultantCustomerCart(): boolean {
    return this.cart?.customerType === 'consultant'
  }

  get isSelfCustomerCart(): boolean {
    return this.dsrId === this.customerId
  }
}
