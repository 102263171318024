import { useMemo } from 'react'

import { Dropdown } from '@nori/ui-kit'

import type { ProductSubscriptionEntity } from '@nori/app-kit'

type Props = {
  subscriptionId?: number
  onSubscriptionChange: (id: number) => void
  subscriptions?: ProductSubscriptionEntity[]
}

export const AutosaveDropdown = ({
  subscriptionId,
  onSubscriptionChange,
  subscriptions,
}: Props) => {
  const selectedSubscription = useMemo(() => {
    const selectedSubscription = subscriptions?.find(
      (subscription) => subscription.id === subscriptionId
    )
    if (selectedSubscription) {
      return selectedSubscription
    }
    if (subscriptions?.length && subscriptionId === -1) {
      subscriptions[0] && onSubscriptionChange(subscriptions[0].id)
      return subscriptions[0]
    }
  }, [subscriptionId, subscriptions, onSubscriptionChange])

  return (
    <Dropdown hideHelperBlock title={selectedSubscription?.name}>
      {subscriptions?.map((option) => {
        return (
          <div
            key={option.id}
            className="cursor-pointer p-2 text-sm font-normal text-gray-900 hover:bg-gray-50"
            onClick={() => {
              onSubscriptionChange(option.id)
            }}
          >
            {option.name}
          </div>
        )
      })}
    </Dropdown>
  )
}
