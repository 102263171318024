import { observer } from '@nori/di'
import { useTranslations } from '@nori/lang-i18n'
import { Input, Loader, Swap, useDebounce, useMount } from '@nori/ui-kit'
import { usePartiesListContext } from '~/modules/parties/interface/use-parties-list-context'
import { ContactPartyList } from '~/modules/parties/ui/contact-party-list/contact-party-list'

import type { PartyEntity } from '../../core/entities/party.entity'

type Props = {
  onSelectContact: (party: PartyEntity) => void
}

export const SearchContactPartyBlock = observer(
  ({ onSelectContact }: Props) => {
    const t = useTranslations('contactSelector')
    const { contactPartiesListStore, contactPartiesListLoadAction } =
      usePartiesListContext()
    const { onChangeValue, value } = useDebounce({
      initialValue: contactPartiesListStore.searchQuery,
      onChangeDebounce: contactPartiesListLoadAction.handleSetSearchQuery,
    })

    useMount(contactPartiesListLoadAction.handleDropSearch)
    return (
      <>
        <Input
          data-test-id={'search-contact-input'}
          value={value}
          type="search"
          onChange={onChangeValue}
          placeholder={t('searchHost')}
          hideHelperBlock
        />

        <div className="mt-1">
          <Swap
            is={contactPartiesListStore.contactPartyList.isLoading}
            isSlot={<Loader />}
          >
            <ContactPartyList onSelectContact={onSelectContact} />
          </Swap>
        </div>
      </>
    )
  }
)
