import { useMemo } from 'react'

import { getSkuName, getStyleItemName } from '@nori/app-kit'
import { useTranslations } from '@nori/lang-i18n'
import { Swap } from '@nori/ui-kit'

import { AutoSaveOrderRowEditor } from './autosave-order-row-editor'

import type { ProductSubscriptionEntity } from '@nori/app-kit'
import type { NoriDate } from '@nori/date'

type Props = {
  productName: string
  skuCode: string
  skuVariation?: string
  skuName?: string
  productSubscriptions?: ProductSubscriptionEntity[]
  itemQty: string
  onItemQtyChange?: (qty: string) => void
  cycleName?: string
  onFrequencyChange?: (value: number) => void
  itemDate?: NoriDate
  onDateChange?: (date: NoriDate) => void
  isEditMode?: boolean
  styleName?: string
  handleSave?: () => void
}

export const AutoSaveOrderRowInfo = ({
  productName,
  skuCode,
  skuVariation,
  skuName,
  itemQty,
  onItemQtyChange,
  productSubscriptions,
  cycleName,
  onFrequencyChange,
  itemDate,
  onDateChange,
  isEditMode,
  handleSave,
  styleName,
}: Props) => {
  const t = useTranslations('autosaveSettings')

  const subtitle = useMemo(
    () =>
      [
        getSkuName({
          variation: skuVariation,
          name: skuName,
        }),
      ]
        .filter(Boolean)
        .join(', '),
    [skuName, skuVariation]
  )

  return (
    <div className="flex flex-col">
      <span className="mb-2 text-base font-bold text-gray-700">
        {getStyleItemName({
          productName,
          styleName,
        })}{' '}
      </span>
      <div className="max-lg:hidden">
        <Swap
          is={isEditMode}
          isSlot={
            <div className="mt-1">
              <AutoSaveOrderRowEditor
                itemQty={itemQty}
                onItemQtyChange={onItemQtyChange}
                cycleName={cycleName}
                onFrequencyChange={onFrequencyChange}
                productSubscriptions={productSubscriptions}
                itemDate={itemDate}
                handleSave={handleSave}
                onDateChange={onDateChange}
              />
            </div>
          }
        >
          <div className="flex flex-col gap-1 text-sm font-medium text-gray-500">
            {subtitle && <span>{subtitle}</span>}
            <span>{skuCode}</span>
            <span>{t('qty', { qty: itemQty })}</span>
          </div>
        </Swap>
      </div>
      <div className="lg:hidden">
        <div className="flex flex-col gap-1 text-sm font-medium text-gray-500">
          {subtitle && <span>{subtitle}</span>}
          <span>{skuCode}</span>
          <span>{t('qty', { qty: itemQty })}</span>
        </div>
      </div>
    </div>
  )
}
