import { createConsoleTransport } from './console';
import { LOG_LEVEL } from './log-level';
import { usedLogLevel } from './used-log-level';
export class Logger {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    info(message, details) {
        if (this.logLevel < LOG_LEVEL.INFO) return;
        this.transport.forEach((transport)=>transport.info({
                name: this.name,
                section: this.section,
                message,
                details
            }));
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    warn(message, details) {
        if (this.logLevel < LOG_LEVEL.WARN) return;
        this.transport.forEach((transport)=>transport.warn({
                name: this.name,
                section: this.section,
                message,
                details
            }));
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    error(message, details) {
        this.transport.forEach((transport)=>transport.error({
                name: this.name,
                section: this.section,
                message,
                details
            }));
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    log(message, details) {
        if (this.logLevel < LOG_LEVEL.DEBUG) return;
        this.transport.forEach((transport)=>transport.log({
                name: this.name,
                section: this.section,
                message,
                details
            }));
    }
    constructor(name, options){
        this.name = '';
        this.section = '';
        this.transport = [];
        this.logLevel = usedLogLevel;
        const { transport , section  } = options || {};
        this.name = name;
        this.section = section;
        const consoleTransport = createConsoleTransport();
        this.transport = [
            consoleTransport
        ];
        if (transport && transport.name !== 'console') {
            this.transport.push(transport);
        }
    }
}
