import { ProductDetailsService, UserAutoshipStore } from '@nori/app-kit'
import { Inject, Service } from '@nori/di'
import { isErr } from '@nori/result'
import { createLogger } from '~/infra/create-logger'
import { SubscriptionService } from '~/modules/subscription'

import { SubscriptionsStore } from '../store/subscriptions.store'

import type { BundleDetailsEntity, StyleDetailsEntity } from '@nori/app-kit'

const logger = createLogger('autosave.service')

@Service()
export class AutosaveService {
  constructor(
    @Inject() private readonly subscriptionsStore: SubscriptionsStore,
    @Inject() private readonly subscriptionService: SubscriptionService,
    @Inject() private readonly productDetailsService: ProductDetailsService,
    @Inject() private readonly userAutoshipStore: UserAutoshipStore
  ) {}

  async loadSubscriptions(userId?: number): Promise<void> {
    this.subscriptionsStore.subscriptions.setError(undefined)

    const result = await this.subscriptionService.getSubscriptions(userId)
    if (isErr(result)) {
      logger.error(result.error.key, result.error)
      this.subscriptionsStore.subscriptions.setError(result.error)
      return
    }

    await this.loadPendingOrders(userId)

    const stylesDetailsIds: number[] = []
    const bundleDetailsIds: number[] = []
    for (const subscription of result.data) {
      if (
        subscription.bundleId &&
        !bundleDetailsIds.includes(subscription.bundleId)
      ) {
        bundleDetailsIds.push(subscription.bundleId)
      }
      if (
        subscription.productId &&
        !stylesDetailsIds.includes(subscription.productId)
      ) {
        stylesDetailsIds.push(subscription.productId)
      }
    }

    const [stylesDetails, bundleDetails] = await Promise.all([
      Promise.all(
        stylesDetailsIds.map((id) => this.loadStyleDetails(id.toString()))
      ),
      Promise.all(
        bundleDetailsIds.map((id) => this.loadBundleDetails(id.toString()))
      ),
    ])

    if (result.data.length) {
      this.userAutoshipStore.setIsShowAutosaveTabs(true)
    } else {
      this.userAutoshipStore.setIsShowAutosaveTabs(false)
    }

    this.subscriptionsStore.subscriptions.setData(result.data)
    this.subscriptionsStore.subscriptionProducts.setData([
      ...stylesDetails.filter((e): e is StyleDetailsEntity => e !== undefined),
      ...bundleDetails.filter((e): e is BundleDetailsEntity => e !== undefined),
    ])
  }

  private async loadPendingOrders(userId?: number): Promise<void> {
    const pendingOrders = await this.subscriptionService.getPendingOrders(
      userId
    )
    if (isErr(pendingOrders)) {
      logger.error(pendingOrders.error.key, pendingOrders.error)
      this.subscriptionsStore.pendingOrders.setError(pendingOrders.error)
      return
    }

    this.subscriptionsStore.pendingOrders.setData(pendingOrders.data)
  }

  private async loadStyleDetails(
    id: string
  ): Promise<StyleDetailsEntity | undefined> {
    const result = await this.productDetailsService.getStyleDetails(id)

    if (isErr(result)) {
      logger.error(result.error.key, result.error)
      this.subscriptionsStore.subscriptionProducts.setError(result.error)
      return undefined
    }

    return result.data
  }

  private async loadBundleDetails(
    id: string
  ): Promise<BundleDetailsEntity | undefined> {
    const result = await this.productDetailsService.getBundleDetails(id)

    if (isErr(result)) {
      logger.error(result.error.key, result.error)
      this.subscriptionsStore.subscriptionProducts.setError(result.error)
      return undefined
    }

    return result.data
  }
}
