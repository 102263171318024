const PRODUCTION_DOMAIN = 'norwex.com';
const STAGING_DOMAIN = 'stg.norwex';
const DYN_DOMAIN = 'main.dyn.norwex';
const ADMIN_SUB_DOMAIN = 'admin';
const BACKOFFICE_SUB_DOMAIN = 'my';
const WWW_SUB_DOMAIN = 'www';
const ALL_DOMAINS = [
    // Production domains
    `${ADMIN_SUB_DOMAIN}.${PRODUCTION_DOMAIN}`,
    `${BACKOFFICE_SUB_DOMAIN}.${PRODUCTION_DOMAIN}`,
    `${WWW_SUB_DOMAIN}.${PRODUCTION_DOMAIN}`,
    // Staging domains
    `${ADMIN_SUB_DOMAIN}.${STAGING_DOMAIN}`,
    `${BACKOFFICE_SUB_DOMAIN}.${STAGING_DOMAIN}`,
    `${WWW_SUB_DOMAIN}.${STAGING_DOMAIN}`,
    // Main DynEnv domains
    `${ADMIN_SUB_DOMAIN}.${DYN_DOMAIN}`,
    `${BACKOFFICE_SUB_DOMAIN}.${DYN_DOMAIN}`,
    `${WWW_SUB_DOMAIN}.${DYN_DOMAIN}`
];
export function checkHostInWhitelist() {
    if (typeof window === 'undefined') return true;
    return ALL_DOMAINS.some((domain)=>window.location.host.includes(domain));
}
