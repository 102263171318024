import { centsToMainUnit, mainUnitToCents } from '@nori/app-kit'
import { Store } from '@nori/di'

import type { CreditCardPriorityValueObject } from '../../core'

@Store()
export class BillingFormSplitPaymentStore {
  cardAmountMap: Record<CreditCardPriorityValueObject, number> = {
    main: 0,
    second: 0,
  }

  setCardAmount({
    type,
    cents,
  }: {
    type: CreditCardPriorityValueObject
    cents: number
  }): void {
    this.cardAmountMap[type] = cents
  }
}
