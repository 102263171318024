import { Inject, Service } from '@nori/di'
import { isErr, resultOk } from '@nori/result'
import { createLogger } from '~/infra/create-logger'
import { FindOrCreateContactStore } from '~/modules/contacts/interface/store/find-or-create-contact.store'

import { ContactAdapter } from '../../infra/contact.adapter'

import type { PromiseResult } from '@nori/result'
import type { ContactErrorInstance } from '../../core'

const logger = createLogger('ensure-contact.service')

@Service()
export class EnsureContactService {
  constructor(
    @Inject() private readonly contactAdapter: ContactAdapter,
    private readonly findOrCreateContactStore: FindOrCreateContactStore
  ) {}

  async ensureContact(
    contactId: number
  ): PromiseResult<{ customerId: number }, ContactErrorInstance> {
    const result = await this.contactAdapter.ensureContact(contactId)

    if (isErr(result)) {
      logger.error(result.error.key, result.error)
      this.findOrCreateContactStore.setError(result.error)
      return result
    }

    const contactDetails = {
      customerId: result.data.user.id,
    }

    return resultOk(contactDetails)
  }
}
