import { useCallback } from 'react'

import { observer } from '@nori/di'
import { useTranslations } from '@nori/lang-i18n'
import { TableTh } from '@nori/ui-kit'

import { useContactContext } from '../../interface/use-contact-context'

import type { SortDirectionValueObject } from '~/core'
import type { PropsWithChildren } from 'react'
import type { ContactSort } from '../../core/contact-sort'

type Props = PropsWithChildren & {
  sortBy?: ContactSort
}

export const ContactTableHeaderCell = observer(
  ({ sortBy, children }: Props) => {
    const t = useTranslations('contacts.list')

    const { contactsAction, contactsStore } = useContactContext()

    const sortOrder = contactsStore.getSelectedSort(sortBy)

    const handleSortChange = useCallback(
      (order: SortDirectionValueObject) => {
        if (!sortBy) return
        contactsAction.handleSortChange(sortBy, order)
      },
      [contactsAction, sortBy]
    )

    return (
      <TableTh
        isSortable={!!sortBy}
        onSort={handleSortChange}
        sortDirection={sortOrder}
      >
        {children}
      </TableTh>
    )
  }
)
