import { useTranslations } from '@nori/lang-i18n'

export function FirstAutosaveOrderTooltip() {
  const t = useTranslations('autosaveSettings.autosaveTooltips')
  return (
    <div className="flex min-w-52 items-center justify-center px-3 py-1.5">
      <span className="text-center text-sm font-medium text-primary-900">
        {t('firstAutoSaveOrderTooltip')}
      </span>
    </div>
  )
}
