import { Inject, Service } from '@nori/di'
import { isErr } from '@nori/result'
import { createLogger } from '~/infra/create-logger'

import { OrderAdapter } from '../../infra/order.adapter'

import type { Result } from '@nori/result'
import type { OrderEntity, OrderErrorInstance } from '../../core'

const logger = createLogger('order-init-load.service')

@Service()
export class LoadOrderService {
  constructor(@Inject() private readonly orderAdapter: OrderAdapter) {}

  async loadOrder(
    orderId: number
  ): Promise<Result<OrderEntity, OrderErrorInstance>> {
    const result = await this.orderAdapter.getOrder(orderId)

    if (isErr(result)) {
      logger.error(result.error.key, result.error)
    }

    return result
  }
}
