import { observer } from '@nori/di'
import { useTranslations } from '@nori/lang-i18n'
import { Icon } from '@nori/ui-kit'

import { useContactContext } from '../../../interface/use-contact-context'

import { ContactsFiltersMenu } from './filters-menu'

export const ContactsFilters = observer(() => {
  const { contactFiltersAction } = useContactContext()

  const t = useTranslations('contacts.filters')

  return (
    <>
      <button
        onClick={contactFiltersAction.handleOpenFilters}
        className="flex items-center text-xs font-bold text-primary-900 lg:text-base"
      >
        <Icon name="AdjustmentsOutline" />
        <span className="ml-1 max-md:hidden">{t('filters')}</span>
      </button>
      <ContactsFiltersMenu />
    </>
  )
})
