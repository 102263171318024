import { observer } from '@nori/di'
import { useTranslations } from '@nori/lang-i18n'
import { Accordion } from '@nori/ui-kit'

import { useBillingFormContext } from '../interface/use-billing-form.context'

import { CreditCardSection } from './credit-card-section/credit-card-section'
import { FormHeader } from './form-header'
import { PaypalSection } from './paypal-section'

import type { PaymentMethodValueObject } from '@nori/app-kit'

export const BillingForms = observer(() => {
  const t = useTranslations('billingForm')
  const { billingFormStore, billingFormAction } = useBillingFormContext()

  return (
    <div>
      <h4 className="mb-5 text-lg font-bold text-gray-700">
        {t('choosePaymentMethod')}
      </h4>

      <Accordion<PaymentMethodValueObject>
        active={billingFormStore.selectedPaymentMethod}
        onChange={(paymentMethod) => {
          billingFormAction.handleSelectPaymentMethod(paymentMethod)
        }}
      >
        {billingFormStore.view !== 'customerOrder' && (
          <Accordion.Item
            name="paypal"
            collapsedSlot={
              <Accordion.Collapsed name="paypal">
                <FormHeader
                  isChecked={
                    billingFormStore.selectedPaymentMethod === 'paypal'
                  }
                  paymentMethodType="paypal"
                />
              </Accordion.Collapsed>
            }
            expandedSlot={
              <Accordion.Expanded>
                <PaypalSection />
              </Accordion.Expanded>
            }
          />
        )}

        <Accordion.Item
          name="card"
          isStayInDOM
          collapsedSlot={
            <Accordion.Collapsed name="card">
              <FormHeader
                isChecked={billingFormStore.selectedPaymentMethod === 'card'}
                paymentMethodType="card"
              />
            </Accordion.Collapsed>
          }
          expandedSlot={
            <Accordion.Expanded>
              <CreditCardSection />
            </Accordion.Expanded>
          }
        />
      </Accordion>
    </div>
  )
})
