import { useEffect, useState } from 'react'
import Script from 'next/script'

import { sessionInfo, useProfileContext } from '@nori/app-kit'
import { observer } from '@nori/di'
import { ALL_DOMAINS } from '~/core/all-domains.constants'

const CLARITY_ID = 'ju2t4cyt89'
const CLARITY_LOADED_EVENT = 'clarityLoaded'

export const ClarityScript = observer(() => {
  const [isClarityEnabled, setIsClarityEnabled] = useState(false)
  const [isClarityLoaded, setIsClarityLoaded] = useState(false)
  const { userStore } = useProfileContext()
  const { currentUser } = userStore

  useEffect(() => {
    setIsClarityEnabled(
      ALL_DOMAINS.some((item) => window.location.host.includes(item))
    )
  }, [])

  useEffect(() => {
    if (!isClarityEnabled || !isClarityLoaded) return

    const clarity = (window as any).clarity

    if (typeof clarity !== 'function') return

    const userId = currentUser.dsrLogin
    if (userId) {
      clarity('identify', userId)
    }
    clarity('set', 'client', sessionInfo.getClientValue())
  }, [isClarityEnabled, currentUser, isClarityLoaded])

  useEffect(() => {
    const onClarityLoaded = () => {
      setIsClarityLoaded(true)
    }

    window.addEventListener(CLARITY_LOADED_EVENT, onClarityLoaded)

    return () => {
      window.removeEventListener(CLARITY_LOADED_EVENT, onClarityLoaded)
    }
  }, [])

  if (!isClarityEnabled) {
    return null
  }

  return (
    <Script
      strategy="afterInteractive"
      id="clarity-script"
      dangerouslySetInnerHTML={{
        __html: `
      (function(c,l,a,r,i,t,y){
          c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
          t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
          t.addEventListener("load", () => {
            const event = new CustomEvent("${CLARITY_LOADED_EVENT}");
            window.dispatchEvent(event);
          });
          y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
      })(window, document, "clarity", "script", "${CLARITY_ID}");`,
      }}
    />
  )
})
