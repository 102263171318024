import { errorFactory } from '@nori/errors'

import type { KeyOfErrors } from '@nori/errors'

export const { ProductSubscriptionErrors } = errorFactory(
  'ProductSubscriptionErrors',
  {
    GetSubscriptionUnexpectedError: 'Get Subscription Unexpected Error',
    GetSubscriptionUnknownError: 'Get Subscription Unknown Error',
    GetBundleSubscriptionUnexpectedError:
      'Get Bundle Subscription Unexpected Error',
    GetBundleSubscriptionUnknownError: 'Get Bundle Subscription Unknown Error',
    UpdateSubscriptionUnexpectedError: 'Update Subscription Unexpected Error',
    UpdateSubscriptionUnknownError: 'Update Subscription Unknown Error',
  }
)

export type ProductSubscriptionErrorInstance = InstanceType<
  KeyOfErrors<typeof ProductSubscriptionErrors>
>
