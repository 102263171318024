import { useTranslations } from '@nori/lang-i18n'
import { Link, Swap } from '@nori/ui-kit'

import { AutoSaveOrderActionsEnum } from '../../core/autosave-order-actions.enum'

type Props =
  | { availableActions: AutoSaveOrderActionsEnum.None }
  | {
      availableActions: AutoSaveOrderActionsEnum.CancelOnly
      handleChangeStatus: (isSkip?: boolean) => void
    }
  | {
      availableActions: AutoSaveOrderActionsEnum.Reactivate
      handleChangeStatus: () => void
    }
  | {
      availableActions: AutoSaveOrderActionsEnum.Edit
      isEditMode: boolean
      handleChangeStatus: () => void
      handleEditMode: () => void
      canBeSkipped: boolean
    }
  | {
      availableActions: AutoSaveOrderActionsEnum.EditSkip
      isEditMode: boolean
      handleChangeStatus: (isSkip?: boolean) => void
      handleEditMode: () => void
      canBeSkipped: boolean
    }

export const AutoSaveOrderRowActions = (props: Props) => {
  const { availableActions } = props

  const t = useTranslations('autosaveSettings')

  if (availableActions === AutoSaveOrderActionsEnum.None) return null

  if (availableActions === AutoSaveOrderActionsEnum.CancelOnly) {
    return (
      <div className="flex gap-2 lg:mb-16 lg:py-3.5">
        <Link
          size="small"
          weight="bold"
          isUnderline={false}
          iconLeft="CloseLine"
          onClick={() => props.handleChangeStatus()}
        >
          {t('autosaveTable.cancelSubscription')}
        </Link>
      </div>
    )
  }

  if (availableActions === AutoSaveOrderActionsEnum.Reactivate) {
    return (
      <Link
        size="small"
        weight="bold"
        isUnderline={false}
        iconLeft="Refresh"
        onClick={() => props.handleChangeStatus()}
      >
        {t('autosaveTable.reactivateSubscription')}
      </Link>
    )
  }

  return (
    <div>
      <Swap
        is={props.isEditMode}
        isSlot={
          <div className="flex gap-2 lg:mb-11 lg:py-3.5">
            {props.canBeSkipped && (
              <>
                <Link
                  size="small"
                  weight="bold"
                  isUnderline={false}
                  iconLeft="Skip"
                  onClick={() => props.handleChangeStatus(true)}
                  data-test-id="autosave-skip-one-time"
                >
                  {t('autosaveTable.skipOneTime')}
                </Link>
                <div className="h-[18px] border border-gray-100"></div>
              </>
            )}
            <Link
              size="small"
              weight="bold"
              isUnderline={false}
              iconLeft="CloseLine"
              onClick={() => props.handleChangeStatus()}
            >
              {t('autosaveTable.cancelSubscription')}
            </Link>
          </div>
        }
      >
        <div className="flex gap-2 ">
          {(availableActions === AutoSaveOrderActionsEnum.Edit ||
            availableActions === AutoSaveOrderActionsEnum.EditSkip) && (
            <Link
              size="small"
              weight="bold"
              isUnderline={false}
              iconLeft="PencilAlt"
              onClick={() => props.handleEditMode()}
              data-test-id="autosave-edit-subscription"
            >
              {t('autosaveTable.editSubscription')}
            </Link>
          )}
          {availableActions === AutoSaveOrderActionsEnum.EditSkip && (
            <>
              {props.canBeSkipped && (
                <>
                  <div className="h-[18px] border border-gray-100"></div>
                  <Link
                    size="small"
                    weight="bold"
                    isUnderline={false}
                    iconLeft="Skip"
                    onClick={() => props.handleChangeStatus(true)}
                    data-test-id="autosave-skip-one-time"
                  >
                    {t('autosaveTable.skipOneTime')}
                  </Link>
                </>
              )}
            </>
          )}
        </div>
      </Swap>
    </div>
  )
}
