import { getMoneyWithIsoCurrency } from '@nori/app-kit'
import { useTranslations } from '@nori/lang-i18n'

import type { MoneyValueObject } from '@nori/app-kit'

type Props = {
  consultantPrice?: MoneyValueObject
  isFullHeight: boolean
}

export function ConsultantPrice({ consultantPrice, isFullHeight }: Props) {
  const t = useTranslations('productList.item')
  const containerClass = `ml-4 mr-8 flex flex-col text-left font-normal max-lg:m-0 max-lg:w-1/3 lg:w-[4.4rem] ${
    isFullHeight ? 'lg:h-full' : ''
  }`

  return (
    <div className={containerClass}>
      {consultantPrice && (
        <div className="flex flex-col max-lg:ml-auto max-lg:w-fit max-lg:w-min max-sm:gap-0.5">
          <span className="text-xs font-normal text-gray-500">
            {t('consultant')}:
          </span>
          <div
            data-test-id="stylist-price"
            className="text-sm max-sm:font-bold"
          >
            {getMoneyWithIsoCurrency(consultantPrice)}
          </div>
        </div>
      )}
    </div>
  )
}
