import { useEffect } from 'react'

import { observer } from '@nori/di'
import { useContactContext } from '~/modules/contacts/interface/use-contact-context'

import { RightColumn } from './right-column/right-column'
import { LeftColumn } from './left-column'

import type { ContactListItemEntity } from '~/modules/contacts/core'

type Props = {
  isOpen: boolean
  contact: ContactListItemEntity
}

export const ContactListRowExtended = observer(({ isOpen, contact }: Props) => {
  const {
    contactsStore,
    contactsAction,
    contactSubscriptionAction,
    contactSubscriptionStore,
  } = useContactContext()

  const nextOrder = contactSubscriptionStore.nextOrderById[contact.id]
  const isLoading = contactSubscriptionStore.isLoadingById[contact.id]
  const colSpan = contactsStore.visibleColumns.length

  useEffect(() => {
    if (contact.belongsToDifferentDsr) return
    if (!isOpen || nextOrder || isLoading || !contact.customerId) return

    contactSubscriptionAction.handleLoadNextOrder({
      customerId: contact.customerId,
      isPreferredDsr: contact.isPreferredDsr,
    })
    contactsAction.handleLoadContactPerks(contact.customerId)
  }, [isOpen, nextOrder, isLoading, contact])

  if (!isOpen) return null
  return (
    <tr data-test-id="expanded-contact-item" className="bg-gray-50">
      <td colSpan={colSpan}>
        <div
          className={'flex w-screen flex-col px-7 py-12 lg:w-full lg:flex-row'}
        >
          <div className={'flex flex-col gap-4 lg:w-[230px]'}>
            <LeftColumn contact={contact} />
          </div>

          <div className="my-7 h-px self-stretch bg-primary-100 lg:mx-7 lg:my-0 lg:h-auto lg:w-[1px]" />

          <div className={'flex flex-col text-sm lg:w-[700px]'}>
            <RightColumn contact={contact} />
          </div>
        </div>
      </td>
    </tr>
  )
})
