import { hookContextFactory } from '@nori/di'

import { MoveOrderAction } from '../actions/move-order.action'
import { OrderDetailsAction } from '../actions/order-details.action'
import { OrdersListAction } from '../actions/orders-list.action'
import { OrdersListExportsAction } from '../actions/orders-list-exports.action'
import { OrdersListFiltersAction } from '../actions/orders-list-filters.action'
import { MoveOrderStore } from '../stores/move-order.store'
import { OrderDetailsStore } from '../stores/order-details.store'
import { OrdersListStore } from '../stores/orders-list.store'
import { OrdersListFiltersStore } from '../stores/orders-list-filters.store'

export const { useModuleContext: useOrdersListContext } = hookContextFactory({
  ordersListAction: OrdersListAction,
  ordersListStore: OrdersListStore,
  orderDetailsStore: OrderDetailsStore,
  orderDetailsAction: OrderDetailsAction,
  ordersListFiltersStore: OrdersListFiltersStore,
  ordersListFiltersAction: OrdersListFiltersAction,
  ordersListExportsAction: OrdersListExportsAction,
  moveOrderAction: MoveOrderAction,
  moveOrderStore: MoveOrderStore,
})
