import { useCallback, useEffect, useState } from 'react'

import {
  type CartItemEntity,
  useProductsContext,
  useProfileContext,
} from '@nori/app-kit'
import { observer } from '@nori/di'
import { useTranslations } from '@nori/lang-i18n'
import { useNotifyContext } from '@nori/notify'
import { useCartContext } from '~/modules/cart/interface/context/use-cart-context'
import { useProductCartContext } from '~/modules/product-cart/interface/use-product-cart.contact'
import { useProductSubscriptionContext } from '~/modules/product-subscription/interface/product-subscription.context'
import { useProductsLoungeContext } from '~/modules/products/interface/use-products-context'

import { ProductItemDesktop } from './desktop/product-item-desktop'
import { ProductItemMobile } from './mobile/product-item-mobile'

type Props = {
  product: CartItemEntity
}

const MIN_QUANTITY = 1

export const ProductItem = observer(({ product }: Props) => {
  const { productCartAction, productCartStore } = useProductCartContext()
  const { cartStore, cartAction } = useCartContext()
  const { styleDetailsAction: styleDetailsLoungeAction } =
    useProductsLoungeContext()
  const { styleDetailsAction, styleDetailsStore } = useProductsContext()
  const { userStore } = useProfileContext()
  const t = useTranslations('productList.item')
  const { productSubscriptionAction, productSubscriptionStore } =
    useProductSubscriptionContext()
  const { notifyAction } = useNotifyContext()

  const { cart } = cartStore
  const cartCurrency = cart?.currency ?? userStore.currency

  const {
    isRemovingFromCart,
    isProductSwitchInCart,
    isApplyingHostessDiscount,
  } = productCartStore

  const [isEditMode, setIsEditMode] = useState(false)
  const [itemQuantity, setItemQuantity] = useState(`${product.quantity}`)
  const [error, setError] = useState<string>()

  const isPromoGenerated = product.visibleByOverride

  const isProductLoading =
    isProductSwitchInCart || isRemovingFromCart || isApplyingHostessDiscount

  const { canBeAutoshipped } =
    productSubscriptionStore.getProductSubscriptions({
      productId: product.productId,
    }) || {}

  useEffect(() => {
    setItemQuantity(`${product.quantity}`)
  }, [isEditMode, product.quantity])

  useEffect(() => {
    productSubscriptionAction.handleGetProductSubscriptionOptions({
      productId: product.productId,
    })
  }, [product.productId])

  const { selectedStyle } = styleDetailsStore

  useEffect(() => {
    if (!product.styleId) return
    styleDetailsAction.handleLoad(product.productId.toString())
  }, [product.styleId])

  const handleCancel = useCallback(() => {
    setItemQuantity(`${product.quantity}`)
    setIsEditMode(false)
    setError(undefined)
  }, [product.quantity])

  const handleAddToCart = useCallback(() => {
    const num = Number(itemQuantity)
    if (isNaN(num) || num < MIN_QUANTITY) {
      setError(t('invalidQuantity'))
      return
    }

    if (!product.skuId) return
    if (num > product.maxInCart) {
      notifyAction.handleAddNotify({
        type: 'danger',
        title: t('maxQuantity') + String(product.maxInCart),
      })
      return
    }

    setError(undefined)
    setIsEditMode(false)
    productCartAction.handleSwitchProductInCart(product, num)
  }, [itemQuantity, product, productCartAction, t])

  if (!cart) return null

  return (
    <div data-test-id="product-item">
      <div className="lg:invisible lg:hidden">
        <ProductItemMobile
          error={error}
          product={product}
          currentQuantity={itemQuantity.toString()}
          handleCancel={handleCancel}
          handleItemQuantityChange={setItemQuantity}
          isEditMode={isEditMode}
          isProductLoading={isProductLoading}
          onAdd={handleAddToCart}
          currency={cartCurrency}
          onRemove={() => cartAction.handleRemoveFromCart(product)}
          onEditSwitch={() => setIsEditMode(true)}
          onAddWishlist={() =>
            productCartAction.handleAddProductToWishList(product)
          }
          canBeAutoShipped={canBeAutoshipped}
          isWishButtonEnabled={
            userStore.isOSEnabled || cartStore.isPersonalCart
          }
          isPromoGenerated={isPromoGenerated}
          selectedStyle={selectedStyle}
        />
      </div>
      <div className="invisible hidden lg:visible lg:block">
        <ProductItemDesktop
          error={error}
          product={product}
          currentQuantity={itemQuantity.toString()}
          handleCancel={handleCancel}
          handleItemQuantityChange={setItemQuantity}
          isEditMode={isEditMode}
          isProductLoading={isProductLoading}
          currency={cartCurrency}
          onAdd={handleAddToCart}
          onRemove={() => cartAction.handleRemoveFromCart(product)}
          onEditSwitch={() => setIsEditMode(true)}
          onAddWishlist={() =>
            productCartAction.handleAddProductToWishList(product)
          }
          canBeAutoShipped={canBeAutoshipped}
          isWishButtonEnabled={
            userStore.isOSEnabled || cartStore.isPersonalCart
          }
          isPromoGenerated={isPromoGenerated}
        />
      </div>
    </div>
  )
})
