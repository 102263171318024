import { Service } from '@nori/di'

import type { ApplyCouponCodeRequest } from '../types/apply-coupon-code.request'
import type { CouponCodeInput } from '../types/coupon-code.input'

@Service()
export class CouponCodeMapper {
  toApplyCouponCodeRequest(input: CouponCodeInput): ApplyCouponCodeRequest {
    return {
      code: input.code,
    }
  }
}
