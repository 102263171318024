import { observer } from '@nori/di'
import { useTranslations } from '@nori/lang-i18n'
import { Button } from '@nori/ui-kit'

import { useContactManageMailingContext } from '../../interface/use-contact-manage-mailing-context'

export const ContactManageMailingModalFooter = observer(() => {
  const t = useTranslations('contacts.manageMailingModal')
  const { contactManageMailingAction, сontactManageMailingStore } =
    useContactManageMailingContext()

  if (!сontactManageMailingStore.isContactsTouched) return null

  return (
    <div className="sticky bottom-0 bg-white">
      <div className="mb-2">
        <Button
          widthType="full"
          onClick={
            contactManageMailingAction.handleSaveContactsManageMailingData
          }
        >
          {t('save')}
        </Button>
      </div>

      <Button
        widthType="full"
        isOutline
        onClick={
          contactManageMailingAction.handleToggleContactManageMailingModal
        }
      >
        {t('cancel')}
      </Button>
    </div>
  )
})
