import {
  FEATURE_FLAGS_LIST,
  FeatureFlagsService,
  useFeatureFlagsContext
} from "./chunk-BS446X6Y.mjs";
import {
  VIEW_TYPE_KEY,
  useRouteContext
} from "./chunk-5FU32FX2.mjs";
import {
  DEFAULT_REPORT,
  PageLoader,
  REPORT_NAME_KEY,
  React,
  SELECTED_USER_ID_KEY,
  __name,
  getQueryParam,
  isServer,
  useReportContext
} from "./chunk-P4TY67WJ.mjs";

// src/setup.ts
import "reflect-metadata";
import { enableStaticRendering } from "@nori/di";
enableStaticRendering(isServer);

// src/modules/module-reports/ui/report.tsx
import { observer as observer2 } from "@nori/di";

// src/modules/module-reports/ui/use-report.ts
import { useEffect, useRef } from "react";

// src/modules/module-reports/interface/get-brand.ts
function getBrand() {
  const domain = window.location.hostname;
  switch (true) {
    case domain.includes("norwex"):
      return "norwex";
    case domain.includes("stelladot"):
      return "stelladot";
    case domain.includes("keep"):
      return "keep";
    case domain.includes("ever"):
      return "ever";
    default:
      return getQueryParam("platform") || getQueryParam("brand") || "norwex";
  }
}
__name(getBrand, "getBrand");

// src/modules/module-reports/interface/get-env-run.ts
function getEnvRun() {
  const host = window.location.hostname;
  if (host.search("local") !== -1) {
    return "local";
  }
  if (host.search("qa") !== -1) {
    return "qa";
  }
  if (host.search("stg") !== -1) {
    return "staging";
  }
  return "production";
}
__name(getEnvRun, "getEnvRun");

// src/modules/module-reports/interface/get-report-name.ts
function getReportName() {
  const reportFromParam = getQueryParam(REPORT_NAME_KEY);
  return reportFromParam || DEFAULT_REPORT;
}
__name(getReportName, "getReportName");

// src/modules/module-reports/ui/use-report.ts
function useReport({ type, reportNameQuery, viewTypeNameQuery, updateUrlCallback }) {
  const { manageReportAction } = useReportContext();
  const reportName = getReportName();
  const ref = useRef("");
  const isSame = reportNameQuery ? ref.current === reportNameQuery : ref.current === reportName;
  ref.current = reportName;
  const { routeAction } = useRouteContext();
  useEffect(() => {
    if (!viewTypeNameQuery) {
      routeAction.removeStateParams([
        VIEW_TYPE_KEY
      ]);
    }
  }, [
    viewTypeNameQuery
  ]);
  if (!isSame) {
    manageReportAction.handleInitLoad({
      type,
      reportName,
      brand: getBrand(),
      envRun: getEnvRun(),
      selectedUserId: getQueryParam(SELECTED_USER_ID_KEY),
      updateUrlCallback
    });
  }
}
__name(useReport, "useReport");

// src/modules/module-reports/ui/used-report.tsx
import { lazy, Suspense } from "react";
import { useI18nContext } from "@nori/app-kit";
import { observer } from "@nori/di";
import { blockTranslator, NextIntlProvider } from "@nori/lang-i18n";
import { Swap } from "@nori/ui-kit";

// src/modules/module-reports/ui/error-block.tsx
function ErrorBlock({ children }) {
  return /* @__PURE__ */ React.createElement("div", {
    className: "m-10"
  }, /* @__PURE__ */ React.createElement("h5", {
    className: "mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white"
  }, children));
}
__name(ErrorBlock, "ErrorBlock");

// src/modules/module-reports/ui/use-lang-loader.ts
import { useEffect as useEffect2, useState } from "react";

// src/modules/i18n/interface/get-messages.ts
import { mergeMessages } from "@nori/lang-i18n";

// ../../packages/app-kit/i18n/messages/en-ca.json
var en_ca_default = {
  appkit: {
    Auth: {},
    address: {
      zipCodeFormat: "Postal code must be in a format T2X 1V4 or T2X1V4"
    },
    autoSave: {
      emptyState: {
        getOff: "Get <bold>10</bold>% Off",
        getRewardDescription: "Get rewarded for being a loyal customer! Earn discounts and free shipping on the products you use every day.",
        shopEligible: "Shop Eligible Products",
        howAutosaveWorks: "How AutoSave Works:",
        footerDescription: "*Order may include both AutoSave and full-priced items.",
        chooseFrequency: "Choose frequency",
        chooseFrequencyDescription: "Choose your products and shipment frequency.",
        save: "Save",
        saveDescription: "Enjoy 10% OFF every recurring order.",
        freeShipping: "Free shipping",
        freeShippingDescription: "Secure FREE SHIPPING when your order reaches $200*",
        saveMore: "Save more",
        saveMoreDescription: "In future months, add a new subscription and receive 10% OFF the FIRST order!"
      }
    },
    changeGeolocationModal: {
      youreOnAnotherLocation: "It looks like you're in {location}.",
      wouldYouUpdateLocation: "Would you like to update your location?",
      chooseLocation: "Choose Location",
      stayOnSite: "Stay On Site"
    }
  }
};

// ../../packages/app-kit/i18n/messages/en-us.json
var en_us_default = {
  appkit: {
    newConsultants: {
      title: "My Bright Start",
      endDay: "End Date - {dayTitle}",
      remainingDay: "Day {count}",
      details: "Earn free product and credits when you achieve your sales goals.",
      empty: "Empty data",
      openReport: "View My Team Bright Start Tracker",
      recruitTitle: "My Recruiting",
      recruitDetails: "See program details in the Bright Start Brochure",
      left: "left"
    },
    Auth: {
      signIn: {
        title: "Consultant Sign In",
        sectionTitle: "Sign in to the Consultant Office",
        submit: "Sign In",
        createAnAccount: "Learn how you can become one!",
        emailOrUsername: "Email or Consultant ID",
        password: "Password",
        forgotPassword: "Forgot Password?",
        notANorwexConsultantYet: "Not a Norwex Consultant yet?",
        error: "Login or password is invalid!"
      },
      forgotPassword: {
        title: "Forgot your password?",
        sectionTitle: "No problem. Enter your email address below and we'll send you an email to reset your password.",
        submit: "Send Email",
        emailAddress: "Email Address",
        success: "In the next 10 minutes you should receive an email with instructions on how to reset your password.",
        error: "Email address is invalid!",
        back: "Back"
      },
      signUp: {
        createAnAccount: "Create an Account",
        name: "Name",
        firstName: "First Name",
        lastName: "Last Name",
        email: "Email",
        password: "Password",
        passwordRules: "Password must be 8+ characters with 1 uppercase, 1 lowercase, 1 special character & 1 number.",
        birthDayTitle: "When is your Birthday? (Optional)",
        birthDaySubTitle: "So we can send you something to celebrate!",
        createAccount: "Create Account",
        alreadyHaveAnAccount: "Already have an Account?",
        signIn: "Sign in",
        month: "Birth Month",
        day: "Birth Day",
        error: "Create account error",
        emailConsent: "Receive the latest Norwex tips, trends and exclusive offers via email.",
        termsConsent: "I have read and accepted the Terms of Use and Privacy Policy.",
        termsConsentHint: "Please accept the Terms of Use to create your account.",
        store: {
          us: "United States",
          ca: "Canada"
        },
        searchConsultant: {
          title: "Select a Norwex Consultant (Optional)",
          placeholder: "Search Consultant by name",
          mentorSearchFailed: "Failed to load mentors",
          noResults: "No results found",
          loadMentorInfoFailed: "Failed to load mentor info. Please try again",
          change: "Change"
        }
      },
      resetPassword: {
        resetPassword: "Reset your password",
        pleaseCreateNewPassword: "Please create a new password",
        repeatPassword: "Repeat Password",
        createPassword: "Create Password",
        error: "Error while reset password"
      }
    },
    settings: {
      password: {
        title: "Password",
        currentPassword: "Current Password",
        newPassword: "New Password",
        repeatNewPassword: "Repeat New Password",
        passwordRequirement: "Password must be 8+ characters with 1 uppercase, 1 lowercase, 1 special character & 1 number",
        save: "Save"
      }
    },
    address: {
      addressLine1: "Address Line 1",
      addressLine2: "Address Line 2 (optional)",
      streetName: "Street Name, Number, PO Box, C/O",
      apartment: "(Optional) Apartment, suite, unit, etc.",
      postalCode: "Postal Code",
      zipCode: "Zip Code",
      city: "City",
      country: "Select Country",
      state: "Select State",
      province: "Select Province",
      firstName: "First Name",
      lastName: "Last Name",
      phone: "Phone",
      zipCodeFormat: "Zip code must be in a format 12345 or 12345-6789",
      phonePlaceholder: "Phone Number"
    },
    profile: {
      birthDay: "Birth Day",
      birthMonth: "Birth Month"
    },
    photo: {
      adjustPhoto: "Adjust Photo",
      changePhoto: "Change photo",
      uploadPhoto: "Upload photo",
      removePhoto: "Remove photo",
      save: "Save",
      note: "Use a photo that you have the rights to publish. The photo should present you and your Norwex business professionally, and be consistent with Norwex image, reputation and policies. Norwex has the right to remove any image at its sole discretion.",
      cancel: "Cancel"
    },
    iconLegend: {
      title: "Icon legend",
      zeroDollarRetail: "$0 Retail",
      qualifiedParty: "Qualified Event",
      inPersonParty: "In Person Event",
      onlineParty: "Online Event",
      retail: " Retail"
    },
    dateFilter: {
      applyButton: "Apply",
      clearButton: "Clear All",
      dateRangeTab: "Date Range",
      customDateTab: "Custom Date",
      thisMonth: "This Month",
      nextMonth: "Next Month",
      last45: "Last 45 Days",
      last60: "Last 60 Days",
      thisYear: "This Year",
      date: "Date",
      next30: "Next 30 Days",
      next60: "Next 60 Days",
      next90: "Next 90 Days"
    },
    cardBlock: {
      cardholderName: "Cardholder Name",
      cardNumber: "Card Number",
      expirationDate: "Expiration Date",
      cvv: "CVV/CVC",
      cvvTooltip: {
        title: "Where do I find the CVV?",
        title3Digit: "Visa & Mastercard",
        desc3Digit: "3-digit code on back",
        title4Digit: "American Express",
        desc4Digit: "4-digit code on front"
      },
      firstName: "Card Holder First Name",
      lastName: "Card Holder Last Name",
      year: "Expiration Year",
      month: "Expiration Month",
      yearError: "Invalid expiration year",
      required: "Required",
      invalidCardNumber: "Card number is invalid",
      invalidExpirationMonth: "Expiration month is invalid",
      invalidExpirationYear: "Expiration year is invalid"
    },
    productList: {
      item: {
        add: "Add",
        salePrice: "Sale Price",
        retailPrice: "Retail Price",
        maxPrice: "Max Price"
      },
      cell: {
        name: "Name: {value}",
        outOfStock: "Out of stock!",
        callout: "Callout: {value}"
      },
      details: {
        salePrice: "Sale Price",
        retail: "Retail Price",
        ambassador: "Ambassador",
        value: "Value",
        estimatedCommission: "Estimated commission: "
      }
    },
    braintree: {
      errors: {
        getTokenError: "Braintree Client Initialization Error: Failed To Get Client Token",
        unexpectedClientInitError: "Braintree Client Initialization Error: Unexpected Error",
        paypalInitClientUndefined: "Braintree PayPal Initialization Error: Client Not Defined",
        unexpectedPaypalInitError: "Braintree PayPal Initialization Error: Unexpected Error",
        vaultInitClientUndefined: "Braintree Vault Initialization Error: Client Not Defined",
        unexpectedVaultInitError: "Braintree Vault Initialization Error: Unexpected Error",
        tokenizePaypalUndefined: " Braintree PayPal Tokenization Error: PayPal Not Defined",
        unexpectedTokenizePaypalError: "Braintree PayPal Error: Unexpected Tokenization Error",
        createHostedFieldsClientUndefined: "Braintree Hosted Fields Creation Error: Client Not Defined",
        unexpectedCreateHostedFieldsError: "Braintree Hosted Fields Creation Unexpected Error",
        hostedFieldsValidationError: "Braintree Hosted Fields Validation Error: {fields}",
        unexpectedTokenizeHostedFieldsError: "Braintree Hosted Fields: Unexpected Tokenization Error",
        removePaymentMethodClientUndefined: "Braintree Remove Payment Method Error: Client Not Defined",
        removePaymentMethodVaultUndefined: "Braintree Remove Payment Method Error: Vault Not Defined",
        unexpectedPaypalCheckoutError: "Braintree PayPal Checkout Error: Unexpected Error",
        checkoutFailed: "Please verify your payment details are correct",
        checkoutTaxIsNotCalculated: "Oops, something went wrong. Please wait while we recalculate your shopping cart",
        checkoutDisabled: "Placing orders is temporarily unavailable."
      },
      payments: {
        selectSavedCard: "Select your Saved Credit Card",
        selectNewCard: "Enter a New Card",
        creditCard: "Credit Card",
        noCardsSaved: "No cards saved",
        cardNumber: "Card Number",
        cvv: "CVV/CVC",
        expirationMonth: "Expiration Date",
        expirationYear: "Expiration Year",
        months: {
          january: "01 - January",
          february: "02 - February",
          march: "03 - March",
          april: "04 - April",
          may: "05 - May",
          june: "06 - June",
          july: "07 - July",
          august: "08 - August",
          september: "09 - September",
          october: "10 - October",
          november: "11 - November",
          december: "12 - December"
        }
      }
    },
    paymentMethods: {
      errors: {
        loadPaymentMethodsFailure: "Failed to load payment methods",
        createPaymentMethodUserIdUndefined: "Create Payment Method Error: user ID is not defined",
        createCardPaymentMethodFailure: "Failed to create card payment method",
        createPaypalPaymentMethodFailure: "Failed to create PayPal payment method",
        createPaypalPaymentMethodAlreadyExists: "Failed to create: PayPal payment method already exists for this user",
        deletePaymentMethodFailure: "Failed to delete payment method",
        changePrimaryPaymentMethodFailure: "Failed to change primary payment method",
        missingPayPalAccount: "Failed to add PayPal Payment Method: Missing PayPal Account"
      }
    },
    columnSelectionModal: {
      columns: "Columns",
      apply: "Apply",
      clear: "Clear all"
    },
    exportAll: {
      pdf: "PDF",
      title: "Export All",
      errorCsv: "Seems CSV export failed",
      processing: "Processing",
      processingTotal: "Processing ({amount}/{total})",
      download: "Download"
    },
    common: {
      months: {
        january: "January",
        february: "February",
        march: "March",
        april: "April",
        may: "May",
        june: "June",
        july: "July",
        august: "August",
        september: "September",
        october: "October",
        november: "November",
        december: "December"
      }
    },
    cart: {
      cartItem: {
        qty: "Qty: ",
        autosaveFrequency: "AutoSave Frequency: "
      },
      cartBundle: {
        productDetails: "Product Details"
      }
    },
    stickyBottomButton: {
      items: "{count, plural, =1 {Item: 1} other {Items: #}}",
      inRetailSales: "{value} in Personal Retail Sales",
      subtotal: "Subtotal: "
    },
    rewardsInfo: {
      loadFail: "Failed to load rewards info"
    },
    languageSwitcher: {
      "en-US": "English",
      "en-CA": "English",
      "es-US": "Spanish",
      "fr-CA": "Fran\xE7aise"
    },
    autoSave: {
      emptyState: {
        getOff: "Get <bold>10</bold>% Off",
        getRewardDescription: "Get rewarded for being a loyal customer! Earn discounts and free shipping on the products you use every day.",
        shopEligible: "Shop Eligible Products",
        howAutosaveWorks: "How AutoSave Works:",
        footerDescription: "*Order may include both AutoSave and full-priced items.",
        chooseFrequency: "Choose frequency",
        chooseFrequencyDescription: "Choose your products and shipment frequency.",
        save: "Save",
        saveDescription: "Enjoy 10% OFF every recurring order.",
        freeShipping: "Free shipping",
        freeShippingDescription: "Secure FREE SHIPPING when your order reaches $150*",
        saveMore: "Save more",
        saveMoreDescription: "In future months, add a new subscription and receive 10% OFF the FIRST order!"
      },
      autoSaveItem: "AutoSave Item"
    },
    changeGeolocationModal: {
      youreOnAnotherLocation: "It looks like you're in {location}.",
      wouldYouUpdateLocation: "Would you like to update your location?",
      chooseLocation: "Choose Location",
      stayOnSite: "Stay On Site"
    },
    totalPriceSummary: {
      hostessDiscount: "{percentage}% Off",
      storeCredit: "Store Credit",
      norwexDiscount: "Norwex Discount",
      dsrDiscount: "35% Consultant Discount"
    }
  },
  Country: {
    us: "United States",
    ca: "Canada"
  }
};

// ../../packages/app-kit/i18n/messages/es-us.json
var es_us_default = {
  appkit: {
    autoSave: {
      autoSaveItem: "Art\xEDculo de AutoAhorro"
    },
    newConsultants: {
      title: "Mi brillante comienzo",
      endDay: "Mi Bright Start termina el {dayTitle}",
      remainingDay: "D\xEDa {count}",
      details: "Gana productos gratis y cr\xE9ditos al alcanzar tus metas de ventas.",
      empty: "datos vacios",
      openReport: "Ver el rastreador Bright Start de mi equipo",
      recruitTitle: "mi reclutamiento",
      recruitDetails: "Vea los detalles del programa en el folleto de Bright Start",
      left: "izquierda"
    },
    Auth: {
      signIn: {
        title: "Iniciar sesi\xF3n como Consultora",
        sectionTitle: "Inicia una sesi\xF3n en la Oficina de la Consultora",
        submit: "Iniciar sesi\xF3n",
        createAnAccount: "\xA1Ent\xE9rate c\xF3mo ser Consultora Norwex!",
        emailOrUsername: "Correo electr\xF3nico o ID de Consultora",
        password: "Contrase\xF1a",
        forgotPassword: "\xBFHas olvidado tu contrase\xF1a?",
        notANorwexConsultantYet: "\xBFA\xFAn no eres Consultora Norwex?",
        error: "\xA1El inicio de sesi\xF3n o la contrase\xF1a no son v\xE1lidos!"
      },
      forgotPassword: {
        title: "\xBFOlvidaste tu contrase\xF1a?",
        sectionTitle: "Ning\xFAn problema. Ingresa tu direcci\xF3n de correo electr\xF3nico a continuaci\xF3n y te enviaremos un correo electr\xF3nico para restablecer tu contrase\xF1a.",
        submit: "Enviar correo electr\xF3nico",
        emailAddress: "Direcci\xF3n de correo electr\xF3nico",
        success: "En los pr\xF3ximos 10 minutos deber\xEDas recibir un correo electr\xF3nico con instrucciones sobre c\xF3mo restablecer tu contrase\xF1a.",
        error: "\xA1Direcci\xF3n de correo electr\xF3nico es inv\xE1lida!",
        back: "Atr\xE1s"
      },
      signUp: {
        createAnAccount: "Crea una cuenta",
        name: "Nombre",
        firstName: "Nombre de pila",
        lastName: "Apellido",
        email: "Correo electr\xF3nico",
        password: "Contrase\xF1a",
        passwordRules: "La contrase\xF1a debe tener m\xE1s de 8 caracteres, 1 may\xFAscula, 1 min\xFAscula, 1 car\xE1cter especial y 1 n\xFAmero.",
        birthDayTitle: "\xBFCu\xE1ndo es tu cumplea\xF1os? (opcional)",
        birthDaySubTitle: "\xA1As\xED podremos enviarte algo para celebrar!",
        createAccount: "Crear una cuenta",
        alreadyHaveAnAccount: "\xBFYa tienes una cuenta?",
        signIn: "Iniciar sesi\xF3n",
        month: "Mes",
        day: "D\xEDa",
        error: "Error al crear cuenta",
        emailConsent: "Reciba los \xFAltimos consejos, tendencias y ofertas exclusivas de Norwex por correo electr\xF3nico.",
        termsConsent: "He le\xEDdo y aceptado los T\xE9rminos de uso y la Pol\xEDtica de privacidad.",
        termsConsentHint: "Por favor, acepte los T\xE9rminos de Uso para crear su cuenta.",
        store: {
          us: "Estados Unidos",
          ca: "Canad\xE1"
        },
        searchConsultant: {
          title: "Seleccione un Consultor Norwex (Opcional)",
          placeholder: "Buscar Consultor por nombre",
          mentorSearchFailed: "Error al cargar mentores",
          noResults: "No se han encontrado resultados",
          loadMentorInfoFailed: "Error al cargar la informaci\xF3n del mentor. Por favor, int\xE9ntelo de nuevo",
          change: "Cambiar"
        }
      }
    },
    settings: {
      password: {
        title: "Contrase\xF1a",
        currentPassword: "Contrase\xF1a actual",
        newPassword: "Nueva contrase\xF1a",
        repeatNewPassword: "Repita la nueva contrase\xF1a",
        passwordRequirement: "La contrase\xF1a debe tener m\xE1s de 8 caracteres, 1 may\xFAscula, 1 min\xFAscula, 1 car\xE1cter especial y 1 n\xFAmero.",
        save: "Guardar"
      }
    },
    address: {
      addressLine1: "Direcci\xF3n L\xEDnea 1",
      addressLine2: "L\xEDnea de direcci\xF3n 2 (opcional)",
      streetName: "Nombre de la calle, n\xFAmero, apartado postal, C/O",
      apartment: "(Opcional) Apartamento, suite, unidad, etc.",
      postalCode: "C\xF3digo Postal",
      zipCode: "C\xF3digo postal",
      city: "Ciudad",
      country: "Seleccionar pa\xEDs",
      state: "Seleccione estado",
      province: "Seleccionar Provincia",
      firstName: "Nombre de pila",
      lastName: "Apellido",
      phone: "Tel\xE9fono",
      zipCodeFormat: "El c\xF3digo postal debe estar en un formato 12345 o 12345-6789",
      phonePlaceholder: "N\xFAmero de Tel\xE9fono"
    },
    profile: {
      birthDay: "Cumplea\xF1os",
      birthMonth: "Mes de nacimiento"
    },
    photo: {
      adjustPhoto: "Ajustar foto",
      changePhoto: "Cambiar foto",
      uploadPhoto: "Subir foto",
      removePhoto: "Quitar foto",
      save: "Ahorrar",
      note: "Utilice una fotograf\xEDa que tenga derecho a publicar. La fotograf\xEDa debe presentarte a ti y a tu negocio Norwex de manera profesional y ser consistente con la imagen, reputaci\xF3n y pol\xEDticas de Norwex. Norwex tiene el derecho de eliminar cualquier imagen a su exclusivo criterio.",
      cancel: "Cancelar"
    },
    iconLegend: {
      title: "Leyenda del icono",
      zeroDollarRetail: "$0 al por menor",
      qualifiedParty: "Evento calificado",
      inPersonParty: "Evento en persona",
      onlineParty: "Evento en l\xEDnea",
      retail: " Minorista"
    },
    dateFilter: {
      applyButton: "Aplicar",
      clearButton: "Limpiar todo",
      dateRangeTab: "Rango de fechas",
      customDateTab: "Fecha personalizada",
      thisMonth: "Este mes",
      nextMonth: "Pr\xF3ximo mes",
      last45: "\xDAltimos 45 d\xEDas",
      last60: "\xDAltimos 60 d\xEDas",
      thisYear: "Este a\xF1o",
      date: "Fecha"
    },
    cardBlock: {
      cardholderName: "Nombre del Titular de la Tarjeta",
      cardNumber: "N\xFAmero de Tarjeta",
      expirationDate: "Fecha de Vencimiento",
      cvv: "CVV/CVC",
      cvvTooltip: {
        title: "\xBFD\xF3nde encuentro el CVV?",
        title3Digit: "Visa & Mastercard",
        desc3Digit: "C\xF3digo de 3 d\xEDgitos en la parte trasera",
        title4Digit: "American Express",
        desc4Digit: "C\xF3digo de 4 d\xEDgitos en el frente"
      },
      firstName: "Nombre del Titular de la Tarjeta",
      lastName: "Apellido del Titular de la Tarjeta",
      year: "A\xF1o de Vencimiento",
      month: "Mes de Vencimiento",
      yearError: "A\xF1o de vencimiento inv\xE1lido",
      required: "Requerido",
      invalidCardNumber: "N\xFAmero de tarjeta inv\xE1lido",
      invalidExpirationMonth: "El mes de vencimiento es inv\xE1lido",
      invalidExpirationYear: "El a\xF1o de vencimiento es inv\xE1lido"
    },
    productList: {
      item: {
        add: "Agregar",
        salePrice: "Precio de venta",
        retailPrice: "Precio al por menor",
        maxPrice: "Precio m\xE1ximo"
      },
      cell: {
        name: "Nombre: {valor}",
        outOfStock: "\xA1Agotado!",
        callout: "Llamada: {valor}"
      },
      details: {
        salePrice: "Precio de venta",
        retail: "Precio al por menor",
        ambassador: "Consultora",
        value: "Valor",
        estimatedCommission: "Comisi\xF3n estimada:"
      }
    },
    stickyBottomButton: {
      items: "{count, plural, =1 {Elemento: 1} other {Elementos: #}}",
      inRetailSales: "{value} en Ventas al Detalle Personales",
      subtotal: "Total parcial: "
    },
    braintree: {
      errors: {
        getTokenError: "Error de inicializaci\xF3n del cliente Braintree: no se pudo obtener el token del cliente",
        unexpectedClientInitError: "Error de inicializaci\xF3n del cliente Braintree: error inesperado",
        paypalInitClientUndefined: "Error de inicializaci\xF3n de Braintree PayPal: cliente no definido",
        unexpectedPaypalInitError: "Error de inicializaci\xF3n de Braintree PayPal: error inesperado",
        vaultInitClientUndefined: "Error de inicializaci\xF3n de Braintree Vault: cliente no definido",
        unexpectedVaultInitError: "Error de inicializaci\xF3n de Braintree Vault: error inesperado",
        tokenizePaypalUndefined: " Error de tokenizaci\xF3n de PayPal de Braintree: PayPal no definido",
        unexpectedTokenizePaypalError: "Error de Braintree PayPal: error de tokenizaci\xF3n inesperado",
        createHostedFieldsClientUndefined: "Error de creaci\xF3n de campos alojados en Braintree: cliente no definido",
        unexpectedCreateHostedFieldsError: "Error inesperado en la creaci\xF3n de campos alojados en Braintree",
        hostedFieldsValidationError: "Error de validaci\xF3n de campos alojados en Braintree: {campos}",
        unexpectedTokenizeHostedFieldsError: "Campos alojados en Braintree: error de tokenizaci\xF3n inesperado",
        removePaymentMethodClientUndefined: "Error de eliminaci\xF3n del m\xE9todo de pago de Braintree: Cliente no definido",
        removePaymentMethodVaultUndefined: "Error de eliminaci\xF3n del m\xE9todo de pago de Braintree: B\xF3veda no definida",
        unexpectedPaypalCheckoutError: "Error de pago de Braintree PayPal: error inesperado",
        checkoutFailed: "Por favor, verifica que tus detalles de pago sean correctos.",
        checkoutTaxIsNotCalculated: "Oups, quelque chose s'est mal pass\xE9. Veuillez patienter pendant que nous recalculons votre panier d'achat.",
        checkoutDisabled: "Realizar pedidos no est\xE1 disponible temporalmente."
      },
      payments: {
        selectSavedCard: "Selecciona tu Tarjeta de Cr\xE9dito Guardada",
        selectNewCard: "Introduce una Nueva Tarjeta",
        creditCard: "Tarjeta de Cr\xE9dito",
        noCardsSaved: "No hay tarjetas guardadas",
        cardNumber: "N\xFAmero de Tarjeta",
        cvv: "CVV",
        expirationMonth: "Mes de Expiraci\xF3n",
        expirationYear: "A\xF1o de Expiraci\xF3n",
        months: {
          january: "01 - Enero",
          february: "02 - Febrero",
          march: "03 - Marzo",
          april: "04 - Abril",
          may: "05 - Mayo",
          june: "06 - Junio",
          july: "07 - Julio",
          august: "08 - Agosto",
          september: "09 - Septiembre",
          october: "10 - Octubre",
          november: "11 - Noviembre",
          december: "12 - Diciembre"
        }
      }
    },
    paymentMethods: {
      errors: {
        loadPaymentMethodsFailure: "No se pudieron cargar los m\xE9todos de pago",
        createPaymentMethodUserIdUndefined: "Error al crear m\xE9todo de pago: el ID de usuario no est\xE1 definido",
        changePaypalPaymentMethodUserIdUndefined: "Error al cambiar el m\xE9todo de pago de Paypal: el ID de usuario no est\xE1 definido",
        createCardPaymentMethodFailure: "No se pudo crear el m\xE9todo de pago con tarjeta",
        createPaypalPaymentMethodFailure: "No se pudo crear el m\xE9todo de pago de PayPal",
        createPaypalPaymentMethodAlreadyExists: "No se pudo crear: el m\xE9todo de pago de PayPal ya existe para este usuario",
        deletePaymentMethodFailure: "No se pudo eliminar el m\xE9todo de pago",
        changePrimaryPaymentMethodFailure: "No se pudo cambiar el m\xE9todo de pago principal",
        changePaypalPaymentMethodFailure: "No se pudo cambiar el m\xE9todo de pago de PayPal",
        missingPayPalAccount: "Error al crear el m\xE9todo de pago PayPal: Falta cuenta de PayPal"
      }
    },
    columnSelectionModal: {
      columns: "columnas",
      apply: "Aplicar",
      clear: "Limpiar todo"
    },
    exportAll: {
      pdf: "PDF",
      title: "Exportar todo",
      errorCsv: "Parece que la exportaci\xF3n CSV fall\xF3"
    },
    common: {
      months: {
        january: "Enero",
        february: "Febrero",
        march: "Marzo",
        april: "Abril",
        may: "Puede",
        june: "Junio",
        july: "Julio",
        august: "Agosto",
        september: "Septiembre",
        october: "Octubre",
        november: "Noviembre",
        december: "Diciembre"
      }
    },
    rewardsInfo: {
      loadFail: "Fallo al cargar la informaci\xF3n de las recompensas"
    },
    languageSwitcher: {
      "en-US": "Ingl\xE9s",
      "en-CA": "English",
      "es-US": "Espa\xF1ol",
      "fr-CA": "Fran\xE7aise"
    },
    autoSave: {
      emptyState: {
        getOff: "Obt\xE9n <bold>10</bold>% de Descuento",
        getRewardDescription: "\xA1Recibe recompensas por ser un cliente leal! Gana descuentos y env\xEDo gratis en los productos que usas todos los d\xEDas.",
        shopEligible: "Compra Productos Elegibles",
        howAutosaveWorks: "C\xF3mo Funciona AutoSave:",
        footerDescription: "*El pedido puede incluir tanto art\xEDculos de AutoSave como art\xEDculos a precio completo.",
        chooseFrequency: "Elige la frecuencia",
        chooseFrequencyDescription: "Elige tus productos y la frecuencia de env\xEDo.",
        save: "Ahorrar",
        saveDescription: "Disfruta de un 10% de DESCUENTO en cada pedido recurrente.",
        freeShipping: "Env\xEDo gratis",
        freeShippingDescription: "Asegura el ENV\xCDO GRATIS cuando tu pedido alcance los $150*",
        saveMore: "Ahorra m\xE1s",
        saveMoreDescription: "En los pr\xF3ximos meses, agrega una nueva suscripci\xF3n y recibe un 10% de DESCUENTO en el PRIMER pedido."
      }
    },
    changeGeolocationModal: {
      youreOnAnotherLocation: "Parece que est\xE1s en {location}.",
      wouldYouUpdateLocation: "\xBFQuieres actualizar tu ubicaci\xF3n?",
      chooseLocation: "Elegir ubicaci\xF3n",
      stayOnSite: "Permanecer en el sitio"
    },
    totalPriceSummary: {
      hostessDiscount: "{percentage}% de descuento",
      storeCredit: "Cr\xE9dito de tienda",
      norwexDiscount: "Descuento Norwex",
      dsrDiscount: "35% Descuento de Consultora"
    }
  },
  Country: {
    us: "Estados Unidos",
    ca: "Canada"
  }
};

// ../../packages/app-kit/i18n/messages/fr-ca.json
var fr_ca_default = {
  appkit: {
    autoSave: {
      autoSaveItem: "Article Auto\xC9conomie"
    },
    newConsultants: {
      title: "Mon D\xE9part brillant",
      endDay: "Mon D\xE9part brillant se termine le {dayTitle}",
      remainingDay: "Jour(s) {count}",
      details: "Gagnez des produits gratuits et des cr\xE9dits lorsque vous atteignez vos objectifs de ventes.",
      empty: "Vider les donn\xE9es",
      openReport: "Afficher le suivi D\xE9part brillant de mon \xE9quipe",
      recruitTitle: "Mon recrutement",
      recruitDetails: "Voir les d\xE9tails du programme dans la brochure D\xE9part brillant",
      left: "gauche"
    },
    Auth: {
      signIn: {
        title: "Connexion pour Conseill\xE8re (er)",
        sectionTitle: "Se connecter au Bureau de Conseill\xE8re (er)",
        submit: "Se connecter",
        createAnAccount: "D\xE9couvrez comment devenir Conseill\xE8re (er)!",
        emailOrUsername: "Email ou ID du consultant",
        password: "Mot de passe",
        forgotPassword: "Mot de passe oubli\xE9?",
        notANorwexConsultantYet: "Vous n'\xEAtes pas encore Conseill\xE8re (er) Norwex?",
        error: "L'identifiant ou le mot de passe est invalide!"
      },
      forgotPassword: {
        title: "Mot de passe oubli\xE9?",
        sectionTitle: "Aucun probl\xE8me. Entrez votre adresse courriel ci-dessous et nous vous enverrons un courriel pour r\xE9initialiser votre mot de passe.",
        submit: "Envoyer un courriel",
        emailAddress: "Adresse courriel",
        success: "Dans les 10 prochaines minutes, vous devriez recevoir un courriel contenant des instructions sur la fa\xE7on de r\xE9initialiser votre mot de passe.",
        error: "Adresse courriel invalide!",
        back: "Retour"
      },
      signUp: {
        createAnAccount: "Cr\xE9er un compte",
        name: "Nom",
        firstName: "Pr\xE9nom",
        lastName: "Nom de famille",
        email: "Courriel",
        password: "Mot de passe",
        passwordRules: "Le mot de passe doit contenir plus de 8 caract\xE8res, dont 1 majuscule, 1 minuscule, 1 caract\xE8re sp\xE9cial et 1 chiffre.",
        birthDayTitle: "Quelle la date de votre anniversaire? (facultatif)",
        birthDaySubTitle: "Pour que nous puissions vous envoyer quelque chose pour c\xE9l\xE9brer!",
        createAccount: "Cr\xE9er un compte",
        alreadyHaveAnAccount: "Vous avez d\xE9j\xE0 un compte?",
        signIn: "Se connecter",
        month: "Mois",
        day: "Jour",
        error: "Erreur de cr\xE9ation de compte",
        emailConsent: "Recevez les derniers conseils, tendances et offres exclusives de Norwex par email.",
        termsConsent: "J'ai lu et accept\xE9 les Conditions d'utilisation et la Politique de confidentialit\xE9.",
        termsConsentHint: "Veuillez accepter les conditions d'utilisation pour cr\xE9er votre compte.",
        store: {
          us: "\xC9tats-Unis",
          ca: "Canada"
        },
        searchConsultant: {
          title: "S\xE9lectionnez un consultant Norwex (Optionnel)",
          placeholder: "Rechercher un consultant par nom",
          mentorSearchFailed: "\xC9chec du chargement des mentors",
          noResults: "Aucun r\xE9sultat trouv\xE9",
          loadMentorInfoFailed: "\xC9chec du chargement des informations du mentor. Veuillez r\xE9essayer",
          change: "Cambiar"
        }
      },
      resetPassword: {
        resetPassword: "R\xE9initialisez votre mot de passe",
        pleaseCreateNewPassword: "Veuillez cr\xE9er un nouveau mot de passe",
        repeatPassword: "R\xE9p\xE9ter le mot de passe",
        createPassword: "Cr\xE9er un mot de passe",
        error: "Erreur lors de la cr\xE9ation du compte"
      }
    },
    settings: {
      password: {
        title: "Mot de passe",
        currentPassword: "Mot de passe actuel",
        newPassword: "Nouveau mot de passe",
        repeatNewPassword: "R\xE9p\xE9ter le nouveau mot de passe",
        passwordRequirement: "Le mot de passe doit contenir plus de 8 caract\xE8res, dont 1 majuscule, 1 minuscule, 1 caract\xE8re sp\xE9cial et 1 chiffre.",
        save: "Sauvegarder"
      }
    },
    address: {
      addressLine1: "Adresse ligne 1",
      addressLine2: "Adresse ligne 2 (optionnel)",
      streetName: "Nom de la rue, num\xE9ro, bo\xEEte postale, C/O",
      apartment: "(Facultatif) Appartement, suite, unit\xE9, etc.",
      postalCode: "Code Postal",
      zipCode: "Code postal",
      city: "Ville",
      country: " S\xE9lectionnez le pays",
      state: "S\xE9lectionnez l'\xE9tat",
      province: "S\xE9lectionnez la province",
      firstName: "Pr\xE9nom",
      lastName: "Nom de famille",
      phone: "T\xE9l\xE9phone",
      zipCodeFormat: "Le code postal doit \xEAtre au format T2X 1V4 ou T2X1V4",
      phonePlaceholder: "Num\xE9ro de t\xE9l\xE9phone"
    },
    profile: {
      birthDay: "Jour de naissance",
      birthMonth: "Mois de naissance"
    },
    photo: {
      adjustPhoto: "Ajuster la photo",
      changePhoto: "Changer la photo",
      uploadPhoto: "Envoyer la photo",
      removePhoto: "Retirer la photo",
      save: "Sauvegarder",
      note: "Utilisez une photo que vous avez le droit de publier. La photo doit vous pr\xE9senter, vous et votre entreprise Norwex, de mani\xE8re professionnelle et \xEAtre conforme \xE0 l'image, \xE0 la r\xE9putation et aux politiques de Norwex. Norwex a le droit de supprimer toute image \xE0 sa seule discr\xE9tion.",
      cancel: "Annuler"
    },
    iconLegend: {
      title: "L\xE9gende de l'ic\xF4ne",
      zeroDollarRetail: "0 $ au d\xE9tail",
      qualifiedParty: "\xC9v\xE8nement qualifi\xE9",
      inPersonParty: "\xC9v\xE8nement en personne",
      onlineParty: "\xC9v\xE8nement en ligne",
      retail: " Au d\xE9tail"
    },
    dateFilter: {
      applyButton: "Appliquer",
      clearButton: "Tout effacer",
      dateRangeTab: "Plage de dates",
      customDateTab: "Date personnalis\xE9e",
      thisMonth: "Ce mois-ci",
      nextMonth: "Le mois prochain",
      last45: "45 derniers jours",
      last60: "60 derniers jours",
      thisYear: "Cette ann\xE9e",
      date: "Date"
    },
    cardBlock: {
      cardholderName: "Nom du Titulaire de la Carte",
      cardNumber: "Num\xE9ro de Carte",
      expirationDate: "Date d'Expiration",
      cvv: "CVV/CVC",
      cvvTooltip: {
        title: "O\xF9 puis-je trouver le CVV?",
        title3Digit: "Visa & Mastercard",
        desc3Digit: "Code \xE0 3 chiffres au dos",
        title4Digit: "American Express",
        desc4Digit: "Code \xE0 4 chiffres sur le devant"
      },
      firstName: "Pr\xE9nom du Titulaire de la Carte",
      lastName: "Nom de Famille du Titulaire de la Carte",
      year: "Ann\xE9e d'Expiration",
      month: "Mois d'Expiration",
      yearError: "Ann\xE9e d'expiration invalide",
      required: "Requis",
      invalidCardNumber: "Num\xE9ro de carte invalide",
      invalidExpirationMonth: "Le mois d'expiration est invalide",
      invalidExpirationYear: "L'ann\xE9e d'expiration est invalide"
    },
    productList: {
      item: {
        add: "Ajouter",
        salePrice: "Prix de vente",
        retailPrice: "Prix au d\xE9tail",
        maxPrice: "Prix maximum"
      },
      cell: {
        name: "Nom : {value}",
        outOfStock: "En rupture de stock!",
        callout: "Appel : {value}"
      },
      details: {
        salePrice: "Prix de vente",
        retail: "Prix en d\xE9tail",
        ambassador: "Ambassadrice",
        value: "Valeur",
        estimatedCommission: "Commission estim\xE9e:"
      }
    },
    braintree: {
      errors: {
        getTokenError: "Erreur d'initialisation du client Braintree : \xC9chec de l'obtention de la preuve du client",
        unexpectedClientInitError: "Erreur d'initialisation du client Braintree: Erreur inattendue",
        paypalInitClientUndefined: "Erreur d'initialisation PayPal de Braintree : Client non d\xE9fini",
        unexpectedPaypalInitError: "Erreur d'initialisation Braintree PayPal : Erreur inattendue",
        vaultInitClientUndefined: "Erreur d'initialisation du Coffre-fort Braintree : Client non d\xE9fini",
        unexpectedVaultInitError: "Erreur d'initialisation du Coffre-fort Braintree : Erreur inattendue",
        tokenizePaypalUndefined: " Erreur de tokenisation PayPal Braintree : PayPal non d\xE9fini",
        unexpectedTokenizePaypalError: "Erreur PayPal Braintree : Erreur de tokenisation inattendue",
        createHostedFieldsClientUndefined: "Erreur de cr\xE9ation de champs h\xE9berg\xE9s par Braintree : Client non d\xE9fini",
        unexpectedCreateHostedFieldsError: "Erreur inattendue lors de la cr\xE9ation de champs h\xE9berg\xE9s par Braintree",
        hostedFieldsValidationError: "Erreur de validation des champs h\xE9berg\xE9s par Braintree : {fields}",
        unexpectedTokenizeHostedFieldsError: "Champs h\xE9berg\xE9s Braintree : Erreur de tokenisation inattendue",
        removePaymentMethodClientUndefined: "Erreur de suppression du mode de paiement par Braintree : Client non d\xE9fini",
        removePaymentMethodVaultUndefined: "Erreur de suppression du mode de paiement Braintree : Coffre-fort non d\xE9fini",
        unexpectedPaypalCheckoutError: "Erreur de paiement PayPal Braintree : Erreur inattendue",
        checkoutFailed: "Veuillez v\xE9rifier que vos d\xE9tails de paiement sont corrects.",
        checkoutTaxIsNotCalculated: "Ups, algo sali\xF3 mal. Por favor, espere mientras recalculamos su carrito de compras.",
        checkoutDisabled: "Passer des commandes est temporairement impossible."
      },
      payments: {
        selectSavedCard: "S\xE9lectionnez votre carte de cr\xE9dit enregistr\xE9e",
        selectNewCard: "Entrez une nouvelle carte",
        creditCard: "Carte de cr\xE9dit",
        noCardsSaved: "Aucune carte enregistr\xE9e",
        cardNumber: "Num\xE9ro de carte",
        cvv: "CVV",
        expirationMonth: "Mois d'expiration",
        expirationYear: "Ann\xE9e d'expiration",
        months: {
          january: "01 - Janvier",
          february: "02 - F\xE9vrier",
          march: "03 - Mars",
          april: "04 - Avril",
          may: "05 - Mai",
          june: "06 - Juin",
          july: "07 - Juillet",
          august: "08 - Ao\xFBt",
          september: "09 - Septembre",
          october: "10 - Octobre",
          november: "11 - Novembre",
          december: "12 - D\xE9cembre"
        }
      }
    },
    paymentMethods: {
      errors: {
        loadPaymentMethodsFailure: "\xC9chec du chargement des m\xE9thodes de paiement",
        createPaymentMethodUserIdUndefined: "Erreur de cr\xE9ation de la m\xE9thode de paiement : Le no d\u2019utilisateur n'est pas d\xE9fini",
        changePaypalPaymentMethodUserIdUndefined: "Erreur de la modification de la m\xE9thode de paiement Paypal : Le no d\u2019utilisateur n'est pas d\xE9fini",
        createCardPaymentMethodFailure: "\xC9chec de la cr\xE9ation de la m\xE9thode de paiement par carte",
        createPaypalPaymentMethodFailure: "\xC9chec de la cr\xE9ation de la m\xE9thode de paiement PayPal",
        createPaypalPaymentMethodAlreadyExists: "\xC9chec de la cr\xE9ation : La m\xE9thode de paiement PayPal existe d\xE9j\xE0 pour cet utilisateur",
        deletePaymentMethodFailure: "\xC9chec de la suppression de la m\xE9thode de paiement",
        changePrimaryPaymentMethodFailure: "\xC9chec de la modification de la m\xE9thode de paiement principale",
        changePaypalPaymentMethodFailure: "\xC9chec de la modification de la m\xE9thode de paiement PayPal",
        missingPayPalAccount: "Erreur lors de la cr\xE9ation de la m\xE9thode de paiement PayPal : Compte PayPal manquant"
      }
    },
    columnSelectionModal: {
      columns: "Colonnes",
      apply: "Appliquer",
      clear: "Tout effacer"
    },
    exportAll: {
      pdf: "PDF",
      title: "Tout exporter",
      errorCsv: "Il semble que l'exportation CSV ait \xE9chou\xE9"
    },
    common: {
      months: {
        january: "Janvier",
        february: "F\xE9vrier",
        march: "Mars",
        april: "Avril",
        may: "Mai",
        june: "Juin",
        july: "Juillet",
        august: "Ao\xFBt",
        september: "Septembre",
        october: "Octobre",
        november: "Novembre",
        december: "D\xE9cembre"
      }
    },
    cart: {
      cartItem: {
        qty: "Quantit\xE9 : ",
        autosaveFrequency: "Fr\xE9quence d'AutoSauvegarde : "
      },
      cartBundle: {
        productDetails: "D\xE9tails du Produit"
      }
    },
    stickyBottomButton: {
      items: "{count, plural, =1 {Article : 1} other {Articles : #}}",
      inRetailSales: "{value} dans les Ventes au D\xE9tail",
      subtotal: "Sous-total : "
    },
    rewardsInfo: {
      loadFail: "\xC9chec du chargement des informations sur les r\xE9compenses"
    },
    languageSwitcher: {
      "en-US": "English",
      "en-CA": "English",
      "es-US": "Spanish",
      "fr-CA": "Fran\xE7aise"
    },
    autoSave: {
      emptyState: {
        getOff: "Obtenez <bold>10</bold>% de R\xE9duction",
        getRewardDescription: "Soyez r\xE9compens\xE9 pour votre fid\xE9lit\xE9 ! Obtenez des r\xE9ductions et la livraison gratuite sur les produits que vous utilisez tous les jours.",
        shopEligible: "Acheter des Produits \xC9ligibles",
        howAutosaveWorks: "Comment Fonctionne AutoSave :",
        footerDescription: "*La commande peut inclure \xE0 la fois des articles AutoSave et des articles au prix fort.",
        chooseFrequency: "Choisissez la fr\xE9quence",
        chooseFrequencyDescription: "Choisissez vos produits et la fr\xE9quence d'exp\xE9dition.",
        save: "\xC9conomiser",
        saveDescription: "Profitez de 10% de R\xC9DUCTION sur chaque commande r\xE9currente.",
        freeShipping: "Livraison gratuite",
        freeShippingDescription: "Assurez-vous la LIVRAISON GRATUITE lorsque votre commande atteint 200 $*",
        saveMore: "\xC9conomisez plus",
        saveMoreDescription: "Dans les prochains mois, ajoutez un nouvel abonnement et recevez 10% de R\xC9DUCTION sur la PREMI\xC8RE commande !"
      }
    },
    changeGeolocationModal: {
      youreOnAnotherLocation: "Il semble que vous soyez \xE0 {location}.",
      wouldYouUpdateLocation: "Souhaitez-vous mettre \xE0 jour votre emplacement ?",
      chooseLocation: "Choisir l'endroit",
      stayOnSite: "Rester sur le site"
    },
    totalPriceSummary: {
      hostessDiscount: "{percentage}% de r\xE9duction",
      storeCredit: "Cr\xE9dit en magasin",
      norwexDiscount: "Rabais Norwex",
      dsrDiscount: "35% R\xE9duction pour Consultant"
    }
  },
  Country: {
    us: "\xC9tats-Unis",
    ca: "Canada"
  }
};

// ../../packages/ui-kit/i18n/messages/en-ca.json
var en_ca_default2 = {};

// ../../packages/ui-kit/i18n/messages/en-us.json
var en_us_default2 = {
  uiKit: {
    button: {
      loading: "Loading..."
    },
    buttonClipboardCopy: {
      copied: "Copied!",
      copyLink: "Copy Link",
      copy: "Copy"
    },
    moneyInput: {
      errors: {
        amountExceeds: "Exceeds ${amount} limit",
        amountBelow: "Below ${amount} limit"
      }
    },
    datePicker: {
      today: "Today",
      clear: "Clear",
      days: {
        Sun: "Mon",
        Mon: "Tue",
        Tue: "Wed",
        Wed: "Thu",
        Thu: "Fri",
        Fri: "Sat",
        Sat: "Sun"
      },
      months: {
        January: "January",
        February: "February",
        March: "March",
        April: "April",
        May: "May",
        June: "June",
        July: "July",
        August: "August",
        September: "September",
        October: "October",
        November: "November",
        December: "December"
      }
    },
    step: {
      skipThisStep: "Skip This Step",
      skip: "Skip",
      edit: "Edit",
      stepOf: "Step {stepLabel} of {totalSteps}"
    },
    trackerGauge: {
      titleGoal: "{current} of {goal}"
    },
    confirmationModal: {
      yes: "Yes",
      no: "No"
    },
    table: {
      emptyState: "No Results Found"
    },
    notification: {
      success: "Success! Your changes have been saved"
    },
    toggleContent: {
      yes: "Yes",
      no: "No",
      required: "Required"
    },
    emailConfirmationModal: {
      verifyEmail: "Verify your email",
      codeSent: "We\u2019ve sent a code to {email}",
      enterCode: "Enter Code",
      verify: "Verify",
      resendCode: "Resend Code",
      differentEmail: "Use a different email address",
      accessWarning: "You will not be able to access orders or credits associated with the other email address.",
      resendCooldown: "Please wait {seconds} seconds before resending the code."
    }
  }
};

// ../../packages/ui-kit/i18n/messages/es-us.json
var es_us_default2 = {
  uiKit: {
    button: {
      loading: "Cargando..."
    },
    buttonClipboardCopy: {
      copied: "\xA1Copiado!",
      \u0441opyLink: "Copiar Enlace",
      \u0441opy: "Copiar"
    },
    moneyInput: {
      errors: {
        amountExceeds: "Excede el l\xEDmite de ${amount}",
        amountBelow: "Por debajo del l\xEDmite de ${amount}"
      }
    },
    datePicker: {
      today: "Hoy",
      clear: "Borrar",
      days: {
        Fri: "S\xE1b",
        Mon: "Mar",
        Sat: "Dom",
        Sun: "Lun",
        Thu: "Vie",
        Tue: "Mi\xE9",
        Wed: "Jue"
      },
      months: {
        April: "Abril",
        August: "Agosto",
        December: "Diciembre",
        February: "Febrero",
        January: "Enero",
        July: "Julio",
        June: "Junio",
        March: "Marzo",
        May: "Mayo",
        November: "Noviembre",
        October: "Octubre",
        September: "Septiembre"
      }
    },
    notification: {
      success: "\xA1\xC9xito! Tus cambios se han guardado"
    },
    step: {
      skipThisStep: "Omitir Este Paso",
      skip: "Omitir",
      edit: "Editar",
      stepOf: "Paso {stepLabel} de {totalSteps}"
    },
    trackerGauge: {
      titleGoal: "{current} de {goal}"
    },
    confirmationModal: {
      yes: "S\xED",
      no: "No"
    },
    table: {
      emptyState: "No Se Encontraron Resultados"
    },
    toggleContent: {
      yes: "S\xED",
      no: "No",
      required: "Obligatorio"
    },
    emailConfirmationModal: {
      verifyEmail: "Verifica tu correo electr\xF3nico",
      codeSent: "Hemos enviado un c\xF3digo a {email}",
      enterCode: "Ingresa el c\xF3digo",
      verify: "Verificar",
      resendCode: "Reenviar c\xF3digo",
      differentEmail: "Usar una direcci\xF3n de correo electr\xF3nico diferente",
      accessWarning: "No podr\xE1s acceder a los pedidos o cr\xE9ditos asociados con la otra direcci\xF3n de correo electr\xF3nico.",
      resendCooldown: "Por favor, espera {seconds} segundos antes de reenviar el c\xF3digo."
    }
  }
};

// ../../packages/ui-kit/i18n/messages/fr-ca.json
var fr_ca_default2 = {
  uiKit: {
    button: {
      loading: "Chargement..."
    },
    buttonClipboardCopy: {
      copied: "Copi\xE9!",
      copyLink: "Copier le lien",
      copy: "Copier"
    },
    moneyInput: {
      errors: {
        amountExceeds: "D\xE9passe la limite de ${amount}",
        amountBelow: "Inf\xE9rieur \xE0 la limite de ${amount}"
      }
    },
    datePicker: {
      today: "Aujourd'hui",
      clear: "Tout effacer",
      days: {
        Sun: "Lun",
        Mon: "Mar",
        Tue: "Mer",
        Wed: "Jeu",
        Thu: "Ven",
        Fri: "Sam",
        Sat: "Dim"
      },
      months: {
        January: "Janvier",
        February: "F\xE9vrier",
        March: "Mars",
        April: "Avril",
        May: "Mai",
        June: "Juin",
        July: "Juillet",
        August: "Ao\xFBt",
        September: "Septembre",
        October: "Octobre",
        November: "Novembre",
        December: "D\xE9cembre"
      }
    },
    step: {
      skipThisStep: "Passer cette \xE9tape",
      skip: "Passer",
      edit: "Modifier",
      stepOf: "\xC9tape {stepLabel}/{totalSteps}"
    },
    trackerGauge: {
      titleGoal: "{current} de {goal}"
    },
    confirmationModal: {
      yes: "Oui",
      no: "Non"
    },
    table: {
      emptyState: "Aucun r\xE9sultat trouv\xE9"
    },
    toggleContent: {
      yes: "Oui",
      no: "Non",
      required: "Requis"
    },
    emailConfirmationModal: {
      verifyEmail: "V\xE9rifiez votre e-mail",
      codeSent: "Nous avons envoy\xE9 un code \xE0 {email}",
      enterCode: "Saisir le code",
      verify: "V\xE9rifier",
      resendCode: "Renvoyer le code",
      differentEmail: "Utiliser une autre adresse e-mail",
      accessWarning: "Vous ne pourrez pas acc\xE9der aux commandes ou cr\xE9dits associ\xE9s \xE0 l'autre adresse e-mail.",
      resendCooldown: "Veuillez attendre {seconds} secondes avant de renvoyer le code."
    }
  }
};

// src/i18n/messages/en-ca.json
var en_ca_default3 = {
  reports: {
    title: "Reports"
  }
};

// src/i18n/messages/en-us.json
var en_us_default3 = {
  reports: {
    title: "Reports",
    searchByName: "Search by name",
    ambassador: "{totalPersons, plural, =1 {1 Ambassador} other {# Ambassadors}}",
    consultant: "{totalPersons, plural, =1 {1 Consultant} other {# Consultants}}",
    consultantTitle: "{total, plural, =1 {Consultant} other {Consultants}}",
    exportAll: "Export All",
    dates: "Dates",
    yes: "Yes",
    no: "No",
    viewAll: "View All",
    updated: "Updated",
    viewAllConsultants: "View all consultants",
    filters: {
      title: "Filters",
      apply: "Apply",
      clearAll: "Clear all",
      selected: "selected"
    },
    careerRank: {
      goal: "Goal"
    },
    rankProgress: {
      title: "Success Builder",
      currentPayRank: "Current Paid-As-Title",
      careerTitle: "Career Title",
      viewAllRanks: "View All Ranks",
      yourPaidTitle: "Your Paid Title",
      potentialEarnings: "Potential Earnings"
    },
    profileModal: {
      sorryButProfileNotLoaded: "Sorry, but profile not loaded",
      careerTitle: "Career Title",
      status: "Status",
      startDate: "Start Date",
      qualifyByDate: "Qualify By Date",
      qualifyDate: "Qualified Date",
      qualified: "Qualified",
      shipping: "Shipping",
      phone: "Phone",
      email: "Email",
      moreDetails: "More Details"
    },
    filterModal: {
      dateRange: {
        from: "From",
        to: "To"
      }
    },
    lineChart: {
      title: "Trends Chart"
    },
    teamBrightStart: {
      title: "Team Bright Start Report"
    },
    coaching: {
      title: "Team Relationship Manager"
    },
    noResult: "No results match your criteria.",
    pipeline: {
      title: "Event Pipeline",
      iconLegend: "Icon Legend",
      totalEvents: "Total Events",
      thisAmbassadorHasNoEvents: "This consultant has no events",
      partyPipeline: "Party Pipeline",
      online: "Online",
      line: "Line",
      personalGroup: "Personal Group",
      level: "{level} Level",
      events: {
        qualified: "Qualified Event",
        inPerson: "In Person Event",
        online: "Online Event"
      }
    },
    recognition: {
      title: "Recognition Report",
      showOnlyMyTeam: "Show only my team",
      name: "Name",
      location: "Location",
      categories: "Categories"
    },
    dashboard: {
      title: "My Team & Me",
      commissionEarnings: "Team Commission Earnings",
      commissionDetails: "Commission Details",
      meAndMyTeamStats: "Me & My Team Stats",
      businessStats: "Business Stats",
      earningsTotal: "Total:",
      yearAtGlance: "Year at Glance",
      viewDetails: "View Details",
      viewFullYear: "Full Year Details",
      type: "Type",
      trip: {
        level: "Level {level}",
        levelOf: "Level {current} of {total}",
        of: "{current}<br/> of {total}",
        viewProgramDetails: "View Program Details",
        personalEligibleRecruits: "{eligible} of {total} Personal Eligible Recruits",
        empty: "Trip is empty",
        earnYourTrip: "Earn your trip",
        myPointsSummary: "My Points Summary",
        daysLeft: "{days} {days, plural, =1 {day} other {days}} left",
        pointsSummary: {
          title: "Points Summary",
          total: "Total:",
          consultantTitle: "Personal New Consultants who each achieve $400 in Personal Retail Sales in their first 15 days.",
          export: "Export"
        }
      },
      incentives: {
        details: "Details"
      },
      header: {
        commissionStatement: "Commission Statement",
        teamStats: "Team Stats"
      }
    },
    tabMenu: {
      commisions: "Commissions",
      stats: "Stats",
      brightStart: "Bright Start",
      successBuilder: "Success Builder",
      trip: "Trip",
      incentive: "Incentive"
    }
  }
};

// src/i18n/messages/es-us.json
var es_us_default3 = {
  reports: {
    title: "Informes",
    searchByName: "Buscar por nombre",
    ambassador: "{totalPersons, plural, =1 {1 Ambassador} otros {# Ambassadors}}",
    consultant: "{totalPersons, plural, =1 {1 Consultant} otros {# Consultants}}",
    consultantTitle: "{total, plural, =1 {Consultora} other {Consultoras}}",
    exportAll: "Exportar todo",
    dates: "fechas",
    yes: "S\xED",
    no: "No",
    viewAll: "Ver todo",
    updated: "Actualizado",
    viewAllConsultants: "Ver todas las Consultoras",
    filters: {
      title: "Filtros",
      apply: "Aplicar",
      clearAll: "Limpiar todo",
      selected: "seleccionado"
    },
    careerRank: {
      goal: "Meta"
    },
    rankProgress: {
      title: "Edificador de \xE9xito",
      currentPayRank: "T\xEDtulo Actual Como Pagado",
      viewAllRanks: "Ver todos los rangos",
      yourPaidTitle: "Tu Pago de acuerdo al T\xEDtulo",
      potentialEarnings: "Ganancias potenciales"
    },
    profileModal: {
      sorryButProfileNotLoaded: "Lo siento, pero el perfil no est\xE1 cargado.",
      careerTitle: "T\xEDtulo de carrera",
      status: "Estado",
      startDate: "Fecha de inicio",
      qualifyByDate: "Calificar por fecha",
      qualifyDate: "Fecha calificada",
      qualified: "Calificado",
      shipping: "Env\xEDo",
      phone: "Tel\xE9fono",
      email: "Correo electr\xF3nico",
      moreDetails: "M\xE1s detalles"
    },
    filterModal: {
      dateRange: {
        from: "De",
        to: "A"
      }
    },
    lineChart: {
      title: "Gr\xE1fico de tendencias"
    },
    teamBrightStart: {
      title: "Informe del Brillante Comienzo del equipo"
    },
    coaching: {
      title: "Gerente de Relaciones del Equipo"
    },
    noResult: "Ning\xFAn resultado coincide con tu criterio.",
    pipeline: {
      title: "Canalizaci\xF3n de eventos",
      iconLegend: "Leyenda del icono",
      totalEvents: "Eventos totales",
      thisAmbassadorHasNoEvents: "Este embajador no tiene eventos.",
      partyPipeline: "Canalizaci\xF3n de la Demostraci\xF3n",
      online: "En l\xEDnea",
      line: "L\xEDnea",
      personalGroup: "Grupo Personal",
      level: "{level} Nivel",
      events: {
        qualified: "Evento calificado",
        inPerson: "Evento en persona",
        online: "Evento en l\xEDnea"
      }
    },
    recognition: {
      title: "Informe de reconocimiento",
      showOnlyMyTeam: "Mostrar solo mi equipo",
      name: "Nombre",
      location: "Ubicaci\xF3n",
      categories: "Categor\xEDas"
    },
    dashboard: {
      title: "Mi equipo y yo",
      commissionEarnings: "Ganancias de comisiones de equipo",
      commissionDetails: "Detalles de la comisi\xF3n",
      meAndMyTeamStats: "Estad\xEDsticas de mi equipo y yo",
      businessStats: "Estad\xEDsticas de negocio",
      earningsTotal: "Total:",
      yearAtGlance: "A\xF1o de un vistazo",
      viewDetails: "Ver detalles",
      viewFullYear: "Detalles del a\xF1o completo",
      type: "Tipo",
      incentives: {
        details: "Detalles"
      },
      trip: {
        level: "Nivel {level}",
        levelOf: "Nivel {current} de {total}",
        of: "{current} de {total}",
        viewProgramDetails: "Ver detalles del programa",
        personalEligibleRecruits: "{elegible} de {total} reclutas personales elegibles",
        empty: "El viaje de incentivo est\xE1 vac\xEDo.",
        earnYourTrip: "Gana tu viaje",
        myPointsSummary: "Resumen de mis puntos",
        daysLeft: "{days} {days, plural, =1 {day} otros {days}} restantes",
        pointsSummary: {
          title: "Resumen de puntos",
          total: "Total:",
          consultantTitle: "Nuevas Consultoras Personales que logren cada una $400 en Ventas Personales al Detalle en sus primeros 15 d\xEDas.",
          export: "Exportar"
        }
      },
      header: {
        commissionStatement: "Declaraci\xF3n de la Comisi\xF3n",
        teamStats: "Estad\xEDsticas del equipo"
      }
    }
  }
};

// src/i18n/messages/fr-ca.json
var fr_ca_default3 = {
  reports: {
    title: "Rapports",
    searchByName: "Rechercher par nom",
    ambassador: "{totalPersons, plural, =1 {1 Ambassadeur} sur {# Ambassadeurs}}",
    consultant: "{totalPersons, plural, =1 {1 Consultant} sur {# Consultants}}",
    ambassadorTitle: "{total, plural, =1 {Ambassadeur} sur {Ambassadeurs}}",
    consultantTitle: "{total, plural, =1 {Consultant} other {Consultants}}",
    exportAll: "Tout exporter",
    dates: "Dates",
    yes: "Oui",
    no: "Non",
    viewAll: "Tout afficher",
    updated: "Mis \xE0 jour",
    viewAllConsultants: "Afficher toutes les Conseill\xE8res (ers)",
    filters: {
      title: "Filtres",
      apply: "Appliquer",
      clearAll: "Tout effacer",
      selected: "choisi"
    },
    careerRank: {
      goal: "Objectif"
    },
    rankProgress: {
      title: "B\xE2tisseur de r\xE9ussites",
      currentPayRank: "Titre Actuel Comme Pay\xE9",
      viewAllRanks: "Afficher tous les rangs",
      yourPaidTitle: "Votre titre pay\xE9",
      potentialEarnings: "Gains potentiels"
    },
    profileModal: {
      sorryButProfileNotLoaded: "D\xE9sol\xE9, mais le profil n'est pas charg\xE9",
      careerTitle: "Titre de carri\xE8re",
      status: "Statut",
      startDate: "Date de d\xE9but",
      qualifyByDate: "Qualification avant la date",
      qualifyDate: "Date de qualification",
      qualified: "Qualifi\xE9",
      shipping: "Livraison",
      phone: "T\xE9l\xE9phone",
      email: "Courriel",
      moreDetails: "Plus de d\xE9tails"
    },
    filterModal: {
      dateRange: {
        from: "Depuis",
        to: "\xC0"
      }
    },
    lineChart: {
      title: "Tableau des tendances"
    },
    teamBrightStart: {
      title: "Rapport D\xE9part brillant de l'\xE9quipe"
    },
    coaching: {
      title: "Gestionnaire de Relation d'\xC9quipe"
    },
    noResult: "Aucun r\xE9sultat ne correspond \xE0 vos crit\xE8res.",
    pipeline: {
      title: "R\xE9seaux d'\xE9v\xE8nements",
      iconLegend: "L\xE9gende des ic\xF4nes",
      totalEvents: "Total des \xE9v\xE8nements",
      thisAmbassadorHasNoEvents: "Cette Conseill\xE8re (er) n'a aucun \xE9v\xE8nement",
      partyPipeline: "R\xE9seaux d'\xE9v\xE8nements",
      online: "En ligne",
      line: "Doubler",
      personalGroup: "Groupe Personnel",
      level: "{level} Niveau",
      events: {
        qualified: "\xC9v\xE8nement qualifi\xE9",
        inPerson: "\xC9v\xE8nement en personne",
        online: "\xC9v\xE8nement en ligne"
      }
    },
    recognition: {
      title: "Rapport de reconnaissance",
      showOnlyMyTeam: "Afficher uniquement mon \xE9quipe",
      name: "Nom",
      location: "Emplacement",
      categories: "Cat\xE9gories"
    },
    dashboard: {
      title: "Mon \xE9quipe et moi",
      commissionEarnings: "Gains de commission d'\xE9quipe",
      commissionDetails: "Commissions D\xE9tails",
      meAndMyTeamStats: "Statistiques de moi et de mon \xE9quipe",
      businessStats: "Statistiques commerciales",
      earningsTotal: "Total:",
      yearAtGlance: "Coup d'\u0153il sur l'ann\xE9e",
      viewDetails: "Voir les d\xE9tails",
      viewFullYear: "Ann\xE9e compl\xE8te",
      type: "Taper",
      trip: {
        level: "Niveau {level}",
        levelOf: "Niveau {current} de {total}",
        of: "{current} sur {total}",
        viewProgramDetails: "Afficher les d\xE9tails du programme",
        personalEligibleRecruits: "{eligible} sur {total} recrues personnelles \xE9ligibles",
        empty: "Le voyage est vide",
        earnYourTrip: "Gagnez votre voyage",
        myPointsSummary: "R\xE9capitulatif de mes points",
        daysLeft: "{days} {days, plural, =1 {day} other {days}} restants",
        pointsSummary: {
          title: "R\xE9sum\xE9 des points",
          total: "Total:",
          consultantTitle: "Nouveaux consultants personnels qui r\xE9alisent chacun 400 $ de ventes au d\xE9tail personnelles au cours de leurs 15 premiers jours.",
          export: "Exporter"
        }
      },
      header: {
        commissionStatement: "D\xE9claration de la Commission",
        teamStats: "Statistiques de l'\xE9quipe"
      }
    }
  }
};

// src/modules/i18n/interface/get-messages.ts
var defaultMessages = mergeMessages([
  en_us_default,
  en_us_default2,
  en_us_default3
]);
var messages = {
  "en-US": defaultMessages,
  "es-US": mergeMessages([
    defaultMessages,
    es_us_default,
    es_us_default2,
    es_us_default3
  ]),
  "en-CA": mergeMessages([
    defaultMessages,
    en_ca_default,
    en_ca_default2,
    en_ca_default3
  ]),
  "fr-CA": mergeMessages([
    defaultMessages,
    fr_ca_default,
    fr_ca_default2,
    fr_ca_default3
  ])
};
function getMessages() {
  return messages;
}
__name(getMessages, "getMessages");

// src/modules/module-reports/ui/use-lang-loader.ts
function useLangLoader(locale) {
  const [messages2, setMessages] = useState();
  useEffect2(() => {
    const messages3 = getMessages();
    setMessages(messages3[locale]);
  }, [
    locale
  ]);
  return {
    messages: messages2,
    isMessagesLoading: !messages2
  };
}
__name(useLangLoader, "useLangLoader");

// src/modules/module-reports/ui/used-report.tsx
var reportsMap = {
  // businessDashboard: ReportActivity, // deprecated legacy report
  coaching: /* @__PURE__ */ lazy(() => import("./report-coaching.page-MGUJQY2O.mjs")),
  pipeline: /* @__PURE__ */ lazy(() => import("./report-pipeline.page-5CPCT5NJ.mjs")),
  recognition: /* @__PURE__ */ lazy(() => import("./report-recognition.page-Q5QQLXQM.mjs")),
  activity: /* @__PURE__ */ lazy(() => import("./report-dashboard.page-7UIQH3G6.mjs")),
  teamBrightStart: /* @__PURE__ */ lazy(() => import("./report-team-bright-start.page-TEJMORIJ.mjs"))
};
var UsedReport = observer(() => {
  const { reportContextStore } = useReportContext();
  const { i18nStore } = useI18nContext();
  const { messages: messages2, isMessagesLoading } = useLangLoader(i18nStore.currentLocale);
  const { reportName } = reportContextStore;
  const ReportByName = reportName ? reportsMap[reportName] : null;
  if (reportContextStore.isInitialLoading)
    return /* @__PURE__ */ React.createElement(PageLoader, null);
  if (!ReportByName)
    return /* @__PURE__ */ React.createElement(ErrorBlock, null, "Seems not correct report name: ", reportName);
  blockTranslator.appendMessages(messages2);
  return /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(Suspense, {
    fallback: /* @__PURE__ */ React.createElement(PageLoader, null)
  }, /* @__PURE__ */ React.createElement(Swap, {
    is: isMessagesLoading,
    isSlot: /* @__PURE__ */ React.createElement(PageLoader, null)
  }, /* @__PURE__ */ React.createElement(NextIntlProvider, {
    locale: i18nStore.currentLocale,
    messages: messages2
  }, /* @__PURE__ */ React.createElement(ReportByName, {
    key: reportName
  })))));
});

// src/modules/module-reports/ui/report.tsx
var Report = observer2(({ type = "module", reportNameQuery, viewTypeNameQuery, updateUrlCallback }) => {
  useReport({
    type,
    reportNameQuery,
    viewTypeNameQuery,
    updateUrlCallback
  });
  return /* @__PURE__ */ React.createElement(UsedReport, null);
});
export {
  FEATURE_FLAGS_LIST,
  FeatureFlagsService,
  Report,
  useFeatureFlagsContext
};
