import { Store } from '@nori/di'

import type { CartBannerValueObject } from '../../core/value-objects/cart-banner.value-object'

@Store()
export class CartBannerStore {
  cartBanner?: CartBannerValueObject = undefined
  isLoading = false
  isError = false

  get messagesNumber(): number | undefined {
    return this.cartBanner?.messages.length
  }

  setCartBanner(value?: CartBannerValueObject): void {
    this.cartBanner = value
  }

  setIsLoading(value: boolean): void {
    this.isLoading = value
  }

  setIsError(value: boolean): void {
    this.isError = value
  }

  handleDrop(): void {
    this.cartBanner = undefined
    this.isError = false
  }
}
