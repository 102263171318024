import { useCallback, useState } from 'react'

import { observer } from '@nori/di'
import { useTranslations } from '@nori/lang-i18n'
import { useNotifyContext } from '@nori/notify'
import { Input } from '@nori/ui-kit'
import { useCartContext } from '~/modules/cart'
import {
  MAX_PRODUCT_QUANTITY,
  MIN_PRODUCT_QUANTITY,
} from '~/modules/cart/cart.constants'
import { AddToCartButton } from '~/modules/products'

import { ConsultantPrice } from './consultant-price'
import { RetailPrice } from './retail-price'

import type { MoneyValueObject } from '@nori/app-kit'

type Props = {
  id?: string
  isAvailable?: boolean
  salePrice?: MoneyValueObject
  retailPrice?: MoneyValueObject
  isCustomizable?: boolean
  isDisabled?: boolean
  consultantPrice?: MoneyValueObject
  isQuantityAdjustable?: boolean
  onAddToCart: (quantity: number) => void
}

export const AddToCartBlock = observer(
  ({
    id,
    isAvailable,
    salePrice,
    retailPrice,
    consultantPrice,
    isCustomizable,
    isDisabled,
    isQuantityAdjustable = true,
    onAddToCart,
  }: Props) => {
    const { cartStore } = useCartContext()
    const { isCustomerCart } = cartStore
    const inputContainerClass = `mr-5 max-lg:m-0  lg:mr-0 lg:w-15 ${
      isCustomerCart ? 'max-lg:w-1/2' : 'max-lg:w-1/3'
    }`
    const t = useTranslations('shopForMyself.productList')
    const { notifyAction } = useNotifyContext()
    const [cartQuantity, setCartQuantity] = useState(`${MIN_PRODUCT_QUANTITY}`)

    const handleAddToCart = useCallback(() => {
      const num = Number(cartQuantity)
      if (isNaN(num) || num < MIN_PRODUCT_QUANTITY) {
        notifyAction.handleAddNotify({
          type: 'danger',
          title: t('invalidQuantity'),
        })
        return
      }
      onAddToCart(num)
    }, [cartQuantity, t, onAddToCart, notifyAction])

    return (
      <>
        <div className={inputContainerClass}>
          <div className="max-lg:w-15">
            <Input
              disabled={!isQuantityAdjustable}
              size="small"
              type="integer"
              min={MIN_PRODUCT_QUANTITY}
              max={MAX_PRODUCT_QUANTITY}
              value={cartQuantity.toString()}
              onChange={setCartQuantity}
              hideHelperBlock
              data-test-id={'quantity-input'}
            />
          </div>
        </div>
        <RetailPrice
          salePrice={salePrice}
          retailPrice={retailPrice}
          isCustomerCart={isCustomerCart}
        />
        {!isCustomerCart && (
          <ConsultantPrice
            consultantPrice={consultantPrice}
            isFullHeight={!!salePrice}
          />
        )}

        <div className="mr-5 w-[125px]">
          <AddToCartButton
            id={id}
            isDisabled={isDisabled}
            isOutOfStock={!isAvailable}
            onClick={handleAddToCart}
            isCustomizable={isCustomizable}
          />
        </div>
      </>
    )
  }
)
