import { getBaseUrl, RequestService, SERVICE_NAMES } from '@nori/app-kit'
import { Adapter, Inject } from '@nori/di'
import { resultErr, resultOk } from '@nori/result'

import { PennyErrors } from '../core/errors/penny.errors'

import { CartBannerMapper } from './mappers/cart-banner.mapper'
import { CheckoutSummaryMapper } from './mappers/checkout-summary.mapper'
import { RewardItemMapper } from './mappers/reward.mapper'

import type { PromiseResult } from '@nori/result'
import type { CartErrorInstance } from '~/modules/cart/core'
import type { RewardProductEntity } from '../core/entity/reward.entity'
import type { CartBannerValueObject } from '../core/value-objects/cart-banner.value-object'
import type { CheckoutSummaryValueObject } from '../core/value-objects/checkout-summary.value-object'
import type cartBannerMock from './mocks/cart-banner.mock.json'
import type rewardMock from './mocks/reward.mock.json'
import type { PennyCartBannerInput } from './types/penny-cart-banner.input'
import type { PennyCheckoutSummaryInput } from './types/penny-checkout-summary.input'
import type { RewardInput } from './types/reward.input'

@Adapter()
export class PennyAdapter {
  baseUrl = getBaseUrl(SERVICE_NAMES.CART_SERVICE)
  productsBaseUrl = getBaseUrl(SERVICE_NAMES.CATALOG_SERVICE)

  public constructor(
    @Inject() private readonly requestService: RequestService,
    @Inject() private readonly cartBannerMapper: CartBannerMapper,
    @Inject() private readonly checkoutSummaryMapper: CheckoutSummaryMapper
  ) {}

  public async getCartBanner({
    cartId,
  }: PennyCartBannerInput): PromiseResult<
    CartBannerValueObject,
    CartErrorInstance
  > {
    const url = `${this.baseUrl}/carts/${cartId}/messaging?location=cart_banner`

    try {
      const { ok, status, json } = await this.requestService.secured.get<
        typeof cartBannerMock
      >(url)

      if (ok) return resultOk(this.cartBannerMapper.toValueObject(json))

      return resultErr(new PennyErrors.GetCarBannerUnknownError(json))
    } catch (err: unknown) {
      return resultErr(new PennyErrors.GetCarBannerUnexpectedError(err))
    }
  }

  public async getCheckoutSummary({
    cartId,
  }: PennyCheckoutSummaryInput): PromiseResult<
    CheckoutSummaryValueObject,
    CartErrorInstance
  > {
    const url = `${this.baseUrl}/carts/${cartId}/messaging?location=checkout_summary`

    try {
      const { ok, status, json } = await this.requestService.secured.get<
        typeof cartBannerMock
      >(url)

      if (ok) return resultOk(this.checkoutSummaryMapper.toValueObject(json))

      return resultErr(new PennyErrors.GetCheckoutSummaryUnknownError(json))
    } catch (err: unknown) {
      return resultErr(new PennyErrors.GetCheckoutSummaryUnexpectedError(err))
    }
  }

  public async getReward({
    items,
    visibility,
  }: RewardInput): PromiseResult<RewardProductEntity[], CartErrorInstance> {
    const query = new URLSearchParams(
      items.map((item) => ['sku_codes[]', item])
    )
    query.append('visibility', visibility)

    const url = `${this.productsBaseUrl}/purchasable_skus?${query.toString()}`

    try {
      const { ok, status, json } = await this.requestService.secured.get<
        typeof rewardMock
      >(url)

      if (ok) return resultOk(RewardItemMapper.toValueObject(json))

      return resultErr(new PennyErrors.GetRewardUnknownError(json))
    } catch (err: unknown) {
      return resultErr(new PennyErrors.GetRewardUnexpectedError(err))
    }
  }
}
