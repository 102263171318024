import { hookContextFactory } from '@nori/di'

import { ContactManageMailingAction } from './action/contact-manage-mailing.action'
import { ContactManageMailingStore } from './store/contact-manage-mailing.store'

export const { useModuleContext: useContactManageMailingContext } =
  hookContextFactory({
    сontactManageMailingStore: ContactManageMailingStore,
    contactManageMailingAction: ContactManageMailingAction,
  })
