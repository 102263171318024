import { Action, Inject } from '@nori/di'
import { isErr } from '@nori/result'
import { FailedValidationError } from '@nori/validator'
import { createLogger } from '~/infra/create-logger'
import { CartStore } from '~/modules/cart/interface/store/cart-store/cart.store'

import { CouponCodeAdapter } from '../../infra/coupon-code.adapter'
import { CouponCodeStore } from '../store/coupon-code.store'

const logger = createLogger('coupon-code-action')

@Action()
export class CouponCodeAction {
  constructor(
    @Inject() private readonly couponCodeAdapter: CouponCodeAdapter,
    @Inject() private readonly couponCodeStore: CouponCodeStore,
    @Inject() private readonly cartStore: CartStore
  ) {}

  get couponCodeHandler() {
    return this.couponCodeStore.couponCodeValidator.handlers
  }

  async handleApplyCouponCode(): Promise<void> {
    this.couponCodeStore.couponCodeState.setIsLoading(true)

    await this.applyCouponCode()

    this.couponCodeStore.couponCodeState.setIsLoading(false)
  }

  async handleDeleteCouponCode(code: string): Promise<void> {
    this.couponCodeStore.couponCodeState.setIsLoading(true)

    await this.deleteCouponCode(code)

    this.couponCodeStore.couponCodeState.setIsLoading(false)
  }

  private async applyCouponCode(): Promise<void> {
    this.couponCodeStore.couponCodeState.setError(undefined)
    this.couponCodeStore.couponCodeValidator.resetErrors()

    if (!this.cartStore.cart) return

    const validator = await this.couponCodeStore.couponCodeValidator.submit()
    if (!validator.isValid) {
      return
    }

    const result = await this.couponCodeAdapter.applyCouponCode({
      cartId: this.cartStore.cart.id,
      code: this.couponCodeStore.couponCodeValidator.values.couponCode || '',
    })

    if (isErr(result)) {
      logger.error(result.error.key, result.error)
      this.couponCodeStore.couponCodeState.setError(result.error)
      return
    }

    if (result.data.type === 'validationFailed') {
      FailedValidationError.handleValidatorErrors({
        errors: result.data.data,
        validator: this.couponCodeStore.couponCodeValidator,
      })
      return
    }

    if (result.data.type === 'response') {
      this.cartStore.cartData.setData(result.data.data)
      this.couponCodeStore.couponCodeValidator.handlers.couponCode('')
    }
  }

  private async deleteCouponCode(code: string): Promise<void> {
    this.couponCodeStore.couponCodeState.setError(undefined)
    this.couponCodeStore.couponCodeValidator.resetErrors()

    if (!this.cartStore.cart) return

    const result = await this.couponCodeAdapter.deleteCouponCode({
      cartId: this.cartStore.cart.id,
      code,
    })

    if (isErr(result)) {
      logger.error(result.error.key, result.error)
      this.couponCodeStore.couponCodeState.setError(result.error)
      return
    }

    this.cartStore.cartData.setData(result.data)
  }
}
