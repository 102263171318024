import { CookieService, I18nStore, TokenStore, UserStore } from '@nori/app-kit'
import { Action, Inject } from '@nori/di'

const defaultLocale = 'en-US'
const GHOST_LOGIN_AUTH_TOKEN_COOKIE = 'gl_auth_token'
const GHOST_LOGIN_USER_ID_COOKIE = 'gl_user_id'

@Action()
export class GhostLoginAction {
  constructor(
    @Inject() private readonly cookieService: CookieService,
    @Inject() private readonly tokenStore: TokenStore,
    @Inject() private readonly userStore: UserStore,
    @Inject() private readonly i18nStore: I18nStore
  ) {}

  handleGhostLogin(): void {
    const locale = this.i18nStore.currentLocale

    const baseDomain = window.location.hostname.split('.').slice(1).join('.')
    const authToken = this.cookieService.getCookie(
      GHOST_LOGIN_AUTH_TOKEN_COOKIE
    )
    const userId = this.cookieService.getCookie(GHOST_LOGIN_USER_ID_COOKIE)
    const isUserExist = !!this.userStore.id
    const isSameUserAsLoggedInUser =
      isUserExist && String(this.userStore.id) === userId

    if (isSameUserAsLoggedInUser || !authToken || !userId) return

    this.cookieService.removeCookie(GHOST_LOGIN_AUTH_TOKEN_COOKIE, {
      domain: baseDomain,
      path: '/',
    })
    this.cookieService.removeCookie(GHOST_LOGIN_USER_ID_COOKIE, {
      domain: baseDomain,
      path: '/',
    })

    this.tokenStore.setToken(authToken)
    this.userStore.setId(Number(userId))
    if (window.location.href.includes(`/${locale}`)) {
      window.location.reload()
    } else {
      window.location.replace(locale === defaultLocale ? '/' : `/${locale}`)
    }
  }
}
