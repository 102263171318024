import { HistoryService } from '@nori/app-kit'
import { Action, Inject } from '@nori/di'
import { isErr } from '@nori/result'
import { FailedValidationError } from '@nori/validator'
import { createLogger } from '~/infra/create-logger'
import { ContactErrors } from '~/modules/contacts/core'
import { ContactAdapter } from '~/modules/contacts/infra/contact.adapter'
import { Routes } from '~/modules/routes'

import { ContactFormService } from '../services/contact-form.service'
import { EditContactStore } from '../store/edit-contact.store'

import type { ContactDetailsEntity } from '~/modules/contacts/core'

const logger = createLogger('edit-contact-action')

@Action()
export class EditContactAction {
  constructor(
    @Inject() private readonly editContactStore: EditContactStore,
    @Inject() private readonly contactAdapter: ContactAdapter,
    @Inject() private readonly historyService: HistoryService,
    @Inject() private readonly contactFormService: ContactFormService
  ) {}

  async handleSave(): Promise<void> {
    this.editContactStore.setIsSaving(true)
    this.editContactStore.setError(undefined)
    await this.save()
    this.editContactStore.setIsSaving(false)
  }

  async handleInitialLoad(id: string): Promise<void> {
    this.editContactStore.setIsLoading(true)
    this.editContactStore.setError(undefined)
    await this.load(id)
    this.editContactStore.setIsLoading(false)
  }

  private async load(id: string): Promise<void> {
    const result = await this.contactAdapter.getContactById(id)
    if (isErr(result)) {
      logger.error(result.error.key, result.error)
      this.editContactStore.setError(result.error)
      return
    }

    const contact = result.data
    if (contact?.isMyself) {
      const selfContactError = new ContactErrors.EditAndDeleteForbiddenError()
      logger.error(selfContactError.message, selfContactError)
      this.editContactStore.setError(selfContactError)
      this.editContactStore.setIsAvailableToEdit(false)
      return
    }
    this.editContactStore.setContact(contact)
    this.setContactValues(contact)
  }

  handleCancel(): void {
    const { contact } = this.editContactStore
    if (contact) this.setContactValues(contact)

    this.historyService.push(Routes.contacts.path())
  }

  async handleDeleteContact(): Promise<void> {
    this.editContactStore.setIsDeleting(true)
    this.editContactStore.setError(undefined)

    await this.deleteContact()
    this.editContactStore.setIsDeleting(false)
  }

  private setContactValues(contact: ContactDetailsEntity): void {
    const contactHandlers = this.editContactStore.contactValidator.handlers
    const addressHandlers = this.editContactStore.addressValidator.handlers

    contactHandlers.firstName(contact.firstName)
    contactHandlers.lastName(contact.lastName)
    contactHandlers.email(contact.email)
    contactHandlers.phone(contact.phone ?? '')
    contactHandlers.day(contact.birth.day)
    contactHandlers.month(contact.birth.month)
    contactHandlers.isPotentialHost(contact.isPotentialHost)
    contactHandlers.isPotentialConsultant(contact.isPotentialConsultant)

    addressHandlers.city(contact.currentAddress.city ?? '')
    addressHandlers.state(contact.currentAddress.state ?? '')
    addressHandlers.address1(contact.currentAddress.address1 ?? '')
    addressHandlers.address2(contact.currentAddress.address2 ?? '')
    addressHandlers.zipCode(contact.currentAddress.zipCode ?? '')
  }

  private async save(): Promise<void> {
    const { contact } = this.editContactStore
    if (!contact) return

    const addressValues = this.editContactStore.addressValidator.values
    const contactValues = this.editContactStore.contactValidator.values

    // Required to pass optional validation (since empty string is not considered `no value`)
    this.editContactStore.contactValidator.handlers.phone(
      contactValues.phone || undefined
    )

    const isValid = await this.validate()
    if (!isValid) return

    const result = await this.contactAdapter.editContact({
      ...contact,
      addressAttributes: {
        address1: addressValues.address1,
        address2: addressValues.address2,
        city: addressValues.city,
        country: addressValues.country,
        state: addressValues.state,
        zipCode: addressValues.zipCode,
      },
      birth: {
        day: contactValues.day,
        month: contactValues.month,
      },
      email: contactValues.email,
      firstName: contactValues.firstName,
      lastName: contactValues.lastName,
      phone: contactValues.phone,
      isPotentialHost: contactValues.isPotentialHost,
      isPotentialConsultant: contactValues.isPotentialConsultant,
    })

    if (isErr(result)) {
      logger.error(result.error.key, result.error)
      this.editContactStore.setError(result.error)
      return
    }

    if (result.data.type === 'validationFailed') {
      FailedValidationError.handleValidatorErrors({
        errors: result.data.data,
        validator: this.editContactStore.addressValidator,
      })
      FailedValidationError.handleValidatorErrors({
        errors: result.data.data,
        validator: this.editContactStore.contactValidator,
      })
      return
    }

    this.historyService.push(Routes.contacts.path())
  }

  private async validate(): Promise<boolean> {
    try {
      this.editContactStore.addressValidator.resetErrors()

      const isContactValid = await this.validateContactForm()
      const isAddressValid = await this.contactFormService.validateAddressForm(
        this.editContactStore.addressValidator
      )

      return isAddressValid && isContactValid
    } catch (error: unknown) {
      return false
    }
  }

  private async validateContactForm(): Promise<boolean> {
    const result = await this.editContactStore.contactValidator.submit()

    return result.isValid
  }

  private async deleteContact(): Promise<void> {
    const id = this.editContactStore.contact?.id
    if (!id) return

    const result = await this.contactAdapter.deleteContact(id)
    if (isErr(result)) {
      logger.error(result.error.key, result.error)
      this.editContactStore.setError(result.error)
      return
    }

    this.historyService.push(Routes.contacts.path())
  }
}
