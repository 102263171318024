import { useEffect, useState } from 'react';
export function useImageLoader(url, skipLoading = false) {
    const [status, setStatus] = useState('init');
    useEffect(()=>{
        if (skipLoading || !url) {
            setStatus('empty');
            return;
        }
        const img = new Image();
        img.src = url;
        img.onerror = ()=>{
            setStatus('error');
        };
        img.onload = ()=>{
            setStatus('loaded');
        };
        return ()=>{
            img.onerror = null;
            img.onload = null;
        };
    }, [
        url
    ]);
    return {
        status
    };
}
