import { calculateMoneyDifference, toMoneyValueObject } from '@nori/app-kit'
import { Inject, Service } from '@nori/di'

import { ShippingAddressMapperService } from '../../interface/services/shipping-address-mapper.service'

import type { MoneyValueObject } from '@nori/app-kit'
import type {
  ArchivedOrderItemValueObject,
  ArchivedOrderValueObject,
  ArchivedPaymentToken,
} from '../../core/archived-order.value-object'
import type { ArchivedDsrValueObject } from '../../core/dsr.entity'
import type { DsrDiscountsValueObject } from '../../core/dsr-discounts.value-object'
import type { ShippingRateValueObject } from '../../core/shipping-rate.value-object'

@Service()
export class ArchivedOrderMapper {
  constructor(
    @Inject()
    private readonly shippingAddressMapperService: ShippingAddressMapperService
  ) {}

  toOrderEntity(data: any): ArchivedOrderValueObject {
    return {
      isAddToMailingList: data.add_to_mailing_list,
      backorderedTotal: toMoneyValueObject(data.backorder_total),
      billingAddress: data.billing_address
        ? this.shippingAddressMapperService.toShippingAddressEntity(
            data.billing_address
          )
        : undefined,
      isBillingAddressAsShipping: data.billing_address_as_shipping,
      billingAddresses: data?.billing_addresses?.map((address: any) =>
        address
          ? this.shippingAddressMapperService.toShippingAddressEntity(address)
          : undefined
      ),
      combinedCreditsTotal: toMoneyValueObject(data.combined_credits_total),
      contactId: data.contact_id,
      isContainsAutoship: data.contains_autoship,
      couponDiscountTotal: toMoneyValueObject(data.coupon_discount_total),
      createdAt: new Date(data.created_at),
      currency: data.currency,
      customerEmail: data.customer_email,
      customerFirstName: data.customer_first_name,
      customerId: data.customer_id,
      customerLastName: data.customer_last_name,
      isDefaultShippingAddress: data.default_shipping_address,
      dsr: this.toDsrEntity(data.dsr),
      dsrCapsuleDiscountTotal: toMoneyValueObject(
        data.dsr_capsule_discount_total
      ),
      dsrDiscountTotal: toMoneyValueObject(data.dsr_discount_total),
      isEligibleForCancelation: data.eligible_for_cancelation,
      eligibleItemTotal: toMoneyValueObject(data.eligible_item_total),
      isFullyPaidByCreditsAndDiscounts:
        data.fully_paid_by_credits_and_discounts,
      isFullyPaidByStoreCredits: data.fully_paid_by_store_credits,
      giftCardTotal: toMoneyValueObject(data.gift_card_total),
      grossShippingTotal: toMoneyValueObject(data.gross_shipping_total),
      hasOnlyVirtualSkus: data.has_only_virtual_skus,
      hostessCreditsTotal: toMoneyValueObject(data.hostess_credits_total),
      hostessDiscountsAmountTotal: toMoneyValueObject(
        data.hostess_discounts_amount_total
      ),
      hostessDiscountsCount: data.hostess_discounts_count,
      isFirstOrder: data.is_first_order,
      itemTotal: { cents: data.item_total * 100, currency: data.currency },
      items: this.toItemValueObject(data.items),
      locale: data.locale,
      hasLoyaltyCart: data.loyalty_cart,
      partyAt: data.party_at ? new Date(data.party_at) : undefined,
      partyId: data.party_id,
      partyName: data.party_name,
      pendingPcvCents: data.pending_pcv_cents,
      pendingPqv: data.pending_pqv,
      pendingPrvCents: data.pending_prv_cents,
      placedAt: new Date(data.placed_at),
      productCreditsTotal: toMoneyValueObject(data.product_credits_total),
      promotionalCreditsTotal: toMoneyValueObject(
        data.promotional_credits_total
      ),
      promotionsTotal: toMoneyValueObject(data.promotions_total),
      publicOrderId: data.public_order_id,
      publicStatus: data.public_status,
      referralId: data.referral_id,
      referrerAddress: data.referrer_address,
      referrerId: data.referrer_id,
      referrerName: data.referrer_name,
      retailSales: toMoneyValueObject({
        cents: Math.round(this.toCents(data.prv)),
        currency: data.currency ?? data.total.currency,
      }),
      isSaveAsContactAddress: data.save_as_contact_address,
      sharedCartId: data.shared_cart_id,
      shippedTotal: toMoneyValueObject(data.shipped_total),
      shippingAddress: data.shipping_address
        ? this.shippingAddressMapperService.toShippingAddressEntity(
            data.shipping_address
          )
        : undefined,
      shippingRate: this.toShippingRateValueObject(data.shipping_rate),
      shippingTotal: toMoneyValueObject({
        cents: Math.round(this.toCents(data.shipping_total)),
        currency: data.currency,
      }),
      status: data.status,
      store: data.store,
      storeCreditsTotal: toMoneyValueObject(data.store_credits_total),
      subtotal: this.toSubtotal(data),
      tax: toMoneyValueObject(data.tax),
      isTaxCalculated: data.tax_calculated,
      total: toMoneyValueObject(data.total),
      totalPqv: data.total_pqv,
      typeCode: data.type_code,
      typeName: data.type_name,
      unallowedCancellationMessage: data.unallowed_cancellation_message,
      updatedAt: new Date(data.updated_at),
      isWithBackorderItems: data.with_backorder_items,
      earningsTotal: toMoneyValueObject(data.earnings_total),
      consultantDiscountPercentage: data.earnings_percentage,
      consultantDiscountTotal: data.total_earned_redeemed,
      isConsultantDiscountApplied: data.earned_redeemed,
      totalEarned: toMoneyValueObject(data.total_earned),
      totalNetEarned: toMoneyValueObject(data.total_net_earned),
      couponFee: calculateMoneyDifference({
        minuend: data.total_earned,
        subtrahend: data.earned_redeemed
          ? data.total_earned_redeemed
          : data.total_net_earned,
      }),
      typePublicName: data.type_public_name,
      shipments: data.shipments?.map((shipment: any) => ({
        trackingNumber: shipment.tracking_number,
        trackingUrl: shipment.tracking_url,
      })),
      paymentTokens: this.toPayments(data),
    }
  }

  toPayments(data: any): ArchivedPaymentToken[] {
    return data.payment_tokens.map((token: any) => {
      return {
        amount: Math.round(this.toCents(token.amount)),
        currency: token.currency,
        gatewayType: token.gateway_type,
        nameOnCard: token.name_on_card,
        norwexOrderHeaderId: token.norwex_order_header_id,
        norwexPaymentId: token.norwex_payment_id,
        reqCardNumber: this.toCardNumber(token.req_card_number),
        reqCardType: token.req_card_type,
        status: token.status,
        transactionId: token.transaction_id,
      }
    })
  }

  toCardNumber(number: number | undefined): string | undefined {
    if (!number) return undefined
    let _number = number.toString()
    if (_number.length < 4) {
      _number = _number.padStart(4, '0')
    }
    return _number
  }

  toSubtotal(data: any): MoneyValueObject | undefined {
    if (typeof data.item_total !== 'number') {
      return undefined
    }

    let subtotal = this.toCents(data.item_total)

    if (typeof data.coupon_discount_total?.cents === 'number') {
      subtotal = Math.round(subtotal - data.coupon_discount_total.cents)
    }

    if (typeof data.hostess_credits_total?.cents === 'number') {
      subtotal = Math.round(subtotal - data.hostess_credits_total.cents)
    }

    return toMoneyValueObject({
      cents: subtotal,
      currency: data.currency,
    })
  }

  toCents(value: number): number {
    return value * 100
  }

  toItemValueObject(items: any): ArchivedOrderItemValueObject[] {
    return items.map((item: any) => {
      return {
        discountAmount: item.discount_amount,
        engravable: item.engravable,
        extendedPrice: item.extended_price,
        giftcard: item.giftcard,
        hostessCredits: item.hostess_credits,
        inputTexts: item.input_texts,
        isBackordered: item.is_backordered,
        isGiftcard: item.is_giftcard,
        pqv: item.pqv,
        price: item.price,
        processingStatus: item.processing_status,
        productDescription: item.product_description,
        productName: item.product_name,
        quantity: item.quantity,
        retailPrice: item.retail_price,
        skuCode: item.sku_code,
        templateDisplayName: item.template_display_name,
        virtual: item.virtual,
      }
    })
  }

  toDsrEntity(data: any): ArchivedDsrValueObject {
    return {
      dsrLogin: data.dsr_login,
      firstName: data.first_name,
      lastName: data.last_name,
      nickname: data.nickname,
      email: data.email,
      pwsUrlSuffix: data.pws_url_suffix,
      profilePhoto: data.profile_photo,
    }
  }

  toDsrDiscounts(data: any): DsrDiscountsValueObject | undefined {
    return {
      percentage: data.percentage,
      total: toMoneyValueObject(data.total),
    }
  }

  toShippingRateValueObject(data: any): ShippingRateValueObject | undefined {
    if (!data) return undefined

    return {
      key: data.key,
      name: data.name,
      fee: toMoneyValueObject(data.fee),
      additionalInfo: data.additional_info,
    }
  }
}
