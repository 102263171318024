import { useEffect, useMemo } from 'react'

import { DaysDropdown, MonthsDropdown } from '@nori/app-kit'
import { observer } from '@nori/di'
import { useTranslations } from '@nori/lang-i18n'
import { Checkbox, Input, PhoneInput } from '@nori/ui-kit'

import type {
  ContactValidatorSchemeType,
  ContactWithoutEmailValidatorSchemeType,
} from '~/modules/contacts/interface/create-contact-validator'

type Props = {
  validator: ContactValidatorSchemeType | ContactWithoutEmailValidatorSchemeType
  isEmailEditable?: boolean
  isEmailProvided?: boolean
  isShowContactWithoutEmailCheckbox?: boolean
  isContactWithoutEmail?: boolean
  handleContactWithoutEmailToggle?: () => void
}

export const ContactBlock = observer(
  ({
    validator,
    isEmailEditable = true,
    isEmailProvided,
    isContactWithoutEmail = false,
    isShowContactWithoutEmailCheckbox,
    handleContactWithoutEmailToggle = () => {},
  }: Props) => {
    const t = useTranslations('contact')
    const { values, handlers, errors } = validator
    const emailHelperText = useMemo(() => {
      if (isEmailProvided === false) return t('autogeneratedEmail')
      if (isEmailEditable) return ''
      return t('emailIsNotEditable')
    }, [t, isEmailEditable, isEmailProvided])

    const emailValue = useMemo(() => {
      if (isContactWithoutEmail) {
        return ''
      }
      if (isEmailProvided === false) {
        return ''
      }
      return values.email
    }, [isContactWithoutEmail, isEmailProvided, values.email])

    useEffect(() => {
      return () => validator.reset()
    }, [])

    return (
      <>
        <div className="flex flex-col justify-between lg:flex-row lg:gap-4">
          <Input
            data-test-id="first-name-input"
            label={t('firstName')}
            placeholder={t('firstName')}
            value={values.firstName}
            error={errors.firstName}
            onChange={handlers.firstName}
          />
          <div className="mt-2 lg:mt-0" />
          <Input
            data-test-id="last-name-input"
            label={t('lastName')}
            placeholder={t('lastName')}
            value={values.lastName}
            error={errors.lastName}
            onChange={handlers.lastName}
          />
        </div>

        <div className="my-2 flex flex-col justify-between lg:my-5 lg:flex-row lg:gap-4">
          <MonthsDropdown
            month={values.month}
            onChange={(month) => {
              handlers.month(month)
              handlers.day(undefined)
            }}
          />
          <div className="mt-2 lg:mt-0" />
          <DaysDropdown
            day={values.day}
            month={values.month}
            onChange={(day) => handlers.day(day)}
          />
        </div>

        <Input
          label={t('email')}
          placeholder={t('email')}
          type="email"
          value={emailValue}
          onChange={handlers.email}
          error={errors.email}
          disabled={!isEmailEditable || isContactWithoutEmail}
          helperText={emailHelperText}
          data-test-id={'email-input'}
        />
        {isShowContactWithoutEmailCheckbox && (
          <Checkbox
            value={isContactWithoutEmail}
            onChange={handleContactWithoutEmailToggle}
          >
            {t('createContactWithoutEmail')}
          </Checkbox>
        )}
        <div className="mt-2 lg:mt-10" />
        <PhoneInput
          label={t('phone')}
          placeholder={t('phone')}
          data-test-id="phone-input"
          onChange={handlers.phone}
          value={values.phone}
          error={errors.phone}
        />
        <hr className="my-3 lg:my-5" />
        <div className="flex flex-row gap-12 lg:gap-24">
          <Checkbox
            onChange={handlers.isPotentialConsultant}
            value={values.isPotentialConsultant}
            data-test-id={'potential-ambassador-checkbox'}
          >
            {t('potentialHost')}
          </Checkbox>
          <Checkbox
            onChange={handlers.isPotentialHost}
            value={values.isPotentialHost}
            data-test-id={'potential-host-checkbox'}
          >
            {t('potentialConsultant')}
          </Checkbox>
        </div>
        <hr className="mb-10 mt-5" />
      </>
    )
  }
)
