import { useEffect, useRef } from 'react';
import { useEscPress } from '../../../hooks/use-esc-press';
import { toggleState } from './toggle-state';
function getId() {
    const hash = new Date().getTime();
    const id = Math.random().toString(16).slice(2);
    return `m-${hash}-${id}`;
}
export function useClose({ onClose , isOpen  }) {
    const idRef = useRef(getId());
    const isOpenRef = useRef(false);
    const closeRef = useRef(onClose);
    closeRef.current = onClose;
    const isOpenChanged = isOpenRef.current !== isOpen;
    if (isOpenRef.current !== isOpen) {
        isOpenRef.current = isOpen;
    }
    if (isOpenChanged) {
        toggleState({
            isOpen,
            id: idRef.current
        });
    }
    useEscPress({
        isOpen,
        onClose: closeRef.current
    });
    useEffect(()=>{
        const onPop = ()=>{
            if (!isOpenRef.current) return;
            const isExist = window.location.search.includes(idRef.current);
            if (!isExist) {
                // close
                closeRef.current();
            }
        };
        window.addEventListener('popstate', onPop);
        return ()=>{
            window.removeEventListener('popstate', onPop);
        };
    }, []);
}
