import type { AutosaveTabsValueObject } from '../core/autosave-tabs.value-object'

export const autosaveTabs: AutosaveTabsValueObject[] = [
  {
    key: 'next-order',
    translationKey: 'autosaveSettings.nextOrder',
    dataTestId: 'autosave-nextOrder-tab',
  },
  {
    key: 'subscriptions',
    translationKey: 'autosaveSettings.allSubscriptions',
    dataTestId: 'autosave-all-tab',
  },
]
