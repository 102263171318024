import { Divider, Loader } from '@nori/ui-kit'
import { CartBundleSubscription } from '~/modules/product-subscription/ui/cart-bundle-subscription'

import { BundleDropDown } from '../bundle-dropdown/bundle-dropdown'
import { ProductItemControlsDesktop } from '../desktop/product-item-controls-desktop'
import { HostessDiscountButton } from '../hostess-discount-button'
import { ProductItemName } from '../product-item-name'
import { CartItemPrice } from '../product-item-price'
import { ProductItemQuantity } from '../product-item-quantity'
import { ProductItemThumbnail } from '../product-item-thumbnail'

import type { CartBundleEntity } from '@nori/app-kit'

type Props = {
  currentQuantity: string
  currency: string
  handleCancel: () => void
  handleItemQuantityChange: (value: string) => void
  isEditMode: boolean
  isProductLoading: boolean
  onAdd: () => void
  onEditSwitch: () => void
  onRemove: () => void
  bundle: CartBundleEntity
  canBeAutoShipped?: boolean
  isEditable?: boolean
  lastItemInBundle?: number
}

export const BundleItemDesktop = ({
  currentQuantity,
  handleCancel,
  handleItemQuantityChange,
  isEditMode,
  isProductLoading,
  onAdd,
  onEditSwitch,
  onRemove,
  bundle,
  canBeAutoShipped,
  isEditable,
  lastItemInBundle,
  currency,
}: Props) => {
  return (
    <>
      <div className="relative py-6">
        {isProductLoading && <Loader viewType="filled-center" />}

        <div className="flex justify-between">
          <div className="flex w-3/6">
            <div className="flex-shrink-0 flex-grow-0 basis-[100px]">
              <ProductItemThumbnail url={bundle.thumbnailUrl} />
            </div>
            <div className="ml-7 flex w-full flex-col gap-1">
              <ProductItemName productName={bundle.name} />
              <ProductItemQuantity
                maxInCart={bundle.maxInCart}
                isEditMode={isEditMode}
                currentQuantity={currentQuantity}
                productQuantity={bundle.quantity}
                onQuantityChange={handleItemQuantityChange}
              />
            </div>
          </div>
          <CartItemPrice
            retailPrice={bundle.retailPrice}
            salePrice={bundle.salePrice}
            price={bundle.price}
            promotionalDiscounts={bundle.totalPromotionalDiscounts}
            totalRetailPrice={bundle.retailPrice}
            totalSalePrice={bundle.salePrice}
            combinedCredits={bundle.totalCombinedCredits}
            dsrDiscount={bundle.totalDsrDiscount}
            currency={currency}
            hostessDiscountPercentage={bundle.hostessDiscountPercentage}
            hostessDiscountValue={bundle.totalHostessDiscountValue}
            productCredits={bundle.totalProductCredits}
            totalPrice={bundle.totalPrice}
            totalDiscounts={bundle.totalDiscount}
          />
        </div>
        <div className="ml-[128px] flex flex-row justify-between">
          {!isEditMode && (
            <div className="flex max-w-[250px] flex-col gap-5">
              <BundleDropDown bundle={bundle} />

              {lastItemInBundle && (
                <HostessDiscountButton
                  productId={lastItemInBundle}
                  isDiscountApplied={!!bundle.hostessDiscount}
                  isDiscountDisallow={bundle.disallowHostessDiscounts}
                />
              )}
            </div>
          )}
          <ProductItemControlsDesktop
            id={bundle.id}
            isEditMode={isEditMode}
            isEditable={isEditable}
            onCancel={handleCancel}
            onAdd={onAdd}
            onRemove={onRemove}
            onEditSwitch={onEditSwitch}
          />
        </div>
        {canBeAutoShipped && (
          <div className="mt-6">
            <CartBundleSubscription bundle={bundle} />
          </div>
        )}
      </div>

      <Divider />
    </>
  )
}
