import { Store, Toggle } from '@nori/di'

import type { PartyEntity } from '~/modules/parties/core/entities/party.entity'
import type { MoveOrderStepValueObject, OrderEntity } from '../../core'

@Store()
export class MoveOrderStore {
  modalToggle = new Toggle(false)

  step: MoveOrderStepValueObject = 'selectEvent'

  selectedOrder?: OrderEntity = undefined
  selectedEvent?: PartyEntity = undefined

  isLoading = false
  isNoEventsAvailable = false

  isMovingOrder = false

  setSelectedOrder(order?: OrderEntity): void {
    this.selectedOrder = order
  }

  setSelectedEvent(event?: PartyEntity): void {
    this.selectedEvent = event
  }

  setStep(step: MoveOrderStepValueObject): void {
    this.step = step
  }

  setIsMovingOrder(isMoving: boolean): void {
    this.isMovingOrder = isMoving
  }

  setIsLoading(isLoading: boolean): void {
    this.isLoading = isLoading
  }

  setNoEventsAvailable(noEventsAvailable: boolean): void {
    this.isNoEventsAvailable = noEventsAvailable
  }
}
