import { useEffect } from 'react'

import { observer } from '@nori/di'
import { useCartContext } from '~/modules/cart'

import { ProductSubscription } from './product-subscription'

import type { CommonBundleEntity, CommonStyleEntity } from '@nori/app-kit'

type Props = {
  item: CommonStyleEntity | CommonBundleEntity
  selectedSubscriptionId?: number
  isSubscriptionSelected: boolean
  onSubscriptionOpen: () => void
  onSubscriptionClose: () => void
  onSubscriptionChange: (id: number) => void
}

export const AddProductSubscription = observer(
  ({
    item,
    selectedSubscriptionId,
    isSubscriptionSelected,
    onSubscriptionChange,
    onSubscriptionOpen,
    onSubscriptionClose,
  }: Props) => {
    const { cartStore } = useCartContext()
    useEffect(() => {
      if (cartStore.isCustomerCart) {
        onSubscriptionClose()
      }
    }, [cartStore.isCustomerCart, onSubscriptionClose])

    return (
      <ProductSubscription
        subscriptions={item.productSubscriptions}
        selectedSubscriptionId={selectedSubscriptionId}
        isSubscriptionSelected={isSubscriptionSelected}
        onSubscriptionOptionChange={(id: number) => onSubscriptionChange(id)}
        onSubscriptionOpen={onSubscriptionOpen}
        onSubscriptionClose={onSubscriptionClose}
      />
    )
  }
)
