import { formatPhoneNumber } from './format-phone-number';
import { PHONE_REGEXP } from './phone-regexp';
import { getRawPhoneNumber } from './raw-format-phone';
export function formatPhone(phone) {
    if (!phone) {
        return {
            isRegexPassed: false,
            formattedPhoneNumber: '',
            rawValue: ''
        };
    }
    const formattedPhoneNumber = formatPhoneNumber(phone);
    const rawValue = getRawPhoneNumber(formattedPhoneNumber);
    const isRegexPassed = PHONE_REGEXP.test(rawValue.trim());
    return {
        isRegexPassed,
        formattedPhoneNumber,
        rawValue
    };
}
