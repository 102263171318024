import { type CommonBundleEntity, ProductImage } from '@nori/app-kit'
import { AddProductSubscription } from '~/modules/product-subscription/ui/add-product-subscription'

import { AddToCartBlock } from './add-to-cart-block'

import type { MoneyValueObject } from '@nori/app-kit'

type Props = {
  item: CommonBundleEntity
  picture: string
  name?: string
  maxRetailPrice?: MoneyValueObject
  maxPrice?: MoneyValueObject
  onAddToCart: (quantity: number) => void
  isBundleCustomizable?: boolean
  selectedSubscriptionId: number
  setSelectedSubscriptionId: (id: number) => void
  subscriptionToggle: {
    isOpen: boolean
    handleOpen: () => void
    handleClose: () => void
    handleToggle: () => void
  }
}

export function BundleDesktopCell({
  item,
  picture,
  name,
  maxRetailPrice,
  maxPrice,
  onAddToCart,
  isBundleCustomizable,
  selectedSubscriptionId,
  setSelectedSubscriptionId,
  subscriptionToggle,
}: Props) {
  return (
    <>
      <div className="flex w-full flex-wrap items-center justify-between  lg:flex-nowrap">
        <div className="flex w-full items-center justify-between">
          <div className="flex">
            <div className="mr-5 flex min-w-20 flex-col items-center">
              <ProductImage
                src={picture}
                alt="Product image not found!"
                width={80}
                height={80}
                styleName="h-full"
              />
            </div>

            <div className="flex flex-col justify-start text-sm font-medium text-gray-500">
              {name && <div className="text-base font-bold">{name}</div>}
            </div>
          </div>
        </div>
        <div className="mt-5 flex w-full items-center max-lg:flex-wrap max-lg:justify-end max-lg:gap-y-5 lg:mt-0 lg:justify-end lg:justify-around">
          <AddToCartBlock
            isQuantityAdjustable={false}
            id={item.code}
            salePrice={maxPrice}
            retailPrice={maxRetailPrice}
            onAddToCart={onAddToCart}
            isAvailable={item.available}
            isCustomizable={isBundleCustomizable}
          />
          <div className="order-4">
            <div className="min-h-5 h-5 w-5 min-w-5" />
          </div>
        </div>
      </div>
      {!!item.canBeAutoShipped && (
        <div className="mt-6">
          <AddProductSubscription
            isSubscriptionSelected={subscriptionToggle.isOpen}
            onSubscriptionClose={subscriptionToggle.handleClose}
            onSubscriptionOpen={subscriptionToggle.handleOpen}
            onSubscriptionChange={(id) => setSelectedSubscriptionId(id)}
            selectedSubscriptionId={selectedSubscriptionId}
            item={item}
          />
        </div>
      )}
    </>
  )
}
