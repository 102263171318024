import { useProfileContext } from '@nori/app-kit'
import { observer } from '@nori/di'
import { useTranslations } from '@nori/lang-i18n'
import { Divider, DropdownSection, Tabs } from '@nori/ui-kit'

import { useContactContext } from '../../../interface/use-contact-context'

import { FilterButtons } from './filter-buttons'
import { ContactsFiltersGroup } from './filter-group'

import type { TabOptions } from '../../../core/filters/contact-filter-tab-option'

export const ContactsFiltersMenu = observer(() => {
  const { contactFiltersStore, contactFiltersAction } = useContactContext()

  const t = useTranslations('contacts.filters')
  const { userStore } = useProfileContext()

  if (!contactFiltersStore.filtersDropdownToggle.isOpen) {
    return null
  }

  return (
    <DropdownSection onOutsideClick={contactFiltersAction.handleCloseFilters}>
      <div className="flex flex-col overflow-hidden p-3 ">
        <Tabs<TabOptions>
          active={contactFiltersStore.activeTab}
          onChange={contactFiltersAction.handleSetActiveTab}
        >
          <Tabs.Titles isWrap={false}>
            <Tabs.Title<TabOptions> name="customer">
              <span className="whitespace-nowrap">{t('customer')}</span>
            </Tabs.Title>
            <Tabs.Title<TabOptions> name="products">
              <span className="whitespace-nowrap">{t('products')}</span>
            </Tabs.Title>
            <Tabs.Title<TabOptions> name="perks">
              <span className="whitespace-nowrap">{t('perks')}</span>
            </Tabs.Title>
            {userStore.isOSEnabled && (
              <Tabs.Title<TabOptions> name="catalogs">
                <span className="whitespace-nowrap">{t('catalogMailing')}</span>
              </Tabs.Title>
            )}
          </Tabs.Titles>
          <div className="overflow-auto">
            <Tabs.Panel<TabOptions> name="customer">
              <ContactsFiltersGroup
                options={contactFiltersStore.customerFilters}
              />
            </Tabs.Panel>
            <Tabs.Panel<TabOptions> name="products">
              <ContactsFiltersGroup
                options={contactFiltersStore.productFilters}
              />
            </Tabs.Panel>
            <Tabs.Panel<TabOptions> name="perks">
              <ContactsFiltersGroup
                options={contactFiltersStore.rewardFilters}
              />
            </Tabs.Panel>
            {userStore.isOSEnabled && (
              <Tabs.Panel<TabOptions> name="catalogs">
                <ContactsFiltersGroup
                  options={contactFiltersStore.catalogFilters}
                />
              </Tabs.Panel>
            )}
          </div>
        </Tabs>
        <div className="mb-3">
          <Divider />
        </div>
        <FilterButtons />
      </div>
    </DropdownSection>
  )
})
