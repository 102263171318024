import type { OrderFilterOption } from '../../core/orders-list-filters'

export const ordersFiltersOptions: OrderFilterOption[] = [
  {
    filterKey: 'group_status_list[]',
    translationKey: 'orders.list.filters.options.groupStatusListShipped',
    value: 'shipped',
    isChecked: false,
  },
  {
    filterKey: 'group_status_list[]',
    translationKey: 'orders.list.filters.options.groupStatusListPlaced',
    value: 'placed',
    isChecked: false,
  },
  {
    filterKey: 'group_status_list[]',
    translationKey: 'orders.list.filters.options.groupStatusListCancelled',
    value: 'cancelled',
    isChecked: false,
  },
  {
    filterKey: 'group_status_list[]',
    translationKey: 'orders.list.filters.options.groupStatusListError',
    value: 'error',
    isChecked: false,
  },
  {
    filterKey: 'order_type_list[]',
    translationKey: 'orders.list.filters.options.orderTypeListHostessOrder',
    value: 'hostess_order',
    isChecked: false,
  },
  {
    filterKey: 'order_type_list[]',
    translationKey:
      'orders.list.filters.options.orderTypeListReferralHostessOrder',
    value: 'referral_hostess_order',
    isChecked: false,
  },
  {
    filterKey: 'order_type_list[]',
    translationKey: 'orders.list.filters.options.orderTypeListNonReferralOrder',
    value: 'non_referral_order',
    isChecked: false,
  },
  {
    filterKey: 'order_type_list[]',
    translationKey:
      'orders.list.filters.options.orderTypeListCorporateHostessOrder',
    value: 'corporate_hostess_order',
    isChecked: false,
  },
  {
    filterKey: 'order_type_list[]',
    translationKey: 'orders.list.filters.options.orderTypeListPersonalOrder',
    value: 'personal_order',
    isChecked: false,
  },
  {
    filterKey: 'order_type_list[]',
    translationKey: 'orders.list.filters.options.orderTypeListReplacementOrder',
    value: 'replacement_order',
    isChecked: false,
  },
  {
    filterKey: 'order_type_list[]',
    translationKey: 'orders.list.filters.options.orderTypeListAutoCorpOrder',
    value: 'auto_corp_order',
    isChecked: false,
  },
  {
    filterKey: 'order_type_list[]',
    translationKey:
      'orders.list.filters.options.orderTypeListAutoCustomerOrder',
    value: 'auto_customer_order',
    isChecked: false,
  },
  {
    filterKey: 'order_type_list[]',
    translationKey:
      'orders.list.filters.options.orderTypeListAutoPersonalOrder',
    value: 'auto_personal_order',
    isChecked: false,
  },
  {
    filterKey: 'rewards',
    isChecked: false,
    translationKey: 'orders.list.filters.options.rewards',
    value: true,
  },
  {
    filterKey: 'shop_offer',
    isChecked: false,
    translationKey: 'orders.list.filters.options.consultantOffers',
    value: true,
  },
  {
    filterKey: 'referral_order',
    isChecked: false,
    translationKey: 'orders.list.filters.options.referralOrder',
    value: true,
  },
  {
    filterKey: 'can_be_moved',
    isChecked: false,
    translationKey: 'orders.list.filters.options.canBeMoved',
    value: true,
  },
]
