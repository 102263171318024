type Props = {
  label: string
  value: string
}

export function SummaryItem({ label, value }: Props) {
  return (
    <div className="flex items-center justify-between lg:flex-col lg:justify-center">
      <div>{label}</div>
      <div className="text-xl font-bold text-gray-700">{value}</div>
    </div>
  )
}
