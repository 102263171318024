import { useTranslations } from '@nori/lang-i18n'
import { Icon } from '@nori/ui-kit'

export function TableEmpty() {
  const t = useTranslations('contacts.list.table.extended.subscription')

  return (
    <div className="flex text-primary-900">
      <Icon name="StarOutline" />
      <span className="ml-1 text-sm font-bold">{t('tableEmpty')}</span>
    </div>
  )
}
