import { ExportAllStore, UserStore } from '@nori/app-kit'
import { Inject, Service } from '@nori/di'
import { isErr } from '@nori/result'
import { createLogger } from '~/infra/create-logger'
import { NotesStore } from '~/modules/notes/interface/notes.store'

import {
  type ContactDetailsEntity,
  type ContactErrorInstance,
  ContactErrors,
  type ContactsValueObject,
  type SearchContactsInput,
} from '../../core'
import { ContactAdapter } from '../../infra/contact.adapter'
import { ContactFiltersStore } from '../store/contact-filters.store'
import { ContactsStore } from '../store/contacts.store'

import type { DomainError } from '@nori/errors'
import type { PromiseResult } from '@nori/result'

const logger = createLogger('load-contacts.service')

@Service()
export class LoadContactsService {
  constructor(
    @Inject() private readonly contactsStore: ContactsStore,
    @Inject() private readonly contactAdapter: ContactAdapter,
    @Inject() private readonly contactFiltersStore: ContactFiltersStore,
    @Inject() private readonly notesStore: NotesStore,
    @Inject() private readonly exportAllStore: ExportAllStore,
    @Inject() private readonly userStore: UserStore
  ) {}

  searchContacts(
    input: SearchContactsInput
  ): PromiseResult<ContactsValueObject, DomainError> {
    return this.contactAdapter.searchContacts(input)
  }

  async loadContacts({
    page,
    perPage,
    searchQuery,
  }: {
    page?: number
    perPage?: number
    searchQuery?: string
  }): Promise<void> {
    this.contactsStore.setIsLoading(true)

    await this.fetchContacts({
      page,
      perPage,
      searchQuery,
    })

    this.contactsStore.setIsLoading(false)
  }

  private async fetchContacts({
    page,
    perPage,
    searchQuery,
    shouldReceivePrintCatalog,
  }: {
    page?: number
    perPage?: number
    searchQuery?: string
    shouldReceivePrintCatalog?: boolean
  }): Promise<void> {
    this.contactsStore.setError(undefined)

    if (searchQuery) {
      this.contactsStore.abortPreviousRequest()
    }
    const result = await this.contactAdapter.searchContacts({
      page: page ?? this.contactsStore.pagination?.page ?? undefined,
      perPage: perPage ?? this.contactsStore.pagination?.perPage,
      searchQuery: searchQuery ?? this.contactsStore.searchQuery,
      searchFilter: this.contactsStore.searchFilter,
      filters: this.contactFiltersStore.appliedFilters,
      order: this.contactsStore.sortDirection,
      sortBy: this.contactsStore.sortBy,
      controller: this.contactsStore.abortController,
      shouldReceivePrintCatalog,
    })

    if (isErr(result)) {
      if (result.error instanceof ContactErrors.RequestAborted) {
        return
      }
      logger.error(result.error.key, result.error)
      this.contactsStore.setError(result.error)
      return
    }

    this.exportAllStore.dropContactExportProgress()

    this.contactsStore.setContacts(result.data.contacts)
    result.data.contacts.forEach((contact) => {
      this.notesStore.setNotes(contact.id, contact.notes)
    })
    this.contactsStore.setPagination(result.data.pagination)
  }

  getSelfContact():
    | PromiseResult<ContactDetailsEntity, DomainError>
    | undefined {
    if (!this.userStore.id) return
    return this.findContactByDsrAndCustomerIds({
      dsrId: this.userStore.id,
      customerId: this.userStore.id,
    })
  }

  getContactById(
    id: string
  ): PromiseResult<ContactDetailsEntity, ContactErrorInstance> {
    return this.contactAdapter.getContactById(id)
  }

  findContactByDsrAndCustomerIds({
    dsrId,
    customerId,
  }: {
    dsrId: number
    customerId: number
  }): PromiseResult<ContactDetailsEntity, ContactErrorInstance> {
    return this.contactAdapter.findContactByDsrAndCustomerIds({
      dsrId,
      customerId,
    })
  }
}
