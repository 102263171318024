import _async_to_generator from "@swc/helpers/src/_async_to_generator.mjs";
import { initialize } from 'launchdarkly-js-client-sdk';
import { createLogger } from '../infra/create-logger';
import { CLIENT_SIDE_IDS } from './client-side-ids';
const logger = createLogger('feature-flags.service');
const isLocalNodeEnv = process.env.NODE_ENV === 'development';
let client;
export class FeatureFlags {
    handleInitFeatureFlags(environment, context) {
        var _this = this;
        return _async_to_generator(function*() {
            _this.isInitialized = true;
            if (isLocalNodeEnv) return;
            if (!environment || !(environment in CLIENT_SIDE_IDS)) {
                logger.error('Tried to init feature flags with unsupported env');
                return;
            }
            const clientSideId = CLIENT_SIDE_IDS[environment];
            if (!clientSideId) return;
            yield _this.initClient(context, clientSideId);
            _this.subscribeToFlags();
            _this.loadFlags();
        })();
    }
    getFlag(flagName) {
        if (!this.isInitialized) {
            logger.error('Tried to access getFlag before init');
        }
        return this.flags[flagName];
    }
    getFlags() {
        if (!this.isInitialized) {
            logger.error('Tried to access getFlags before init');
        }
        return this.flags;
    }
    on(flagName, cb) {
        var _this_cbMap, _this_cbMap_flagName;
        if (!this.isInitialized) {
            logger.error('Tried to access on before init');
        }
        var _this_cbMap_flagName1;
        this.cbMap[flagName] = (_this_cbMap_flagName1 = (_this_cbMap = this.cbMap) == null ? void 0 : _this_cbMap[flagName]) != null ? _this_cbMap_flagName1 : [];
        (_this_cbMap_flagName = this.cbMap[flagName]) == null ? void 0 : _this_cbMap_flagName.push(cb);
        return ()=>{
            const list = this.cbMap[flagName];
            if (!list) return;
            const index = list.findIndex((item)=>item === cb);
            if (index === -1) return;
            list.splice(index, 1);
        };
    }
    initClient(context, clientSideId) {
        return _async_to_generator(function*() {
            try {
                client = initialize(clientSideId, context);
                yield client.waitForInitialization();
                logger.info('Succesfully initialized feature flags client');
            } catch (err) {
                logger.error('Failed to load Feature Flags client');
            }
        })();
    }
    subscribeToFlags() {
        if (!client) return;
        client.on('change', (changeset)=>{
            const changesetFlagNames = Object.keys(changeset);
            changesetFlagNames.forEach((flagName)=>{
                if (flagName in this.flags) {
                    var _changeset_flagName;
                    // At this point flagName from LD is one of supported by application
                    const _flagName = flagName;
                    this.emit(_flagName, (_changeset_flagName = changeset[flagName]) == null ? void 0 : _changeset_flagName.current);
                }
            });
        });
    }
    loadFlags() {
        if (!client) return;
        const allFlags = client.allFlags();
        Object.entries(allFlags).forEach(([flagName, flagValue])=>{
            if (flagName in this.flags) {
                // At this point flagName from LD is one of supported by application
                const _flagName = flagName;
                this.flags[_flagName] = flagValue;
            }
        });
    }
    emit(flagName, flagValue) {
        var _this_cbMap_flagName;
        this.flags[flagName] = flagValue;
        if (!(flagName in this.cbMap)) return;
        (_this_cbMap_flagName = this.cbMap[flagName]) == null ? void 0 : _this_cbMap_flagName.forEach((cb)=>{
            cb(flagName, flagValue);
        });
    }
    constructor(featureFlagsList){
        this.cbMap = {};
        this.isInitialized = false;
        this.flags = featureFlagsList;
    }
}
