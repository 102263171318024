import { useEffect } from 'react'

import { observer } from '@nori/di'
import { useTranslations } from '@nori/lang-i18n'
import { Loader, PaypalButton, Swap } from '@nori/ui-kit'
import { usePaymentMethodsContext } from '~/modules/payment-methods'

import { useBillingFormContext } from '../../interface/use-billing-form.context'

import { PaypalAccount } from './paypal-account'

export const PaypalSection = observer(() => {
  const t = useTranslations('billingForm.enrollment')
  const { billingFormStore, billingFormPayPalAction } = useBillingFormContext()
  const { consultantPaymentMethodStore } = usePaymentMethodsContext()

  useEffect(() => {
    if (consultantPaymentMethodStore.isLoading) return
    billingFormPayPalAction.handleInitialLoad()
  }, [consultantPaymentMethodStore.isLoading])

  return (
    <div className="border-b border-l border-r border-solid border-primary-500 max-sm:px-4 max-sm:py-8 sm:p-8">
      <Swap is={billingFormStore.payPalToken.isLoading} isSlot={<Loader />}>
        <Swap
          is={!!billingFormStore.payPalToken.data}
          isSlot={
            <>
              {billingFormStore.payPalToken.data && (
                <PaypalAccount
                  paypalAccount={billingFormStore.payPalToken.data}
                  onChange={billingFormPayPalAction.handleChangePayPal}
                />
              )}
            </>
          }
        >
          <PaypalButton
            data-test-id="pay_pal"
            onClick={billingFormPayPalAction.handleChangePayPal}
          />
        </Swap>

        {billingFormStore.view === 'enrollment' && (
          <div className="mt-4 text-gray-700">
            {t('savePayPalToProfileCaption')}
          </div>
        )}
      </Swap>
    </div>
  )
})
