import { Action, Inject } from '@nori/di'
import { isErr } from '@nori/result'
import { createLogger } from '~/infra/create-logger'
import { LoadContactsService } from '~/modules/contacts/interface/services/contact.service'
import { LoadOrderService } from '~/modules/order/interface/services/load-order.service'
import { SkuService } from '~/modules/sku'

import { OrdersListAdapter } from '../../infra/orders-list.adapter'
import { OrderDetailsStore } from '../stores/order-details.store'

import type { DomainError } from '@nori/errors'
import type { Result } from '@nori/result'
import type { ContactDetailsEntity } from '~/modules/contacts'
import type { OrderEntity } from '~/modules/orders-list/core'

const logger = createLogger('order-details.action')

@Action()
export class OrderDetailsAction {
  constructor(
    @Inject() private readonly orderListAdapter: OrdersListAdapter,
    @Inject() private readonly orderDetailsStore: OrderDetailsStore,
    @Inject() private readonly loadContactsService: LoadContactsService,
    @Inject() private readonly skuService: SkuService,
    @Inject() private readonly loadOrderService: LoadOrderService
  ) {}

  async handleLoadOrderDetails({
    publicId,
    contactId,
  }: Pick<OrderEntity, 'publicId' | 'contactId'>): Promise<void> {
    if (this.orderDetailsStore.getOrderDetails(publicId).isLoading) return

    this.orderDetailsStore.setIsLoading(publicId, true)

    await this.loadOrderDetails({ publicId, contactId })

    this.orderDetailsStore.setIsLoading(publicId, false)
  }

  private async loadOrderDetails({
    publicId,
    contactId,
  }: Pick<OrderEntity, 'publicId' | 'contactId'>): Promise<void> {
    this.orderDetailsStore.setError(publicId, undefined)

    const [orderResult, contactResult] = await Promise.all([
      this.loadOrderService.loadOrder(publicId),
      this.loadContact(contactId),
    ])

    if (isErr(orderResult)) {
      logger.error(orderResult.error.key, orderResult.error)
      this.orderDetailsStore.setError(publicId, orderResult.error)
      return
    }

    let contact
    if (isErr(contactResult)) {
      logger.error(contactResult.error.key, contactResult.error)
    } else {
      contact = contactResult?.data
    }

    this.orderDetailsStore.setOrderDetails(publicId, {
      order: orderResult.data,
      contact: contact,
    })
  }

  private async loadContact(
    contactId?: number
  ): Promise<Result<ContactDetailsEntity, DomainError> | undefined> {
    if (!contactId) return undefined
    return this.loadContactsService.getContactById(contactId.toString())
  }
}
