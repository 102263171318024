import { useMemo, useState } from 'react'

import { useTranslations } from '@nori/lang-i18n'
import { Dropdown, Icon, Radio, Tooltip } from '@nori/ui-kit'
import { NextOrderDate } from '~/modules/autosave/ui/next-order/next-order-date'

import { AutosaveTooltip } from './autosave-tooltip'

import type { ProductSubscriptionValueObject } from '../core/subscription.value-object'

type AddItemProductSubscriptionProps = {
  canBeAutoshipped?: boolean
  isLoading?: boolean
  subscriptions?: ProductSubscriptionValueObject
  selectedSubscriptionId: number
  itemId: number
  isSubscriptionSelected: boolean
  isCanBeAutoshipped: boolean
  isNextOrderDateIsDifferent?: boolean
  onSubscriptionOptionChange: (id: number) => void
  onSubscriptionOpen: () => void
  onSubscriptionClose: () => void
}

export const AddItemProductSubscription = ({
  isLoading,
  subscriptions,
  selectedSubscriptionId,
  itemId,
  isSubscriptionSelected,
  isCanBeAutoshipped,
  isNextOrderDateIsDifferent,
  onSubscriptionOptionChange,
  onSubscriptionOpen,
  onSubscriptionClose,
}: AddItemProductSubscriptionProps) => {
  const t = useTranslations()
  const [isSinglePurchase, setIsSinglePurchase] =
    useState<boolean>(isCanBeAutoshipped)
  const loadingClassName = isLoading ? 'opacity-50 pointer-events-auto' : ''
  const selectedSubscription = useMemo(() => {
    if (isSinglePurchase && !selectedSubscriptionId) {
      return undefined
    }
    const selectedSubscription = subscriptions?.find(
      (subscription) => subscription.id === selectedSubscriptionId
    )
    if (selectedSubscription) {
      return selectedSubscription
    } else if (subscriptions?.length) {
      subscriptions[0] && onSubscriptionOptionChange(subscriptions[0].id)
      return subscriptions[0]
    }
  }, [
    selectedSubscriptionId,
    subscriptions,
    isSinglePurchase,
    onSubscriptionOptionChange,
  ])
  const shipmentMessage = useMemo(() => {
    return isNextOrderDateIsDifferent
      ? t('header.settings.addItem.otherDate')
      : t('header.settings.addItem.shipmentMessage')
  }, [isNextOrderDateIsDifferent, t])

  const autoSaveSelected = useMemo(() => {
    return isSubscriptionSelected ? 'border bg-primary-900 bg-opacity-10' : ''
  }, [isSubscriptionSelected])
  const singleShipSelected = useMemo(() => {
    return !isSubscriptionSelected ? 'border bg-primary-900 bg-opacity-10' : ''
  }, [isSubscriptionSelected])

  return (
    <div className={`${loadingClassName} flex-col justify-between`}>
      <div className="flex w-full max-lg:flex-col">
        <div className={`${autoSaveSelected} w-full px-4 py-5`}>
          <Radio
            value={''}
            checked={isSubscriptionSelected}
            onChange={() => {
              setIsSinglePurchase(false)
              onSubscriptionOpen()
            }}
          >
            <div className="flex flex-col gap-1 ">
              <span className="text-sm font-bold leading-none text-black">
                <span className="flex items-center gap-1">
                  {t('header.settings.addItem.subscribe')}
                  <span className="text-xs font-normal text-gray-400 lg:hidden">
                    <Tooltip slot={<AutosaveTooltip />} place="bottom">
                      <Icon name="InformationCircleFill" />
                    </Tooltip>
                  </span>
                  <span className="hidden text-xs font-normal text-gray-400 lg:block">
                    <Tooltip slot={<AutosaveTooltip />}>
                      <Icon name="InformationCircleFill" />
                    </Tooltip>
                  </span>
                </span>
              </span>
            </div>
          </Radio>
        </div>
        <div
          className={`${singleShipSelected} w-full px-4 py-5 font-bold text-black`}
        >
          <Radio
            value={''}
            checked={!isSubscriptionSelected}
            onChange={() => {
              setIsSinglePurchase(true)
              onSubscriptionClose()
            }}
          >
            <div>{t('header.settings.addItem.oneTime')}</div>
          </Radio>
        </div>
      </div>
      {isSubscriptionSelected && (
        <div className="flex w-full max-lg:flex-col">
          <div className="flex">
            <div className="mt-3 w-full lg:w-[236px]">
              <Dropdown
                hideHelperBlock
                size="medium"
                title={
                  selectedSubscription?.name ||
                  t('cart.productList.selectAutoShipOption')
                }
              >
                {subscriptions?.map((option) => {
                  return (
                    <div
                      key={option.id}
                      className="cursor-pointer p-2 text-sm font-normal text-gray-900 hover:bg-gray-50"
                      onClick={() => {
                        onSubscriptionOptionChange(option.id)
                      }}
                    >
                      {option.name}
                    </div>
                  )
                })}
              </Dropdown>
            </div>
          </div>
          <div className="mt-3 min-h-[60px] w-full bg-gray-50 py-3 lg:ml-5 lg:px-4">
            <div className="text-sm font-normal text-gray-500">
              {shipmentMessage}
            </div>
            <NextOrderDate isListView itemId={itemId} />
          </div>
        </div>
      )}
    </div>
  )
}
