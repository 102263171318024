import { observer } from '@nori/di'
import { useTranslations } from '@nori/lang-i18n'
import { Table, TableBody, TableHeader, TableTh } from '@nori/ui-kit'
import { useAutosaveContext } from '~/modules/autosave/interface/use-autosave-context'

import { BundleRow } from './bundle-row'
import { StyleRow } from './style-row'

export const AddItemTableDesktop = observer(() => {
  const t = useTranslations('autosaveSettings.addItemModal')

  const { nextOrderAddItemStore } = useAutosaveContext()

  return (
    <Table widthType="full">
      <TableHeader>
        <TableTh percentageWidth={50}>{t('item')}</TableTh>
        <TableTh percentageWidth={20}>{t('subscription')}</TableTh>
        <TableTh percentageWidth={30}>{t('oneTime')}</TableTh>
      </TableHeader>
      <TableBody>
        {nextOrderAddItemStore.products?.items?.map((item) => {
          switch (item.typeCode) {
            case 'style':
              return <StyleRow key={item.id} item={item} />
            case 'bundle':
              return <BundleRow key={item.id} item={item} />
            default:
              return null
          }
        })}
      </TableBody>
    </Table>
  )
})
