import { hookContextFactory } from '@nori/di'

import { CalculateTaxAction } from '../actions/calculate-tax.action'
import { CartAction } from '../actions/cart.action'
import { CartStore } from '../store/cart-store/cart.store'

export const { useModuleContext: useCartContext } = hookContextFactory({
  cartAction: CartAction,
  cartStore: CartStore,
  calculateTaxAction: CalculateTaxAction,
})
