import type { FailedValidationValueObject } from '../../core/failed-validation'

export class ValidationMapper {
  static toValidationErrors(data: any): FailedValidationValueObject {
    return {
      firstName: data.errors['shipping_address.first_name']?.[0],
      lastName: data.errors['shipping_address.last_name']?.[0],
      phone: data.errors['phone']?.[0],
      address1: data.errors['shipping_address.address1']?.[0],
      address2: data.errors['shipping_address.address2']?.[0],
      city: data.errors['shipping_address.city']?.[0],
      country: data.errors['shipping_address.country']?.[0],
      state: data.errors['shipping_address.state']?.[0],
      zipCode: data.errors['shipping_address.zip_code']?.[0],
    }
  }
}
