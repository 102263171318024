import { BundleDetailsStore } from '@nori/app-kit'
import { Action, Inject } from '@nori/di'
import { AddToCartService } from '~/modules/cart/interface/services/add-to-cart.service'

@Action()
export class BundleDetailsAction {
  constructor(
    @Inject()
    private readonly bundleDetailsStore: BundleDetailsStore,
    @Inject() private readonly addToCartService: AddToCartService
  ) {}

  async handleAddToCart({ quantity }: { quantity: number }): Promise<void> {
    const product = this.bundleDetailsStore.product
    const statusId = this.bundleDetailsStore.statusId
    if (!product || !statusId) return

    const skuIds = this.addToCartService.getBundleSkuIds(product.components)
    if (!skuIds.length) return

    await this.addToCartService.handleAddBundleToCart({
      id: product.id,
      code: product.code,
      skuIds,
      quantity,
      statusId,
    })
  }
}
