import { AddressBlockWithUserNamesAndPhone } from '@nori/app-kit'
import { observer } from '@nori/di'
import { useTranslations } from '@nori/lang-i18n'
import { Radio } from '@nori/ui-kit'
import { useBillingFormContext } from '~/modules/billing-form/interface/use-billing-form.context'
import { useCartContext } from '~/modules/cart'

export const SecondBillingAddress = observer(() => {
  const t = useTranslations('billingForm.billingAddress')
  const { billingFormAction, billingFormStore } = useBillingFormContext()
  const { cartStore } = useCartContext()

  const shouldDisplayInputs =
    billingFormStore.secondBillingAddressVariant === 'second'

  if (!cartStore.cart) return <div />
  return (
    <>
      <div className="mb-7 mt-2 text-xl font-normal text-primary-900">
        {t('billingAddress')}
      </div>
      <div className="mb-6 flex flex-col gap-7">
        <Radio
          checked={billingFormStore.secondBillingAddressVariant === 'shipping'}
          value="shipping"
          key="shipping"
          onChange={() =>
            billingFormAction.handleSetSecondBillingAddressVariant('shipping')
          }
        >
          {t('sameAsShipping')}
        </Radio>
        <Radio
          checked={billingFormStore.secondBillingAddressVariant === 'main'}
          value="main"
          key="main"
          onChange={() =>
            billingFormAction.handleSetSecondBillingAddressVariant('main')
          }
        >
          {t('sameAsMainCreditCard')}
        </Radio>
        <Radio
          checked={billingFormStore.secondBillingAddressVariant === 'second'}
          value="second"
          key="second"
          onChange={() =>
            billingFormAction.handleSetSecondBillingAddressVariant('second')
          }
        >
          {t('enterANewAddress')}
        </Radio>
      </div>
      {shouldDisplayInputs && (
        <AddressBlockWithUserNamesAndPhone
          validator={billingFormStore.billingAddressValidatorMap.second}
        />
      )}
    </>
  )
})
