import { useMemo } from 'react'

import { observer } from '@nori/di'
import { useTranslations } from '@nori/lang-i18n'
import { Input } from '@nori/ui-kit'
import { useShopForMyselfContext } from '~/modules/shop-for-myself/interface/use-shop-for-myself.context'

export const SearchInput = observer(() => {
  const t = useTranslations('shopForMyself.search')

  const { searchProductsAction, searchProductsStore } =
    useShopForMyselfContext()

  const helperText = useMemo(() => {
    if (searchProductsStore.isLoading) {
      return null
    }
    if (!searchProductsStore.isDataLoaded) {
      return t('helperText')
    }
    if (searchProductsStore.isDataEmpty) {
      return (
        <span>
          {t('noMatches')}{' '}
          <span className="font-bold">
            &quot;{searchProductsStore.appliedSearchQuery}&quot;
          </span>
        </span>
      )
    }

    return null
  }, [
    searchProductsStore.isDataEmpty,
    searchProductsStore.isDataLoaded,
    searchProductsStore.isLoading,
    searchProductsStore.appliedSearchQuery,
    t,
  ])

  return (
    <div>
      <Input
        value={searchProductsStore.searchQuery}
        onChange={searchProductsAction.handleSearchQueryChange}
        size="default"
        label={t('placeholder')}
        placeholder={t('placeholder')}
        type="search"
        hideHelperBlock
        selectOnFocus
        data-test-id={'search-products-input'}
      />
      {helperText && (
        <div className="mt-6 text-center text-gray-500 lg:mt-8">
          {helperText}
        </div>
      )}
    </div>
  )
})
