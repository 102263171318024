import {
  CartService,
  getBaseUrl,
  RequestService,
  SERVICE_NAMES,
} from '@nori/app-kit'
import { Adapter, Inject } from '@nori/di'
import { resultErr, resultOk } from '@nori/result'

import {
  type ProductSubscriptionErrorInstance,
  ProductSubscriptionErrors,
} from '../core/prdouct-subscription.errors'

import { CartSubscriptionMapper } from './mappers/cart-subscription.mapper'

import type { CartEntity } from '@nori/app-kit'
import type { PromiseResult } from '@nori/result'
import type { UpdateBundleSubscriptionInput } from './types/update-bundle-cart-subscription.input'
import type { UpdateSubscriptionInput } from './types/update-cart-subscription.input'

@Adapter()
export class CartSubscriptionAdapter {
  baseUrl = getBaseUrl(SERVICE_NAMES.CART_SERVICE)

  public constructor(
    @Inject() private readonly requestService: RequestService,
    @Inject() private readonly cartService: CartService,
    @Inject() private readonly cartSubscriptionMapper: CartSubscriptionMapper
  ) {}

  async updateSubscription(
    input: UpdateSubscriptionInput
  ): PromiseResult<CartEntity, ProductSubscriptionErrorInstance> {
    const url = `${this.baseUrl}/carts/${input.cartId}/update_sku_subscription`

    try {
      const response = await this.requestService.secured.patch<any>(url, {
        body: this.cartSubscriptionMapper.toRequestBody(input),
      })

      if (response.ok) {
        return resultOk(this.cartService.toCartEntity(response.json))
      }

      return resultErr(
        new ProductSubscriptionErrors.UpdateSubscriptionUnknownError(
          response.status,
          response.json
        )
      )
    } catch (err: unknown) {
      return resultErr(
        new ProductSubscriptionErrors.UpdateSubscriptionUnexpectedError(err)
      )
    }
  }

  async updateBundleSubscription(
    input: UpdateBundleSubscriptionInput
  ): PromiseResult<CartEntity, ProductSubscriptionErrorInstance> {
    const url = `${this.baseUrl}/carts/${input.cartId}/update_bundle_subscription`

    try {
      const response = await this.requestService.secured.patch<any>(url, {
        body: this.cartSubscriptionMapper.toBundleRequestBody(input),
      })

      if (response.ok) {
        return resultOk(this.cartService.toCartEntity(response.json))
      }

      return resultErr(
        new ProductSubscriptionErrors.UpdateSubscriptionUnknownError(
          response.status,
          response.json
        )
      )
    } catch (err: unknown) {
      return resultErr(
        new ProductSubscriptionErrors.UpdateSubscriptionUnexpectedError(err)
      )
    }
  }
}
