import { isWithinInterval } from 'date-fns';
import { NoriDate } from '@nori/date';
export class DayHandler {
    get firstDate() {
        var _this_props_value;
        return (_this_props_value = this.props.value) == null ? void 0 : _this_props_value[0];
    }
    get lastDate() {
        var _this_props_value;
        if (!((_this_props_value = this.props.value) == null ? void 0 : _this_props_value.length)) return undefined;
        return this.props.value[this.props.value.length - 1];
    }
    get isInCurrentMonth() {
        return this.props.viewMonth.formatInTZ('MM') === this.props.currentDate.formatInTZ('MM');
    }
    get isSelected() {
        var _this_props_value;
        return !!((_this_props_value = this.props.value) == null ? void 0 : _this_props_value.some((val)=>this.props.currentDate.isSameDay(val)));
    }
    get isFirstDate() {
        return !!(this.isSelected && this.firstDate && this.props.currentDate.isSameDay(this.firstDate));
    }
    get isLastDate() {
        return !!(this.isSelected && this.lastDate && this.props.currentDate.isSameDay(this.lastDate));
    }
    get isExceedsMaxDate() {
        if (!this.props.maxDate) return false;
        const isSameDayAsMax = this.props.currentDate.isSameDay(this.props.maxDate);
        if (isSameDayAsMax) return false;
        return this.props.maxDate.getTime() < this.props.currentDate.getTime();
    }
    get isExceedsMinDate() {
        if (!this.props.minDate) return false;
        const isSameDayAsMin = this.props.currentDate.isSameDay(this.props.minDate);
        if (isSameDayAsMin) return false;
        return this.props.minDate.getTime() > this.props.currentDate.getTime();
    }
    get isDisabled() {
        return this.isExceedsMinDate || this.isExceedsMaxDate;
    }
    get isToday() {
        return this.props.currentDate.isSameDay(new NoriDate());
    }
    get isInInterval() {
        if (!this.firstDate) return false;
        if (!this.lastDate) return false;
        return isWithinInterval(this.props.currentDate, {
            start: this.firstDate,
            end: this.lastDate
        });
    }
    constructor(props){
        this.props = props;
    }
}
