import {
  ProductListCell,
  ProductListCellContainer,
  StylePrices,
} from '@nori/app-kit'

import type { CommonStyleEntity } from '@nori/app-kit'

type Props = {
  item: CommonStyleEntity
  onClick: (item: CommonStyleEntity) => void
}

export function StyleCell({ item, onClick }: Props) {
  const { maxPriceSku } = item

  return (
    <ProductListCellContainer>
      <ProductListCell item={item} onClick={() => onClick(item)} />
      <StylePrices
        prices={{
          prv: maxPriceSku?.currentPrv,
          retailPrice: maxPriceSku?.currentRetailPrice,
          salePrice: maxPriceSku?.currentSalePrice,
          stylistPrice: maxPriceSku?.currentStylistPrice,
        }}
      />
    </ProductListCellContainer>
  )
}
