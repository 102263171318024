import { observer } from '@nori/di'
import { useTranslations } from '@nori/lang-i18n'
import { ConfirmationModal, Link, Tooltip, useToggle } from '@nori/ui-kit'
import { useContactContext } from '~/modules/contacts/interface/use-contact-context'

export const DeleteContactButton = observer(() => {
  const t = useTranslations('contacts')
  const { editContactAction, editContactStore } = useContactContext()
  const { handleOpen, handleClose, isOpen } = useToggle()

  if (editContactStore.isAvailableToDelete) {
    return (
      <>
        <Link size="large" onClick={handleOpen}>
          {t('edit.delete')}
        </Link>
        <ConfirmationModal
          title={t('deleteConfirmation.title')}
          text={t('deleteConfirmation.text')}
          isOpen={isOpen}
          onClose={handleClose}
          onSubmit={() => {
            editContactAction.handleDeleteContact()
            handleClose()
          }}
        />
      </>
    )
  }

  return (
    <div className="self-center">
      <Tooltip
        place="top"
        slot={<div className="min-w-32">{t('edit.deleteTooltip')}</div>}
      >
        <Link isDisabled={true} size="large" onClick={handleOpen}>
          {t('edit.delete')}
        </Link>
      </Tooltip>
    </div>
  )
})
