import { AddressBlockWithUserNamesAndPhone } from '@nori/app-kit'
import { useTranslations } from '@nori/lang-i18n'
import { Radio } from '@nori/ui-kit'

import type { ValidatorAddressWithUserNamesAndPhoneType } from '@nori/app-kit'

type Props = {
  onToggleShipping: (value: boolean) => void
  isSameAsShipping: boolean
  validator: ValidatorAddressWithUserNamesAndPhoneType
}

export function BillingAddress({
  onToggleShipping,
  isSameAsShipping,
  validator,
}: Props) {
  const t = useTranslations('billingForm.billingAddress')

  const shouldDisplayInputs = !isSameAsShipping
  return (
    <>
      <div className="mb-7 mt-2 text-xl font-normal text-primary-900">
        {t('billingAddress')}
      </div>
      <div className="mb-6 flex flex-col gap-7">
        <Radio
          checked={isSameAsShipping}
          value="shipping"
          key="shipping"
          onChange={() => onToggleShipping(true)}
        >
          {t('sameAsShipping')}
        </Radio>
        <Radio
          checked={!isSameAsShipping}
          value="new"
          key="new"
          onChange={() => onToggleShipping(false)}
        >
          {t('enterANewAddress')}
        </Radio>
      </div>
      {shouldDisplayInputs && (
        <AddressBlockWithUserNamesAndPhone validator={validator} />
      )}
    </>
  )
}
