import { observer } from '@nori/di'
import { Modal } from '@nori/ui-kit'

import { useContactManageMailingContext } from '../../interface/use-contact-manage-mailing-context'

import { ContactManageMailingModalContent } from './contact-manage-mailing-modal-content'

export const ContactManageMailingModal = observer(() => {
  const { сontactManageMailingStore, contactManageMailingAction } =
    useContactManageMailingContext()

  if (!сontactManageMailingStore.contactManageMailingModal.isOpen) return null

  return (
    <Modal
      size="6xl"
      isOpen={сontactManageMailingStore.contactManageMailingModal.isOpen}
      onClose={contactManageMailingAction.handleToggleContactManageMailingModal}
      isOverflowHidden
      withScroll={false}
    >
      <ContactManageMailingModalContent />
    </Modal>
  )
})
