import { Inject, Service } from '@nori/di'

import { PartyMapper } from './party.mapper'

import type { PartiesListValueObject } from '../../core/value-objects/parties-list.value-object'
import type { GetPartyListInput } from '../types/get-party-list/get-party-list.input'
import type { GetPartyListRequest } from '../types/get-party-list/get-party-list.request'

@Service()
export class PartiesListMapper {
  constructor(@Inject() private readonly partyMapper: PartyMapper) {}

  toPartyListRequest(input: GetPartyListInput): GetPartyListRequest {
    return {
      page: input.page,
      per_page: input.perPage,
      to: input.to,
      from: input.from,
      _: Date.now().toString(),
      search_param: input.searchParam || undefined,
      sort_by: input.sortBy || undefined,
      order: input.ordering || undefined,
      with_accurate_time: input.isWithAccurateTime || undefined,
      search_filter: input.searchFilter || undefined,
    }
  }

  toPartiesListValueObject(data: any): PartiesListValueObject {
    const parties = data.objects?.map((party: any) => {
      return this.partyMapper.toPartyEntity(party)
    })

    const pagination = {
      page: data.pagination.current_page,
      perPage: data.pagination.per_page,
      totalCount: data.pagination.total_count,
      totalPages: data.pagination.total_pages,
    }

    return { parties, pagination }
  }
}
