import { errorFactory } from '@nori/errors'

import type { KeyOfErrors } from '@nori/errors'

export const { PartiesListErrors } = errorFactory('PartiesListErrors', {
  BadRequest: 'Bad Request',
  UnexpectedError: 'Unexpected Error',
  UnknownError: 'Unknown Error',
})

export type PartiesListErrorInstance = InstanceType<
  KeyOfErrors<typeof PartiesListErrors>
>
