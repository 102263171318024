import { DeleteNoteErrors } from '../../core/delete-note.errors'

import type { DeleteNoteErrorInstance } from '../../core/delete-note.errors'

export class DeleteNoteErrorsMapper {
  static toErrors(json: any, status: number): DeleteNoteErrorInstance {
    switch (status) {
      case 400:
        return new DeleteNoteErrors.BadRequest(json.errors.message)

      default:
        return new DeleteNoteErrors.UnknownError(json)
    }
  }
}
