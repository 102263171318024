import { observer } from '@nori/di'
import { useTranslations } from '@nori/lang-i18n'
import { InfiniteScroll, Loader, Modal, Swap, useMount } from '@nori/ui-kit'

import { useContactManageMailingContext } from '../../interface/use-contact-manage-mailing-context'

import { ContactManageMailingModalFooter } from './contact-manage-mailing-modal-footer'
import { ContactManageMailingModalHeaderControls } from './contact-manage-mailing-modal-header-controls'
import { ContactManageMailingModalTable } from './contact-manage-mailing-modal-table'

export const ContactManageMailingModalContent = observer(() => {
  const t = useTranslations('contacts.manageMailingModal')
  const { сontactManageMailingStore, contactManageMailingAction } =
    useContactManageMailingContext()

  useMount(() => {
    contactManageMailingAction.handleLoadContactsManageMailingData()

    return () => {
      contactManageMailingAction.handleResetContactsManageMailingData()
    }
  })

  return (
    <div className="flex flex-col overflow-hidden">
      <Modal.Header
        title={t('title')}
        headerType="only-close-button"
        onClose={
          contactManageMailingAction.handleToggleContactManageMailingModal
        }
      />
      <ContactManageMailingModalHeaderControls />
      <div
        id="contact-manage-mailing-modal"
        className="my-6 flex min-h-16 flex-col justify-between overflow-y-auto"
      >
        <Swap
          is={сontactManageMailingStore.isLoading}
          isSlot={
            <div className="mt-4">
              <Loader />
            </div>
          }
        >
          <div className="mt-5">
            <InfiniteScroll
              scrollThreshold={0.5}
              hasMore={!сontactManageMailingStore.isLastPage}
              dataLength={сontactManageMailingStore.contacts?.length ?? 0}
              next={contactManageMailingAction.handleLoadNextPage}
              loader={
                сontactManageMailingStore.isLoadingNextPage && (
                  <div className="p-4">
                    <Loader />
                  </div>
                )
              }
              scrollableTarget="contact-manage-mailing-modal"
            >
              <ContactManageMailingModalTable />
            </InfiniteScroll>
          </div>
        </Swap>
      </div>
      <ContactManageMailingModalFooter />
    </div>
  )
})
