import { useEffect } from 'react'

import { observer } from '@nori/di'
import { useToggle } from '@nori/ui-kit'

import { useProductSubscriptionContext } from '../interface/product-subscription.context'

import { ProductSubscription } from './product-subscription'

import type { CartItemEntity } from '@nori/app-kit'

type Props = {
  product: CartItemEntity
}

export const CartProductSubscription = observer(({ product }: Props) => {
  const { productSubscriptionAction, productSubscriptionStore } =
    useProductSubscriptionContext()

  const subscriptionData = productSubscriptionStore.getProductSubscriptions({
    productId: product.productId,
  })
  const defaultSubscriptionId =
    subscriptionData?.defaultSubscription &&
    subscriptionData?.defaultSubscription.id > 0
      ? subscriptionData?.defaultSubscription.id
      : undefined
  const selectedSubscriptionId = product.autoShip.id || defaultSubscriptionId
  const isSubscriptionSelected = !!product.autoShip.id
  const subscriptionOptions = subscriptionData?.subscriptions
  const isLoading =
    productSubscriptionStore.productSubscriptionLoadingMap[product.productId]
  const subscriptionToggle = useToggle()
  useEffect(() => {
    if (isSubscriptionSelected) {
      subscriptionToggle.handleClose()
    }
  }, [isSubscriptionSelected])

  return (
    <ProductSubscription
      isLoading={isLoading}
      subscriptions={subscriptionOptions}
      selectedSubscriptionId={selectedSubscriptionId}
      isSubscriptionSelected={
        isSubscriptionSelected || subscriptionToggle.isOpen
      }
      onSubscriptionOptionChange={(id: number) =>
        productSubscriptionAction.handleUpdateProductSubscription({
          skuId: product.skuId,
          subscriptionPlan: id,
          oldSubscriptionPlan: product.autoShip.id,
          productId: product.productId,
        })
      }
      onSubscriptionClose={() =>
        productSubscriptionAction.handleSubscriptionToggle({
          isToggle: false,
          isSubscriptionSelected,
          oldSubscriptionPlan: product.autoShip.id,
          productId: product.productId,
          skuId: product.skuId,
          subscriptionToggle: subscriptionToggle,
        })
      }
      onSubscriptionOpen={() =>
        productSubscriptionAction.handleProductSubscriptionOpen({
          skuId: product.skuId,
          subscriptionPlan: selectedSubscriptionId,
          oldSubscriptionPlan: product.autoShip.id,
          productId: product.productId,
          isSubscriptionSelected: !!selectedSubscriptionId,
          subscriptionToggle: subscriptionToggle,
        })
      }
    />
  )
})
