import { HistoryService } from '@nori/app-kit'
import { Action, Inject } from '@nori/di'
import { sleep } from '@nori/ui-kit'
import { AddToCartService } from '~/modules/cart/interface/services/add-to-cart.service'
import { ShopForMyselfService } from '~/modules/shop-for-myself/interface/service/shop-for-myself.service'
import { SearchProductsStore } from '~/modules/shop-for-myself/interface/store/search-products.store'

import type { CommonBundleEntity } from '@nori/app-kit'

const DEBOUNCE_MS = 500

@Action()
export class SearchProductsAction {
  constructor(
    @Inject() private readonly searchProductsStore: SearchProductsStore,
    @Inject() private readonly addToCartService: AddToCartService,
    @Inject() private readonly shopForMyselfService: ShopForMyselfService,
    @Inject() private readonly historyService: HistoryService
  ) {}

  async handleAddBundleToCart({
    item,
    quantity,
    subscriptionPlanId,
  }: {
    item: CommonBundleEntity
    quantity: number
    subscriptionPlanId?: number
  }): Promise<void> {
    const skuIds = this.addToCartService.getBundleSkuIds(item.components)
    if (!skuIds.length) return

    await this.addToCartService.handleAddBundleToCart({
      id: item.id,
      quantity,
      code: item.code,
      skuIds,
      statusId: item.code,
      subscriptionPlanId,
    })
  }

  async handleAddStyleToCart({
    skuId,
    quantity,
    subscriptionPlanId,
  }: {
    skuId: number
    quantity: number
    subscriptionPlanId?: number
  }): Promise<void> {
    await this.addToCartService.handleAddStyleToCart({
      quantity,
      skuId,
      statusId: skuId.toString(),
      subscriptionPlanId,
    })
  }

  async handleLoadNextItems(): Promise<void> {
    this.searchProductsStore.setError(undefined)
    this.searchProductsStore.setIsLoadingNextPage(true)

    const page = this.searchProductsStore.pagination.page + 1
    await this.shopForMyselfService.loadProducts(page)

    this.searchProductsStore.setIsLoadingNextPage(false)
  }

  handleDropSearchQuery(): void {
    this.searchProductsStore.setSearchQuery('')
    this.searchProductsStore.setAppliedSearchQuery('')
  }

  handleRemoveQuery(): void {
    this.historyService.removeSearchParams('search')
  }

  async handleSearchQueryChange(query: string): Promise<void> {
    this.searchProductsStore.setSearchQuery(query)

    await sleep(DEBOUNCE_MS)

    if (query !== this.searchProductsStore.searchQuery) return
    if (query === this.searchProductsStore.appliedSearchQuery) return

    await this.shopForMyselfService.handleSearchQueryChange(query)
  }
}
