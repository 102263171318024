export const LIVE_CHAT_SCRIPT_SRC =
  'https://home-c33.nice-incontact.com/inContact/ChatClient/js/embed.min.js'
export const LIVE_CHAT_CONFIGS = {
  US: {
    url: 'https://home-c33.nice-incontact.com',
    id: 4598218,
    systemGroup: '627ea5c4-0125-4cfc-8e4e-85e1490c9de7',
    systemGroupAll: 'd758721f-e278-4826-9fc4-dada17a53327',
  },
  CA: {
    url: 'https://home-c33.nice-incontact.com',
    id: 4598218,
    systemGroup: '090d91c7-a6a6-4dcb-b771-aa6d755ba125',
    systemGroupAll: 'ae2ff549-31e4-4b4d-b18b-0af9f997b5af',
  },
}

export const systemGroupRanks = [
  'SESL',
  'VPSL',
  'EVPSL',
  'SVPSL',
  'ESVP',
  'SSVP',
]
