import { DataState, Inject, Store } from '@nori/di'
import { CartStore } from '~/modules/cart/interface/store/cart-store/cart.store'
import { CartUserCreditsStore } from '~/modules/user-credits/interface/store/cart-user-credits.store'

import type { MoneyValueObject } from '@nori/app-kit'
import type { DomainError } from '@nori/errors'
import type { ApplyCreditsStoreInterface } from './apply-credits.interface'

@Store()
export class ApplyHostessCreditsStore implements ApplyCreditsStoreInterface {
  constructor(
    @Inject()
    private readonly cartStore: CartStore,
    @Inject()
    private readonly cartUserCreditsStore: CartUserCreditsStore
  ) {}

  updatedCredits = new DataState<null, DomainError>({
    isLoading: false,
  })

  creditInputValue = ''

  get applicableCartCredits(): MoneyValueObject | undefined {
    const creditLimits = this.cartStore.cart?.creditLimits
    if (!creditLimits) return

    const hostessCredits = this.creditAmount
    const maxHostessCreditLimits = creditLimits.hostess_credits.max

    if (hostessCredits && maxHostessCreditLimits)
      return hostessCredits.cents < maxHostessCreditLimits.cents
        ? hostessCredits
        : maxHostessCreditLimits
  }

  get hasApplicableCredits(): boolean {
    return !!(
      this.applicableCartCredits?.cents && this.applicableCartCredits?.cents > 0
    )
  }

  get isShowApplyCredits(): boolean {
    if (this.cartStore.isConsultantCustomerCart) {
      return false
    }
    const combinedCredits =
      this.cartUserCreditsStore.cartUserCredits.data?.combinedCredits

    return !!(
      this.hasApplicableCredits &&
      combinedCredits &&
      combinedCredits > 0
    )
  }

  get isShowConfirmationModal(): boolean {
    if (this.cartStore.isConsultantCustomerCart) {
      return false
    }
    const hostessCredits =
      this.cartUserCreditsStore.cartUserCredits.data?.hostessCredits
    const hasApplicableHostessCredits = !!hostessCredits && hostessCredits > 0

    const isAppliedCreditsFullyApplied =
      this.appliedCredits?.cents === this.creditAmount?.cents ||
      this.appliedCredits?.cents === this.applicableCartCredits?.cents

    return (
      hasApplicableHostessCredits &&
      this.hasApplicableCredits &&
      !isAppliedCreditsFullyApplied
    )
  }

  get creditsToApply(): MoneyValueObject | undefined {
    if (this.applicableCartCredits && this.creditAmount)
      return this.applicableCartCredits.cents > this.creditAmount.cents
        ? this.creditAmount
        : this.applicableCartCredits
  }

  get creditAmount(): MoneyValueObject {
    return {
      cents:
        this.cartUserCreditsStore.cartUserCredits.data?.combinedCredits || 0,
      currency:
        this.cartUserCreditsStore.cartUserCredits.data?.currency || 'USD',
    }
  }

  get creditError(): DomainError | undefined {
    return this.updatedCredits.error
  }

  get isLoading(): boolean {
    return this.updatedCredits.isLoading
  }

  get appliedCredits(): MoneyValueObject | undefined {
    return this.cartStore.cart?.combinedCreditsTotal
  }

  setValue(value: string): void {
    this.creditInputValue = value
  }
}
