import { AddToCartErrors } from '~/modules/cart/core'

import type { AddToCartErrorInstance } from '~/modules/cart/core'

export class AddToCartErrorMapper {
  static toErrors(status: number, json: any): AddToCartErrorInstance {
    switch (status) {
      case 400:
        return new AddToCartErrors.BadRequest(json.error.message)
      case 404:
        return new AddToCartErrors.NotFound(json.error.message)
      case 422:
        return this.getUnprocessableEntityError(json)

      default:
        return new AddToCartErrors.UnknownError(json)
    }
  }

  static getUnprocessableEntityError(json: any): AddToCartErrorInstance {
    const error: string = (
      json?.errors?.base?.[0] ||
      json?.errors?.quantity?.[0] ||
      ''
    )
      .trim()
      .toLowerCase()

    if (!error) {
      return new AddToCartErrors.UnproceccedEntity(json.errors)
    }

    if (error.includes('max quantity allowed')) {
      return new AddToCartErrors.MaximumQuantityError(error)
    } else if (json.fields && json.fields[0] === 'quantity') {
      return new AddToCartErrors.NotAvailableError(json?.errors?.quantity)
    } else if (error.includes('cart size limit reached')) {
      return new AddToCartErrors.CartLimitError(error)
    }

    return new AddToCartErrors.UnproceccedEntity(json.errors)
  }
}
