import { useMemo } from 'react'

import { StoreEnum, useI18nContext, useProfileContext } from '@nori/app-kit'
import { observer } from '@nori/di'
import { useClickOutside } from '@nori/ui-kit'
import { useHeaderContext } from '~/modules/header/interface/use-header-context'
import { HelpLinks } from '~/modules/help-links/help-links'

import { MOBILE_LINKS } from '../../header-links/header-links'
import { HELP_LINKS } from '../../header-links/help-links'
import { getResourceLinks } from '../../header-links/product-info-links'

import { MenuLinkItem } from './menu-link-item'
import { useLanguageLinks } from './use-language-links'

export const MobileHeaderMenu = observer(() => {
  const { headerStore, headerAction } = useHeaderContext()
  const { languageLinks } = useLanguageLinks()
  const { i18nStore } = useI18nContext()
  const { userStore } = useProfileContext()
  const { currentLocale } = i18nStore
  const { ref } = useClickOutside(headerAction.handleToggleMenu)
  const resourceLinks = useMemo(() => {
    return getResourceLinks(currentLocale)
  }, [currentLocale])

  return (
    <div
      ref={ref}
      className="absolute h-[calc(100vh-60px)] w-full overflow-auto bg-white"
    >
      {MOBILE_LINKS.map((item) => {
        return (
          <MenuLinkItem
            key={item.translationKey}
            link={item}
            nestedMenuVisibility={headerStore.nestedMenuVisibility}
            onToggleNestedMenu={headerAction.handleToggleNestedMenu}
            onCloseMenu={headerAction.handleToggleMenu}
          />
        )
      })}
      <div className="my-3 h-[1px] w-full bg-gray-400" />
      <MenuLinkItem
        key={resourceLinks.translationKey}
        link={resourceLinks}
        nestedMenuVisibility={headerStore.nestedMenuVisibility}
        onToggleNestedMenu={headerAction.handleToggleNestedMenu}
        onCloseMenu={headerAction.handleToggleMenu}
      />
      <MenuLinkItem
        key={HELP_LINKS.translationKey}
        link={HELP_LINKS}
        listSlot={<HelpLinks />}
        nestedMenuVisibility={headerStore.nestedMenuVisibility}
        onToggleNestedMenu={headerAction.handleToggleNestedMenu}
        onCloseMenu={headerAction.handleToggleMenu}
      />
      {userStore.store !== StoreEnum.US && (
        <MenuLinkItem
          key={languageLinks.translationKey}
          link={languageLinks}
          nestedMenuVisibility={headerStore.nestedMenuVisibility}
          onToggleNestedMenu={headerAction.handleToggleNestedMenu}
          onCloseMenu={headerAction.handleToggleMenu}
        />
      )}
    </div>
  )
})
