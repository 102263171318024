import { Service } from '@nori/di'

import type { CreateCartInput } from '../../core/create-cart.input'
import type { CreateCartRequest } from '../types/create-cart'

@Service()
export class GetCartMapper {
  toRequestInput(data?: Partial<CreateCartInput>): CreateCartRequest {
    return {
      isLoungeCart: true,
      promotions: [],
      type_code: this.toTypeCode(data),
      ...this.toPartyFields(data),
      ...this.toCustomerFields(data),
      ...this.toHostessFields(data),
    }
  }

  private toTypeCode(data?: Partial<CreateCartInput>) {
    if (data?.typeCode) {
      return data.typeCode
    }

    if (data?.partyId) {
      return 'dsr_customer_party'
    }

    if (data?.customerId) {
      return 'dsr_customer'
    }

    return 'dsr'
  }

  private toPartyFields(
    data?: Partial<CreateCartInput>
  ): Pick<CreateCartRequest, 'party_at' | 'party_id' | 'party_name'> {
    if (!data) return {}

    return {
      party_at: data.partyAt,
      party_id: data.partyId,
      party_name: data.partyName,
    }
  }

  private toCustomerFields(
    data?: Partial<CreateCartInput>
  ): Pick<
    CreateCartRequest,
    | 'contact_id'
    | 'customer_id'
    | 'customer_email'
    | 'customer_first_name'
    | 'customer_last_name'
    | 'customer_email_provided'
  > {
    if (!data) return {}

    return {
      contact_id: data.contactId,
      customer_id: data.customerId,
      customer_email: data.customerEmail,
      customer_first_name: data.customerFirstName,
      customer_last_name: data.customerLastName,
      customer_email_provided: data.customerEmailProvided,
    }
  }

  private toHostessFields(
    data?: Partial<CreateCartInput>
  ): Pick<CreateCartRequest, 'referrer_id'> {
    if (!data?.referrerId) return {}

    return {
      referrer_id: data.referrerId,
    }
  }
}
