import { observer } from '@nori/di'
import { useTranslations } from '@nori/lang-i18n'
import { Input } from '@nori/ui-kit'
import { useBillingFormContext } from '~/modules/billing-form/interface/use-billing-form.context'

export const SplitAmountFields = observer(() => {
  const t = useTranslations('billingForm.creditCard')
  const { billingFormSplitPaymentStore, billingFormSplitPaymentAction } =
    useBillingFormContext()

  return (
    <div className="flex gap-8 py-5">
      <Input
        type="money"
        data-test-id="main-credit-card-amount"
        label={t('mainCreditCard')}
        placeholder={t('mainCreditCard')}
        value={billingFormSplitPaymentStore.cardAmountMap.main}
        onChange={(value) =>
          billingFormSplitPaymentAction.handleAmountChange('main', value)
        }
        onBlur={() => billingFormSplitPaymentAction.handleAmountBlur('main')}
        hideHelperBlock
      />
      <Input
        type="money"
        data-test-id="secondary-credit-card-amount"
        label={t('secondaryCreditCard')}
        placeholder={t('secondaryCreditCard')}
        value={billingFormSplitPaymentStore.cardAmountMap.second}
        onChange={(value) =>
          billingFormSplitPaymentAction.handleAmountChange('second', value)
        }
        onBlur={() => billingFormSplitPaymentAction.handleAmountBlur('second')}
        hideHelperBlock
      />
    </div>
  )
})
