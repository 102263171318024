type Size = 'md' | 'lg'
type Props = {
  size?: Size
}

const sizeMap: Record<Size, { w: string; h: string }> = {
  md: { w: '166', h: '15' },
  lg: { w: '190', h: '16' },
}
export function LogoTitle({ size = 'md' }: Props) {
  const sizeNode = sizeMap[size]

  return (
    <svg
      width={sizeNode.w}
      height={sizeNode.h}
      viewBox="0 0 166 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M161.038 2.56201V2.92624H161.762V4.80125H162.172V2.92624H162.89V2.56201H161.038ZM165.185 2.56201L164.5 3.96827L163.834 2.56201H163.388L163.269 4.80125H163.698L163.763 3.20971L164.333 4.41406H164.683L165.247 3.22634L165.326 4.79967H165.754L165.63 2.56201H165.185Z"
        fill="currentColor"
      />
      <mask
        id="mask0_1798_135128"
        style={{ maskType: 'luminance' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="166"
        height="15"
      >
        <path d="M165.754 0.5H0.233887V14.5H165.754V0.5Z" fill="white" />
      </mask>
      <g mask="url(#mask0_1798_135128)">
        <path
          d="M3.5009 1.73817C3.41774 1.73526 3.33486 1.74927 3.25727 1.77935C3.17969 1.80943 3.10903 1.85495 3.04957 1.91316C2.99073 1.97491 2.94498 2.04791 2.91505 2.12778C2.88511 2.20765 2.87162 2.29274 2.87537 2.37795C2.87238 2.46202 2.88635 2.54583 2.91646 2.62439C2.94657 2.70294 2.9922 2.77462 3.05061 2.83515C3.10903 2.89568 3.17905 2.94383 3.25648 2.97671C3.33391 3.00959 3.41717 3.02654 3.50129 3.02654C3.58542 3.02654 3.66868 3.00959 3.74611 2.97671C3.82354 2.94383 3.89356 2.89568 3.95197 2.83515C4.01039 2.77462 4.05602 2.70294 4.08613 2.62439C4.11624 2.54583 4.13021 2.46202 4.12722 2.37795C4.13087 2.29269 4.11725 2.20756 4.08718 2.12769C4.05711 2.04782 4.01121 1.97485 3.95223 1.91316C3.89277 1.85495 3.82211 1.80943 3.74452 1.77935C3.66694 1.74927 3.58406 1.73526 3.5009 1.73817Z"
          fill="currentColor"
        />
        <path
          d="M3.0835 11.8981H3.40655C3.4777 11.8986 3.54824 11.885 3.61412 11.8582C3.68 11.8313 3.73991 11.7917 3.7904 11.7415C3.8409 11.6914 3.88097 11.6318 3.90832 11.5661C3.93566 11.5004 3.94974 11.43 3.94974 11.3588V5.05127H3.0835V11.8981Z"
          fill="currentColor"
        />
        <path
          d="M8.53199 10.9067L6.13914 5.05127H5.18896L8.0957 11.8981H8.92552L11.8172 5.05127H10.924L8.53199 10.9067Z"
          fill="currentColor"
        />
        <path
          d="M30.9633 10.7991C30.6507 10.9749 30.3159 11.1081 29.968 11.195C29.6338 11.2813 29.2902 11.326 28.945 11.328C28.5675 11.3449 28.1906 11.2844 27.8374 11.1503C27.4842 11.0162 27.1621 10.8113 26.891 10.5481C26.6288 10.269 26.4253 9.93989 26.2928 9.5805C26.1604 9.22111 26.1015 8.83875 26.1198 8.45615C26.1083 7.93887 26.2293 7.42724 26.4714 6.96992C26.6984 6.55605 27.034 6.21193 27.4421 5.97462C27.8743 5.72855 28.3646 5.60329 28.8618 5.61197C29.2045 5.61337 29.5458 5.65564 29.8785 5.73787C30.1947 5.81073 30.499 5.92831 30.782 6.08705L30.816 6.10606L30.9514 5.32929L30.934 5.31979C30.2962 4.98231 29.5834 4.81154 28.8618 4.82333C28.2076 4.81204 27.562 4.97345 26.99 5.29129C26.4503 5.59716 26.006 6.04666 25.7065 6.58985C25.3896 7.17686 25.23 7.83591 25.2433 8.50287C25.2295 9.16271 25.3866 9.8149 25.6993 10.3961C25.9974 10.9336 26.4431 11.3745 26.9837 11.6669C27.5749 11.9784 28.2357 12.1345 28.9038 12.1206C29.303 12.1184 29.7003 12.0652 30.086 11.9623C30.4414 11.8762 30.7804 11.7322 31.0892 11.5363L31.1042 11.5268L30.9989 10.7785L30.9633 10.7991Z"
          fill="currentColor"
        />
        <path
          d="M32.9387 11.8975H33.2618C33.3326 11.8975 33.4027 11.8835 33.4681 11.8564C33.5336 11.8293 33.593 11.7896 33.6431 11.7395C33.6931 11.6895 33.7329 11.63 33.76 11.5646C33.7871 11.4992 33.801 11.4291 33.801 11.3582V0.569824H32.9387V11.8975Z"
          fill="currentColor"
        />
        <path
          d="M0.233887 11.8975H0.556945C0.627757 11.8975 0.697875 11.8835 0.763297 11.8564C0.828718 11.8293 0.888162 11.7896 0.938233 11.7395C0.988305 11.6895 1.02802 11.63 1.05512 11.5646C1.08222 11.4992 1.09617 11.4291 1.09617 11.3582V0.569824H0.233887V11.8975Z"
          fill="currentColor"
        />
        <path
          d="M40.4214 5.23561C39.9409 4.95713 39.393 4.81606 38.8378 4.82783C38.2295 4.81833 37.631 4.98141 37.1116 5.29816C36.6041 5.615 36.192 6.0635 35.9192 6.59594C35.6221 7.18157 35.4728 7.83101 35.4845 8.48758C35.4689 9.15836 35.6257 9.82193 35.9397 10.4148C36.2376 10.9564 36.6895 11.3974 37.2383 11.6817C37.8557 11.988 38.5384 12.1393 39.2273 12.1228C39.6825 12.1226 40.136 12.0662 40.5774 11.9549C40.971 11.8612 41.3503 11.715 41.7049 11.5202L41.7208 11.5107L41.6004 10.7577L41.5663 10.7759C40.8551 11.1536 40.0611 11.3486 39.2558 11.3436C38.501 11.3806 37.7598 11.1322 37.1797 10.6476C36.9179 10.3997 36.7085 10.1017 36.5641 9.77132C36.4196 9.44093 36.343 9.08489 36.3388 8.72433H41.8316V8.69899C41.8411 8.58814 41.8482 8.48203 41.853 8.38227C41.8577 8.2825 41.8601 8.18194 41.8601 8.08138C41.873 7.48907 41.7429 6.90238 41.4808 6.37107C41.2412 5.89954 40.8735 5.50517 40.4198 5.23324M41.0248 8.02674H36.3372C36.3846 7.35807 36.6648 6.72713 37.129 6.24359C37.3468 6.02249 37.6073 5.84814 37.8947 5.73119C38.1821 5.61424 38.4903 5.55715 38.8006 5.56342C39.0856 5.55318 39.3699 5.6001 39.6366 5.70141C39.9032 5.80272 40.1469 5.95637 40.3533 6.15332C40.7702 6.58122 41.0066 7.15303 41.0137 7.7504C41.0216 7.83592 41.0263 7.92777 41.0271 8.02674"
          fill="currentColor"
        />
        <path
          d="M65.3611 5.23561C64.8805 4.95713 64.3327 4.81606 63.7774 4.82783C63.1692 4.81825 62.5706 4.98133 62.0513 5.29816C61.5438 5.61497 61.1317 6.06348 60.8588 6.59594C60.5617 7.18157 60.4125 7.83101 60.4241 8.48758C60.4087 9.15844 60.5658 9.82203 60.8802 10.4148C61.178 10.9564 61.63 11.3974 62.1788 11.6817C62.7962 11.988 63.4788 12.1393 64.1678 12.1228C64.6217 12.1223 65.0738 12.066 65.5139 11.9549C65.9075 11.8613 66.2868 11.7151 66.6414 11.5202L66.6573 11.5107L66.5369 10.7577L66.5029 10.7759C65.7916 11.1536 64.9976 11.3486 64.1923 11.3436C63.4375 11.3806 62.6963 11.1322 62.1162 10.6476C61.854 10.4003 61.644 10.1029 61.4989 9.77288C61.3538 9.44291 61.2764 9.08715 61.2714 8.7267H66.7737V8.70137C66.7824 8.59289 66.7895 8.48678 66.7942 8.38464C66.799 8.2825 66.8014 8.1859 66.8014 8.08375C66.8147 7.4915 66.6848 6.90479 66.4229 6.37344C66.1832 5.90194 65.8147 5.50758 65.3611 5.23561ZM65.9668 8.02912H61.2793C61.3263 7.36035 61.6065 6.72928 62.0711 6.24596C62.2888 6.02483 62.5493 5.85045 62.8367 5.7335C63.1241 5.61655 63.4324 5.55948 63.7426 5.5658C64.0277 5.55556 64.3119 5.60247 64.5786 5.70378C64.8453 5.80509 65.089 5.95875 65.2953 6.15569C65.712 6.58368 65.9482 7.1555 65.9549 7.75278C65.9636 7.83829 65.9684 7.93093 65.9692 8.02912"
          fill="currentColor"
        />
        <path
          d="M133 5.23561C132.519 4.95713 131.972 4.81606 131.416 4.82783C130.808 4.81825 130.21 4.98133 129.69 5.29816C129.183 5.61481 128.77 6.06336 128.498 6.59594C128.201 7.18157 128.051 7.83101 128.063 8.48758C128.048 9.15844 128.205 9.82203 128.519 10.4148C128.817 10.9565 129.269 11.3975 129.818 11.6817C130.435 11.988 131.118 12.1394 131.807 12.1228C132.261 12.1223 132.713 12.066 133.153 11.9549C133.547 11.8614 133.926 11.7152 134.28 11.5202L134.297 11.5107L134.177 10.7577L134.143 10.7759C133.431 11.1536 132.637 11.3487 131.832 11.3436C131.077 11.3806 130.336 11.1322 129.756 10.6476C129.494 10.4003 129.284 10.1029 129.139 9.77288C128.993 9.44291 128.916 9.08715 128.911 8.7267H134.404V8.70137C134.413 8.59289 134.42 8.48678 134.424 8.38464C134.429 8.2825 134.432 8.18986 134.432 8.08375C134.445 7.49153 134.315 6.90488 134.053 6.37344C133.813 5.90194 133.446 5.50758 132.992 5.23561M133.597 8.02912H128.909C128.957 7.36036 129.237 6.72932 129.701 6.24596C129.919 6.02491 130.18 5.85058 130.467 5.73363C130.754 5.61668 131.063 5.55958 131.373 5.5658C131.658 5.55556 131.942 5.60247 132.209 5.70378C132.475 5.80509 132.719 5.95875 132.926 6.15569C133.342 6.58372 133.578 7.15551 133.585 7.75278C133.594 7.83829 133.599 7.93093 133.599 8.02912"
          fill="currentColor"
        />
        <path
          d="M17.5073 5.23561C17.0268 4.95713 16.479 4.81606 15.9237 4.82783C15.3154 4.81825 14.7169 4.98133 14.1975 5.29816C13.69 5.61497 13.2779 6.06348 13.0051 6.59594C12.708 7.18157 12.5588 7.83101 12.5704 8.48758C12.555 9.15844 12.712 9.82203 13.0265 10.4148C13.3243 10.9564 13.7762 11.3974 14.325 11.6817C14.9424 11.988 15.6251 12.1394 16.314 12.1228C16.7679 12.1223 17.22 12.066 17.6601 11.9549C18.0538 11.8613 18.433 11.7151 18.7877 11.5202L18.8035 11.5107L18.6831 10.7577L18.6491 10.7759C17.9378 11.1536 17.1439 11.3486 16.3386 11.3436C15.5837 11.3806 14.8425 11.1322 14.2625 10.6476C14.0002 10.4003 13.7903 10.1029 13.6452 9.77288C13.5 9.44291 13.4227 9.08715 13.4176 8.7267H18.9104V8.70137C18.9199 8.59289 18.9262 8.48678 18.931 8.38464C18.9357 8.2825 18.9381 8.1859 18.9381 8.08375C18.9514 7.4915 18.8216 6.90479 18.5596 6.37344C18.32 5.90192 17.9523 5.50754 17.4986 5.23561M18.1035 8.02912H13.416C13.463 7.36035 13.7432 6.72928 14.2078 6.24596C14.4262 6.0239 14.6877 5.84892 14.9763 5.73181C15.2648 5.61469 15.5743 5.55792 15.8857 5.565C16.1708 5.55476 16.455 5.60168 16.7217 5.70299C16.9884 5.8043 17.232 5.95796 17.4384 6.1549C17.8551 6.58289 18.0913 7.1547 18.098 7.75198C18.1067 7.8375 18.1115 7.93014 18.1122 8.02833"
          fill="currentColor"
        />
        <path
          d="M49.6904 6.48268C49.4375 5.99718 49.0605 5.58736 48.5977 5.29497C48.1125 4.98885 47.5498 4.82811 46.9761 4.83176C46.3595 4.8247 45.7535 4.99274 45.2286 5.31634C44.7121 5.63779 44.2857 6.08516 43.9894 6.6165C43.6761 7.18459 43.5164 7.8246 43.5262 8.47329C43.5155 9.12545 43.6648 9.7703 43.9609 10.3515C44.2351 10.8853 44.6499 11.334 45.1605 11.6492C45.6828 11.9651 46.2834 12.1278 46.8937 12.1188C47.4923 12.128 48.0812 11.9674 48.5922 11.6556C49.0606 11.3609 49.4406 10.945 49.692 10.452L49.7007 11.3618C49.7024 11.5037 49.7598 11.6393 49.8605 11.7393C49.9612 11.8393 50.0972 11.8957 50.2391 11.8963H50.5392V5.0503H49.707L49.6904 6.48268ZM47.0774 11.3412C46.5903 11.3505 46.1105 11.2217 45.6934 10.9699C45.2933 10.7206 44.9674 10.3687 44.7495 9.95081C44.5146 9.49482 44.3963 8.98775 44.4051 8.47488C44.3946 7.95956 44.513 7.44979 44.7495 6.99181C44.9686 6.57658 45.2944 6.22727 45.6934 5.97988C46.1103 5.72739 46.5901 5.59809 47.0774 5.60694C47.56 5.59888 48.0349 5.72764 48.4473 5.9783C48.846 6.22601 49.1717 6.57524 49.3911 6.99023C49.6276 7.44822 49.7459 7.95798 49.7355 8.47329C49.7442 8.98615 49.6259 9.49319 49.3911 9.94923C49.1732 10.3671 48.8473 10.719 48.4473 10.9683C48.0349 11.2189 47.56 11.3477 47.0774 11.3396"
          fill="currentColor"
        />
        <path
          d="M72.367 10.7134C72.3374 11.3952 72.2135 12.0696 71.9988 12.7175C71.8023 13.337 71.5152 13.924 71.1468 14.4594L71.116 14.503H71.8215L71.8294 14.4935C72.1088 14.1765 72.3443 13.8234 72.5294 13.4435C72.7389 13.0207 72.9073 12.5787 73.0321 12.1236C73.1602 11.6649 73.2398 11.1942 73.2697 10.7189L72.367 10.7134Z"
          fill="currentColor"
        />
        <path
          d="M82.1515 8.0159C81.6807 7.91398 81.2333 7.72463 80.8323 7.45767C80.7151 7.36269 80.6216 7.24165 80.5593 7.10419C80.497 6.96674 80.4677 6.81665 80.4736 6.66586C80.469 6.50696 80.5051 6.34952 80.5786 6.20855C80.652 6.06758 80.7604 5.94778 80.8933 5.86059C81.2438 5.64647 81.6511 5.54376 82.0612 5.56603C82.4045 5.5664 82.7461 5.61273 83.0771 5.70381C83.3973 5.7892 83.7021 5.92412 83.9806 6.10367L84.0146 6.12584L84.1651 5.37125L84.1492 5.36096C83.8525 5.18783 83.5325 5.05798 83.1991 4.97534C82.8357 4.87812 82.4612 4.82862 82.085 4.82807C81.4522 4.79736 80.8272 4.97785 80.3082 5.34116C80.0924 5.50213 79.9187 5.71281 79.8018 5.9553C79.6849 6.19778 79.6283 6.4649 79.6367 6.73395C79.6274 6.96616 79.6675 7.1977 79.7541 7.41332C79.8408 7.62895 79.9721 7.82379 80.1395 7.98502C80.6335 8.3789 81.2109 8.65486 81.8276 8.79187C82.3284 8.90256 82.8056 9.10122 83.2371 9.3786C83.3572 9.47464 83.453 9.59771 83.5166 9.73779C83.5801 9.87787 83.6097 10.031 83.6029 10.1847C83.6057 10.3577 83.5641 10.5286 83.4822 10.681C83.4003 10.8335 83.2807 10.9625 83.1349 11.0557C82.745 11.2941 82.2919 11.4087 81.8356 11.3843C81.0572 11.3668 80.3 11.1273 79.6533 10.6938L79.6169 10.6685L79.4807 11.4524L79.495 11.4626C79.8195 11.6784 80.1775 11.8389 80.5544 11.9377C80.9709 12.0577 81.4021 12.119 81.8356 12.1199C82.5137 12.1501 83.1834 11.9601 83.7446 11.5783C83.9757 11.4181 84.1637 11.2033 84.2919 10.953C84.4201 10.7027 84.4845 10.4247 84.4794 10.1435C84.4896 9.90403 84.4481 9.66519 84.3578 9.44317C84.2675 9.22116 84.1304 9.02118 83.956 8.8568C83.4227 8.44768 82.8062 8.16038 82.1499 8.0151"
          fill="currentColor"
        />
        <path
          d="M92.1139 6.48268C91.8608 5.99729 91.4838 5.58751 91.0212 5.29497C90.536 4.98885 89.9732 4.82811 89.3995 4.83176C88.7829 4.8247 88.1769 4.99274 87.652 5.31634C87.1346 5.63884 86.7076 6.0876 86.4112 6.62046C86.0979 7.18855 85.9383 7.82856 85.948 8.47725C85.9374 9.12941 86.0867 9.77426 86.3827 10.3554C86.6568 10.8893 87.0716 11.3381 87.5823 11.6532C88.1047 11.9691 88.7053 12.1318 89.3156 12.1227C89.9134 12.1304 90.5011 11.9687 91.0109 11.6564C91.4793 11.3617 91.8593 10.9458 92.1107 10.4528L92.1186 11.3626C92.1205 11.5046 92.178 11.6402 92.2789 11.7401C92.3798 11.8401 92.5158 11.8965 92.6578 11.8971H92.9579V5.0503H92.1273L92.1139 6.48268ZM89.4961 11.3412C89.009 11.3505 88.5292 11.2217 88.1121 10.9699C87.712 10.7206 87.3861 10.3687 87.1682 9.95081C86.9333 9.49481 86.815 8.98774 86.8238 8.47488C86.8134 7.95956 86.9318 7.4498 87.1682 6.99181C87.3873 6.57658 87.7131 6.22727 88.1121 5.97988C88.5301 5.73646 89.0052 5.60821 89.489 5.60821C89.9728 5.60821 90.4479 5.73646 90.866 5.97988C91.2647 6.2276 91.5904 6.57683 91.8098 6.99181C92.0463 7.4498 92.1646 7.95956 92.1542 8.47488C92.1629 8.98773 92.0446 9.49477 91.8098 9.95081C91.5919 10.3687 91.266 10.7206 90.866 10.9699C90.4536 11.2205 89.9787 11.3492 89.4961 11.3412Z"
          fill="currentColor"
        />
        <path
          d="M97.1199 1.8862C97.282 1.6739 97.4948 1.50573 97.7389 1.39712C97.9829 1.28852 98.2504 1.24296 98.5166 1.26462C98.9251 1.26731 99.3289 1.35157 99.7043 1.51246L99.7368 1.52671L99.8421 0.789538L99.8239 0.780037C99.6251 0.690157 99.4169 0.622701 99.2031 0.578917C98.9589 0.525744 98.7096 0.499196 98.4596 0.499736C98.0894 0.476739 97.719 0.539843 97.3773 0.684096C97.0356 0.828348 96.7321 1.04982 96.4904 1.33114C95.9924 2.0486 95.7544 2.91458 95.8157 3.78575V5.05265H94.3826V5.80487H95.8157V11.9018H96.1388C96.2818 11.9016 96.4188 11.8447 96.5199 11.7436C96.6209 11.6426 96.6778 11.5055 96.678 11.3626V5.80487H98.9743V5.05027H96.678V3.86731C96.6271 3.17854 96.7802 2.49002 97.1183 1.88778"
          fill="currentColor"
        />
        <path
          d="M124.699 5.29693C124.174 4.9787 123.57 4.81562 122.957 4.8266C122.365 4.81779 121.784 4.97616 121.279 5.28347C120.814 5.57209 120.432 5.9761 120.17 6.45614V0.569824H119.308V11.8975H119.608C119.75 11.8971 119.886 11.8408 119.987 11.7408C120.088 11.6407 120.146 11.505 120.147 11.363L120.155 10.4809C120.411 10.9788 120.801 11.3957 121.28 11.685C121.76 11.9743 122.31 12.1246 122.87 12.1192C123.489 12.1258 124.097 11.9582 124.624 11.6354C125.145 11.3144 125.573 10.8623 125.865 10.3241C126.172 9.75625 126.329 9.11928 126.321 8.47368C126.333 7.81908 126.184 7.17156 125.886 6.58838C125.611 6.05916 125.2 5.61343 124.694 5.29772M122.786 5.60653C123.269 5.5985 123.744 5.72725 124.157 5.97789C124.556 6.22557 124.881 6.57481 125.101 6.98982C125.337 7.44795 125.455 7.95761 125.445 8.47288C125.454 8.98574 125.336 9.49278 125.101 9.94882C124.883 10.3667 124.557 10.7185 124.158 10.9679C123.745 11.2185 123.27 11.3472 122.787 11.3392C122.308 11.3479 121.836 11.2222 121.425 10.9763C121.013 10.7304 120.679 10.3742 120.46 9.94803C120.225 9.49213 120.106 8.98499 120.116 8.47209C120.105 7.95678 120.224 7.44703 120.46 6.98903C120.68 6.5727 121.008 6.22322 121.41 5.9771C121.825 5.72697 122.302 5.59831 122.787 5.60574"
          fill="currentColor"
        />
        <path
          d="M140.4 10.983C139.985 11.2233 139.515 11.3516 139.036 11.3551C138.834 11.3741 138.63 11.3439 138.443 11.267C138.255 11.1902 138.089 11.0691 137.958 10.9141C137.702 10.4653 137.587 9.94935 137.63 9.43419V5.80215H140.135V5.05072H137.63V2.88037H136.767V5.05072H135.58V5.80215H136.767V9.61472C136.713 10.2887 136.895 10.9607 137.282 11.5151C137.492 11.7306 137.746 11.897 138.028 12.0021C138.31 12.1073 138.611 12.1486 138.911 12.1232C139.209 12.1232 139.506 12.0857 139.794 12.0115C140.055 11.9479 140.306 11.8486 140.539 11.7162L140.555 11.7067L140.434 10.9671L140.4 10.983Z"
          fill="currentColor"
        />
        <path
          d="M146.663 10.983C146.249 11.2234 145.778 11.3516 145.299 11.3551C145.097 11.3741 144.894 11.3439 144.706 11.267C144.518 11.1902 144.352 11.0691 144.221 10.9141C143.965 10.4653 143.85 9.94935 143.893 9.43419V5.80215H146.398V5.05072H143.893V2.88037H143.03V5.05072H141.843V5.80215H143.03V9.61472C142.976 10.2887 143.158 10.9607 143.545 11.5151C143.755 11.7306 144.01 11.897 144.291 12.0021C144.573 12.1073 144.874 12.1486 145.174 12.1232C145.472 12.1232 145.769 12.0857 146.058 12.0115C146.318 11.948 146.569 11.8486 146.803 11.7162L146.819 11.7067L146.697 10.9671L146.663 10.983Z"
          fill="currentColor"
        />
        <path
          d="M72.4192 5.59268V4.82779C71.9161 4.80558 71.4138 4.88712 70.9436 5.06734C70.4734 5.24756 70.0452 5.5226 69.6859 5.87536C68.9249 6.63945 68.6565 7.36317 68.6565 8.89532V11.8971H68.8331C68.9265 11.916 69.0231 11.9117 69.1145 11.8845C69.2058 11.8573 69.289 11.808 69.3569 11.741C69.4247 11.6741 69.475 11.5915 69.5034 11.5005C69.5317 11.4095 69.5373 11.3129 69.5196 11.2193V8.80585C69.4658 8.36037 69.5138 7.90849 69.66 7.48427C69.8062 7.06004 70.0468 6.67453 70.3636 6.35678C70.9245 5.84415 71.6629 5.57009 72.4223 5.59268"
          fill="currentColor"
        />
        <path
          d="M104.654 5.23562C104.173 4.95709 103.625 4.81603 103.07 4.82783C102.462 4.8183 101.863 4.98138 101.344 5.29817C100.836 5.61491 100.424 6.06344 100.152 6.59595C99.8543 7.18149 99.705 7.83098 99.7169 8.48758C99.7012 9.15847 99.8583 9.82212 100.173 10.4148C100.471 10.9567 100.923 11.3977 101.472 11.6817C102.089 11.9879 102.771 12.1393 103.46 12.1228C103.914 12.1223 104.366 12.066 104.806 11.9549C105.2 11.8614 105.579 11.7152 105.934 11.5202L105.95 11.5107L105.83 10.7577L105.795 10.7759C105.084 11.1536 104.29 11.3487 103.484 11.3436C102.73 11.3804 101.989 11.132 101.409 10.6476C101.146 10.4005 100.936 10.1031 100.791 9.77308C100.646 9.44306 100.569 9.0872 100.564 8.72671H106.056V8.70137C106.066 8.59289 106.072 8.48679 106.077 8.38465C106.081 8.2825 106.084 8.1859 106.084 8.08376C106.097 7.4915 105.967 6.90479 105.705 6.37345C105.466 5.9017 105.098 5.50724 104.644 5.23562M105.249 8.02912H100.563C100.609 7.3603 100.89 6.72917 101.354 6.24597C101.572 6.0248 101.833 5.8504 102.12 5.73344C102.407 5.61649 102.716 5.55944 103.026 5.5658C103.311 5.55556 103.595 5.60248 103.862 5.70379C104.129 5.8051 104.372 5.95875 104.579 6.1557C104.995 6.58381 105.231 7.15561 105.237 7.75278C105.246 7.8383 105.251 7.93094 105.252 8.02912"
          fill="currentColor"
        />
        <path
          d="M111.661 10.7134C111.631 11.3952 111.508 12.0696 111.293 12.7175C111.097 13.337 110.81 13.924 110.441 14.4594L110.41 14.503H111.116L111.124 14.4935C111.403 14.1765 111.639 13.8234 111.824 13.4435C112.033 13.0207 112.202 12.5787 112.327 12.1236C112.455 11.665 112.534 11.1942 112.564 10.7189L111.661 10.7134Z"
          fill="currentColor"
        />
        <path
          d="M111.712 5.59271V4.82782C111.209 4.80551 110.707 4.88701 110.236 5.06723C109.766 5.24746 109.338 5.52255 108.979 5.87539C108.217 6.63948 107.949 7.3632 107.949 8.89535V11.8971H108.127C108.22 11.916 108.317 11.9117 108.408 11.8845C108.5 11.8573 108.583 11.808 108.651 11.7411C108.718 11.6741 108.769 11.5915 108.797 11.5005C108.826 11.4095 108.831 11.313 108.813 11.2193V8.80588C108.76 8.3604 108.808 7.90852 108.954 7.4843C109.1 7.06007 109.341 6.67456 109.657 6.35681C110.218 5.84425 110.957 5.5702 111.716 5.59271"
          fill="currentColor"
        />
        <path
          d="M152.821 5.23561C152.34 4.95713 151.792 4.81606 151.237 4.82783C150.629 4.81833 150.03 4.98141 149.511 5.29816C149.003 5.615 148.591 6.0635 148.318 6.59594C148.021 7.18158 147.872 7.83101 147.884 8.48758C147.868 9.15847 148.025 9.82212 148.34 10.4148C148.637 10.9565 149.089 11.3974 149.637 11.6817C150.255 11.988 150.938 12.1393 151.627 12.1228C152.082 12.1226 152.535 12.0662 152.977 11.9549C153.37 11.8612 153.749 11.715 154.104 11.5202L154.121 11.5107L154 10.7577L153.966 10.7759C153.254 11.1536 152.46 11.3486 151.655 11.3436C150.9 11.3806 150.159 11.1322 149.579 10.6476C149.317 10.4003 149.107 10.1029 148.962 9.77288C148.816 9.44291 148.739 9.08715 148.734 8.7267H154.228V8.70137C154.237 8.59289 154.243 8.48678 154.248 8.38464C154.253 8.2825 154.255 8.1859 154.255 8.08375C154.269 7.4915 154.139 6.90478 153.877 6.37344C153.637 5.90192 153.269 5.50754 152.816 5.23561M153.421 8.02912H148.733C148.78 7.3604 149.061 6.72939 149.525 6.24596C149.743 6.02487 150.003 5.85052 150.291 5.73357C150.578 5.61662 150.886 5.55953 151.197 5.5658C151.482 5.55556 151.766 5.60247 152.033 5.70378C152.299 5.80509 152.543 5.95875 152.749 6.15569C153.166 6.5836 153.403 7.1554 153.41 7.75278C153.418 7.83829 153.422 7.93093 153.423 8.02912"
          fill="currentColor"
        />
        <path
          d="M159.879 5.59267V4.82778C159.376 4.80561 158.873 4.88717 158.403 5.06739C157.933 5.24761 157.505 5.52262 157.145 5.87534C156.384 6.63944 156.116 7.36315 156.116 8.89531V11.8971H156.293C156.386 11.916 156.483 11.9117 156.574 11.8845C156.665 11.8573 156.749 11.808 156.816 11.741C156.884 11.6741 156.934 11.5915 156.963 11.5005C156.991 11.4095 156.997 11.3129 156.979 11.2193V8.80583C156.925 8.36035 156.973 7.90844 157.119 7.4842C157.266 7.05995 157.506 6.67445 157.823 6.35676C158.384 5.84392 159.122 5.56982 159.882 5.59267"
          fill="currentColor"
        />
        <path
          d="M57.7962 5.66747C57.5083 5.39124 57.1684 5.17494 56.7962 5.03113C56.424 4.88732 56.027 4.81886 55.6282 4.82973C54.8234 4.81069 54.0438 5.11136 53.4602 5.66588C53.1392 5.97115 52.8912 6.34494 52.7348 6.7594C52.5783 7.17385 52.5175 7.61828 52.5567 8.05953V11.8958H52.7397C52.8346 11.9097 52.9314 11.9021 53.023 11.8738C53.1147 11.8454 53.1988 11.797 53.2694 11.7319C53.328 11.6624 53.3715 11.5815 53.3973 11.4942C53.4231 11.407 53.4305 11.3154 53.419 11.2252V7.96451C53.3905 7.63418 53.4351 7.30161 53.5498 6.99052C53.6645 6.67943 53.8464 6.39746 54.0826 6.16472C54.5045 5.77947 55.0552 5.56587 55.6266 5.56587C56.198 5.56587 56.7487 5.77947 57.1706 6.16472C57.4068 6.39743 57.5888 6.67939 57.7035 6.99049C57.8181 7.30159 57.8628 7.63417 57.8342 7.96451V11.226C57.8227 11.3162 57.8301 11.4078 57.8559 11.495C57.8816 11.5823 57.9252 11.6632 57.9838 11.7327C58.0543 11.7978 58.1385 11.8463 58.2301 11.8746C58.3218 11.903 58.4186 11.9105 58.5135 11.8966H58.6964V8.06111C58.7337 7.62002 58.672 7.17617 58.5157 6.76202C58.3593 6.34787 58.1124 5.97391 57.793 5.66747"
          fill="currentColor"
        />
        <path
          d="M160.097 10.8867C159.978 10.8867 159.861 10.9221 159.762 10.9884C159.663 11.0547 159.585 11.1489 159.54 11.2592C159.494 11.3694 159.482 11.4907 159.505 11.6078C159.529 11.7248 159.586 11.8323 159.671 11.9167C159.755 12.0011 159.863 12.0586 159.98 12.0818C160.097 12.1051 160.218 12.0932 160.328 12.0475C160.438 12.0018 160.533 11.9245 160.599 11.8253C160.665 11.7261 160.701 11.6094 160.701 11.4901C160.7 11.3301 160.637 11.1768 160.524 11.0637C160.411 10.9506 160.257 10.8869 160.097 10.8867Z"
          fill="currentColor"
        />
      </g>
    </svg>
  )
}
