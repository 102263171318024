import { errorFactory } from '@nori/errors'

import type { KeyOfErrors } from '@nori/errors'

export const { ShareCartErrors } = errorFactory('ShareCartErrors', {
  BadRequest: 'Bad Request',
  NotFound: 'Not Found',
  UnknownError: 'Unknown Error',
})

export type ShareCartErrorInstance = InstanceType<
  KeyOfErrors<typeof ShareCartErrors>
>
