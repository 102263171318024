import {
  getBaseUrl,
  ProductErrorMapper,
  ProductErrors,
  RequestService,
  SERVICE_NAMES,
} from '@nori/app-kit'
import { Adapter, Inject } from '@nori/di'
import { resultErr, resultOk } from '@nori/result'

import { ProductListMapper } from './mappers/product-list.mapper'

import type { ProductErrorInstance } from '@nori/app-kit'
import type { PromiseResult } from '@nori/result'
import type { ProductListValueObject } from '~/modules/products/core'
import type { GetProductsRequest } from '~/modules/products/infra/types/get-products.request'

@Adapter()
export class ProductsLoungeAdapter {
  private readonly baseUrl = getBaseUrl(SERVICE_NAMES.CATALOG_SERVICE)

  public constructor(
    @Inject() private readonly requestService: RequestService,
    @Inject() private readonly productListMapper: ProductListMapper
  ) {}

  public async getProducts(
    params: GetProductsRequest
  ): PromiseResult<ProductListValueObject, ProductErrorInstance> {
    try {
      const query = new URLSearchParams()
      query.append('page', `${params.pagination.page}`)
      query.append('per_page', `${params.pagination.perPage}`)
      query.append('group_styles', 'true')
      query.append('_', `${Date.now()}`)

      const url = `${this.baseUrl}/${params.type}/${
        params.id
      }/items?${query.toString()}`

      const { json, ok, status } = await this.requestService.secured.get(url)

      if (ok) {
        return resultOk(this.productListMapper.toProductListValueObject(json))
      }

      return resultErr(ProductErrorMapper.toErrors({ json, status }))
    } catch (err: unknown) {
      return resultErr(new ProductErrors.UnexpectedError(err))
    }
  }
}
