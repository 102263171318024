import { useTranslations } from '@nori/lang-i18n'

import { CreditItem } from '../credit-item/credit-item'

import type { UserCreditsEntity } from '../../core'

type Props = {
  credits: UserCreditsEntity
  customerId?: number
}

export function SelfCustomerCartCredits({ credits, customerId }: Props) {
  const t = useTranslations('userCredits')
  const shouldDisplayStoreCredits = !!customerId

  return (
    <div className="flex w-full bg-legend-group-5 px-5 py-5 lg:px-10">
      <CreditItem
        title={t('itemDiscount')}
        subTitle={t('appliedOnCart')}
        credit={credits.hostessDiscountsHash?.count}
        data-test-id="item-discount"
      />
      <CreditItem
        title={t('norwexCredits')}
        subTitle={t('appliedOnCart')}
        credit={credits.combinedCredits}
        currency={credits.currency}
        isWithLine
        data-test-id="norwex-credits"
      />
      {shouldDisplayStoreCredits && (
        <CreditItem
          title={t('storeCredits')}
          subTitle={t('appliedAtCheckout')}
          credit={credits.storeCredits}
          currency={credits.currency}
          isWithLine
          data-test-id="store-credits"
        />
      )}
      <CreditItem
        title={t('productCredits')}
        subTitle={t('notAvailable')}
        credit={undefined}
        currency={''}
        isCreditNA
        isWithLine
        data-test-id="product-credits"
      />
    </div>
  )
}
