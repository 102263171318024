import { useEffect } from 'react'

import { observer } from '@nori/di'
import { useTranslations } from '@nori/lang-i18n'
import { Loader, Pagination, Table, TableBody } from '@nori/ui-kit'
import { useContactContext } from '~/modules/contacts/interface/use-contact-context'

import { ContactListRow } from './contact-list-row/contact-list-row'
import { ContactTableHeader } from './contact-table-header'

export const ContactsTable = observer(() => {
  const { contactsStore, contactsAction } = useContactContext()
  const t = useTranslations('contacts.list.table')

  useEffect(() => {
    contactsAction.handleInitialLoad()
  }, [])

  if (contactsStore.isLoading) return <Loader />
  if (contactsStore.error) return <div>{t('error')}</div>
  return (
    <div className="mt-6 w-full">
      <div className="overflow-x-auto">
        <Table
          widthType="min-1194"
          data-test-id="contacts-list-table"
          withEmpty
        >
          <ContactTableHeader />
          <TableBody>
            {contactsStore.contactsList?.map((contact) => {
              return <ContactListRow key={contact.id} contact={contact} />
            })}
          </TableBody>
        </Table>
      </div>

      <div className="mb-6 mt-6 flex justify-center">
        <Pagination
          currentPage={contactsStore.pagination?.page ?? 1}
          totalPages={contactsStore.pagination?.totalPages ?? 0}
          onChange={(page) => contactsAction.handleLoadMore(page)}
          offset={1}
          limitNumber={500}
        />
      </div>
    </div>
  )
})
