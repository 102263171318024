var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __name = (target, value) => __defProp(target, "name", { value, configurable: true });
var __publicField = (obj, key, value) => {
  __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
  return value;
};

// react-import.js
import React from "react";

// src/interface/services/is-server.ts
var isServer = typeof window === "undefined";

// src/infra/create-logger.ts
import { loggerFactory } from "@nori/logger";
function createLogger(name) {
  return loggerFactory({
    name,
    section: "ibo-dashboards"
  });
}
__name(createLogger, "createLogger");

// src/modules/report-kit/ui/page-loader.tsx
import { Loader } from "@nori/ui-kit";
function PageLoader() {
  return /* @__PURE__ */ React.createElement("div", {
    className: "flex h-screen w-full items-center justify-center"
  }, /* @__PURE__ */ React.createElement(Loader, {
    withTitle: true
  }));
}
__name(PageLoader, "PageLoader");

// src/interface/query-keys.ts
var SELECTED_USER_ID_KEY = "selected-user-id";
var REPORT_NAME_KEY = "report-name";

// src/modules/route/interface/get-query-param.ts
function getQueryParam(key) {
  if (isServer)
    return;
  const urlParams = new URLSearchParams(window.location.search);
  const value = urlParams.get(key);
  if (!value)
    return;
  return value;
}
__name(getQueryParam, "getQueryParam");

// src/modules/report-type-coaching/interface/store/tree-view-store.ts
import { Store } from "@nori/di";
var __decorate = function(decorators, target, key, desc) {
  var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
  if (typeof Reflect === "object" && typeof Reflect.decorate === "function")
    r = Reflect.decorate(decorators, target, key, desc);
  else
    for (var i = decorators.length - 1; i >= 0; i--)
      if (d = decorators[i])
        r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
  return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var TreeViewStore = /* @__PURE__ */ __name(class TreeViewStore2 {
  constructor() {
    __publicField(this, "treeStructure", []);
    __publicField(this, "activeTreeCommissionGroupFilter", "Personal Group");
    __publicField(this, "isTreeLoading", false);
  }
  setIsTreeLoading(value) {
    this.isTreeLoading = value;
  }
  setActiveTreeCommissionGroupFilter(value) {
    this.activeTreeCommissionGroupFilter = value;
    this.clearTreeView();
  }
  addTreeParent(loginId, parentLoginId, parentPath) {
    const index = this.treeStructure.findIndex((s) => s.loginId === loginId);
    if (index !== -1)
      return;
    this.treeStructure.push({
      loginId,
      isOpen: false,
      isLoading: false,
      parentLoginId,
      parentPath: parentPath || []
    });
  }
  toggleTreeItem(loginId, isOpen) {
    if (!isOpen) {
      this.treeStructure = this.treeStructure.filter((s) => !s.parentPath.includes(loginId)).map((s) => s.loginId === loginId ? {
        ...s,
        isOpen
      } : s);
      return;
    }
    this.treeStructure = this.treeStructure.map((s) => s.loginId === loginId ? {
      ...s,
      isOpen
    } : s);
  }
  toggleTreeItemLoading(loginId, isLoading) {
    this.treeStructure = this.treeStructure.map((s) => s.loginId === loginId ? {
      ...s,
      isLoading
    } : s);
  }
  isTreeItemOpen(loginId) {
    var _a2;
    return !!((_a2 = this.treeStructure.find((t) => t.loginId === loginId)) == null ? void 0 : _a2.isOpen);
  }
  isTreeItemLoading(loginId) {
    var _a2;
    return !!((_a2 = this.treeStructure.find((t) => t.loginId === loginId)) == null ? void 0 : _a2.isLoading);
  }
  clearTreeView() {
    this.treeStructure = [];
  }
  getFilterByCommissionGroup() {
    const commissionGroup = this.activeTreeCommissionGroupFilter;
    switch (commissionGroup) {
      case "Personal Group": {
        return {
          ["group_level"]: {
            type: "num-list",
            value: [
              0
            ]
          }
        };
      }
      case "Commissionable Downline": {
        return {
          ["commissionable_downline"]: {
            type: "bool",
            value: true
          }
        };
      }
      case "Full Downline": {
        return {};
      }
      default: {
        return {};
      }
    }
  }
  resetTreeView() {
    this.clearTreeView();
    this.setActiveTreeCommissionGroupFilter("Personal Group");
  }
}, "TreeViewStore");
TreeViewStore = __decorate([
  Store()
], TreeViewStore);

// src/modules/report-type-coaching/interface/store/report-coaching-store.ts
import { Inject as Inject2, Store as Store4 } from "@nori/di";

// src/modules/report-type/interface/store/config-store.ts
import { HistoryService } from "@nori/app-kit";
import { Inject, Store as Store3 } from "@nori/di";

// src/modules/report-type/interface/store/report-context-store.ts
import { Store as Store2 } from "@nori/di";

// src/infra/sanitize-upstyle-id.ts
function sanitizeUpstyleId(value) {
  return value || "";
}
__name(sanitizeUpstyleId, "sanitizeUpstyleId");

// src/interface/default-report.ts
var DEFAULT_REPORT = "activity";

// src/modules/report-type/core/errors/report.errors.ts
import { errorFactory } from "@nori/errors";
var { ReportErrors } = errorFactory("ReportErrors", {
  LoadConfig: "Error while loading config",
  LoadConfigUnexpected: "Unexpected error while loading config",
  LoadReportNotExist: "Report not exist",
  LoadData: "Error while loading data",
  LoadDataUnknownError: "Unknown error data loading",
  LoadDataUserNotFound: "User not found while load report",
  LoadDataUnexpected: "Unexpected error while loading data"
});

// src/modules/report-type/interface/store/report-context-store.ts
var __decorate2 = function(decorators, target, key, desc) {
  var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
  if (typeof Reflect === "object" && typeof Reflect.decorate === "function")
    r = Reflect.decorate(decorators, target, key, desc);
  else
    for (var i = decorators.length - 1; i >= 0; i--)
      if (d = decorators[i])
        r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
  return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var ReportContextStore = /* @__PURE__ */ __name(class ReportContextStore2 {
  constructor() {
    __publicField(this, "isInitialLoading", true);
    __publicField(this, "isReportDataLoading", false);
    __publicField(this, "error");
    __publicField(this, "loginId", "");
    __publicField(this, "report");
    __publicField(this, "reportName", DEFAULT_REPORT);
    __publicField(this, "brand");
    __publicField(this, "envRun");
    __publicField(this, "selectedUserId", "");
  }
  get currentUserId() {
    return this.loginId;
  }
  setInitialParams({ envRun, brand, selectedUserId, reportName }) {
    this.reportName = reportName;
    this.brand = brand;
    this.envRun = envRun;
    this.selectedUserId = sanitizeUpstyleId(selectedUserId);
  }
  get basicReport() {
    if (!this.report)
      return;
    if ("report" in this.report) {
      return this.report;
    }
    return void 0;
  }
  getCurrentReport() {
    return this.report;
  }
  setLoginId(value) {
    this.loginId = value;
  }
  isMyReport() {
    if (!this.selectedUserId)
      return true;
    return this.loginId === this.selectedUserId;
  }
  setError(error) {
    this.error = error;
  }
  setIsInitialLoading(value) {
    this.isInitialLoading = value;
  }
  setIsReportDataLoading(value) {
    this.isReportDataLoading = value;
  }
  setReport(value) {
    this.report = value;
  }
  get errorTitle() {
    if (!this.error)
      return "";
    if (this.error instanceof ReportErrors.LoadDataUserNotFound) {
      return "User not found.";
    }
    if (this.error instanceof ReportErrors.LoadReportNotExist)
      return "Report data does not exist.";
    if (this.error instanceof ReportErrors.LoadData)
      return "There seems to be an error in the process of loading the report.";
    return "An error occurred during the data loading process.";
  }
}, "ReportContextStore");
ReportContextStore = __decorate2([
  Store2()
], ReportContextStore);

// src/modules/report-type/interface/store/config-store.ts
var __decorate3 = function(decorators, target, key, desc) {
  var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
  if (typeof Reflect === "object" && typeof Reflect.decorate === "function")
    r = Reflect.decorate(decorators, target, key, desc);
  else
    for (var i = decorators.length - 1; i >= 0; i--)
      if (d = decorators[i])
        r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
  return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = function(k, v) {
  if (typeof Reflect === "object" && typeof Reflect.metadata === "function")
    return Reflect.metadata(k, v);
};
var __param = function(paramIndex, decorator) {
  return function(target, key) {
    decorator(target, key, paramIndex);
  };
};
var ConfigStore = /* @__PURE__ */ __name(class ConfigStore2 {
  constructor(reportContextStore, historyService) {
    __publicField(this, "reportContextStore");
    __publicField(this, "historyService");
    __publicField(this, "config");
    __publicField(this, "queryParams");
    this.reportContextStore = reportContextStore;
    this.historyService = historyService;
    this.queryParams = {};
    this.queryParams = this.historyService.getQuery();
  }
  setActiveTabId(id) {
    const query = this.queryParams;
    query.tab = id;
    this.historyService.replace({
      pathname: this.historyService.pathname,
      query
    }, void 0, {
      shallow: true
    });
  }
  setConfig(value) {
    this.config = value;
    const firstTab = this.tabsList[0];
    if (!firstTab || this.activeTabId)
      return;
    this.setActiveTabId(firstTab.id);
  }
  get activeTabId() {
    var _a2;
    return (_a2 = this.queryParams.tab) != null ? _a2 : "";
  }
  get activeTabTitle() {
    const activeTab = this.tabsList.find((tab) => tab.id === this.activeTabId);
    return (activeTab == null ? void 0 : activeTab.display) || "";
  }
  sanitizeOwnerTitle(ownerName) {
    if (!ownerName)
      return "";
    const capitalized = ownerName.toLowerCase().split(" ").map((node) => `${(node[0] || "").toUpperCase()}${node.slice(1)}`).join(" ");
    return `${capitalized} - `;
  }
  getHeaderTitle({ defaultTitle: defaultTitle2, ownerName }) {
    var _a2, _b, _c, _d;
    const title = ((_c = (_b = (_a2 = this.config) == null ? void 0 : _a2.header) == null ? void 0 : _b.content) == null ? void 0 : _c.title) || ((_d = this.config) == null ? void 0 : _d.title) || defaultTitle2 || "";
    if (this.reportContextStore.isMyReport())
      return title;
    const ownerTitleSanitized = this.sanitizeOwnerTitle(ownerName);
    const resultTitle = `${ownerTitleSanitized} ${title}`.trim();
    return resultTitle;
  }
  get tabsList() {
    var _a2;
    const usedTabs = (_a2 = this.config) == null ? void 0 : _a2.tabs;
    if (!usedTabs)
      return [];
    const isCorrectTabs = "list" in usedTabs;
    if (isCorrectTabs)
      return usedTabs.list;
    return [];
  }
  // TODO: check usage in next reports, if not - then drop it
  get tabsListLegs() {
    var _a2;
    const usedTabs = (_a2 = this.config) == null ? void 0 : _a2.tabs;
    if (!usedTabs)
      return {
        legs: {
          columns: []
        },
        list: []
      };
    const isCorrectTabs = "legs" in usedTabs;
    if (!isCorrectTabs)
      return {
        legs: {
          columns: []
        },
        list: []
      };
    return usedTabs;
  }
  get tabsSections() {
    var _a2;
    const usedTabs = (_a2 = this.config) == null ? void 0 : _a2.tabs;
    if (!usedTabs)
      return [];
    const isLegsInTabs = "legs" in usedTabs;
    if (isLegsInTabs)
      return [];
    return usedTabs;
  }
}, "ConfigStore");
ConfigStore = __decorate3([
  Store3(),
  __param(0, Inject()),
  __param(1, Inject()),
  __metadata("design:type", Function),
  __metadata("design:paramtypes", [
    typeof ReportContextStore === "undefined" ? Object : ReportContextStore,
    typeof HistoryService === "undefined" ? Object : HistoryService
  ])
], ConfigStore);

// src/modules/report-type-coaching/interface/store/report-coaching-store.ts
var __decorate4 = function(decorators, target, key, desc) {
  var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
  if (typeof Reflect === "object" && typeof Reflect.decorate === "function")
    r = Reflect.decorate(decorators, target, key, desc);
  else
    for (var i = decorators.length - 1; i >= 0; i--)
      if (d = decorators[i])
        r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
  return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata2 = function(k, v) {
  if (typeof Reflect === "object" && typeof Reflect.metadata === "function")
    return Reflect.metadata(k, v);
};
var __param2 = function(paramIndex, decorator) {
  return function(target, key) {
    decorator(target, key, paramIndex);
  };
};
var PERMANENT_COLUMNS = {
  // eslint-disable-next-line camelcase
  stylist_name: true
};
var ReportCoachingStore = /* @__PURE__ */ __name(class ReportCoachingStore2 {
  constructor(configStore, reportContextStore, treeViewStore) {
    __publicField(this, "configStore");
    __publicField(this, "reportContextStore");
    __publicField(this, "treeViewStore");
    __publicField(this, "period");
    __publicField(this, "isInitialLoading");
    __publicField(this, "viewType");
    this.configStore = configStore;
    this.reportContextStore = reportContextStore;
    this.treeViewStore = treeViewStore;
    this.period = "";
    this.isInitialLoading = true;
    this.viewType = "list";
  }
  setIsInitialLoading(value) {
    this.isInitialLoading = value;
  }
  get report() {
    return this.reportContextStore.getCurrentReport();
  }
  get rows() {
    var _a2;
    return ((_a2 = this.report) == null ? void 0 : _a2.report.stylists) || [];
  }
  get currentColumns() {
    const currentTab = this.configStore.tabsList.find(({ id }) => this.configStore.activeTabId === id);
    if (!currentTab)
      return [];
    const firstRow = this.rows[0];
    const columns = currentTab.columns;
    const visibleCols = columns.reduce((acc, column) => {
      if (PERMANENT_COLUMNS[column.name]) {
        return [
          ...acc,
          column
        ];
      }
      if (firstRow && firstRow[column.name] === void 0) {
        return acc;
      }
      if (!column.show)
        return acc;
      return [
        ...acc,
        column
      ];
    }, []);
    return visibleCols;
  }
  get totalResults() {
    var _a2, _b, _c;
    return (_c = (_b = (_a2 = this.report) == null ? void 0 : _a2.report) == null ? void 0 : _b.total_results) != null ? _c : 0;
  }
  findItemInTreeByStylistId(loginId, stylist) {
    var _a2;
    if (!stylist)
      return {};
    if (stylist.login_id === loginId) {
      const path = [
        stylist.login_id
      ];
      return {
        result: stylist,
        path
      };
    }
    for (const child of (stylist == null ? void 0 : stylist.children) || []) {
      const tmp = this.findItemInTreeByStylistId(loginId, child);
      if (tmp.path !== void 0 && tmp.result !== void 0) {
        (_a2 = tmp == null ? void 0 : tmp.path) == null ? void 0 : _a2.unshift(stylist.login_id);
        return tmp;
      }
    }
    return {};
  }
  addChildrenToStylistById(loginId, parentStylist, stylists) {
    if (parentStylist.login_id === loginId) {
      parentStylist.children = stylists;
    }
    if (parentStylist.children && parentStylist.children.length > 0) {
      for (let i = 0; i < parentStylist.children.length; i++) {
        parentStylist.children[i] = this.addChildrenToStylistById(loginId, parentStylist.children[i], stylists);
      }
    }
    return parentStylist;
  }
  addChildrenToStylistByIdAndFilter(loginId, parentStylist, stylists, nextStylistId) {
    if (parentStylist.login_id === loginId) {
      const nextStylistInfo = stylists.find((s) => s.login_id === nextStylistId);
      parentStylist.children = [
        nextStylistInfo
      ];
    }
    if (parentStylist.children && parentStylist.children.length > 0) {
      for (let i = 0; i < parentStylist.children.length; i++) {
        parentStylist.children[i] = this.addChildrenToStylistByIdAndFilter(loginId, parentStylist.children[i], stylists, nextStylistId);
      }
    }
    return parentStylist;
  }
  addFilteredChildrenForCurrentReport(stylistId, stylists, nextStylistId) {
    var _a2, _b;
    const { path, result } = this.findItemInTreeByStylistId(stylistId, (_a2 = this.report) == null ? void 0 : _a2.report.stylists[0]);
    let stylistArray = (_b = this.report) == null ? void 0 : _b.report.stylists.slice();
    this.updateTreeView(stylists, stylistId, path || []);
    if (result && (stylistArray == null ? void 0 : stylistArray[0])) {
      const stylistWithTreeLvl = stylists.map((s) => ({
        ...s,
        treeLvl: (path == null ? void 0 : path.length) || 0,
        parentPath: path
      }));
      stylistArray = [
        this.addChildrenToStylistByIdAndFilter(stylistId, stylistArray[0], stylistWithTreeLvl, nextStylistId)
      ];
    }
    this.updateStylistsInReport(stylistArray);
  }
  addChildrenForCurrentReport(stylistId, stylists) {
    var _a2, _b;
    const { path, result } = this.findItemInTreeByStylistId(stylistId, (_a2 = this.report) == null ? void 0 : _a2.report.stylists[0]);
    let stylistArray = (_b = this.report) == null ? void 0 : _b.report.stylists.slice();
    this.updateTreeView(stylists, stylistId, path || []);
    if (result && (stylistArray == null ? void 0 : stylistArray[0])) {
      const stylistWithTreeLvl = stylists.map((s) => ({
        ...s,
        treeLvl: (path == null ? void 0 : path.length) || 0,
        parentPath: path
      }));
      stylistArray = [
        this.addChildrenToStylistById(stylistId, stylistArray[0], stylistWithTreeLvl)
      ];
    }
    this.updateStylistsInReport(stylistArray);
  }
  removeStylistChildren(stylistId) {
    var _a2;
    let stylistArray = (_a2 = this.report) == null ? void 0 : _a2.report.stylists.slice();
    if (stylistArray == null ? void 0 : stylistArray[0]) {
      stylistArray = [
        this.addChildrenToStylistById(stylistId, stylistArray[0], [])
      ];
    }
    this.updateStylistsInReport(stylistArray);
  }
  updateViewType(viewType) {
    this.viewType = viewType;
  }
  updateTreeView(stylists, stylistId, parentPath) {
    stylists.forEach((stylist) => {
      if (stylist.has_children) {
        this.treeViewStore.addTreeParent(stylist.login_id, stylistId, parentPath);
        if (stylist.login_id === stylistId) {
          this.treeViewStore.clearTreeView();
          this.treeViewStore.toggleTreeItem(stylist.login_id, true);
        }
      }
    });
  }
  updateStylistsInReport(stylists) {
    if (!stylists)
      return;
    const currentReport = this.report;
    const report = {
      ...currentReport,
      report: {
        ...currentReport == null ? void 0 : currentReport.report,
        stylists
      }
    };
    this.reportContextStore.setReport(report);
  }
}, "ReportCoachingStore");
ReportCoachingStore = __decorate4([
  Store4(),
  __param2(0, Inject2()),
  __param2(1, Inject2()),
  __param2(2, Inject2()),
  __metadata2("design:type", Function),
  __metadata2("design:paramtypes", [
    typeof ConfigStore === "undefined" ? Object : ConfigStore,
    typeof ReportContextStore === "undefined" ? Object : ReportContextStore,
    typeof TreeViewStore === "undefined" ? Object : TreeViewStore
  ])
], ReportCoachingStore);

// src/modules/report-type/interface/use-report-context.ts
import { hookContextFactory } from "@nori/di";

// src/modules/report-type/interface/actions/manage-tabs.action.ts
import { Action, Inject as Inject3 } from "@nori/di";
var __decorate5 = function(decorators, target, key, desc) {
  var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
  if (typeof Reflect === "object" && typeof Reflect.decorate === "function")
    r = Reflect.decorate(decorators, target, key, desc);
  else
    for (var i = decorators.length - 1; i >= 0; i--)
      if (d = decorators[i])
        r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
  return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata3 = function(k, v) {
  if (typeof Reflect === "object" && typeof Reflect.metadata === "function")
    return Reflect.metadata(k, v);
};
var __param3 = function(paramIndex, decorator) {
  return function(target, key) {
    decorator(target, key, paramIndex);
  };
};
var ManageTabsAction = /* @__PURE__ */ __name(class ManageTabsAction2 {
  constructor(configStore) {
    __publicField(this, "configStore");
    this.configStore = configStore;
  }
  handleChangeTab(id) {
    this.configStore.setActiveTabId(id);
  }
}, "ManageTabsAction");
ManageTabsAction = __decorate5([
  Action(),
  __param3(0, Inject3()),
  __metadata3("design:type", Function),
  __metadata3("design:paramtypes", [
    typeof ConfigStore === "undefined" ? Object : ConfigStore
  ])
], ManageTabsAction);

// src/modules/report-type/interface/actions/manage-report.action.ts
import { Action as Action3, Inject as Inject15 } from "@nori/di";

// src/modules/report-type/interface/service/load-data.service.ts
import { Inject as Inject13, Service as Service2 } from "@nori/di";
import { NotifyAction } from "@nori/notify";
import { isErr as isErr2 } from "@nori/result";

// src/modules/auth/interface/services/load-auth.service.ts
import * as process2 from "process";
import { UserStore } from "@nori/app-kit";
import { Inject as Inject4, Service } from "@nori/di";
import { resultOk } from "@nori/result";
var import_meta = {};
var __decorate6 = function(decorators, target, key, desc) {
  var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
  if (typeof Reflect === "object" && typeof Reflect.decorate === "function")
    r = Reflect.decorate(decorators, target, key, desc);
  else
    for (var i = decorators.length - 1; i >= 0; i--)
      if (d = decorators[i])
        r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
  return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata4 = function(k, v) {
  if (typeof Reflect === "object" && typeof Reflect.metadata === "function")
    return Reflect.metadata(k, v);
};
var __param4 = function(paramIndex, decorator) {
  return function(target, key) {
    decorator(target, key, paramIndex);
  };
};
var _a;
var DEFAULT_LOGIN_ID = process2.env.NODE_ENV === "development" ? (
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  (_a = import_meta == null ? void 0 : import_meta.env) == null ? void 0 : _a.VITE_DEV_REPORTS_DSR_LOGIN_ID
) : "";
var LoadAuthService = /* @__PURE__ */ __name(class LoadAuthService2 {
  constructor(userStore) {
    __publicField(this, "userStore");
    this.userStore = userStore;
  }
  async getAuthData() {
    const dsrLoginId = this.userStore.currentUser.dsrLogin || DEFAULT_LOGIN_ID;
    return new Promise((resolve) => {
      resolve(resultOk({
        loginId: dsrLoginId || ""
      }));
    });
  }
}, "LoadAuthService");
LoadAuthService = __decorate6([
  Service(),
  __param4(0, Inject4()),
  __metadata4("design:type", Function),
  __metadata4("design:paramtypes", [
    typeof UserStore === "undefined" ? Object : UserStore
  ])
], LoadAuthService);

// src/modules/filter/interface/store/filters-store.ts
import { HistoryService as HistoryService2 } from "@nori/app-kit";
import { Inject as Inject5, Store as Store5 } from "@nori/di";

// src/modules/filter/interface/store/get-used-schema/get-sub-filters.ts
var matchSubFilters = /* @__PURE__ */ __name(({ useArray, options, id }, config) => {
  return useArray ? !!config[id] : options.some(({ value }) => !!config[value]);
}, "matchSubFilters");
var getFilteredOptions = /* @__PURE__ */ __name(({ useArray, options }, config) => {
  return useArray ? options : options.filter(({ value }) => config[value]);
}, "getFilteredOptions");
var isOptionDisabled = /* @__PURE__ */ __name((id, option, config) => {
  const optionConfig = config[id] ? config[id].find(({ value }) => option.value === value) : null;
  return optionConfig ? optionConfig.count === 0 : false;
}, "isOptionDisabled");
function getSubFilters({ subFilters, filterOptions }) {
  if (!subFilters)
    return [];
  const { team_insights: config } = filterOptions;
  if (!config)
    return [];
  return subFilters.filter((filter) => matchSubFilters(filter, config)).map((filter) => {
    const options = getFilteredOptions(filter, config).map((option) => {
      return {
        disabled: isOptionDisabled(filter.id, option, config),
        ...option
      };
    });
    return {
      ...filter,
      options
    };
  });
}
__name(getSubFilters, "getSubFilters");

// src/modules/filter/interface/store/get-used-schema/get-team-extras.ts
var TEAM_INSIGHT_FILTER = "team_insights";
function removeTeamInsightsFilter(schema) {
  return schema.map((item) => {
    return item.filter((filter) => {
      return filter.id !== TEAM_INSIGHT_FILTER;
    });
  });
}
__name(removeTeamInsightsFilter, "removeTeamInsightsFilter");
function getTeamInsightFilterSchema(schema) {
  const res = schema.reduce((array, items) => [
    ...array,
    ...items
  ], []).find(({ id }) => id === TEAM_INSIGHT_FILTER);
  return res;
}
__name(getTeamInsightFilterSchema, "getTeamInsightFilterSchema");
function getTeamInsight(schema, filterOptions) {
  return schema.map((item) => {
    return item.map((filter) => {
      return filter.id === TEAM_INSIGHT_FILTER ? {
        ...filter,
        // eslint-disable-next-line camelcase
        sub_filters: getSubFilters({
          subFilters: filter.sub_filters,
          filterOptions
        })
      } : filter;
    });
  });
}
__name(getTeamInsight, "getTeamInsight");

// src/modules/filter/interface/store/get-used-schema/get-used-schema.ts
function getUsedSchema({ filterOptions, schema }) {
  const teamInsideFilter = getTeamInsightFilterSchema(schema);
  if (!teamInsideFilter)
    return schema;
  if (!(filterOptions == null ? void 0 : filterOptions.team_insights)) {
    return removeTeamInsightsFilter(schema);
  }
  if (!teamInsideFilter.sub_filters) {
    return removeTeamInsightsFilter(schema);
  }
  return getTeamInsight(schema, filterOptions);
}
__name(getUsedSchema, "getUsedSchema");

// src/modules/filter/interface/store/filters-store.ts
var __decorate7 = function(decorators, target, key, desc) {
  var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
  if (typeof Reflect === "object" && typeof Reflect.decorate === "function")
    r = Reflect.decorate(decorators, target, key, desc);
  else
    for (var i = decorators.length - 1; i >= 0; i--)
      if (d = decorators[i])
        r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
  return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata5 = function(k, v) {
  if (typeof Reflect === "object" && typeof Reflect.metadata === "function")
    return Reflect.metadata(k, v);
};
var __param5 = function(paramIndex, decorator) {
  return function(target, key) {
    decorator(target, key, paramIndex);
  };
};
var IGNORED_FILTERS = {
  // eslint-disable-next-line camelcase
  stylist_id: true,
  // eslint-disable-next-line camelcase
  partial_name: true
};
var FiltersStore = /* @__PURE__ */ __name(class FiltersStore2 {
  constructor(configStore, reportContextStore, historyService) {
    __publicField(this, "configStore");
    __publicField(this, "reportContextStore");
    __publicField(this, "historyService");
    __publicField(this, "selectedFilters");
    __publicField(this, "initialValues");
    __publicField(this, "hiddenKeys");
    __publicField(this, "queryParams");
    this.configStore = configStore;
    this.reportContextStore = reportContextStore;
    this.historyService = historyService;
    this.selectedFilters = {};
    this.initialValues = {};
    this.hiddenKeys = {};
    this.queryParams = {};
    this.queryParams = this.historyService.getQuery();
  }
  get filterValues() {
    return this.queryParams.filters ? JSON.parse(this.queryParams.filters) : {};
  }
  setHiddenKeys(values) {
    this.hiddenKeys = values;
  }
  get schema() {
    var _a2, _b;
    const features = (_a2 = this.configStore.config) == null ? void 0 : _a2.features;
    const schema = ((_b = features == null ? void 0 : features.list) == null ? void 0 : _b.filters) || (features == null ? void 0 : features.filters);
    if (!schema)
      return [];
    const usedSchema = getUsedSchema({
      schema,
      filterOptions: this.filterOptions
    });
    return usedSchema.map((subItem) => {
      return subItem.filter((node) => {
        return !this.hiddenKeys[node.id];
      });
    });
  }
  get schemaAllKeys() {
    const flatMap = this.schema.flat();
    return flatMap.reduce((acc, item) => {
      if (item.options) {
        item.options.forEach((option) => {
          acc[option.value] = {
            ...item,
            display: option.display
          };
        });
      }
      if (!item.sub_filters) {
        acc[item.id] = item;
        return acc;
      }
      item.sub_filters.forEach((subItem) => {
        if (acc[subItem.id])
          return;
        acc[subItem.id] = subItem;
        if (subItem.options) {
          subItem.options.forEach((option) => {
            acc[option.value] = {
              ...subItem,
              display: option.display
            };
          });
        }
      });
      return acc;
    }, {});
  }
  initValues(values) {
    this.initialValues = {
      ...values
    };
    this.selectedFilters = {
      ...values
    };
  }
  changeFilter(id, value) {
    if (value) {
      this.selectedFilters[id] = value;
      return;
    }
    const { [id]: _, ...fields } = this.selectedFilters;
    this.selectedFilters = fields;
  }
  setFilterValues(values) {
    const query = this.queryParams;
    query.filters = JSON.stringify(values);
    this.historyService.replace({
      pathname: this.historyService.pathname,
      query
    }, void 0, {
      shallow: true
    });
  }
  dropValuesToInitial() {
    this.setFilterValues(this.initialValues);
  }
  clearFilters() {
    this.selectedFilters = Object.keys(this.initialValues).filter((key) => IGNORED_FILTERS[key]).reduce((newFilters, key) => {
      return {
        ...newFilters,
        [key]: this.initialValues[key]
      };
    }, {});
  }
  get totalFilters() {
    return this.calculateSum(this.filterValues);
  }
  get selectedFiltersTotal() {
    return this.calculateSum(this.selectedFilters);
  }
  calculateSum(keys) {
    return Object.keys(keys).reduce((sum, key) => {
      if (keys[key] === void 0)
        return sum;
      const section = keys[key];
      if (section.type === "str-list") {
        return sum + section.value.length;
      }
      return sum + 1;
    }, 0);
  }
  get filterOptions() {
    var _a2;
    return (_a2 = this.reportContextStore.basicReport) == null ? void 0 : _a2.report.filter_options;
  }
}, "FiltersStore");
FiltersStore = __decorate7([
  Store5(),
  __param5(0, Inject5()),
  __param5(1, Inject5()),
  __param5(2, Inject5()),
  __metadata5("design:type", Function),
  __metadata5("design:paramtypes", [
    typeof ConfigStore === "undefined" ? Object : ConfigStore,
    typeof ReportContextStore === "undefined" ? Object : ReportContextStore,
    typeof HistoryService2 === "undefined" ? Object : HistoryService2
  ])
], FiltersStore);

// src/modules/filter-search/interface/store/filter-search-store.ts
import { Store as Store6 } from "@nori/di";
var __decorate8 = function(decorators, target, key, desc) {
  var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
  if (typeof Reflect === "object" && typeof Reflect.decorate === "function")
    r = Reflect.decorate(decorators, target, key, desc);
  else
    for (var i = decorators.length - 1; i >= 0; i--)
      if (d = decorators[i])
        r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
  return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var FilterSearchStore = /* @__PURE__ */ __name(class FilterSearchStore2 {
  constructor() {
    __publicField(this, "isLoading", false);
    __publicField(this, "filters", {});
    __publicField(this, "searchValue", "");
    __publicField(this, "error", null);
    __publicField(this, "searchData", null);
  }
  setSearchValue(value) {
    this.searchValue = value;
  }
  setError(error) {
    this.error = error;
  }
  setSearchData(data) {
    this.searchData = data;
  }
  setFilters(filters) {
    this.filters = filters;
  }
  setIsLoading(value) {
    this.isLoading = value;
  }
  get isDataLoaded() {
    if (this.isLoading)
      return false;
    if (!this.searchData)
      return false;
    if (!this.searchValue)
      return false;
    return true;
  }
  get treeViewSearchPathFilter() {
    var _a2, _b;
    const stylist = (_b = (_a2 = this.filters) == null ? void 0 : _a2.stylist_id) == null ? void 0 : _b.value;
    if (!stylist)
      return [];
    return stylist.path;
  }
}, "FilterSearchStore");
FilterSearchStore = __decorate8([
  Store6()
], FilterSearchStore);

// src/modules/pagination/interface/store/pagination.store.ts
import { Inject as Inject6, Store as Store7 } from "@nori/di";
var __decorate9 = function(decorators, target, key, desc) {
  var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
  if (typeof Reflect === "object" && typeof Reflect.decorate === "function")
    r = Reflect.decorate(decorators, target, key, desc);
  else
    for (var i = decorators.length - 1; i >= 0; i--)
      if (d = decorators[i])
        r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
  return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata6 = function(k, v) {
  if (typeof Reflect === "object" && typeof Reflect.metadata === "function")
    return Reflect.metadata(k, v);
};
var __param6 = function(paramIndex, decorator) {
  return function(target, key) {
    decorator(target, key, paramIndex);
  };
};
var PaginationStore = /* @__PURE__ */ __name(class PaginationStore2 {
  constructor(reportContextStore) {
    __publicField(this, "reportContextStore");
    this.reportContextStore = reportContextStore;
  }
  get pageSize() {
    var _a2, _b, _c;
    return ((_c = (_b = (_a2 = this.reportContextStore.basicReport) == null ? void 0 : _a2.report) == null ? void 0 : _b.pagination) == null ? void 0 : _c.page_size) || 0;
  }
  get totalPages() {
    var _a2, _b, _c;
    const page = (_c = (_b = (_a2 = this.reportContextStore.basicReport) == null ? void 0 : _a2.report) == null ? void 0 : _b.pagination) == null ? void 0 : _c.last_page;
    if (!page)
      return 0;
    return page;
  }
  get currentPage() {
    var _a2, _b, _c;
    const page = (_c = (_b = (_a2 = this.reportContextStore.basicReport) == null ? void 0 : _a2.report) == null ? void 0 : _b.pagination) == null ? void 0 : _c.page;
    if (!page)
      return 0;
    return page - 1;
  }
}, "PaginationStore");
PaginationStore = __decorate9([
  Store7(),
  __param6(0, Inject6()),
  __metadata6("design:type", Function),
  __metadata6("design:paramtypes", [
    typeof ReportContextStore === "undefined" ? Object : ReportContextStore
  ])
], PaginationStore);

// src/modules/report-type/infra/config/config.adapter.ts
import { Adapter, Inject as Inject8 } from "@nori/di";
import { resultErr, resultOk as resultOk2 } from "@nori/result";

// src/modules/report-type/infra/config/config.data-provider.ts
import { RequestService } from "@nori/app-kit";
import { DataProvider, Inject as Inject7 } from "@nori/di";
var __decorate10 = function(decorators, target, key, desc) {
  var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
  if (typeof Reflect === "object" && typeof Reflect.decorate === "function")
    r = Reflect.decorate(decorators, target, key, desc);
  else
    for (var i = decorators.length - 1; i >= 0; i--)
      if (d = decorators[i])
        r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
  return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata7 = function(k, v) {
  if (typeof Reflect === "object" && typeof Reflect.metadata === "function")
    return Reflect.metadata(k, v);
};
var __param7 = function(paramIndex, decorator) {
  return function(target, key) {
    decorator(target, key, paramIndex);
  };
};
var configMap = {
  businessDashboard: "business-dashboard",
  activity: "business-dashboard",
  coaching: "coaching",
  loungeSummary: "loungeSummary",
  pipeline: "pipeline",
  recognition: "recognition",
  teamJumpStart: "teamJumpStart2015",
  teamJumpStart2019: "teamJumpStart2019",
  teamEventPipeline: "teamEventPipeline",
  hoopla2019: "hoopla2019",
  rookiesThatRock: "rookiesThatRock",
  teamBrightStart: "team-bright-start",
  // eslint-disable-next-line camelcase
  coaching_tree: "coaching_tree"
};
var CONFIG_API = "/report_service/reports/";
var ConfigDataProvider = /* @__PURE__ */ __name(class ConfigDataProvider2 {
  constructor(requestService) {
    __publicField(this, "requestService");
    this.requestService = requestService;
  }
  async getConfig(reportName, params) {
    const configName = configMap[reportName];
    const result = await this.requestService.secured.get(`${CONFIG_API}${configName}/config`, {
      querySearchParams: params
    });
    return result;
  }
}, "ConfigDataProvider");
ConfigDataProvider = __decorate10([
  DataProvider(),
  __param7(0, Inject7()),
  __metadata7("design:type", Function),
  __metadata7("design:paramtypes", [
    typeof RequestService === "undefined" ? Object : RequestService
  ])
], ConfigDataProvider);

// src/modules/report-type/infra/config/config.adapter.ts
var __decorate11 = function(decorators, target, key, desc) {
  var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
  if (typeof Reflect === "object" && typeof Reflect.decorate === "function")
    r = Reflect.decorate(decorators, target, key, desc);
  else
    for (var i = decorators.length - 1; i >= 0; i--)
      if (d = decorators[i])
        r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
  return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata8 = function(k, v) {
  if (typeof Reflect === "object" && typeof Reflect.metadata === "function")
    return Reflect.metadata(k, v);
};
var __param8 = function(paramIndex, decorator) {
  return function(target, key) {
    decorator(target, key, paramIndex);
  };
};
var ConfigAdapter = /* @__PURE__ */ __name(class ConfigAdapter2 {
  constructor(configDataProvider) {
    __publicField(this, "configDataProvider");
    this.configDataProvider = configDataProvider;
  }
  async getConfig(reportName, params) {
    try {
      const { ok, json } = await this.configDataProvider.getConfig(reportName, params);
      if (!ok)
        return resultErr(new ReportErrors.LoadConfig());
      return resultOk2(json);
    } catch (e) {
      return resultErr(new ReportErrors.LoadConfigUnexpected(e));
    }
  }
}, "ConfigAdapter");
ConfigAdapter = __decorate11([
  Adapter(),
  __param8(0, Inject8()),
  __metadata8("design:type", Function),
  __metadata8("design:paramtypes", [
    typeof ConfigDataProvider === "undefined" ? Object : ConfigDataProvider
  ])
], ConfigAdapter);

// src/modules/report-type/infra/report/report.adapter.ts
import { Adapter as Adapter2, Inject as Inject10 } from "@nori/di";
import { resultErr as resultErr2, resultOk as resultOk3 } from "@nori/result";

// src/infra/post-message.data-provider.ts
import { DataProvider as DataProvider2 } from "@nori/di";
import { isErr } from "@nori/result";
var __decorate12 = function(decorators, target, key, desc) {
  var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
  if (typeof Reflect === "object" && typeof Reflect.decorate === "function")
    r = Reflect.decorate(decorators, target, key, desc);
  else
    for (var i = decorators.length - 1; i >= 0; i--)
      if (d = decorators[i])
        r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
  return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var PostMessageDataProvider = /* @__PURE__ */ __name(class PostMessageDataProvider2 {
  sendMessage(value) {
    if (!window.parent)
      return;
    const message = {
      status: value
    };
    window.parent.postMessage(JSON.stringify(message), "*");
  }
  async handlePostMessage(callback) {
    const result = await callback();
    if (isErr(result)) {
      this.sendMessage("fail");
      return result;
    }
    this.sendMessage("ok");
    return result;
  }
}, "PostMessageDataProvider");
PostMessageDataProvider = __decorate12([
  DataProvider2()
], PostMessageDataProvider);

// src/modules/report-type/infra/report/report.data-provider.ts
import { RequestService as RequestService2 } from "@nori/app-kit";
import { DataProvider as DataProvider3, Inject as Inject9 } from "@nori/di";

// src/core/api.constants.ts
var DEFAULT_TIMEOUT = Number(process.env["IBO_DASH_REQUEST_TIMEOUT"] || 3e4);
var DEFAULT_API_ENDPOINT = "/report_service/ibo/";

// src/modules/route/interface/get-dev-use-case.ts
var devUseCase = getQueryParam("useCase");
var isDevUseCase = !!devUseCase;
function getDevUseCase({ period, reportName }) {
  const urlParts = [
    `${reportName}_usecase_`,
    period && reportName === "businessDashboard" ? "period_" : "",
    `${devUseCase}.json`
  ];
  return urlParts.join("");
}
__name(getDevUseCase, "getDevUseCase");

// src/modules/route/interface/get-report-url.ts
function getReportUrl({ reportName, stylistId, period, apiEndpoint }) {
  if (isDevUseCase) {
    return getDevUseCase({
      period,
      reportName
    });
  }
  return `${apiEndpoint}${stylistId}/reports/${reportName}`;
}
__name(getReportUrl, "getReportUrl");

// src/modules/filter/interface/service/get-filters-as-params.ts
import { NoriDate } from "@nori/date";
function getFiltersAsParams(filters) {
  return Object.keys(filters).reduce((acc, key) => {
    const filter = filters[key];
    if (filter.type === "range-date") {
      acc[key] = {
        min: filter.value.min ? new NoriDate(filter.value.min).formatInTZ("yyyy-MM-dd") : void 0,
        max: filter.value.max ? new NoriDate(filter.value.max).formatInTZ("yyyy-MM-dd") : void 0
      };
      return acc;
    }
    if (filter.type === "stylist") {
      acc[key] = filter.value.id;
      return acc;
    }
    acc[key] = filter.value;
    return acc;
  }, {});
}
__name(getFiltersAsParams, "getFiltersAsParams");

// src/modules/report-type/infra/report/get-filters.ts
function getFilters(filters) {
  const paramsFilters = getFiltersAsParams(filters);
  return JSON.stringify(paramsFilters);
}
__name(getFilters, "getFilters");

// src/modules/report-type/infra/report/report.data-provider.ts
var __decorate13 = function(decorators, target, key, desc) {
  var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
  if (typeof Reflect === "object" && typeof Reflect.decorate === "function")
    r = Reflect.decorate(decorators, target, key, desc);
  else
    for (var i = decorators.length - 1; i >= 0; i--)
      if (d = decorators[i])
        r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
  return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata9 = function(k, v) {
  if (typeof Reflect === "object" && typeof Reflect.metadata === "function")
    return Reflect.metadata(k, v);
};
var __param9 = function(paramIndex, decorator) {
  return function(target, key) {
    decorator(target, key, paramIndex);
  };
};
var reportNameMap = {
  activity: "businessDashboard",
  teamBrightStart: "team-bright-start"
};
var ReportDataProvider = /* @__PURE__ */ __name(class ReportDataProvider2 {
  constructor(requestService) {
    __publicField(this, "requestService");
    this.requestService = requestService;
  }
  async handleRequest(url, params) {
    const { filters } = params;
    const stringFilters = filters ? getFilters(filters) : void 0;
    const request = await this.requestService.secured.get(url, {
      querySearchParams: {
        ...params.customParams,
        stylistId: params.stylistId,
        uplineId: params.uplineId,
        filters: stringFilters,
        period: params.period,
        // eslint-disable-next-line camelcase
        sort_by: params.sortBy,
        // eslint-disable-next-line camelcase
        sort_direction: params.sortDirection,
        // eslint-disable-next-line camelcase
        page_size: params.pageSize,
        page: params.page,
        //      reportName: params.reportName,
        expandId: params.expandId
      }
    });
    return request;
  }
  async getReport(loginId, params) {
    const { stylistId, reportName, uplineId } = params;
    const defaultLoginId = sanitizeUpstyleId(loginId);
    const usedUplineId = uplineId ? sanitizeUpstyleId(uplineId) : defaultLoginId;
    const usedStylistId = stylistId ? sanitizeUpstyleId(stylistId) : defaultLoginId;
    const usedReportName = reportNameMap[reportName] || reportName;
    const url = getReportUrl({
      reportName: usedReportName,
      period: params.period,
      stylistId: usedStylistId,
      apiEndpoint: DEFAULT_API_ENDPOINT
    });
    const res = await this.handleRequest(url, {
      ...params,
      stylistId: usedStylistId,
      uplineId: usedUplineId
    });
    return res;
  }
}, "ReportDataProvider");
ReportDataProvider = __decorate13([
  DataProvider3(),
  __param9(0, Inject9()),
  __metadata9("design:type", Function),
  __metadata9("design:paramtypes", [
    typeof RequestService2 === "undefined" ? Object : RequestService2
  ])
], ReportDataProvider);

// src/modules/report-type/infra/report/report.adapter.ts
var __decorate14 = function(decorators, target, key, desc) {
  var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
  if (typeof Reflect === "object" && typeof Reflect.decorate === "function")
    r = Reflect.decorate(decorators, target, key, desc);
  else
    for (var i = decorators.length - 1; i >= 0; i--)
      if (d = decorators[i])
        r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
  return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata10 = function(k, v) {
  if (typeof Reflect === "object" && typeof Reflect.metadata === "function")
    return Reflect.metadata(k, v);
};
var __param10 = function(paramIndex, decorator) {
  return function(target, key) {
    decorator(target, key, paramIndex);
  };
};
var ReportAdapter = /* @__PURE__ */ __name(class ReportAdapter2 {
  constructor(reportDataProvider, postMessageDataProvider) {
    __publicField(this, "reportDataProvider");
    __publicField(this, "postMessageDataProvider");
    this.reportDataProvider = reportDataProvider;
    this.postMessageDataProvider = postMessageDataProvider;
  }
  async getReport(loginId, params) {
    const res = await this.postMessageDataProvider.handlePostMessage(async () => {
      try {
        const { ok, json } = await this.reportDataProvider.getReport(loginId, params);
        if (!ok)
          return resultErr2(new ReportErrors.LoadReportNotExist(json));
        if (json.success) {
          return resultOk3(json);
        }
        if (!("error" in json)) {
          return resultErr2(new ReportErrors.LoadDataUnknownError(json));
        }
        if (json.error.includes(`Consultant ${params.stylistId} not found`)) {
          return resultErr2(new ReportErrors.LoadDataUserNotFound(json));
        }
        return resultErr2(new ReportErrors.LoadData(json));
      } catch (e) {
        return resultErr2(new ReportErrors.LoadDataUnexpected(e));
      }
    });
    return res;
  }
}, "ReportAdapter");
ReportAdapter = __decorate14([
  Adapter2(),
  __param10(0, Inject10()),
  __param10(1, Inject10()),
  __metadata10("design:type", Function),
  __metadata10("design:paramtypes", [
    typeof ReportDataProvider === "undefined" ? Object : ReportDataProvider,
    typeof PostMessageDataProvider === "undefined" ? Object : PostMessageDataProvider
  ])
], ReportAdapter);

// src/modules/report-type/interface/store/period.store.ts
import { Inject as Inject11, Store as Store8 } from "@nori/di";
var __decorate15 = function(decorators, target, key, desc) {
  var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
  if (typeof Reflect === "object" && typeof Reflect.decorate === "function")
    r = Reflect.decorate(decorators, target, key, desc);
  else
    for (var i = decorators.length - 1; i >= 0; i--)
      if (d = decorators[i])
        r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
  return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata11 = function(k, v) {
  if (typeof Reflect === "object" && typeof Reflect.metadata === "function")
    return Reflect.metadata(k, v);
};
var __param11 = function(paramIndex, decorator) {
  return function(target, key) {
    decorator(target, key, paramIndex);
  };
};
var PeriodStore = /* @__PURE__ */ __name(class PeriodStore2 {
  constructor(reportContextStore) {
    __publicField(this, "reportContextStore");
    this.reportContextStore = reportContextStore;
  }
  isPeriodField(report) {
    return "period_end" in report.report;
  }
  get period() {
    const report = this.reportContextStore.basicReport;
    if (!report)
      return "";
    if (!report.report)
      return "";
    if (this.isPeriodField(report)) {
      return report.report.period_end;
    }
    return report.report.period;
  }
  get periodOptions() {
    var _a2, _b;
    return (_b = (_a2 = this.reportContextStore.basicReport) == null ? void 0 : _a2.report.filter_options) == null ? void 0 : _b.periods;
  }
}, "PeriodStore");
PeriodStore = __decorate15([
  Store8(),
  __param11(0, Inject11()),
  __metadata11("design:type", Function),
  __metadata11("design:paramtypes", [
    typeof ReportContextStore === "undefined" ? Object : ReportContextStore
  ])
], PeriodStore);

// src/modules/sorting/interface/sorting.store.ts
import { Inject as Inject12, Store as Store9 } from "@nori/di";
var __decorate16 = function(decorators, target, key, desc) {
  var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
  if (typeof Reflect === "object" && typeof Reflect.decorate === "function")
    r = Reflect.decorate(decorators, target, key, desc);
  else
    for (var i = decorators.length - 1; i >= 0; i--)
      if (d = decorators[i])
        r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
  return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata12 = function(k, v) {
  if (typeof Reflect === "object" && typeof Reflect.metadata === "function")
    return Reflect.metadata(k, v);
};
var __param12 = function(paramIndex, decorator) {
  return function(target, key) {
    decorator(target, key, paramIndex);
  };
};
var SortingStore = /* @__PURE__ */ __name(class SortingStore2 {
  constructor(reportContextStore, configStore) {
    __publicField(this, "reportContextStore");
    __publicField(this, "configStore");
    this.reportContextStore = reportContextStore;
    this.configStore = configStore;
  }
  get sortDirection() {
    var _a2, _b, _c, _d, _e;
    const sortDirection = (_c = (_b = (_a2 = this.reportContextStore.basicReport) == null ? void 0 : _a2.report) == null ? void 0 : _b.pagination) == null ? void 0 : _c.sort_direction;
    if (sortDirection)
      return sortDirection;
    return (_e = (_d = this.configStore.config) == null ? void 0 : _d.features) == null ? void 0 : _e.default_sorting_column_direction;
  }
  get sortField() {
    var _a2, _b, _c, _d, _e;
    const sortField = (_c = (_b = (_a2 = this.reportContextStore.basicReport) == null ? void 0 : _a2.report) == null ? void 0 : _b.pagination) == null ? void 0 : _c.sort_by;
    if (sortField)
      return sortField;
    return (_e = (_d = this.configStore.config) == null ? void 0 : _d.features) == null ? void 0 : _e.default_sorting_column;
  }
}, "SortingStore");
SortingStore = __decorate16([
  Store9(),
  __param12(0, Inject12()),
  __param12(1, Inject12()),
  __metadata12("design:type", Function),
  __metadata12("design:paramtypes", [
    typeof ReportContextStore === "undefined" ? Object : ReportContextStore,
    typeof ConfigStore === "undefined" ? Object : ConfigStore
  ])
], SortingStore);

// src/modules/report-type/interface/service/load-data.service.ts
var __decorate17 = function(decorators, target, key, desc) {
  var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
  if (typeof Reflect === "object" && typeof Reflect.decorate === "function")
    r = Reflect.decorate(decorators, target, key, desc);
  else
    for (var i = decorators.length - 1; i >= 0; i--)
      if (d = decorators[i])
        r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
  return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata13 = function(k, v) {
  if (typeof Reflect === "object" && typeof Reflect.metadata === "function")
    return Reflect.metadata(k, v);
};
var __param13 = function(paramIndex, decorator) {
  return function(target, key) {
    decorator(target, key, paramIndex);
  };
};
var logger = createLogger("load-data.service");
var LoadDataService = /* @__PURE__ */ __name(class LoadDataService2 {
  constructor(reportAdapter, reportContextStore, reportCoachingStore, loadAuthService, sortingStore, paginationStore, filtersStore, periodStore, configStore, configAdapter, notifyAction, treeViewStore, filterSearchStore) {
    __publicField(this, "reportAdapter");
    __publicField(this, "reportContextStore");
    __publicField(this, "reportCoachingStore");
    __publicField(this, "loadAuthService");
    __publicField(this, "sortingStore");
    __publicField(this, "paginationStore");
    __publicField(this, "filtersStore");
    __publicField(this, "periodStore");
    __publicField(this, "configStore");
    __publicField(this, "configAdapter");
    __publicField(this, "notifyAction");
    __publicField(this, "treeViewStore");
    __publicField(this, "filterSearchStore");
    this.reportAdapter = reportAdapter;
    this.reportContextStore = reportContextStore;
    this.reportCoachingStore = reportCoachingStore;
    this.loadAuthService = loadAuthService;
    this.sortingStore = sortingStore;
    this.paginationStore = paginationStore;
    this.filtersStore = filtersStore;
    this.periodStore = periodStore;
    this.configStore = configStore;
    this.configAdapter = configAdapter;
    this.notifyAction = notifyAction;
    this.treeViewStore = treeViewStore;
    this.filterSearchStore = filterSearchStore;
  }
  async loadConfig(params) {
    const configResult = await this.configAdapter.getConfig(this.reportContextStore.reportName, params);
    if (isErr2(configResult)) {
      logger.error(configResult.error.key, configResult.error);
      this.setError(configResult.error);
      return;
    }
    this.configStore.setConfig(configResult.data);
  }
  async handleReloadConfig(nextReportOptions) {
    const nextPeriod = nextReportOptions.period || "";
    if (nextPeriod === this.periodStore.period)
      return;
    await this.loadConfig({
      period: nextReportOptions.period
    });
  }
  async handleLoadReport(params) {
    const { options, name, isInitial } = params || {};
    const defaultOptions = isInitial ? {
      page: 0,
      filters: this.filtersStore.filterValues
    } : this.getDefaultOptions();
    this.reportContextStore.setIsReportDataLoading(true);
    const authResult = await this.loadAuthService.getAuthData();
    if (isErr2(authResult)) {
      this.setError(authResult.error);
      this.reportContextStore.setIsReportDataLoading(false);
      return;
    }
    this.reportContextStore.setLoginId(authResult.data.loginId);
    const reportName = this.getReportName(name);
    const nextReportOptions = {
      ...defaultOptions,
      ...options
    };
    if (reportName === "coaching_tree") {
      const commissionFilter = this.treeViewStore.getFilterByCommissionGroup();
      nextReportOptions.filters = {
        ...nextReportOptions.filters,
        ...commissionFilter
      };
    }
    await this.handleReloadConfig(nextReportOptions);
    await this.loadReport(reportName, nextReportOptions);
    if (reportName === "coaching_tree") {
      const { selectedUserId } = this.reportContextStore;
      const paths = [
        selectedUserId || authResult.data.loginId,
        ...this.filterSearchStore.treeViewSearchPathFilter
      ];
      if (paths.length === 1) {
        await this.loadStylistChildren(selectedUserId || authResult.data.loginId);
      }
      if (paths.length) {
        await this.loadPathToUser(paths);
      }
    }
    this.reportContextStore.setIsReportDataLoading(false);
  }
  async loadPathToUser(paths) {
    const currentPath = [];
    for (let i = 0; i < paths.length; i++) {
      const stylistId = paths[i];
      const nextStylistId = paths[i + 1];
      await this.loadStylistChildren(stylistId, nextStylistId);
      currentPath.push(stylistId);
      this.updateTreeViewForPath(currentPath);
    }
  }
  updateTreeViewForPath(path) {
    path.forEach((id, index) => {
      if (index === path.length - 1) {
        this.treeViewStore.toggleTreeItem(id, true);
      } else {
        this.treeViewStore.addTreeParent(id, path[index + 1], path.slice(0, index));
      }
    });
  }
  getReportName(name) {
    let reportName = name ? name : this.reportContextStore.reportName;
    if (reportName === "coaching" && this.reportCoachingStore.viewType === "tree") {
      reportName = "coaching_tree";
    }
    return reportName;
  }
  getDefaultOptions() {
    return {
      pageSize: this.paginationStore.pageSize,
      page: this.paginationStore.currentPage,
      period: this.periodStore.period,
      filters: this.filtersStore.filterValues,
      sortBy: this.sortingStore.sortField,
      sortDirection: this.sortingStore.sortDirection
    };
  }
  async loadReport(reportName, reportOptions) {
    this.setError(void 0);
    const result = await this.getReport({
      reportName,
      reportOptions: reportOptions || {}
    });
    if (isErr2(result)) {
      logger.error(result.error.key, result.error);
      this.setError(result.error);
      return;
    }
    this.reportContextStore.setReport(result.data);
  }
  setError(error) {
    this.reportContextStore.setError(error);
    const errorTitle = this.reportContextStore.errorTitle;
    if (!errorTitle)
      return;
    this.notifyAction.handleAddNotify({
      type: "danger",
      title: errorTitle
    });
  }
  async getReport({ reportName, reportOptions }) {
    const { selectedUserId, currentUserId, loginId } = this.reportContextStore;
    const result = await this.reportAdapter.getReport(loginId, {
      reportName,
      stylistId: selectedUserId,
      uplineId: currentUserId,
      ...reportOptions
    });
    return result;
  }
  async loadStylistChildren(stylistId, nextStylistId) {
    const { currentUserId } = this.reportContextStore;
    const commissionFilter = this.treeViewStore.getFilterByCommissionGroup();
    const filters = {
      ...this.getDefaultOptions().filters,
      ...commissionFilter
    };
    const options = {
      ...this.getDefaultOptions(),
      filters
    };
    this.treeViewStore.toggleTreeItemLoading(stylistId, true);
    const result = await this.reportAdapter.getReport(stylistId, {
      reportName: "coaching_tree",
      uplineId: currentUserId,
      expandId: stylistId,
      ...options
    });
    this.treeViewStore.toggleTreeItemLoading(stylistId, false);
    if (isErr2(result)) {
      logger.error(result.error.key, result.error);
      this.setError(result.error);
      return;
    }
    if (nextStylistId) {
      this.reportCoachingStore.addFilteredChildrenForCurrentReport(stylistId, (result == null ? void 0 : result.data).report.stylists, nextStylistId);
      return;
    }
    this.reportCoachingStore.addChildrenForCurrentReport(stylistId, (result == null ? void 0 : result.data).report.stylists);
  }
}, "LoadDataService");
LoadDataService = __decorate17([
  Service2(),
  __param13(0, Inject13()),
  __param13(1, Inject13()),
  __param13(2, Inject13()),
  __param13(3, Inject13()),
  __param13(4, Inject13()),
  __param13(5, Inject13()),
  __param13(6, Inject13()),
  __param13(7, Inject13()),
  __param13(8, Inject13()),
  __param13(9, Inject13()),
  __param13(10, Inject13()),
  __param13(11, Inject13()),
  __param13(12, Inject13()),
  __metadata13("design:type", Function),
  __metadata13("design:paramtypes", [
    typeof ReportAdapter === "undefined" ? Object : ReportAdapter,
    typeof ReportContextStore === "undefined" ? Object : ReportContextStore,
    typeof ReportCoachingStore === "undefined" ? Object : ReportCoachingStore,
    typeof LoadAuthService === "undefined" ? Object : LoadAuthService,
    typeof SortingStore === "undefined" ? Object : SortingStore,
    typeof PaginationStore === "undefined" ? Object : PaginationStore,
    typeof FiltersStore === "undefined" ? Object : FiltersStore,
    typeof PeriodStore === "undefined" ? Object : PeriodStore,
    typeof ConfigStore === "undefined" ? Object : ConfigStore,
    typeof ConfigAdapter === "undefined" ? Object : ConfigAdapter,
    typeof NotifyAction === "undefined" ? Object : NotifyAction,
    typeof TreeViewStore === "undefined" ? Object : TreeViewStore,
    typeof FilterSearchStore === "undefined" ? Object : FilterSearchStore
  ])
], LoadDataService);

// src/modules/route/interface/action/route-action.ts
import { Action as Action2, Inject as Inject14 } from "@nori/di";

// src/modules/route/interface/store/route-store.ts
import { Store as Store10 } from "@nori/di";
var __decorate18 = function(decorators, target, key, desc) {
  var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
  if (typeof Reflect === "object" && typeof Reflect.decorate === "function")
    r = Reflect.decorate(decorators, target, key, desc);
  else
    for (var i = decorators.length - 1; i >= 0; i--)
      if (d = decorators[i])
        r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
  return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata14 = function(k, v) {
  if (typeof Reflect === "object" && typeof Reflect.metadata === "function")
    return Reflect.metadata(k, v);
};
var RouteStore = /* @__PURE__ */ __name(class RouteStore2 {
  constructor() {
    __publicField(this, "queryParams", {});
    // used for synchronize router with parent module
    __publicField(this, "updateUrlCallback");
    if (isServer)
      return;
    this.updateQueryParams(window.location.search);
    const onPop = /* @__PURE__ */ __name(() => this.updateQueryParams(window.location.search), "onPop");
    window.addEventListener("popstate", onPop);
  }
  setUrlUpdateCallback(callback) {
    this.updateUrlCallback = callback;
  }
  updateQueryParams(queryString) {
    this.queryParams = Object.fromEntries(new URLSearchParams(queryString));
  }
  pushStateParams(params) {
    const existedQueryParams = new URLSearchParams(window.location.search);
    Object.keys(params).forEach((key) => {
      if (existedQueryParams.has(key)) {
        existedQueryParams.delete(key);
      }
      existedQueryParams.append(key, params[key]);
    });
    this.pushState(existedQueryParams.toString());
  }
  pushState(stringParams) {
    var _a2;
    const url = `${window.location.pathname}?${stringParams}`;
    window.history.pushState("", "", url);
    this.updateQueryParams(stringParams);
    (_a2 = this.updateUrlCallback) == null ? void 0 : _a2.call(this, url);
  }
  removeStateParams(keys) {
    const queryParams = new URLSearchParams(window.location.search);
    keys.forEach((key) => {
      queryParams.delete(key);
    });
    const stringParams = queryParams.toString();
    this.pushState(stringParams);
  }
}, "RouteStore");
RouteStore = __decorate18([
  Store10(),
  __metadata14("design:type", Function),
  __metadata14("design:paramtypes", [])
], RouteStore);

// src/modules/route/interface/action/route-action.ts
var __decorate19 = function(decorators, target, key, desc) {
  var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
  if (typeof Reflect === "object" && typeof Reflect.decorate === "function")
    r = Reflect.decorate(decorators, target, key, desc);
  else
    for (var i = decorators.length - 1; i >= 0; i--)
      if (d = decorators[i])
        r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
  return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata15 = function(k, v) {
  if (typeof Reflect === "object" && typeof Reflect.metadata === "function")
    return Reflect.metadata(k, v);
};
var __param14 = function(paramIndex, decorator) {
  return function(target, key) {
    decorator(target, key, paramIndex);
  };
};
var RouteAction = /* @__PURE__ */ __name(class RouteAction2 {
  constructor(routeStore) {
    __publicField(this, "routeStore");
    this.routeStore = routeStore;
  }
  setUrlUpdateCallback(callback) {
    this.routeStore.setUrlUpdateCallback(callback);
  }
  pushStateParams(params) {
    this.routeStore.pushStateParams(params);
  }
  removeStateParams(keys) {
    this.routeStore.removeStateParams(keys);
  }
}, "RouteAction");
RouteAction = __decorate19([
  Action2(),
  __param14(0, Inject14()),
  __metadata15("design:type", Function),
  __metadata15("design:paramtypes", [
    typeof RouteStore === "undefined" ? Object : RouteStore
  ])
], RouteAction);

// src/modules/report-type/interface/service/set-document-title.ts
var defaultTitle = "IBO Dashboard";
var reportToTitle = {
  teamJumpStart: "Team Jumpstart Report"
};
function setDocumentTitle({ reportName }) {
  const title = reportToTitle[reportName] || defaultTitle;
  document.title = title;
}
__name(setDocumentTitle, "setDocumentTitle");

// src/modules/report-type/interface/actions/manage-report.action.ts
var __decorate20 = function(decorators, target, key, desc) {
  var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
  if (typeof Reflect === "object" && typeof Reflect.decorate === "function")
    r = Reflect.decorate(decorators, target, key, desc);
  else
    for (var i = decorators.length - 1; i >= 0; i--)
      if (d = decorators[i])
        r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
  return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata16 = function(k, v) {
  if (typeof Reflect === "object" && typeof Reflect.metadata === "function")
    return Reflect.metadata(k, v);
};
var __param15 = function(paramIndex, decorator) {
  return function(target, key) {
    decorator(target, key, paramIndex);
  };
};
var ManageReportAction = /* @__PURE__ */ __name(class ManageReportAction2 {
  constructor(reportContextStore, loadDataService, routeAction) {
    __publicField(this, "reportContextStore");
    __publicField(this, "loadDataService");
    __publicField(this, "routeAction");
    this.reportContextStore = reportContextStore;
    this.loadDataService = loadDataService;
    this.routeAction = routeAction;
  }
  async handleInitLoad({ reportName, brand, envRun, type, selectedUserId, updateUrlCallback }) {
    if (type === "app") {
      setDocumentTitle({
        reportName
      });
    }
    if (!reportName)
      return;
    this.routeAction.setUrlUpdateCallback(updateUrlCallback);
    this.reportContextStore.setIsInitialLoading(true);
    this.reportContextStore.setError(void 0);
    this.reportContextStore.setReport(void 0);
    this.reportContextStore.setInitialParams({
      reportName,
      brand,
      envRun,
      selectedUserId
    });
    await this.loadDataService.loadConfig();
    this.reportContextStore.setIsInitialLoading(false);
  }
}, "ManageReportAction");
ManageReportAction = __decorate20([
  Action3(),
  __param15(0, Inject15()),
  __param15(1, Inject15()),
  __param15(2, Inject15()),
  __metadata16("design:type", Function),
  __metadata16("design:paramtypes", [
    typeof ReportContextStore === "undefined" ? Object : ReportContextStore,
    typeof LoadDataService === "undefined" ? Object : LoadDataService,
    typeof RouteAction === "undefined" ? Object : RouteAction
  ])
], ManageReportAction);

// src/modules/report-type/interface/use-report-context.ts
var { useModuleContext: useReportContext } = hookContextFactory({
  configStore: ConfigStore,
  manageReportAction: ManageReportAction,
  manageTabsAction: ManageTabsAction,
  reportContextStore: ReportContextStore,
  periodStore: PeriodStore
});

export {
  __name,
  __publicField,
  React,
  isServer,
  createLogger,
  SELECTED_USER_ID_KEY,
  REPORT_NAME_KEY,
  sanitizeUpstyleId,
  DEFAULT_REPORT,
  ReportContextStore,
  ConfigStore,
  LoadAuthService,
  FiltersStore,
  FilterSearchStore,
  PaginationStore,
  PostMessageDataProvider,
  DEFAULT_API_ENDPOINT,
  getQueryParam,
  getReportUrl,
  getFiltersAsParams,
  PeriodStore,
  TreeViewStore,
  ReportCoachingStore,
  SortingStore,
  LoadDataService,
  RouteStore,
  RouteAction,
  useReportContext,
  PageLoader
};
