import { Service } from '@nori/di'

import { CouponCodeErrors } from '../../core/coupon-code-errors'

import type { CouponCodeErrorInstance } from '../../core/coupon-code-errors'

@Service()
export class CouponCodeErrorMapper {
  toErrors(status: number, json: any): CouponCodeErrorInstance {
    switch (status) {
      case 400:
        return new CouponCodeErrors.BadRequest(json.error.message)

      case 404:
        return new CouponCodeErrors.NotFound(json.errors)

      default:
        return new CouponCodeErrors.UnknownError(json)
    }
  }
}
