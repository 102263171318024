import { observer } from '@nori/di'
import { useTranslations } from '@nori/lang-i18n'
import { Checkbox } from '@nori/ui-kit'
import { useAutosaveContext } from '~/modules/autosave/interface/use-autosave-context'

type Props = {
  isContactDetailsPage?: boolean
}

export const NextOrderCredits = observer(({ isContactDetailsPage }: Props) => {
  const t = useTranslations('autosaveSettings.rewards')
  const { nextOrderStore, nextOrderAction } = useAutosaveContext()
  const checkBoxLabel = isContactDetailsPage
    ? t('applyCredits')
    : t('applyMyCredits')

  if (!nextOrderStore.nextOrder) return null
  return (
    <div>
      <div className="mt-3 flex items-center gap-1">
        <Checkbox
          alignment="top"
          value={nextOrderStore.nextOrder.applyCredits}
          onChange={nextOrderAction.handleToggleShouldApplyCredits}
        >
          <span className="text-sm font-medium text-primary-900 lg:text-base">
            {checkBoxLabel}
          </span>
        </Checkbox>
      </div>
      <span className="mt-2 block pl-6 text-xs font-medium text-primary-700 lg:pl-0">
        {t('creditsLowerFreeShipping')}
      </span>
    </div>
  )
})
