var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __name = (target, value) => __defProp(target, "name", { value, configurable: true });
var __publicField = (obj, key, value) => {
  __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
  return value;
};

// react-import.js
import React from "react";

// src/interface/actions/notify.action.ts
import { Action, Inject } from "@nori/di";

// src/interface/store/notify.store.ts
import { nanoid } from "nanoid";
import { Store } from "@nori/di";
import { sleep } from "@nori/ui-kit";
var __decorate = function(decorators, target, key, desc) {
  var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
  if (typeof Reflect === "object" && typeof Reflect.decorate === "function")
    r = Reflect.decorate(decorators, target, key, desc);
  else
    for (var i = decorators.length - 1; i >= 0; i--)
      if (d = decorators[i])
        r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
  return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var DEFAULT_TIMEOUT = 5e3;
var NotifyStore = /* @__PURE__ */ __name(class NotifyStore2 {
  constructor() {
    __publicField(this, "notifyList", []);
  }
  async addNotify(params) {
    const { timeout = DEFAULT_TIMEOUT } = params;
    const id = `${nanoid()}`;
    this.notifyList.push({
      id,
      type: params.type,
      dataTestId: params.dataTestId,
      onClose: params.onClose,
      title: params.title
    });
    if (timeout === 0)
      return;
    await sleep(timeout);
    this.removeNotify(id);
  }
  removeNotify(id) {
    const index = this.notifyList.findIndex((item) => item.id === id);
    if (index === -1)
      return;
    const node = this.notifyList[index];
    if (node == null ? void 0 : node.onClose)
      node.onClose();
    this.notifyList.splice(index, 1);
  }
}, "NotifyStore");
NotifyStore = __decorate([
  Store()
], NotifyStore);

// src/interface/actions/notify.action.ts
var __decorate2 = function(decorators, target, key, desc) {
  var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
  if (typeof Reflect === "object" && typeof Reflect.decorate === "function")
    r = Reflect.decorate(decorators, target, key, desc);
  else
    for (var i = decorators.length - 1; i >= 0; i--)
      if (d = decorators[i])
        r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
  return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = function(k, v) {
  if (typeof Reflect === "object" && typeof Reflect.metadata === "function")
    return Reflect.metadata(k, v);
};
var __param = function(paramIndex, decorator) {
  return function(target, key) {
    decorator(target, key, paramIndex);
  };
};
var NotifyAction = /* @__PURE__ */ __name(class NotifyAction2 {
  constructor(notifyStore) {
    __publicField(this, "notifyStore");
    this.notifyStore = notifyStore;
  }
  handleAddNotify(params) {
    this.notifyStore.addNotify(params);
  }
  handleRemoveNotify(id) {
    this.notifyStore.removeNotify(id);
  }
}, "NotifyAction");
NotifyAction = __decorate2([
  Action(),
  __param(0, Inject()),
  __metadata("design:type", Function),
  __metadata("design:paramtypes", [
    typeof NotifyStore === "undefined" ? Object : NotifyStore
  ])
], NotifyAction);

// src/interface/context/use-notify-context.ts
import { hookContextFactory } from "@nori/di";
var { useModuleContext: useNotifyContext } = hookContextFactory({
  notifyAction: NotifyAction,
  notifyStore: NotifyStore
});

// src/ui/notify-container.tsx
import { observer } from "@nori/di";
import { Portal, Toast } from "@nori/ui-kit";
var NotifyContainer = observer(() => {
  const { notifyStore, notifyAction } = useNotifyContext();
  const list = notifyStore.notifyList;
  if (list.length === 0)
    return null;
  return /* @__PURE__ */ React.createElement(Portal, null, /* @__PURE__ */ React.createElement("div", {
    className: "fixed left-0 right-0 top-0 z-50"
  }, /* @__PURE__ */ React.createElement("div", {
    className: "absolute left-2/4 top-4 flex max-w-full -translate-x-2/4 flex-col gap-2"
  }, list.map((notify) => {
    return /* @__PURE__ */ React.createElement("div", {
      key: notify.id
    }, /* @__PURE__ */ React.createElement(Toast, {
      "data-test-id": notify.dataTestId,
      type: notify.type,
      onClose: () => notifyAction.handleRemoveNotify(notify.id)
    }, notify.title));
  }))));
});
export {
  NotifyAction,
  NotifyContainer,
  useNotifyContext
};
