import { useCallback } from 'react';
import { getCentsValue } from './get-cents-value';
import { getIntegerValue } from './get-integer-value';
export function useHandleChange({ onChange , type , min , max  }) {
    const handleChange = useCallback((ev)=>{
        const newValue = ev.currentTarget.value;
        if (type === 'money' && newValue) {
            const centsValue = getCentsValue(newValue, min);
            if (centsValue === undefined) {
                return;
            }
            if (typeof max === 'number' && centsValue > max) {
                onChange == null ? void 0 : onChange(max);
                return;
            }
            onChange == null ? void 0 : onChange(centsValue);
            return;
        }
        if (type === 'integer' && newValue) {
            const integerValue = getIntegerValue({
                value: newValue.toString(),
                max,
                min
            });
            onChange == null ? void 0 : onChange(integerValue);
            return;
        }
        const stringifiedValue = newValue == null ? void 0 : newValue.toString();
        if (type === 'password-numeric' && stringifiedValue) {
            const numericValue = stringifiedValue.replace(/\D/g, '');
            onChange == null ? void 0 : onChange(numericValue);
            return;
        }
        onChange == null ? void 0 : onChange(newValue);
    }, [
        onChange
    ]);
    return {
        handleChange
    };
}
