import { ProductListService, ProductTypeCode } from '@nori/app-kit'
import { Inject, Service } from '@nori/di'

import type {
  ProductListItem,
  ProductListItemStyleArray,
  ProductListValueObject,
} from '~/modules/products/core/product-list.value-object'

@Service()
export class ProductListMapper {
  constructor(
    @Inject() private readonly productListService: ProductListService
  ) {}

  toProductListValueObject(data: any): ProductListValueObject {
    return this.productListService.processProductList({
      data,
      itemMapper: this.toProductListItem.bind(this),
    })
  }

  private toProductListItem(data: any): ProductListItem {
    switch (data.type_code) {
      case ProductTypeCode.Bundle:
        return this.productListService.processCommonBundle(data)
      case ProductTypeCode.Style:
        return this.productListService.processCommonStyle(data)
      case ProductTypeCode.Array:
        return this.toStyleArrayEntity(data)
      default:
        return data
    }
  }

  private toStyleArrayEntity(item: any): ProductListItemStyleArray {
    return {
      typeCode: ProductTypeCode.Array,
      styles: item.styles_group.map((item: any) =>
        this.productListService.processCommonStyle(item)
      ),
    }
  }
}
