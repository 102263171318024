import {
  CartService,
  getBaseUrl,
  RequestService,
  SERVICE_NAMES,
} from '@nori/app-kit'
import { Adapter, Inject } from '@nori/di'
import { resultErr, resultOk } from '@nori/result'

import { CartContactPartyErrors } from '../core/cart-contact-part.errors'

import { CartContactErrorMapper } from './mappers/cart-contact.errors.mapper'
import { CartContactMapper } from './mappers/cart-contact.mapper'

import type { CartEntity } from '@nori/app-kit'
import type { PromiseResult } from '@nori/result'
import type { CartContactPartyErrorInstance } from '../core/cart-contact-part.errors'
import type { AddContactToCartInput } from './types/add-contact-to-cart.input'
import type { AddToMailingListInput } from './types/add-to-mailing-list.input'

@Adapter()
export class CartContactAdapter {
  baseUrl = getBaseUrl(SERVICE_NAMES.CART_SERVICE)

  public constructor(
    @Inject() private readonly requestService: RequestService,
    @Inject() private readonly cartService: CartService
  ) {}

  async addContactToOrder(
    input: AddContactToCartInput
  ): PromiseResult<CartEntity, CartContactPartyErrorInstance> {
    const url = `${this.baseUrl}/carts/${input.cartId}`

    try {
      const { ok, json, status } = await this.requestService.secured.put(url, {
        body: CartContactMapper.toAddContact(input),
      })

      if (ok) return resultOk(this.cartService.toCartEntity(json))

      return resultErr(CartContactErrorMapper.toContactErrors(status, json))
    } catch (err: unknown) {
      return resultErr(new CartContactPartyErrors.UnknownErrorAddToContact(err))
    }
  }

  async addToMailingList(
    input: AddToMailingListInput
  ): PromiseResult<CartEntity, CartContactPartyErrorInstance> {
    const url = `${this.baseUrl}/carts/${input.cartId}`

    try {
      const { ok, json, status } = await this.requestService.secured.put(url, {
        body: CartContactMapper.toAddToMailingList(input),
      })

      if (ok) return resultOk(this.cartService.toCartEntity(json))

      return resultErr(CartContactErrorMapper.toMailingErrors(status, json))
    } catch (err: unknown) {
      return resultErr(new CartContactPartyErrors.UnknownErrorAddToMailing(err))
    }
  }
}
