import { MonthsDropdown, useProfileContext } from '@nori/app-kit'
import { observer } from '@nori/di'
import { useTranslations } from '@nori/lang-i18n'
import { Button, Icon, Input, Modal, Tooltip } from '@nori/ui-kit'

import { useBirthdayGuardContext } from '../interface/use-birthday-guard-context'

const DEFAULT_MONTH = 1

export const BirthdayGuardModal = observer(() => {
  const { userStore } = useProfileContext()
  const { birthdayGuardAction, birthdayGuardStore } = useBirthdayGuardContext()
  const t = useTranslations('birthdayGuard')

  const isOpen = userStore.isUserLoaded && !userStore.currentUser.birthday

  return (
    <Modal withScroll={false} isOpen={isOpen} position="top" onClose={() => {}}>
      <Modal.Body>
        <div className="flex flex-row items-center gap-2 text-gray-400">
          <span className="font-bold text-primary-900">
            {t('enterBirthdate')}
          </span>
          <Tooltip
            place="right"
            slot={
              <div className="min-w-32 text-gray-500">
                {t('birthdayTooltip')}
              </div>
            }
          >
            <Icon name="InformationCircleFill" />
          </Tooltip>
        </div>

        <div className="mt-8 flex flex-col items-center md:flex-row md:gap-4">
          <div className="flex max-md:w-full md:flex-grow">
            <MonthsDropdown
              label={t('month')}
              error={birthdayGuardStore.birthdayValidator.errors.month}
              month={birthdayGuardStore.birthdayValidator.values.month}
              onChange={(val) =>
                birthdayGuardAction.handleMonthChange(val || DEFAULT_MONTH)
              }
            />
          </div>
          <div className="w-full md:w-1/5">
            <Input
              type="integer"
              max={31}
              maxLength={2}
              placeholder="DD"
              onChange={birthdayGuardAction.handleDayChange}
              value={birthdayGuardStore.birthdayValidator.values.day}
              error={birthdayGuardStore.birthdayValidator.errors.day}
            />
          </div>
          <div className="w-full md:w-1/3">
            <Input
              type="integer"
              maxLength={4}
              placeholder="YYYY"
              onChange={birthdayGuardAction.handleYearChange}
              value={birthdayGuardStore.birthdayValidator.values.year}
              error={birthdayGuardStore.birthdayValidator.errors.year}
            />
          </div>
        </div>

        <div className="mt-2 flex flex-row justify-center">
          <div className="w-full md:min-w-56">
            <Button
              isLoading={birthdayGuardStore.isSubmitting}
              disabled={birthdayGuardStore.isSubmitting}
              onClick={birthdayGuardAction.handleSubmit}
              size="lg"
              color="primary"
              widthType="full"
            >
              {t('continue')}
            </Button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  )
})
