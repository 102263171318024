import { hookContextFactory } from '@nori/di'

import { ContactDetailsAction } from './action/contact-details.action'
import { ContactFiltersAction } from './action/contact-filters.action'
import { ContactSelectorAction } from './action/contact-selector.action'
import { ContactSubscriptionAction } from './action/contact-subscription.action'
import { ContactsAction } from './action/contacts.action'
import { ContactsExportsAction } from './action/contacts-exports.action'
import { CreateContactAction } from './action/create-contact.action'
import { EditContactAction } from './action/edit-contact.action'
import { FindOrCreateContactAction } from './action/find-or-create-contact.action'
import { ContactDetailsStore } from './store/contact-details.store'
import { ContactFiltersStore } from './store/contact-filters.store'
import { ContactSelectorStore } from './store/contact-selector.store'
import { ContactSubscriptionStore } from './store/contact-subscription.store'
import { ContactsStore } from './store/contacts.store'
import { CreateContactStore } from './store/create-contact.store'
import { EditContactStore } from './store/edit-contact.store'
import { FindOrCreateContactStore } from './store/find-or-create-contact.store'

export const { useModuleContext: useContactContext } = hookContextFactory({
  contactsStore: ContactsStore,
  contactsAction: ContactsAction,
  contactsExportsAction: ContactsExportsAction,

  // TODO: extract to separated context
  contactDetailsStore: ContactDetailsStore,
  contactDetailsAction: ContactDetailsAction,

  // TODO: extract to separated context
  createContactAction: CreateContactAction,
  createContactStore: CreateContactStore,
  editContactAction: EditContactAction,
  editContactStore: EditContactStore,

  // TODO: extract to separated context
  contactSelectorAction: ContactSelectorAction,
  contactSelectorStore: ContactSelectorStore,

  // TODO: extract to separated context
  findOrCreateContactAction: FindOrCreateContactAction,
  findOrCreateContactStore: FindOrCreateContactStore,

  // TODO: extract to separated context
  contactSubscriptionAction: ContactSubscriptionAction,
  contactSubscriptionStore: ContactSubscriptionStore,

  // TODO: extract to separated context
  contactFiltersStore: ContactFiltersStore,
  contactFiltersAction: ContactFiltersAction,
})
