import { observer } from '@nori/di'
import { useTranslations } from '@nori/lang-i18n'
import { Link, Loader, Swap } from '@nori/ui-kit'
import { useContactContext } from '~/modules/contacts/interface/use-contact-context'
import { Routes } from '~/modules/routes'

import { NextCartAt } from './next-cart-at'
import { SubscriptionTableHead } from './subscription-table-head'
import { SubscriptionTableRow } from './subscription-table-row'
import { TableEmpty } from './table-empty'

import type { ContactEntity } from '~/modules/contacts/core'

type Props = {
  contact: ContactEntity
}

export const SubscriptionTable = observer(({ contact }: Props) => {
  const t = useTranslations(
    'contacts.list.table.extended.subscription.tableHead'
  )
  const { contactSubscriptionStore } = useContactContext()
  const id = contact.customerId

  if (!id) return <TableEmpty />

  const isLoading = contactSubscriptionStore.isLoadingById[id]
  const error = contactSubscriptionStore.errorById[id]
  const nextOrder = contactSubscriptionStore.nextOrderById[id]

  if (isLoading) return <Loader />
  if (error) return <span>Error while loading next order</span>

  const autoSaveLink = Routes.contacts.contactDetails.path({
    id: contact?.id,
    tab: 'subscriptions',
  })

  return (
    <Swap
      is={!nextOrder?.contactNextOrder.subscriptions.length}
      isSlot={<TableEmpty />}
    >
      <div className="flex flex-col gap-2 lg:flex-row lg:justify-between">
        <NextCartAt id={id} />
        <div className="max-lg:self-end">
          <Link href={autoSaveLink} iconRight={'ExternalLink'}>
            {t('viewAllAutoSave')}
          </Link>
        </div>
      </div>
      <table className="mt-10 ">
        <SubscriptionTableHead />
        <tbody>
          {nextOrder?.skus?.map((sku, index) => (
            <SubscriptionTableRow
              key={sku.id}
              id={id}
              sku={sku}
              isFirst={!index}
            />
          ))}
        </tbody>
      </table>
    </Swap>
  )
})
