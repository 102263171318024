import { observer } from '@nori/di'
import { useTranslations } from '@nori/lang-i18n'
import { Tabs } from '@nori/ui-kit'
import { useContactContext } from '~/modules/contacts/interface/use-contact-context'

import { CreateContactBlock } from './create-contact-block'
import { SearchContactBlock } from './search-contact-block'

import type { ContactEntity } from '~/modules/contacts/core'
import type { ContactSelectorTab } from '~/modules/contacts/interface/store/contact-selector.store'

export type ContactSelectorProps = {
  onSaveContact: () => void
  onChangeTab: (tab: ContactSelectorTab) => void
  onSelectContact: (contact: ContactEntity) => void
  withAddress?: boolean
  withPhoneNumber?: boolean
  isSavingLoading?: boolean
  isSelfContactAllowed?: boolean
  onSelectSelfContact?: () => void
  isSelfHostAllowed?: boolean
  selfContactLabel?: string
}

export const ContactSelector = observer(
  ({
    onSaveContact,
    onChangeTab,
    onSelectContact,
    withAddress,
    isSavingLoading,
    withPhoneNumber,
    isSelfContactAllowed,
    onSelectSelfContact,
    selfContactLabel,
  }: ContactSelectorProps) => {
    const { contactSelectorStore, findOrCreateContactStore } =
      useContactContext()
    const t = useTranslations('contactSelector')

    return (
      <Tabs active={contactSelectorStore.activeTab} onChange={onChangeTab}>
        <Tabs.Titles>
          <Tabs.Title data-test-id="search-contact-tab" name="search">
            {t('searchContact')}
          </Tabs.Title>
          <Tabs.Title data-test-id="create-contact-tab" name="create">
            {t(
              findOrCreateContactStore.isEditContact
                ? 'editContact'
                : 'createContact'
            )}
          </Tabs.Title>
        </Tabs.Titles>

        <div className="mt-5" />

        <Tabs.Panel name="search">
          <SearchContactBlock
            onSelectContact={onSelectContact}
            isSelfContactAllowed={isSelfContactAllowed}
            onSelectSelfContact={onSelectSelfContact}
            selfContactLabel={selfContactLabel}
          />
        </Tabs.Panel>

        <Tabs.Panel name="create">
          <CreateContactBlock
            onSaveContact={onSaveContact}
            withAddress={withAddress}
            withPhoneNumber={withPhoneNumber}
            isSavingLoading={isSavingLoading}
          />
        </Tabs.Panel>
      </Tabs>
    )
  }
)
