import { ProductEntity, ProductTypeCode } from '@nori/app-kit'
import { DataState, Inject, Store, Toggle } from '@nori/di'
import { SearchProductsStore } from '~/modules/shop-for-myself/interface/store/search-products.store'

import type {
  BundleComponentEntity,
  BundleEntityComponentProduct,
  CommonBundleEntity,
  MoneyValueObject,
} from '@nori/app-kit'
import type { BundleErrorInstance } from '../../core/errors'

type SelectedSkuByProduct = Record<number, number | null>

export const INITIAL_STEP = 0

@Store()
export class CustomizeBundleStore {
  constructor(
    @Inject() private readonly searchProductsStore: SearchProductsStore
  ) {}
  bundleEditOptions = new DataState<
    BundleComponentEntity[],
    BundleErrorInstance
  >({ isLoading: false })

  editMode = new Toggle()

  modalToggle = new Toggle()
  isLoading = false

  selectedSku: SelectedSkuByProduct = {}
  invisibleSelectedSku: SelectedSkuByProduct = {}

  bundleId?: number = undefined
  bundleCode?: string = undefined
  subscriptionPlanId?: number = undefined
  currentStep = INITIAL_STEP

  get stepsLength(): number {
    const componentsLength = this.visibleBundleComponents?.length
    if (!componentsLength) return 0
    return componentsLength
  }

  get currentStepTitle(): string {
    return `${this.currentStep + 1}`
  }

  get lastStepTitle(): string {
    return `${this.stepsLength}`
  }

  get isLastStep(): boolean {
    return this.currentStep >= this.stepsLength
  }

  get buttonTitle(): string {
    const buttonTitle = this.isLastStep ? 'addToCart' : 'next'
    return this.currentStep === this.stepsLength - 1 ? 'review' : buttonTitle
  }

  get visibleBundleComponents(): BundleComponentEntity[] | undefined {
    if (this.editMode.isOpen) {
      return this.bundleEditOptions.data
    }
    return this.searchProductsStore.list?.items
      .find((product): product is CommonBundleEntity => {
        if (product.typeCode === ProductTypeCode.Bundle) {
          return product.id === this.bundleId
        }
        return false
      })
      ?.components?.filter((component: BundleComponentEntity) =>
        component.products.some(
          (product: BundleEntityComponentProduct) =>
            product.product.visible === true
        )
      )
  }

  get bundleComponents(): BundleComponentEntity[] | undefined {
    if (this.editMode.isOpen) {
      return this.bundleEditOptions.data
    }
    return this.searchProductsStore.list?.items.find(
      (product): product is CommonBundleEntity => {
        if (product.typeCode === ProductTypeCode.Bundle) {
          return product.id === this.bundleId
        }
        return false
      }
    )?.components
  }
  get selectedProducts(): BundleEntityComponentProduct[] {
    if (!this.bundleComponents) {
      return []
    }
    return this.visibleBundleComponents?.reduce(
      (acc: BundleEntityComponentProduct[], component, index) => {
        const product = component.products.find(
          (product) => product.skus?.[0]?.id === this.selectedSku[index]
        )

        if (!product) {
          return acc
        }
        acc.push(product)
        return acc
      },
      []
    ) as BundleEntityComponentProduct[]
  }
  get invisibleBundleComponents(): BundleComponentEntity[] | undefined {
    if (this.editMode.isOpen) {
      return this.bundleEditOptions.data
    }
    return this.searchProductsStore.list?.items
      .find((product): product is CommonBundleEntity => {
        if (product.typeCode === ProductTypeCode.Bundle) {
          return product.id === this.bundleId
        }
        return false
      })
      ?.components?.filter((component: BundleComponentEntity) =>
        component.products.every(
          (product: BundleEntityComponentProduct) =>
            product.product.visible === false
        )
      )
  }

  get summaryPrice(): MoneyValueObject {
    const visiblePrice = this.selectedProducts.reduce(
      (acc: MoneyValueObject, product) => {
        const price = product.skus?.[0]?.currentSalePrice
        if (!price) return acc
        acc.cents += price.cents
        return acc
      },
      { cents: 0, currency: 'USD' }
    )

    const invisiblePrice = this.invisibleBundleComponents?.reduce(
      (acc: MoneyValueObject, component) => {
        const price = component?.products?.[0]?.skus?.[0]?.currentSalePrice
        if (!price) return acc
        acc.cents += price.cents
        return acc
      },
      { cents: 0, currency: 'USD' }
    )
    if (!invisiblePrice?.cents) return visiblePrice
    return {
      cents: invisiblePrice.cents + visiblePrice.cents,
      currency: visiblePrice.currency,
    }
  }

  get title(): string {
    return this.visibleBundleComponents?.[this.currentStep]?.name ?? ''
  }

  get isButtonDisabled(): boolean {
    if (this.isLastStep) {
      return false
    }
    return this.isLoading || !this.selectedSku[this.currentStep]
  }

  get isPreviousStepExits(): boolean {
    return this.currentStep > INITIAL_STEP
  }

  setCurrentStep(step: number): void {
    this.currentStep = step
  }

  dropStore(): void {
    this.setBundleId(undefined)
    this.selectedSku = {}
    this.setSubscriptionPlanId(undefined)
    this.setCurrentStep(INITIAL_STEP)
    this.editMode.handleClose()
  }

  setBundleId(bundleId?: number): void {
    this.bundleId = bundleId
  }

  setBundleCode(bundleCode?: string): void {
    this.bundleCode = bundleCode
  }

  setSelectedSkuId(skuId: number | null): void {
    this.selectedSku[this.currentStep] = skuId
  }

  setIsLoading(isLoading: boolean): void {
    this.isLoading = isLoading
  }

  setSubscriptionPlanId(subscriptionPlanId?: number): void {
    this.subscriptionPlanId = subscriptionPlanId
  }
}
