import { CommonBundleEntity, CommonStyleEntity } from '@nori/app-kit'
import { DataState, Store } from '@nori/di'

import type { ProductListValueObject } from '~/modules/products'
import type { SubscriptionErrorInstance } from '~/modules/subscription'

const INITIAL_PAGE_NUMBER = 1
@Store()
export class SubscriptionsAddProductStore {
  isOpen = false
  isLoadingMore = false

  isLoadingById: Record<number, boolean> = {}

  products = new DataState<ProductListValueObject, SubscriptionErrorInstance>({
    isLoading: false,
  })
  searchQuery = ''

  get isAnyAutoShip(): boolean {
    return (
      this.products.data?.items?.some((product) => {
        if (product.typeCode === 'bundle') {
          return product.canBeAutoShipped
        }
        if (product.typeCode === 'style') {
          return product.canBeAutoShipped
        }
        return false
      }) ?? false
    )
  }

  get page(): number {
    return this.products?.data?.pagination.page ?? INITIAL_PAGE_NUMBER
  }

  get hasMore(): boolean {
    return (
      !this.products?.data ||
      (!!this.products?.data?.pagination?.totalPages &&
        this.products.data.pagination.totalPages > this.page)
    )
  }

  setIsProductLoading(id: number, isLoading: boolean): void {
    this.isLoadingById[id] = isLoading
  }

  setIsLoadingMore(isLoadingMore: boolean): void {
    this.isLoadingMore = isLoadingMore
  }

  setSearchQuery(query: string): void {
    this.searchQuery = query
  }

  setIsOpen(isOpen: boolean): void {
    this.isOpen = isOpen
  }
}
