import { observer } from '@nori/di'
import { useTranslations } from '@nori/lang-i18n'
import { Button } from '@nori/ui-kit'
import { useCartContext } from '~/modules/cart/interface/context/use-cart-context'
import { useCartUserCreditsContext } from '~/modules/user-credits/interface/cart-user-credits-context'

type Props = {
  productId: number
  isDiscountApplied: boolean
  isDiscountDisallow?: boolean
  widthType?: 'none' | 'full'
}

export const HostessDiscountButton = observer(
  ({
    productId,
    isDiscountApplied,
    isDiscountDisallow,
    widthType = 'none',
  }: Props) => {
    const t = useTranslations('cart.productList.hostessDiscountButton')
    const { cartAction, cartStore } = useCartContext()
    const { cartUserCreditsStore } = useCartUserCreditsContext()
    const { data } = cartUserCreditsStore.cartUserCredits

    const title = isDiscountApplied
      ? t('appliedPercentsOff', {
          value: data?.hostessDiscountsHash?.percentage,
        })
      : t('applyPercentsOff', { value: data?.hostessDiscountsHash?.percentage })
    const iconName = isDiscountApplied ? 'CheckCircleOutline' : undefined

    const handleDiscountApply = async () => {
      await cartAction.handleApplyHostessDiscount({
        productId,
        isDiscountApply: !isDiscountApplied,
      })
    }

    if (cartStore.isConsultantCustomerCart) return null
    if (isDiscountDisallow) return null
    if (!data?.hostessDiscountsHash?.count) return null

    return (
      <Button
        onClick={handleDiscountApply}
        size="base"
        isOutline
        iconLeft={iconName}
        widthType={widthType}
      >
        {title}
      </Button>
    )
  }
)
