import { HistoryService, UserStore } from '@nori/app-kit'
import { Action, Inject } from '@nori/di'
import { Routes } from '~/modules/routes'

@Action()
export class AuthGuardAction {
  constructor(
    @Inject() private readonly userStore: UserStore,
    @Inject() private readonly historyService: HistoryService
  ) {}

  async handleAuthRedirect(route: string): Promise<void> {
    const { isActiveDsr, mentorConfirmedAt } = this.userStore.currentUser
    const isConfirmationRoute = route.startsWith(
      Routes.auth.enrollmentConfirmation.path()
    )

    if (!isActiveDsr) {
      await this.historyService.push(Routes.auth.enrollment.path())
      return
    }

    if (mentorConfirmedAt || isConfirmationRoute) {
      return
    }
    await this.historyService.push(Routes.auth.enrollmentConfirmation.path())
  }
}
