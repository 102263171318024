import { observer } from '@nori/di'
import { useTranslations } from '@nori/lang-i18n'
import { Icon, Loader, Modal, useInitialLoad } from '@nori/ui-kit'
import { usePartiesListContext } from '~/modules/parties/interface/use-parties-list-context'
import { SearchContactPartyBlock } from '~/modules/parties/ui/contact-party-list/search-contact-party-block'

import { useCartContactPartyContext } from '../../interface/use-cart-contact-part.context'

import { SelectedEventBlock } from './selected-event'

export const EditHostessModal = observer(() => {
  const t = useTranslations('contactSelector')
  const { partiesListStore, partiesListAction } = usePartiesListContext()
  const { contactAndPartyAction, cartContactPartyStore } =
    useCartContactPartyContext()
  const isShowLoading = cartContactPartyStore.isLoading
  useInitialLoad(() => {
    partiesListAction.handleInitialLoad('open')
  })
  return (
    <Modal
      data-test-id="select-party-or-hostes-modal"
      size="2xl"
      isOpen={cartContactPartyStore.editHostessToggle.isOpen}
      onClose={contactAndPartyAction.handleCloseHostessModal}
    >
      <Modal.Header onClose={contactAndPartyAction.handleCloseHostessModal} />
      <Modal.Body>
        {isShowLoading && <Loader viewType="filled-center" />}
        <div className="lg:w-full lg:px-7 lg:py-4">
          <h1 className="mb-5 text-center text-lg font-bold text-primary-900">
            {t('editHost')}
          </h1>
          <SelectedEventBlock
            title={cartContactPartyStore.partyOrHostessName}
            hostCaption={cartContactPartyStore.hostName}
            partyDate={
              cartContactPartyStore.partyDate &&
              `${cartContactPartyStore.partyDate.formatInTZ(
                'EEE MMM d, yyyy / hh:mma'
              )}`
            }
            partyAddress={cartContactPartyStore.partyAddress}
            onRemove={() => contactAndPartyAction.handleRemovePartyFromOrder()}
          />

          <div className="my-4">
            <SearchContactPartyBlock
              onSelectContact={
                contactAndPartyAction.handleAddPartyByContactToOrder
              }
            />
          </div>
          <div>
            {!!partiesListStore.partiesList?.length && (
              <p className="text-base font-normal text-primary-900">
                {t('yourEvents')}
              </p>
            )}
            <div>
              {partiesListStore.partiesList?.map((item) => {
                const host = item.hostess
                const hostName = [host.firstName, host.lastName]
                  .filter(Boolean)
                  .join(' ')
                const hostCaption = t('hostName', { hostName })
                return (
                  <div
                    data-test-id={`party-option_${item.id}`}
                    key={item.id}
                    onClick={() =>
                      contactAndPartyAction.handleAddPartyToOrder(item.id)
                    }
                    className="flex cursor-pointer border-b-2 border-b-gray-50 p-5 pl-0 hover:bg-gray-50"
                  >
                    <div className="mr-3 flex h-10 min-w-[40px] items-center justify-center rounded-full bg-primary-900 text-white">
                      <Icon name="CalendarOutline" />
                    </div>
                    <div className="flex flex-col">
                      <span className="text-sm font-bold text-primary-900">
                        {item.partyName}
                      </span>
                      <span className="text-sm font-normal text-gray-500">
                        {hostCaption}
                      </span>
                      <p className="text-sm font-normal text-gray-500">
                        {`${item.partyAt.formatInTZ('MMM d, yyyy / hh:mma')}`}
                        {item?.address?.city && item.address?.state && (
                          <span>{` / ${item?.address?.city}, ${item.address?.state}`}</span>
                        )}
                      </p>
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  )
})
