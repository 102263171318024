import { Fragment } from 'react'
import Head from 'next/head'
import NextNProgress from 'nextjs-progressbar'

import { BaseApp, Hydrated, isServer, useI18nContext } from '@nori/app-kit'
import { enableStaticRendering, observer } from '@nori/di'
import { blockTranslator, NextIntlProvider } from '@nori/lang-i18n'
import { LoggerProvider } from '@nori/logger'
import { NotifyContainer } from '@nori/notify'
import { AppcuesScript } from '~/modules/appcues/ui/appcues-script'
import { GhostLogin } from '~/modules/auth'
import { ClarityScript } from '~/modules/clarity/ui/clarity-script'
import { GoogleTagManager } from '~/modules/google-tag-manager'
import { getMessages } from '~/modules/i18n'

import { AuthGuard } from './auth-guard'
import { Mocks } from './mocks'

import type { NextPage } from 'next'
import type { AppProps } from 'next/app'

enableStaticRendering(isServer)

export type NextPageWithLayout = NextPage & {
  getLayout?: (page: React.ReactElement) => React.ReactNode
}

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout
}

export const App = observer((props: AppPropsWithLayout) => {
  const getLayout = props.Component.getLayout ?? ((page) => page)
  const { i18nStore } = useI18nContext()
  const messages = getMessages()
  blockTranslator.updateMessages({
    locale: i18nStore.currentLocale,
    messages: messages[i18nStore.currentLocale],
  })

  return (
    <Fragment>
      <NextNProgress
        options={{
          showSpinner: false,
        }}
        color="#8292A7"
      />
      <Head>
        <title>Norwex Consultant Office</title>
        <link href="https://use.typekit.net/cjn7kna.css" rel="stylesheet" />
        <link
          href="/images/norwex-favicon-light.png"
          rel="icon"
          media="(prefers-color-scheme: light)"
        />
        <link
          href="/images/norwex-favicon-dark.png"
          rel="icon"
          media="(prefers-color-scheme: dark)"
        />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=0"
        />
      </Head>
      <LoggerProvider>
        <Mocks>
          <NextIntlProvider
            messages={messages[i18nStore.currentLocale]}
            locale={i18nStore.currentLocale}
          >
            <Hydrated>
              <GoogleTagManager />
              <ClarityScript />
              <AppcuesScript />
              <NotifyContainer />

              <GhostLogin />

              <AuthGuard>{getLayout(<BaseApp {...props} />)}</AuthGuard>
            </Hydrated>
          </NextIntlProvider>
        </Mocks>
      </LoggerProvider>
    </Fragment>
  )
})
