import {
  buildUrl,
  getBaseUrl,
  RequestService,
  SERVICE_NAMES,
} from '@nori/app-kit'
import { Adapter, Inject } from '@nori/di'
import { resultErr, resultOk } from '@nori/result'

import { PartiesListErrors } from '../core/errors/parties-list.errors'

import { PartiesListMapper } from './mappers/parties-list.mapper'

import type { PromiseResult } from '@nori/result'
import type { GetPartyListInput } from '~/modules/parties/infra/types/get-party-list/get-party-list.input'
import type { PartiesListErrorInstance } from '../core/errors/parties-list.errors'
import type { PartiesListValueObject } from '../core/value-objects/parties-list.value-object'
import type { PartyTypeValueObject } from '../core/value-objects/party-type.value-object'

const ITEMS_PER_PAGE = 40
const INITIAL_PAGE = 1

@Adapter()
export class PartiesListAdapter {
  private readonly baseUrl = getBaseUrl(SERVICE_NAMES.MY_BUSINESS_SERVICE)

  public constructor(
    @Inject() private readonly requestService: RequestService,
    @Inject() private readonly partiesListMapper: PartiesListMapper
  ) {}

  private getPartyTypeUrl(partyType: PartyTypeValueObject): string {
    switch (partyType) {
      case 'closed':
        return 'parties/closed'

      case 'all':
        return 'parties.json'

      case 'open':
        return 'parties/open'

      case 'scheduled':
        return 'parties/booked'
    }
  }

  public async getPartyList({
    partyType = 'open',
    sortBy,
    ordering,
    page = INITIAL_PAGE,
    perPage = ITEMS_PER_PAGE,
    to = '',
    from = '',
    searchParam = '',
    isWithAccurateTime = false,
  }: GetPartyListInput): PromiseResult<
    PartiesListValueObject,
    PartiesListErrorInstance
  > {
    try {
      const queryParams = this.partiesListMapper.toPartyListRequest({
        partyType,
        sortBy,
        ordering,
        page,
        perPage,
        to,
        from,
        searchParam,
        isWithAccurateTime,
      })

      const url = buildUrl(
        `${this.baseUrl}/${this.getPartyTypeUrl(partyType)}`,
        { queryParams }
      )
      const { ok, json } = await this.requestService.secured.get(url)

      if (!ok) {
        return resultErr(new PartiesListErrors.UnknownError(json))
      }

      return resultOk(this.partiesListMapper.toPartiesListValueObject(json))
    } catch (err: unknown) {
      return resultErr(new PartiesListErrors.UnexpectedError(err))
    }
  }

  public async getPartyListByContact({
    searchParam = '',
  }: GetPartyListInput): PromiseResult<
    PartiesListValueObject,
    PartiesListErrorInstance
  > {
    try {
      const queryParams = this.partiesListMapper.toPartyListRequest({
        searchParam,
        searchFilter: 'hostess',
      })

      const url = buildUrl(`${this.baseUrl}/parties/open`, {
        queryParams,
      })
      const { ok, json } = await this.requestService.secured.get(url)

      if (!ok) {
        return resultErr(new PartiesListErrors.UnknownError(json))
      }

      return resultOk(this.partiesListMapper.toPartiesListValueObject(json))
    } catch (err: unknown) {
      return resultErr(new PartiesListErrors.UnexpectedError(err))
    }
  }
}
