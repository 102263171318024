import { Action, Inject } from '@nori/di'
import { isErr } from '@nori/result'
import { createLogger } from '~/infra/create-logger'
import { LoadCartService } from '~/modules/cart/interface/services/create-cart.service'
import { CartStore } from '~/modules/cart/interface/store/cart-store/cart.store'
import { CartContactAdapter } from '~/modules/cart-contact-party/infra/cart-contact.adapter'
import { LoadContactsService } from '~/modules/contacts/interface/services/contact.service'
import { FindOrCreateContactService } from '~/modules/contacts/interface/services/find-or-create-contact.service'
import { ContactSelectorStore } from '~/modules/contacts/interface/store/contact-selector.store'
import { PartiesListLoadService } from '~/modules/parties/interface/services/parties-list-load.service'
import { PartiesListStore } from '~/modules/parties/interface/stores/parties-list.store'

import { ContactAndPartyService } from '../service/contact-and-party.service'
import { CartContactPartyStore } from '../store/cart-contact-party.store'

import type { PartyEntity } from '~/modules/parties/core/entities/party.entity'

const logger = createLogger('contact-and-party.action')

@Action()
export class ContactAndPartyAction {
  constructor(
    @Inject() private readonly cartContactPartyStore: CartContactPartyStore,
    @Inject() private readonly cartStore: CartStore,
    @Inject() private readonly partiesListStore: PartiesListStore,
    @Inject() private readonly cartContactAdapter: CartContactAdapter,
    @Inject() private readonly partiesListLoadService: PartiesListLoadService,
    @Inject() private readonly loadCartService: LoadCartService,
    @Inject() private readonly contactSelectorStore: ContactSelectorStore,
    @Inject()
    private readonly findOrCreateContactService: FindOrCreateContactService,
    @Inject()
    private readonly contactAndPartyService: ContactAndPartyService,
    @Inject() private readonly contactService: LoadContactsService
  ) {}

  handleInitialLoad(): void {
    this.loadSelfContact().then()

    if (!this.cartContactPartyStore.isContactSelected) {
      this.contactSelectorStore.setSelectedContactId(undefined)
    }
  }

  handleOpenHostessModal(): void {
    this.cartContactPartyStore.editHostessToggle.handleOpen()
  }

  handleCloseHostessModal(): void {
    this.contactSelectorStore.setSearchQuery('')
    this.cartContactPartyStore.editHostessToggle.handleClose()
  }

  handleOpenContactModal(): void {
    this.contactSelectorStore.setActiveTab('search')
    this.cartContactPartyStore.editContactToggle.handleOpen()
  }

  handleCloseContactModal(): void {
    this.contactSelectorStore.setSearchQuery('')
    this.cartContactPartyStore.editContactToggle.handleClose()
  }

  handleOnEditClick(): void {
    this.cartContactPartyStore.setIsContactEdit(true)
  }

  async handleOnContinueClick(): Promise<void> {
    const { cart } = this.cartStore

    if (cart?.id) {
      await this.loadCartService.loadCartById({ cartId: cart.id })
    }

    this.cartContactPartyStore.setIsContactEdit(false)
  }

  async handleCreateContact(): Promise<void> {
    this.cartContactPartyStore.setIsCreateContactLoading(true)
    const result = await this.findOrCreateContactService.createContact()
    if (isErr(result) || result.data.type === 'validationFailed') {
      this.cartContactPartyStore.setIsCreateContactLoading(false)
      return
    }
    const createdContact = this.findOrCreateContactService.getCreatedContact()
    await this.handleContactSelect(createdContact?.id)

    this.cartContactPartyStore.setIsCreateContactLoading(false)
    this.cartContactPartyStore.editContactToggle.handleClose()
  }

  async handleContactSelect(contactId?: number): Promise<void> {
    this.contactSelectorStore.setSelectedContactId(contactId)
    await this.contactAndPartyService.handleContactSelect(contactId)
  }

  async handleAddToMailingList(): Promise<void> {
    const { cart } = this.cartStore

    if (!cart) {
      logger.error('Cart is not defined')
      return
    }

    const result = await this.cartContactAdapter.addToMailingList({
      isAddToMailingList: !cart.addToMailingList,
      cartId: cart.id,
    })

    if (isErr(result)) {
      logger.error(result.error.key, result.error)
      return
    }
    this.cartStore.cartData.setData(result.data)
  }

  async handleEditHostessClick(): Promise<void> {
    await this.partiesListLoadService.initialLoad({ partyType: 'open' })

    if (!this.partiesListStore.partiesList?.length) return

    this.cartContactPartyStore.editHostessToggle.handleOpen()
  }

  async handleAddPartyByContactToOrder(party: PartyEntity): Promise<void> {
    await this.contactAndPartyService.handleAddPartyToOrder(party.id)
  }

  async handleAddPartyToOrder(partyId: number): Promise<void> {
    await this.contactAndPartyService.handleAddPartyToOrder(partyId)
  }

  async handleRemoveHostessFromOrder(): Promise<void> {
    await this.contactAndPartyService.handleRemoveHostessFromOrder()
  }

  async handleRemovePartyFromOrder(): Promise<void> {
    await this.contactAndPartyService.handleRemovePartyFromOrder()
  }

  async handleRemove(): Promise<void> {
    if (this.cartStore.cart?.partyId) {
      await this.handleRemovePartyFromOrder()
      return
    }

    await this.handleRemoveHostessFromOrder()
  }

  private async loadSelfContact(): Promise<void> {
    const selfContactData = await this.contactService.getSelfContact()
    if (isErr(selfContactData) || !selfContactData) {
      return
    }

    this.contactSelectorStore.setSelfContactId(selfContactData.data.id)
  }
}
