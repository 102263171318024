import { Inject, Service } from '@nori/di'
import { isErr } from '@nori/result'
import { createLogger } from '~/infra/create-logger'
import { CartStore } from '~/modules/cart/interface/store/cart-store/cart.store'

import { ProductCartAdapter } from '../../infra/product-cart.adapter'
import { ProductCartStore } from '../store/product-cart.store'

import type { CartItemEntity } from '@nori/app-kit'

const logger = createLogger('switch-product-in-cart.service')

@Service()
export class SwitchProductInCartService {
  constructor(
    @Inject() private readonly cartStore: CartStore,
    @Inject() private readonly productCartStore: ProductCartStore,
    @Inject() private readonly productCartAdapter: ProductCartAdapter
  ) {}

  async handleSwitchProductInCart(
    product: CartItemEntity,
    quantity: number
  ): Promise<void> {
    this.productCartStore.setIsProductSwitchInCart(true)

    await this.switchProductInCart(product, quantity)

    this.productCartStore.setIsProductSwitchInCart(false)
  }

  private async switchProductInCart(
    product: CartItemEntity,
    quantity: number
  ): Promise<void> {
    const { cart } = this.cartStore
    this.cartStore.cartData.setError(undefined)

    if (!cart) return

    const result = await this.productCartAdapter.switchProductInCart(
      cart.id,
      product,
      quantity
    )

    if (isErr(result)) {
      logger.error(result.error.key, result.error)
      this.cartStore.cartData.setError(result.error)

      return
    }

    this.cartStore.cartData.setData(result.data)
  }
}
