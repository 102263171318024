import { Action, Inject } from '@nori/di'

import { LoadOrdersService } from '../service/load-orders.service'
import { OrdersListFiltersStore } from '../stores/orders-list-filters.store'

import type { NoriDate } from '@nori/date'
import type {
  FilterValue,
  OrderFilterOption,
  OrderFiltersKeys,
} from '../../core/orders-list-filters'
import type { OrderListFilterTabOption } from '../../core/orders-list-filters-tab-options'

const START_PAGE = 1

@Action()
export class OrdersListFiltersAction {
  constructor(
    @Inject() private readonly ordersListFiltersStore: OrdersListFiltersStore,
    @Inject() private readonly loadOrdersService: LoadOrdersService
  ) {}

  handleOpenFilterModal(): void {
    this.ordersListFiltersStore.clearAllFiltersOptions()
    this.ordersListFiltersStore.fillFiltersOptionsByAppliedFilters()

    this.ordersListFiltersStore.filterModalToggle.handleOpen()
  }

  handleSetSelectedRange(value: string): void {
    this.ordersListFiltersStore.setSelectedRange(value)
  }

  async handleDateRangeChange(dateRange: NoriDate[]): Promise<void> {
    this.ordersListFiltersStore.setDateRange(dateRange)

    await this.handleLoad({ page: START_PAGE })
  }

  handleCloseFilterModal(): void {
    this.ordersListFiltersStore.filterModalToggle.handleClose()
  }

  handleSelectFilterTab(tab: OrderListFilterTabOption): void {
    this.ordersListFiltersStore.setSelectedFilterTab(tab)
  }

  async handleApplyFilters(): Promise<void> {
    const checkedFilters = this.ordersListFiltersStore.filtersOptions.filter(
      (filterOption) => filterOption.isChecked
    )
    this.ordersListFiltersStore.setAppliedFilters(checkedFilters)

    this.handleCloseFilterModal()
    await this.handleLoad({ page: START_PAGE })
  }

  handleClearAllFiltersOptions(): void {
    this.ordersListFiltersStore.clearAllFiltersOptions()
  }

  async handleRemoveOneFilter(appliedFilter: OrderFilterOption): Promise<void> {
    this.ordersListFiltersStore.removeAppliedFilter(appliedFilter)

    await this.handleLoad({ page: START_PAGE })
  }

  async handleClearAllAppliedFilters(): Promise<void> {
    this.ordersListFiltersStore.setAppliedFilters([])
    await this.handleLoad({ page: START_PAGE })
  }

  handleToggleFilter<T extends OrderFiltersKeys>({
    key,
    filterValue,
    isChecked,
  }: {
    key: T
    filterValue: FilterValue<T>
    isChecked: boolean
  }): void {
    this.ordersListFiltersStore.setFilterByKey({ key, filterValue, isChecked })
  }

  private async handleLoad({ page }: { page?: number }): Promise<void> {
    await this.loadOrdersService.loadOrderList({ page })
  }
}
