import { UserStore } from '@nori/app-kit'
import { DataState, Inject, Store, Toggle } from '@nori/di'
import {
  type SubscriptionEntity,
  type SubscriptionErrorInstance,
} from '~/modules/subscription'

import { ShippingAddressModalConfig } from '../shipping-address-modal-config'

import { NextOrderStore } from './next-order.store'

import type {
  AddressValueObject,
  BundleDetailsEntity,
  StyleDetailsEntity,
} from '@nori/app-kit'
import type { PendingOrderEntity } from '~/modules/subscription'
import type { SubscriptionProductsErrorInstance } from '~/modules/subscription/core/subscription-products.errors'
import type { PendingOrdersErrorInstance } from './../../../subscription/core/pending-order.errors'

@Store()
export class SubscriptionsStore {
  constructor(
    @Inject()
    private readonly nextOrderStore: NextOrderStore,
    @Inject() private readonly userStore: UserStore
  ) {}

  subscriptions = new DataState<
    SubscriptionEntity[],
    SubscriptionErrorInstance
  >()

  subscriptionProducts = new DataState<
    (BundleDetailsEntity | StyleDetailsEntity)[],
    SubscriptionProductsErrorInstance
  >()

  pendingOrders = new DataState<
    PendingOrderEntity[],
    PendingOrdersErrorInstance
  >()

  shippingAddressModalToggle = new Toggle(false)
  subsriptionStatusTab: 'active' | 'cancelled' = 'active'

  isAnotherAddress = false

  shippingAddressConfig = new ShippingAddressModalConfig()

  getSubscriptionShippingAddress(
    subscription: SubscriptionEntity
  ): AddressValueObject {
    const isShippingAddressExists =
      Object.values(subscription?.shippingAddress ?? {}).filter(Boolean)
        .length > 0

    return isShippingAddressExists
      ? (subscription?.shippingAddress as AddressValueObject)
      : this.defaultShippingAddress
  }

  get defaultShippingAddress(): AddressValueObject {
    return this.userStore.currentUser.shippingAddress
  }

  get activeSubscriptions(): SubscriptionEntity[] {
    if (!this.subscriptions.data) return []
    return this.subscriptions.data.filter((subscription) => subscription.active)
  }

  get inactiveSubscriptions(): SubscriptionEntity[] {
    if (!this.subscriptions.data) return []
    return this.subscriptions.data.filter(
      (subscription) =>
        !subscription.active && !(subscription.workflowState === 'pending')
    )
  }

  private hasSubscriptions(): boolean {
    return (
      this.activeSubscriptions.length > 0 ||
      this.inactiveSubscriptions.length > 0 ||
      !!(
        this.nextOrderStore.nextOrder &&
        this.nextOrderStore.nextOrder.subscriptions.length > 0
      )
    )
  }

  get isToShowPendingOrders(): boolean {
    return !this.hasSubscriptions()
  }

  get isToShowAutoSaveTabs(): boolean {
    return (
      this.hasSubscriptions() ||
      (!this.pendingOrders?.data?.length && !this.hasSubscriptions())
    )
  }

  setIsAnotherAddress(value: boolean): void {
    this.isAnotherAddress = value
  }

  validateAddress():
    | { address: AddressValueObject; isValid: true }
    | { isValid: false } {
    if (this.shippingAddressConfig.addressType === 'current-user') {
      return {
        address: this.defaultShippingAddress,
        isValid: true,
      }
    }

    const { isValid } =
      this.shippingAddressConfig.shippingAddressValidator.submit()

    if (!isValid) return { isValid }

    return {
      address: {
        ...this.shippingAddressConfig.shippingAddressValidator.values,
        phoneNumber:
          this.shippingAddressConfig.shippingAddressValidator.values.phone,
      },
      isValid: true,
    }
  }

  setSubsriptionStatusTab(tab: 'active' | 'cancelled'): void {
    this.subsriptionStatusTab = tab
  }
}
