import { useCallback } from 'react'

import { useQuery } from '@nori/app-kit'
import { useTranslations } from '@nori/lang-i18n'
import { Dropdown, Tabs } from '@nori/ui-kit'

import { useAutosaveContext } from '../interface/use-autosave-context'

import { AllAutosavePanel } from './all-autosave/all-autosave-panel'
import { NextOrderPanel } from './next-order/next-order-panel'
import { autosaveTabs } from './autosave-tabs-list'

import type { ContactTabsLabelValueObject } from '~/modules/contacts/core/contact-tabs-label.value-object'
import type { AutosaveTabsLabelValueObject } from '../core/autosave-tabs-label.value-object'

type Props = {
  activeTab: ContactTabsLabelValueObject
}

type Query = { id: number }

export const AutosaveContactContent = ({ activeTab }: Props) => {
  const { id } = useQuery<Query>()
  const t = useTranslations()
  const { subscriptionsAction } = useAutosaveContext()

  const handleTabChange = useCallback(
    (tab: AutosaveTabsLabelValueObject) => {
      subscriptionsAction.handleTabChange(tab, true, id)
    },
    [id, subscriptionsAction]
  )

  const trasnlationKey =
    activeTab === 'next-order' ? 'nextOrder' : 'allSubscriptions'

  return (
    <div className="mt-5 lg:mt-12">
      <div className="w-full lg:hidden">
        <Dropdown
          hideHelperBlock
          size="default"
          title={t(`autosaveSettings.${trasnlationKey}` as IntlMessageKeys)}
        >
          {autosaveTabs.map((_tab) => {
            return (
              <Dropdown.Option
                key={_tab.key}
                data-test-id={_tab.dataTestId}
                selected={_tab.key === activeTab}
                onClick={() => handleTabChange(_tab.key)}
              >
                {t(_tab.translationKey)}
              </Dropdown.Option>
            )
          })}
        </Dropdown>
      </div>
      <Tabs<AutosaveTabsLabelValueObject>
        active={activeTab as AutosaveTabsLabelValueObject}
        onChange={handleTabChange}
        skin="button"
      >
        <div className="mb-12 hidden w-full max-w-[450px] lg:block">
          <Tabs.Titles>
            {autosaveTabs.map((_tab) => (
              <Tabs.Title<AutosaveTabsLabelValueObject>
                key={_tab.key}
                name={_tab.key}
                data-test-id={_tab.dataTestId}
              >
                {t(_tab.translationKey)}
              </Tabs.Title>
            ))}
          </Tabs.Titles>
        </div>

        <Tabs.Panel<AutosaveTabsLabelValueObject> name="next-order">
          <NextOrderPanel isContactDetailsPage />
        </Tabs.Panel>
        <Tabs.Panel<AutosaveTabsLabelValueObject> name="subscriptions">
          <AllAutosavePanel isContactDetailsPage />
        </Tabs.Panel>
      </Tabs>
    </div>
  )
}
