import { useEffect } from 'react'

import { useQuery } from '@nori/app-kit'
import { observer } from '@nori/di'
import { useTranslations } from '@nori/lang-i18n'
import { Dropdown, Tabs } from '@nori/ui-kit'
import { AutosaveContactContent } from '~/modules/autosave/ui/autosave-contact-content'

import { useContactContext } from '../../interface/use-contact-context'

import { ContactWishlist } from './contact-wishlist/contact-wishlist'
import { NorwexPerks } from './norwex-perks/norwex-perks'
import { customerTabs } from './contact-detail-tabs-list'
import { ContactInfo } from './contact-info'

import type { ContactTabsLabelValueObject } from '../../core/contact-tabs-label.value-object'

type Query = { tab: ContactTabsLabelValueObject }

export const ContactDetailsTabs = observer(() => {
  const t = useTranslations()
  const { tab = 'info' } = useQuery<Query>()
  const { contactDetailsAction, contactDetailsStore } = useContactContext()

  const tabs =
    contactDetailsStore.isCustomer &&
    contactDetailsStore.contact?.isPreferredDsr
      ? customerTabs
      : undefined

  if (!tabs) {
    return <ContactInfo />
  }
  const activeTab = tab === 'subscriptions' ? 'next-order' : tab
  const translationKey = activeTab === 'next-order' ? 'autosave' : activeTab

  return (
    <div className="mt-5 flex flex-auto flex-wrap gap-2">
      <div className="w-full lg:hidden">
        <Dropdown
          hideHelperBlock
          size="default"
          title={t(
            `contactDetailsPage.tabs.${translationKey}` as IntlMessageKeys
          )}
        >
          {tabs.map((_tab) => {
            return (
              <Dropdown.Option
                key={_tab.key}
                data-test-id={_tab.dataTestId}
                selected={_tab.key === activeTab}
                onClick={() =>
                  contactDetailsAction.handleSetActiveTab(_tab.key)
                }
              >
                {t(_tab.translationKey)}
              </Dropdown.Option>
            )
          })}
        </Dropdown>
      </div>
      <Tabs<ContactTabsLabelValueObject>
        active={activeTab}
        onChange={contactDetailsAction.handleSetActiveTab}
      >
        <div className="hidden w-full text-center lg:block">
          <Tabs.Titles>
            {tabs.map((tab) => {
              if (tab.key === 'credits' && contactDetailsStore.isConsultant)
                return null

              return (
                <Tabs.Title<ContactTabsLabelValueObject>
                  data-test-id={tab.dataTestId}
                  name={tab.key}
                  key={tab.key}
                >
                  {t(tab.translationKey)}
                </Tabs.Title>
              )
            })}
          </Tabs.Titles>
        </div>
        <div className="w-full">
          <Tabs.Panel<ContactTabsLabelValueObject> name="info">
            <ContactInfo />
          </Tabs.Panel>
          <Tabs.Panel<ContactTabsLabelValueObject> name="credits">
            <NorwexPerks />
          </Tabs.Panel>
          <Tabs.Panel<ContactTabsLabelValueObject> name="next-order">
            <AutosaveContactContent activeTab={tab} />
          </Tabs.Panel>
          <Tabs.Panel<ContactTabsLabelValueObject> name="wishlist">
            <ContactWishlist />
          </Tabs.Panel>
        </div>
      </Tabs>
    </div>
  )
})
