import { Store } from '@nori/di'

import type { NoriDate } from '@nori/date'
import type { SkuEntity } from '~/modules/sku'
import type {
  NextOrderValueObject,
  SubscriptionEntity,
  SubscriptionErrorInstance,
} from '~/modules/subscription'

type NextOrder = {
  contactNextOrder: NextOrderValueObject
  skus?: SkuEntity[]
}

@Store()
export class ContactSubscriptionStore {
  nextOrderById: Record<number, NextOrder | undefined> = {}
  isLoadingById: Record<number, boolean> = {}
  errorById: Record<number, SubscriptionErrorInstance | undefined> = {}

  getNextCartAt(id: number): NoriDate | undefined {
    const nextOrder = this.nextOrderById[id]?.contactNextOrder
    return nextOrder?.subscriptions.find(
      (subscription) => !!subscription.nextCartAt
    )?.nextCartAt
  }

  getSubscription(id: number, code: string): SubscriptionEntity | undefined {
    const nextOrder = this.nextOrderById[id]?.contactNextOrder
    return nextOrder?.subscriptionsByCode[code]?.[0]
  }

  setSkus(orderId: number, skus: SkuEntity[]): void {
    const nextOrder = this.nextOrderById[orderId]
    if (!nextOrder) return

    nextOrder.skus = [...skus].reverse()
    this.nextOrderById[orderId] = nextOrder
  }

  setNextOrder(id: number, value: NextOrderValueObject): void {
    this.nextOrderById[id] = { contactNextOrder: value }
  }

  setIsLoading(id: number, value: boolean): void {
    this.isLoadingById[id] = value
  }

  setError(id: number, error?: SubscriptionErrorInstance): void {
    this.errorById[id] = error
  }
}
