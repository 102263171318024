import { observer } from '@nori/di'
import { useTranslations } from '@nori/lang-i18n'
import { DropdownSearch, Modal, useDebounce } from '@nori/ui-kit'

import { useAutosaveContext } from '../../../interface/use-autosave-context'

import { AddItemSearchResults } from './add-item-search-results'

export const AddItemModal = observer(() => {
  const { nextOrderAddItemAction, nextOrderAddItemStore } = useAutosaveContext()
  const t = useTranslations('autosaveSettings')

  const { value, onChangeValue } = useDebounce({
    initialValue: nextOrderAddItemStore.searchQuery,
    onChangeDebounce: nextOrderAddItemAction.handleSearchQuery,
  })

  return (
    <Modal
      id="autosave-settings-add-item-modal"
      size="3xl"
      isOpen={nextOrderAddItemStore.isOpen}
      onClose={nextOrderAddItemAction.handleClose}
    >
      <Modal.Header
        onClose={nextOrderAddItemAction.handleClose}
        headerType="only-close-button"
      />
      <Modal.Body>
        <div>
          <h1 className="text-center text-2xl font-normal text-primary-900">
            {t('addItems')}
          </h1>
          <div className="my-8 ">
            <DropdownSearch
              value={value}
              onChange={onChangeValue}
              hideHelperBlock
              placeholder={t('searchProducts')}
            />
          </div>
          <AddItemSearchResults />
        </div>
      </Modal.Body>
    </Modal>
  )
})
