import { LOG_LEVEL } from './log-level';
export const usedLogLevel = (()=>{
    if (process.env['LOGGER_LOG_LEVEL']) {
        return Number(process.env['LOGGER_LOG_LEVEL']);
    }
    if (process.env.NODE_ENV === 'production') return LOG_LEVEL.ERROR;
    return LOG_LEVEL.INFO;
})();
const logLevelTitleMap = {
    [LOG_LEVEL.ERROR]: 'error',
    [LOG_LEVEL.WARN]: 'warn',
    [LOG_LEVEL.INFO]: 'info',
    [LOG_LEVEL.DEBUG]: 'debug',
    [LOG_LEVEL.TRACE]: 'trace'
};
console.log('[log level info]', logLevelTitleMap[usedLogLevel], usedLogLevel);
