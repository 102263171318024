import { useRouter } from 'next/router'

import { useProductsContext, useProfileContext, UserStore } from '@nori/app-kit'
import { observer } from '@nori/di'
import { useTranslations } from '@nori/lang-i18n'
import { Button, Divider, Pagination } from '@nori/ui-kit'
import { useContactContext } from '~/modules/contacts/interface/use-contact-context'
import { Routes } from '~/modules/routes'
import { useWishListContext } from '~/modules/wishlist/interface/use-wish-list.context'
import { WishListContent } from '~/modules/wishlist/ui/wishlist-content'
import { WishlistHeader } from '~/modules/wishlist/ui/wishlist-header.page'

export const ContactWishlist = observer(() => {
  const t = useTranslations()
  const router = useRouter()
  const { contactDetailsStore, contactDetailsAction } = useContactContext()
  const { wishListAction, wishListStore } = useWishListContext()
  const { userStore } = useProfileContext()

  if (!userStore.isOSEnabled) {
    return (
      <div className="mt-28 flex w-full flex-col items-center justify-center gap-8">
        <div>
          <p className="text-base font-bold text-primary-900">
            {t('contactDetailsPage.unavailableWishlist')}
          </p>
        </div>
        <div>
          <Button
            onClick={() =>
              router.push(Routes.settings.officeSuite.path('billing'))
            }
          >
            {t('contactDetailsPage.officeSuiteSubscribeButton')}
          </Button>
        </div>
      </div>
    )
  }

  return (
    <div className="flex flex-auto flex-col flex-wrap gap-2">
      <WishlistHeader
        totalCount={wishListStore.wishlist?.data?.pagination.totalCount}
        wishlistType="contact"
        contactName={contactDetailsStore.contactFullName}
      />
      <WishListContent
        isError={wishListStore.wishlist.isError}
        isLoading={contactDetailsStore.isWishlistLoading}
        wishList={wishListStore.wishlist.data?.items}
        contact={contactDetailsStore.contact}
        onRemoveItemFromWishList={wishListAction.handleRemoveItemFromWishList}
      />

      <Divider />
      {wishListStore.wishlist?.data?.pagination && (
        <div className="flex flex-row justify-center">
          <Pagination
            currentPage={wishListStore.wishlist?.data?.pagination.page}
            totalPages={wishListStore.wishlist?.data?.pagination.totalPages}
            onChange={contactDetailsAction.handleLoadWishList}
            offset={1}
          />
        </div>
      )}
    </div>
  )
})
