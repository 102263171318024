import { Service } from '@nori/di'

import type { SearchProductsInput } from '../types/search-products.input'
import type { SearchProductsRequest } from '../types/search-products.request'

@Service()
export class SearchProductsMapper {
  toSearchProductsRequest({
    pagination,
    searchQuery,
    noShippingRestricted,
  }: Omit<SearchProductsInput, 'isCustomerSearch'>): SearchProductsRequest {
    const params: SearchProductsRequest = {
      page: pagination.page,
      per_page: pagination.perPage,
      query: searchQuery,
    }
    if (noShippingRestricted)
      params.no_shipping_restricted_items = noShippingRestricted

    return params
  }
}
