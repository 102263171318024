import { useMemo } from 'react';
const headerTitleMap = {
    days: (date, locale)=>date.formatInTZ('MMM yyyy', locale),
    months: (date, locale)=>date.formatInTZ('yyyy', locale),
    years: (date)=>{
        const startDate = date.startOfDecade();
        const startYear = startDate.getFullYear();
        const endYear = startYear + 11;
        return `${startYear} - ${endYear}`;
    }
};
export function useTitle({ viewDate , viewType , locale  }) {
    return useMemo(()=>{
        return headerTitleMap[viewType](viewDate, locale);
    }, [
        viewDate,
        viewType
    ]);
}
