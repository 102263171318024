import { useTranslations } from '@nori/lang-i18n'
import { ConfirmationModal, Link, useToggle } from '@nori/ui-kit'

import type { LinkSize } from '@nori/ui-kit'

type Props = {
  size?: LinkSize
  onCancel: () => void
}

export function CancelOrderLink({ size = 'medium', onCancel }: Props) {
  const t = useTranslations('shopForMyself.orderSummary')
  const { isOpen, handleOpen, handleClose } = useToggle()

  return (
    <>
      <Link
        size={size}
        onClick={handleOpen}
        data-test-id={'cancel-order-button'}
      >
        {t('cancel')}
      </Link>

      <ConfirmationModal
        isOpen={isOpen}
        title={t('cancel')}
        text={t('cancelDescription')}
        onClose={handleClose}
        onSubmit={() => {
          handleClose()
          onCancel()
        }}
      />
    </>
  )
}
