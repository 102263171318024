import Link from 'next/link'

import { getLink } from '@nori/app-kit'
import { useTranslations } from '@nori/lang-i18n'

export const ContactConsultantLink = () => {
  const t = useTranslations('header.help')
  const linkClass =
    'lg:block lg:px-2 lg:py-2 lg:text-sm lg:hover:bg-primary-50 lg:bg-white font-bold text-primary-900'
  const linkClassMobile =
    'flex flex-row justify-between border-primary-700 bg-primary-50 px-4 py-3'

  const link = getLink({ type: 'bo', personalLink: 'contact-us' })

  return (
    <>
      <Link
        className={`${linkClass} ${linkClassMobile}`}
        data-test-id="contact-consultant-care-option"
        href={link.url}
        target="_blank"
      >
        {t('contactConsultantCare')}
      </Link>
    </>
  )
}
