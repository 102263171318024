import type { ContactFilterOption } from '../../core/filters/filter-options'

export const contactFiltersOptions: ContactFilterOption[] = [
  {
    filterKey: 'social_this_month',
    translationKey: 'contacts.filters.social_this_month',
    value: true,
    isChecked: false,
  },
  {
    filterKey: 'birthday_this_month',
    translationKey: 'contacts.filters.birthday_this_month',
    value: true,
    isChecked: false,
  },
  {
    filterKey: 'birthday_next_month',
    translationKey: 'contacts.filters.birthday_next_month',
    value: true,
    isChecked: false,
  },
  {
    filterKey: 'potential_hostess',
    translationKey: 'contacts.filters.potential_hostess',
    value: true,
    isChecked: false,
  },
  {
    filterKey: 'potential_designer',
    translationKey: 'contacts.filters.potential_designer',
    value: true,
    isChecked: false,
  },
  {
    filterKey: 'customer_type_list[]',
    translationKey: 'contacts.filters.customer_type_list_hostess',
    value: 'hostess',
    isChecked: false,
  },
  {
    filterKey: 'customer_type_list[]',
    translationKey: 'contacts.filters.customer_type_list_customer',
    value: 'customer',
    isChecked: false,
  },
  {
    filterKey: 'customer_type_list[]',
    translationKey: 'contacts.filters.customer_type_list_contact',
    value: 'contact',
    isChecked: false,
  },
  {
    filterKey: 'corp_referral',
    translationKey: 'contacts.filters.corp_referral',
    value: true,
    isChecked: false,
  },
  {
    filterKey: 'is_preferred_dsr',
    translationKey: 'contacts.filters.is_preferred_dsr',
    value: true,
    isChecked: false,
  },
  {
    filterKey: 'easy_replenish_customer',
    translationKey: 'contacts.filters.easy_replenish_customer',
    value: true,
    isChecked: false,
  },
  {
    filterKey: 'has_wish_list',
    translationKey: 'contacts.filters.has_wish_list',
    value: true,
    isChecked: false,
  },
  {
    filterKey: 'expiring_rewards_this_month',
    translationKey: 'contacts.filters.expiring_rewards_this_month',
    value: true,
    isChecked: false,
  },
  {
    filterKey: 'has_hostess_rewards',
    translationKey: 'contacts.filters.has_hostess_rewards',
    value: true,
    isChecked: false,
  },
  {
    filterKey: 'has_store_credit',
    translationKey: 'contacts.filters.has_store_credit',
    value: true,
    isChecked: false,
  },
  {
    filterKey: 'has_referral_earning_period',
    translationKey: 'contacts.filters.has_referral_earning_period',
    value: true,
    isChecked: false,
  },
  {
    filterKey: 'catalog_mailing_type_list[]',
    translationKey: 'contacts.filters.mailing',
    value: '#Mailing',
    isChecked: false,
  },
  {
    filterKey: 'catalog_mailing_type_list[]',
    translationKey: 'contacts.filters.mailingAug',
    value: '#Mailed_August_2024_Catalog',
    isChecked: false,
  },
  {
    filterKey: 'catalog_mailing_type_list[]',
    translationKey: 'contacts.filters.mailingMar',
    value: '#Mailed_March_2024_Catalog',
    isChecked: false,
  },
  {
    filterKey: 'catalog_mailing_type_list[]',
    translationKey: 'contacts.filters.mailingJan',
    value: '#Mailed_January_2024_Catalog',
    isChecked: false,
  },
]
