import { useCallback, useEffect, useMemo, useState } from 'react'

import { type CartBundleEntity, useProfileContext } from '@nori/app-kit'
import { observer } from '@nori/di'
import { useCartContext } from '~/modules/cart/interface/context/use-cart-context'
import { useBundleContext } from '~/modules/customize-bundle/interface/use-bundle.context'
import { useProductCartContext } from '~/modules/product-cart/interface/use-product-cart.contact'
import { useProductSubscriptionContext } from '~/modules/product-subscription/interface/product-subscription.context'

import { BundleItemDesktop } from './bundle-desktop/bundle-desktop'
import { BundleItemMobile } from './bundle-mobile/bundle-mobile'

type Props = {
  bundle: CartBundleEntity
}

export const BundleItem = observer(({ bundle }: Props) => {
  const { productCartAction, productCartStore } = useProductCartContext()
  const { customizeBundleAction, customizeBundleStore } = useBundleContext()
  const { userStore } = useProfileContext()
  const { cartStore, cartAction } = useCartContext()
  const { bundleSubscriptionAction, productSubscriptionStore } =
    useProductSubscriptionContext()
  const { cart } = cartStore

  const cartCurrency = cart?.currency ?? userStore.currency

  const { isRemovingFromCart, isApplyingHostessDiscount } = productCartStore

  const [isEditMode, setIsEditMode] = useState(false)
  const [itemQuantity, setItemQuantity] = useState(bundle.quantity)

  const isBundleLoading = useMemo(() => {
    if (
      bundle.id === customizeBundleStore.bundleId &&
      customizeBundleStore.bundleEditOptions.isLoading
    ) {
      return true
    }

    return isRemovingFromCart || isApplyingHostessDiscount
  }, [
    bundle.id,
    customizeBundleStore.bundleEditOptions.isLoading,
    customizeBundleStore.bundleId,
    isRemovingFromCart,
    isApplyingHostessDiscount,
  ])

  const { canBeAutoshipped } =
    productSubscriptionStore.getProductSubscriptions({
      productId: bundle.code,
    }) || {}

  const isBundleEditable = bundle.variant && !bundle.hostessDiscount

  useEffect(() => {
    if (cartStore.isCustomerCart) return
    bundleSubscriptionAction.handleGetBundleSubscriptionOptions({
      bundleCode: bundle.code,
    })
  }, [])

  const handleItemQuantityChange = useCallback((value: string) => {
    const number = Number(value)
    if (isNaN(number)) return
    if (number < 0) return
    setItemQuantity(number)
  }, [])

  const handleCancel = useCallback(() => {
    setItemQuantity(bundle.quantity)
    setIsEditMode(false)
  }, [bundle.quantity])

  const handleAddToCart = useCallback(
    (quantity: number) => {
      if (!bundle.code || !quantity) return
      setIsEditMode(false)
      // productCartAction.handleSwitchProductInCart(product, itemQuantity)
    },
    [itemQuantity, productCartAction]
  )

  const bundleLength = bundle.items.length
  const lastItemInBundle = bundle.items[bundleLength - 1]?.id

  if (!cart) return null

  return (
    <div data-test-id="product-item">
      <div className="lg:invisible lg:hidden">
        <BundleItemMobile
          currency={cartCurrency}
          bundle={bundle}
          currentQuantity={itemQuantity.toString()}
          handleCancel={handleCancel}
          handleItemQuantityChange={handleItemQuantityChange}
          isEditMode={isEditMode}
          isProductLoading={isBundleLoading}
          onAdd={() => handleAddToCart(itemQuantity)}
          onRemove={() => cartAction.handleRemoveBundleFromCart(bundle)}
          onEditSwitch={() =>
            customizeBundleAction.handleEditBundle({
              bundleCode: bundle.code,
              bundleId: bundle.id,
            })
          }
          canBeAutoShipped={canBeAutoshipped}
          isEditable={isBundleEditable}
          lastItemInBundle={lastItemInBundle}
        />
      </div>
      <div className="invisible hidden lg:visible lg:block">
        <BundleItemDesktop
          bundle={bundle}
          currency={cartCurrency}
          currentQuantity={itemQuantity.toString()}
          handleCancel={handleCancel}
          handleItemQuantityChange={handleItemQuantityChange}
          isEditMode={isEditMode}
          isProductLoading={isBundleLoading}
          onAdd={() => handleAddToCart(itemQuantity)}
          onRemove={() => cartAction.handleRemoveBundleFromCart(bundle)}
          onEditSwitch={() =>
            customizeBundleAction.handleEditBundle({
              bundleCode: bundle.code,
              bundleId: bundle.id,
            })
          }
          canBeAutoShipped={canBeAutoshipped}
          isEditable={isBundleEditable}
          lastItemInBundle={lastItemInBundle}
        />
      </div>
    </div>
  )
})
