import { getBaseUrl, RequestService, SERVICE_NAMES } from '@nori/app-kit'
import { Adapter, Inject } from '@nori/di'
import { resultErr, resultOk } from '@nori/result'

import {
  type ProductSubscriptionErrorInstance,
  ProductSubscriptionErrors,
} from '../core/prdouct-subscription.errors'

import { ProductSubscriptionMapper } from './mappers/product-subscription.mapper'

import type { PromiseResult } from '@nori/result'
import type { CartProductSubscriptionValueObject } from '../core/cart-product-subscription.value-object'
import type productSubscriptionMock from './mock/product-subsrcription.json'

@Adapter()
export class SubscriptionAdapter {
  private baseUrl = getBaseUrl(SERVICE_NAMES.CATALOG_SERVICE)

  public constructor(
    @Inject() private readonly requestService: RequestService
  ) {}

  async getProductSubscriptionOptions({
    productId,
  }: {
    productId: number
  }): PromiseResult<
    CartProductSubscriptionValueObject,
    ProductSubscriptionErrorInstance
  > {
    const url = `${this.baseUrl}/products/${productId}/subscriptions`

    try {
      const response = await this.requestService.secured.get<
        typeof productSubscriptionMock
      >(url, {
        withCache: true,
      })

      if (response.ok) {
        return resultOk(ProductSubscriptionMapper.toValueObject(response.json))
      }

      return resultErr(
        new ProductSubscriptionErrors.GetSubscriptionUnknownError(
          response.status,
          response.json
        )
      )
    } catch (err: unknown) {
      return resultErr(
        new ProductSubscriptionErrors.GetSubscriptionUnexpectedError(err)
      )
    }
  }

  async getBundleSubscriptionOptions({
    bundleCode,
  }: {
    bundleCode: string
  }): PromiseResult<
    CartProductSubscriptionValueObject,
    ProductSubscriptionErrorInstance
  > {
    const url = `${this.baseUrl}/bundles/${bundleCode}/subscriptions`

    try {
      const response = await this.requestService.secured.get<
        typeof productSubscriptionMock
      >(url, {
        withCache: true,
      })

      if (response.ok) {
        return resultOk(ProductSubscriptionMapper.toValueObject(response.json))
      }

      return resultErr(
        new ProductSubscriptionErrors.GetBundleSubscriptionUnknownError(
          response.status,
          response.json
        )
      )
    } catch (err: unknown) {
      return resultErr(
        new ProductSubscriptionErrors.GetBundleSubscriptionUnexpectedError(err)
      )
    }
  }
}
