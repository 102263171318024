import { UserStore } from '@nori/app-kit'
import { DataState, Inject, Store, Toggle } from '@nori/di'

import type { AcceptAgreementErrorInstance } from '../../core/accept-agreement.errors'
import type { AgreementVersionEntity } from '../../core/entities/agreement-version.entity'
import type { GetAgreementVersionErrorInstance } from '../../core/get-agreement-version.errors'

@Store()
export class AgreementStore {
  constructor(
    @Inject()
    private readonly userStore: UserStore
  ) {}
  agreementModalToggle = new Toggle()
  isLoadingAcceptance = false
  agreementVersionError?: GetAgreementVersionErrorInstance = undefined
  agreementAcceptanceError?: AcceptAgreementErrorInstance = undefined
  agreementVersion = new DataState<
    AgreementVersionEntity,
    GetAgreementVersionErrorInstance
  >({
    isLoading: false,
  })

  public get isCurrentAgreementAccepted(): boolean {
    return (
      this.agreementVersion.data?.currentVersion ===
      this.agreementVersion.data?.lastAcceptedVersion
    )
  }

  public setIsLoadingAcceptance(value: boolean): void {
    this.isLoadingAcceptance = value
  }

  public setAgreementAcceptanceError(
    value?: AcceptAgreementErrorInstance
  ): void {
    this.agreementAcceptanceError = value
  }
}
