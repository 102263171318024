import clsx from 'clsx'

type Props = {
  title: string
  total?: string | React.ReactNode
  isPriceStrikeThrough?: boolean
  afterPriceTitle?: string
  'data-test-id'?: string
}

export function CartSummaryItem({
  title,
  total,
  'data-test-id': dataTestId,
  isPriceStrikeThrough,
  afterPriceTitle,
}: Props) {
  const priceStyle = clsx('text-sm font-medium', {
    'line-through': isPriceStrikeThrough,
  })
  return (
    <div className="flex items-center justify-between text-gray-600">
      <p className="text-sm font-normal">{title}</p>
      <p>
        <span className={priceStyle} data-test-id={dataTestId}>
          {total}
        </span>
        {afterPriceTitle && (
          <span className="ml-1 text-sm font-medium text-gray-700">
            {afterPriceTitle}
          </span>
        )}
      </p>
    </div>
  )
}
