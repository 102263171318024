import { CategoryEndpointService } from '@nori/app-kit'
import { Service } from '@nori/di'

import type { CategoryEndpointServiceInterface } from '@nori/app-kit'

@Service({ id: CategoryEndpointService })
class RewriteCategoryEndpointService
  implements CategoryEndpointServiceInterface
{
  getEndpoint(): {
    endpoint: string
    withAuth: boolean
  } {
    const endpoint = '/categories?en-US'
    return {
      endpoint,
      withAuth: true,
    }
  }
}
