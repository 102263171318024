import { getBaseUrl, RequestService, SERVICE_NAMES } from '@nori/app-kit'
import { Adapter, Inject } from '@nori/di'
import { resultErr, resultOk } from '@nori/result'
import { CartErrors } from '~/modules/cart/core'

import { CreateShareLinkRequestMapper } from './mappers/create-share-link-request.mapper'
import { CreateShareLinkResponseMapper } from './mappers/create-share-link-response.mapper'
import { ShareCartErrorMapper } from './mappers/share-cart-error.mapper'

import type { PromiseResult } from '@nori/result'
import type { ShareCartErrorInstance } from '../core/share-cart.errors'
import type { CartShareLinkValueObject } from '../core/share-link.value-object'
import type { CreateShareLinkResponse } from './types/create-share-link.response'

@Adapter()
export class ShareAdapter {
  baseUrl = getBaseUrl(SERVICE_NAMES.CART_SERVICE)

  public constructor(
    @Inject() private readonly requestService: RequestService,
    @Inject()
    private readonly createShareLinkRequestMapper: CreateShareLinkRequestMapper,
    @Inject()
    private readonly createShareLinkResponseMapper: CreateShareLinkResponseMapper
  ) {}

  async createShareLink({
    cartId,
    partyId,
  }: {
    cartId: number
    partyId?: number
  }): PromiseResult<CartShareLinkValueObject, ShareCartErrorInstance> {
    try {
      const url = `${this.baseUrl}/carts/${cartId}/shared`
      const input = this.createShareLinkRequestMapper.toRequestInput(partyId)
      const response =
        await this.requestService.secured.post<CreateShareLinkResponse>(url, {
          body: input,
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        })

      if (response.ok) {
        return resultOk(
          this.createShareLinkResponseMapper.toCartShareLinkValueObject(
            response.json
          )
        )
      }

      return resultErr(
        ShareCartErrorMapper.toShareCartErrors(response.status, response.json)
      )
    } catch (err: unknown) {
      return resultErr(new CartErrors.UnexpectedError(err))
    }
  }
}
