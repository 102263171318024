import { useMemo } from 'react'
import { useRouter } from 'next/router'

import { getMoneyWithIsoCurrency, useProfileContext } from '@nori/app-kit'
import { observer } from '@nori/di'
import { useTranslations } from '@nori/lang-i18n'
import { Swap, useToggle } from '@nori/ui-kit'
import { useApplyCreditsContext } from '~/modules/apply-credits/interface/use-apply-credits-context'
import { ApplyCreditsConfirmationModal } from '~/modules/apply-credits/ui/apply-credits-confirmation-modal'
import { useCartContext } from '~/modules/cart/interface/context/use-cart-context'
import { CartCouponCode, CartCouponCodeForm } from '~/modules/coupon-code'
import { usePartiesListContext } from '~/modules/parties/interface/use-parties-list-context'
import { useProductSubscriptionContext } from '~/modules/product-subscription/interface/product-subscription.context'
import { Routes } from '~/modules/routes'
import { ShareBagModal } from '~/modules/share'
import { useShareContext } from '~/modules/share/interface/use-share-context'

import { CartSummaryControls } from './cart-summary-controls'
import { CartSummaryItem } from './cart-summary-item'

type Props = {
  onCheckout: () => void
  isApplyCreditConfirmationModalOpen: boolean
  onCloseApplyCreditConfirmationModal: () => void
}

export const CartSummary = observer(
  ({
    onCheckout,
    isApplyCreditConfirmationModalOpen,
    onCloseApplyCreditConfirmationModal,
  }: Props) => {
    const router = useRouter()
    const { handleOpen, handleClose, isOpen: isShareBagModalOpen } = useToggle()
    const { cartAction, cartStore } = useCartContext()
    const { partiesListAction } = usePartiesListContext()
    const { shareAction, shareStore } = useShareContext()
    const { applyCreditsStore, applyCreditsAction } = useApplyCreditsContext()
    const { userStore } = useProfileContext()
    const t = useTranslations('cart.summary')
    const { cart } = cartStore
    const { shareCartErrorTitle, isCreatingShareCartLink } = shareStore

    const { productSubscriptionStore } = useProductSubscriptionContext()

    if (!cart) return null
    const isCheckoutDisabled =
      !cart.items.length ||
      (cartStore.isCartHaveAutoshipItem && cartStore.isCustomerCart) ||
      productSubscriptionStore.getProductSubscriptionLoading()
    const isHelperBlockVisible =
      cartStore.isCartHaveAutoshipItem && cartStore.isCustomerCart

    const openShareModalCallback = useMemo(() => {
      if (cartStore.isPersonalCart) return
      return async () => {
        await shareAction.handleCreateShareLink(cart.partyId)
        await partiesListAction.handleInitialLoad('open')
        if (!shareStore.createShareLinkError) {
          handleOpen()
        }
      }
    }, [
      cartStore.isPersonalCart,
      cart.partyId,
      shareStore.createShareLinkError,
    ])

    const saveCartForLaterCallback = useMemo(() => {
      if (cartStore.isPersonalCart) return
      return () => router.push(Routes.orders.list.selectedTab.path('saved'))
    }, [cartStore.isPersonalCart, router])

    return (
      <>
        <div
          className="sticky top-36 h-fit rounded-sm bg-primary-50 p-6 lg:min-w-92"
          data-test-id="order-summary"
        >
          <div className="mb-2 flex items-center justify-between ">
            <p className="text-lg font-semibold text-gray-600">{t('title')}</p>
            <p className="text-xs font-normal text-gray-600">
              {t('item', { count: cartStore.totalItemsCount })}
            </p>
          </div>

          <div className="my-4 h-px w-full bg-primary-100" />

          <CartSummaryItem
            title={t('item', {
              count: cartStore.totalItemsCount,
            })}
            total={getMoneyWithIsoCurrency(cart.itemTotal)}
            data-test-id="item-total"
          />

          {!!cart.hostessDiscountsCount && (
            <CartSummaryItem
              title={t('hostessDiscount', {
                percentage: cartStore.hostessDiscountPercentage,
              })}
              total={getMoneyWithIsoCurrency(cart.hostessDiscountsAmountTotal, {
                isNegative: true,
              })}
              data-test-id="item-discount"
            />
          )}

          {!!cart.productCreditsTotal &&
            cart.productCreditsTotal?.cents > 0 && (
              <CartSummaryItem
                title={t('productCredits')}
                total={getMoneyWithIsoCurrency(cart.productCreditsTotal, {
                  isNegative: true,
                })}
                data-test-id="product-credits"
              />
            )}

          {!!cart.combinedCreditsTotal?.cents && (
            <CartSummaryItem
              title={t('norwexCredits')}
              total={getMoneyWithIsoCurrency(cart.combinedCreditsTotal, {
                isNegative: true,
              })}
              data-test-id="norwex-credits"
            />
          )}

          {!!cart.promotions.length && (
            <div className="w-full">
              {cart.promotions.map((promotion) => (
                <CartSummaryItem
                  key={promotion.key}
                  title={promotion.label}
                  total={getMoneyWithIsoCurrency(promotion.amount, {
                    isNegative: true,
                  })}
                  data-test-id={promotion.label}
                />
              ))}
            </div>
          )}

          {!!cart.dsrDiscountTotal?.cents && (
            <CartSummaryItem
              title={t('dsrDiscount', {
                percentage: cart.dsrDiscountPercentage,
              })}
              total={getMoneyWithIsoCurrency(cart.dsrDiscountTotal, {
                isNegative: true,
              })}
              data-test-id="dsr-discount"
            />
          )}

          <div className="my-4 h-px w-full bg-primary-100" />

          <CartSummaryItem
            title={t('subtotal')}
            total={getMoneyWithIsoCurrency(cart.publicSubtotal)}
            data-test-id="subtotal"
          />

          {!cartStore.isPersonalCart && (
            <>
              <div className="my-4 h-px w-full bg-primary-100" />
              <CartCouponCodeForm />
              {!!cart.coupons.length && (
                <div className="mt-4 flex flex-col gap-4">
                  {cart.coupons?.map((coupon, index) => (
                    <CartCouponCode
                      key={index}
                      code={coupon?.code}
                      name={coupon?.displayName}
                      amount={{
                        cents: coupon.appliedDiscountAmountCents,
                        currency: cart.couponDiscountTotal?.currency || '',
                      }}
                    />
                  ))}
                </div>
              )}
              <div className="my-4 h-px w-full bg-primary-100" />
            </>
          )}

          <Swap
            is={cartStore.isFreeShipping}
            isSlot={
              <CartSummaryItem
                title={t('estimatedShipping')}
                isPriceStrikeThrough
                afterPriceTitle={t('free')}
                total={getMoneyWithIsoCurrency(cart.shippingRate?.fee)}
              />
            }
          >
            <CartSummaryItem
              title={t('estimatedShipping')}
              total={getMoneyWithIsoCurrency(cart.shippingTotal)}
              data-test-id="estimated-shipping"
            />
          </Swap>

          <div className="my-4 h-px w-full bg-primary-100" />

          <div className="flex items-center justify-between text-gray-700">
            <p className="text-base font-medium">{t('totalBeforeTax')}</p>
            <p data-test-id="total-before-tax" className="text-base font-bold">
              {getMoneyWithIsoCurrency(cart.totalBeforeTax)}
            </p>
          </div>

          <div className="my-4 h-px w-full bg-primary-100" />

          <div
            data-test-id="retail-sales"
            className="mb-4 flex items-center justify-center text-sm text-gray-600"
          >
            {getMoneyWithIsoCurrency({
              cents: cart?.retailSales?.cents || 0,
              currency: cart?.retailSales?.currency || userStore.currency,
            })}{' '}
            {t('inRetailSales')}
          </div>

          <CartSummaryControls
            isShareLinkLoading={isCreatingShareCartLink}
            isCheckoutDisabled={isCheckoutDisabled}
            isHelperBlockVisible={isHelperBlockVisible}
            shareErrorTitle={shareCartErrorTitle}
            isShareDisabled={!userStore.isOSEnabled}
            onCheckout={onCheckout}
            onShare={openShareModalCallback}
            onSave={saveCartForLaterCallback}
            onCancel={cartAction.handleCancelOrder}
          />
        </div>
        <ShareBagModal
          partyId={cart.partyId}
          contactFirstName={cart.customerFirstName}
          contactLastName={cart.customerLastName}
          isOpen={isShareBagModalOpen}
          title={t('shareThisCart')}
          onClose={handleClose}
        />

        <ApplyCreditsConfirmationModal
          onClose={onCloseApplyCreditConfirmationModal}
          isOpen={isApplyCreditConfirmationModalOpen}
          isLoading={applyCreditsStore.isLoading}
          onApply={applyCreditsAction.handleApplyCreditsAndGoCheckout}
          onContinue={cartAction.handleNavigateToCheckout}
          applicableCredits={applyCreditsStore.applicableCartCredits}
        />
      </>
    )
  }
)
