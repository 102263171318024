import { useMemo } from 'react'

import { useI18nContext, useProfileContext } from '@nori/app-kit'
import { useTranslations } from '@nori/lang-i18n'
import { HelpLinks } from '~/modules/help-links/help-links'

import { HELP_LINKS } from '../../header-links/help-links'
import { getResourceLinks } from '../../header-links/product-info-links'

import { ChildrenList } from './children-list'
import { HeaderLink } from './header-link'

export function BarNav() {
  const t = useTranslations('header')
  const { userStore } = useProfileContext()
  const { i18nStore } = useI18nContext()

  const { currentLocale } = i18nStore

  const resourceLinks = useMemo(() => {
    return getResourceLinks(currentLocale)
  }, [currentLocale])

  const { productFirstColumn, productSecondColumn } = useMemo(() => {
    if (!resourceLinks.children?.length) {
      return {}
    }
    const divider = Math.ceil(resourceLinks.children.length / 2)
    const productFirstColumn = resourceLinks.children?.slice(0, divider)
    const productSecondColumn = resourceLinks.children?.slice(divider)
    return { productFirstColumn, productSecondColumn }
  }, [resourceLinks])

  return (
    <div className="mx-auto flex h-14 max-w-top-section-content flex-row items-center justify-between px-6 text-base font-normal text-gray-600">
      <div className="flex flex-row items-center gap-1.5 text-sm font-bold">
        {t('bottomNav.status')}:{' '}
        <span className="font-light capitalize">
          {userStore.nemusStatus} | {userStore.careerRank}
        </span>
      </div>

      <div className="flex h-full flex-row items-center gap-8">
        <HeaderLink
          target="_blank"
          key={resourceLinks.translationKey}
          link={resourceLinks}
          data-test-id={resourceLinks.dataTestId}
          align="right"
          isFullHeight
          borderRadiusType="sm"
          verticalAlign="top-13"
          listSlot={
            <div className="flex flex-row">
              <div>
                <ChildrenList linkChildren={productFirstColumn} />
              </div>
              <div className="mx-5 w-[1px] self-stretch bg-gray-100" />
              <div>
                <ChildrenList linkChildren={productSecondColumn} />
              </div>
            </div>
          }
        />
        <HeaderLink
          key={HELP_LINKS.translationKey}
          link={HELP_LINKS}
          data-test-id={HELP_LINKS.dataTestId}
          align="right"
          isFullHeight
          borderRadiusType="sm"
          verticalAlign="top-13"
          listSlot={<HelpLinks />}
        />
      </div>
    </div>
  )
}
