import { UserStore } from '@nori/app-kit'
import { DataState, Inject, Store } from '@nori/di'

import type { MoneyValueObject } from '@nori/app-kit'
import type { UserCreditsEntity, UserCreditsErrorInstance } from '../../core'

@Store()
export class CartUserCreditsStore {
  constructor(@Inject() private readonly userStore: UserStore) {}

  cartUserCredits = new DataState<UserCreditsEntity, UserCreditsErrorInstance>()

  getCurrency(): string | undefined {
    return this.cartUserCredits.data?.currency
  }

  get applicableStoreCredits(): MoneyValueObject | undefined {
    const cartUserCredits = this.cartUserCredits.data
    const storeCredits = this.cartUserCredits.data?.storeCredits

    const shouldDisplayStoreCredits = !!(storeCredits && storeCredits > 0)

    if (!shouldDisplayStoreCredits) return undefined

    return {
      cents: storeCredits,
      currency: cartUserCredits?.currency || this.userStore.currency,
    }
  }
}
