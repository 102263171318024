import { observer } from '@nori/di'
import { useRootAppStore } from '~/modules/root-app/interface/use-root-app-store'

import { DesktopContent } from './desktop-content'
import { MobileContent } from './mobile-content'

export const Footer = observer(() => {
  const { rootAppStore } = useRootAppStore()
  const isFooterVisible = rootAppStore.appStartSettings.value?.isFooterVisible

  if (!isFooterVisible) return <></>
  return (
    <div className="relative bg-primary-900 text-white max-lg:h-[426px] lg:h-32">
      <div className="absolute bottom-0 left-0 right-0 top-0 bg-primary-900 bg-[url('/images/footer-bg.png')] bg-center bg-repeat-x opacity-40" />

      <div className="relative mx-auto h-full w-full max-w-top-section-content max-lg:hidden">
        <DesktopContent />
      </div>

      <div className="relative h-full w-full lg:hidden">
        <MobileContent />
      </div>
    </div>
  )
})
