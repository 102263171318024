import { useMemo } from 'react'

import { useI18nContext, useProfileContext } from '@nori/app-kit'

import type { LangType } from '@nori/ui-kit'
import type { LinkWithChildren } from '../../header-links/header-link.type'

const langMap: Record<LangType, string> = {
  'es-US': 'Spanish',
  'en-US': 'English',
  'en-CA': 'English',
  'fr-CA': 'French',
}

const langTranslationKeyMap: Record<LangType, IntlMessageKeys> = {
  'es-US': 'header.bottomNav.languages.spanish',
  'en-US': 'header.bottomNav.languages.english',
  'en-CA': 'header.bottomNav.languages.english',
  'fr-CA': 'header.bottomNav.languages.french',
}

export function useLanguageLinks() {
  const { userLocaleStore } = useProfileContext()
  const { userLocales } = userLocaleStore
  const { i18nAction } = useI18nContext()
  const { handleSetLocale } = i18nAction

  const languageLinks: LinkWithChildren = useMemo(
    () => ({
      dataTestId: 'language-option',
      href: '#',
      translationKey: 'header.bottomNav.language',
      children: userLocales.map((locale) => ({
        dataTestId: `language-${langMap[locale]}-option`,
        href: {},
        translationKey: langTranslationKeyMap[locale],
        onClick: () => handleSetLocale(locale),
      })),
    }),
    [userLocales, handleSetLocale]
  )

  return {
    languageLinks,
  }
}
