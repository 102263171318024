var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __name = (target, value) => __defProp(target, "name", { value, configurable: true });
var __publicField = (obj, key, value) => {
  __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
  return value;
};

// src/interface/block-translator.ts
import { createTranslator } from "next-intl";

// src/interface/merge-deep.ts
function isObject(item) {
  return item && typeof item === "object" && !Array.isArray(item);
}
__name(isObject, "isObject");
function mergeDeep(target, source) {
  const output = {
    ...target
  };
  if (isObject(target) && isObject(source)) {
    Object.keys(source).forEach((key) => {
      if (isObject(source[key])) {
        if (!(key in target))
          Object.assign(output, {
            [key]: source[key]
          });
        else
          output[key] = mergeDeep(target[key], source[key]);
      } else {
        Object.assign(output, {
          [key]: source[key]
        });
      }
    });
  }
  return output;
}
__name(mergeDeep, "mergeDeep");

// src/interface/block-translator.ts
var DEFAULT_LANG_LOCALE = "en-US";
var BlockTranslator = /* @__PURE__ */ __name(class BlockTranslator2 {
  constructor() {
    __publicField(this, "locale", DEFAULT_LANG_LOCALE);
    __publicField(this, "messages", {});
    __publicField(this, "getBlock", /* @__PURE__ */ __name((namespace) => {
      return createTranslator({
        locale: this.locale,
        messages: this.messages,
        namespace
      });
    }, "getBlock"));
  }
  appendMessages(messages) {
    this.messages = mergeDeep(this.messages || {}, messages || {});
  }
  updateMessages({ locale = DEFAULT_LANG_LOCALE, messages }) {
    this.locale = locale;
    this.messages = messages || {};
  }
}, "BlockTranslator");
var blockTranslator = new BlockTranslator();
var getBlock = blockTranslator.getBlock;

// src/interface/inlt-lib.ts
import { NextIntlProvider as NextProvider, useTranslations as useT } from "next-intl";
var NextIntlProvider = NextProvider;
var useTranslations = useT;

// src/interface/merge-messages.ts
function mergeMessages(messages) {
  return messages.reduce((acc, block) => {
    return mergeDeep(acc, block);
  }, {});
}
__name(mergeMessages, "mergeMessages");
export {
  DEFAULT_LANG_LOCALE,
  NextIntlProvider,
  blockTranslator,
  getBlock,
  mergeMessages,
  useTranslations
};
