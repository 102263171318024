import { Service } from '@nori/di'

import type { UpdateBundleSubscriptionInput } from '../types/update-bundle-cart-subscription.input'
import type { UpdateSubscriptionInput } from '../types/update-cart-subscription.input'
import type {
  UpdateBundleSubscriptionRequestBody,
  UpdateSubscriptionRequestBody,
} from '../types/update-cart-subscription.request-body'

@Service()
export class CartSubscriptionMapper {
  toRequestBody(input: UpdateSubscriptionInput): UpdateSubscriptionRequestBody {
    return {
      old_subscription_plan_template_id: input.oldSubscriptionPlan,
      subscription_plan_template_id: input.subscriptionPlan,
      sku_id: input.skuId,
    }
  }

  toBundleRequestBody(
    input: UpdateBundleSubscriptionInput
  ): UpdateBundleSubscriptionRequestBody {
    return {
      old_subscription_plan_template_id: input.oldSubscriptionPlan,
      subscription_plan_template_id: input.subscriptionPlan,
      bundle_id: input.bundleId,
    }
  }
}
