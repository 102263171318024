// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable camelcase */

import { UserStore } from '@nori/app-kit'
import { addDays, format, isWithinInterval } from '@nori/date'
import { Action, Inject } from '@nori/di'

import { AppcuesStore } from '../store/appcues.store'

@Action()
export class AppcuesAction {
  constructor(
    @Inject() private readonly appcuesStore: AppcuesStore,
    @Inject() private readonly userStore: UserStore
  ) {}

  private get appcues() {
    if (typeof window === 'undefined') {
      return
    }
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    return window.Appcues
  }

  handleAppcuesLoaded(): void {
    this.appcuesStore.setIsLoaded(true)
  }

  handleIdentify(): void {
    const {
      firstName,
      lastName,
      dsrLogin,
      displayName,
      isActiveDsr,
      jumpStartDate,
      accessLevel,
      locale,
    } = this.userStore.currentUser

    const consultantInFirst90Days = jumpStartDate
      ? isWithinInterval(new Date(), {
          start: jumpStartDate,
          end: addDays(jumpStartDate, 90),
        })
      : undefined

    const consultantStatus = isActiveDsr ? 'active' : 'inactive'

    this.appcues?.identify(dsrLogin, {
      name: displayName,
      first_name: firstName,
      last_name: lastName,
      consultant_start_date: jumpStartDate?.toISOString(),
      consultant_in_first90_days: consultantInFirst90Days,
      career_level: accessLevel,
      region: locale,
      consultant_status: consultantStatus,
      //   bank_account_setup: false,
      //   consultant_qualified: true,
      //   consultant_in_first15_days: false,
      //   consultant_has_booked_party: false,
      //   consultant_has_submitted_order: false,
      //   consultant_has_recruited: true,
      //   consultant_date_of_free_kit_earned: '',
      //   consultant_date_of_virtual_kit_earned: '',
      //   consultant_date_of_last_promotion: '',
      //   consultant_last_promotion_level: '',
      //   consultant_date_of_first_party: '',
      //   consultant_month_to_date_parties: 0,
      //   consultant_sold_in_first_15_days: 0.0,
      //   consultant_sold_in_first_30_days: 0.0,
      //   consultant_sold_in_first_45_days: 0.0,
      //   consultant_sold_in_first_60_days: 0.0,
      //   consultant_sold_in_first_90_days: 0.0,
      //   consultant_sold_in_first_6_months: 0.0,
      //   consultant_month_to_date_sales: 0.0,
      //   consultant_sold_in_last_15_days: 0.0,
      //   consultant_sold_in_last_30_days: 0.0,
      //   consultant_sold_in_last_45_days: 0.0,
      //   consultant_sold_in_last_60_days: 0.0,
      //   consultant_sold_in_last_90_days: 0.0,
      //   consultant_sold_in_best_month: 0.0,
      //   consultant_date_of_best_month: '',
      //   consultant_month_to_date_new_customers: 0,
      //   consultant_total_customers: 0,
      //   consultant_date_of_first_recruit: '08-12-2015',
      //   consultant_month_to_date_recruits: 0,
      //   consultant_total_recruits: 0,
      //   consultant_date_of_last_party: '',
      //   consultant_date_of_last_sale: '',
      //   consultant_date_of_last_recruit: '06-01-2023',
      //   consultant_has_registered_for_national_conference: false,
      //   consultant_has_registered_for_leadership_conference: false,
      //   consultant_status: 'active',
      //   region: 'en-US',
    })
  }
}
