import {
  calculateMoneyDifference,
  createAddressValidator,
  UserStore,
} from '@nori/app-kit'
import { DataState, Inject, Store, Toggle } from '@nori/di'
import { Validator } from '@nori/validator'
import { BillingFormStore } from '~/modules/billing-form'
import { CartStore } from '~/modules/cart/interface/store/cart-store/cart.store'

import type {
  BraintreeAddressValueObject,
  CartAddressEntity,
  CartShippingRateEntity,
  MoneyValueObject,
  ValidatorAddressWithUserNamesAndPhoneType,
} from '@nori/app-kit'
import type { DomainError } from '@nori/errors'
import type { CreditCardPriorityValueObject } from '~/modules/billing-form'
import type { FinancialsErrorsInstance } from '~/modules/dashboard/core/financials.errors'
import type { LandingPageReportsValueObject } from '~/modules/dashboard/core/landing-page-reports.value-object'
import type {
  CheckoutStatusInventoryError,
  UnavailableSkuValueObject,
} from '../../core'

const ACTIVE_DSR_STATUSES = new Set(['active', 'engaged'])

@Store()
export class CheckoutStore {
  constructor(
    @Inject() private readonly cartStore: CartStore,
    @Inject() private readonly userStore: UserStore,
    @Inject() private readonly billingFormStore: BillingFormStore
  ) {}

  isLoading = false
  error?: DomainError = undefined

  unavailableInventoryToggle = new Toggle()

  unavailableIntentoryItems: CheckoutStatusInventoryError[] = []

  unavailableSkus: UnavailableSkuValueObject[] = []

  shippingRate?: CartShippingRateEntity = undefined

  shippingAddressValidator = createAddressValidator('with-user-and-phone')

  isEditShippingInformation = false
  isUpdatingShippingAddress = false
  isExceededCheckoutAttempts = false

  landingReports = new DataState<
    LandingPageReportsValueObject,
    FinancialsErrorsInstance
  >()

  get prsAmountNeeded(): MoneyValueObject | undefined {
    const nemusStatus = this.userStore.nemusStatus.toLowerCase()

    const difference = calculateMoneyDifference({
      minuend: this.landingReports.data?.active.amountNeeded,
      subtrahend: this.cartStore.cart?.retailSales,
    })

    if (!difference || difference.cents <= 0) return undefined
    if (ACTIVE_DSR_STATUSES.has(nemusStatus)) return undefined
    return {
      cents: difference.cents,
      currency: this.userStore.currency,
    }
  }

  applyProductCreditsValidator = new Validator({
    productCreditsAmount: Validator.scheme.number(),
  })

  applyStoreCreditsValidator = new Validator({
    storeCreditsAmount: Validator.scheme.string(),
  })

  get email(): string {
    if (this.cartStore.isCustomerCart) {
      return this.cartStore.cart?.customerEmail ?? ''
    }

    return this.userStore.currentUser.email
  }

  get errorTitle(): string {
    if (!this.error) return ''

    return 'Something goes wrong'
  }

  get shippingAddress(): BraintreeAddressValueObject {
    return this.getBraintreeCheckoutAddress(this.shippingAddressValidator)
  }

  get isCart(): boolean {
    return !!this.cartStore.cart
  }

  get allowedShippingRates(): CartShippingRateEntity[] {
    return this.cartStore.cart?.allowedShippingRates ?? []
  }

  get hasPaymentDue(): boolean {
    const zeroPaymentDue =
      this.cartStore.cart?.fullyPaidByCreditsAndDiscounts ||
      this.cartStore.cart?.fullyPaidByStoreCredits ||
      !this.cartStore.cart?.total?.cents ||
      this.cartStore.cart?.total.cents === 0

    return !zeroPaymentDue
  }

  get isBillingAddressSameAsShipping(): boolean {
    if (!this.hasPaymentDue) return true
    return this.billingFormStore.isBillingAddressSameAsShipping
  }

  get billingAddresses(): Record<
    CreditCardPriorityValueObject,
    BraintreeAddressValueObject
  > {
    return {
      main: this.billingAddress,
      second: this.secondBillingAddress,
    }
  }

  get billingAddress(): BraintreeAddressValueObject {
    if (
      this.billingFormStore.creditCardModeMap.main === 'saved' &&
      this.billingFormStore.selectedCardMap.main.data
    ) {
      const { address1, city, country, state, zipCode, address2, phoneNumber } =
        this.billingFormStore.selectedCardMap.main.data.billingAddress

      return {
        address1,
        city,
        country,
        firstName:
          this.billingFormStore.selectedCardMap.main.data.firstName ?? '',
        lastName:
          this.billingFormStore.selectedCardMap.main.data.lastName ?? '',
        state,
        zipCode,
        address2,
        phoneNumber,
      }
    }

    if (!this.isBillingAddressSameAsShipping) {
      return this.getBraintreeCheckoutAddress(
        this.billingFormStore.billingAddressValidatorMap.main
      )
    }

    return this.getBraintreeCheckoutAddress(this.shippingAddressValidator)
  }

  get secondBillingAddress(): BraintreeAddressValueObject {
    if (
      this.billingFormStore.creditCardModeMap.second === 'saved' &&
      this.billingFormStore.selectedCardMap.second.data
    ) {
      const { address1, city, country, state, zipCode, address2, phoneNumber } =
        this.billingFormStore.selectedCardMap.second.data.billingAddress
      return {
        address1,
        city,
        country,
        firstName:
          this.billingFormStore.selectedCardMap.second.data.firstName ?? '',
        lastName:
          this.billingFormStore.selectedCardMap.second.data.lastName ?? '',
        state,
        zipCode,
        address2,
        phoneNumber,
      }
    }

    switch (this.billingFormStore.secondBillingAddressVariant) {
      case 'shipping':
        return this.shippingAddress
      case 'main':
        return this.billingAddress
      default:
        return this.getBraintreeCheckoutAddress(
          this.billingFormStore.billingAddressValidatorMap.second
        )
    }
  }

  setUnavailableInventoryItems(items: CheckoutStatusInventoryError[]): void {
    this.unavailableIntentoryItems = items
  }

  setUnavailableSkus(skus: UnavailableSkuValueObject[]): void {
    this.unavailableSkus = skus
  }

  setShippingRate(shippingRate: CartShippingRateEntity): void {
    this.shippingRate = shippingRate
  }

  setIsLoading(isLoading: boolean): void {
    this.isLoading = isLoading
  }

  setError(error?: DomainError): void {
    this.error = error
  }

  setInitialShippingAddress(): void {
    this.shippingAddressValidator.reset()
  }

  setIsExceededCheckoutAttempts(isError: boolean): void {
    this.isExceededCheckoutAttempts = isError
  }

  setShippingAddress(shippingAddress: CartAddressEntity): void {
    const { handlers } = this.shippingAddressValidator

    handlers.address1(shippingAddress.address1)
    handlers.address2(shippingAddress.address2)
    handlers.city(shippingAddress.city)
    handlers.firstName(shippingAddress.firstName)
    handlers.lastName(shippingAddress.lastName)
    handlers.state(shippingAddress.state)
    handlers.zipCode(shippingAddress.zipCode)
    handlers.phone(shippingAddress.phone)
  }

  private getBraintreeCheckoutAddress(
    validator: ValidatorAddressWithUserNamesAndPhoneType
  ): BraintreeAddressValueObject {
    return {
      address1: validator.values.address1,
      address2: validator.values.address2,
      city: validator.values.city,
      country: validator.values.country,
      state: validator.values.state,
      zipCode: validator.values.zipCode,
      firstName: validator.values.firstName,
      lastName: validator.values.lastName,
      phoneNumber: validator.values.phone,
    }
  }

  setIsEditShippingInformation(value: boolean): void {
    this.isEditShippingInformation = value
  }

  setIsUpdatingShippingAddress(value: boolean): void {
    this.isUpdatingShippingAddress = value
  }
}
