import { getSkuName, getStyleItemName } from '@nori/app-kit'

type Props = {
  productName: string
  styleName?: string
  skuVariation?: string
  skuName?: string
  skuCode?: string
}

export function ProductItemName({
  productName,
  styleName,
  skuVariation,
  skuName,
  skuCode,
}: Props) {
  const subtitle = [
    getSkuName({
      variation: skuVariation,
      name: skuName,
    }),
    skuCode,
  ]
    .filter(Boolean)
    .join(', ')

  return (
    <div>
      <p data-test-id="item-name" className="text-base font-bold text-gray-700">
        {getStyleItemName({
          productName,
          styleName,
        })}
      </p>
      <p
        data-test-id="item-sku-name"
        className="text-sm font-medium text-gray-500"
      >
        {subtitle}
      </p>
    </div>
  )
}
