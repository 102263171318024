import { errorFactory } from '@nori/errors'

import type { KeyOfErrors } from '@nori/errors'

export const { EnrollmentErrors } = errorFactory('EnrollmentErrors', {
  AuthCookiesUndefined: 'Authorization cookies not defined',

  GetStarterKitsUnknownError: 'Unknown error while loading starter kits',
  UnexpectedGetStarterKitsError: 'Unexpected load starter kits error',

  GetUpgradeKitsUnknownError: 'Unknown error while loading upgrade kits',
  UnexpectedGetUpgradeKitsError: 'Unexpected error while loading upgrade kits',

  GetEcomUserUnknownError: 'Unknown error while loading ecom user',
  UnexpectedGetEcomUserError: 'Unexpected error while loading ecom user',

  AssignMentorUnknownError: 'Unknown error while assigning mentor',
  UnexpectedAssignMentorError: 'Unexpected error while assigning mentor',
})

export type EnrollmentErrorInstance = InstanceType<
  KeyOfErrors<typeof EnrollmentErrors>
>
