import { getLink } from '@nori/app-kit'
import { DataState, Store } from '@nori/di'
import { getBlock } from '@nori/lang-i18n'

import type { WishListErrorInstance } from '~/modules/wishlist/core/error/wish-list.errors'
import type { WishlistShareLinkValueObject } from '~/modules/wishlist/core/value-object/share-link.value-object'
import type { ProcessingItemValueObject } from '../../core/value-object/processing-item.value-object'
import type { WishListValueObject } from '../../core/value-object/wish-list.value-object'

const FB_APP_ID = process.env.FB_APP_ID

@Store()
export class WishListStore {
  wishlist = new DataState<WishListValueObject, WishListErrorInstance>({
    isLoading: false,
  })
  wishlistShareLinkObject?: WishlistShareLinkValueObject = undefined

  customerCode?: string = undefined

  isLoadingMore = false
  isLoadingMoreError = false

  isAddingToCart = false

  processingItemMap: ProcessingItemValueObject = {}

  isItemInWishList(itemId: number): boolean {
    return !!this.wishlist.data?.items.some(
      (wishListItem) => wishListItem.id === itemId
    )
  }

  setIsLoadingMore(value: boolean): void {
    this.isLoadingMore = value
  }

  setIsLoadingMoreError(value: boolean): void {
    this.isLoadingMoreError = value
  }

  setProcessingItem(id: number, value: boolean): void {
    if (!value) {
      delete this.processingItemMap[id]
      return
    }

    this.processingItemMap[id] = value
  }

  setIsAddingToCart(value: boolean): void {
    this.isAddingToCart = value
  }

  setCustomerCode(code?: string): void {
    this.customerCode = code
  }

  get wishlistShareMailUrl(): string {
    return `mailto:?subject=${getBlock('wishList')(
      'share_mail_subject'
    )}&body=${getBlock('wishList')('share_mail_body')} %0D%0A ${
      this.wishlistShareLink
    }`
  }

  get wishlistShareMessengerUrl(): string {
    return `https://www.facebook.com/dialog/send?app_id=${FB_APP_ID}&link=${this.wishlistShareLink}`
  }

  get wishlistShareFacebookUrl(): string {
    return `https://www.facebook.com/sharer/sharer.php?u=${this.wishlistShareLink}`
  }

  get wishlistShareTwitterUrl(): string {
    return `https://twitter.com/share?hashtags=norwex&text=${getBlock(
      'wishList'
    )('share_text')}&url=${this.wishlistShareLink}`
  }

  get wishlistSharePinterestUrl(): string {
    return `https://www.pinterest.com/pin/create/button/?description=${getBlock(
      'wishList'
    )('share_text')}&url=${this.wishlistShareLink}`
  }

  get wishlistShareLink(): string {
    const personalLink = this.wishlistShareLinkObject?.referrerPersonalLink
      ? `?s=${this.wishlistShareLinkObject?.referrerPersonalLink}`
      : ''

    return getLink({
      type: 'bo',
      personalLink: `wishlist/${this.wishlistShareLinkObject?.urlCode}${personalLink}`,
    }).url
  }

  setWishlistShareLinkObject(value: WishlistShareLinkValueObject): void {
    this.wishlistShareLinkObject = value
  }
}
