import { observer } from '@nori/di'
import { useTranslations } from '@nori/lang-i18n'
import { Icon, Link } from '@nori/ui-kit'

import { useCouponCodeContext } from '../interface/use-coupon-code-context'

import type { MoneyValueObject } from '@nori/app-kit'

type Props = {
  code?: string
  name?: string
  amount?: MoneyValueObject
}

export const CartCouponCode = observer(({ code, name }: Props) => {
  const { couponCodeAction, couponCodeStore } = useCouponCodeContext()
  const t = useTranslations()

  if (!code) return null

  return (
    <div className="flex items-start justify-between gap-3">
      <div className="flex flex-1 items-start gap-1 text-primary-900">
        <Icon size="s" name="CheckCircle" />
        <div className="break-words text-xs leading-[18px] text-black">
          <span className="font-bold uppercase">
            {code} <span>({name})</span>
          </span>
          <span className="ml-1"> {t('CouponCode.applied')}</span>
        </div>
      </div>
      <div className="flex flex-shrink-0 flex-grow-0 basis-auto items-start">
        <Link
          size="small"
          weight="regular"
          isUnderline
          isDisabled={couponCodeStore.couponCodeState.isLoading}
          onClick={() => couponCodeAction.handleDeleteCouponCode(code)}
        >
          {t('CouponCode.remove')}
        </Link>
      </div>
    </div>
  )
})
