// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable camelcase */
import { centsToMainUnit, HistoryService, UserStore } from '@nori/app-kit'
import { Action, Inject } from '@nori/di'

import { Event } from '../core/event.enum'
import { GtmAdapter } from '../infra/gtm.adapter'
import { TrackMapper } from '../infra/mappers/track.mapper'

import type {
  CartBundleEntity,
  CartEntity,
  CartItemEntity,
  CommonBundleEntity,
  CommonStyleEntity,
} from '@nori/app-kit'
import type { ConfirmedOrderEntity } from '~/modules/checkout/core/confirmed-order.entity'
import type { ProductListValueObject } from '~/modules/products'
import type { TrackNavigationData } from '../core/entities/track-data.entity'

@Action()
export class GtmAction {
  constructor(
    @Inject() private readonly gtmAdapter: GtmAdapter,
    @Inject() private readonly userStore: UserStore,
    @Inject() private readonly historyService: HistoryService,
    @Inject() private readonly trackMapper: TrackMapper
  ) {}

  handleTrackViewItemList(
    list: ProductListValueObject,
    searchQuery: string
  ): void {
    if (!list.items.length) return

    const data = {
      event: Event.ViewItemList,
      ecommerce: {
        item_list_search: searchQuery,
        item_list_page: list.pagination.page,
        items:
          this.trackMapper.toTrackDataItemsFromProductListValueObject(list),
      },
    }
    this.gtmAdapter.track(data)
  }

  handleTrackAddShippingInfo(cart: CartEntity): void {
    const data = {
      event: Event.AddShippingInfo,
      ecommerce: {
        shiping_value: centsToMainUnit({
          cents: cart.shippingTotal?.cents || 0,
        }),
        item_value: centsToMainUnit({ cents: cart.itemTotal?.cents || 0 }),
        ...this.trackMapper.toTrackDataFromCartEntity(cart),
      },
    }
    this.gtmAdapter.track(data)
  }

  handleTrackBeginCheckout(cart: CartEntity): void {
    const data = {
      event: Event.BeginCheckout,
      ecommerce: this.trackMapper.toTrackDataFromCartEntity(cart),
    }
    this.gtmAdapter.track(data)
  }

  handleTrackViewCart(cart: CartEntity): void {
    const data = {
      event: Event.ViewCart,
      ecommerce: this.trackMapper.toTrackDataFromCartEntity(cart),
    }
    this.gtmAdapter.track(data)
  }

  handleTrackRemoveItemFromCart(item: CartItemEntity): void {
    const data = {
      event: Event.RemoveFromCart,
      ecommerce: {
        currency: item.price?.currency,
        value: centsToMainUnit({ cents: item.price?.cents || 0 }),
        items: this.trackMapper.toTrackDataFromCartItemUnion([item]),
      },
    }
    this.gtmAdapter.track(data)
  }

  handleTrackRemoveBundleFromCart(item: CartBundleEntity): void {
    const data = {
      event: Event.RemoveFromCart,
      ecommerce: {
        currency: item.price?.currency,
        value: centsToMainUnit({ cents: item.price?.cents || 0 }),
        items: this.trackMapper.toTrackDataFromCartItemUnion([item]),
      },
    }
    this.gtmAdapter.track(data)
  }

  handleTrackItemAddToCart(
    item: CommonStyleEntity,
    selectedSkuId: number,
    quantity: number
  ): void {
    const sku = item.skus?.find((s) => s.id === selectedSkuId)
    if (!sku) return
    const data = {
      event: Event.AddToCart,
      ecommerce: {
        currency: sku.currentRetailPrice.currency,
        value: centsToMainUnit({ cents: sku.currentRetailPrice.cents }),
        items: [
          {
            product_id: item.id,
            sku_id: sku.id,
            item_name: item.product.name,
            item_brand: item.product.brandName,
            price: centsToMainUnit({ cents: sku.currentRetailPrice.cents }),
            quantity,
            is_bundle: false,
          },
        ],
      },
    }
    this.gtmAdapter.track(data)
  }

  handleTrackAddBundleToCart(item: CommonBundleEntity, quantity: number): void {
    const data = {
      event: Event.AddToCart,
      ecommerce: {
        currency: item.maxPrice?.currency,
        value: centsToMainUnit({ cents: item.maxPrice?.cents || 0 }),
        items: [
          {
            product_id: item.id,
            item_name: item.name,
            item_brand: item.brandName,
            price: centsToMainUnit({ cents: item.maxPrice?.cents || 0 }),
            quantity,
            is_bundle: true,
          },
        ],
      },
    }
    this.gtmAdapter.track(data)
  }

  handleTrackNavigation(): void {
    const data: TrackNavigationData = {
      event: Event.Navigation,
      customerType: 'Consultant',
      location: this.historyService.asPath,
      locationTitle: document.title,
      loggedIn: !!this.userStore.id,
      uid: this.userStore.id || undefined,
    }
    this.gtmAdapter.track(data)
  }

  handleTrackPurchase(confirmedOrderData: ConfirmedOrderEntity): void {
    const data = {
      event: Event.Purchase,
      ecommerce:
        this.trackMapper.toTrackDataItemFromConfirmedOrderEntity(
          confirmedOrderData
        ),
    }

    this.gtmAdapter.track(data)
  }

  private isBundle(item: CartItemEntity): boolean {
    return !!item.bundle?.code
  }
}
