import { useEffect } from 'react'

import { AddressBlock } from '@nori/app-kit'
import { observer } from '@nori/di'
import { useTranslations } from '@nori/lang-i18n'
import { Button, Form, Link, PageContainer, PageHeader } from '@nori/ui-kit'
import { useContactContext } from '~/modules/contacts/interface/use-contact-context'
import { PageLayout } from '~/modules/page-layout'

import { ContactBlock } from './contact-block'

export const CreateContactPage = observer(() => {
  const t = useTranslations('createContactPage')
  const { createContactStore, createContactAction } = useContactContext()
  const { isLoading } = createContactStore

  useEffect(() => {
    return () => {
      createContactStore.contactValidator.reset()
      createContactStore.addressValidator.reset()
      createContactStore.contactWithoutEmailToggle.handleClose()
    }
  }, [])
  return (
    <PageLayout>
      <PageHeader title={t('title')} />
      <PageContainer>
        <Form onSubmit={createContactAction.handleSubmit}>
          <div className="mx-auto my-0 flex max-w-3xl flex-col">
            <ContactBlock
              validator={createContactStore.contactValidator}
              isContactWithoutEmail={
                createContactStore.contactWithoutEmailToggle.isOpen
              }
              handleContactWithoutEmailToggle={
                createContactAction.handleContactWithoutEmailToggle
              }
              isShowContactWithoutEmailCheckbox
            />
            <AddressBlock validator={createContactStore.addressValidator} />

            <div className="mt-5" />
            <Button
              type="submit"
              isLoading={isLoading}
              data-test-id={'contact-form-submit-button'}
              size="lg"
            >
              {t('submit')}
            </Button>
            <div className="mt-5 text-center">
              <Link size="medium" onClick={createContactAction.handleCancel}>
                {t('cancel')}
              </Link>
            </div>
          </div>
        </Form>
      </PageContainer>
    </PageLayout>
  )
})
