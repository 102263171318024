import { Inject, Service } from '@nori/di'

import { OrderMapper } from '../../infra/mappers/order.mapper'

import type { DsrEntity } from '../../core'

@Service()
export class OrderMapperService {
  constructor(@Inject() private readonly orderMapper: OrderMapper) {}

  toDsrEntity(data: any): DsrEntity {
    return this.orderMapper.toDsrEntity(data)
  }
}
