import { useEffect } from 'react'

import { AddressBlock } from '@nori/app-kit'
import { observer } from '@nori/di'
import { useTranslations } from '@nori/lang-i18n'
import { Button, Checkbox, Form, Input, PhoneInput } from '@nori/ui-kit'
import { useContactContext } from '~/modules/contacts/interface/use-contact-context'

type Props = {
  onSaveContact: () => void
  withAddress?: boolean
  isSavingLoading?: boolean
  withPhoneNumber?: boolean
}

export const CreateContactBlock = observer(
  ({ onSaveContact, isSavingLoading, withAddress, withPhoneNumber }: Props) => {
    const t = useTranslations()
    const { findOrCreateContactAction, findOrCreateContactStore } =
      useContactContext()
    const handlers = findOrCreateContactAction.createContactFieldHandlers
    const validator = findOrCreateContactStore.contactValidator
    const isShowContactWithoutEmailCheckbox =
      !findOrCreateContactStore.isEditContact

    const isEmailInputDisabled =
      isSavingLoading || findOrCreateContactStore.isEmailInputDisabled

    useEffect(() => {
      return () => {
        findOrCreateContactAction.handleResetContactForm()
      }
    }, [])

    return (
      <Form onSubmit={onSaveContact}>
        <div className="lg:flex lg:gap-8">
          <div data-test-id="contact-first-name-input" className="basis-1/2">
            <Input
              label={t('contact.firstName')}
              placeholder={t('contact.firstName')}
              value={validator.values.firstName}
              onChange={handlers.firstName}
              error={validator.errors.firstName}
              disabled={isSavingLoading ?? findOrCreateContactStore.isLoading}
            />
          </div>
          <div data-test-id="contact-last-name-input" className="basis-1/2">
            <Input
              label={t('contact.lastName')}
              placeholder={t('contact.lastName')}
              value={validator.values.lastName}
              onChange={handlers.lastName}
              error={validator.errors.lastName}
              disabled={isSavingLoading ?? findOrCreateContactStore.isLoading}
            />
          </div>
        </div>

        <div className="mt-3" />

        <Input
          data-test-id="contact-email-input"
          label={t('contact.email')}
          placeholder={t('contact.email')}
          value={findOrCreateContactStore.emailValue}
          onChange={handlers.email}
          error={validator.errors.email}
          disabled={isEmailInputDisabled}
          helperText={findOrCreateContactStore.emailHelperText}
        />
        {isShowContactWithoutEmailCheckbox && (
          <Checkbox
            value={findOrCreateContactStore.contactWithoutEmailToggle.isOpen}
            onChange={findOrCreateContactAction.handleContactWithoutEmailToggle}
          >
            {t('contact.createContactWithoutEmail')}
          </Checkbox>
        )}
        {withPhoneNumber && (
          <>
            <div className="mt-2 lg:mt-10"></div>
            <PhoneInput
              onChange={handlers.phone}
              value={validator.values.phone}
              error={validator.errors.phone}
              label={t('contact.phone')}
              placeholder={t('contact.phone')}
            />
          </>
        )}
        <div className="mt-3" />
        {withAddress && (
          <AddressBlock validator={findOrCreateContactStore.addressValidator} />
        )}
        <div className="mt-3" />
        {!findOrCreateContactStore.contactWithoutEmailToggle.isOpen && (
          <p className="mb-5 text-sm text-gray-500">
            {t('contact.emailDescription')}
          </p>
        )}
        <Button
          data-test-id="submit-create-contact-button"
          size="lg"
          widthType="full"
          isLoading={isSavingLoading ?? findOrCreateContactStore.isLoading}
          type="submit"
        >
          {t(
            findOrCreateContactStore.isEditContact
              ? 'contact.editContact'
              : 'contact.createContact'
          )}
        </Button>
      </Form>
    )
  }
)
