import type { MessageKeys } from '@nori/lang-i18n'
import type { AddToCartStatus } from '~/modules/cart/interface/store/cart-store/cart-store.types'

export const addToCartStatusMap: Record<
  AddToCartStatus,
  MessageKeys<
    {
      add: string
      added: string
      failedAdd: string
      outOfStock: string
      loading: string
      customize: string
    },
    'loading' | 'add' | 'added' | 'failedAdd' | 'outOfStock' | 'customize'
  >
> = {
  default: 'add',
  error: 'failedAdd',
  loading: 'loading',
  success: 'added',
  outOfStock: 'outOfStock',
  customize: 'customize',
}
