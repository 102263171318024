import Link from 'next/link'

import { useProfileContext, useSignOutContext } from '@nori/app-kit'
import { observer } from '@nori/di'
import { useTranslations } from '@nori/lang-i18n'
import { Avatar, Icon, Link as HyperLink, useClickOutside } from '@nori/ui-kit'
import { useHeaderContext } from '~/modules/header/interface/use-header-context'

import { USER_LINKS } from '../../header-links/user-links'

export const UserMenu = observer(() => {
  const t = useTranslations()
  const { headerStore, headerAction } = useHeaderContext()
  const { signOutAction } = useSignOutContext()
  const { userStore } = useProfileContext()

  const { ref } = useClickOutside(() => {
    headerAction.handleToggleUserMenu()
  })

  const userFullName = [
    userStore.currentUser.firstName,
    userStore.currentUser.lastName,
  ].join(' ')

  if (!headerStore.isUserMenuVisible) return null
  return (
    <div
      ref={ref}
      className="absolute max-h-[calc(100vh-60px)] w-full flex-1 bg-white text-sm font-medium text-white shadow-lg"
    >
      <div className="flex flex-row bg-primary-50 p-5">
        <div>
          <Avatar
            isBlank={userStore.currentUser.profilePhoto.isBlank}
            imageUrl={userStore.currentUser.profilePhoto.thumbnailSmallUrl}
            size="xl"
          />
        </div>
        <div className="ml-5 flex min-w-0 flex-1 flex-col items-start gap-1.5">
          <div className="text-lg font-normal text-primary-900">
            {userFullName}
          </div>
          <div className="text-lg font-normal text-primary-900">
            <span className="text-xs font-bold">
              {t('header.userMenu.status')}:{' '}
            </span>
            <span className="text-xs font-normal capitalize">
              {userStore.nemusStatus} | {userStore.careerRank}
            </span>
          </div>
          {userStore.personalLink && (
            <HyperLink
              data-test-id="mobile-header-user-dropdown-pws-link"
              href={userStore.personalLink?.url}
              isTruncate
              justifyContent="normal"
            >
              {userStore.personalLink?.title}
            </HyperLink>
          )}
          <div className="text-xs font-light text-gray-600">
            {t('header.userMenu.id')}: {userStore.currentUser.dsrLogin}
          </div>
        </div>
      </div>
      {USER_LINKS.children?.map((child) => (
        <Link
          className="flex flex-row justify-between border-primary-700 px-4 py-3 font-bold text-primary-900"
          key={child.translationKey}
          href={child.href}
          onClick={headerAction.handleToggleUserMenu}
        >
          {t(child.translationKey)}
        </Link>
      ))}

      <button
        onClick={() => {
          signOutAction.handleSignOut()
          headerAction.handleToggleUserMenu()
        }}
        className="flex flex-row items-center justify-between gap-0.5 border-primary-700 px-4 py-3 font-bold text-primary-900"
      >
        <Icon size="xs" name="Logout" />
        {t('header.userMenu.signOut')}
      </button>
    </div>
  )
})
