import type { AgreementVersionEntity } from '../../core/entities/agreement-version.entity'
import type { GetAgreementVersionResponse } from '../types/get-agreement-version.response'

export class AgreementVersionMapper {
  static toAgreementVersionEntity(
    response: GetAgreementVersionResponse
  ): AgreementVersionEntity {
    return {
      currentVersion: response.current_version,
      lastAcceptedVersion: response.last_accepted_version || undefined,
    }
  }
}
