import { type CreditEntity, getMoneyWithIsoCurrency } from '@nori/app-kit'
import { format } from '@nori/date'
import { useTranslations } from '@nori/lang-i18n'
import { TableCell, TableRow } from '@nori/ui-kit'

type Props = {
  record: CreditEntity
}

export function CreditHistoryCreditRecord({ record }: Props) {
  const t = useTranslations('contactDetailsPage.norwexPerks.creditHistory')
  const expires = record.expiresAt
    ? format(Date.parse(record.expiresAt?.toString()), 'MMMM dd, yyyy')
    : ''

  return (
    <TableRow>
      <TableCell>
        <p
          data-test-id={`history-table-reward-type_${record.id}`}
          className="text-xs font-bold text-sky-900"
        >
          {t('hostessCredits')}
        </p>
      </TableCell>
      <TableCell>
        <p
          data-test-id={`history-table-reward-description_${record.id}`}
          className="textgray-600 text-xs"
        >
          {record.description}
        </p>
      </TableCell>
      <TableCell>
        <p
          data-test-id={`history-table-reward-start-date_${record.id}`}
          className="textgray-600 text-xs"
        >
          {format(Date.parse(record.createdAt.toString()), 'MMMM dd, yyyy')}
        </p>
      </TableCell>
      <TableCell>
        <p
          data-test-id={`history-table-reward-finish-date_${record.id}`}
          className="textgray-600 text-xs"
        >
          {expires}
        </p>
      </TableCell>
      <TableCell>
        <p
          data-test-id={`history-table-reward-amount_${record.id}`}
          className="textgray-600 text-xs"
        >
          {getMoneyWithIsoCurrency({
            cents: record.amount,
            currency: record.currency,
          })}
        </p>
      </TableCell>
    </TableRow>
  )
}
