import { Loader, PageContainer, Swap } from '@nori/ui-kit'
import { PageLayout } from '~/modules/page-layout'

import { ProductList } from '../product-list'

import { ProductGroups } from './product-groups'

import type { ProductListType } from '@nori/app-kit'

type Props = {
  isLoading: boolean
  id?: string
  type: ProductListType
}

export function ProductCatalogPageContent({ isLoading, id, type }: Props) {
  if (!isLoading && !id) {
    return <div>Missing id!</div>
  }

  return (
    <PageLayout>
      <PageContainer>
        <ProductGroups />

        <Swap
          is={!isLoading && !!id}
          isSlot={!!id && <ProductList id={id} type={type} />}
        >
          <Loader />
        </Swap>
      </PageContainer>
    </PageLayout>
  )
}
