import { errorFactory } from '@nori/errors'

import type { KeyOfErrors } from '@nori/errors'

export const { UserCreditsErrors } = errorFactory('UserCreditsErrors', {
  BadRequest: 'Credits Bad Request',
  NotFound: 'Credits Not Found',
  UnexpectedError: 'Credits Unexpected Error',
  UnknownError: 'Credits Unknown Error',
})

export type UserCreditsErrorInstance = InstanceType<
  KeyOfErrors<typeof UserCreditsErrors>
>
