import {
  AddressMapperService,
  CardTypeMapperService,
  CartService,
  toMoneyValueObject,
} from '@nori/app-kit'
import { Inject, Service } from '@nori/di'

import { ShippingAddressMapperService } from '../../interface/services/shipping-address-mapper.service'

import type {
  PaymentCaptureValueObject,
  PaymentTokenValueObject,
} from '~/modules/checkout'
import type { ShipmentEntity } from '~/modules/order/core/shipment.entity'
import type { DsrEntity } from '../../core/dsr.entity'
import type { DsrDiscountsValueObject } from '../../core/dsr-discounts.value-object'
import type { OrderEntity } from '../../core/order.entity'
import type { RetailSalesValueObject } from '../../core/retail-sales.value-object'
import type { ReturnOptionsValueObject } from '../../core/return-options.value-object'
import type { ShippingRateValueObject } from '../../core/shipping-rate.value-object'

@Service()
export class OrderMapper {
  constructor(
    @Inject() private readonly cardTypeMapperService: CardTypeMapperService,
    @Inject() private readonly cartService: CartService,
    @Inject()
    private readonly shippingAddressMapperService: ShippingAddressMapperService,
    @Inject() private readonly addressMapperService: AddressMapperService
  ) {}

  toOrderEntity(data: any): OrderEntity {
    return {
      id: data.id,
      isAddToMailingList: data.add_to_mailing_list,
      backorderedTotal: toMoneyValueObject(data.backorder_total),
      totalEarnedRedeemed: toMoneyValueObject(data.total_earned_redeemed),
      billingAddress: this.shippingAddressMapperService.toShippingAddressEntity(
        data.billing_address
      ),
      isBillingAddressAsShipping: data.billing_address_as_shipping,
      billingAddresses: data?.billing_addresses.map((address: any) =>
        this.shippingAddressMapperService.toShippingAddressEntity(address)
      ),
      combinedCreditsTotal: toMoneyValueObject(data.combined_credits_total),
      contactId: data.contact_id,
      isContainsAutoship: data.contains_autoship,
      couponDiscountTotal: toMoneyValueObject(data.coupon_discount_total),
      createdAt: new Date(data.created_at),
      currency: data.currency,
      customerEmail: data.customer_email,
      isCustomerEmailProvided: data.customer_email_provided,
      customerFirstName: data.customer_first_name,
      customerId: data.customer_id,
      customerLastName: data.customer_last_name,
      isDefaultShippingAddress: data.default_shipping_address,
      dsr: this.toDsrEntity(data.dsr),
      dsrCapsuleDiscountTotal: toMoneyValueObject(
        data.dsr_capsule_discount_total
      ),
      dsrCapsulePercentage: data.dsr_capsule_percentage,
      dsrDiscountPercentage: data.dsr_discount_percentage,
      dsrDiscountTotal: toMoneyValueObject(data.dsr_discount_total),
      dsrDiscountWithoutCapsuleTotal: toMoneyValueObject(
        data.dsr_discount_without_capsule_total
      ),
      dsrDiscounts: data.dsr_discounts?.map((discount: any) =>
        this.toDsrDiscounts(discount)
      ),
      dsrId: data.dsr_id,
      isEligibleForCancelation: data.eligible_for_cancelation,
      eligibleItemTotal: toMoneyValueObject(data.eligible_item_total),
      isFullyPaidByCreditsAndDiscounts:
        data.fully_paid_by_credits_and_discounts,
      isFullyPaidByStoreCredits: data.fully_paid_by_store_credits,
      giftCardTotal: toMoneyValueObject(data.gift_card_total),
      grossShippingTotal: toMoneyValueObject(data.gross_shipping_total),
      hasOnlyVirtualSkus: data.has_only_virtual_skus,
      hostessCreditsTotal: toMoneyValueObject(data.hostess_credits_total),
      hostessDiscountsAmountTotal: toMoneyValueObject(
        data.hostess_discounts_amount_total
      ),
      hostessDiscountsCount: data.hostess_discounts_count,
      orderTypeName: this.getOrderTypeName(data.type_public_name),
      isFirstOrder: data.is_first_order,
      itemTotal: toMoneyValueObject(data.item_total),
      items: this.cartService.toItemsEntities(data.items),
      locale: data.locale,
      hasLoyaltyCart: data.loyalty_cart,
      partyAt: data.party_at ? new Date(data.party_at) : undefined,
      partyId: data.party_id,
      partyName: data.party_name,
      pendingPcvCents: data.pending_pcv_cents,
      pendingPqv: data.pending_pqv,
      pendingPrvCents: data.pending_prv_cents,
      placedAt: new Date(data.placed_at),
      productCreditsTotal: toMoneyValueObject(data.product_credits_total),
      promotionalCreditsTotal: toMoneyValueObject(
        data.promotional_credits_total
      ),
      promotionsTotal: toMoneyValueObject(data.promotions_total),
      promotions: data.promotions,
      publicOrderId: data.public_order_id,
      publicStatus: data.public_status,
      referralId: data.referral_id,
      referrerAddress: data.referrer_address
        ? this.addressMapperService.toAddressValueObject(data.referrer_address)
        : undefined,
      referrerId: data.referrer_id,
      referrerName: data.referrer_name,
      referrerEmail: data.referrer_email,
      retailSales: toMoneyValueObject(data.retail_sales),
      retailTotal: toMoneyValueObject(data.retail_total),
      returnOptions: this.toReturnOptionsValueObject(data.return_options),
      isSaveAsContactAddress: data.save_as_contact_address,
      sharedCartId: data.shared_cart_id,
      shippedTotal: toMoneyValueObject(data.shipped_total),
      shippingAddress:
        this.shippingAddressMapperService.toShippingAddressEntity(
          data.shipping_address
        ),
      shippingRate: this.toShippingRateValueObject(data.shipping_rate),
      shippingTotal: toMoneyValueObject(data.shipping_total),
      status: data.status,
      store: data.store,
      storeCreditsTotal: toMoneyValueObject(data.store_credits_total),
      subtotal: toMoneyValueObject(data.subtotal),
      publicSubtotal: toMoneyValueObject(data.public_subtotal),
      publicRetailTotal: toMoneyValueObject(data.public_retail_total),
      tax: toMoneyValueObject(data.tax),
      isTaxCalculated: data.tax_calculated,
      total: toMoneyValueObject(data.total),
      totalPqv: data.total_pqv,
      typeCode: data.type_code,
      typeName: data.type_name,
      unallowedCancellationMessage: data.unallowed_cancellation_message,
      updatedAt: new Date(data.updated_at),
      isWithBackorderItems: data.with_backorder_items,
      earningsTotal: toMoneyValueObject(data.earnings_total),
      consultantDiscountPercentage: data.earnings_percentage,
      consultantDiscountTotal: data.total_earned_redeemed,
      isConsultantDiscountApplied: data.earned_redeemed,
      totalEarned: toMoneyValueObject(data.total_earned),
      totalNetEarned: toMoneyValueObject(data.total_net_earned),
      couponFee: toMoneyValueObject(data.shop_offers_fee),
      displayableShipments: this.toShipmentEntityCollection(data.shipments),
      isAllVirtual: !data.shipping_address || data.has_only_virtual_skus,
      paymentTokens: this.toPaymentTokens(data),
      retailDeliveryFee: toMoneyValueObject(data.retail_delivery_fee),
    }
  }

  toPaymentTokens(data: any): PaymentTokenValueObject[] {
    return data.payment_tokens.map((token: any) => {
      return {
        cardType: this.cardTypeMapperService.toCardType(token?.req_card_type),
        creditCardEndsWith: token?.req_card_number,
        amount: token.amount,
        gatewayType: token?.gateway_type,
        email: token?.email,
        paymentCaptures: this.toPaymentCaptures(token),
      }
    })
  }

  toPaymentCaptures(token: any): PaymentCaptureValueObject[] | undefined {
    if (!token?.payment_captures) {
      return
    }
    return token.payment_captures.map((capture: any) => ({
      id: capture.id,
      status: capture.status,
      amount: toMoneyValueObject({
        cents: capture.amount_cents,
        currency: capture.currency,
      }),
    }))
  }

  toDsrEntity(data: any): DsrEntity {
    return {
      id: data.id,
      firstName: data.first_name,
      lastName: data.last_name,
      nickname: data.nickname,
      displayName: [data.nickname || data.first_name, data.last_name].join(' '),
      email: data.email,
    }
  }

  toDsrDiscounts(data: any): DsrDiscountsValueObject | undefined {
    return {
      percentage: data.percentage,
      total: toMoneyValueObject(data.total),
    }
  }

  toReturnOptionsValueObject(data: any): ReturnOptionsValueObject {
    return {
      refund: {
        available: data.refund.available,
        isInBundle: data.refund.in_bundle,
        reasonCode: data.refund.reason_code,
        reasonData: data.refund.reason_data,
      },
      replacement: {
        available: data.replacement.available,
        isInBundle: data.replacement.in_bundle,
        reasonCode: data.replacement.reason_code,
        reasonData: data.replacement.reason_data,
      },
    }
  }

  toShippingRateValueObject(data: any): ShippingRateValueObject {
    return {
      key: data.key,
      name: data.name,
      fee: toMoneyValueObject(data.fee),
      additionalInfo: data.additional_info,
    }
  }

  toRetailSalesValueObject(data: any): RetailSalesValueObject {
    return {
      capturedCustomerCount: data.captured_customer_count,
      capturedOrderCount: data.captured_order_count,
      capturedRetailSalesCents: data.captured_retail_sales_cents,
      capturedTotalPqv: data.captured_total_pqv,
      capturedTotalNetEarnedCents: data.captured_total_net_earned_cents,
      currency: data.currency,
      orderCount: data.order_count,
      placedCustomerCount: data.placed_customer_count,
      placedOrderCount: data.placed_order_count,
      placedRetailSalesCents: data.placed_retail_sales_cents,
      placedTotalPqv: data.placed_total_pqv,
      placedRewardsSalesCents: data.placed_rewards_sales_cents,
      placedTotalNetEarnedCents: data.placed_total_net_earned_cents,
      placedTotalEarnedCents: data.placed_total_earned_cents,
      retailSalesCents: data.retail_sales_cents,
      store: data.store,
      totalPqv: data.total_pqv,
    }
  }

  private getOrderTypeName(
    // TODO: figure out when it's object or string
    typePublicName: string | { [key: string]: any }
  ): string {
    if (typeof typePublicName === 'object') {
      return typePublicName.dsr
    }
    return typePublicName
  }

  toShipmentEntityCollection(shipments: any): ShipmentEntity[] {
    if (!shipments?.length) return []

    return shipments
      .map((shipment: any) => {
        if (shipment.tracking_url && shipment.tracking_number) {
          return {
            trackingUrl: shipment.tracking_url,
            trackingNumber: shipment.tracking_number,
          }
        }
      })
      .filter(Boolean)
  }
}
