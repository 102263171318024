import { Action, Inject } from '@nori/di'

import { RootAppService } from '../services/root-app.service'

@Action()
export class RootAppAction {
  constructor(@Inject() private readonly rootAppService: RootAppService) {}

  async handleInitialLoad(): Promise<void> {
    await this.rootAppService.loadInitialContext()
  }
}
