import { observer } from '@nori/di'

import { useNotesContext } from '../interface/use-notes-context'

import { NoteListItemEdit } from './note-list-item-edit'
import { NoteListItemView } from './note-list-item-view'

import type { NoteEntity } from '../core/note.entity'

type Props = {
  note: NoteEntity
  children: React.ReactNode
  contactId: number
}

export const NoteListItem = observer(({ note, contactId, children }: Props) => {
  const { notesStore } = useNotesContext()
  // @TODO: check for notesStore.editedNoteMap[contactId] to exist
  const isShowListItemEdit =
    notesStore.editedNoteMap[contactId] &&
    notesStore.editedNoteMap[contactId]!.id === note.id

  if (isShowListItemEdit) {
    return <NoteListItemEdit>{children}</NoteListItemEdit>
  }

  return <NoteListItemView contactId={contactId} note={note} />
})
