import { Store } from '@nori/di'
import { Validator } from '@nori/validator'

@Store()
export class BirthdayGuardStore {
  birthdayValidator = new Validator({
    day: Validator.scheme.requiredString('Required'),
    month: Validator.scheme.number(),
    year: Validator.scheme.requiredString('Required'),
  })

  isSubmitting = false

  setIsSubmitting(isSubmitting: boolean): void {
    this.isSubmitting = isSubmitting
  }
}
