import { useMemo } from 'react'

import { observer } from '@nori/di'
import { useTranslations } from '@nori/lang-i18n'
import { Button } from '@nori/ui-kit'
import { useCartContext } from '~/modules/cart'

import { addToCartStatusMap } from './add-to-cart-status-map'

type Props = {
  id?: string
  isDisabled?: boolean
  isOutOfStock?: boolean
  isCustomizable?: boolean
  onClick: () => void
}

export const AddToCartButton = observer(
  ({ id = '', isDisabled, isOutOfStock, isCustomizable, onClick }: Props) => {
    const t = useTranslations('productList.item')

    const { cartStore } = useCartContext()

    const addToCartStatus = cartStore.addToCartStatusById[id] ?? 'default'
    const isLoading = addToCartStatus === 'loading'
    const title = useMemo(() => {
      if (isOutOfStock) {
        return addToCartStatusMap.outOfStock
      }
      if (isCustomizable) {
        return addToCartStatusMap.customize
      }
      return addToCartStatusMap[addToCartStatus]
    }, [addToCartStatus, isCustomizable, isOutOfStock])

    const dataTestId = isOutOfStock
      ? 'out-of-stock-button'
      : 'add-to-cart-button'

    return (
      <Button
        isLoading={isLoading}
        onClick={onClick}
        disabled={isDisabled || isOutOfStock}
        widthType="full"
        data-test-id={dataTestId}
      >
        {t(title)}
      </Button>
    )
  }
)
