import { Inject, Service } from '@nori/di'
import { isErr } from '@nori/result'
import { createLogger } from '~/infra/create-logger'
import { CartStore } from '~/modules/cart/interface/store/cart-store/cart.store'

import { PennyAdapter } from '../../infra/penny.adapter'
import { CartBannerStore } from '../store/cart-banner.store'
import { PennyCheckoutSummaryStore } from '../store/penny-checkout-summary.store'

const logger = createLogger('penny-checkout-summary.service')

@Service()
export class PennyCheckoutSummaryService {
  constructor(
    @Inject() private readonly cartStore: CartStore,
    @Inject() private readonly pennyAdapter: PennyAdapter,
    @Inject()
    private readonly pennyCheckoutSummaryStore: PennyCheckoutSummaryStore
  ) {}

  async loadMessages(): Promise<void> {
    if (!this.cartStore.cart?.id) {
      return
    }

    if (!this.pennyCheckoutSummaryStore.checkoutSummary) {
      this.initialLoadPennyCheckoutSummary()
      return
    }

    const result = await this.pennyAdapter.getCheckoutSummary({
      cartId: this.cartStore.cart.id,
    })

    if (isErr(result)) {
      logger.error(result.error.key, result.error)
      return
    }

    this.pennyCheckoutSummaryStore.setCheckoutSummary(result.data)
  }

  private async initialLoadPennyCheckoutSummary(): Promise<void> {
    if (!this.cartStore.cart?.id) {
      return
    }

    this.pennyCheckoutSummaryStore.setIsLoading(true)
    this.pennyCheckoutSummaryStore.setIsError(false)
    this.pennyCheckoutSummaryStore.setCheckoutSummary(undefined)

    const result = await this.pennyAdapter.getCheckoutSummary({
      cartId: this.cartStore.cart.id,
    })

    if (isErr(result)) {
      logger.error(result.error.key, result.error)
      this.pennyCheckoutSummaryStore.setIsError(true)
      this.pennyCheckoutSummaryStore.setIsLoading(false)
      return
    }

    this.pennyCheckoutSummaryStore.setCheckoutSummary(result.data)
    this.pennyCheckoutSummaryStore.setIsLoading(false)
  }
}
