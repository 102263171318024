import {
  CartService,
  getBaseUrl,
  RequestService,
  SERVICE_NAMES,
} from '@nori/app-kit'
import { Adapter, Inject } from '@nori/di'
import { resultErr, resultOk } from '@nori/result'
import { CartErrors } from '~/modules/cart/core'

import { CartErrorMapper } from '../../cart/infra/mappers/cart-error.mapper'

import { CartHostessMapper } from './mappers/cart-hostess.mapper'
import { CartPartyMapper } from './mappers/cart-party.mapper'

import type { CartEntity } from '@nori/app-kit'
import type { PromiseResult } from '@nori/result'
import type { CartErrorInstance } from '~/modules/cart/core'
import type { RemoveFromCartInput } from '../../cart/infra/types/remove-from-cart.input'
import type { AddHostessToCartInput } from './types/add-hostess-to-cart.input'
import type { AddPartyToCartInput } from './types/add-party-to-cart.input'

@Adapter()
export class CartPartyAdapter {
  baseUrl = getBaseUrl(SERVICE_NAMES.CART_SERVICE)

  public constructor(
    @Inject() private readonly requestService: RequestService,
    @Inject() private readonly cartService: CartService
  ) {}

  async addHostessToOrder(
    input: AddHostessToCartInput
  ): PromiseResult<CartEntity, CartErrorInstance> {
    const url = `${this.baseUrl}/carts/${input.cartId}.json`

    try {
      const { ok, json, status } = await this.requestService.secured.put(url, {
        body: CartHostessMapper.toAddHostess(input),
      })

      if (ok) return resultOk(this.cartService.toCartEntity(json))

      return resultErr(CartErrorMapper.toErrors(status, json))
    } catch (err: unknown) {
      return resultErr(new CartErrors.UnexpectedError(err))
    }
  }

  async addPartyToOrder(
    input: AddPartyToCartInput
  ): PromiseResult<CartEntity, CartErrorInstance> {
    const url = `${this.baseUrl}/carts/${input.cartId}.json`

    try {
      const { ok, json, status } = await this.requestService.secured.put(url, {
        body: CartPartyMapper.toAddParty(input),
      })

      if (ok) {
        return resultOk(this.cartService.toCartEntity(json))
      }

      return resultErr(CartErrorMapper.toErrors(status, json))
    } catch (err: unknown) {
      return resultErr(new CartErrors.UnexpectedError(err))
    }
  }

  async removeHostessFromOrder(
    input: RemoveFromCartInput
  ): PromiseResult<CartEntity, CartErrorInstance> {
    const url = `${this.baseUrl}/carts/${input.cartId}.json`

    try {
      const { ok, json, status } = await this.requestService.secured.put(url, {
        body: {
          // eslint-disable-next-line camelcase
          referrer_id: null,
        },
      })

      if (ok) {
        return resultOk(this.cartService.toCartEntity(json))
      }

      return resultErr(CartErrorMapper.toErrors(status, json))
    } catch (err: unknown) {
      return resultErr(new CartErrors.UnexpectedError(err))
    }
  }

  async removePartyFromOrder(
    input: RemoveFromCartInput
  ): PromiseResult<CartEntity, CartErrorInstance> {
    const url = `${this.baseUrl}/carts/${input.cartId}.json`

    try {
      const { ok, json, status } = await this.requestService.secured.put(url, {
        body: {
          // eslint-disable-next-line camelcase
          party_id: null,
        },
      })

      if (ok) {
        return resultOk(this.cartService.toCartEntity(json))
      }

      return resultErr(CartErrorMapper.toErrors(status, json))
    } catch (err: unknown) {
      return resultErr(new CartErrors.UnexpectedError(err))
    }
  }
}
