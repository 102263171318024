import { observer } from '@nori/di'
import { useTranslations } from '@nori/lang-i18n'
import { Icon, TableHeader, Tooltip } from '@nori/ui-kit'

import { useContactContext } from '../../interface/use-contact-context'

import { ContactTableHeaderCell } from './contact-table-header-cell'

function ExpiringRewardsTooltip() {
  const t = useTranslations('contacts.list')

  return (
    <div className="w-48 px-3 py-2.5">
      {t('table.header.expiringRewardsTooltip')}
    </div>
  )
}

export const ContactTableHeader = observer(() => {
  const t = useTranslations()
  const { contactsStore } = useContactContext()

  return (
    <TableHeader>
      {contactsStore.visibleColumns.map((cell) => {
        if (cell.sortBy === 'expiring_rewards') {
          return (
            <ContactTableHeaderCell key={cell.id} sortBy={cell.sortBy}>
              <div className="flex items-center justify-between">
                {t(cell.translationKey)}
                <Tooltip slot={<ExpiringRewardsTooltip />} place="bottom">
                  <span className="text-gray-400">
                    <Icon name="InformationCircleFill" />
                  </span>
                </Tooltip>
              </div>
            </ContactTableHeaderCell>
          )
        }

        return (
          <ContactTableHeaderCell key={cell.id} sortBy={cell.sortBy}>
            {t(cell.translationKey)}
          </ContactTableHeaderCell>
        )
      })}
    </TableHeader>
  )
})
