import { useRouter } from 'next/router'

import { observer } from '@nori/di'
import { useTranslations } from '@nori/lang-i18n'
import { Divider, Link } from '@nori/ui-kit'
import { CustomizeBundleModal } from '~/modules/customize-bundle/ui'
import { Routes } from '~/modules/routes'

import { useCartContext } from '../../interface/context/use-cart-context'

import { BundleItem } from './item/bundle-item'
import { ProductItem } from './item'

export const ProductsList = observer(() => {
  const router = useRouter()
  const t = useTranslations('cart.productList')

  const { cartStore } = useCartContext()
  const { cart } = cartStore

  if (!cart) return null

  return (
    <>
      <div>
        <div className="mb-6 hidden justify-between text-xs font-semibold text-gray-500 lg:flex">
          <p className="w-3/6">{t('item')}</p>
          <p className="w-1/6 text-right">{t('retail')}</p>
          <p className="w-2/6 text-right">{t('total')}</p>
        </div>
        <Divider />
        {cart.items.map((product) => {
          if (product.type === 'bundle')
            return <BundleItem key={product.id} bundle={product} />
          return <ProductItem key={product.id} product={product} />
        })}

        <div className="mb-6 mt-6 flex w-full justify-center">
          <Link
            weight="bold"
            size="medium"
            iconLeft="PlusCircle"
            data-test-id={'add-more-items-link'}
            onClick={() => router.push(Routes.shop.path())}
          >
            {t('addMoreItems')}
          </Link>
        </div>
      </div>

      <CustomizeBundleModal />
    </>
  )
})
