import { useTranslations } from '@nori/lang-i18n'
import { Divider, Swap, Tooltip } from '@nori/ui-kit'
import { Icon, Link } from '@nori/ui-kit'
import { useWishListContext } from '~/modules/wishlist/interface/use-wish-list.context'
import { ShareTooltipContent } from '~/modules/wishlist/ui/share-tooltip-content'

type Props = {
  totalCount?: number
  wishlistType: 'self' | 'contact'
  contactName?: string
}

export function WishlistHeader({
  totalCount,
  wishlistType,
  contactName,
}: Props) {
  const t = useTranslations('wishList')
  const { wishListStore } = useWishListContext()
  const { wishlistShareMessengerUrl } = wishListStore

  return (
    <>
      <div className="flex flex-wrap items-center justify-between">
        <div className="flex max-w-[360px] flex-row items-center gap-3.5 max-sm:max-w-[220px] lg:mb-5 lg:mt-5 ">
          <Swap
            is={wishlistType === 'contact'}
            isSlot={
              <p className="overflow-hidden break-words text-base font-bold text-primary-900">
                {t('contactWishlist', { contactName })}
              </p>
            }
          >
            <p className="text-2xl font-bold text-primary-900">
              {t('yourWishlist')}
            </p>
          </Swap>
        </div>
        <div className="mb-5 mt-5 flex flex-row gap-4">
          {!!totalCount && (
            <p
              className="text-sm font-normal text-gray-700"
              data-test-id={'wishlist-quantity-label'}
            >
              {t('items', {
                count: totalCount,
              })}
            </p>
          )}
          <a className="flex items-center">
            <span className="mr-1 text-primary-900">
              <Tooltip slot={<ShareTooltipContent />} place="bottom">
                <div className="flex gap-1">
                  <Icon name="Share" />
                  <span className="text-center text-sm font-bold text-primary-900">
                    {t('share')}
                  </span>
                </div>
              </Tooltip>
            </span>
          </a>
          {/* https://norwex.atlassian.net/browse/NOOK-1210 */}
          {/* <a className="flex items-center pl-4 border-l-2">
            <Link
              isUnderline={false}
              href={wishlistShareMessengerUrl}
              target="_blank"
            >
              <div className="flex">
                <span className="mr-1 text-primary-900">
                  <Icon name="Messenger" />
                </span>
                <span className="text-center text-sm font-bold text-primary-900">
                  {t('messenger')}
                </span>
              </div>
            </Link>
          </a> */}
        </div>
      </div>
      <Divider />
    </>
  )
}
