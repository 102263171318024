import { hookContextFactory } from '@nori/di'

import { BillingFormAction } from './action/billing-form.action'
import { BillingFormPayPalAction } from './action/billing-form-paypal.action'
import { BillingFormSplitPaymentAction } from './action/billing-form-split-payment.action'
import { BillingFormStore } from './store/billing-form.store'
import { BillingFormSplitPaymentStore } from './store/billing-form-split-payment.store'

export const { useModuleContext: useBillingFormContext } = hookContextFactory({
  billingFormSplitPaymentAction: BillingFormSplitPaymentAction,
  billingFormSplitPaymentStore: BillingFormSplitPaymentStore,
  billingFormAction: BillingFormAction,
  billingFormStore: BillingFormStore,
  billingFormPayPalAction: BillingFormPayPalAction,
})
