import { getBaseUrl, RequestService, SERVICE_NAMES } from '@nori/app-kit'
import { Adapter, Inject } from '@nori/di'
import { resultErr, resultOk } from '@nori/result'

import { CartErrors } from '../core'

import type { PromiseResult } from '@nori/result'
import type { CartErrorInstance } from '../core'

@Adapter()
export class CartTaxAdapter {
  baseUrl = getBaseUrl(SERVICE_NAMES.CART_SERVICE)

  public constructor(
    @Inject() private readonly requestService: RequestService
  ) {}

  async calculateTax({
    cartId,
  }: {
    cartId: number
  }): PromiseResult<void, CartErrorInstance> {
    const url = `${this.baseUrl}/carts/${cartId}/calculate_tax`

    try {
      const { ok, json, status } = await this.requestService.secured.get(url)

      if (ok) return resultOk(undefined)

      return resultErr(new CartErrors.UnknownCartTaxError(json))
    } catch (err: unknown) {
      return resultErr(new CartErrors.UnexpectedCartTaxError(err))
    }
  }
}
