/**
 * Returns one of 'iOS', 'Android', 'Windows Phone', or 'unknown'.
 */ function getMobileOperatingSystem() {
    // Adapted from http://stackoverflow.com/a/21742107
    const userAgent = window.navigator.userAgent || window.navigator.vendor;
    // Windows Phone must come first because its UA also contains "Android"
    if (/windows phone/i.test(userAgent)) {
        return 'Windows Phone';
    }
    if (/android/i.test(userAgent)) {
        return 'Android';
    }
    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    if (/iPad|iPhone|iPod/.test(userAgent)) {
        return 'iOS';
    }
    return 'unknown';
}
export const copyToClipboardFallback = (text)=>{
    // Create a text area
    const textArea = document.createElement('textArea');
    textArea.value = text;
    document.body.append(textArea);
    textArea.setAttribute('readonly', '');
    // Select the text of text area
    let range;
    let selection;
    // check if the device is iOS
    if (getMobileOperatingSystem() === 'iOS') {
        range = document.createRange();
        range.selectNodeContents(textArea);
        selection = window.getSelection();
        selection.removeAllRanges();
        selection.addRange(range);
        textArea.setSelectionRange(0, 999999);
    } else {
        textArea.select();
    }
    document.execCommand('copy');
    textArea.remove();
    return true;
};
copyToClipboardFallback.element = null;
