import type { CreateNotePayloadValueObject } from '../../core/create-note.value-object'

export class CreateNoteRequestMapper {
  static toInput(data: CreateNotePayloadValueObject): any {
    return {
      contact_id: data.contactId,
      content: data.content,
    }
  }
}
