import { HistoryService, ProductTypeCode } from '@nori/app-kit'
import { Action, Inject } from '@nori/di'
import { isErr } from '@nori/result'
import { ProductsLoungeAdapter } from '~/modules/products/infra/products.adapter'
import { Routes } from '~/modules/routes'

import { ProductsStore } from '../../store/products.store'

import type { CommonBundleEntity, CommonStyleEntity } from '@nori/app-kit'
import type { ProductListValueObject } from '~/modules/products/core'
import type {
  HandleLoadParams,
  InitialLoadParams,
} from './products-action.types'

@Action()
export class ProductsAction {
  constructor(
    @Inject() private readonly productsStore: ProductsStore,
    @Inject() private readonly productsAdapter: ProductsLoungeAdapter,
    @Inject() private readonly historyService: HistoryService
  ) {}

  async handleInitialLoad({ type, id }: InitialLoadParams): Promise<void> {
    this.productsStore.setIsLoading(true)
    this.productsStore.setError(undefined)
    this.productsStore.setCurrentId(id)
    this.productsStore.setCurrentType(type)

    await this.load({ page: 0 })

    this.productsStore.setIsLoading(false)
  }

  async handleLoadNextItems(): Promise<void> {
    this.productsStore.setError(undefined)
    this.productsStore.setIsLoadingNextPage(true)

    const page = this.productsStore.pagination.page - 0 + 1
    await this.load({ page })

    this.productsStore.setIsLoadingNextPage(false)
  }

  private async load({ page }: HandleLoadParams): Promise<void> {
    const id = this.productsStore.currentId
    const type = this.productsStore.currentType
    if (!id || !type) return

    const result = await this.productsAdapter.getProducts({
      id,
      type,
      pagination: { page, perPage: this.productsStore.pagination.perPage },
    })

    if (isErr(result)) {
      this.productsStore.setError(result.error)
      return
    }

    result.data.pagination.page === 1
      ? this.productsStore.setList(result.data)
      : this.appendList(result.data)
  }

  private appendList(result: ProductListValueObject): void {
    const list = {
      items: [...(this.productsStore.list?.items ?? []), ...result.items],
      pagination: result.pagination,
      filters: result.filters,
    }
    this.productsStore.setList(list)
  }

  private isStyleProduct(
    item: CommonBundleEntity | CommonStyleEntity
  ): item is CommonStyleEntity {
    return item.typeCode === ProductTypeCode.Style
  }

  public handleOpenProduct(item: CommonBundleEntity | CommonStyleEntity): void {
    const id = this.isStyleProduct(item)
      ? item.productId.toString()
      : item.id.toString()

    this.historyService.push(
      Routes.product.path({
        id,
        type: item.typeCode,
      })
    )
  }
}
