import { useMemo } from 'react'
import { useRouter } from 'next/router'

import { getLink, useProfileContext } from '@nori/app-kit'
import { useTranslations } from '@nori/lang-i18n'
import { formatPhone } from '@nori/phone'
import { Button, Icon, LinkClipboardCopy } from '@nori/ui-kit'
import { useFormatAddressToString } from '~/modules/autosave/ui/use-format-address-to-string'
import { useContactContext } from '~/modules/contacts/interface/use-contact-context'
import { Routes } from '~/modules/routes'

import type { ContactEntity } from '~/modules/contacts/core'

type Props = {
  contact: ContactEntity
}

export function LeftColumn({ contact }: Props) {
  const t = useTranslations('contacts.list.table.extended')
  const { contactsAction } = useContactContext()
  const { userStore } = useProfileContext()
  const router = useRouter()

  const contactAddress = useFormatAddressToString(contact.currentAddress)

  const phoneNumber = useMemo(() => {
    if (!contact.phone) return
    const { formattedPhoneNumber, isRegexPassed } = formatPhone(contact.phone)

    if (!isRegexPassed) return contact.phone

    return formattedPhoneNumber
  }, [contact.phone])

  const personalLink = getLink({
    type: 'b2c',
    personalLink: contact.personalLink,
    isMask: true,
  })
  const shouldDisplayNotRegisteredWarning =
    contact.isCustomerRegistered === false

  const shouldDisplayPersonalLink =
    !shouldDisplayNotRegisteredWarning &&
    contact.isPreferredDsr &&
    personalLink.url &&
    contact.customerId &&
    userStore.isOSEnabled

  return (
    <>
      {contact.isEmailProvided !== false && (
        <div className="flex items-center">
          <span className="mr-2 text-primary-900">
            <Icon name="MailOutline" />
          </span>
          <a
            href={`mailto:${contact.email}`}
            className="text-xs text-gray-500 underline"
            data-test-id={'contact-expanded-view-email'}
          >
            {contact.email}
          </a>
        </div>
      )}

      {phoneNumber && (
        <div className="flex items-center">
          <span className="mr-2 text-primary-900">
            <Icon name="PhoneOutline" />
          </span>
          <span
            className="text-xs text-gray-500"
            data-test-id={'contact-expanded-view-phone'}
          >
            {phoneNumber}
          </span>
        </div>
      )}

      <button className="flex hidden items-center">
        <span className="mr-2 text-primary-900">
          <Icon name="Messenger" />
        </span>
        <span className="text-xs font-bold text-primary-900 underline">
          {t('messenger')}
        </span>
      </button>

      {shouldDisplayPersonalLink && (
        <div className="flex">
          <div className="mr-2 text-primary-900">
            <Icon name="LinkOutline" />
          </div>
          <div className="flex flex-col text-xs text-gray-500">
            <div
              className="break-all text-left"
              data-test-id={'contact-expanded-view-referral-link'}
            >
              {personalLink.title}
            </div>
            {personalLink.url && (
              <div className="mt-1 cursor-pointer">
                <LinkClipboardCopy
                  value={personalLink.url}
                  size="small"
                  linkWeight="regular"
                />
              </div>
            )}
          </div>
        </div>
      )}

      <div className="flex items-center">
        <span className="mr-2 text-primary-900">
          <Icon name="MapOutline" />
        </span>
        <span
          className="text-xs text-gray-500"
          data-test-id={'contact-expanded-view-address'}
        >
          {contactAddress}
        </span>
      </div>

      {contact.isPreferredDsr && (
        <div className={'flex items-center'}>
          <span className="mr-2 text-primary-900">
            <Icon name="StarOutline" />
          </span>
          <span
            className="text-xs text-gray-500"
            data-test-id={'contact-expanded-view-preferred-dsr'}
          >
            {t('preferredDsr')}
          </span>
        </div>
      )}

      {shouldDisplayNotRegisteredWarning && (
        <div className="flex items-start gap-2">
          <span className="text-primary-900">
            <Icon name="LinkOutline" />
          </span>
          <div className="break-words text-left text-xs font-bold text-gray-500">
            {t('mustHaveAnAccountToShoppingLink')}
          </div>
        </div>
      )}

      <div className="mt-2">
        <Button
          onClick={() =>
            contactsAction.handleOpenContactDetailsPage(contact.id)
          }
          size="base"
          widthType="full"
          data-test-id={'contact-expanded-view-profile-button'}
        >
          {t('viewProfile')}
        </Button>
      </div>

      {contact.customerId &&
        contact.isPreferredDsr &&
        contact.customerType !== 'consultant' && (
          <button
            className="mt-1 flex justify-center text-sm text-primary-900 underline"
            data-test-id={'contact-expanded-view-view-rewards-button'}
            onClick={() =>
              router.push(
                Routes.contacts.contactDetails.path({
                  id: contact.id,
                  tab: 'credits',
                })
              )
            }
          >
            {t('viewRewards')}
          </button>
        )}
    </>
  )
}
