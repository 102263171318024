import { useCallback, useState } from 'react'

import { useToggle } from '@nori/ui-kit'
import { useGtmContext } from '~/modules/google-tag-manager/interface/use-gtm-context'
import { useShopForMyselfContext } from '~/modules/shop-for-myself/interface/use-shop-for-myself.context'
import { StyleDesktopCell } from '~/modules/shop-for-myself/ui/product-list/cells/style-desktop.cell'
import { StyleMobileCell } from '~/modules/shop-for-myself/ui/product-list/cells/style-mobile.cell'

import type { CommonStyleEntity, ProductSkuEntity } from '@nori/app-kit'

type Props = {
  item: CommonStyleEntity
}

export function StyleCell({ item }: Props) {
  const { searchProductsAction } = useShopForMyselfContext()
  const { gtmAction } = useGtmContext()

  const { skus, available, callout, pictures } = item
  const pictureUrl = pictures[0]?.thumbnailMedURL ?? ''

  const initialSku = !skus || skus.length > 1 ? undefined : skus[0]

  const [selectedSku, setSelectedSku] = useState<ProductSkuEntity | undefined>(
    initialSku
  )
  const [selectedSubscriptionId, setSelectedSubscriptionId] = useState(
    item.defaultProductSubscription.id
  )
  const subscriptionToggle = useToggle()

  const handleAddToCart = useCallback(
    (quantity: number) => {
      if (!selectedSku || !quantity) return
      gtmAction.handleTrackItemAddToCart(item, selectedSku.id, quantity)
      searchProductsAction.handleAddStyleToCart({
        quantity,
        skuId: selectedSku.id,
        subscriptionPlanId: subscriptionToggle.isOpen
          ? selectedSubscriptionId
          : undefined,
      })
    },
    [selectedSku, selectedSubscriptionId, subscriptionToggle.isOpen]
  )

  return (
    <div
      data-test-id="cart-item"
      className="flex w-full flex-col flex-wrap justify-between border-b border-primary-200 p-2 pb-5 pt-5 lg:flex-nowrap"
    >
      <div className="lg:invisible lg:hidden">
        <StyleMobileCell
          item={item}
          pictureUrl={pictureUrl}
          skus={skus}
          callout={callout}
          available={available}
          onAddToCart={handleAddToCart}
          selectedSku={selectedSku}
          setSelectedSku={setSelectedSku}
          selectedSubscriptionId={selectedSubscriptionId}
          setSelectedSubscriptionId={(id) => setSelectedSubscriptionId(id)}
          subscriptionToggle={subscriptionToggle}
        />
      </div>
      <div className="invisible hidden lg:visible lg:block">
        <StyleDesktopCell
          item={item}
          pictureUrl={pictureUrl}
          skus={skus}
          callout={callout}
          available={available}
          handleAddToCart={handleAddToCart}
          selectedSku={selectedSku}
          setSelectedSku={setSelectedSku}
          selectedSubscriptionId={selectedSubscriptionId}
          setSelectedSubscriptionId={(id) => setSelectedSubscriptionId(id)}
          subscriptionToggle={subscriptionToggle}
        />
      </div>
    </div>
  )
}
