import { Inject, Service } from '@nori/di'
import { SearchProductsAdapter } from '~/modules/search-products/infra/search-products.adapter'

import type { ProductErrorInstance } from '@nori/app-kit'
import type { PromiseResult } from '@nori/result'
import type { ProductListValueObject } from '~/modules/products/core'
import type { SearchProductsInput } from '~/modules/search-products/infra/types/search-products.input'

@Service()
export class SearchProductsService {
  constructor(
    @Inject() private readonly searchProductsAdapter: SearchProductsAdapter
  ) {}

  searchProducts(
    input: SearchProductsInput
  ): PromiseResult<ProductListValueObject, ProductErrorInstance> {
    return this.searchProductsAdapter.searchProducts(input)
  }
}
