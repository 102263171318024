import { getLink, StoreEnum } from '@nori/app-kit'
import { DataState, Store } from '@nori/di'

import type {
  RewardsInfoErrorInstance,
  RewardsInfoValueObject,
} from '@nori/app-kit'
import type {
  ContactDetailsEntity,
  ContactErrorInstance,
} from '~/modules/contacts/core'
import type { ContactAddressValueObject } from '../../core/contact-address.value-object'

@Store()
export class ContactDetailsStore {
  isLoading = false
  isWishlistLoading = false
  error?: ContactErrorInstance = undefined

  contact?: ContactDetailsEntity = undefined

  rewards = new DataState<RewardsInfoValueObject, RewardsInfoErrorInstance>()

  isLoadingFreeShipping = false
  hasFreeShipping = false

  get personaLink(): string | undefined {
    if (!this.contact?.personalLink) return

    return this.contact?.belongsToDifferentDsr
      ? undefined
      : getLink({
          type: 'b2c',
          personalLink: this.contact?.personalLink,
          isMask: true,
        }).url
  }

  get contactId(): number | undefined {
    return this.contact?.id
  }

  get contactFullName(): string | undefined {
    if (!this.contact) {
      return
    }
    const { firstName, lastName } = this.contact
    return [firstName, lastName].join(' ')
  }

  get isCustomer(): boolean {
    return !!this.contact?.customerId
  }

  get contactAddress(): ContactAddressValueObject | undefined {
    return this.contact?.currentAddress
  }

  get isWishlistAvailable(): boolean {
    return !!this.contact?.urlCode && !!this.contact.customerId
  }

  get isConsultant(): boolean {
    return this.contact?.userType === 'consultant'
  }

  setIsLoading(value: boolean): void {
    this.isLoading = value
  }

  setIsWishlistLoading(value: boolean): void {
    this.isWishlistLoading = value
  }

  setError(error?: ContactErrorInstance): void {
    this.error = error
  }

  setContact(contact: ContactDetailsEntity): void {
    this.contact = contact
  }

  setIsLoadingFreeShipping(value: boolean): void {
    this.isLoadingFreeShipping = value
  }

  setHasFreeShipping(value: boolean): void {
    this.hasFreeShipping = value
  }
}
