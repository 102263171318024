export enum Event {
  Navigation = 'navigation',
  Purchase = 'purchase',
  AddToCart = 'add_to_cart',
  RemoveFromCart = 'remove_from_cart',
  ViewCart = 'view_cart',
  BeginCheckout = 'begin_checkout',
  AddShippingInfo = 'add_shipping_info',
  ViewItemList = 'view_item_list',
}
