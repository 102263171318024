import { Action, Inject } from '@nori/di'
import { isErr } from '@nori/result'
import { createLogger } from '~/infra/create-logger'
import { CartStore } from '~/modules/cart/interface/store/cart-store/cart.store'
import { RemoveFromCartService } from '~/modules/product-cart/interface/service/remove-from-cart.service'
import { WishListService } from '~/modules/wishlist/interface/service/wish-list.service'

import { CartSubscriptionAdapter } from '../../../product-subscription/infra/cart-subscription.adapter'
import { SwitchProductInCartService } from '../service/switch-product-in-cart.service'
import { ProductCartStore } from '../store/product-cart.store'

import type { CartItemEntity } from '@nori/app-kit'

const logger = createLogger('cart.actions')

@Action()
export class ProductCartAction {
  constructor(
    @Inject() private readonly removeFromCartService: RemoveFromCartService,
    @Inject()
    private readonly switchProductInCartService: SwitchProductInCartService,
    @Inject() private readonly wishListService: WishListService,
    @Inject() private readonly productCartStore: ProductCartStore,
    @Inject() private readonly autoShipAdapter: CartSubscriptionAdapter,
    @Inject() private readonly cartStore: CartStore
  ) {}

  async handleRemoveProductFromCart(product: CartItemEntity): Promise<void> {
    await this.removeFromCartService.handleRemoveFromCart(product)
  }

  async handleAddProductToWishList(product: CartItemEntity): Promise<void> {
    const response = await this.wishListService.addItemToWishList({
      skuId: product.skuId,
    })

    if (isErr(response)) return

    await this.removeFromCartService.handleRemoveFromCart(product)
  }

  async handleSwitchProductInCart(
    product: CartItemEntity,
    quantity: number
  ): Promise<void> {
    await this.switchProductInCartService.handleSwitchProductInCart(
      product,
      quantity
    )
  }
}
