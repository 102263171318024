import { useTranslations } from '@nori/lang-i18n'
import { Icon } from '@nori/ui-kit'

import type { NoriDate } from '@nori/date'

type Props = {
  cycleName?: string
  itemDate?: NoriDate
}

export const AutoSaveOrderRowHeader = ({ cycleName, itemDate }: Props) => {
  const t = useTranslations('autosaveSettings')
  return (
    <div className="flex">
      <div className="flex items-center">
        <span className="text-xs font-bold text-primary-700 lg:text-sm">
          {t('subscription')}
        </span>
        {itemDate && (
          <>
            <div className="mx-3 h-full w-[2px] bg-gray-100" />
            <span
              className="flex items-center gap-1 text-xs font-medium capitalize text-gray-500 lg:text-sm"
              data-test-id="subscription-date"
            >
              <Icon name="Calendar" />
              {itemDate.formatInTZ('MMM dd')}
            </span>
          </>
        )}
        {cycleName && (
          <>
            <div className="mx-3 h-full w-[2px] bg-gray-100" />
            <span className="flex items-center gap-1 text-xs font-medium capitalize text-gray-500 lg:text-sm">
              <Icon name="Refresh" />
              {t('cycleDuration', { cycle: cycleName })}
            </span>
          </>
        )}
      </div>
    </div>
  )
}
