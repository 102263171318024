import { Divider, Loader } from '@nori/ui-kit'
import { CartProductSubscription } from '~/modules/product-subscription/ui/cart-product-subscription'

import { HostessDiscountButton } from '../hostess-discount-button'
import { ProductItemName } from '../product-item-name'
import { CartItemPrice } from '../product-item-price'
import { ProductItemQuantity } from '../product-item-quantity'
import { ProductItemThumbnail } from '../product-item-thumbnail'

import { ProductItemControlsDesktop } from './product-item-controls-desktop'

import type { CartItemEntity } from '@nori/app-kit'

type Props = {
  currentQuantity: string
  handleCancel: () => void
  handleItemQuantityChange: (value: string) => void
  isEditMode: boolean
  isProductLoading: boolean
  onAdd: () => void
  onAddWishlist: () => void
  onEditSwitch: () => void
  onRemove: () => void
  product: CartItemEntity
  canBeAutoShipped?: boolean
  error?: string
  isWishButtonEnabled?: boolean
  isPromoGenerated: boolean
  currency: string
}

export const ProductItemDesktop = ({
  currentQuantity,
  handleCancel,
  handleItemQuantityChange,
  isEditMode,
  isProductLoading,
  onAdd,
  onAddWishlist,
  onEditSwitch,
  onRemove,
  product,
  canBeAutoShipped,
  error,
  isWishButtonEnabled,
  isPromoGenerated,
  currency,
}: Props) => {
  const bottomButtonsStyle = isPromoGenerated
    ? 'justify-end'
    : 'justify-between'
  const onAddToWishlist = isPromoGenerated ? undefined : onAddWishlist

  return (
    <>
      <div className="relative py-6">
        {isProductLoading && <Loader viewType="filled-center" />}

        <div className="flex justify-between">
          <div className="flex w-3/6">
            <div className="flex-shrink-0 flex-grow-0 basis-[100px]">
              <ProductItemThumbnail url={product.thumbnailUrl} />
            </div>
            <div className="ml-7 flex w-full flex-col gap-1">
              <ProductItemName
                productName={product.productName}
                skuVariation={product.skuVariation}
                styleName={product.styleName}
                skuName={product.skuName}
                skuCode={product.skuCode}
              />
              <ProductItemQuantity
                isEditMode={isEditMode}
                currentQuantity={currentQuantity}
                productQuantity={product.quantity}
                onQuantityChange={handleItemQuantityChange}
              />
              {error && (
                <p className="mt-2 text-xs font-normal text-red-600">{error}</p>
              )}
            </div>
          </div>
          <CartItemPrice
            promotionalDiscounts={product.totalPromotionalDiscounts}
            retailPrice={product.retailPrice}
            salePrice={product.salePrice}
            price={product.price}
            currency={currency}
            totalRetailPrice={product.totalRetailPrice}
            totalSalePrice={product.totalSalePrice}
            hostessDiscountPercentage={product.hostessDiscountPercentage}
            combinedCredits={product.totalCombinedCredits}
            dsrDiscount={product.totalDsrDiscount}
            hostessDiscountValue={product.totalHostessDiscountValue}
            productCredits={product.totalProductCredits}
            totalPrice={product.totalPrice}
            totalDiscounts={product.totalDiscount}
          />
        </div>
        <div className={`ml-[128px] mt-5 flex flex-row ${bottomButtonsStyle}`}>
          {!isEditMode && !isPromoGenerated && (
            <HostessDiscountButton
              productId={product.id}
              isDiscountApplied={product.hostessDiscount}
              isDiscountDisallow={product.disallowHostessDiscounts}
            />
          )}

          <ProductItemControlsDesktop
            id={product.skuId}
            isEditMode={isEditMode}
            isEditable={!product.hostessDiscount && !isPromoGenerated}
            onCancel={handleCancel}
            onAdd={onAdd}
            onRemove={onRemove}
            onEditSwitch={onEditSwitch}
            onAddWishlist={onAddToWishlist}
            isWishButtonEnabled={isWishButtonEnabled}
          />
        </div>
        {canBeAutoShipped && (
          <div className="mt-6">
            <CartProductSubscription product={product} />
          </div>
        )}
      </div>

      <Divider />
    </>
  )
}
