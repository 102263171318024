import { format } from '@nori/date'
import { useTranslations } from '@nori/lang-i18n'
import { TableCell, TableRow } from '@nori/ui-kit'

import type { DiscountEntity } from '@nori/app-kit'

type Props = {
  record: DiscountEntity
}

export function CreditHistoryDiscountRecord({ record }: Props) {
  const t = useTranslations('contactDetailsPage.norwexPerks.creditHistory')

  const expires = record.expiresAt
    ? format(Date.parse(record.expiresAt?.toString()), 'MMMM dd, yyyy')
    : ''

  return (
    <TableRow>
      <TableCell>
        <p
          data-test-id={`history-table-reward-type_${record.id}`}
          className="text-xs font-bold text-sky-900"
        >
          {t('hostessDiscounts')}
        </p>
      </TableCell>
      <TableCell>
        <p
          data-test-id={`history-table-reward-description_${record.id}`}
          className="textgray-600 text-xs"
        >
          {record.description}
        </p>
      </TableCell>
      <TableCell>
        <p
          data-test-id={`history-table-reward-start-date_${record.id}`}
          className="textgray-600 text-xs"
        >
          {format(Date.parse(record.createdAt), 'MMMM dd, yyyy')}
        </p>
      </TableCell>
      <TableCell>
        <p
          data-test-id={`history-table-reward-finish-date_${record.id}`}
          className="textgray-600 text-xs"
        >
          {expires}
        </p>
      </TableCell>
      <TableCell>
        <p
          data-test-id={`history-table-reward-amount_${record.id}`}
          className="textgray-600 text-xs"
        >
          {record.amount}
        </p>
      </TableCell>
    </TableRow>
  )
}
