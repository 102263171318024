import { observer } from '@nori/di'
import { useTranslations } from '@nori/lang-i18n'
import { Icon } from '@nori/ui-kit'
import { useContactContext } from '~/modules/contacts/interface/use-contact-context'

export const AppliedFilters = observer(() => {
  const { contactFiltersStore, contactFiltersAction } = useContactContext()

  const t = useTranslations()

  return (
    <div className="flex flex-wrap gap-x-6 gap-y-3">
      {contactFiltersStore.appliedFilters.map((filter) => {
        return (
          <div
            key={`${filter.translationKey}-${filter.value}`}
            className="flex items-center gap-1 rounded-sm bg-primary-50 p-1 text-xs font-normal text-primary-900 "
          >
            {t(filter.translationKey)}
            <div
              onClick={() => contactFiltersAction.handleRemoveOneFilter(filter)}
              className="cursor-pointer"
            >
              <Icon size="xs" name="CloseLine" />
            </div>
          </div>
        )
      })}
    </div>
  )
})
