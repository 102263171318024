import { useMemo, useState } from 'react'

import { useTranslations } from '@nori/lang-i18n'
import { Dropdown, Icon, Radio, Tooltip } from '@nori/ui-kit'

import { AutosaveTooltip } from './autosave-tooltip'

import type { SubscriptionProps } from '../core/subscription-props.value-object'

export const ProductSubscription = ({
  isLoading,
  subscriptions,
  selectedSubscriptionId,
  isSubscriptionSelected,
  onSubscriptionOptionChange,
  onSubscriptionOpen,
  onSubscriptionClose,
}: SubscriptionProps) => {
  const t = useTranslations()
  const [singlePurchase, setSinglePurchase] = useState<boolean>(true)
  const loadingClassName = isLoading ? 'opacity-50 pointer-events-auto' : ''

  const selectedSubscription = useMemo(() => {
    if (singlePurchase && !selectedSubscriptionId) {
      return undefined
    }
    const selectedSubscription = subscriptions?.find(
      (subscription) => subscription.id === selectedSubscriptionId
    )
    if (selectedSubscription) {
      return selectedSubscription
    } else if (subscriptions?.length) {
      subscriptions[0] && onSubscriptionOptionChange(subscriptions[0].id)
      return subscriptions[0]
    }
  }, [selectedSubscriptionId, subscriptions, onSubscriptionOptionChange])

  return (
    <div className={`${loadingClassName}`}>
      <Radio
        value={''}
        checked={isSubscriptionSelected}
        onChange={() => {
          setSinglePurchase(false)
          onSubscriptionOpen()
        }}
      >
        <div className="flex flex-col gap-1">
          <span className="text-sm leading-none text-primary-900">
            <span className="flex items-center gap-1">
              {t('cart.productList.autoSave')}
              <span className="text-xs font-normal text-gray-400">
                <Tooltip slot={<AutosaveTooltip />}>
                  <Icon name="InformationCircleFill" />
                </Tooltip>
              </span>
            </span>
          </span>
          <span className="text-xs font-normal text-gray-500">
            {t('cart.productList.autoSaveSubTitle')}
          </span>
        </div>
      </Radio>
      {isSubscriptionSelected && (
        <div className="flex">
          <div className="mt-3 w-auto">
            <Dropdown
              hideHelperBlock
              size="small"
              title={
                selectedSubscription?.name ||
                t('cart.productList.selectAutoShipOption')
              }
            >
              {subscriptions?.map((option) => {
                return (
                  <div
                    key={option.id}
                    className="cursor-pointer p-2 text-sm font-normal text-gray-900 hover:bg-gray-50"
                    onClick={() => {
                      onSubscriptionOptionChange(option.id)
                    }}
                  >
                    {option.name}
                  </div>
                )
              })}
            </Dropdown>
          </div>
        </div>
      )}
      <div className="mt-6 text-primary-900">
        <Radio
          value={''}
          checked={!isSubscriptionSelected}
          onChange={() => {
            setSinglePurchase(true)
            onSubscriptionClose()
          }}
        >
          <div>{t('cart.productList.singlePurchase')}</div>
        </Radio>
      </div>
    </div>
  )
}
