import { useQuery } from '@nori/app-kit'
import { useMount } from '@nori/ui-kit'

import { useShopForMyselfContext } from '../interface/use-shop-for-myself.context'

type Query = {
  search?: string
}

export const useInitSearchQuery = (): void => {
  const query = useQuery<Query>()

  const { searchProductsAction } = useShopForMyselfContext()

  useMount(() => {
    searchProductsAction.handleDropSearchQuery()

    if (query.search) {
      searchProductsAction.handleSearchQueryChange(query.search)
      searchProductsAction.handleRemoveQuery()
    }
  })
}
