import { Service } from '@nori/di'

import type { ReplaceBundleInput } from '../types/replace-bundle.input'

@Service()
export class BundleRequestMapper {
  getBundleOptionsRequest(): string {
    const query = new URLSearchParams()

    query.append('purchasable_override', 'false')

    return query.toString()
  }

  replaceBundleRequest(input: ReplaceBundleInput) {
    const skus = input.skuIds.map((skuId) => ({ sku_id: skuId }))
    return {
      skus,
      code: input.code,
      quantity: input.quantity,
      rosi_store: input.store,
      bundle_id: input.id,
    }
  }
}
