export const leftIconColorsMap = {
    normal: 'text-primary-600',
    failure: 'text-red-600',
    disabled: 'text-gray-400'
};
export const rightIconColorsMap = {
    normal: 'text-gray-500',
    failure: 'text-red-600',
    disabled: 'text-gray-400'
};
