import { FetchInterceptor, UserBrowserDataService } from '@nori/app-kit'
import { Inject, Service } from '@nori/di'

import type { FetchInterceptorInterface } from '@nori/app-kit'
import type { RequestResponse } from '@nori/fetch-request'

const interceptStatusList = [401]

@Service({ id: FetchInterceptor })
export class RewriteFetchInterceptor implements FetchInterceptorInterface {
  constructor(
    @Inject()
    private readonly userBrowserDataService: UserBrowserDataService
  ) {}
  async onFetch<T>({
    response,
  }: {
    response: RequestResponse<T>
    type: 'basic' | 'secured'
  }): Promise<RequestResponse<T>> {
    if (!interceptStatusList.includes(response.status)) return response

    if (response.status === 401 && process.env.NEXT_PUBLIC_NORI_SKIP_401) {
      throw new Error('401 unauthorized')
    }

    this.userBrowserDataService.handleClearUserData()

    throw new Error('Unauthorized')
  }
}
