import { AcceptAgreementErrors } from '~/modules/agreement/core/accept-agreement.errors'

import type { AcceptAgreementErrorInstance } from '~/modules/agreement/core/accept-agreement.errors'

export class AcceptAgreementErrorMapper {
  static toErrors(status: number, json: any): AcceptAgreementErrorInstance {
    switch (status) {
      case 400:
        return new AcceptAgreementErrors.BadRequest(json.error.message)

      case 401:
        return new AcceptAgreementErrors.Unathorized(json.error.message)

      case 404:
        return new AcceptAgreementErrors.NotFound(json.errors)

      case 422:
        return new AcceptAgreementErrors.NotFound(json.errors)

      default:
        return new AcceptAgreementErrors.UnknownError(json)
    }
  }
}
