import { getMoneyWithIsoCurrency } from '@nori/app-kit'
import { useTranslations } from '@nori/lang-i18n'
import { Icon, Link } from '@nori/ui-kit'
import { Routes } from '~/modules/routes'

import type { PendingOrderEntity } from '~/modules/subscription'

type Props = {
  pendingOrder: PendingOrderEntity
}
export const PendingOrderRow = ({ pendingOrder }: Props) => {
  const t = useTranslations('autosaveSettings.pendingOrder')

  return (
    <>
      <div className="mb-6 hidden justify-between border border-gray-100 bg-gray-50 px-6 py-5 lg:flex ">
        <div>
          <div className="text-sm font-normal text-gray-700">
            {t('autoSaveOrder')}
          </div>
          {pendingOrder.orderDate && (
            <div className="mt-1 text-sm font-normal text-gray-500">
              {pendingOrder.orderDate.formatInTZ('MMM dd, yyyy')}
            </div>
          )}
        </div>
        <div>
          {pendingOrder.orderId && (
            <div className="text-sm font-normal text-gray-700">
              {t('order')} # {pendingOrder.orderId}
            </div>
          )}
          <div className="mt-1 flex gap-2 text-sm font-normal text-primary-900">
            <Icon name="Refresh" />
            {t('pending')} {t('autoSaveOrder')}
          </div>
        </div>
        <div>
          <div className="items-center pt-2 text-base font-normal text-gray-500">
            {pendingOrder.orderTotal &&
              getMoneyWithIsoCurrency({
                cents: pendingOrder.orderTotal.cents,
                currency: pendingOrder.orderTotal.currency,
              })}
          </div>
        </div>
        <div className="items-center pt-2 text-base font-normal text-gray-500">
          {pendingOrder.orderId && (
            <Link
              size="medium"
              weight="bold"
              href={Routes.orders.view.path(pendingOrder.orderId)}
            >
              {t('viewDetails')}
            </Link>
          )}
        </div>
      </div>
      <div className="mb-6 justify-between border border-gray-100 bg-gray-50 px-6 py-5 lg:hidden">
        <div className="flex gap-2">
          <div className="text-sm font-normal text-gray-700">
            {t('autoSaveOrder')}
          </div>
          {pendingOrder.orderDate && (
            <div className="text-sm font-normal text-gray-500">
              {pendingOrder.orderDate.formatInTZ('MMM dd, yyyy')}
            </div>
          )}
        </div>
        <div className="mt-4 flex justify-between">
          {pendingOrder.orderId && (
            <div>
              <div className="text-sm font-normal text-gray-700">
                {t('order')}
              </div>
              <div className="text-sm font-normal text-gray-700">
                #{pendingOrder.orderId}
              </div>
            </div>
          )}
          <div className="mt-1 flex max-w-min gap-2 text-sm font-normal text-primary-900">
            <Icon name="Refresh" />
            {t('pending')} {t('autoSaveOrder')}
          </div>
        </div>
        <div className="mt-2 flex justify-between">
          <div className="items-center pt-2 text-base font-normal text-gray-500">
            {pendingOrder.orderTotal &&
              getMoneyWithIsoCurrency({
                cents: pendingOrder?.orderTotal.cents,
                currency: pendingOrder?.orderTotal.currency,
              })}
          </div>
          <div className="items-center pt-2 text-base font-normal text-gray-500">
            {pendingOrder.orderId && (
              <Link
                size="medium"
                weight="bold"
                href={Routes.orders.view.path(pendingOrder.orderId)}
              >
                {t('viewDetails')}
              </Link>
            )}
          </div>
        </div>
      </div>
    </>
  )
}
