import { useMemo } from 'react'

import { observer } from '@nori/di'
import { useTranslations } from '@nori/lang-i18n'
import { Input, Link, Loader, Swap, useDebounce, useMount } from '@nori/ui-kit'
import { useContactContext } from '~/modules/contacts/interface/use-contact-context'

import { ContactList } from './contact-list/contact-list'

import type { ContactSelectorProps } from './contact-selector'

type Props = Pick<
  ContactSelectorProps,
  | 'onSelectContact'
  | 'isSelfContactAllowed'
  | 'onSelectSelfContact'
  | 'selfContactLabel'
>

export const SearchContactBlock = observer(
  ({
    onSelectContact,
    isSelfContactAllowed,
    onSelectSelfContact,
    selfContactLabel,
  }: Props) => {
    const t = useTranslations('contactSelector')
    const { contactSelectorStore, contactSelectorAction } = useContactContext()
    const { onChangeValue, value } = useDebounce({
      initialValue: contactSelectorStore.searchQuery,
      onChangeDebounce: contactSelectorAction.handleSearchQueryChange,
    })

    useMount(contactSelectorAction.handleDropSearchQuery)

    return (
      <>
        <Input
          data-test-id={'search-contact-input'}
          value={value}
          type="search"
          onChange={onChangeValue}
          placeholder={t('searchByNameEmail')}
          hideHelperBlock
        />

        {isSelfContactAllowed && (
          <div className="mt-5">
            <Link
              onClick={onSelectSelfContact}
              data-test-id={'make-me-contact-link'}
              size={'medium'}
              weight={'bold'}
              justifyContent={'normal'}
            >
              <span className={'text-sm font-semibold text-primary-900'}>
                {selfContactLabel || t('makeMeContactText')}
              </span>
            </Link>
          </div>
        )}

        <div className="mt-3">
          <Swap is={contactSelectorStore.isContactsLoading} isSlot={<Loader />}>
            <ContactList onSelectContact={onSelectContact} />
          </Swap>
        </div>
      </>
    )
  }
)
