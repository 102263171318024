import { toMoneyValueObject } from '@nori/app-kit'
import { NoriDate } from '@nori/date'
import { Inject, Service } from '@nori/di'
import { ShippingAddressMapperService } from '~/modules/order'

import type { MoneyValueObject } from '@nori/app-kit'
import type { UpdateSubscriptionsInput } from '~/modules/subscription/infra/types/update-subscriptions/update-subscriptions.input'
import type { UpdateSubscriptionsRequest } from '~/modules/subscription/infra/types/update-subscriptions/update-subscriptions.request'
import type {
  AddShippingAddressInputValueObject,
  NextOrderCreditsValueObject,
  NextOrderValueObject,
  OneTimeShippingAddressEntity,
  SubscriptionEntity,
} from '../../core'
import type { AddSubscriptionInput } from '../../core/add-subscription.input'
import type { AddSubscriptionRequest } from '../types/add-subscription.request'
import type { SetShouldApplyCreditsInput } from '../types/set-should-apply-credits/set-should-apply-credits.input'
import type { SetShouldApplyCreditsRequest } from '../types/set-should-apply-credits/set-should-apply-credits.request'
import type { UpdateShippingAddressRequest } from '../types/update-shipping-address.request'

@Service()
export class SubscriptionMapper {
  constructor(
    @Inject()
    private readonly shippingAddressMapperService: ShippingAddressMapperService
  ) {}

  toSetShouldApplyCreditsRequest(
    input: SetShouldApplyCreditsInput
  ): SetShouldApplyCreditsRequest {
    return {
      apply_credits: input.applyCredits,
    }
  }

  toAddSubscriptionRequest(
    input: AddSubscriptionInput
  ): AddSubscriptionRequest {
    return {
      one_off: input.oneOff,
      quantity: input.quantity,
      next_cart_at: input.nextCartAt,
      sku_id: input.skuId,
      sku_ids: input.skuIds,
      bundle_code: input.bundleCode,
      cycle_id: input.cycleId,
      shipping_address_attributes: input.shippingAddress
        ? {
            address1: input.shippingAddress.address1,
            address2: input.shippingAddress.address2,
            city: input.shippingAddress.city,
            country: input.shippingAddress.country,
            state: input.shippingAddress.state,
            zip_code: input.shippingAddress.zipCode,
            first_name: input.shippingAddress.firstName,
            last_name: input.shippingAddress.lastName,
            phone: input.shippingAddress.phoneNumber,
          }
        : undefined,
    }
  }

  toUpdateSubscriptionsRequest(
    input: UpdateSubscriptionsInput
  ): UpdateSubscriptionsRequest {
    return {
      ids: input.ids,
      quantity: input.quantity,
      cycle_id: input.cycleId,
      next_cart_at: input.nextCartAt?.formatInTZ('yyyy-MM-dd'),
      shipping_address_attributes: input.shippingAddress
        ? {
            address1: input.shippingAddress.address1,
            address2: input.shippingAddress.address2,
            city: input.shippingAddress.city,
            country: input.shippingAddress.country,
            state: input.shippingAddress.state,
            zip_code: input.shippingAddress.zipCode,
            first_name: input.shippingAddress.firstName,
            last_name: input.shippingAddress.lastName,
            phone: input.shippingAddress.phoneNumber,
          }
        : undefined,
    }
  }

  toUpdateShippingAddressRequest(
    input: AddShippingAddressInputValueObject
  ): UpdateShippingAddressRequest {
    return {
      address1: input.address1,
      address2: input.address2,
      city: input.city,
      country: input.country,
      state: input.state,
      zip_code: input.zipCode,
      first_name: input.firstName,
      last_name: input.lastName,
      phone: input.phoneNumber || '',
      one_time: input.isOneTime,
      user_id: input.userId ? input.userId : undefined,
    }
  }

  toSubscriptions(data: any): SubscriptionEntity[] {
    return data.map((subscription: any) => this.toSubscription(subscription))
  }

  toNextOrderValueObject(data: any): NextOrderValueObject {
    const subscriptions: SubscriptionEntity[] = []
    const subscriptionsByCode: Record<
      string,
      SubscriptionEntity[] | undefined
    > = {}

    for (const _subscription of data.subscriptions) {
      const subscription = this.toSubscription(_subscription)
      const existingSubscription = subscriptions.find(
        (sub) =>
          sub.code === subscription.code && sub.oneOff === subscription.oneOff
      )

      if (existingSubscription) {
        existingSubscription.quantity += 1
        existingSubscription.total = this.calculateMoney({
          aPrice: existingSubscription.total,
          bPrice: subscription.total,
        })
      } else {
        subscriptions.push(subscription)
      }
    }

    return {
      applyCredits: data.apply_credits,
      credits: this.toCredits(data.credits),
      dsrDiscount: data.dsr_discount,
      isFreeShipping: data.free_shipping,
      otherOrderExists: data.other_order_exists,
      retryAt: data.retry_at ? new NoriDate(data.retry_at) : undefined,
      s2sDiscount: data.s2s_discount,
      subscriptions,
      subscriptionsByCode,
      oneTimeShippingAddress: this.toOneTimeShippingAddress(
        data.one_time_shipping_address
      ),
    }
  }

  private toOneTimeShippingAddress(
    data: any
  ): OneTimeShippingAddressEntity | undefined {
    if (!data?.id) return

    return {
      id: data.id,
      address1: data.address1,
      address2: data.address2 || '',
      city: data.city,
      state: data.state,
      zipCode: data.zip_code,
      firstName: data.first_name,
      lastName: data.last_name,
      phoneNumber: data.phone,
      country: data.country,
      userId: data.user_id,
      isOneTime: data.one_time,
      workflowState: data.workflow_state,
    }
  }

  private toSubscription(data: any): SubscriptionEntity {
    return {
      active: data.active,
      id: data.id,
      oneOff: data.one_off,
      pqv: data.pqv,
      price: toMoneyValueObject(data.price),
      productId: data.product_id,
      productName: data.product_name,
      quantity: data.quantity,
      code: data.bundle?.code ?? data.sku_code,
      skuCode: data.sku_code,
      skuId: data.sku_id,
      startAt: new Date(data.start_at),
      store: data.store,
      thumbnailUrl: data.thumbnail_url ?? '',
      userId: data.user_id,
      workflowState: data.workflow_state,
      bundle: data.bundle
        ? {
            bundlePqv: data.bundle.bundle_pqv,
            code: data.bundle.code,
            descriptor: data.bundle.descriptor,
            id: data.bundle.id,
            maxDsrPrice: data.bundle.max_dsr_price,
            maxPrice: data.bundle.max_price,
            name: data.bundle.name,
            thumbnailUrl: data.bundle.thumbnail_url ?? '',
            bundlePrice: toMoneyValueObject(data.bundle.bundle_price),
            bundleRetailPrice: toMoneyValueObject(
              data.bundle.bundle_retail_price
            ),
          }
        : undefined,
      bundleCode: data.bundle_code ?? undefined,
      bundleId: data.bundle_id ?? undefined,
      cycleDuration: data.cycle_duration ?? undefined,
      cycleId: data.cycle_id ?? undefined,
      cycleName: data.cycle_name ?? undefined,
      cycleType: data.cycle_type ?? undefined,
      lastCartAt: data.last_cart_at ? data.last_cart_at : undefined,
      nextCartAt: data.next_cart_at
        ? new NoriDate(data.next_cart_at)
        : undefined,
      originalOrderId: data.original_order_id ?? undefined,
      retailPrice: toMoneyValueObject(data.retail_price),
      salePrice: toMoneyValueObject(data.sale_price),
      shippingAddress: data.shipping_address
        ? this.shippingAddressMapperService.toShippingAddressEntity(
            data.shipping_address
          )
        : undefined,
      shippingAddressId: data.shipping_address_id ?? undefined,
      total: toMoneyValueObject(data.total),
      dsrPrice: toMoneyValueObject(data.dsr_price),
      subscriptionPrice: toMoneyValueObject(data.subscription_price),
      skipAt: data.skip_at ? new NoriDate(data.skip_at) : undefined,
      canBeSkipped: data.can_be_skipped,
    }
  }

  private toCredits(data: any): NextOrderCreditsValueObject {
    return {
      combinedCredits: data.combined_credits,
      currency: data.currency,
      hostessCredits: data.hostess_credits,
      hostessDiscountsHash: data.hostess_discounts_hash,
      loyaltySavingsCents: data.loyalty_savings_cents,
      otherStoresWithHostessRewards: data.other_stores_with_hostess_rewards,
      productCredits: data.product_credits,
      promotionalCredits: data.promotional_credits,
      storeCredits: data.store_credits,
    }
  }

  private calculateMoney({
    aPrice,
    bPrice,
  }: {
    aPrice?: MoneyValueObject
    bPrice?: MoneyValueObject
  }): MoneyValueObject | undefined {
    if (!aPrice || !bPrice) {
      return undefined
    }

    if (!aPrice) {
      return bPrice
    }

    if (!bPrice) {
      return aPrice
    }

    return {
      cents: aPrice.cents + bPrice.cents,
      currency: aPrice.currency,
    }
  }
}
