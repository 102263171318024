import { Action, Inject, observe } from '@nori/di'
import { CalculateTaxService } from '~/modules/cart/interface/services/calculate-tax.service'
import { CartStore } from '~/modules/cart/interface/store/cart-store/cart.store'

import type { Lambda } from '@nori/di'

@Action()
export class CalculateTaxAction {
  private checkoutTotalDispose?: Lambda = undefined

  constructor(
    @Inject() private readonly calculateTaxService: CalculateTaxService,
    @Inject() private readonly cartStore: CartStore
  ) {}

  handleSubscribe(): void {
    this.checkoutTotalDispose?.()

    this.handlePoling()

    this.checkoutTotalDispose = observe(this.cartStore, 'cart', () => {
      if (!this.cartStore.cart) {
        return
      }

      if (this.cartStore.taxCalculationData.isLoading) {
        return
      }

      this.handlePoling()
    })
  }

  handleUnsubscribe(): void {
    this.checkoutTotalDispose?.()
    this.checkoutTotalDispose = undefined
  }

  private shouldStartPolling(): boolean {
    if (
      this.cartStore.isTaxCalculated ||
      !this.cartStore.isShippingAddressDefined
    ) {
      return false
    }
    return true
  }

  private handlePoling(): void {
    if (!this.shouldStartPolling()) return

    const cartId = this.cartStore.cart?.id
    if (!cartId) return

    this.calculateTaxService.loadCheckoutTotal({ cartId })
  }
}
