function checkError(result) {
    if (!result) return false;
    return 'error' in result;
}
export function isErr(result) {
    if (!Array.isArray(result)) {
        return checkError(result);
    }
    const findResult = result.find((item)=>checkError(item));
    return !!findResult;
}
