import {
  CreditsService,
  HistoryService,
  RewardsInfoService,
} from '@nori/app-kit'
import { Action, Inject } from '@nori/di'
import { isErr } from '@nori/result'
import { Routes } from '~/modules/routes'

import { LoadContactsService } from '../services/contact.service'
import { ContactsStore } from '../store/contacts.store'

import type { SortDirectionValueObject } from '~/core'
import type { ContactListColumnsSelectionMapValueObject } from '../../core/contact-list-columns-selection-map.value-object'
import type { ContactSort } from '../../core/contact-sort'

const MIN_SEARCH_QUERY_LENGTH = 3
const INITIAL_PAGE = 1

@Action()
export class ContactsAction {
  constructor(
    @Inject() private readonly contactsStore: ContactsStore,
    @Inject() private readonly historyService: HistoryService,
    @Inject() private readonly loadContactsService: LoadContactsService,
    @Inject() private readonly rewardsInfoService: RewardsInfoService,
    @Inject() private readonly creditsService: CreditsService
  ) {}

  async handleLoadContactPerks(customerId: number): Promise<void> {
    const perks = this.contactsStore.getOrCreateContactPerks(customerId)
    perks.setIsLoading(true)
    perks.setError(undefined)

    this.contactsStore.setContactPerks(customerId, perks)

    await this.loadContactPerks(customerId)

    perks.setIsLoading(false)
  }

  private async loadContactPerks(customerId: number): Promise<void> {
    const perks = this.contactsStore.getOrCreateContactPerks(customerId)
    const [freeShippingResult, creditsInfoResult, rewardsInfoResult] =
      await Promise.all([
        this.rewardsInfoService.getFreeShipping(customerId),
        this.creditsService.getCreditsInfo(customerId),
        this.rewardsInfoService.fetchRewardsInfo(customerId),
      ])

    if (isErr(creditsInfoResult)) {
      perks.setError(creditsInfoResult.error)
      return
    }

    if (isErr(rewardsInfoResult)) {
      perks.setError(rewardsInfoResult.error)
      return
    }

    const hasFreeShipping =
      !isErr(freeShippingResult) && freeShippingResult.data

    perks.setData({
      creditsInfo: creditsInfoResult.data,
      hasFreeShipping,
      rewardsInfo: rewardsInfoResult.data,
    })
  }

  handleSearchQueryChange(query: string): void {
    const existQuery = this.contactsStore.searchQuery
    if (query === existQuery) return

    this.contactsStore.setSearchQuery(query)

    if (!query) {
      this.contactsStore.setSortType('first_name')
      this.contactsStore.setSortDirection('asc')

      this.loadContactsService.loadContacts({
        page: INITIAL_PAGE,
        searchQuery: query,
      })
      return
    }

    if (query.length < MIN_SEARCH_QUERY_LENGTH) return

    this.contactsStore.setSortType('_score')
    this.contactsStore.setSortDirection('desc')

    this.loadContactsService.loadContacts({
      page: INITIAL_PAGE,
      searchQuery: query,
    })
  }

  handleSortChange(
    type: ContactSort,
    direction: SortDirectionValueObject
  ): void {
    this.contactsStore.setSortType(type)
    this.contactsStore.setSortDirection(direction)

    this.loadContactsService.loadContacts({})
  }

  handleInitialLoad(): void {
    this.contactsStore.resetFilters()
    this.loadContactsService.loadContacts({})
  }

  handleLoadMore(page: number): void {
    this.loadContactsService.loadContacts({
      page,
    })
  }

  handleChangePerPage(perPage: number): void {
    this.loadContactsService.loadContacts({
      page: INITIAL_PAGE,
      perPage,
    })
  }

  handleOpenLastOrder(id: number): void {
    this.historyService.push(Routes.orders.view.path(id))
  }

  handleOpenContactDetailsPage(id: number): void {
    this.historyService.push(Routes.contacts.contactDetails.path({ id }))
  }

  handleOpenCreateContactPage(): void {
    this.historyService.push(Routes.contacts.createContact.path())
  }

  handleToggleSelectContact(id: number): void {
    if (!this.contactsStore.contactsList) return
    this.contactsStore.toggleSelectedContactId(id)
  }

  handleToggleSelectAllContacts(): void {
    if (!this.contactsStore.contactsList) return

    if (this.contactsStore.selectedContactIds.size) {
      this.contactsStore.setSelectedContactIds([])
      return
    }

    const ids = this.contactsStore.contactsList.map((contact) => contact.id)

    this.contactsStore.setSelectedContactIds(ids)
  }

  handleEmailSelectedContacts(): void {
    if (!this.contactsStore.contactsList) return

    const selectedEmails = this.contactsStore.selectedContactEmails.join(';')
    const mailtoLink = `mailto:${encodeURIComponent(selectedEmails)}`
    window.location.href = mailtoLink
  }

  handleApplyColumnsVisibility(
    value: ContactListColumnsSelectionMapValueObject
  ): void {
    this.contactsStore.applyColumnsVisibility(value)
  }
}
