import { Inject, Service } from '@nori/di'
import { isErr } from '@nori/result'
import { ORDER_LIST_IGNORED_ERRORS } from '~/modules/orders-list/core'
import { OrdersListAdapter } from '~/modules/orders-list/infra/orders-list.adapter'

import { OrdersListStore } from '../stores/orders-list.store'
import { OrdersListFiltersStore } from '../stores/orders-list-filters.store'

import type { PromiseResult } from '@nori/result'
import type {
  OrderEntity,
  OrdersListErrorInstance,
  OrdersListValueObject,
} from '~/modules/orders-list/core'

@Service()
export class LoadOrdersService {
  constructor(
    @Inject() private readonly ordersListAdapter: OrdersListAdapter,
    @Inject() private readonly ordersListStore: OrdersListStore,
    @Inject() private readonly ordersListFiltersStore: OrdersListFiltersStore
  ) {}

  async loadOrderList({ page }: { page?: number }): Promise<void> {
    this.ordersListStore.setError(undefined)
    this.ordersListStore.setIsLoading(true)
    const { partyId } = this.ordersListStore

    const result = await (partyId
      ? this.getPartyOrdersList(partyId, page)
      : this.getOrdersList(page))

    if (!isErr(result)) {
      this.setOrdersList(result.data.orders)
      this.ordersListStore.setPagination(result.data.pagination)
      this.ordersListStore.setIsLoading(false)
      return
    }

    if (
      ORDER_LIST_IGNORED_ERRORS.find(
        (ignoredError) => result.error instanceof ignoredError
      )
    ) {
      return
    }

    this.ordersListStore.setIsLoading(false)
    this.ordersListStore.setError(result.error)
  }

  private async getPartyOrdersList(
    partyId: number,
    page?: number
  ): PromiseResult<OrdersListValueObject, OrdersListErrorInstance> {
    return this.ordersListAdapter.getPartyOrdersList(partyId, {
      searchFilter: this.ordersListStore.searchFilterKey,
      searchParam: this.ordersListStore.queryFilters.values.query,
      sortBy: this.ordersListStore.sortBy,
      ordering: this.ordersListStore.sortOrder,
      page: page ?? this.ordersListStore.pagination?.page,
      perPage: this.ordersListStore.pagination?.perPage,
    })
  }

  private async getOrdersList(
    page?: number
  ): PromiseResult<OrdersListValueObject, OrdersListErrorInstance> {
    const formatDates = this.ordersListFiltersStore.dateRangeSection
    return this.ordersListAdapter.getOrdersList({
      searchFilter: this.ordersListStore.searchFilterKey,
      page: page ?? this.ordersListStore.pagination?.page,
      perPage: this.ordersListStore.pagination?.perPage,
      searchParam: this.ordersListStore.queryFilters.values.query,
      sortBy: this.ordersListStore.sortBy,
      ordering: this.ordersListStore.sortOrder,
      filters: this.ordersListFiltersStore.appliedFilters,
      from: formatDates?.from.backendTimestamp,
      to: formatDates?.to.backendTimestamp,
    })
  }

  private setOrdersList(orders: OrderEntity[]): void {
    const canBeMovedOnly = this.ordersListFiltersStore.appliedFilters.some(
      (it) => it.filterKey === 'can_be_moved'
    )

    if (!canBeMovedOnly) return this.ordersListStore.setOrdersList(orders)

    const filteredOrders = orders.filter(
      (it) => !!it.orderMoveEligibility?.isEligible
    )
    this.ordersListStore.setOrdersList(filteredOrders)
  }
}
