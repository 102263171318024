import { ProductImage } from '@nori/app-kit'

type Props = {
  url?: string
  size?: number
}

export function ProductItemThumbnail({ url, size = 125 }: Props) {
  return (
    <div>
      <ProductImage src={url} height={size} width={size} alt="" />
    </div>
  )
}
