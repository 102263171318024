import {
  buildUrl,
  getBaseUrl,
  RequestService,
  SERVICE_NAMES,
} from '@nori/app-kit'
import { Adapter, Inject } from '@nori/di'
import { resultErr, resultOk } from '@nori/result'

import { WishListErrors } from '../core/error/wish-list.errors'

import { WishListMapper } from './mappers/wish-list-response.mapper'

import type { PromiseResult } from '@nori/result'
import type { ToggleWishlistInput } from '~/modules/wishlist/core/input/toggle-wishlist.input'
import type { WishListErrorInstance } from '../core/error/wish-list.errors'
import type { WishListInput } from '../core/input/wishlist.input'
import type { WishListValueObject } from '../core/value-object/wish-list.value-object'

@Adapter()
export class WishListAdapter {
  baseUrl = getBaseUrl(SERVICE_NAMES.UGC_SERVICE)

  constructor(@Inject() private readonly requestService: RequestService) {}

  async getWishList({
    page,
    perPage,
    customerCode,
  }: WishListInput): PromiseResult<WishListValueObject, WishListErrorInstance> {
    try {
      const customerLabel = customerCode ? '/customer' : ''
      const endpoint = this.getUrl({ customerCode }) + customerLabel

      const url = buildUrl(endpoint, {
        queryParams: {
          // eslint-disable-next-line camelcase
          per_page: perPage,
          page,
        },
      })

      const response = await this.requestService.secured.get<any>(url)

      if (response.ok) {
        return resultOk(WishListMapper.toValueObject(response.json))
      }
      return resultErr(new WishListErrors.UnknownError(response.json))
    } catch (error: unknown) {
      return resultErr(new WishListErrors.UnexpectedError(error))
    }
  }

  async addItemToWishList({
    skuId,
    customerCode,
  }: ToggleWishlistInput): PromiseResult<undefined, WishListErrorInstance> {
    try {
      const url = this.getUrl({ customerCode, skuId })

      const response = await this.requestService.secured.post<any>(url, {
        body: {},
      })

      if (response.ok) {
        return resultOk(undefined)
      }
      return resultErr(new WishListErrors.UnknownError(response.json))
    } catch (error: unknown) {
      return resultErr(new WishListErrors.UnexpectedError(error))
    }
  }

  async removeItemFromWishList({
    skuId,
    customerCode,
  }: ToggleWishlistInput): PromiseResult<undefined, WishListErrorInstance> {
    try {
      const url = this.getUrl({ customerCode, skuId })

      const response = await this.requestService.secured.delete<any>(url, {
        body: {},
      })

      if (response.ok) {
        return resultOk(undefined)
      }
      return resultErr(new WishListErrors.UnknownError(response.json))
    } catch (error: unknown) {
      return resultErr(new WishListErrors.UnexpectedError(error))
    }
  }

  private getUrl({
    customerCode,
    skuId,
  }: Partial<ToggleWishlistInput>): string {
    const endpoint = customerCode ? `/users/${customerCode}` : ''
    const skuIdSegment = skuId ? `/${skuId}` : ''

    return `${this.baseUrl}${endpoint}/likes/sku${skuIdSegment}`
  }
}
