import { LocalStorageItem } from '@nori/app-kit'
import { Store } from '@nori/di'

const LS_APP_START_SETTINGS = '@nori/start-settings'

type AppSettingsValueObject = {
  isHeaderVisible: boolean
  isFooterVisible: boolean
}

@Store()
export class RootAppStore {
  isLoadingDetails = true

  isDataLoaded = false

  appStartSettings = LocalStorageItem<AppSettingsValueObject>(
    LS_APP_START_SETTINGS,
    {
      initialValue: {
        isHeaderVisible: true,
        isFooterVisible: true,
      },
    }
  )

  setIsLoadingDetails(value: boolean): void {
    this.isLoadingDetails = value
  }

  setIsDataLoaded(value: boolean): void {
    this.isDataLoaded = value
  }
}
