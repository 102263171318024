import { useEffect } from 'react'
import { useRouter } from 'next/router'
import Script from 'next/script'

import { observer } from '@nori/di'
import { ALL_DOMAINS } from '~/core/all-domains.constants'

import { useGtmContext } from '../interface/use-gtm-context'

const GTM_ID = 'GTM-TW9FTP6D'

function isGTMEnabled(): boolean {
  if (typeof window === 'undefined') {
    return false
  }

  return ALL_DOMAINS.some((url) => window.location.host.includes(url))
}

export const GoogleTagManager = observer(() => {
  const router = useRouter()
  const currentRoute = router.asPath
  const { gtmAction } = useGtmContext()

  useEffect(() => {
    if (isGTMEnabled()) {
      gtmAction.handleTrackNavigation()
    }
  }, [currentRoute])

  if (!isGTMEnabled()) {
    return null
  }

  return (
    <>
      <Script id="gtm-script">
        {`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','${GTM_ID}');
    `}
      </Script>
      <noscript
        dangerouslySetInnerHTML={{
          __html: `<iframe src="https://www.googletagmanager.com/ns.html?id=${GTM_ID}"
height="0" width="0" style="display:none;visibility:hidden"></iframe>`,
        }}
      ></noscript>
    </>
  )
})
