function convertDecimal(decimal) {
    if (!decimal) {
        return;
    }
    if (decimal.length === 1) {
        return `${decimal}0`;
    }
    if (decimal.length === 2) {
        return decimal;
    }
    return decimal.slice(0, 2);
}
export function getCentsValue(value, min) {
    const valueWithDot = value.replace(',', '.');
    const [integer, decimal] = valueWithDot.split('.');
    const convertedDecimal = convertDecimal(decimal);
    const centsValue = Number(integer != null ? integer : 0) * 100 + Number(convertedDecimal != null ? convertedDecimal : 0);
    if (min !== undefined && centsValue < min) {
        return min;
    }
    return centsValue;
}
