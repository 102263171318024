import { useProfileContext } from '@nori/app-kit'
import { observer } from '@nori/di'
import { Loader, Swap, useInitialLoad } from '@nori/ui-kit'
import { useAutosaveContext } from '~/modules/autosave/interface/use-autosave-context'
import { useContactContext } from '~/modules/contacts/interface/use-contact-context'

import { AddItemModal } from './add-item/add-item.modal'
import { NextOrderPanelContent } from './next-order-panel/next-order-panel-content'
import { NextOrderEmpty } from './next-order-empty'

type Props = {
  isContactDetailsPage?: boolean
}

export const NextOrderPanel = observer(
  ({ isContactDetailsPage = false }: Props) => {
    const { nextOrderAction, nextOrderStore } = useAutosaveContext()
    const { contactDetailsStore } = useContactContext()
    const { userStore } = useProfileContext()

    useInitialLoad(() =>
      nextOrderAction.handleInitialLoad(
        isContactDetailsPage
          ? contactDetailsStore.contact?.customerId
          : userStore.currentUser?.id
      )
    )

    if (nextOrderStore.error) return <div>{nextOrderStore.error.message}</div>

    return (
      <>
        <Swap
          is={nextOrderStore.isLoading}
          isSlot={
            <div className="mt-5">
              <Loader />
            </div>
          }
        >
          <Swap
            is={!nextOrderStore.nextOrder?.subscriptions?.length}
            isSlot={<NextOrderEmpty userId={userStore.id} />}
          >
            <NextOrderPanelContent
              isContactDetailsPage={isContactDetailsPage}
            />
          </Swap>
        </Swap>
        <AddItemModal />
      </>
    )
  }
)
