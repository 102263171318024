import { observer } from '@nori/di'
import { useTranslations } from '@nori/lang-i18n'
import { Button } from '@nori/ui-kit'

import { useContactContext } from '../../../interface/use-contact-context'

export const FilterButtons = observer(() => {
  const { contactFiltersAction } = useContactContext()

  const t = useTranslations('contacts.filters')

  return (
    <div className=" flex justify-between">
      <Button onClick={contactFiltersAction.handleApplyFilters}>
        {t('apply')}
      </Button>
      <Button
        onClick={contactFiltersAction.handleClearAllFiltersOptions}
        color="alternative"
        iconLeft="CloseLine"
      >
        {t('clearAll')}
      </Button>
    </div>
  )
})
