import { useMemo } from 'react'

import {
  centsToMainUnit,
  dateFormat,
  getMoneyWithIsoCurrency,
  useProfileContext,
} from '@nori/app-kit'
import { differenceInCalendarDays, format } from '@nori/date'
import { observer } from '@nori/di'
import { useTranslations } from '@nori/lang-i18n'
import { Icon, Link } from '@nori/ui-kit'
import { Routes } from '~/modules/routes'

import type { ReferralEarningPeriodEntity } from '@nori/app-kit'
import type { ContactEntity } from '~/modules/contacts/core'

type Props = {
  contact: ContactEntity
  referralEarningPeriod?: ReferralEarningPeriodEntity
}

export const EarningPeriod = observer(
  ({ contact, referralEarningPeriod }: Props) => {
    const t = useTranslations('contacts.list.table.extended.earningPeriod')
    const { userStore } = useProfileContext()

    const eventSales = useMemo(() => {
      if (!referralEarningPeriod)
        return getMoneyWithIsoCurrency({
          cents: 0,
          currency: userStore.currency,
        })

      return getMoneyWithIsoCurrency({
        cents: referralEarningPeriod.totalRetailSalesCents,
        currency: referralEarningPeriod.currency,
      })
    }, [referralEarningPeriod, userStore.currency])

    if (!contact.endEarningPeriod) return null
    return (
      <div className="bg-white p-5 shadow-xs">
        <span className="text-sm font-bold text-primary-900">
          {t('activeEarningPeriod')}
        </span>
        <div className="mt-2.5 flex flex-col justify-between gap-3 text-xs text-gray-500 lg:flex-row lg:items-center lg:gap-0">
          <div className="flex items-center">
            <Icon name="ClockOutline" />
            <span className="ml-1">
              {t('ends', {
                endDate: format(contact.endEarningPeriod, 'MMM dd'),
                days: differenceInCalendarDays(contact.endEarningPeriod),
              })}
            </span>
          </div>
          <div className="flex items-center">
            <Icon name="CashOutline" />
            <span className="ml-1">
              {centsToMainUnit({
                cents: contact.totalRewardsCredits.cents,
              })}{' '}
              {t('inCredit')}
            </span>
          </div>
          <div className="flex items-center">
            <Icon name="UserOutline" />
            <span className="ml-1">
              {contact.hostessDiscounts} {t('discountedItems')}
            </span>
          </div>
          <div className="flex items-center">
            <Icon name="CurrencyDollarOutline" />
            <span className="ml-1">
              {eventSales} {t('eventSales')}
            </span>
          </div>
          <Link
            href={Routes.contacts.contactDetails.path({
              id: contact.id,
              tab: 'credits',
            })}
          >
            {t('viewDetails')}
          </Link>
        </div>
      </div>
    )
  }
)
