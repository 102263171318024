import { useTranslations } from '@nori/lang-i18n'

export const useAllAutoSaveTable = (
  isOpen: boolean,
  isActiveAutosaveTable: boolean
): {
  modalText: string
  modalTitle: string
  modalCancelText: string
  modalConfirmText: string
  bottomText: string
} => {
  const t = useTranslations('autosaveSettings')

  let modalTitle = isActiveAutosaveTable
    ? t('cancelAutoSaveModalForAll.title')
    : t('reactivateAutoSaveModal.title')
  modalTitle = isOpen ? t('skipAutoSaveModal.title') : modalTitle

  let modalText = isActiveAutosaveTable
    ? t('cancelAutoSaveModalForAll.description')
    : t('reactivateAutoSaveModal.description')

  modalText = isOpen ? t('skipAutoSaveModal.description') : modalText

  let modalCancelText = isActiveAutosaveTable
    ? t('cancelAutoSaveModalForAll.cancel')
    : t('reactivateAutoSaveModal.cancel')

  modalCancelText = isOpen ? t('skipAutoSaveModal.cancel') : modalCancelText

  let modalConfirmText = isActiveAutosaveTable
    ? t('cancelAutoSaveModalForAll.confirm')
    : t('reactivateAutoSaveModal.confirm')

  modalConfirmText = isOpen ? t('skipAutoSaveModal.confirm') : modalConfirmText

  const bottomText = isOpen ? t('skipAutoSaveModal.bottomDescription') : ''

  return {
    modalText,
    modalTitle,
    modalCancelText,
    modalConfirmText,
    bottomText,
  }
}
