import { observer } from '@nori/di'
import { useCartContext } from '~/modules/cart'

import { useBillingFormContext } from '../../interface/use-billing-form.context'
import { BillingAddress } from '../billing-address'

export const BillingAddressSection = observer(() => {
  const { cartStore } = useCartContext()
  const { billingFormAction, billingFormStore } = useBillingFormContext()

  if (!cartStore.cart) return <div />
  return (
    <BillingAddress
      isSameAsShipping={billingFormStore.isBillingAddressSameAsShipping}
      onToggleShipping={billingFormAction.handleSetBillingAddressSameAsShipping}
      validator={billingFormStore.billingAddressValidatorMap.main}
    />
  )
})
