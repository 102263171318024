import { observer } from '@nori/di'
import { Divider, Dropdown } from '@nori/ui-kit'
import { usePartiesListContext } from '~/modules/parties/interface/use-parties-list-context'

import { PartyDropdownItem } from './party-dropdown-item'

type Props = {
  partyId?: number
  onSelectParty: (partyId: number) => void
}

export const PartyDropdown = observer(({ partyId, onSelectParty }: Props) => {
  const { partiesListStore } = usePartiesListContext()
  const { partiesList } = partiesListStore

  return (
    <Dropdown
      title={
        <PartyDropdownItem
          party={partiesList?.find((party) => party.id === partyId)}
        />
      }
      size="small"
      hideHelperBlock
      data-test-id="party-dropdown"
      isWithBorder={false}
      isDisabled={!partiesList?.length}
    >
      {partiesList?.map((party, index) => (
        <>
          <div
            key={party.id}
            onClick={() => onSelectParty(party.id)}
            className="cursor-pointer px-4 py-5 hover:bg-gray-50"
          >
            <PartyDropdownItem party={party} />
          </div>
          {partiesList.length > 1 && index !== partiesList.length - 1 && (
            <Divider />
          )}
        </>
      ))}
    </Dropdown>
  )
})
