import { errorFactory } from '@nori/errors'

import type { KeyOfErrors } from '@nori/errors'

export const { UpdateNoteErrors } = errorFactory('UpdateNoteErrors', {
  BadRequest: 'Bad Request',
  UnexpectedError: 'Unexpected Error',
  UnknownError: 'Unknown Error',
})

export type UpdateNoteErrorInstance = InstanceType<
  KeyOfErrors<typeof UpdateNoteErrors>
>
