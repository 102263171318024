import { Inject, Service } from '@nori/di'
import { getBlock } from '@nori/lang-i18n'
import { NotifyAction } from '@nori/notify'
import { isErr } from '@nori/result'
import { sleep } from '@nori/ui-kit'
import { createLogger } from '~/infra/create-logger'
import { CartStore } from '~/modules/cart/interface/store/cart-store/cart.store'
import { CheckoutStore } from '~/modules/checkout/interface/store/checkout.store'

import { CartTaxAdapter } from '../../infra/cart-tax.adapter'
import { CartTotalAdapter } from '../../infra/cart-total.adapter'

import type { CheckoutTotalEntity } from '@nori/app-kit'
import type { DomainError } from '@nori/errors'
import type { PromiseResult } from '@nori/result'

const TOTAL_ATTEMPTS = 10
const SLEEP_TIMEOUT_MS = 1500

const logger = createLogger('calculate-tax.service')

@Service()
export class CalculateTaxService {
  constructor(
    @Inject() private readonly cartTaxAdapter: CartTaxAdapter,
    @Inject() private readonly cartTotalAdapter: CartTotalAdapter,
    @Inject() private readonly notifyAction: NotifyAction,
    @Inject() private readonly cartStore: CartStore,
    @Inject() private readonly checkoutStore: CheckoutStore
  ) {}

  async loadCheckoutTotal({
    cartId,
  }: {
    cartId: number
  }): PromiseResult<CheckoutTotalEntity, DomainError> {
    this.cartStore.taxCalculationData.setIsLoading(true)

    const calculateTaxResult = await this.cartTaxAdapter.calculateTax({
      cartId: cartId,
    })

    if (isErr(calculateTaxResult)) {
      logger.error(calculateTaxResult.error.key, calculateTaxResult.error)
      this.cartStore.taxCalculationData.setError(calculateTaxResult.error)
      this.cartStore.taxCalculationData.setIsLoading(false)
      return calculateTaxResult
    }

    this.notifyAction.handleAddNotify({
      type: 'success',
      title: getBlock('checkout')('taxLoading'),
    })

    const result = await this.pollCheckoutTotal(cartId, TOTAL_ATTEMPTS)
    this.cartStore.taxCalculationData.setIsLoading(false)

    return result
  }

  private async pollCheckoutTotal(
    cartId: number,
    remainingAttempts: number
  ): PromiseResult<CheckoutTotalEntity, DomainError> {
    const checkoutTotalResult = await this.cartTotalAdapter.getCartTotal({
      cartId,
    })

    if (isErr(checkoutTotalResult)) {
      this.notifyAction.handleAddNotify({
        type: 'danger',
        title: getBlock('checkout')('taxError'),
      })
      return checkoutTotalResult
    }

    if (checkoutTotalResult.data.isTaxCalculated) {
      this.cartStore.updateCartTotals(checkoutTotalResult.data)
      return checkoutTotalResult
    }

    if (remainingAttempts <= 0) {
      this.notifyAction.handleAddNotify({
        type: 'danger',
        title: getBlock('checkout')('taxError'),
      })
      this.checkoutStore.setIsExceededCheckoutAttempts(true)
      return checkoutTotalResult
    }

    await sleep(SLEEP_TIMEOUT_MS)
    return this.pollCheckoutTotal(cartId, remainingAttempts - 1)
  }
}
