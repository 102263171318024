import { NoriDate } from '@nori/date';
import { sortDates } from './sort-dates';
function getStartDate(date, minDate) {
    if (minDate && date.startOfDay() < minDate) return minDate;
    return date.startOfDay();
}
function getEndDate(date, maxDate) {
    if (maxDate && date.endOfDay() > maxDate) return maxDate;
    return date.endOfDay();
}
export function handleDates({ onChange , values , minDate , maxDate  }) {
    const nextValues = values.sort(sortDates).reduce((acc, date, index)=>{
        if (index === 0) return [
            ...acc,
            new NoriDate(getStartDate(date, minDate))
        ];
        if (index === 1) return [
            ...acc,
            new NoriDate(getEndDate(date, maxDate))
        ];
        return [
            ...acc,
            date
        ];
    }, []);
    onChange(nextValues);
}
