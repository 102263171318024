import { format } from '@nori/date'
import { Store } from '@nori/di'

import type { StepStatus } from '@nori/ui-kit'
import type { ContactEntity } from '~/modules/contacts/core/contact.entity'
import type { WizardStoreContact } from '~/modules/order-wizard/interface/store/wizard-store-contact'
import type { PartyEntity } from '~/modules/parties/core/entities/party.entity'
import type { OrderWizardStep } from '../../core/order-wizard-step'

@Store()
export class OrderWizardStore {
  isLoading = false
  query = ''
  selectedParty?: PartyEntity = undefined
  selectedHost?: ContactEntity = undefined
  selectedContact?: WizardStoreContact = undefined
  selfContact?: WizardStoreContact = undefined
  stepStatuses: Record<OrderWizardStep, StepStatus> = {
    contact: 'current',
    'party-or-host': 'upcoming',
  }

  get hostFullName(): string | undefined {
    if (this.selectedHost) {
      return `${this.selectedHost.firstName} ${this.selectedHost.lastName}`
    }
  }

  get hostName(): string | undefined {
    if (this.selectedHost)
      return `${this.selectedHost.firstName} ${this.selectedHost.lastName}`
    if (this.selectedParty) return this.selectedParty.hostess.displayName
    return undefined
  }

  get partyDateString(): string | undefined {
    if (!this.selectedParty) return undefined
    return format(this.selectedParty.partyAt, 'ccc MM/dd')
  }
  setIsLoading(value: boolean): void {
    this.isLoading = value
  }

  setQuery(value: string): void {
    this.query = value
  }

  setStepStatus(step: OrderWizardStep, status: StepStatus): void {
    this.stepStatuses[step] = status
  }

  setSelectedParty(value?: PartyEntity): void {
    this.selectedParty = value
  }

  setSelectedHost(value?: ContactEntity): void {
    this.selectedHost = value
  }

  setSelectedContact(value?: WizardStoreContact): void {
    this.selectedContact = value
  }

  get isContactSelected(): boolean {
    return !!this.selectedContact
  }

  get contactFullName(): string | undefined {
    if (this.selectedContact) {
      return `${this.selectedContact.firstName} ${this.selectedContact.lastName}`
    }
  }

  get contactEmail(): string | undefined {
    if (!this.selectedContact?.isEmailProvided) {
      return ''
    }
    if (this.selectedContact) {
      return `${this.selectedContact.email}`
    }
  }

  setSelfContact(value: WizardStoreContact): void {
    this.selfContact = value
  }
}
