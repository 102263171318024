import { cleanAll } from './clean-all';
import { getFullPath } from './get-full-path';
import { removeFromUrl } from './remove-from-url';
const QUERY_KEY = 'md';
cleanAll(QUERY_KEY);
export function toggleState({ isOpen , id  }) {
    const currentSearch = new URLSearchParams(window.location.search);
    if (!isOpen) {
        removeFromUrl({
            currentSearch,
            id,
            key: QUERY_KEY
        });
        return;
    }
    const isExist = window.location.search.includes(id);
    if (isExist) return;
    currentSearch.append(QUERY_KEY, id);
    window.history.pushState(window.history.state, '', getFullPath(currentSearch));
}
