import Link from 'next/link'

import { useTranslations } from '@nori/lang-i18n'
import { Swap } from '@nori/ui-kit'
import { useFeatureFlagsContext } from '~/modules/feature-flags/use-feature-flags'

import type { LinkWithChildren as HeaderLinkType } from '../../header-links/header-link.type'

type RenderLinksProps = {
  linkChildren: HeaderLinkType['children']
}

export function ChildrenList({ linkChildren }: RenderLinksProps) {
  const t = useTranslations()
  const { featureFlagsStore } = useFeatureFlagsContext()

  if (!linkChildren?.length) return null

  return (
    <div>
      {linkChildren.map((child) => {
        const isFeatureFlagEnabled = child.featureFlag
          ? featureFlagsStore.getFlag(child.featureFlag)
          : true

        return (
          <Swap
            is={!isFeatureFlagEnabled}
            isSlot={<></>}
            key={child.translationKey}
          >
            <Link
              data-test-id={child.dataTestId}
              key={child.translationKey}
              href={child.href}
              className="block px-2 py-2 text-sm font-bold text-primary-900 hover:bg-primary-50 hover:text-gray-900"
            >
              {t(`${child.translationKey}`)}
            </Link>
          </Swap>
        )
      })}
    </div>
  )
}
