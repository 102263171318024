import { DomainErrorClass } from '@nori/errors';
import { createRollbarLogger } from './create-rollbar-logger';
function getException(details, message) {
    if (details && typeof details === 'object' && details instanceof DomainErrorClass) {
        const exception = Error.call(details, message);
        exception.stack = details.stack;
        return exception;
    }
    return details;
}
function getArgs({ name , section , message , details  }) {
    const title = section ? `[${name} in ${section}] ${message}` : `[${name}] ${message}`;
    const exception = getException(details, message);
    const args = [
        title,
        exception
    ].filter((item)=>item !== undefined);
    return args;
}
export const createRollbarTransport = ()=>{
    const rollbar = createRollbarLogger();
    if (!rollbar) return undefined;
    return {
        name: 'rollbar',
        log (params) {
            const args = getArgs(params);
            rollbar.log(...args);
        },
        info (params) {
            const args = getArgs(params);
            rollbar.info(...args);
        },
        error (params) {
            const args = getArgs(params);
            rollbar.error(...args);
        },
        warn (params) {
            const args = getArgs(params);
            rollbar.warn(...args);
        }
    };
};
