import type { ContactDetailsEntity } from '~/modules/contacts/core'

const year = 0

export function getBirthdayString({
  birth: { month, day },
}: Pick<ContactDetailsEntity, 'birth'>): string {
  const monthName = month
    ? new Date(year, month - 1).toLocaleString('en-us', { month: 'long' })
    : '--'

  return `${day ?? '--'} ${monthName}`
}
