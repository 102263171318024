import { observer } from '@nori/di'

import { AutoSaveOrderRow } from '../../autosave-order-row/autosave-order-row'
import { AutoSaveOrderRowActions } from '../../autosave-order-row/autosave-order-row-actions'

import { useAllAutoSavetableRow } from './use-all-autosave-table-row'

import type { SubscriptionEntity } from '~/modules/subscription'

type Props = {
  isActiveAutosaveTable?: boolean
  isContactDetailsPage?: boolean
  subscription: SubscriptionEntity
  onChangeStatus: (skip?: boolean) => void
}

export const AllAutosaveTableRow = observer(
  ({
    isActiveAutosaveTable,
    isContactDetailsPage,
    subscription,
    onChangeStatus,
  }: Props) => {
    const {
      productInfo,
      itemQuantity,
      handleQuantityChange,
      handleFrequencyChange,
      itemDate,
      handleDateChange,
      cycleName,
      retailPrice,
      subscriptionPrice,
      price,
      handleSave,
      toggleEditMode,
      actionsSlotProps,
      isAvailable,
    } = useAllAutoSavetableRow({
      isActiveAutosaveTable,
      isContactDetailsPage,
      subscription,
      onChangeStatus,
    })

    return (
      <div className={'mt-6 w-full'}>
        <AutoSaveOrderRow
          subscription={subscription}
          productInfo={productInfo}
          itemQty={itemQuantity}
          onItemQtyChange={handleQuantityChange}
          onItemCycleIdChange={handleFrequencyChange}
          itemDate={itemDate}
          onDateChange={handleDateChange}
          cycleName={cycleName}
          retailPrice={retailPrice}
          isContactDetailsPage={isContactDetailsPage}
          subscriptionPrice={subscriptionPrice}
          price={price}
          handleSave={handleSave}
          isAvailable={isAvailable}
          isEditMode={toggleEditMode.isOpen}
          actionsSlot={<AutoSaveOrderRowActions {...actionsSlotProps} />}
        />
      </div>
    )
  }
)
