import type { ContactListColumnNamesValueObject } from '../core/contact-list-column-names.value-object'
import type { ContactSort } from '../core/contact-sort'

export const contactListTableCellsKeys: {
  translationKey: IntlMessageKeys
  sortBy?: ContactSort
  id: ContactListColumnNamesValueObject
  isDisabled?: boolean
}[] = [
  {
    translationKey: 'contacts.list.table.header.name',
    sortBy: 'first_name',
    id: 'name',
    isDisabled: true,
  },
  {
    translationKey: 'contacts.list.table.header.contactInfo',
    id: 'contact_info',
  },
  {
    translationKey: 'contacts.list.table.header.customerType',
    sortBy: 'customer_type',
    id: 'customer_type',
  },
  {
    id: 'cityState',
    translationKey: 'contacts.list.table.header.cityState',
  },
  {
    id: 'last_order_date',
    translationKey: 'contacts.list.table.header.lastOrder',
    sortBy: 'last_order_date',
  },
  {
    id: 'last_hosted_social_date',
    translationKey: 'contacts.list.table.header.lastHostedEvent',
    sortBy: 'last_hosted_social_date',
  },
  {
    translationKey: 'contacts.list.table.header.expiringRewards',
    sortBy: 'expiring_rewards',
    id: 'expiring_rewards',
  },
  {
    translationKey: 'contacts.list.table.header.norwexCredit',
    id: 'norwex_credits',
  },
  {
    translationKey: 'contacts.list.table.header.itemDiscount',
    id: 'item_discount',
  },
  {
    translationKey: 'contacts.list.table.header.freeShipping',
    id: 'has_free_shipping',
  },
  {
    translationKey: 'contacts.list.table.header.lifetimeSpend',
    sortBy: 'lifetime_spend_cents',
    id: 'lifetime_spend',
  },
  {
    translationKey: 'contacts.list.table.header.createdAt',
    id: 'created_at',
    sortBy: 'created_at',
  },
  {
    translationKey: 'contacts.list.table.header.preferredLanguage',
    id: 'preferred_language',
  },
]
