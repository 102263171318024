import { errorFactory } from '@nori/errors'

import type { KeyOfErrors } from '@nori/errors'

export const { CartContactPartyErrors } = errorFactory(
  'CartContactPartyErrors',
  {
    BadRequestAddToContact: 'Bad Request Add Contact To Order',
    NotFoundAddToContact: 'Not Found Add Contact To Order',
    UnexpectedErrorAddToContact: 'Unexpected Error Add Contact To Order',
    BadRequestAddToMailing: 'Bad Request Add To Mailing List',
    NotFoundAddToMailing: 'Not Found Add To Mailing List',
    UnexpectedErrorAddToMailing: 'Unexpected Error Add To Mailing List',
    UnknownErrorAddToContact: 'Unknown Error Add Contact To Order',
    UnknownErrorAddToMailing: 'Unknown Error Add To Mailing List',
  }
)

export type CartContactPartyErrorInstance = InstanceType<
  KeyOfErrors<typeof CartContactPartyErrors>
>
