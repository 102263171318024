import { useProfileContext } from '@nori/app-kit'
import { observer } from '@nori/di'
import { useTranslations } from '@nori/lang-i18n'
import { Button, Icon, Loader, Swap, Tooltip, useMount } from '@nori/ui-kit'
import { FirstAutosaveOrderTooltip } from '~/modules/autosave/ui/all-autosave/first-autosave-order-tooltip'
import { useContactContext } from '~/modules/contacts/interface/use-contact-context'

import { useAutosaveContext } from '../../interface/use-autosave-context'

import { AddProductModal } from './add-product/add-product.modal'
import { AllAutosaveTable } from './all-autosave-table'

type Props = {
  isContactDetailsPage?: boolean
}

export const AllAutosavePanel = observer(
  ({ isContactDetailsPage = false }: Props) => {
    const t = useTranslations('autosaveSettings')
    const {
      subscriptionsAction,
      subscriptionsStore,
      subscriptionsAddProductAction,
    } = useAutosaveContext()
    const { contactDetailsStore } = useContactContext()
    const { userStore } = useProfileContext()

    useMount(() => {
      subscriptionsAction.handleInitialLoad(
        isContactDetailsPage
          ? contactDetailsStore.contact?.customerId
          : userStore.currentUser?.id
      )
    })
    if (subscriptionsStore.subscriptions.isLoading) {
      return (
        <div className="mt-5">
          <Loader />
        </div>
      )
    }
    const isActive = subscriptionsStore.subsriptionStatusTab === 'active'
    const isCancelled = subscriptionsStore.subsriptionStatusTab === 'cancelled'
    return (
      <>
        <div className="mt-7 flex w-full lg:w-[370px]">
          <div className="w-full">
            <Button
              size="base"
              widthType="full"
              onClick={() => {
                subscriptionsStore.setSubsriptionStatusTab('active')
              }}
              isOutline={isCancelled}
            >
              {t('active')}
            </Button>
          </div>
          <div className="w-full">
            <Button
              size="base"
              widthType="full"
              onClick={() => {
                subscriptionsStore.setSubsriptionStatusTab('cancelled')
              }}
              isOutline={isActive}
            >
              {t('cancelled')}
            </Button>
          </div>
        </div>
        {isActive && (
          <div className="mt-7 flex flex-row-reverse flex-wrap text-left lg:flex-nowrap">
            {!isContactDetailsPage && (
              <div className="flex w-full max-lg:ml-0 md:ml-auto md:w-72">
                <Swap
                  is={!subscriptionsStore.subscriptions.data?.length}
                  isSlot={
                    <Tooltip
                      slot={<FirstAutosaveOrderTooltip />}
                      place={'bottom'}
                      widthType={'full'}
                    >
                      <Button
                        size="base"
                        widthType="full"
                        onClick={subscriptionsAddProductAction.handleOpen}
                        disabled
                      >
                        {t('addSubscription')}
                      </Button>
                    </Tooltip>
                  }
                >
                  <Button
                    size="base"
                    widthType="full"
                    onClick={subscriptionsAddProductAction.handleOpen}
                  >
                    {t('addSubscription')}
                  </Button>
                </Swap>
              </div>
            )}
            <span className="flex w-full gap-2 pt-7 text-sm font-medium text-primary-900 lg:pt-4">
              {t('getFreeShipping')}
            </span>
          </div>
        )}
        <div className="mt-4 lg:mt-12">
          <AllAutosaveTable isContactDetailsPage={isContactDetailsPage} />
        </div>
        <AddProductModal />
      </>
    )
  }
)
