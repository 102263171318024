import { hookContextFactory } from '@nori/di'

import { ContactAndPartyAction } from './actions/contact-and-party.action'
import { CartContactPartyStore } from './store/cart-contact-party.store'

export const { useModuleContext: useCartContactPartyContext } =
  hookContextFactory({
    cartContactPartyStore: CartContactPartyStore,
    contactAndPartyAction: ContactAndPartyAction,
  })
