import { NorwexLogo } from '@nori/app-kit'

import { LogoTitle } from './logo-title'
import { RightBlock } from './right-block'
import { SocialLinks } from './social-links'

export function MobileContent() {
  return (
    <div className="flex h-full flex-col items-center gap-4 px-6 py-10 pt-20">
      <div className="flex flex-1 flex-col items-center gap-6">
        <div className="flex flex-col items-center justify-center gap-4">
          <NorwexLogo size="lg" />
          <LogoTitle size="lg" />
        </div>

        <div className="flex flex-1 justify-center pb-10">
          <SocialLinks />
        </div>
      </div>

      <div>
        <RightBlock />
      </div>
    </div>
  )
}
