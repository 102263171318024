import { useMemo } from 'react'

import { observer } from '@nori/di'
import { useTranslations } from '@nori/lang-i18n'
import { useToggle } from '@nori/ui-kit'

import { useNotesContext } from '../interface/use-notes-context'

import { COLLAPSED_VIEW_NOTES_COUNT } from './note.constants'
import { NoteInput } from './note-input'
import { NoteListItem } from './note-list-item'

type Props = {
  contactId: number
}

export const NoteList = observer(({ contactId }: Props) => {
  const { notesStore, notesAction } = useNotesContext()
  const { isOpen: isShowMore, handleToggle: handleTriggerShowNotes } =
    useToggle()
  const t = useTranslations('contacts.notes')
  const notes = notesStore.notesMap[contactId]
  const notesAmount = notes?.length ?? 0
  const notesToShow = useMemo(() => {
    if (!notes) return []
    return isShowMore ? notes : notes.slice(-COLLAPSED_VIEW_NOTES_COUNT)
  }, [isShowMore, notes])

  const hiddenNotesCount = notesAmount - COLLAPSED_VIEW_NOTES_COUNT
  const showMoreTitle = isShowMore
    ? t('showLess')
    : t('showMore', { count: hiddenNotesCount })

  return (
    <div>
      <div className="flex justify-between">
        <div className="text-sm font-bold text-primary-900">{t('title')}</div>

        {hiddenNotesCount > 0 && (
          <div
            className="cursor-pointer text-sm text-primary-900 underline"
            onClick={handleTriggerShowNotes}
          >
            {showMoreTitle}
          </div>
        )}
      </div>

      <div>
        {notesToShow.map((note) => (
          <div key={note.id} className="mt-1.5">
            <NoteListItem contactId={contactId} note={note}>
              <NoteInput
                notesAmount={notesAmount}
                onSubmit={() => notesAction.handleUpdateNote(contactId)}
                contactId={contactId}
              />
            </NoteListItem>
          </div>
        ))}
      </div>

      {!notesStore.editedNoteMap[contactId] && (
        <div className="mt-1.5">
          <NoteInput
            notesAmount={notesAmount}
            onSubmit={() => notesAction.handleCreateNote(contactId)}
            contactId={contactId}
          />
        </div>
      )}
    </div>
  )
})
