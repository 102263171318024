import { CreditCardForm, usePaymentMethodsContext } from '@nori/app-kit'
import { observer } from '@nori/di'
import { useTranslations } from '@nori/lang-i18n'
import { Checkbox, Tabs } from '@nori/ui-kit'
import { useBillingFormContext } from '~/modules/billing-form/interface/use-billing-form.context'

import { SavedCreditCardsDropdown } from '../../saved-credit-cards-dropdown'

import { SecondBillingAddress } from './second-billing-address'

import type {
  CreditCardModeValueObject,
  CreditCardPriorityValueObject,
} from '~/modules/billing-form/core'

const DEFAULT_CREDIT_CARD_PRIORITY: CreditCardPriorityValueObject = 'second'

export const CreditCardModeTabs = observer(() => {
  const t = useTranslations('billingForm.creditCard')
  const { billingFormAction, billingFormStore } = useBillingFormContext()
  const { paymentMethodStore } = usePaymentMethodsContext()

  return (
    <Tabs<CreditCardModeValueObject>
      active={
        billingFormStore.creditCardModeMap[DEFAULT_CREDIT_CARD_PRIORITY] ||
        'saved'
      }
      onChange={billingFormAction.handleSetSecondCreditCardMode}
    >
      <div>
        <Tabs.Titles>
          <div className="flex gap-12">
            {!!paymentMethodStore.creditCards?.length && (
              <Tabs.Title name="saved">{t('yourSavedCard')}</Tabs.Title>
            )}
            <Tabs.Title name="new">{t('enterNewCard')}</Tabs.Title>
          </div>
        </Tabs.Titles>
      </div>

      <div className="mt-4">
        <Tabs.Panel name="saved">
          <SavedCreditCardsDropdown
            size="small"
            error={
              billingFormStore.selectedCardMap[DEFAULT_CREDIT_CARD_PRIORITY]
                .error?.message
            }
            isVisible={
              billingFormStore.creditCardModeMap[
                DEFAULT_CREDIT_CARD_PRIORITY
              ] === 'saved'
            }
            selectedCard={
              billingFormStore.selectedCardMap[DEFAULT_CREDIT_CARD_PRIORITY]
                .data
            }
            onSelectCreditCard={(value) =>
              billingFormAction.handleSelectCreditCard(
                DEFAULT_CREDIT_CARD_PRIORITY,
                value
              )
            }
          />
        </Tabs.Panel>

        <Tabs.Panel name="new" isStayInDOM>
          <CreditCardForm
            cardholderValidator={
              billingFormStore.cardholderValidatorMap[
                DEFAULT_CREDIT_CARD_PRIORITY
              ]
            }
            cardId={DEFAULT_CREDIT_CARD_PRIORITY}
            isVisible={
              billingFormStore.creditCardModeMap[
                DEFAULT_CREDIT_CARD_PRIORITY
              ] === 'new'
            }
            isLoading={
              billingFormStore.hostedFieldsMap[DEFAULT_CREDIT_CARD_PRIORITY]
                .isLoading
            }
            fieldsValidation={
              billingFormStore.hostedFieldsValidationMap[
                DEFAULT_CREDIT_CARD_PRIORITY
              ]
            }
          >
            {billingFormStore.canSaveToProfile && (
              <Checkbox
                value={
                  !!billingFormStore.saveToProfileMap[
                    DEFAULT_CREDIT_CARD_PRIORITY
                  ]
                }
                onChange={(value) =>
                  billingFormAction.handleSetSaveToProfile(
                    DEFAULT_CREDIT_CARD_PRIORITY,
                    value
                  )
                }
              >
                {t('saveCardToProfile')}
              </Checkbox>
            )}
          </CreditCardForm>

          <SecondBillingAddress />
        </Tabs.Panel>
      </div>
    </Tabs>
  )
})
