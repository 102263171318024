import { Action, Inject } from '@nori/di'
import { isErr } from '@nori/result'
import { createLogger } from '~/infra/create-logger'

import { AgreementAdapter } from '../../infra/agreement.adapter'
import { AgreementStore } from '../store/agreement.store'

const logger = createLogger('agreement.action')

@Action()
export class AgreementAction {
  constructor(
    @Inject() private readonly agreementStore: AgreementStore,
    @Inject() private readonly agreementAdapter: AgreementAdapter
  ) {}

  async handleLoadAgreementInformation(): Promise<void> {
    this.agreementStore.agreementVersion.setIsLoading(true)

    await this.loadAgreementVersion()

    this.agreementStore.agreementVersion.setIsLoading(false)
  }

  async handleAcceptAgreement(): Promise<void> {
    this.agreementStore.setIsLoadingAcceptance(true)

    await this.acceptAgreement()

    this.agreementStore.setIsLoadingAcceptance(false)
  }

  private async acceptAgreement(): Promise<void> {
    this.agreementStore.setAgreementAcceptanceError(undefined)
    const result = await this.agreementAdapter.acceptAgreement()

    if (isErr(result)) {
      logger.error(result.error.key, result.error)
      this.agreementStore.setAgreementAcceptanceError(result.error)
      return
    }

    this.agreementStore.agreementModalToggle.handleClose()
  }

  private async loadAgreementVersion(): Promise<void> {
    this.agreementStore.agreementVersion.setError(undefined)
    const result = await this.agreementAdapter.getAgreementVersion()

    if (isErr(result)) {
      logger.error(result.error.key, result.error)
      this.agreementStore.agreementVersion.setError(result.error)
      return
    }

    this.agreementStore.agreementVersion.setData(result.data)
  }
}
