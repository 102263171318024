import { errorFactory } from '@nori/errors'

import type { KeyOfErrors } from '@nori/errors'

export const { SkuErrors } = errorFactory('SkuErrors', {
  GetSkusUnknownError: 'Error while getting contact subscription skus',
  GetSkusUnexpectedError:
    'Unexpected error while getting contact subscription skus',
})

export type SkuErrorInstance = InstanceType<KeyOfErrors<typeof SkuErrors>>
