import { useMemo } from 'react'

import type { CustomerTypeValueObject } from '@nori/app-kit'
import type { UserCreditsEntity } from '../../core'

export function useHostConsultantCartCredits({
  customerType,
  credits,
}: {
  customerType?: CustomerTypeValueObject
  credits?: UserCreditsEntity
}) {
  const sanitizedCredits = useMemo(() => {
    if (!credits) return null
    switch (customerType) {
      case 'consultant':
        return {
          itemDiscount: 0,
          storeCredits: 0,
          combinedCredits: 0,
        }
      default:
        return {
          itemDiscount: credits.hostessDiscountsHash.count,
          storeCredits: credits.storeCredits,
          combinedCredits: credits.combinedCredits,
        }
    }
  }, [credits, customerType])

  return { sanitizedCredits }
}
