import { observer } from '@nori/di'
import { ContentBlock } from '@nori/ui-kit'
import { PageLayout } from '~/modules/page-layout'

import { ContactHeaderControls } from './contact-header-controls'
import { ContactPageHeader } from './contact-page-header'
import { ContactsTable } from './contacts-table'

export const ContactsPage = observer(() => {
  return (
    <PageLayout>
      <ContactPageHeader />
      <div className="mt-8 px-5 lg:mx-5 lg:mt-7 lg:px-0">
        <ContentBlock>
          <ContactHeaderControls />
        </ContentBlock>
      </div>

      <ContentBlock>
        <ContactsTable />
      </ContentBlock>
    </PageLayout>
  )
})
