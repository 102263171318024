import { getBaseUrl, RequestService, SERVICE_NAMES } from '@nori/app-kit'
import { Adapter, Inject } from '@nori/di'
import { resultErr, resultOk } from '@nori/result'

import { UserCreditsErrors } from '../core'

import { UserCreditsMapper } from './mappers/user-credits.mapper'
import { UserCreditsErrorMapper } from './mappers/user-credits-error.mapper'

import type { PromiseResult } from '@nori/result'
import type { UserCreditsEntity, UserCreditsErrorInstance } from '../core'

@Adapter()
export class UserCreditsAdapter {
  baseUrl = getBaseUrl(SERVICE_NAMES.USER_SERVICE)

  constructor(@Inject() private readonly requestService: RequestService) {}

  public async getCredits(
    userId: number
  ): PromiseResult<UserCreditsEntity, UserCreditsErrorInstance> {
    const url = `${this.baseUrl}/users/${userId}/credits`

    try {
      const { ok, json, status } = await this.requestService.secured.get(url)

      if (ok) return resultOk(UserCreditsMapper.toUserCreditsEntity(json))

      return resultErr(UserCreditsErrorMapper.toErrors(status, json))
    } catch (err: unknown) {
      return resultErr(new UserCreditsErrors.UnexpectedError(err))
    }
  }
}
