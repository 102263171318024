import { useProfileContext } from '@nori/app-kit'
import { observer } from '@nori/di'
import { useTranslations } from '@nori/lang-i18n'
import { Icon, useToggle } from '@nori/ui-kit'
import { useAutosaveContext } from '~/modules/autosave/interface/use-autosave-context'
import { useContactContext } from '~/modules/contacts/interface/use-contact-context'
import { IntlDatePickerTZ } from '~/modules/i18n/ui/intl-date-picker-tz'

import { NextOrderDateTooltip } from './next-order-date-tooltip'

type Props = {
  isContactDetailsPage?: boolean
  isListView?: boolean
  itemId?: number
}

export const NextOrderDate = observer(
  ({ isContactDetailsPage, isListView, itemId }: Props) => {
    const t = useTranslations('autosaveSettings')
    const { nextOrderStore, nextOrderAction } = useAutosaveContext()
    const { contactDetailsStore } = useContactContext()
    const { userStore } = useProfileContext()

    const nextCartAt =
      isListView && itemId
        ? nextOrderStore.getNextOrderPopupDate(itemId)
        : nextOrderStore.subscription?.nextCartAt

    if (!nextCartAt) return null

    const userId = isContactDetailsPage
      ? contactDetailsStore.contact?.customerId
      : userStore.currentUser?.id

    const {
      isOpen: isCalendarOpen,
      handleClose: handleCalendarClose,
      handleToggle: handleCalendarToggle,
    } = useToggle()

    const buttonTextWeight = isListView ? 'font-normal' : 'font-bold'
    const buttonText = isListView ? t('edit') : t('changeDate')
    const buttonWidth = isListView ? 'w-48' : ''

    const marginRight = isListView ? 'mr-2' : 'mr-8'
    return (
      <div className="flex items-center text-primary-900">
        <div className={`${marginRight}`}>
          {!isListView && (
            <span className="text-sm font-bold">{t('orderDate')}: </span>
          )}
          <span className="text-sm font-bold text-gray-700">
            {nextCartAt.formatInTZ('MMMM do yyyy')}
          </span>
        </div>
        <IntlDatePickerTZ
          value={nextCartAt}
          isOpen={isCalendarOpen}
          onClose={handleCalendarClose}
          minDate={nextOrderStore.autoSaveMinDate}
          maxDate={nextOrderStore.autoSaveMaxDate}
          withClear={false}
          withToday={false}
          onChange={(dates) => {
            if (isListView && dates[0] && itemId) {
              nextOrderStore.setNextOrderPopupDate(dates[0], itemId)
            } else {
              nextOrderAction.handleNextOrderDateChange({ dates, userId })
            }
          }}
        >
          <button
            className={`min-w-64 flex text-sm ${buttonTextWeight} ${buttonWidth}`}
            onClick={handleCalendarToggle}
          >
            {isListView && <Icon name="PencilAlt" />}
            <span className="mr-1 cursor-pointer underline">{buttonText}</span>
            {!isListView && (
              <span className="text-gray-400">
                <NextOrderDateTooltip />
              </span>
            )}
          </button>
        </IntlDatePickerTZ>
      </div>
    )
  }
)
