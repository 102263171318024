import { createAddressValidator } from '@nori/app-kit'
import { Store, Toggle } from '@nori/di'
import { getBlock } from '@nori/lang-i18n'

import {
  createContactValidator,
  createContactWithoutEmailValidator,
} from '../create-contact-validator'

import type {
  ContactDetailsEntity,
  ContactErrorInstance,
} from '~/modules/contacts/core'
import type { ContactToEditEntity } from '../../core/contact-to-edit.entity'
import type {
  ContactValidatorSchemeType,
  ContactWithoutEmailValidatorSchemeType,
} from '../create-contact-validator'

@Store()
export class FindOrCreateContactStore {
  createdContact?: ContactDetailsEntity = undefined
  contactToEdit?: ContactToEditEntity = undefined
  isLoading = false
  isEditContact = false
  error?: ContactErrorInstance = undefined

  contactWithoutEmailToggle = new Toggle(false)

  contactValidator:
    | ContactValidatorSchemeType
    | ContactWithoutEmailValidatorSchemeType = createContactValidator()
  addressValidator = createAddressValidator()
  get validationErrors(): { field: string; error: string }[] {
    return [this.addressValidator.errors].flatMap((error) =>
      Object.entries(error).map((error) => ({
        field: error[0],
        error: error[1],
      }))
    )
  }

  get isEmailInputDisabled(): boolean {
    if (this.isEditContact) {
      return true
    }
    if (this.isLoading) {
      return true
    }
    if (this.contactWithoutEmailToggle.isOpen) {
      return true
    }
    return false
  }

  get emailValue(): string {
    const validatorValues = this.contactValidator.values
    if (this.isEditContact) {
      return this.contactToEdit?.isEmailProvided === false
        ? ''
        : validatorValues.email
    }
    if (this.contactWithoutEmailToggle.isOpen) {
      return ''
    }
    return validatorValues.email
  }

  get emailHelperText(): string | undefined {
    if (this.contactToEdit?.isEmailProvided === false) {
      return getBlock('contact')('autogeneratedEmail')
    }
  }

  updateContactValidator(): void {
    const currentContactValues = this.contactValidator.values
    if (this.contactWithoutEmailToggle.isOpen) {
      this.contactValidator =
        createContactWithoutEmailValidator(currentContactValues)
      return
    }

    this.contactValidator = createContactValidator(currentContactValues)
  }

  setIsLoading(value: boolean): void {
    this.isLoading = value
  }

  setError(errorInstance?: ContactErrorInstance): void {
    this.error = errorInstance
  }

  setCreatedContact(value?: ContactDetailsEntity): void {
    this.createdContact = value
  }

  setIsEditContact(value: boolean): void {
    this.isEditContact = value
  }

  setContactToEdit(value?: ContactToEditEntity): void {
    this.contactToEdit = value
    if (!value) return

    this.fillValidator(value)
  }

  private fillValidator(value?: ContactToEditEntity): void {
    this.contactValidator.updateInitialFields({
      firstName: value?.firstName || '',
      email: value?.email || '',
      lastName: value?.lastName || '',
      phone: value?.phone || '',
    })

    this.addressValidator.updateInitialFields({
      address1: value?.currentAddress.address1 || '',
      city: value?.currentAddress.city || '',
      state: value?.currentAddress.state || '',
      zipCode: value?.currentAddress.zipCode || '',
      country: value?.currentAddress.country || '',
      address2: value?.currentAddress.address2 || '',
      phone: value?.phone || '',
    })
  }

  resetContactValidator(): void {
    this.fillValidator()
  }

  resetAddressValidator(): void {
    this.addressValidator.reset()
  }
}
