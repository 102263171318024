import { errorFactory } from '@nori/errors'

import type { KeyOfErrors } from '@nori/errors'

export const { WishListErrors } = errorFactory('WishListErrors', {
  BadRequest: 'Bad Request',
  NotFound: 'Not Found',
  UnprocessedEntity: 'UnprocessedEntity',
  UnexpectedError: 'Unexpected Error',
  UnknownError: 'Unknown Error',
  DeleteForbiddenError: 'Delete Forbidden Error',
})

export type WishListErrorInstance = InstanceType<
  KeyOfErrors<typeof WishListErrors>
>
