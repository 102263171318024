import type { AddContactToCartInput } from '../types/add-contact-to-cart.input'
import type { AddToMailingListInput } from '../types/add-to-mailing-list.input'

export class CartContactMapper {
  static toAddContact({ contactId }: AddContactToCartInput) {
    return { contact_id: contactId ?? null }
  }

  static toAddToMailingList({ isAddToMailingList }: AddToMailingListInput) {
    return {
      add_to_mailing_list: isAddToMailingList,
    }
  }
}
