import { ExportCalendarService, getLink } from '@nori/app-kit'
import { Action, Inject } from '@nori/di'
import { isErr } from '@nori/result'
import { createLogger } from '~/infra/create-logger'

import { PartiesListAdapter } from '../../infra/parties-list.adapter'
import { PartiesCalendarStore } from '../stores/parties-calendar.store'
import { PartiesListStore } from '../stores/parties-list.store'

import type { CalendarEventValueObject } from '@nori/app-kit'
import type { NoriDate } from '@nori/date'
import type { PartyEntity } from '../../core/entities/party.entity'
import type { CalendarListValueObject } from '../../core/value-objects/calendar-list.value-object'

const logger = createLogger('parties-calendar.action')

const PARTY_DURATION_IN_HRS = 1

@Action()
export class PartiesCalendarAction {
  constructor(
    @Inject() private readonly partiesListAdapter: PartiesListAdapter,
    @Inject() private readonly partiesListStore: PartiesListStore,
    @Inject() private readonly partiesCalendarStore: PartiesCalendarStore,
    @Inject() private readonly exportCalendarService: ExportCalendarService
  ) {}

  handleSelectDay(date: NoriDate): void {
    if (date.getTime() === this.partiesCalendarStore.selectedDate?.getTime()) {
      this.partiesCalendarStore.setSelectedDate(undefined)
      return
    }

    this.partiesCalendarStore.setSelectedDate(date)
  }

  handleSetViewDate(date: NoriDate): void {
    this.partiesCalendarStore.setViewDate(date)
  }

  async handleLoadByCalendar(args: {
    from: NoriDate
    to: NoriDate
  }): Promise<void> {
    this.partiesCalendarStore.calendarList.setIsLoading(true)

    await this.fetchListByCalendar(args)

    this.partiesCalendarStore.calendarList.setIsLoading(false)
  }

  handleExportParty(party: PartyEntity): void {
    this.exportCalendarService.downloadEvent(this.formatParty(party))
  }

  handleExportList(): void {
    if (!this.partiesCalendarStore.calendarList.data) {
      return
    }

    const calendarParties = Object.values(
      this.partiesCalendarStore.calendarList.data
    )
      .flatMap((parties) => parties)
      .map(this.formatParty)

    this.exportCalendarService.downloadCalendar({ events: calendarParties })
  }

  private getPartyLink(party: PartyEntity) {
    const status = this.partiesListStore.getPartyStatus(party)
    if (status !== 'open') return

    return getLink({
      type: 'bo',
      personalLink: party.partyPath,
      isMask: true,
    })
  }

  private formatParty(party: PartyEntity): CalendarEventValueObject {
    const urlLink = this.getPartyLink(party)?.url

    return {
      title: party.partyName,
      start: party.partyAt,
      duration: { hours: PARTY_DURATION_IN_HRS },
      organizer: {
        firstName: party.hostess?.firstName,
        lastName: party.hostess?.lastName,
        email:
          party.hostess.isEmailProvided !== false
            ? party.hostess?.email
            : undefined,
      },
      ...(urlLink ? { url: urlLink } : {}),
    }
  }

  private async fetchListByCalendar({
    from,
    to,
  }: {
    from: NoriDate
    to: NoriDate
  }): Promise<void> {
    this.partiesCalendarStore.calendarList.setError(undefined)

    const result = await this.partiesListAdapter.getPartyList({
      to: to.backendTimestamp,
      from: from.backendTimestamp,
      partyType: 'all',
    })

    if (isErr(result)) {
      logger.error(result.error.key, result.error)
      this.partiesCalendarStore.calendarList.setError(result.error)
      return
    }

    const calendarList = result.data.parties.reduce(
      (acc: CalendarListValueObject, item) => {
        const key = item.partyAt.formatInTZ('yyyy-MM-dd')
        const value = acc[key] || []
        value.push(item)

        return {
          ...acc,
          [key]: value,
        }
      },
      {}
    )

    this.partiesCalendarStore.calendarList.setData(calendarList)
  }
}
