import { Inject, Service } from '@nori/di'
import { isErr, resultErr, resultOk } from '@nori/result'
import { FailedValidationError } from '@nori/validator'
import { createLogger } from '~/infra/create-logger'
import { ContactErrors } from '~/modules/contacts/core'
import { ContactAdapter } from '~/modules/contacts/infra/contact.adapter'
import { FindOrCreateContactStore } from '~/modules/contacts/interface/store/find-or-create-contact.store'

import { CreateContactAction } from '../action/create-contact.action'
import { CreateContactStore } from '../store/create-contact.store'

import { EnsureContactService } from './ensure-contact.service'

import type { PromiseResult } from '@nori/result'
import type {
  ContactDetailsEntity,
  ContactErrorInstance,
} from '~/modules/contacts/core'
import type { ContactToEditEntity } from '../../core/contact-to-edit.entity'
import type { FailedValidationValueObject } from '../../core/failed-validation.value-object'

const logger = createLogger('find-or-create-contact.service')

@Service()
export class FindOrCreateContactService {
  constructor(
    @Inject() private readonly contactAdapter: ContactAdapter,
    @Inject() private readonly ensureContactService: EnsureContactService,
    @Inject()
    private readonly findOrCreateContactStore: FindOrCreateContactStore,
    @Inject() private readonly createContactStore: CreateContactStore,
    @Inject() private readonly createContactAction: CreateContactAction
  ) {}

  getCreatedContact(): ContactDetailsEntity | undefined {
    return this.findOrCreateContactStore.createdContact
  }

  setContactToEdit(contact?: ContactToEditEntity): void {
    this.findOrCreateContactStore.setContactToEdit(contact)
  }

  finishEdit(): void {
    this.findOrCreateContactStore.setContactToEdit(undefined)
    this.findOrCreateContactStore.setIsEditContact(false)
  }

  async submitContact(): PromiseResult<
    | { data: ContactDetailsEntity; type: 'response' }
    | {
        data: FailedValidationValueObject
        type: 'validationFailed'
      },
    ContactErrorInstance
  > {
    this.findOrCreateContactStore.setError(undefined)
    const validator = this.findOrCreateContactStore.contactValidator

    const { isValid } = validator.submit()
    if (!isValid) return resultErr(new ContactErrors.ValidationError())

    this.findOrCreateContactStore.setIsLoading(true)

    const result = this.findOrCreateContactStore.isEditContact
      ? await this.updateContact()
      : await this.createContact()

    this.findOrCreateContactStore.setIsLoading(false)

    return result
  }

  async createContact(): PromiseResult<
    | { data: ContactDetailsEntity; type: 'response' }
    | {
        data: FailedValidationValueObject
        type: 'validationFailed'
      },
    ContactErrorInstance
  > {
    const validator = this.findOrCreateContactStore.contactValidator

    const { values } = validator

    const isEmailProvided =
      !this.findOrCreateContactStore.contactWithoutEmailToggle.isOpen

    const result = await this.contactAdapter.createContactWithMinimumParams({
      firstName: values.firstName,
      lastName: values.lastName,
      email: values.email,
      isEmailProvided,
    })

    if (isErr(result)) {
      logger.error(result.error.key, result.error)
      this.findOrCreateContactStore.setError(result.error)
      return result
    }

    if (result.data.type === 'validationFailed') {
      FailedValidationError.handleValidatorErrors({
        errors: result.data.data,
        validator,
      })
      return result
    }

    const ensuredContactResult = await this.ensureContactService.ensureContact(
      result.data.data.id
    )

    if (isErr(ensuredContactResult)) {
      return ensuredContactResult
    }

    const contactDetails = {
      ...result.data.data,
      customerId: ensuredContactResult.data.customerId,
    }

    this.findOrCreateContactStore.setCreatedContact(contactDetails)
    this.findOrCreateContactStore.resetContactValidator()

    return resultOk({ data: contactDetails, type: 'response' })
  }

  async updateContact(): PromiseResult<
    | { data: ContactDetailsEntity; type: 'response' }
    | {
        data: FailedValidationValueObject
        type: 'validationFailed'
      },
    ContactErrorInstance
  > {
    const validator = this.findOrCreateContactStore.contactValidator
    const addressValidator = this.findOrCreateContactStore.addressValidator
    const { values } = validator
    const { values: addressValues } = addressValidator

    const result = await this.contactAdapter.editContact({
      ...this.findOrCreateContactStore?.contactToEdit,
      addressAttributes: {
        ...addressValues,
      },
      firstName: values.firstName,
      lastName: values.lastName,
      email: values.email,
      phone: values.phone,
    })

    if (isErr(result)) {
      logger.error(result.error.key, result.error)
      this.findOrCreateContactStore.setError(result.error)
      return result
    }

    if (result.data.type === 'validationFailed') {
      FailedValidationError.handleValidatorErrors({
        errors: result.data.data,
        validator,
      })
      FailedValidationError.handleValidatorErrors({
        errors: result.data.data,
        validator: addressValidator,
      })
      return result
    }

    this.finishEdit()
    this.findOrCreateContactStore.resetContactValidator()
    this.findOrCreateContactStore.setCreatedContact(result.data.data)

    return result
  }
}
