import { AuthEndpointService } from '@nori/app-kit'
import { Service } from '@nori/di'

import type { AuthEndpointServiceInterface } from '@nori/app-kit'

@Service({ id: AuthEndpointService })
class RewriteAuthEndpointService implements AuthEndpointServiceInterface {
  getLoginEndpoint(): string {
    return '/login.json'
  }
}
