import {
  HistoryService,
  I18nAction,
  LoginStore,
  ProfileService,
  UserStore,
} from '@nori/app-kit'
import { Inject, Service } from '@nori/di'
import { FeatureFlagsService as ReportsFeatureFlagsService } from '@nori/ibo-dashboards'
import { AgreementAction } from '~/modules/agreement/interface/actions/agreement.action'
import { CartAction } from '~/modules/cart/interface/actions/cart.action'
import { FeatureFlagsService } from '~/modules/feature-flags'
import { Routes } from '~/modules/routes'

import { RootAppAdapter } from '../../infra/root-app.adapter'
import { AuthGuardAction } from '../actions/auth-guard.action'
import { RootAppStore } from '../stores/root-app.store'

@Service()
export class RootAppService {
  constructor(
    @Inject() private readonly historyService: HistoryService,
    @Inject() private readonly rootAppStore: RootAppStore,
    @Inject() private readonly cartAction: CartAction,
    @Inject() private readonly profileService: ProfileService,
    @Inject() private readonly userStore: UserStore,
    @Inject() private readonly loginStore: LoginStore,
    @Inject() private readonly agreementAction: AgreementAction,
    @Inject() private readonly featureFlagsService: FeatureFlagsService,
    @Inject()
    private readonly reportsFeatureFlagsService: ReportsFeatureFlagsService,
    @Inject() private readonly rootAppAdapter: RootAppAdapter,
    @Inject() private readonly authGuardAction: AuthGuardAction,
    @Inject() private readonly i18nAction: I18nAction
  ) {}

  async getRuntimeEnvironment() {
    return this.rootAppAdapter.getRuntimeEnvironment()
  }

  async loadInitialContext(): Promise<void> {
    if (!this.userStore.isUserIdExist) {
      await this.historyService.push(Routes.auth.signIn.path())
      return
    }

    this.rootAppStore.setIsLoadingDetails(true)
    await this.loadUserData()
    await this.authGuardAction.handleAuthRedirect(this.historyService.asPath)
    this.rootAppStore.setIsLoadingDetails(false)
  }

  async loadLoginContext(): Promise<void> {
    if (this.rootAppStore.isDataLoaded) return
    this.rootAppStore.setIsLoadingDetails(false)
    this.loginStore.setIsLoading(true)

    await this.loadUserData()

    this.loginStore.setIsLoading(false)
  }

  private async loadUserData(): Promise<void> {
    const [runtimeEnvironmentResponse] = await Promise.all([
      this.getRuntimeEnvironment(),
      await this.profileService.handleLoadUser(),
    ])

    const { runtimeEnvironment } = runtimeEnvironmentResponse.data
    this.i18nAction.handleUpdateLocale()

    await Promise.all([
      this.agreementAction.handleLoadAgreementInformation(),
      this.cartAction.handleInitLoad(),
      this.featureFlagsService.handleInitFeatureFlags(
        runtimeEnvironment,
        this.userStore.currentUser
      ),
      this.reportsFeatureFlagsService.handleInitFeatureFlags(
        runtimeEnvironment,
        this.userStore.currentUser
      ),
    ])

    this.rootAppStore.setIsDataLoaded(true)
  }
}
