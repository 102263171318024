import { CartErrors } from '~/modules/cart/core'

import type { CartErrorInstance } from '~/modules/cart/core'
import type { CartErrorWithMessageValueObject } from '../../core/cart-error-with-message.value-object'

export class CartErrorMapper {
  static toErrors(status: number, json: any): CartErrorInstance {
    switch (status) {
      case 400:
        return new CartErrors.BadRequest(json.error.message)

      case 404:
        return new CartErrors.NotFound(json.errors)

      case 422:
        return new CartErrors.UnproceccedEntity(json.errors)

      default:
        return new CartErrors.UnknownError(json)
    }
  }

  static toApplyHostessDiscountError(
    status: number,
    json: any
  ): CartErrorWithMessageValueObject {
    switch (status) {
      case 400:
        return {
          error: new CartErrors.BadRequestApplyHostessDiscount(
            json.error.message
          ),
        }

      case 404:
        return {
          error: new CartErrors.NotFoundApplyHostessDiscount(json.errors),
        }

      case 422:
        return {
          error: new CartErrors.UnprocessableEntityApplyHostessDiscount(
            json.errors
          ),
          message: json.errors?.hostess_discount?.[0],
        }

      default:
        return {
          error: new CartErrors.UnexpectedErrorApplyHostessDiscount(json),
        }
    }
  }

  static toApplyStoreCreditsErrors(
    status: number,
    json: any
  ): CartErrorInstance {
    switch (status) {
      case 400:
        return new CartErrors.BadRequestApplyStoreCredits(json.error.message)

      case 404:
        return new CartErrors.NotFoundApplyStoreCredits(json.errors)
      case 422:
        if (
          (
            json.errors && (json.errors.store_credits_total as string[])
          ).includes('Tax is not calculated for cart') ||
          (json.fields && (json.fields as string[])).includes('tax_calculated')
        ) {
          return new CartErrors.TaxIsNotCalculatedError(json.errors)
        }
        return new CartErrors.UnknownErrorApplyStoreCredits(json)
      default:
        return new CartErrors.UnknownErrorApplyStoreCredits(json)
    }
  }

  static toApplyProductCreditsErrors(
    status: number,
    json: any
  ): CartErrorInstance {
    switch (status) {
      case 400:
        return new CartErrors.BadRequestApplyProductCredits(json.error.message)

      case 422:
        return new CartErrors.UnprocessableEntityApplyProductCredits(
          json.errors
        )

      case 404:
        return new CartErrors.NotFoundApplyProductCredits(json.errors)

      default:
        return new CartErrors.UnknownErrorApplyProductCredits(json)
    }
  }

  static toApplyHostessCreditsErrors(
    status: number,
    json: any
  ): CartErrorInstance {
    switch (status) {
      case 400:
        return new CartErrors.BadRequestApplyHostessCredits(json.error.message)

      case 404:
        return new CartErrors.NotFoundApplyHostessCredits(json.errors)

      default:
        return new CartErrors.UnknownErrorApplyHostessCredits(json)
    }
  }

  static toChangeCartShippingAddressErrors(
    status: number,
    json: any
  ): CartErrorInstance {
    switch (status) {
      case 400:
        return new CartErrors.BadRequestChangeCartShippingAddress(
          json.error.message
        )

      case 422:
        return new CartErrors.IncorrectShippingAddress(json.errors)
      case 404:
        return new CartErrors.NotFoundChangeCartShippingAddress(json.errors)

      default:
        return new CartErrors.UnknownErrorChangeCartShippingAddress(json)
    }
  }
}
