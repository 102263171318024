import { useEffect } from 'react'
import { useRouter } from 'next/router'

import { StickyBottomButton } from '@nori/app-kit'
import { observer } from '@nori/di'
import { useTranslations } from '@nori/lang-i18n'
import {
  Button,
  ContentBlock,
  HelperTextBlock,
  Icon,
  Loader,
  PageContainer,
  Swap,
  useToggle,
} from '@nori/ui-kit'
import { useApplyCreditsContext } from '~/modules/apply-credits/interface/use-apply-credits-context'
import { ApplyCredits } from '~/modules/apply-credits/ui/apply-credits'
import { useCartContext } from '~/modules/cart/interface/context/use-cart-context'
import { useGtmContext } from '~/modules/google-tag-manager/interface/use-gtm-context'
import { useProductSubscriptionContext } from '~/modules/product-subscription/interface/product-subscription.context'
import { Routes } from '~/modules/routes'

import { CartSummary } from './cart-summary'
import { ProductsList } from './products-list'

export const Cart = observer(() => {
  const router = useRouter()
  const { cartAction, cartStore } = useCartContext()
  const { applyCreditsStore, applyCreditsAction } = useApplyCreditsContext()
  const t = useTranslations('cart')
  const { cartData, isCartExists, cart, errorTitle } = cartStore
  const { productSubscriptionStore } = useProductSubscriptionContext()
  const { gtmAction } = useGtmContext()
  const {
    isOpen: isApplyCreditConfirmationModalOpen,
    handleClose: handleCloseApplyCreditConfirmationModal,
    handleOpen: handleOpenApplyCreditConfirmationModal,
  } = useToggle()
  const isCheckoutDisabled =
    !cart?.items.length ||
    (cartStore.isCartHaveAutoshipItem && cartStore.isCustomerCart) ||
    productSubscriptionStore.getProductSubscriptionLoading()

  useEffect(() => {
    cartAction.handleInitLoad()
  }, [cartAction])

  useEffect(() => {
    if (cart?.items.length) {
      gtmAction.handleTrackViewCart(cart)
    }
  }, [cart, gtmAction])

  if (cartData.isLoading) return <Loader />
  if (!isCartExists) return <div>{t('errors.cartNotExist')}</div>

  const handleCheckout = () => {
    if (applyCreditsStore.isShowConfirmationModal) {
      handleOpenApplyCreditConfirmationModal()
      return
    }

    cartAction.handleNavigateToCheckout()
  }

  return (
    <Swap
      is={!cart?.items.length}
      isSlot={
        <div className="flex flex-col items-center">
          <div className="text-gray-400 max-lg:hidden">
            <Icon name="ShoppingCart" size="3xl" />
          </div>
          <div className="text-gray-400 lg:hidden">
            <Icon name="ShoppingCart" size="xl" />
          </div>
          <p className="mb-5 mt-3 text-lg font-normal text-gray-500 lg:mb-8 lg:mt-2 lg:text-2xl">
            {t('emptyCart')}
          </p>
          <Button size="xl" onClick={() => router.push(Routes.shop.path())}>
            {t('keepShopping')}
          </Button>
        </div>
      }
    >
      <PageContainer>
        <ContentBlock>
          {errorTitle && (
            <HelperTextBlock type="error">{errorTitle}</HelperTextBlock>
          )}

          <div className="mt-2 flex flex-col justify-between lg:flex-row">
            <div className="mr-0 flex flex-col lg:mr-10 lg:w-2/3">
              <ProductsList />
            </div>
            <div className="flex flex-col lg:max-w-[368px]">
              <ApplyCredits
                titleKey={'yourNorwexCredit'}
                applicableCredits={applyCreditsStore.applicableCartCredits}
                appliedCredits={applyCreditsStore.appliedCredits}
                creditAmount={applyCreditsStore.creditAmount}
                creditInputValue={applyCreditsStore.creditInputValue}
                error={applyCreditsStore.creditError}
                isLoading={applyCreditsStore.isLoading}
                isShowApplyCredits={applyCreditsStore.isShowApplyCredits}
                onApply={applyCreditsAction.handleApplyCredit}
                onChange={applyCreditsAction.handleChangeValue}
                onRemove={applyCreditsAction.handleRemoveCredits}
              />
              <CartSummary
                isApplyCreditConfirmationModalOpen={
                  isApplyCreditConfirmationModalOpen
                }
                onCloseApplyCreditConfirmationModal={
                  handleCloseApplyCreditConfirmationModal
                }
                onCheckout={handleCheckout}
              />
            </div>
          </div>
        </ContentBlock>
      </PageContainer>
      <StickyBottomButton
        cart={cart}
        totalItemsCount={cartStore.totalItemsCount}
        buttonSlot={
          <div className="min-w-36">
            <Button
              onClick={handleCheckout}
              widthType="full"
              disabled={isCheckoutDisabled}
            >
              {t('checkout')}
            </Button>
          </div>
        }
      />
    </Swap>
  )
})
