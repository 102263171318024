import { type BundleEntityComponentProduct, ProductImage } from '@nori/app-kit'
import { observer } from '@nori/di'
import { Icon } from '@nori/ui-kit'

import { useBundleContext } from '../interface/use-bundle.context'

type Props = {
  product: BundleEntityComponentProduct
}

export const ProductItem = observer(({ product }: Props) => {
  const { customizeBundleAction, customizeBundleStore } = useBundleContext()

  const { currentStep, selectedSku, isLastStep } = customizeBundleStore

  const picture = product.style.pictures[0]?.iphoneLarge
  const productId = product.skus[0]?.id
  const isSelected = isLastStep ? false : selectedSku[currentStep] === productId
  const itemBorderColorStyle = isSelected
    ? ' border-primary-900'
    : 'border-transparent'

  // @TODO: check for productId to exist
  return (
    <div
      onClick={() => customizeBundleAction.handleSelectSku(productId!)}
      className="relative flex flex-col px-4 py-2"
    >
      <div className={`rounded-sm border-2 ${itemBorderColorStyle}`}>
        <ProductImage
          src={picture}
          alt="Product image not found!"
          width={335}
          height={335}
          styleName="h-full"
        />
        {isSelected && (
          <div className="absolute right-6 top-4 text-primary-900">
            <Icon name="CheckCircle" size="lg" />
          </div>
        )}
      </div>
      <div className="mt-4 text-base font-bold text-gray-700">
        {product.style.name}
      </div>
    </div>
  )
})
