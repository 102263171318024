import { Service } from '@nori/di'

import type { CartBundleEntity, CartItemEntity } from '@nori/app-kit'
import type { SwitchProductRequest } from '../types/switch-product.request'

@Service()
export class ProductCartRequestMapper {
  static removeRequest(product: CartItemEntity): URLSearchParams {
    const query = new URLSearchParams()

    query.append('sku_id', String(product.skuId))
    query.append('quantity', String(product.quantity))
    if (product.autoShip.id)
      query.append('subscription_plan_template_id', String(product.autoShip.id))

    return query
  }

  static removeBundleRequest(bundle: CartBundleEntity): URLSearchParams {
    const query = new URLSearchParams()

    query.append('bundle_id', String(bundle.id))

    return query
  }

  static getBundleOptionsRequest(): string {
    const query = new URLSearchParams()

    query.append('purchasable_override', 'false')
    query.append('_', new Date().getTime().toString())

    return query.toString()
  }

  static switchRequest(
    product: CartItemEntity,
    quantity: number
  ): SwitchProductRequest {
    return {
      new_sku_id: product.newSkuId || product.skuId,
      sku_id: product.skuId,
      quantity: quantity,
      subscription_plan_template_id: product.autoShip?.id
        ? product.autoShip.id
        : undefined,
    }
  }
}
