import { ItemPrice } from '@nori/app-kit'

import { ProductItemTotalPrice } from './product-item-total-price'

import type { ItemPriceProps } from './product-item-price-types'

export function CartItemPrice({
  retailPrice,
  salePrice,
  price,
  totalRetailPrice,
  totalSalePrice,
  promotionalDiscounts,
  combinedCredits,
  dsrDiscount,
  hostessDiscountPercentage,
  hostessDiscountValue,
  productCredits,
  totalPrice,
  currency,
  totalDiscounts,
}: ItemPriceProps) {
  return (
    <>
      <div className="flex w-1/6 flex-col text-gray-700">
        <div className="flex flex-col text-sm font-medium lg:text-right">
          <div>
            <ItemPrice
              isCartPrice
              price={price}
              retailPrice={retailPrice}
              salePrice={salePrice}
              data-test-id="item"
            />
          </div>
        </div>
      </div>
      <div className="flex w-2/6 flex-col text-right">
        <ProductItemTotalPrice
          totalRetailPrice={totalRetailPrice}
          totalSalePrice={totalSalePrice}
          promotionalDiscounts={promotionalDiscounts}
          totalDiscounts={totalDiscounts}
          hostessDiscountValue={hostessDiscountValue}
          hostessDiscountPercentage={hostessDiscountPercentage}
          productCredits={productCredits}
          combinedCredits={combinedCredits}
          dsrDiscount={dsrDiscount}
          totalPrice={totalPrice}
          currency={currency}
        />
      </div>
    </>
  )
}
