import { observer } from '@nori/di'
import { useTranslations } from '@nori/lang-i18n'
import { Icon, Link } from '@nori/ui-kit'
import { Routes } from '~/modules/routes'

import { useAutosaveContext } from '../../interface/use-autosave-context'

export const NextOrderFailedPaymentMessage = observer(() => {
  const t = useTranslations('autosaveSettings')
  const { nextOrderStore } = useAutosaveContext()

  if (!nextOrderStore.failedPaymentRetryDate) return null

  return (
    <div
      className={
        'mb-6 flex items-start justify-center gap-4 rounded-sm bg-primary-50 p-5'
      }
    >
      <div className="mt-1 flex items-start text-red-600">
        <Icon name="InformationCircle" size="md" />
      </div>
      <div>
        <div className="mb-2 text-primary-900">
          <span className="text-base font-bold">{t('paymentFailed')}</span>{' '}
          {t('paymentFailedDescription', {
            paymentDate:
              nextOrderStore.failedPaymentRetryDate?.formatInTZ(
                'MMMM dd, yyyy'
              ),
          })}
        </div>
        <Link
          size="large"
          weight="bold"
          href={Routes.settings.paymentMethods.path()}
        >
          {t('updatePayment')}
        </Link>
      </div>
    </div>
  )
})
