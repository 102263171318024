import { Action, Inject } from '@nori/di'
import { isErr } from '@nori/result'
import { createLogger } from '~/infra/create-logger'
import { CartStore } from '~/modules/cart/interface/store/cart-store/cart.store'

import { CartSubscriptionAdapter } from '../../infra/cart-subscription.adapter'
import { SubscriptionAdapter } from '../../infra/product-subscription.adapter'
import { ProductSubscriptionStore } from '../store/product-subscription.store'

const logger = createLogger('product-subscription.actions')

@Action()
export class ProductSubscriptionAction {
  constructor(
    @Inject()
    private readonly productSubscriptionStore: ProductSubscriptionStore,
    @Inject() private readonly cartSubscriptionAdapter: CartSubscriptionAdapter,
    @Inject()
    private readonly productSubscriptionAdapter: SubscriptionAdapter,
    @Inject() private readonly cartStore: CartStore
  ) {}

  async handleGetProductSubscriptionOptions({
    productId,
  }: {
    productId: number
  }): Promise<void> {
    if (this.productSubscriptionStore.productSubscriptions[productId]) {
      return
    }

    const result =
      await this.productSubscriptionAdapter.getProductSubscriptionOptions({
        productId,
      })

    if (isErr(result)) {
      logger.error(result.error.key, result.error)
      return
    }

    this.productSubscriptionStore.updateProductSubscriptions({
      productId,
      subscription: result.data,
    })
  }

  handleSubscriptionToggle({
    skuId,
    oldSubscriptionPlan,
    productId,
    isToggle,
    isSubscriptionSelected,
    subscriptionToggle,
  }: {
    skuId?: number
    oldSubscriptionPlan?: number
    productId: number
    isToggle: boolean
    isSubscriptionSelected: boolean
    subscriptionToggle: {
      isOpen: boolean
      handleOpen: () => void
      handleClose: () => void
      handleToggle: () => void
    }
  }): void {
    if (subscriptionToggle.isOpen) {
      subscriptionToggle.handleClose()
    }
    if (isToggle && isSubscriptionSelected) {
      return
    }
    if (!isToggle && !isSubscriptionSelected) {
      return
    }

    const subscriptionData =
      this.productSubscriptionStore.getProductSubscriptions({
        productId,
      })

    this.handleUpdateProductSubscription({
      skuId: skuId,
      subscriptionPlan: isToggle
        ? subscriptionData?.defaultSubscription.id
        : undefined,
      oldSubscriptionPlan: oldSubscriptionPlan,
      productId: productId,
    })
  }
  async handleProductSubscriptionOpen({
    skuId,
    oldSubscriptionPlan,
    subscriptionPlan,
    productId,
    isSubscriptionSelected,
    subscriptionToggle,
  }: {
    skuId?: number
    subscriptionPlan?: number
    oldSubscriptionPlan?: number
    productId: number
    isSubscriptionSelected: boolean
    subscriptionToggle: {
      isOpen: boolean
      handleOpen: () => void
      handleClose: () => void
      handleToggle: () => void
    }
  }): Promise<void> {
    subscriptionToggle.handleOpen()

    if (!isSubscriptionSelected) {
      return
    }

    const cartId = this.cartStore.cart?.id

    if (!cartId || !skuId) {
      logger.error('Missing data for subscription update')
      return
    }

    this.productSubscriptionStore.setProductSubscriptionUpdateLoading({
      productId,
      isLoading: true,
    })

    await this.updateProductSubscription({
      cartId,
      skuId,
      oldSubscriptionPlan,
      subscriptionPlan,
    })

    this.productSubscriptionStore.setProductSubscriptionUpdateLoading({
      productId,
      isLoading: false,
    })
  }

  async handleUpdateProductSubscription({
    skuId,
    oldSubscriptionPlan,
    subscriptionPlan,
    productId,
  }: {
    skuId?: number
    subscriptionPlan?: number
    oldSubscriptionPlan?: number
    productId: number
  }): Promise<void> {
    const cartId = this.cartStore.cart?.id

    if (!cartId || !skuId) {
      logger.error('Missing data for subscription update')
      return
    }

    this.productSubscriptionStore.setProductSubscriptionUpdateLoading({
      productId,
      isLoading: true,
    })

    await this.updateProductSubscription({
      cartId,
      skuId,
      oldSubscriptionPlan,
      subscriptionPlan,
    })

    this.productSubscriptionStore.setProductSubscriptionUpdateLoading({
      productId,
      isLoading: false,
    })
  }

  private async updateProductSubscription({
    skuId,
    oldSubscriptionPlan,
    subscriptionPlan,
    cartId,
  }: {
    skuId: number
    subscriptionPlan?: number
    oldSubscriptionPlan?: number
    cartId: number
  }): Promise<void> {
    const result = await this.cartSubscriptionAdapter.updateSubscription({
      cartId,
      skuId,
      oldSubscriptionPlan,
      subscriptionPlan,
    })

    if (isErr(result)) {
      logger.error(result.error.key, result.error)
      return
    }

    this.cartStore.cartData.setData(result.data)
  }
}
