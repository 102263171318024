import { DataState, Store, Toggle } from '@nori/di'

import type {
  PaginationValueObject,
  SortDirectionValueObject,
} from '@nori/app-kit'
import type { DomainError } from '@nori/errors'
import type { NoteEntity } from '~/modules/notes/core/note.entity'
import type { ContactListItemEntity } from '../../core'
import type { ContactSort } from '../../core/contact-sort'

type DataTableType = 'opt-in' | 'all'

const MAILING_MESSAGE = '#Mailing'

@Store()
export class ContactManageMailingStore {
  contactManageMailingModal = new Toggle(false)
  searchQuery = ''
  dataTableType: DataTableType = 'opt-in'
  pagination?: PaginationValueObject = undefined
  searchFilter = 'customer_name_email_address' as const
  sortBy: ContactSort = 'first_name'
  sortDirection: SortDirectionValueObject = 'asc'
  isLoadingNextPage = false
  contactsToReceiveCatalog: Record<number, boolean> = {}
  abortController = new AbortController()
  isLoading = false

  contactsManageMailingData = new DataState<
    ContactListItemEntity[],
    DomainError
  >()

  get contacts(): ContactListItemEntity[] {
    return this.contactsManageMailingData.data ?? []
  }

  getNotesForContact(id: number): NoteEntity | undefined {
    const contact = this.contacts.find((contact) => contact.id === id)

    return contact?.notes.find((note) => note.content === MAILING_MESSAGE)
  }

  get currentPage(): number {
    return this.pagination?.page ?? 0
  }

  get isLastPage(): boolean {
    return this.pagination?.totalPages === this.pagination?.page
  }

  get isContactsTouched(): boolean {
    return !!Object.keys(this.contactsToReceiveCatalog).length
  }

  get totalOptedInContacts(): number {
    return (
      this.contactsManageMailingData.data?.filter(
        (contact) => contact.shouldReceivePrintCatalog
      ).length ?? 0
    )
  }

  setDataToDefault(): void {
    this.contactsManageMailingData.reset()
    this.setPagination(undefined)
  }

  setContactsManageMailingData(value: ContactListItemEntity[]): void {
    this.contactsManageMailingData.setData(value)
  }

  setIsLoading(value: boolean): void {
    this.isLoading = value
  }

  setIsLoadingNextPage(value: boolean): void {
    this.isLoadingNextPage = value
  }

  setSearchQuery(value: string): void {
    this.searchQuery = value
  }

  setDataTableType(value: DataTableType): void {
    this.dataTableType = value
  }

  setPagination(value?: PaginationValueObject): void {
    this.pagination = value
  }

  setError(value: DomainError): void {
    this.contactsManageMailingData.setError(value)
  }

  setContactsToReceiveCatalog(contactId: number, value: boolean): void {
    this.contactsToReceiveCatalog = {
      ...this.contactsToReceiveCatalog,
      [contactId]: value,
    }
  }

  setContactsToReceiveCatalogToDefault(): void {
    this.contactsToReceiveCatalog = {}
  }

  appendContactsManageMailingData(value: ContactListItemEntity[]): void {
    this.contactsManageMailingData.setData([
      ...(this.contactsManageMailingData.data ?? []),
      ...value,
    ])
  }

  abortPreviousRequest(): void {
    this.abortController.abort()
    this.abortController = new AbortController()
  }

  setAllDataToDefault(): void {
    this.setDataToDefault()
    this.setContactsToReceiveCatalogToDefault()
    this.setSearchQuery('')
    this.setDataTableType('opt-in')
    this.setPagination(undefined)
  }
}
