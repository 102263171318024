import { Inject, Service } from '@nori/di'
import { getBlock } from '@nori/lang-i18n'
import { NotifyAction } from '@nori/notify'
import { isErr, resultOk } from '@nori/result'
import { createLogger } from '~/infra/create-logger'
import { CartErrors } from '~/modules/cart/core'
import { CartStore } from '~/modules/cart/interface/store/cart-store/cart.store'

import { CartAdapter } from '../../../infra/cart.adapter'

import type { CartEntity } from '@nori/app-kit'
import type { DomainError } from '@nori/errors'
import type { PromiseResult, Result } from '@nori/result'
import type { CartErrorInstance } from '~/modules/cart/core'
import type { CreateCartInput } from '../../../core/create-cart.input'

const logger = createLogger('load-cart.service')

@Service()
export class LoadCartService {
  constructor(
    @Inject() private readonly cartStore: CartStore,
    @Inject() private readonly cartAdapter: CartAdapter,
    @Inject() private readonly notifyAction: NotifyAction
  ) {}

  async loadCartByType(
    typeCode: NonNullable<CreateCartInput['typeCode']>
  ): Promise<void> {
    if (
      this.cartStore.cart?.typeCode === typeCode ||
      this.cartStore.cartData.isLoading
    ) {
      return
    }

    this.cartStore.cartData.setIsLoading(true)
    this.cartStore.cartData.setError(undefined)
    this.cartStore.cartData.setData(undefined)

    await this.createCart({ typeCode })

    this.cartStore.cartData.setIsLoading(false)
  }

  async loadCartById({ cartId }: { cartId?: number }): Promise<void> {
    const { persistCartId } = this.cartStore

    const usedCartId = cartId ?? persistCartId

    if (!usedCartId) {
      await this.createCart()
      return
    }

    const result = await this.cartAdapter.getCartById({
      cartId: usedCartId,
    })

    if (isErr(result) && result.error instanceof CartErrors.NotFound) {
      await this.createCart()
      return
    }

    this.processResult(result)
  }

  async resetCart(
    data?: Partial<CreateCartInput>
  ): PromiseResult<CartEntity, DomainError> {
    const result = await this.deleteCart()
    if (isErr(result)) {
      this.notifyAction.handleAddNotify({
        type: 'danger',
        title: getBlock('cart.errors')('resetCartError'),
      })
      return result
    }

    return this.createCart(data)
  }

  private async deleteCart(): PromiseResult<void, CartErrorInstance> {
    const cartId = this.cartStore.cart?.id
    if (!cartId) return resultOk(undefined)

    return this.cartAdapter.deleteCart(cartId)
  }

  async createCart(
    data?: Partial<CreateCartInput>
  ): PromiseResult<CartEntity, DomainError> {
    this.cartStore.cartData.setIsLoading(true)
    const result = await this.cartAdapter.createCart(data)

    this.processResult(result)

    this.cartStore.cartData.setIsLoading(false)
    return result
  }

  private processResult(result: Result<CartEntity, DomainError>): void {
    if (isErr(result)) {
      this.notifyAction.handleAddNotify({
        title: getBlock('cart.errors')('cartLoadError'),
        type: 'danger',
      })
      logger.error(result.error.key, result.error)
      this.cartStore.cartData.setError(result.error)
      return
    }

    this.cartStore.cartData.setData(result.data)
  }
}
