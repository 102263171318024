import { DataState, Store, Toggle } from '@nori/di'
import { Validator } from '@nori/validator'

import type { CouponCodeErrorInstance } from '../../core/coupon-code-errors'

@Store()
export class CouponCodeStore {
  couponCodeState = new DataState<string, CouponCodeErrorInstance>({
    isLoading: false,
  })

  couponCodeValidator = new Validator({
    couponCode: Validator.scheme.string(),
  })
}
