import { CartService } from '@nori/app-kit'
import { Inject, Service } from '@nori/di'

import type { AddManyToCartInput } from '~/modules/cart/infra/types/add-many-to-cart/add-many-to-cart.input'
import type { AddManyToCartRequest } from '~/modules/cart/infra/types/add-many-to-cart/add-many-to-cart.request'
import type { AddManyToCartResponseValueObject } from '../../core'

@Service()
export class AddManyToCartMapper {
  constructor(@Inject() private readonly cartService: CartService) {}

  toRequestBody(input: AddManyToCartInput): AddManyToCartRequest {
    return {
      bundles: input.bundles.map((bundle) => ({
        code: bundle.code,
        skus: bundle.skuIds.map((skuId) => ({ sku_id: skuId })),
        subscription_plan_template:
          bundle.subscription_plan_template_id ?? null,
      })),
      skus: input.skuIds.map((skuId) => ({ sku_id: skuId })),
    }
  }

  toResponseValueObject(data: any): AddManyToCartResponseValueObject {
    return {
      cart: this.cartService.toCartEntity(data),
      bundleErrors:
        data.add_all_bundles_errors?.map((e: any) => ({
          errors: e.errors ?? undefined,
        })) ?? [],
      skuErrors:
        data.add_all_items_errors?.map((e: any) => ({
          errors: e.errors ?? undefined,
        })) ?? [],
    }
  }
}
