import { observer } from '@nori/di'
import { useAutosaveContext } from '~/modules/autosave/interface/use-autosave-context'

import { BundleRow } from './bundle-row'
import { StyleRow } from './style-row'

export const AddItemTableMobile = observer(() => {
  const { nextOrderAddItemStore } = useAutosaveContext()

  return (
    <>
      {nextOrderAddItemStore.products?.items?.map((item, index) => {
        switch (item.typeCode) {
          case 'style':
            return <StyleRow key={item.id} item={item} />
          case 'bundle':
            return <BundleRow key={item.id} item={item} />
          default:
            return null
        }
      })}
    </>
  )
})
