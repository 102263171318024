import { errorFactory } from '@nori/errors'

import type { KeyOfErrors } from '@nori/errors'

export const { AddToCartErrors } = errorFactory('AddToCartErrors', {
  BadRequest: 'Bad Request',
  NotFound: 'Not Found',
  UnproceccedEntity: 'UnproceccedEntity',
  UnexpectedError: 'Unexpected Error',
  UnknownError: 'Unknown Error',

  MaximumQuantityError: 'Maximum Quantity Error',
  CartLimitError: 'Cart Limit Error',
  NotAvailableError: 'Not Available Error',
  QuantityNotAvailableError: 'Quantity Not Available',
})

export type AddToCartErrorInstance = InstanceType<
  KeyOfErrors<typeof AddToCartErrors>
>
