import { errorFactory } from '@nori/errors'

import type { KeyOfErrors } from '@nori/errors'

export const { CreateNoteErrors } = errorFactory('CreateNoteErrors', {
  BadRequest: 'Bad Request',
  UnexpectedError: 'Unexpected Error',
  UnknownError: 'Unknown Error',
})

export type CreateNoteErrorInstance = InstanceType<
  KeyOfErrors<typeof CreateNoteErrors>
>
