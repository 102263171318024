/* eslint-disable max-lines */
import {
  getMoneyWithIsoCurrency,
  isPaypalPaymentMethod,
  UserStore,
} from '@nori/app-kit'
import { NoriDate } from '@nori/date'
import { DataState, Inject, Store, Toggle } from '@nori/di'
import { getBlock } from '@nori/lang-i18n'
import { ContactDetailsStore } from '~/modules/contacts/interface/store/contact-details.store'

import { ShippingAddressModalConfig } from '../shipping-address-modal-config'

import type {
  AddressValueObject,
  BundleDetailsEntity,
  CardTypeValueObject,
  MoneyValueObject,
  PaymentMethodEntity,
  StyleDetailsEntity,
} from '@nori/app-kit'
import type {
  NextOrderValueObject,
  OneTimeShippingAddressEntity,
  SubscriptionEntity,
  SubscriptionErrorInstance,
} from '~/modules/subscription'
import type { SubscriptionProductsErrorInstance } from '~/modules/subscription/core/subscription-products.errors'

const MAX_ALLOWED_DAYS_FOR_AUTO_SAVE = 365
@Store()
export class NextOrderStore {
  error?: SubscriptionErrorInstance = undefined
  isLoading = false
  isShouldApplyCreditsPending = false
  shippingAddressModalToggle = new Toggle(false)
  isUseUserIdForShippingAddress = false

  userId?: number = undefined

  nextOrder?: NextOrderValueObject = undefined
  nextOrderDateHashMap: Record<number, NoriDate> = {}
  paymentMethod?: PaymentMethodEntity = undefined
  subscriptionProducts = new DataState<
    (BundleDetailsEntity | StyleDetailsEntity)[],
    SubscriptionProductsErrorInstance
  >()
  shippingAddressConfig = new ShippingAddressModalConfig()

  constructor(
    @Inject() private readonly userStore: UserStore,
    @Inject() private readonly contactDetailsStore: ContactDetailsStore
  ) {}

  get subscription(): SubscriptionEntity | undefined {
    return this.autoSaveSubscriptions?.[0]
  }

  get nextCartAt(): NoriDate | undefined {
    return this.subscription?.nextCartAt
  }

  get lastDayToEdit(): string | undefined {
    return this.nextCartAt
      ? this.nextCartAt.addDays(-1).endOfDay().formatInTZ('MMM dd h:mmaaa')
      : undefined
  }

  get availableSubscriptions():
    | (BundleDetailsEntity | StyleDetailsEntity)[]
    | undefined {
    return this.subscriptionProducts.data?.filter(
      (product) => product.available
    )
  }

  get subscriptions(): SubscriptionEntity[] | undefined {
    return this.nextOrder?.subscriptions
  }

  get autoSaveSubscriptions(): SubscriptionEntity[] | undefined {
    return this.nextOrder?.subscriptions.filter(
      (subscription) => !subscription.oneOff
    )
  }

  get addOnItems(): SubscriptionEntity[] | undefined {
    return this.nextOrder?.subscriptions.filter(
      (subscription) => subscription.oneOff
    )
  }

  get totalSavings(): MoneyValueObject | undefined {
    const subscriptionWithoutAddons =
      this.subscriptions?.filter((subscription) => !subscription.oneOff) ?? []

    const totalSavings = subscriptionWithoutAddons.reduce(
      (total, sub) => {
        if (sub.retailPrice?.cents && sub.subscriptionPrice?.cents) {
          total.cents +=
            (sub.retailPrice.cents - sub.subscriptionPrice.cents) * sub.quantity
        }

        return total
      },
      { cents: 0, currency: this.userStore.currency }
    )
    if (totalSavings.cents <= 0) return

    return totalSavings
  }

  get paypalEmail(): string | undefined {
    if (!this.paymentMethod || !isPaypalPaymentMethod(this.paymentMethod)) {
      return undefined
    }

    return this.paymentMethod.email
  }

  get isShowCardPayment(): boolean {
    return (
      this.paymentMethod?.type === 'CreditCard' &&
      !!this.paymentMethod?.lastDigits
    )
  }

  get paymentCardType(): CardTypeValueObject | undefined {
    if (!this.paymentMethod || isPaypalPaymentMethod(this.paymentMethod)) return
    return this.paymentMethod.cardType
  }

  get paymentLastDigits(): string | undefined {
    if (!this.paymentMethod || isPaypalPaymentMethod(this.paymentMethod)) return
    return this.paymentMethod.lastDigits
  }

  get shippingAddress(): AddressValueObject | undefined {
    const isSubscriptionAddressEmpty = this.checkEmptyAddress(
      this.subscription?.shippingAddress
    )
    return isSubscriptionAddressEmpty
      ? this.userStore.currentUser.shippingAddress
      : this.subscription?.shippingAddress
  }

  get billingAddress(): AddressValueObject | undefined {
    if (!this.paymentMethod) {
      return undefined
    }

    return this.paymentMethod.billingAddress
  }

  get creditsSum(): string {
    if (this.nextOrder) {
      const { storeCredits, productCredits, currency } = this.nextOrder.credits
      const sum = storeCredits + productCredits
      return getMoneyWithIsoCurrency({
        cents: sum,
        currency,
      })
    }
    return '0$'
  }

  get subscriptionShippingAddress(): AddressValueObject {
    return this.isOneTimeShippingAddressExists
      ? (this.oneTimeShippingAddress as AddressValueObject)
      : this.defaultShippingAddress
  }

  get contactSubscriptionShippingAddress(): AddressValueObject {
    return this.isOneTimeShippingAddressExists
      ? (this.oneTimeShippingAddress as AddressValueObject)
      : (this.contactDetailsStore.contactAddress as AddressValueObject)
  }

  get oneTimeShippingAddress(): OneTimeShippingAddressEntity | undefined {
    return this.nextOrder?.oneTimeShippingAddress
  }

  get shippingAddressContactInfo(): { firstName: string; lastName: string } {
    const contactInfo = {
      firstName: this.userStore.currentUser.firstName,
      lastName: this.userStore.currentUser.lastName,
    }
    return contactInfo
  }

  get autoSaveMinDate(): NoriDate {
    return new NoriDate().addDays(1)
  }

  get autoSaveMaxDate(): NoriDate {
    return new NoriDate().addDays(MAX_ALLOWED_DAYS_FOR_AUTO_SAVE)
  }

  getIsItemDateDifferentFromNextOrderDate(id: number): boolean {
    const nextCartAt = this.nextOrder?.subscriptions?.[0]?.nextCartAt
    if (!nextCartAt) return false

    const nextOrderPopupDate = this.getNextOrderPopupDate(id)
    return nextOrderPopupDate?.getTime() !== nextCartAt.getTime()
  }

  getNextOrderPopupDate(id: number): NoriDate | undefined {
    if (this.nextOrderDateHashMap[id]) {
      return this.nextOrderDateHashMap[id] as NoriDate
    }

    const nextCartAt = this.nextOrder?.subscriptions?.[0]?.nextCartAt
    const dateToCache = nextCartAt || this.autoSaveMinDate

    this.nextOrderDateHashMap[id] = dateToCache
    return dateToCache
  }

  get unavailableMessage(): string {
    if (!this.availableSubscriptions) return ''

    if (this.availableSubscriptions?.length >= 2)
      return getBlock('autosaveSettings.nextOrderTable')('outOfStock')

    return getBlock('autosaveSettings.nextOrderTable')('outOfStockAddOn')
  }

  getSubscriptionProductInfo(
    subscription: SubscriptionEntity
  ): BundleDetailsEntity | StyleDetailsEntity | undefined {
    return this.subscriptionProducts.data?.find((product) => {
      if (
        product.id === subscription.productId ||
        product.id === subscription.bundleId
      ) {
        return product
      }
    })
  }

  setUserId(value?: number): void {
    this.userId = value
  }

  get isOneTimeShippingAddressExists(): boolean {
    return (
      Object.values(this.oneTimeShippingAddress ?? {}).filter(Boolean).length >
      0
    )
  }

  get defaultShippingAddress(): AddressValueObject {
    return this.userStore.currentUser.shippingAddress
  }

  get subscriptionIdsWithoutOneOffs(): number[] | undefined {
    return this.subscriptions
      ?.filter((item) => !item.oneOff)
      .map((item) => item.id)
  }

  get failedPaymentRetryDate(): NoriDate | undefined {
    return this.nextOrder?.retryAt
  }

  setNextOrder(nextOrder: NextOrderValueObject): void {
    this.nextOrder = nextOrder
  }

  setNextOrderPopupDate(nextOrderPopupDate: NoriDate, id: number): void {
    this.nextOrderDateHashMap[id] = nextOrderPopupDate
  }

  appendNextOrderSubscriptions(subscriptions: SubscriptionEntity[]): void {
    this.setNextOrderSubscriptions([
      ...(this.nextOrder?.subscriptions ?? []),
      ...subscriptions,
    ])
  }

  setNextOrderSubscriptions(subscriptions: SubscriptionEntity[]): void {
    if (!this.nextOrder) return
    this.nextOrder.subscriptions = subscriptions
  }

  setPaymentMethod(paymentMethod?: PaymentMethodEntity): void {
    this.paymentMethod = paymentMethod
  }

  setIsLoading(isLoading: boolean): void {
    this.isLoading = isLoading
  }

  setShouldApplyCreditsPending(value: boolean): void {
    this.isShouldApplyCreditsPending = value
  }

  setIsShippingAddressModalOpen(): void {
    this.shippingAddressModalToggle.handleOpen()
  }

  setIsShippingAddressModalClose(): void {
    this.shippingAddressModalToggle.handleClose()
  }

  setError(error?: SubscriptionErrorInstance): void {
    this.error = error
  }

  useUserIdForShippingAddress(): void {
    this.isUseUserIdForShippingAddress = true
  }

  validateAddress():
    | { address: AddressValueObject; isValid: true }
    | { isValid: false } {
    if (this.shippingAddressConfig.addressType === 'current-user') {
      return {
        address: this.defaultShippingAddress,
        isValid: true,
      }
    }

    const { isValid } =
      this.shippingAddressConfig.shippingAddressValidator.submit()

    if (!isValid) return { isValid }

    return {
      address: {
        ...this.shippingAddressConfig.shippingAddressValidator.values,
        phoneNumber:
          this.shippingAddressConfig.shippingAddressValidator.values.phone,
      },
      isValid: true,
    }
  }

  private checkEmptyAddress(address?: AddressValueObject): boolean {
    if (!address) return false

    return (
      [
        address.address1,
        address.address2,
        address.city,
        address.state,
        address.zipCode,
        address.country,
      ].filter(Boolean).length === 0
    )
  }

  resetNextOrderDateHashMap(): void {
    this.nextOrderDateHashMap = {}
  }
}
/* eslint-enable max-lines */
