import { useEffect } from 'react'

import { observer } from '@nori/di'
import { useTranslations } from '@nori/lang-i18n'
import { Button, Divider, Link, Loader, Swap } from '@nori/ui-kit'

import { useBundleContext } from '../interface/use-bundle.context'

import { StepPageBundle } from './step-page'
import { SummaryPageBundle } from './summary-page'

export const CustomizeBundle = observer(() => {
  const t = useTranslations('productList.bundleModal')

  const { customizeBundleAction, customizeBundleStore } = useBundleContext()

  const {
    isButtonDisabled,
    isPreviousStepExits,
    isLastStep,
    isLoading,
    buttonTitle,
  } = customizeBundleStore

  const handleGoBackLink = isPreviousStepExits
    ? customizeBundleAction.handleNavigateBack
    : customizeBundleAction.handleCloseModal

  return (
    <div className="lg:p-13">
      <div className="relative">
        <div
          onClick={handleGoBackLink}
          className="absolute left-0 top-0 max-sm:hidden"
        >
          <Link size="medium" iconLeft="ArrowLeft" isUnderline={false}>
            {t('goBack')}
          </Link>
        </div>
        <Swap is={isLastStep} isSlot={<SummaryPageBundle />}>
          <StepPageBundle />
        </Swap>
      </div>
      <div className="mt-12">
        <Divider />
      </div>
      <div className="relative mt-7 flex justify-center">
        <div
          onClick={handleGoBackLink}
          className="absolute left-0 top-0 flex h-full items-center max-sm:hidden"
        >
          <Link size="medium" iconLeft="ArrowLeft" isUnderline={false}>
            {t('goBack')}
          </Link>
        </div>
        <Swap is={isLoading} isSlot={<Loader />}>
          <Button
            onClick={customizeBundleAction.handleAddBundleToCart}
            disabled={isButtonDisabled}
            size="lg"
          >
            <div className="px-16">
              {t(buttonTitle as 'review' | 'next' | 'addToCart')}
            </div>
          </Button>
        </Swap>
      </div>
    </div>
  )
})
