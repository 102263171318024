import { useCallback } from 'react'

import {
  dateFormat,
  getLink,
  getStyleItemName,
  ProductImage,
  ProductPrice,
} from '@nori/app-kit'
import { useTranslations } from '@nori/lang-i18n'
import { Icon } from '@nori/ui-kit'

import { WishListItemButton } from './wish-list-item.button'

import type { WishItemEntity } from '../core/entities/wish-item.entity'
import type { ProcessingItemValueObject } from '../core/value-object/processing-item.value-object'

type Props = {
  item: WishItemEntity
  onRemoveItemFromWishList: (id: number) => void
  processingItemMap?: ProcessingItemValueObject
  isAddingToCart?: boolean
  onAddItemToBag?: (id: number) => void
}

export const WishListItem = ({
  item,
  onRemoveItemFromWishList,
  processingItemMap,
  isAddingToCart,
  onAddItemToBag,
}: Props) => {
  const t = useTranslations('wishList')

  const addedAt = new Date(item.addedAt)

  const itemTitle = getStyleItemName({
    productName: item.productName,
    styleName: item.styleName,
  })

  const handleOpenPDPLink = useCallback(() => {
    const pdpLink = getLink({ type: 'bo', personalLink: `p/${item.styleKey}` })
    window.open(pdpLink.url, '_blank')
  }, [item.styleKey])

  return (
    <>
      <div className="relative flex h-full max-w-[320px] flex-grow flex-col justify-normal gap-y-1 md:w-[250px] lg:max-w-[250px]">
        <div
          onClick={() => onRemoveItemFromWishList(item.id)}
          className="absolute right-0 top-0 z-10 m-1 cursor-pointer rounded p-1"
          data-test-id="remove-item-from-wishlist"
        >
          <Icon name="CloseLine" />
        </div>
        <div
          className="relative flex cursor-pointer flex-col justify-center"
          data-test-id="wishlist-item"
          onClick={handleOpenPDPLink}
        >
          <div className="relative mb-7 flex">
            <ProductImage
              src={item.catalogImage.thumbnailLargeURL}
              alt={item.productName}
              width={320}
              height={320}
              styleName="rounded-sm border text-center"
            />
          </div>
          <div className="mb-1 truncate text-base font-bold leading-6 text-black">
            {itemTitle}
          </div>
          <div className="mb-1 truncate text-sm font-normal text-black">
            {item.skuName} &nbsp;
          </div>
          <div className="text-base font-bold leading-6 text-black">
            <ProductPrice price={item.currentPrice} />
          </div>
        </div>
        <div className="mt-4">
          {onAddItemToBag && processingItemMap && (
            <WishListItemButton
              skuId={item.skuId}
              isAvailable={item.isAvailable}
              onAddItemToBag={onAddItemToBag}
              processingItemMap={processingItemMap}
              isAddingToCart={isAddingToCart}
            />
          )}

          <div className="mt-2 text-xs text-gray-600">
            {t('addedAt', { addedAt: dateFormat(addedAt, 'MM/dd/yyyy') })}
          </div>
        </div>
      </div>
    </>
  )
}
