import Link from 'next/link'

import { useTranslations } from '@nori/lang-i18n'
import { Icon, Swap } from '@nori/ui-kit'
import { useFeatureFlagsContext } from '~/modules/feature-flags'

import type { LinkWithChildren } from '../../header-links/header-link.type'

type Props = {
  link: LinkWithChildren
  listSlot?: React.ReactNode
  nestedMenuVisibility: Record<string, boolean | undefined>
  onToggleNestedMenu: (value: string) => void
  onCloseMenu: () => void
}

export const MenuLinkItem = ({
  link,
  listSlot,
  nestedMenuVisibility,
  onToggleNestedMenu,
  onCloseMenu,
}: Props) => {
  const t = useTranslations()
  const { featureFlagsStore } = useFeatureFlagsContext()

  const hasChildren = !!link.children?.length
  const isNestedMenuExpanded = link.translationKey
    ? !!nestedMenuVisibility[link.translationKey]
    : false

  const backgroundColor =
    isNestedMenuExpanded && hasChildren ? 'bg-primary-50' : ''

  return (
    <>
      <Link
        className={`flex flex-row justify-between border-primary-700 px-5 py-4 text-lg font-medium text-black ${backgroundColor}`}
        href={link.href || '#'}
        onClick={() => {
          !hasChildren && onCloseMenu()
          onToggleNestedMenu(link.translationKey || '')
        }}
      >
        {link.translationKey && t(link.translationKey)}

        {hasChildren && (
          <Icon
            // eslint-disable-next-line @nori/custom-rules/jsx-no-ternary
            name={isNestedMenuExpanded ? 'SolidMinus' : 'SolidPlus'}
            size="md"
          />
        )}
      </Link>

      {isNestedMenuExpanded &&
        !listSlot &&
        link.children?.map((child) => {
          const isFeatureFlagEnabled = child.featureFlag
            ? featureFlagsStore.getFlag(child.featureFlag)
            : true

          return (
            <Swap
              is={!isFeatureFlagEnabled}
              isSlot={<></>}
              key={child.translationKey}
            >
              <Link
                className="flex flex-row justify-between border-primary-700 bg-primary-50 px-4 py-3 font-bold text-primary-900"
                key={child.translationKey}
                href={child.href}
                onClick={() => {
                  child.onClick?.()
                  onCloseMenu()
                }}
              >
                {t(child.translationKey)}
              </Link>
            </Swap>
          )
        })}

      {isNestedMenuExpanded && listSlot && <div>{listSlot}</div>}
    </>
  )
}
