import { AddressMapperService } from '@nori/app-kit'
import { Inject, Service } from '@nori/di'

import type { ShippingAddressEntity } from '../../core'

@Service()
export class ShippingAddressMapperService {
  constructor(
    @Inject() private readonly addressMapperService: AddressMapperService
  ) {}

  toShippingAddressEntity(data: any): ShippingAddressEntity {
    const baseAddressValueObject =
      this.addressMapperService.toAddressValueObject(data)

    return {
      ...baseAddressValueObject,
      id: data.id,
      firstName: data.first_name,
      lastName: data.last_name,
      phoneNumber: data.phone || data.phone_number,
    }
  }
}
