import { Inject, Service } from '@nori/di'
import { isErr } from '@nori/result'
import { createLogger } from '~/infra/create-logger'

import { OrderAdapter } from '../../infra/order.adapter'

import type { RetailSalesValueObject } from '../../core/retail-sales.value-object'

const logger = createLogger('orders-retail-sales.service')

@Service()
export class OrdersRetailSalesService {
  constructor(@Inject() private readonly orderAdapter: OrderAdapter) {}

  async getOrdersRetailSales(
    partyId: number
  ): Promise<RetailSalesValueObject | undefined> {
    const result = await this.orderAdapter.getPartyOrdersRetailSales(partyId)

    if (isErr(result)) {
      logger.error(result.error.key, result.error)
      return undefined
    }

    return result.data
  }
}
