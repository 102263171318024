import { useEffect } from 'react';
export function useEscPress({ onClose , isOpen  }) {
    useEffect(()=>{
        if (!isOpen) return;
        const onKey = (e)=>{
            if (e.code === 'Escape') {
                onClose();
            }
        };
        window.addEventListener('keydown', onKey);
        return ()=>{
            window.removeEventListener('keydown', onKey);
        };
    }, [
        isOpen,
        onClose
    ]);
}
