export const TERMS_CONDITIONS_LINKS = {
  norwexConsultantAgreement: {
    'en-US':
      'https://content-assets.norwex.com/assets/NA/website/documents/compliance/Application_and_Agreement_en_US.pdf',
    'es-US':
      'https://content-assets.norwex.com/assets/NA/website/documents/compliance/Application_and_Agreement_es_US.pdf',
    'en-CA':
      'https://content-assets.norwex.com/assets/NA/website/documents/compliance/Application_and_Agreement_en_CA.pdf',
    'fr-CA':
      'https://content-assets.norwex.com/assets/NA/website/documents/compliance/Application_and_Agreement_fr_CA.pdf',
  },
  policiesAndProcedures: {
    'en-US':
      'https://content-assets.norwex.com/assets/NA/website/documents/compliance/Policies_and_Procedures_en_US.pdf',
    'es-US':
      'https://content-assets.norwex.com/assets/NA/website/documents/compliance/Policies_and_Procedures_es_US.pdf',
    'en-CA':
      'https://content-assets.norwex.com/assets/NA/website/documents/compliance/Policies_and_Procedures_en_CA.pdf',
    'fr-CA':
      'https://content-assets.norwex.com/assets/NA/website/documents/compliance/Policies_and_Procedures_fr_CA.pdf',
  },
  mediaPolicy: {
    'en-US':
      'https://content-assets.norwex.com/assets/NA/website/documents/compliance/Media_Policy_en_US.pdf',
    'es-US':
      'https://content-assets.norwex.com/assets/NA/website/documents/compliance/Media_Policy_es_US.pdf',
    'en-CA':
      'https://content-assets.norwex.com/assets/NA/website/documents/compliance/Media_Policy_en_CA.pdf',
    'fr-CA':
      'https://content-assets.norwex.com/assets/NA/website/documents/compliance/Media_Policy_fr_CA.pdf',
  },
  successBuilder: {
    'en-US':
      'https://content-assets.norwex.com/assets/NA/website/documents/compliance/Success_Builder_en_US.pdf',
    'es-US':
      'https://content-assets.norwex.com/assets/NA/website/documents/compliance/Success_Builder_es_US.pdf',
    'en-CA':
      'https://content-assets.norwex.com/assets/NA/website/documents/compliance/Success_Builder_en_CA.pdf',
    'fr-CA':
      'https://content-assets.norwex.com/assets/NA/website/documents/compliance/Success_Builder_fr_CA.pdf',
  },
  agreementPreview: {
    'en-US':
      'https://content-assets.norwex.com/assets/NA/website/documents/compliance/Agreements_Preview_en_US.pdf',
    'es-US':
      'https://content-assets.norwex.com/assets/NA/website/documents/compliance/Agreements_Preview_es_US.pdf',
    'en-CA':
      'https://content-assets.norwex.com/assets/NA/website/documents/compliance/Agreements_Preview_en_CA.pdf',
    'fr-CA':
      'https://content-assets.norwex.com/assets/NA/website/documents/compliance/Agreements_Preview_fr_CA.pdf',
  },
  officeSuiteTermsAndConditions: {
    'en-US':
      'https://content-assets.norwex.com/assets/NA/website/documents/compliance/Office_Suite_Terms_and_Conditions_en_US.pdf',
    'es-US':
      'https://content-assets.norwex.com/assets/NA/website/documents/compliance/Office_Suite_Terms_and_Conditions_es_US.pdf',
    'en-CA':
      'https://content-assets.norwex.com/assets/NA/website/documents/compliance/Office_Suite_Terms_and_Conditions_en_CA.pdf',
    'fr-CA':
      'https://content-assets.norwex.com/assets/NA/website/documents/compliance/Office_Suite_Terms_and_Conditions_fr_CA.pdf',
  },
} as const
