import type { CreateContactInput } from '../types/create/create-contact.input'
import type { CreateContactRequest } from '../types/create/create-contact.request'

export class CreateContactRequestMapper {
  static toRequestBody(input: CreateContactInput): CreateContactRequest {
    return {
      address_attributes: {
        address1: input.addressAttributes.address1,
        address2: input.addressAttributes.address2,
        city: input.addressAttributes.city,
        country: input.addressAttributes.country,
        state: input.addressAttributes.state,
        zip_code: input.addressAttributes.zipCode,
      },
      birth_day: input.birth.day ?? null,
      birth_month: input.birth.month ?? null,
      creditsEntries: input.creditsEntries ?? null,
      email: input.isEmailProvided ? input.email : undefined,
      email_provided: input.isEmailProvided,
      first_name: input.firstName,
      generate_referral_link: input.generateReferralLink,
      has_wish_list: input.hasWishList,
      last_name: input.lastName,
      last_order_date: input.lastOrderDate ?? null,
      last_order_id: input.lastOrderId ?? null,
      notes_attributes: input.notesAttributes,
      phone: input.phone,
      potential_hostess: input.potentialHostess,
      potential_stylist: input.potentialStylist,
      preferred_customer: input.preferredCustomer,
      quizAttributes: input.quizAttributes ?? null,
      quizTaken: input.quizTaken ?? null,
      quizVersion: input.quizVersion ?? null,
      referrals: input.referrals ?? null,
      selected: input.selected,
      showQuiz: input.showQuiz,
      skinQuizConfig: input.skinQuizConfig,
      storeCreditsEntries: input.storeCreditsEntries ?? null,
    }
  }
}
