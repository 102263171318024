import { getMoneyWithIsoCurrency, ProductImage } from '@nori/app-kit'
import { useTranslations } from '@nori/lang-i18n'
import { useToggle } from '@nori/ui-kit'
import { BundleDropDown } from '~/modules/cart/ui/products-list/item/bundle-dropdown/bundle-dropdown'

import type { CartBundleEntity } from '@nori/app-kit'

type Props = {
  bundle: CartBundleEntity
}

export function CartBundle({ bundle }: Props) {
  const t = useTranslations('shopForMyself.orderSummary.cartItem')
  const detailsToggle = useToggle()

  const detailsHeight = detailsToggle.isOpen ? 'max-h-full' : 'max-h-0'

  return (
    <div className="flex flex-row border-b py-5">
      <ProductImage
        src={bundle.thumbnailUrl}
        width={80}
        height={80}
        alt={bundle.name}
        styleName="h-full"
      />
      <div className="ml-4 flex w-full flex-col">
        <div className="mb-0.5 flex items-center justify-between text-base font-bold text-gray-700">
          <div>{bundle.name}</div>
          <div className="text-sm font-bold text-gray-700">
            {getMoneyWithIsoCurrency(
              bundle.salePrice ?? bundle.retailPrice ?? bundle.price
            )}
          </div>
        </div>

        <div className="text-xs font-medium text-gray-500">
          {t('qty')} {bundle.quantity}
        </div>
        <div className="my-3">
          <BundleDropDown bundle={bundle} />
        </div>

        {bundle.items[0]?.autoShip.name && (
          <div className="text-xs font-medium text-gray-500">
            {t('autosaveFrequency')} {bundle.items[0]?.autoShip.name}
          </div>
        )}
      </div>
    </div>
  )
}
