import { Service } from '@nori/di'

import type { CartShareLinkValueObject } from '../../core/share-link.value-object'
import type { CreateShareLinkResponse } from '../types/create-share-link.response'

@Service()
export class CreateShareLinkResponseMapper {
  toCartShareLinkValueObject(
    data: CreateShareLinkResponse
  ): CartShareLinkValueObject {
    return {
      referrerPersonalLink: data.referrer_personal_link,
      urlCode: data.url_code,
    }
  }
}
