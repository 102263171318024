import { getMoneyWithIsoCurrency } from '@nori/app-kit'
import { useTranslations } from '@nori/lang-i18n'

import type { MoneyValueObject } from '@nori/app-kit'

type Props = {
  salePrice?: MoneyValueObject
  retailPrice?: MoneyValueObject
  isCustomerCart: boolean
}

export function RetailPrice({ salePrice, retailPrice, isCustomerCart }: Props) {
  const t = useTranslations('productList.item')
  const salePriceStyle = salePrice?.cents
    ? 'line-through text-gray-500 font-normal'
    : ''

  const containerClass = `ml-8 flex flex-col text-left font-normal max-lg:m-0 lg:w-[4.4rem] ${
    salePrice && !isCustomerCart ? 'lg:h-full' : ''
  } ${isCustomerCart ? 'max-lg:w-1/2' : 'max-lg:w-1/3'}`

  const priceWrapperClass = `flex flex-col max-sm:gap-0.5 ${
    isCustomerCart ? 'max-lg:ml-auto' : 'max-lg:m-auto'
  }`
  const showLabel = !isCustomerCart

  return (
    <div className={containerClass}>
      <div className={priceWrapperClass}>
        {showLabel && (
          <span className="text-xs font-normal text-gray-500">
            {t('retail')}:
          </span>
        )}
        <div
          data-test-id="retail-price"
          className={`text-sm max-sm:font-bold ${salePriceStyle}`}
        >
          {getMoneyWithIsoCurrency(retailPrice)}
        </div>
        {salePrice && (
          <div data-test-id="sale-price" className="text-sm max-sm:font-bold">
            {getMoneyWithIsoCurrency(salePrice)}
          </div>
        )}
      </div>
    </div>
  )
}
