import { PictureMapperService } from '@nori/app-kit'
import { Inject, Service } from '@nori/di'

import type { SkuEntity } from '~/modules/sku/core'

@Service()
export class SkuMapper {
  constructor(
    @Inject() private readonly pictureMapperService: PictureMapperService
  ) {}

  toSkus(data: any): SkuEntity[] {
    return data.map((sku: any) => this.toSku(sku))
  }

  private toSku(data: any): SkuEntity {
    return {
      id: data.id,
      code: data.code,
      name: data.name,
      description: data.description ?? undefined,
      shortDescription: data.short_description ?? undefined,
      images: data.images.map((picture: any) =>
        this.pictureMapperService.toPictureEntity(picture)
      ),
    }
  }
}
