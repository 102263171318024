import { observer } from '@nori/di'
import { useTranslations } from '@nori/lang-i18n'
import { Loader, Modal } from '@nori/ui-kit'
import { SearchContactBlock } from '~/modules/contacts'
import { useContactContext } from '~/modules/contacts/interface/use-contact-context'
import { CreateContactBlock } from '~/modules/contacts/ui/contact-selector/create-contact-block'
import { SelectedContactBlock } from '~/modules/contacts/ui/contact-selector/selected-contact'

import { useCartContactPartyContext } from '../../interface/use-cart-contact-part.context'

export const EditContactModal = observer(() => {
  const t = useTranslations('contactAndParty.editContact')

  const { contactAndPartyAction, cartContactPartyStore } =
    useCartContactPartyContext()
  const { contactSelectorStore } = useContactContext()

  return (
    <Modal
      data-test-id="search-or-create-contact-modal"
      size="2xl"
      isOpen={cartContactPartyStore.editContactToggle.isOpen}
      onClose={contactAndPartyAction.handleCloseContactModal}
    >
      <Modal.Header onClose={contactAndPartyAction.handleCloseContactModal} />
      <Modal.Body>
        <div className="lg:w-full lg:px-7 lg:py-4">
          <h1 className="mb-5 text-center text-lg font-bold text-primary-900">
            {t('addContact')}
          </h1>
          {cartContactPartyStore.isLoading && (
            <Loader viewType="filled-center" />
          )}
          {!cartContactPartyStore.isContactSelected &&
            contactSelectorStore.activeTab === 'search' && (
              <p className="bg-primary-50 p-5 font-bold text-primary-900">
                {t('noContactSelected')}
              </p>
            )}
          <div>
            {contactSelectorStore.activeTab === 'search' && (
              <>
                <SelectedContactBlock
                  contactEmail={cartContactPartyStore.customerEmail}
                  onRemoveContact={() =>
                    contactAndPartyAction.handleContactSelect(undefined)
                  }
                  selectedContact={cartContactPartyStore.customerFullName}
                />
                <div className="mt-5">
                  <SearchContactBlock
                    onSelectContact={(contact) =>
                      contactAndPartyAction.handleContactSelect(contact.id)
                    }
                    isSelfContactAllowed={
                      !contactSelectorStore.isSelfContactSelected
                    }
                    onSelectSelfContact={() =>
                      contactAndPartyAction.handleContactSelect(
                        contactSelectorStore.selfContactId
                      )
                    }
                  />
                </div>
              </>
            )}
            {contactSelectorStore.activeTab === 'create' && (
              <CreateContactBlock
                isSavingLoading={cartContactPartyStore.isCreateContactLoading}
                onSaveContact={contactAndPartyAction.handleCreateContact}
              />
            )}
          </div>

          {contactSelectorStore.activeTab === 'search' && (
            <div
              onClick={() => contactSelectorStore.setActiveTab('create')}
              className="mt-5 cursor-pointer text-sm font-bold text-primary-900"
            >
              <span>+</span>
              <span
                data-test-id="create-contact-span"
                className="ml-1 underline"
              >
                {t('createContact')}
              </span>
            </div>
          )}
        </div>
      </Modal.Body>
    </Modal>
  )
})
