import { useMemo } from 'react';
import { isDate, NoriDate } from '@nori/date';
export function useDateValue(value) {
    return useMemo(()=>{
        if (!value) return [];
        const checkValue = Array.isArray(value) ? value : [
            value
        ];
        return checkValue.map((val)=>new NoriDate(val)).filter((date)=>{
            if (!isDate(date)) return false;
            return !isNaN(date.getTime());
        });
    }, [
        value
    ]);
}
