import { Store } from '@nori/di'
import { UNAUTHORIZED_ROUTES } from '~/modules/root-app/interface/unauthorized-routes'

@Store()
export class AuthGuardStore {
  hasAuthorizedContent(route: string): boolean {
    return !UNAUTHORIZED_ROUTES.some((storedRoute) =>
      route.startsWith(storedRoute)
    )
  }
}
