import { useTranslations } from '@nori/lang-i18n'
import { DropdownMenu } from '@nori/ui-kit'

import type { ProductSkuEntity } from '@nori/app-kit'

type Props = {
  skuVariation?: string
  selectedSku?: ProductSkuEntity
  onSelect: (sku: ProductSkuEntity) => void
  skus?: ProductSkuEntity[]
}

export function SelectSku({
  skus,
  skuVariation,
  selectedSku,
  onSelect,
}: Props) {
  const t = useTranslations('shopForMyself.productList')

  if (!skus) return <></>
  if (skus.length === 1) return <></>

  const title = selectedSku
    ? selectedSku.name
    : `${t('Select')} ${skuVariation || ''}`

  return (
    <div>
      <DropdownMenu hideHelperBlock title={title} size="sm">
        {skus.map((sku) => {
          return (
            <DropdownMenu.Option
              key={sku.id}
              onClick={() => {
                onSelect(sku)
              }}
              selected={selectedSku?.id === sku.id}
            >
              {sku.name}
            </DropdownMenu.Option>
          )
        })}
      </DropdownMenu>
    </div>
  )
}
