import { useTranslations } from '@nori/lang-i18n'

import { CreditItem } from '../credit-item/credit-item'

import type { UserCreditsEntity } from '../../core'

type Props = {
  credits: UserCreditsEntity
  customerId?: number
}

export function PersonalCartCredits({ credits, customerId }: Props) {
  const t = useTranslations('userCredits')
  const shouldDisplayProductCredits = !!customerId
  const shouldDisplayStoreCredits = !!customerId

  return (
    <div className="flex w-full bg-legend-group-5 px-5 py-5 lg:px-10">
      {shouldDisplayProductCredits && (
        <>
          <CreditItem
            title={t('productCredits')}
            subTitle={t('appliedOnCart')}
            credit={credits.productCredits}
            currency={credits.currency}
            data-test-id="product-credits"
          />
        </>
      )}
      <CreditItem
        title={t('itemDiscount')}
        subTitle={t('appliedOnCart')}
        credit={credits.hostessDiscountsHash?.count}
        isWithLine={shouldDisplayProductCredits}
        data-test-id="item-discount"
      />
      {shouldDisplayStoreCredits && (
        <CreditItem
          title={t('storeCredits')}
          subTitle={t('appliedAtCheckout')}
          credit={credits.storeCredits}
          currency={credits.currency}
          isWithLine
          data-test-id="store-credits"
        />
      )}
    </div>
  )
}
