import { observer } from '@nori/di'
import { useTranslations } from '@nori/lang-i18n'
import { Checkbox } from '@nori/ui-kit'

import { useContactContext } from '../../../interface/use-contact-context'

import type { ContactFilterOption } from '~/modules/contacts/core/filters/filter-options'

type Props = {
  options: ContactFilterOption[]
}

export const ContactsFiltersGroup = observer(({ options }: Props) => {
  const { contactFiltersAction } = useContactContext()

  const t = useTranslations()

  return (
    <>
      {options.map((option) => {
        return (
          <div
            key={`${option.filterKey}-${option.value}`}
            className="my-4 ml-1"
          >
            <Checkbox
              value={option.isChecked}
              onChange={() =>
                contactFiltersAction.handleToggleFilter({
                  key: option.filterKey,
                  filterValue: option.value,
                  value: !option.isChecked,
                })
              }
            >
              {t(option.translationKey)}
            </Checkbox>
          </div>
        )
      })}
    </>
  )
})
