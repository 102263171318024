import { useCallback, useMemo, useState } from 'react'

import { useTranslations } from '@nori/lang-i18n'
import { useToggle } from '@nori/ui-kit'
import { isSingleProduct } from '~/modules/autosave/interface/is-single-product'
import { useAutosaveContext } from '~/modules/autosave/interface/use-autosave-context'

import { NextOrderAddonItem } from './next-order-addon-item'

import type { MoneyValueObject } from '@nori/app-kit'
import type { SubscriptionEntity } from '~/modules/subscription'

type Props = {
  subscription: SubscriptionEntity
  retailPrice?: MoneyValueObject
  salePrice?: MoneyValueObject
  consultantPrice?: MoneyValueObject
  totalPrice?: MoneyValueObject
  onRemove: () => void
}

export const NextOrderAddonItemRow = ({
  subscription,
  retailPrice,
  salePrice,
  consultantPrice,
  totalPrice,
  onRemove,
}: Props) => {
  const t = useTranslations('autosaveSettings')

  const { nextOrderAction, nextOrderStore } = useAutosaveContext()
  const editorToggle = useToggle()
  const [itemQuantity, setItemQuantity] = useState(`${subscription.quantity}`)

  const handleSave = useCallback(() => {
    editorToggle.handleClose()
    nextOrderAction.handleUpdateSubscription({
      ids: [subscription.id],
      quantity: Number(itemQuantity),
    })
  }, [editorToggle, nextOrderAction, subscription.id, itemQuantity])

  const productInfo = useMemo(
    () => nextOrderStore.getSubscriptionProductInfo(subscription),
    [nextOrderStore, subscription]
  )

  const styleName = useMemo(
    () =>
      productInfo && isSingleProduct(productInfo)
        ? productInfo.styles.find((style) =>
            style.skus.find((sku) => sku.code === subscription.skuCode)
          )?.name
        : undefined,
    [productInfo, subscription.skuCode]
  )

  return (
    <NextOrderAddonItem
      subscription={subscription}
      retailPrice={retailPrice}
      salePrice={salePrice}
      consultantPrice={consultantPrice}
      totalPrice={totalPrice}
      onRemove={onRemove}
      handleSave={handleSave}
      productInfo={productInfo}
      itemQty={itemQuantity}
      onItemQtyChange={setItemQuantity}
      isEditMode={editorToggle.isOpen}
      onEdit={editorToggle.handleOpen}
      styleName={styleName}
    />
  )
}
