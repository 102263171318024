import { useProfileContext } from '@nori/app-kit'
import { useTranslations } from '@nori/lang-i18n'
import { Icon, Link, Swap, Tooltip } from '@nori/ui-kit'

type Props = {
  onShare?: () => void
}
export function HelperTextBlockShareCart({ onShare }: Props) {
  const t = useTranslations('cart.summary')
  const { userStore } = useProfileContext()

  return (
    <div className="relative">
      <div className="mr-4 max-w-xs items-center font-normal leading-tight">
        <Swap
          is={!userStore.currentUser.isPwsEnabled}
          isSlot={
            <span className={'text-gray-600'}>
              {t('shareCartDisabledText')}{' '}
            </span>
          }
        >
          <>
            <span className={'text-gray-600'}>
              {t('shareCartEnabledText1')}{' '}
            </span>
            &nbsp;
            <Link onClick={onShare} data-test-id={'share-this-cart-link'}>
              <span className={'text-sm font-semibold text-primary-900'}>
                {t('shareThisCart')}{' '}
              </span>
            </Link>
            &nbsp;
            <span className={'text-gray-600'}>
              {t('shareCartEnabledText2')}
            </span>
            <div className="absolute right-0 top-0">
              <Tooltip
                slot={
                  <div className="min-w-32 text-gray-600">
                    {t('helpToolTip')}
                  </div>
                }
                place={'left'}
              >
                <span className="text-gray-400">
                  <Icon name="InformationCircleFill" />
                </span>
              </Tooltip>
            </div>
          </>
        </Swap>
      </div>
    </div>
  )
}
