import { hookContextFactory } from '@nori/di'

import { BirthdayGuardAction } from './action/birthday-guard.action'
import { BirthdayGuardStore } from './store/birthday-guard.store'

export const { useModuleContext: useBirthdayGuardContext } = hookContextFactory(
  {
    birthdayGuardAction: BirthdayGuardAction,
    birthdayGuardStore: BirthdayGuardStore,
  }
)
