import { NoriDate } from '@nori/date'
import { Inject, Store, Toggle } from '@nori/di'
import { getBlock } from '@nori/lang-i18n'
import { CartStore } from '~/modules/cart/interface/store/cart-store/cart.store'
import { PartiesListStore } from '~/modules/parties/interface/stores/parties-list.store'

import type { PartyEntity } from '~/modules/parties/core/entities/party.entity'

@Store()
export class CartContactPartyStore {
  constructor(
    @Inject()
    private readonly cartStore: CartStore,
    private readonly partiesListStore: PartiesListStore
  ) {}

  isLoading = false
  isContactEdit = false
  isCreateContactLoading = false

  editHostessToggle = new Toggle()
  editContactToggle = new Toggle()

  get isCart(): boolean {
    return !!this.cartStore.cart
  }

  get partyId(): number | undefined {
    return this.cartStore.cart?.partyId
  }

  get isAddToMailingList(): boolean {
    return !!this.cartStore.cart?.addToMailingList
  }

  get customerFirstName(): string | undefined {
    return this.cartStore.cart?.customerFirstName
  }
  get customerLastName(): string | undefined {
    return this.cartStore.cart?.customerLastName
  }

  get isEmailProvided(): boolean {
    return this.cartStore.cart?.isCustomerEmailProvided !== false
  }

  get customerEmail(): string | undefined {
    if (this.cartStore.cart?.isCustomerEmailProvided === false) {
      return ''
    }
    return this.cartStore.cart?.customerEmail
  }

  get customerFullName(): string {
    return [this.customerFirstName, this.customerLastName]
      .filter(Boolean)
      .join(' ')
  }

  get hostName(): string {
    const host = this.cartStore.cart?.referrerName
    return getBlock('contactAndParty')('hostName', {
      hostName: host,
    })
  }

  get isEventAdded(): boolean {
    return (
      !!this.cartStore.cart?.partyName || !!this.cartStore.cart?.referrerName
    )
  }

  get partyOrHostessName(): string | undefined {
    if (this.cartStore.cart?.partyName) {
      return this.cartStore.cart.partyName
    }

    if (this.cartStore.cart?.referrerName) {
      return getBlock('contactAndParty.editPartyOrHostess')('hostedBy', {
        hostName: this.cartStore.cart.referrerName,
      })
    }
  }

  get hostessName(): string | undefined {
    return this.cartStore.cart?.referrerName
  }

  get isContactSelected(): boolean {
    return !!this.cartStore.cart?.contactId
  }

  get partyDate(): NoriDate | undefined {
    const value = this.cartStore.cart?.partyAt
    if (!value) return
    return new NoriDate(value)
  }
  get partyAddress(): string {
    const item = this.partiesListStore.partiesList?.filter(
      (x: PartyEntity) => x.id === this.partyId
    )[0]
    if (!item) return ''
    const address = [item?.address?.city, item?.address?.state]
      .filter(Boolean)
      .join(', ')

    return address || getBlock('contactSelector')('online')
  }

  get eventName(): string | undefined {
    if (this.cartStore.cart?.partyName) {
      return this.cartStore.cart?.partyName
    }

    if (this.cartStore.cart?.referrerName) {
      return this.cartStore.cart?.referrerName
    }
  }

  setIsContactEdit(value: boolean): void {
    this.isContactEdit = value
  }

  setIsLoading(value: boolean): void {
    this.isLoading = value
  }

  setIsCreateContactLoading(value: boolean): void {
    this.isCreateContactLoading = value
  }
}
