import _async_to_generator from "@swc/helpers/src/_async_to_generator.mjs";
export function copyToClipboardAsync(text) {
    return _copyToClipboardAsync.apply(this, arguments);
}
function _copyToClipboardAsync() {
    _copyToClipboardAsync = _async_to_generator(function*(text) {
        try {
            const clipboardPermission = yield navigator.permissions.query({
                name: 'clipboard-write'
            });
            if (clipboardPermission.state === 'granted' || clipboardPermission.state === 'prompt') {
                yield navigator.clipboard.writeText(text);
                return true;
            }
            return false;
        } catch (e) {
            return false;
        }
    });
    return _copyToClipboardAsync.apply(this, arguments);
}
