import { useMemo } from 'react'

import { getMoneyWithIsoCurrency } from '@nori/app-kit'

import type { ContactListItemEntity } from '~/modules/contacts/core'

export function useContactRewards(contact: ContactListItemEntity) {
  const { isPreferredDsr } = contact

  return useMemo(() => {
    if (!isPreferredDsr)
      return {
        norwexDiscounts: '--',
        itemsOff: '--',
        freeShipping: '--',
      }

    const norwexDiscounts = getMoneyWithIsoCurrency({
      cents: contact.totalRewardsCredits.cents,
      currency: contact.totalRewardsCredits.currency,
    })

    return {
      norwexDiscounts,
      itemsOff: contact.hostessDiscounts,
      freeShipping: contact.hasFreeShipping ? 'Y' : 'N',
    }
  }, [contact, isPreferredDsr])
}
