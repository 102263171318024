import { LocalStorageItem, UserBrowserDataService } from '@nori/app-kit'
import { Service } from '@nori/di'

import type { UserBrowserDataServiceInterface } from '@nori/app-kit'

@Service({ id: UserBrowserDataService })
export class RewriteUserBrowserDataService
  implements UserBrowserDataServiceInterface
{
  private storageItem = LocalStorageItem('@nori/check', {
    initialValue: undefined,
  })

  handleClearUserData(): void {
    this.storageItem.removeAll()
    window.location.reload()
  }
}
