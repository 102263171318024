import { ALL_DOMAINS } from '~/core/all-domains.constants'

const IS_APPCUES_ENABLED =
  process.env.NEXT_PUBLIC_APPCUES_ENABLED === undefined
    ? false
    : JSON.parse(String(process.env.NEXT_PUBLIC_APPCUES_ENABLED))

export function isEnabled(): boolean {
  if (typeof window === 'undefined') return false

  return (
    ALL_DOMAINS.some((item) => window.location.host.includes(item)) ||
    IS_APPCUES_ENABLED
  )
}
