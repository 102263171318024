import { errorFactory } from '@nori/errors'

import type { KeyOfErrors } from '@nori/errors'

export const { CartErrors } = errorFactory('CartErrors', {
  BadRequest: 'Bad Request',
  NotFound: 'Not Found',
  UnproceccedEntity: 'UnproceccedEntity',
  UnexpectedError: 'Unexpected Error',
  UnknownError: 'Unknown Error',

  BadRequestChangeCartShippingAddress:
    'Bad Request Change Cart Shipping Address',
  NotFoundChangeCartShippingAddress: 'Not Found Change Cart Shipping Address',
  IncorrectShippingAddress: 'Incorrect shipping address',
  UnexpectedErrorChangeCartShippingAddress:
    'Unexpected Error Change Cart Shipping Address',
  UnknownErrorChangeCartShippingAddress:
    'Unknown Error Change Cart Shipping Address',

  BadRequestApplyStoreCredits: 'Bad Request Apply Store Credits',
  NotFoundApplyStoreCredits: 'Not Found Apply Store Credits',
  UnexpectedErrorApplyStoreCredits: 'Unexpected Error Apply Store Credits',
  UnknownErrorApplyStoreCredits: 'Unknown Error Apply Store Credits',

  BadRequestApplyProductCredits: 'Bad Request Apply Store Credits',
  NotFoundApplyProductCredits: 'Not Found Apply Store Credits',
  UnprocessableEntityApplyProductCredits: 'Available user credits are exceeded',
  UnexpectedErrorApplyProductCredits: 'Unexpected Error Apply Store Credits',
  UnknownErrorApplyProductCredits: 'Unknown Error Apply Store Credits',

  BadRequestApplyHostessCredits: 'Bad Request Apply Hostess Credits',
  NotFoundApplyHostessCredits: 'Not Found Apply Hostess Credits',
  UnexpectedErrorApplyHostessCredits: 'Unexpected Error Apply Hostess Credits',
  UnknownErrorApplyHostessCredits: 'Unknown Error Apply Hostess Credits',

  UnknownCartTaxError: 'Unknown Cart Tax Error',
  UnexpectedCartTaxError: 'Unexpected Cart Tax Error',
  TaxIsNotCalculatedError: 'Tax is not calculated for cart',

  UnknownCartTotalError: 'Unknown Cart Total Error',
  UnexpectedCartTotalError: 'Unexpected Cart Total Error',

  AddManyToCartUnknownError: 'Unknown Error While Adding Many Items to Cart',
  UnexpectedAddManyToCartError:
    'Unexpected Error While Adding Many Items To Cart',

  DeleteCartUnknownError: 'Unknown Error While Deleting Cart',
  UnexpectedDeleteCartError: 'Unexpected Error While Deleting Cart',

  BadRequestApplyHostessDiscount: 'Bad Request Apply Hostess Discount',
  NotFoundApplyHostessDiscount: 'Not Found Apply Hostess Discount',
  UnexpectedErrorApplyHostessDiscount:
    'Unexpected Error Apply Hostess Discount',
  UnprocessableEntityApplyHostessDiscount:
    'Unprocessable Entity Apply Hostess Discount',
})

export type CartErrorInstance = InstanceType<KeyOfErrors<typeof CartErrors>>
