import { errorFactory } from '@nori/errors'

import type { KeyOfErrors } from '@nori/errors'

export const { ContactErrors } = errorFactory('ContactErrors', {
  BadRequest: 'Bad Request',
  NotFound: 'Not Found',
  UnprocessedEntity: 'UnprocessedEntity',
  UnexpectedError: 'Unexpected Error',
  UnexpectedCreateMinimumError:
    'Unexpected Error while creating contact with minimum params',
  UnknownError: 'Unknown Error',
  DeleteForbiddenError: 'Delete Forbidden Error',

  GetContactById: 'Error while getting contact by id',
  UnexpectedGetContactById: 'Unexpected Error while getting contact by id',

  GetContacts: 'Error while getting contacts',
  UnexpectedGetContacts: 'Unexpected Error while getting contacts',

  GetContactWishlist: 'Error while getting contact wishlist',
  UnexpectedGetContactWishlist:
    'Unexpected Error while getting contact wishlist',

  UnexpectedFindContactByDsrAndCustomerIds:
    'Unexpected Error while finding contact by dsr and customer ids',

  ValidationError: 'Validation Error',

  RequestAborted: 'Request Aborted',

  EditAndDeleteForbiddenError:
    'Edit and delete are not allowed for this contact',
  SetContactsToReceiveCatalogUnexpectedError:
    'Unexpected Error while setting contacts to receive catalog',
})

export type ContactErrorInstance = InstanceType<
  KeyOfErrors<typeof ContactErrors>
>
