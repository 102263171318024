import { UserCreditsErrors } from '../../core'

import type { UserCreditsErrorInstance } from '../../core'

export class UserCreditsErrorMapper {
  static toErrors(status: number, json: any): UserCreditsErrorInstance {
    switch (status) {
      case 400:
        return new UserCreditsErrors.BadRequest(json)

      case 404:
        return new UserCreditsErrors.NotFound(json)

      default:
        return new UserCreditsErrors.UnknownError(json)
    }
  }
}
