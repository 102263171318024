import { DataState, Store } from '@nori/di'

import type { PartyEntity } from '../../core/entities/party.entity'
import type { PartiesListErrorInstance } from '../../core/errors/parties-list.errors'

@Store()
export class ContactPartiesListStore {
  contactPartyList = new DataState<PartyEntity[], PartiesListErrorInstance>({
    isLoading: false,
  })

  searchQuery = ''

  setSearchQuery(searchQuery: string): void {
    this.searchQuery = searchQuery
  }
}
