import { ProductEndpointService } from '@nori/app-kit'
import { Service } from '@nori/di'

import type { ProductEndpointServiceInterface } from '@nori/app-kit'

@Service({ id: ProductEndpointService })
class RewriteProductEndpointService implements ProductEndpointServiceInterface {
  getStyleDetailsEndpoint(params: { baseUrl: string; id: string }): string {
    const query = new URLSearchParams()
    query.append('_', `${Date.now()}`)
    query.append('purchasable_override', 'false')
    query.append('pricing_cohort', '0')
    const url = `${params.baseUrl}/products/${
      params.id
    }.json?${query.toString()}`

    return url
  }

  getBundleDetailsEndpoint(params: { baseUrl: string; id: string }): string {
    const query = new URLSearchParams()
    query.append('_', `${Date.now()}`)
    query.append('purchasable_override', 'false')
    const url = `${params.baseUrl}/bundles/${params.id}?${query.toString()}`

    return url
  }
}
