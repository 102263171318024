import { useMemo } from 'react'

import {
  getLink,
  getMoneyWithIsoCurrency,
  useProfileContext,
} from '@nori/app-kit'
import { format } from '@nori/date'
import { useTranslations } from '@nori/lang-i18n'
import { Icon, Link, Swap } from '@nori/ui-kit'
import { useContactContext } from '~/modules/contacts/interface/use-contact-context'
import { Routes } from '~/modules/routes'

import { Separator } from './separator'
import { SummaryItem } from './summary-item'

type Props = {
  partyAt?: Date
  openOn?: Date
  closedOn?: Date
  referralOrdersCount: number
  totalRetailSalesCents: number
  totalReferralCreditsEarned: number
  partyId: number
  currency: string
  partyPath: string
  type: 'booked' | 'upcoming'
}

export const EventSummary = ({
  partyAt,
  openOn,
  closedOn,
  referralOrdersCount,
  totalRetailSalesCents,
  totalReferralCreditsEarned,
  partyId,
  currency,
  partyPath,
  type,
}: Props) => {
  const t = useTranslations('contactDetailsPage.norwexPerks')

  const { contactDetailsStore } = useContactContext()
  const { userStore } = useProfileContext()
  const { isOSEnabled } = userStore

  const { isEventOpen, eventLink } = useMemo(() => {
    const result = { isEventOpen: false, eventLink: '' }
    if (!openOn || !closedOn) return result

    result.isEventOpen = new Date() > new Date(openOn)
    result.eventLink = getLink({ type: 'bo', personalLink: partyPath }).url
    return result
  }, [openOn, closedOn, partyPath])

  return (
    <div className="bg-primary-50 p-5 lg:p-10">
      <div className="text-lg font-bold text-black">
        {t(`eventSummary.${type}Event`)}
      </div>
      {contactDetailsStore.rewards.data?.referralEarningPeriod
        .periodExtended && (
        <div className="text-sm font-normal text-primary-700">
          {t('eventSummary.subTitle')}
        </div>
      )}
      <div className="mt-4 w-full items-center justify-between bg-white p-4 lg:flex">
        <div className="flex items-start gap-5 lg:items-center">
          <div className="flex min-h-[74px] min-w-[74px] flex-col justify-center rounded-full bg-primary-900 text-center text-lg font-bold text-white">
            {partyAt && (
              <div className="capitalize">{format(partyAt, 'MMM')}</div>
            )}
            {partyAt && <div>{format(partyAt, 'dd')}</div>}
          </div>
          <div className="text-sm text-gray-500 max-lg:w-full">
            <div className="flex justify-between text-lg font-bold text-primary-900 lg:block">
              {partyAt && <div>{format(partyAt, 'hh:mm aa')}</div>}
              <div className="text-primary-900 lg:hidden">
                <Icon name="ChevronRightThin" size="md" />
              </div>
            </div>
            {openOn && (
              <div>
                {t('eventSummary.open')}: {format(openOn, 'MM/dd/yyyy')}
              </div>
            )}
            {closedOn && (
              <div>
                {t('eventSummary.closed')}: {format(closedOn, 'MM/dd/yyyy')}
              </div>
            )}

            <Swap
              is={!!eventLink && isEventOpen}
              isSlot={
                <Link
                  data-test-id="party-url-title"
                  href={eventLink}
                  target="_blank"
                  weight="bold"
                  size="large"
                >
                  {t('eventSummary.eventLink')}
                </Link>
              }
            >
              {isOSEnabled && <div>{t('eventSummary.willBeAvailable')}</div>}
            </Swap>
          </div>
        </div>
        <div className="mt-5 h-full items-center gap-4 text-sm text-black lg:flex">
          <SummaryItem
            label={t('summaryItem.totalOrders')}
            value={String(referralOrdersCount)}
          />
          <Separator />
          <SummaryItem
            label={t('summaryItem.referralSales')}
            value={getMoneyWithIsoCurrency({
              cents: totalRetailSalesCents,
              currency,
            })}
          />
          <Separator />
          <SummaryItem
            label={t('summaryItem.earnedCredit')}
            value={getMoneyWithIsoCurrency({
              cents: totalReferralCreditsEarned,
              currency,
            })}
          />
          <div className="ml-1 hidden text-primary-900 lg:block">
            <a
              rel="noreferrer"
              href={Routes.events.view.path(partyId)}
              target="_blank"
            >
              <Icon name="ChevronRightThin" size="md" />
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}
