import { ColumnSelectionModal, ExportAll } from '@nori/app-kit'
import { observer } from '@nori/di'
import { useTranslations } from '@nori/lang-i18n'
import {
  Checkbox,
  Divider,
  Dropdown,
  Icon,
  Input,
  Link,
  Swap,
  useDebounce,
  useToggle,
} from '@nori/ui-kit'
import { useContactContext } from '~/modules/contacts/interface/use-contact-context'
import { useFeatureFlagsContext } from '~/modules/feature-flags'

import { useContactManageMailingContext } from '../../interface/use-contact-manage-mailing-context'
import { ContactManageMailingModal } from '../contact-manage-mailing'

import { AppliedFilters } from './contact-filters/applied-filters'
import { ContactsFilters } from './contact-filters/contacts-filters'

import type { ContactListColumnNamesValueObject } from '../../core/contact-list-column-names.value-object'

const PER_PAGE_OPTIONS = [
  { label: '20', value: 20 },
  { label: '50', value: 50 },
  { label: '100', value: 100 },
]

export const ContactHeaderControls = observer(() => {
  const {
    contactsStore,
    contactsAction,
    contactFiltersStore,
    contactFiltersAction,
    contactsExportsAction,
  } = useContactContext()
  const t = useTranslations()

  const toggleColumnsModal = useToggle()

  const { onChangeValue, value } = useDebounce({
    onChangeDebounce: contactsAction.handleSearchQueryChange,
    initialValue: contactsStore.searchQuery,
  })
  const { contactManageMailingAction, сontactManageMailingStore } =
    useContactManageMailingContext()
  const { featureFlagsStore } = useFeatureFlagsContext()

  const isManageCatalogMailingEnabled = featureFlagsStore.getFlag(
    'manage-catalog-mailing'
  )

  const selectedLabel =
    contactsStore.selectedContactIds.size > 0
      ? ` (${contactsStore.selectedContactIds.size})`
      : ''

  return (
    <>
      <div className="mt-5 flex items-center max-md:mb-3 lg:w-3/5">
        <Input
          data-test-id={'search-contact-input'}
          type="search"
          placeholder={t('contacts.list.searchInputPlaceholder')}
          value={value}
          onChange={onChangeValue}
          hideHelperBlock
        />
        <div className="max-md:items-end max-md:pl-5 lg:ml-6 lg:mt-0">
          <ContactsFilters />
        </div>
      </div>

      {contactFiltersStore.isAnyFilterApplied && (
        <>
          <div className="mb-4 mt-7 ">
            <AppliedFilters />
          </div>
          <div className="lg:hidden">
            <Link
              onClick={contactFiltersAction.handleClearAllAppliedFilters}
              iconLeft="CloseLine"
              weight="bold"
              data-test-id="clear-all-filters-link"
              size="medium"
            >
              {t('contacts.filters.clearFilter')}
            </Link>
          </div>
        </>
      )}

      <div className="mt-10 flex flex-col justify-between max-md:mb-0 max-md:mt-0 max-md:flex-col-reverse lg:flex-row lg:items-center">
        <div className="flex items-center justify-between font-bold text-primary-900 max-md:justify-start lg:justify-start">
          <div className="flex items-center">
            <Checkbox
              value={contactsStore.isAllContactsSelected}
              onChange={contactsAction.handleToggleSelectAllContacts}
            >
              <span className="max-md:hidden">
                {t('contacts.list.selectAll')}
              </span>
              {!!selectedLabel && selectedLabel}
            </Checkbox>
          </div>

          {contactsStore.selectedContactIds.size > 0 && (
            <div className="ml-5 flex items-center">
              <Link
                size="medium"
                weight="bold"
                iconLeft="MailOutline"
                isUnderline={false}
                onClick={contactsAction.handleEmailSelectedContacts}
              >
                {t('contacts.list.emailSelected')}
              </Link>
            </div>
          )}

          {contactFiltersStore.isAnyFilterApplied && (
            <div className="hidden items-center lg:flex">
              <div className="mx-3 h-4 w-px bg-primary-900" />
              <Divider />
              <Link
                onClick={contactFiltersAction.handleClearAllAppliedFilters}
                iconLeft="CloseLine"
                weight="bold"
                data-test-id="clear-all-filters-link"
                size="medium"
              >
                {t('contacts.filters.clearFilter')}
              </Link>
            </div>
          )}
        </div>

        <div className="mt-4 flex items-center justify-between max-md:mb-5 max-md:items-baseline lg:mt-0 lg:items-baseline lg:justify-start">
          <Swap is={!isManageCatalogMailingEnabled} isSlot={<></>}>
            <div
              className="mr-10 flex cursor-pointer items-center justify-center text-primary-900"
              onClick={
                contactManageMailingAction.handleToggleContactManageMailingModal
              }
            >
              <Icon name="BookOpen" size="s" />
              <span className="ml-1 text-sm font-bold max-md:w-[119px]">
                {t('contacts.manageCatalogMailing')}
              </span>
            </div>
          </Swap>
          <div className="mr-8 max-md:hidden">
            <ExportAll
              onClick={contactsExportsAction.handleExportAll}
              type="contacts"
            />
          </div>
          <Swap
            is={!contactsStore.tableCount.total}
            isSlot={
              <span className="text-xxs text-gray-500 lg:text-sm">
                {t('contacts.filters.noContacts')}
              </span>
            }
          >
            <div className="flex flex-auto justify-stretch gap-2 max-md:flex-col-reverse max-md:items-end lg:items-center">
              <span className="text-xxs  text-gray-500 lg:text-sm">
                {t('basic.tableInfoMessage', {
                  firstItem: contactsStore.tableCount.firstItem,
                  lastItem: contactsStore.tableCount.lastItem,
                  total: contactsStore.tableCount.total,
                })}
              </span>
              <div className="w-[86px] flex-shrink-0 max-md:w-40">
                <Dropdown
                  hideHelperBlock
                  label={t('basic.tableItemsPerPage')}
                  title={contactsStore.pagination?.perPage}
                  size="small"
                >
                  {PER_PAGE_OPTIONS.map((option) => (
                    <Dropdown.Option
                      onClick={() =>
                        contactsAction.handleChangePerPage(option.value)
                      }
                      key={option.value}
                    >
                      {option.label}
                    </Dropdown.Option>
                  ))}
                </Dropdown>
              </div>
            </div>
          </Swap>

          <div className="max-md:hidden">
            <button
              onClick={toggleColumnsModal.handleOpen}
              className="ml-2 flex items-center text-xs font-bold text-primary-900 lg:text-sm"
            >
              <span>{t('contacts.list.customizeView')}</span>
              <Icon name="ChevronDown" />
            </button>
            <ColumnSelectionModal<ContactListColumnNamesValueObject>
              direction="right"
              onClose={toggleColumnsModal.handleClose}
              isOpen={toggleColumnsModal.isOpen}
              value={contactsStore.availableColumns}
              onApply={(value) => {
                contactsAction.handleApplyColumnsVisibility(value)
                toggleColumnsModal.handleClose()
              }}
            />
            <ContactManageMailingModal />
          </div>
        </div>
      </div>
    </>
  )
})
