import _async_to_generator from "@swc/helpers/src/_async_to_generator.mjs";
import { copyToClipboardAsync } from './copy-to-clipboard-async';
import { copyToClipboardFallback } from './copy-to-clipboard-fallback';
export function copyToClipboard(text) {
    return _copyToClipboard.apply(this, arguments);
}
function _copyToClipboard() {
    _copyToClipboard = _async_to_generator(function*(text) {
        let isCopied = false;
        try {
            isCopied = yield copyToClipboardAsync(text);
            if (!isCopied) {
                isCopied = copyToClipboardFallback(text);
            }
            return isCopied;
        } catch (e) {
            return isCopied;
        }
    });
    return _copyToClipboard.apply(this, arguments);
}
