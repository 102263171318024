import {
  FilterQueryParams,
  getColumnVisibility,
  getTableCount,
  LocalStorageItem,
} from '@nori/app-kit'
import { Inject, Store } from '@nori/di'
import { FeatureFlagsStore } from '~/modules/feature-flags/interface/store/feature-flags.store'

import type {
  PaginationValueObject,
  TableCountValueObject,
} from '@nori/app-kit'
import type { SortDirectionValueObject } from '~/core'
import type { OrderEntity } from '../../core'
import type { OrderListColumnNamesValueObject } from '../../core/order-list-column-names.value-object'
import type { OrderListColumnsSelectionMapValueObject } from '../../core/order-list-columns-selection-map.value-object'
import type { OrderListSortBy } from '../../core/order-list-sort-by'
import type { OrdersListErrorInstance } from '../../core/orders-list.errors'
import type { SearchFilterKeyValueObject } from '../../core/search-filter-key.value-object'

const LS_COLUMNS_ID_KEY = '@nori/orders-list-columns'

type LocalStorageValue = Record<OrderListColumnNamesValueObject, boolean>

const DEFAULT_SORT_BY = 'placed_at'

const DEFAULT_SORT_ORDER = 'desc'

@Store()
export class OrdersListStore {
  constructor(
    @Inject() private readonly featureFlagsStore: FeatureFlagsStore
  ) {}

  isLoading = false
  isLoadingNextPage = false
  error?: OrdersListErrorInstance = undefined

  searchFilterKey: SearchFilterKeyValueObject = 'customer_email_order_id_sku'

  partyId?: number = undefined

  sortBy: OrderListSortBy = DEFAULT_SORT_BY
  sortOrder: SortDirectionValueObject = DEFAULT_SORT_ORDER

  ordersList: OrderEntity[] = []
  pagination?: PaginationValueObject = undefined

  queryFilters = new FilterQueryParams({
    query: {
      type: 'string',
      defaultValue: '',
    },
  })

  private selectedColumns = LocalStorageItem<Partial<LocalStorageValue>>(
    LS_COLUMNS_ID_KEY,
    {
      initialValue: {
        earnings: false,
        // eslint-disable-next-line camelcase
        earned_redeemed: false,
      },
    }
  )

  get tableCells(): {
    id: OrderListColumnNamesValueObject
    key: IntlMessageKeys
    sort?: OrderListSortBy
    isDisabled?: boolean
  }[] {
    return [
      {
        id: 'customer',
        key: 'orders.list.table.header.customer',
        isDisabled: true,
      },
      {
        id: 'date',
        key: 'orders.list.table.header.date',
        sort: 'placed_at',
        isDisabled: true,
      },
      {
        id: 'order_number',
        key: 'orders.list.table.header.orderNo',
        sort: 'public_order_id.integer',
      },
      { id: 'type', key: 'orders.list.table.header.type' },
      {
        id: 'order_total',
        key: 'orders.list.table.header.orderTotal',
        sort: 'total_cents',
      },
      { id: 'retail_sales', key: 'orders.list.table.header.retailSales' },
      {
        id: 'total_discount_earned',
        key: 'orders.list.table.header.totalDiscountEarned',
      },
      {
        id: 'shop_offer_fee',
        key: 'orders.list.table.header.shopOfferFee',
      },
      { id: 'earnings', key: 'orders.list.table.header.accountBalance' },
      {
        id: 'earned_redeemed',
        key: 'orders.list.table.header.dsrDiscountApplied',
      },
      { id: 'status', key: 'orders.list.table.header.status' },
      {
        id: 'social',
        key: 'orders.list.table.header.eventOrHost',
        sort: 'party_name',
      },
      {
        id: 'move',
        key: 'orders.list.table.header.move',
      },
    ]
  }
  getColVisibility(key: OrderListColumnNamesValueObject): boolean {
    if (
      key === 'move' &&
      !this.featureFlagsStore.getFlag('bo-move-order-enabled')
    )
      return false

    return getColumnVisibility(this.selectedColumns, key, this.tableCells)
  }

  get totalVisibleColumns(): number {
    return Object.keys(this.filteredColsNames).length
  }

  get filteredColsNames(): OrderListColumnNamesValueObject[] {
    const keys = Object.keys(
      this.filteredColumns
    ) as OrderListColumnNamesValueObject[]
    return keys.reduce((acc, key) => {
      const node = this.filteredColumns[key]
      if (node.isChecked) return [...acc, key]
      return acc
    }, [] as OrderListColumnNamesValueObject[])
  }

  get filteredColumns(): OrderListColumnsSelectionMapValueObject {
    return this.tableCells.reduce((acc, cell) => {
      if (
        cell.id === 'move' &&
        !this.featureFlagsStore.getFlag('bo-move-order-enabled')
      ) {
        return acc
      }

      return {
        ...acc,
        [cell.id]: {
          translationKey: cell.key,
          isChecked: this.getColVisibility(cell.id),
          isDisabled: cell.isDisabled,
        },
      }
    }, {} as OrderListColumnsSelectionMapValueObject)
  }

  applyColumnsVisibility(value: OrderListColumnsSelectionMapValueObject): void {
    const keys = Object.keys(value) as OrderListColumnNamesValueObject[]
    const visibilityMap = keys.reduce((acc, key) => {
      return {
        ...acc,
        [key]: value[key].isChecked,
      }
    }, {} as LocalStorageValue)

    this.selectedColumns.set(visibilityMap)
  }

  getSortOrderField(
    sort?: OrderListSortBy
  ): SortDirectionValueObject | undefined {
    if (sort === this.sortBy) return this.sortOrder
    return undefined
  }

  setSortBy(value?: OrderListSortBy): void {
    this.sortBy = value ? value : DEFAULT_SORT_BY
  }

  setSortOrder(value?: SortDirectionValueObject): void {
    this.sortOrder = value ? value : DEFAULT_SORT_ORDER
  }

  setPartyId(value?: number | null): void {
    this.partyId = value ?? undefined
  }

  get tableCount(): TableCountValueObject {
    return getTableCount(this.pagination)
  }

  setOrdersList(orders: OrderEntity[]): void {
    this.ordersList = orders
  }

  setPagination(pagination: PaginationValueObject): void {
    this.pagination = pagination
  }

  setIsLoading(value: boolean): void {
    this.isLoading = value
  }

  setError(value?: OrdersListErrorInstance): void {
    this.error = value
  }

  resetFilters(): void {
    this.setPartyId()
    this.setSortBy()
    this.setSortOrder()
  }

  get isPartyDetailsPage(): boolean {
    return this.partyId !== undefined
  }
}
