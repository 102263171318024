import { type PropsWithChildren, useCallback, useState } from 'react'

import { observer } from '@nori/di'
import { useTranslations } from '@nori/lang-i18n'
import { Button, useToggle } from '@nori/ui-kit'
import { useAutosaveContext } from '~/modules/autosave/interface/use-autosave-context'
import { AddItemProductSubscription } from '~/modules/product-subscription/ui/add-item-product-subscription'

import type { CommonBundleEntity, CommonStyleEntity } from '@nori/app-kit'

export const Row = observer(
  ({
    item,
    children,
  }: PropsWithChildren<{ item: CommonStyleEntity | CommonBundleEntity }>) => {
    const t = useTranslations('basic')
    const { nextOrderAddItemStore, nextOrderStore, nextOrderAddItemAction } =
      useAutosaveContext()
    const subscriptionToggle = useToggle(item.canBeAutoShipped)
    const [selectedSubscriptionId, setSelectedSubscriptionId] = useState(
      item.defaultProductSubscription.id
    )

    const handleAddItem = (item: CommonStyleEntity | CommonBundleEntity) => {
      const oneOff = !subscriptionToggle.isOpen
      const cycleId = subscriptionToggle.isOpen ? selectedSubscriptionId : 0
      const addData = {
        item,
        oneOff,
        cycleId,
        nextCartAt: nextOrderStore.getNextOrderPopupDate(item.id),
      }
      nextOrderAddItemAction.handleAddItem(addData)
    }
    return (
      <div className="border-b border-gray-200 py-6">
        <div className="flex justify-between">{children}</div>
        {!!item.canBeAutoShipped && (
          <div className="mt-6">
            <AddItemProductSubscription
              subscriptions={item.productSubscriptions}
              selectedSubscriptionId={selectedSubscriptionId}
              isSubscriptionSelected={subscriptionToggle.isOpen}
              onSubscriptionOptionChange={(id: number) =>
                setSelectedSubscriptionId(id)
              }
              onSubscriptionOpen={subscriptionToggle.handleOpen}
              onSubscriptionClose={subscriptionToggle.handleClose}
              isNextOrderDateIsDifferent={nextOrderStore.getIsItemDateDifferentFromNextOrderDate(
                item.id
              )}
              itemId={item.id}
              isCanBeAutoshipped={item.canBeAutoShipped}
            />
          </div>
        )}
        <div className="mt-7">
          <Button
            onClick={() => handleAddItem(item)}
            isLoading={!!nextOrderAddItemStore.isLoadingById[item.id]}
            size="lg"
            rounded="unset"
            widthType="full"
          >
            {t('add')}
          </Button>
        </div>
      </div>
    )
  }
)
