import { type PropsWithChildren, useCallback, useState } from 'react'

import { observer } from '@nori/di'
import { useTranslations } from '@nori/lang-i18n'
import { Button, TableCell, TableRow, useToggle } from '@nori/ui-kit'
import { useAutosaveContext } from '~/modules/autosave/interface/use-autosave-context'
import { AddItemProductSubscription } from '~/modules/product-subscription/ui/add-item-product-subscription'

import type { CommonBundleEntity, CommonStyleEntity } from '@nori/app-kit'

export const Row = observer(
  ({
    item,
    children,
  }: PropsWithChildren<{ item: CommonStyleEntity | CommonBundleEntity }>) => {
    const t = useTranslations('basic')
    const { nextOrderAddItemStore, nextOrderStore, nextOrderAddItemAction } =
      useAutosaveContext()
    const subscriptionToggle = useToggle(item.canBeAutoShipped)
    const [selectedSubscriptionId, setSelectedSubscriptionId] = useState(
      item.defaultProductSubscription.id
    )
    const handleAddItem = (item: CommonStyleEntity | CommonBundleEntity) => {
      const oneOff = !subscriptionToggle.isOpen
      const cycleId = subscriptionToggle.isOpen ? selectedSubscriptionId : 0
      const addData = {
        item,
        oneOff,
        cycleId,
        nextCartAt: subscriptionToggle.isOpen
          ? nextOrderStore.getNextOrderPopupDate(item.id)
          : undefined,
      }
      nextOrderAddItemAction.handleAddItem(addData)
    }
    return (
      <>
        <TableRow>{children} </TableRow>
        {!!item.canBeAutoShipped && (
          <TableRow>
            <TableCell colSpan={3} noBorder>
              <div className="mt-6">
                <AddItemProductSubscription
                  subscriptions={item.productSubscriptions}
                  selectedSubscriptionId={selectedSubscriptionId}
                  isSubscriptionSelected={subscriptionToggle.isOpen}
                  onSubscriptionOptionChange={(id: number) =>
                    setSelectedSubscriptionId(id)
                  }
                  isCanBeAutoshipped={item.canBeAutoShipped}
                  onSubscriptionOpen={subscriptionToggle.handleOpen}
                  onSubscriptionClose={subscriptionToggle.handleClose}
                  itemId={item.id}
                  isNextOrderDateIsDifferent={nextOrderStore.getIsItemDateDifferentFromNextOrderDate(
                    item.id
                  )}
                />
              </div>
            </TableCell>
          </TableRow>
        )}
        <TableRow>
          <TableCell colSpan={3}>
            <div className="mt-2 flex justify-end">
              <div className="w-full">
                <Button
                  onClick={() => handleAddItem(item)}
                  isLoading={!!nextOrderAddItemStore.isLoadingById[item.id]}
                  size="xl"
                  rounded="unset"
                  widthType="full"
                >
                  {t('add')}
                </Button>
              </div>
            </div>
          </TableCell>
        </TableRow>
      </>
    )
  }
)
