import { type CartEntity, centsToMainUnit } from '@nori/app-kit'
import { Service } from '@nori/di'
import { type ProductListValueObject } from '~/modules/products'

import type {
  CartItemsUnion,
  CommonBundleEntity,
  CommonStyleEntity,
} from '@nori/app-kit'
import type { ConfirmedOrderEntity } from '~/modules/checkout/core/confirmed-order.entity'
import type { ProductListItem } from '~/modules/products'
import type {
  TrackData,
  TrackItem,
} from '../../core/entities/track-data.entity'

function checkIsNotProductArray(
  product: ProductListItem
): product is CommonBundleEntity | CommonStyleEntity {
  return !Array.isArray(product)
}

@Service()
export class TrackMapper {
  toTrackDataItemFromConfirmedOrderEntity(
    confirmedOrderData: ConfirmedOrderEntity
  ): TrackData['ecommerce'] {
    return {
      transaction_id: confirmedOrderData.publicOrderId,
      currency: confirmedOrderData.details.totalPrice.currency,
      value: centsToMainUnit({
        cents: confirmedOrderData.details.totalPrice.cents,
      }),
      shipping: centsToMainUnit({
        cents: confirmedOrderData.details.shippingTotal?.cents || 0,
      }),
      tax: centsToMainUnit({
        cents: confirmedOrderData.details.tax?.cents || 0,
      }),
      items: this.toTrackDataFromCartItemUnion(
        confirmedOrderData.details.items
      ),
    }
  }

  toTrackDataFromCartItemUnion(items: CartItemsUnion[]): TrackItem[] {
    return items.map((item) => {
      const isBundle = item.type !== 'sku'

      return {
        product_id: item.id,
        sku_id: !isBundle ? item.skuId : undefined,
        item_name: isBundle ? item.name : item.productName,
        item_brand: item.brandName,
        price: centsToMainUnit({ cents: item.price?.cents || 0 }),
        is_bundle: isBundle,
      }
    })
  }

  toTrackDataFromCartEntity(cart: CartEntity): TrackData['ecommerce'] {
    return {
      currency: cart.total?.currency,
      value: centsToMainUnit({ cents: cart.total?.cents || 0 }),
      items: cart.items.map((cartItem) => {
        const isBundle = cartItem.type === 'bundle'

        return {
          product_id: cartItem.id,
          is_bundle: isBundle,
          item_name: isBundle ? cartItem.name : cartItem.productName,
          item_brand: cartItem.brandName,
          quantity: cartItem.quantity,
          price: centsToMainUnit({ cents: cartItem.price?.cents || 0 }),
          sku_id: !isBundle ? cartItem.skuId : undefined,
        }
      }),
    }
  }

  toTrackDataItemsFromProductListValueObject(
    list: ProductListValueObject
  ): TrackData['ecommerce']['items'] {
    return list.items.filter(checkIsNotProductArray).map((item) => {
      const isBundle = item.typeCode === 'bundle'
      const price = isBundle
        ? item.maxPrice
        : item.skus?.[0]?.currentRetailPrice

      return {
        product_id: isBundle ? item.id : item.productId,
        is_bundle: isBundle,
        item_name: isBundle ? item.name : item.product.name,
        item_brand: isBundle ? item.brandName : item.product.brandName,
        price: centsToMainUnit({ cents: price?.cents || 0 }),
        currency: price?.currency,
      }
    })
  }
}
