import type {
  BundleAddToCartInput,
  BundleAddToCartRequest,
  StyleAddToCartInput,
  StyleAddToCartRequest,
} from '../types'

export class AddToCartRequestMapper {
  static toStyleAddToCartBody({
    cartId,
    quantity,
    skuId,
    subscriptionPlanId,
  }: StyleAddToCartInput): StyleAddToCartRequest {
    return {
      order_id: cartId,
      quantity: quantity,
      sku_id: skuId,
      subscription_plan_template_id: subscriptionPlanId,
    }
  }

  static toBundleAddToCartBody({
    code,
    quantity,
    skuIds,
    subscriptionPlanId,
  }: BundleAddToCartInput): BundleAddToCartRequest {
    return {
      code,
      quantity: quantity,
      sku_ids: skuIds,
      subscription_plan_template_id: subscriptionPlanId,
    }
  }
}
