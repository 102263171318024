import { useMemo } from 'react';
export function useTabs({ onChange , active , skin  }) {
    const context = useMemo(()=>{
        const instance = {
            handleChange: (tabName)=>{
                onChange(tabName);
            },
            skin,
            active
        };
        return instance;
    }, [
        onChange,
        active
    ]);
    return {
        context
    };
}
