import {
  CartService,
  getBaseUrl,
  RequestService,
  SERVICE_NAMES,
} from '@nori/app-kit'
import { Adapter, Inject } from '@nori/di'
import { resultErr, resultOk } from '@nori/result'

import { CouponCodeErrors } from '../core/coupon-code-errors'

import { CouponCodeMapper } from './mappers/coupon-code.mapper'
import { CouponCodeErrorMapper } from './mappers/coupon-code-error.mapper'
import { ValidationMapper } from './mappers/coupon-code-failed-validation.mapper'

import type { CartEntity } from '@nori/app-kit'
import type { PromiseResult } from '@nori/result'
import type { CouponCodeErrorInstance } from '../core/coupon-code-errors'
import type { CouponFailedValidationValueObject } from '../core/coupon-failed-validation.value-object'
import type { CouponCodeInput } from './types/coupon-code.input'

@Adapter()
export class CouponCodeAdapter {
  baseUrl = getBaseUrl(SERVICE_NAMES.CART_SERVICE)

  public constructor(
    @Inject() private readonly requestService: RequestService,
    @Inject() private readonly cartService: CartService,
    @Inject() private readonly couponCodeMapper: CouponCodeMapper,
    @Inject() private readonly couponCodeErrorMapper: CouponCodeErrorMapper
  ) {}

  async applyCouponCode(
    input: CouponCodeInput
  ): PromiseResult<
    | { data: CartEntity; type: 'response' }
    | { data: CouponFailedValidationValueObject; type: 'validationFailed' },
    CouponCodeErrorInstance
  > {
    try {
      const url = `${this.baseUrl}/carts/${input.cartId}/coupons`

      const { ok, json, status } = await this.requestService.secured.post(url, {
        body: this.couponCodeMapper.toApplyCouponCodeRequest(input),
      })

      if (ok) {
        return resultOk({
          data: this.cartService.toCartEntity(json),
          type: 'response',
        })
      }

      if (status === 422) {
        return resultOk({
          data: ValidationMapper.toValidationErrors(json),
          type: 'validationFailed',
        })
      }

      return resultErr(this.couponCodeErrorMapper.toErrors(status, json))
    } catch (err: unknown) {
      return resultErr(new CouponCodeErrors.UnexpectedError(err))
    }
  }

  async deleteCouponCode(
    input: CouponCodeInput
  ): PromiseResult<CartEntity, CouponCodeErrorInstance> {
    try {
      const url = `${this.baseUrl}/carts/${input.cartId}/coupons/${input.code}`

      const { ok, json, status } = await this.requestService.secured.delete(url)

      if (!ok) {
        return resultErr(this.couponCodeErrorMapper.toErrors(status, json))
      }

      return resultOk(this.cartService.toCartEntity(json))
    } catch (err: unknown) {
      return resultErr(new CouponCodeErrors.UnexpectedError(err))
    }
  }
}
