import { useTranslations } from '@nori/lang-i18n'
import { Radio } from '@nori/ui-kit'

import { FormHeaderIcons } from './form-header-icons'

import type { PaymentMethodValueObject } from '@nori/app-kit'

type Props = {
  paymentMethodType?: PaymentMethodValueObject
  isChecked: boolean
}

const translationKeysMap: Record<PaymentMethodValueObject, IntlMessageKeys> = {
  card: 'billingForm.creditCard.creditCard',
  paypal: 'billingForm.payPal',
}

export function FormHeader({ paymentMethodType, isChecked }: Props) {
  const t = useTranslations()
  const borderClass = isChecked ? 'border border-solid border-primary-500' : ''

  if (!paymentMethodType) {
    return null
  }

  return (
    <label
      className={`${borderClass} pointer-events-none relative flex w-full items-center justify-between rounded-sm bg-blue-100 px-5 py-2.5`}
    >
      <Radio<PaymentMethodValueObject>
        value={paymentMethodType}
        checked={isChecked}
      >
        <span className="font-medium text-primary-900">
          {t(translationKeysMap[paymentMethodType])}
        </span>
      </Radio>

      <FormHeaderIcons value={paymentMethodType} />
    </label>
  )
}
