import { useMemo, useState } from 'react'

import { useCreditsContext } from '@nori/app-kit'
import { observer } from '@nori/di'
import { useTranslations } from '@nori/lang-i18n'
import {
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableHeader,
} from '@nori/ui-kit'

import { CreditHistoryCreditRecord } from './credit-history-credit-record'
import { CreditHistoryDiscountRecord } from './credit-history-discount-record'

import type { CreditHistoryRecordValueObject } from '~/modules/contacts/core/credit-history-record.value-object'

const DEFAULT_PAGE = 0
const DEFAULT_PER_PAGE = 10

export const CreditHistory = observer(() => {
  const t = useTranslations('contactDetailsPage.norwexPerks.creditHistory')
  const { creditsStore } = useCreditsContext()
  const { combinedCredits, discounts } = creditsStore

  const [page, setPage] = useState(DEFAULT_PAGE)

  const { records, totalPages } = useMemo(() => {
    const records: CreditHistoryRecordValueObject[] = [
      ...(combinedCredits ?? []).map((value) => ({
        value,
        type: 'credit' as const,
      })),
      ...(discounts ?? []).map((value) => ({
        value,
        type: 'discount' as const,
      })),
    ]

    const totalPages = Math.ceil(records.length / DEFAULT_PER_PAGE)

    return { totalPages, records }
  }, [combinedCredits, discounts])

  const paginatedRecords = useMemo(() => {
    return records.slice(
      page * DEFAULT_PER_PAGE,
      Math.min((page + 1) * DEFAULT_PER_PAGE, records.length)
    )
  }, [records, page])

  return (
    <div className="w-full">
      <div className="text-sm font-bold text-black lg:text-xl">
        {t('creditHistory')}
      </div>
      <div className="mt-4 overflow-x-auto">
        <div className="min-w-[800px]">
          <Table
            data-test-id="customer-details-credits-and-rewards-history-table"
            widthType="full"
            withEmpty
          >
            <TableHeader>
              <TableCell>
                <p className=" text-sm font-bold text-sky-900">{t('type')}</p>
              </TableCell>
              <TableCell>
                <p className="text-sm font-bold text-sky-900">
                  {t('description')}
                </p>
              </TableCell>
              <TableCell>
                <p className=" text-sm font-bold text-sky-900">{t('date')}</p>
              </TableCell>
              <TableCell>
                <p className=" text-sm font-bold text-sky-900">
                  {t('expirationDate')}
                </p>
              </TableCell>
              <TableCell>
                <p className=" text-sm font-bold text-sky-900">{t('amount')}</p>
              </TableCell>
            </TableHeader>

            <TableBody>
              {paginatedRecords.map((record, i) => {
                const key = page * DEFAULT_PER_PAGE + i

                if (record.type === 'credit') {
                  return (
                    <CreditHistoryCreditRecord
                      key={key}
                      record={record.value}
                    />
                  )
                }

                return (
                  <CreditHistoryDiscountRecord
                    key={key}
                    record={record.value}
                  />
                )
              })}
            </TableBody>
          </Table>
        </div>
      </div>
      <div className="mt-6 flex w-full justify-center">
        <Pagination
          currentPage={page}
          onChange={setPage}
          totalPages={totalPages}
        />
      </div>
    </div>
  )
})
