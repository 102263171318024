import { createRollbarTransport } from './rollbar';
// TODO change to dynamic import for transport
export function getTransport() {
    switch(process.env.LOGGER_TRANSPORT_TYPE){
        case 'rollbar':
            return createRollbarTransport();
        default:
            return undefined;
    }
}
