function formatRow(acc, item, separator) {
    return acc.concat(item.chain.map((node)=>`${separator || ''}${node}`));
}
function getChain(context) {
    const field = `${context.key}; ${context.message}`;
    const chain = [
        field
    ];
    const error = context.error;
    if (Array.isArray(error)) {
        const chainList = error.reduce((acc, item)=>{
            if (item == null ? void 0 : item.chain) {
                return formatRow(acc, item, '-');
            }
            return acc;
        }, []);
        return chain.concat(chainList);
    }
    if (error == null ? void 0 : error.chain) {
        return formatRow(chain, error);
    }
    return chain;
}
export class DomainErrorClass {
    constructor(message, key, error){
        this.stack = new Error().stack;
        this.error = error;
        this.message = (error == null ? void 0 : error.message) ? `${message}; ${error.message}` : message;
        this.key = key;
        this.chain = getChain(this);
        this.name = 'DomainError';
    }
}
