import { observer } from '@nori/di'
import { useTranslations } from '@nori/lang-i18n'
import { Icon, Swap } from '@nori/ui-kit'

import { usePartiesListContext } from '../../interface/use-parties-list-context'

import type { PartyEntity } from '../../core/entities/party.entity'

type Props = {
  onSelectContact: (party: PartyEntity) => void
}

export const ContactPartyList = observer(({ onSelectContact }: Props) => {
  const t = useTranslations('contact')
  const { contactPartiesListStore, contactPartiesListLoadAction } =
    usePartiesListContext()

  return (
    <Swap is={!contactPartiesListStore.searchQuery} isSlot={null}>
      {contactPartiesListStore.contactPartyList?.data?.map((party) => {
        const contact = party.hostess
        const address = [
          party.hostess.accountAddress?.city?.trim(),
          party.hostess.accountAddress?.state?.trim(),
        ]
          .filter((item) => !!item)
          .join(', ')

        return (
          <div
            key={party.id}
            data-test-id="contact-option"
            className="flex cursor-pointer flex-col gap-1 border-b border-gray-200 py-3 text-sm text-gray-500 hover:bg-gray-50"
            onClick={() => {
              onSelectContact(party)
              contactPartiesListLoadAction.handleDropSearch()
            }}
          >
            <div className="font-bold text-primary-900">
              {contact.firstName} {contact.lastName}
            </div>
            <div>{contact.email}</div>
            <div>{address}</div>

            {contact.endEarningPeriod && (
              <div className="flex gap-2">
                <Icon name="ClockOutline" />
                {t('earningPeriodEnds')}{' '}
                {contact.endEarningPeriod.formatInTZ('MM/dd')}
              </div>
            )}
          </div>
        )
      })}
    </Swap>
  )
})
