import { toMoneyValueObject } from '@nori/app-kit'
import { NoriDate } from '@nori/date'
import { Service } from '@nori/di'

import type { PendingOrderEntity } from '../../core'

@Service()
export class PendingOrderMapper {
  private toEntity(item: any): PendingOrderEntity {
    return {
      orderId: item.order_id,
      orderDate: new NoriDate(item.order_date),
      orderTotal: toMoneyValueObject(item.order_total),
    }
  }

  toEntityArray(data: any): PendingOrderEntity[] {
    return data.map((order: any) => this.toEntity(order))
  }
}
