import { BundleComponentMapperService } from '@nori/app-kit'
import { Inject, Service } from '@nori/di'

import type { BundleComponentEntity, CommonBundleEntity } from '@nori/app-kit'

@Service()
export class BundleResponseMapper {
  constructor(
    @Inject()
    private readonly bundleComponentMapperService: BundleComponentMapperService
  ) {}

  toComponents(data: any): BundleComponentEntity[] {
    const components = data.components.map((component: any) => {
      return this.bundleComponentMapperService.toComponents(component)
    })
    return components
  }
}
