import { DesktopAuthenticatedHeader } from './desktop/desktop-authenticated-header'
import { MobileAuthenticatedHeader } from './mobile/mobile-authenticated-header'

export function AuthenticatedHeader() {
  return (
    <div data-test-id="authenticated-container-header">
      <div className="lg:hidden">
        <MobileAuthenticatedHeader />
      </div>
      <div className="hidden lg:block">
        <DesktopAuthenticatedHeader />
      </div>
    </div>
  )
}
