import { Store } from '@nori/di'

import type { DomainError } from '@nori/errors'
import type { NoteEntity } from '../core/note.entity'

type ContactID = number

@Store()
export class NotesStore {
  isLoadingMap: Record<ContactID, boolean> = {}
  contentMap: Record<ContactID, string> = {}
  editedNoteMap: Record<ContactID, NoteEntity> = {}
  errorMap: Record<ContactID, DomainError> = {}
  notesMap: Record<ContactID, NoteEntity[]> = {}

  setNotes(contactId: ContactID, value: NoteEntity[]): void {
    this.notesMap[contactId] = value
  }

  setIsLoading(contactId: ContactID, value?: boolean): void {
    if (!value) {
      delete this.isLoadingMap[contactId]
      return
    }
    this.isLoadingMap[contactId] = value
  }

  setContent(contactId: ContactID, value: string): void {
    this.contentMap[contactId] = value
  }

  setEditedNote(contactId: ContactID, value?: NoteEntity): void {
    if (!value) {
      delete this.editedNoteMap[contactId]
      return
    }
    this.editedNoteMap[contactId] = value
  }

  setError(contactId: ContactID, value?: DomainError): void {
    if (!value) {
      delete this.errorMap[contactId]
      return
    }

    this.errorMap[contactId] = value
  }
}
