import { Action, Inject } from '@nori/di'

import { HeaderStore } from '../store/header.store'

@Action()
export class HeaderAction {
  constructor(@Inject() private readonly headerStore: HeaderStore) {}

  handleToggleUserMenu(): void {
    const shouldDisplay = !this.headerStore.isUserMenuVisible
    if (shouldDisplay) this.headerStore.setMenuVisible(false)
    this.headerStore.setUserMenuVisible(shouldDisplay)
  }

  handleToggleMenu(): void {
    const shouldDisplay = !this.headerStore.isMenuVisible
    if (shouldDisplay) this.headerStore.setUserMenuVisible(false)
    this.headerStore.setMenuVisible(shouldDisplay)
  }

  handleToggleNestedMenu(key: string): void {
    const isVisible = this.headerStore.nestedMenuVisibility[key]
    this.headerStore.setNestedMenuVisible(key, !isVisible)
  }

  handleResetPersonalCartCounter(): void {
    this.headerStore.setPersonalCartItemsCounter(0)
  }
}
