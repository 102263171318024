import { useEffect } from 'react'

import {
  ProductDetailsInfo,
  ProductDetailsInfoRow,
  ProductPictures,
  StylePrices,
  useProductsContext,
  useQuery,
} from '@nori/app-kit'
import { observer } from '@nori/di'
import { Loader, PageContainer } from '@nori/ui-kit'
import { PageLayout } from '~/modules/page-layout'
import { useProductsLoungeContext } from '~/modules/products/interface/use-products-context'

import { AddToCartButton } from '../add-to-cart-button/add-to-cart-button'

import { BackButton } from './back-button'

type Query = {
  id: string
}

export const StyleDetailsPage = observer(() => {
  const { id } = useQuery<Query>()

  const { styleDetailsAction: styleDetailsLoungeAction } =
    useProductsLoungeContext()
  const { styleDetailsStore, styleDetailsAction } = useProductsContext()
  const { isLoading, error, product, selectedStyle } = styleDetailsStore

  useEffect(() => {
    if (!id) return
    styleDetailsAction.handleLoad(id)
  }, [id])

  if (isLoading) return <Loader />
  if (error) return <div>Error: {error.message}</div>
  if (!product) return <div>Product missing!</div>

  const sku = selectedStyle?.skus?.[0]

  return (
    <PageLayout>
      <PageContainer>
        <BackButton />
        {selectedStyle && <ProductPictures pictures={selectedStyle.pictures} />}
        <ProductDetailsInfo
          product={product}
          productStyles={product.styles}
          selectedStyle={selectedStyle}
          onSelectedStyleChange={styleDetailsAction.handleSetSelectedStyle}
          styleType={product.styleType}
        />

        <ProductDetailsInfoRow title="Prices">
          <StylePrices
            prices={{
              prv: sku?.currentPrv,
              retailPrice: sku?.currentRetailPrice,
              salePrice: sku?.currentSalePrice,
              stylistPrice: sku?.currentStylistPrice,
            }}
          />
        </ProductDetailsInfoRow>
        <AddToCartButton
          id={styleDetailsStore.statusId}
          onClick={() => {
            styleDetailsLoungeAction.handleAddToCart({ quantity: 1 })
          }}
        />
      </PageContainer>
    </PageLayout>
  )
})
