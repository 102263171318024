import { useEffect } from 'react'

import {
  BundlePrices,
  ProductDetailsInfo,
  ProductDetailsInfoRow,
  ProductPictures,
  useProductsContext,
  useQuery,
} from '@nori/app-kit'
import { observer } from '@nori/di'
import { Loader, PageContainer } from '@nori/ui-kit'
import { PageLayout } from '~/modules/page-layout'
import { useProductsLoungeContext } from '~/modules/products/interface/use-products-context'

import { AddToCartButton } from '../add-to-cart-button/add-to-cart-button'

import { BackButton } from './back-button'

type Query = {
  id: string
}

export const BundleDetailsPage = observer(() => {
  const { id } = useQuery<Query>()

  const { bundleDetailsAction: bundleDetailsLoungeAction } =
    useProductsLoungeContext()
  const { bundleDetailsAction, bundleDetailsStore } = useProductsContext()
  const { isLoading, error, product } = bundleDetailsStore

  useEffect(() => {
    if (!id) return
    bundleDetailsAction.handleLoad(id)
  }, [id])

  if (isLoading) return <Loader />
  if (error) return <div>Error: {error.message}</div>
  if (!product) return <div>Product missing!</div>

  const { maxDsrPrice, maxRetailPrice, maxPrice } = product

  return (
    <PageLayout>
      <PageContainer>
        <BackButton />
        <ProductPictures pictures={product.pictures} />
        <ProductDetailsInfo product={product} />
        <ProductDetailsInfoRow title="Prices">
          <BundlePrices
            prices={{
              maxDsrPrice,
              maxPrice,
              maxRetailPrice,
            }}
          />
          <AddToCartButton
            id={bundleDetailsStore.statusId}
            onClick={() => {
              bundleDetailsLoungeAction.handleAddToCart({ quantity: 1 })
            }}
          />
        </ProductDetailsInfoRow>
      </PageContainer>
    </PageLayout>
  )
})
