import { HistoryService } from '@nori/app-kit'
import { Action, Inject } from '@nori/di'
import { Routes } from '~/modules/routes'

@Action()
export class CategoryAction {
  constructor(
    @Inject()
    private readonly historyService: HistoryService
  ) {}

  openCategory(id: number): void {
    this.historyService.push(Routes.category.path(id))
  }
}
