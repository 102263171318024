import { observer } from '@nori/di'
import { useTranslations } from '@nori/lang-i18n'
import { useCartContext } from '~/modules/cart'

import { useCartUserCreditsContext } from '../../interface/cart-user-credits-context'
import { CreditItem } from '../credit-item/credit-item'

import { useHostConsultantCartCredits } from './use-host-consultant'

export const HostConsultantCartCredits = observer(() => {
  const t = useTranslations('userCredits')
  const { cartStore } = useCartContext()
  const { cartUserCreditsStore } = useCartUserCreditsContext()
  const credits = cartUserCreditsStore.cartUserCredits.data

  const shouldDisplayStoreCredits = !!cartStore.cart?.customerId
  const shouldDisplayCombinedCredits = !!cartStore.isCustomerCart

  const { sanitizedCredits } = useHostConsultantCartCredits({
    credits,
    customerType: cartStore.cart?.customerType,
  })

  if (!sanitizedCredits || !credits) return null
  return (
    <div className="flex w-full bg-legend-group-5 px-5 py-5 lg:px-10">
      <CreditItem
        title={t('itemDiscount')}
        subTitle={t('appliedOnCart')}
        credit={sanitizedCredits.itemDiscount}
        data-test-id="item-discount"
      />

      {shouldDisplayCombinedCredits && (
        <CreditItem
          title={t('norwexCredits')}
          subTitle={t('appliedOnCart')}
          credit={sanitizedCredits.combinedCredits}
          currency={credits.currency}
          isWithLine
          data-test-id="norwex-credits"
        />
      )}

      {shouldDisplayStoreCredits && (
        <CreditItem
          title={t('storeCredits')}
          subTitle={t('appliedAtCheckout')}
          credit={sanitizedCredits.storeCredits}
          currency={credits.currency}
          isWithLine
          data-test-id="store-credits"
        />
      )}
    </div>
  )
})
