import { observer } from '@nori/di'
import { useTranslations } from '@nori/lang-i18n'
import { ButtonClipboardCopy, Icon, Link, Loader, Modal } from '@nori/ui-kit'

import { PartyDropdown } from '../../parties/ui/party-dropdown/party-dropdown'
import { useShareContext } from '../interface/use-share-context'

type Props = {
  title: string
  isOpen: boolean
  partyId?: number
  contactFirstName?: string
  contactLastName?: string
  onClose: () => void
}

export const ShareBagModal = observer(
  ({
    partyId,
    title,
    isOpen,
    onClose,
    contactLastName,
    contactFirstName,
  }: Props) => {
    const t = useTranslations('cart.summary.shareModal')

    const { shareAction, shareStore } = useShareContext()
    const {
      isCreatingShareCartLink,
      cartShareLink,
      cartShareMailUrl,
      cartShareMessengerUrl,
    } = shareStore

    const contactName = `${contactFirstName} ${contactLastName}`
    const isContactSelected = contactFirstName || contactLastName
    const contactTitle = isContactSelected
      ? contactName
      : t('noContactSelected')

    return (
      <Modal size="2xl" isOpen={isOpen} onClose={onClose}>
        <Modal.Header title={title} onClose={onClose} />
        <Modal.Body>
          <div className="flex flex-col items-center justify-between">
            {isCreatingShareCartLink && <Loader viewType="filled-center" />}
            <div className="flex w-full flex-col gap-5">
              <div className="flex items-center rounded-sm bg-gray-50 p-3">
                <div className="flex h-10 w-10 items-center justify-center rounded-full bg-primary-900 text-white">
                  <Icon name="UserOutline" />
                </div>
                <p className="ml-2 text-sm font-bold text-primary-900">
                  {contactTitle}
                </p>
              </div>
              <PartyDropdown
                partyId={partyId}
                onSelectParty={shareAction.handleSelectShareParty}
              />
            </div>
            <div className="mt-8 flex w-full flex-col gap-5 lg:flex-row">
              <div className="flex w-full items-center rounded-sm border border-primary-300 bg-gray-50 p-3 text-primary-600 lg:min-w-[450px] lg:px-3 lg:py-5">
                <Icon name="Link" size="xs" />
                <p
                  data-test-id="shared-cart-link"
                  className="ml-2 break-all text-sm font-normal text-gray-500"
                >
                  {cartShareLink}
                </p>
              </div>
              <ButtonClipboardCopy value={cartShareLink} size="base" />
            </div>
            <div className="mt-8 flex w-full items-center justify-center gap-4 text-primary-900">
              <p className="text-base font-bold">{t('shareWith')}</p>
              <Link href={cartShareMailUrl}>
                <Icon name="Mail" size="lg" />
              </Link>
              <Link href={cartShareMessengerUrl} target="_blank">
                <Icon name="Messenger" size="lg" />
              </Link>
            </div>
            <p className="mt-7 text-center text-sm font-normal text-gray-500 lg:mt-8">
              {t('shareInfo')}
            </p>
          </div>
        </Modal.Body>
      </Modal>
    )
  }
)
