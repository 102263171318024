import { Action, Inject } from '@nori/di'
import { isErr } from '@nori/result'
import { createLogger } from '~/infra/create-logger'
import { CartAdapter } from '~/modules/cart/infra/cart.adapter'
import { CartAction } from '~/modules/cart/interface/actions/cart.action'
import { LoadCartService } from '~/modules/cart/interface/services/create-cart.service'
import { CartStore } from '~/modules/cart/interface/store/cart-store/cart.store'

import { ApplyProductCreditsStore } from '../../store/apply-credits.store/apply-product-credits.store'

import type { ApplyCreditsActionInterface } from './apply-credits-action.interface'

const logger = createLogger('apply-product-credits.actions')

@Action()
export class ApplyProductCreditsAction implements ApplyCreditsActionInterface {
  constructor(
    @Inject() private readonly cartStore: CartStore,
    @Inject() private readonly cartAction: CartAction,
    @Inject() private readonly loadCartService: LoadCartService,
    @Inject() private readonly cartAdapter: CartAdapter,
    @Inject()
    private readonly applyProductCreditStore: ApplyProductCreditsStore
  ) {}

  async handleApplyCreditsAndGoCheckout(): Promise<void> {
    if (!this.applyProductCreditStore.creditsToApply) return

    await this.handleApplyCredit(
      this.applyProductCreditStore.creditsToApply.cents
    )
    this.cartAction.handleNavigateToCheckout()
  }

  handleChangeValue(value: string): void {
    const maximumAppliableValue =
      this.applyProductCreditStore.applicableCartCredits?.cents || 0

    if (Number(value) > maximumAppliableValue) {
      this.applyProductCreditStore.setValue(maximumAppliableValue.toString())
      return
    }

    this.applyProductCreditStore.setValue(value)
  }

  async handleRemoveCredits(): Promise<void> {
    if (!this.cartStore.cart) {
      return
    }
    const { id } = this.cartStore.cart
    this.applyProductCreditStore.updatedCredits.setIsLoading(true)

    await this.applyProductCredit({
      id,
      value: 0,
    })

    this.applyProductCreditStore.updatedCredits.setIsLoading(false)
  }

  async handleApplyCredit(productCreditsValue: number): Promise<void> {
    if (!this.cartStore.cart) {
      return
    }
    const { id } = this.cartStore.cart
    this.applyProductCreditStore.updatedCredits.setIsLoading(true)

    await this.applyProductCredit({
      id,
      value: productCreditsValue,
    })

    this.applyProductCreditStore.updatedCredits.setIsLoading(false)
  }

  private async applyProductCredit({
    id,
    value,
  }: {
    id: number
    value: number
  }): Promise<void> {
    this.applyProductCreditStore.updatedCredits.setError(undefined)
    const result = await this.cartAdapter.applyProductCredits({
      cartId: id,
      amount: value,
    })

    if (isErr(result)) {
      logger.error(result.error.key, result.error)
      this.applyProductCreditStore.updatedCredits.setError(result.error)
      return
    }

    await this.loadCartService.loadCartById({
      cartId: id,
    })

    this.applyProductCreditStore.setValue('')
  }
}
