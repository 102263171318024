import { Validator } from '@nori/validator'

const PHONE_LENGTH = 10

export const ContactValidatorScheme = {
  firstName: Validator.scheme.string(),
  lastName: Validator.scheme.string(),
  email: Validator.scheme.string().email(),
  phone: Validator.scheme
    .string()
    .length(PHONE_LENGTH, {
      message: `Phone number must be ${PHONE_LENGTH} digits`,
    })
    .optional(),
  month: Validator.scheme.number().min(1).max(12).optional(),
  day: Validator.scheme.number().min(1).max(31).optional(),
  isPotentialHost: Validator.scheme.boolean().default(false),
  isPotentialConsultant: Validator.scheme.boolean().default(false),
}

type ContactValidatorKeys = {
  [key in keyof typeof ContactValidatorScheme]?: any
}

export const ContactWithoutEmailValidatorScheme: ContactValidatorKeys = {
  firstName: Validator.scheme.string(),
  lastName: Validator.scheme.string(),
  email: Validator.scheme.string().optional(),
  phone: Validator.scheme
    .string()
    .length(PHONE_LENGTH, {
      message: `Phone number must be ${PHONE_LENGTH} digits`,
    })
    .optional(),
  month: Validator.scheme.number().min(1).max(12).optional(),
  day: Validator.scheme.number().min(1).max(31).optional(),
  isPotentialHost: Validator.scheme.boolean().default(false),
  isPotentialConsultant: Validator.scheme.boolean().default(false),
}

type ContactValidatorKeysType = {
  [key in keyof typeof ContactValidatorScheme]?: (typeof ContactValidatorScheme)[key]['_input']
}

export type ContactValidatorSchemeType = Validator<
  typeof ContactValidatorScheme
>
export type ContactWithoutEmailValidatorSchemeType = Validator<
  typeof ContactWithoutEmailValidatorScheme
>

const setValidatorValues = (
  validator: Validator<ContactValidatorKeys>,
  values: ContactValidatorKeysType
): void => {
  Object.keys(values).forEach((key) => {
    validator.handlers[key as keyof ContactValidatorKeysType](
      values[key as keyof ContactValidatorKeysType]
    )
  })
}

export function createContactValidator(
  contactValues?: ContactValidatorKeysType
): ContactValidatorSchemeType {
  const validator = new Validator(ContactValidatorScheme)
  if (contactValues) {
    setValidatorValues(validator, contactValues)
  }
  return validator
}

export function createContactWithoutEmailValidator(
  contactValues?: ContactValidatorKeysType
): ContactWithoutEmailValidatorSchemeType {
  const validator = new Validator(ContactWithoutEmailValidatorScheme)
  if (contactValues) {
    setValidatorValues(validator, contactValues)
  }
  return validator
}
