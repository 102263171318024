import { Service } from '@nori/di'

import type { CartBannerMessageLinkEntity } from '../../core/entity/cart-banner-link.entity'
import type { CartBannerValueObject } from '../../core/value-objects/cart-banner.value-object'
import type cartBannerMock from '../mocks/cart-banner.mock.json'
import type cartCompletedBannerMock from '../mocks/cart-banner-completed.mock.json'
import type cartBannerWithLinkMock from '../mocks/cart-banner-with-link.mock.json'

type ResponseType =
  | typeof cartBannerMock
  | typeof cartCompletedBannerMock
  | typeof cartBannerWithLinkMock

@Service()
export class CartBannerMapper {
  toValueObject(response: ResponseType): CartBannerValueObject {
    return {
      messages: response?.messages.map((message) => {
        const link = this.getLink(message.link, message.link?.type)
        return {
          ...message,
          link,
          promotionName: message.promotion_name,
        }
      }),
    }
  }

  private getLink(
    link: ResponseType['messages'][number]['link'],
    type?: string
  ): CartBannerMessageLinkEntity | undefined {
    if (this.isLinkItemSelection(link, type)) {
      return {
        ...link,
        key: {
          ...link.key,
          hidePrices: link.key.hide_prices,
        },
        type: 'item_selection',
      }
    }

    if (this.isLinkSearch(link, type)) {
      return {
        ...link,
        type: 'search',
      }
    }

    if (this.isLinkUrl(link, type)) {
      return {
        ...(link as (typeof cartBannerWithLinkMock)['messages'][number]['link']),
        type: 'url',
      }
    }
  }

  private isLinkItemSelection(
    link: ResponseType['messages'][number]['link'],
    type?: string
  ): link is (typeof cartCompletedBannerMock)['messages'][number]['link'] {
    return type === 'item_selection' && !!link
  }

  private isLinkSearch(
    link: ResponseType['messages'][number]['link'],
    type?: string
  ): link is (typeof cartBannerMock)['messages'][number]['link'] {
    return type === 'search' && !!link
  }

  private isLinkUrl(
    link: ResponseType['messages'][number]['link'],
    type?: string
  ): link is (typeof cartBannerWithLinkMock)['messages'][number]['link'] {
    return type === 'url' && !!link
  }
}
