import { format } from '@nori/date'
import { useTranslations } from '@nori/lang-i18n'
import { Link } from '@nori/ui-kit'
import { useContactContext } from '~/modules/contacts/interface/use-contact-context'
import { Routes } from '~/modules/routes'

import type { ContactEntity } from '~/modules/contacts/core'

type Props = {
  contact: ContactEntity
}

export function LastOrder({ contact }: Props) {
  const t = useTranslations('contacts.list.table.extended.lastOrder')
  const { contactsAction } = useContactContext()
  const { lastOrderId, lastOrderDate } = contact

  if (!lastOrderId || !lastOrderDate) return null
  return (
    <div
      className="w-full cursor-pointer bg-white p-4 text-primary-900 shadow-xs lg:w-[245px]"
      onClick={() => contactsAction.handleOpenLastOrder(lastOrderId)}
    >
      <div className="flex justify-between font-bold">
        <div className="text-sm">{t('lastOrder')}</div>
        <Link href={Routes.orders.list.path()} weight="bold">
          {t('viewAllOrders')}
        </Link>
      </div>
      <div className="mt-2 flex text-xs">
        <div data-test-id={'contact-expanded-view-last-order-button font-bold'}>
          {lastOrderId}
        </div>
        <div
          className="ml-1 text-gray-500"
          data-test-id={'contact-expanded-view-last-order-date'}
        >
          - {format(lastOrderDate, 'MMM dd, yyyy')}
        </div>
      </div>
    </div>
  )
}
