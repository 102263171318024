import { useEffect } from 'react'

import { observer } from '@nori/di'
import { useToggle } from '@nori/ui-kit'
import { useCartContext } from '~/modules/cart'

import { useProductSubscriptionContext } from '../interface/product-subscription.context'

import { ProductSubscription } from './product-subscription'

import type { CartBundleEntity } from '@nori/app-kit'

type Props = {
  bundle: CartBundleEntity
}

export const CartBundleSubscription = observer(({ bundle }: Props) => {
  const { bundleSubscriptionAction, productSubscriptionStore } =
    useProductSubscriptionContext()

  const firstItem = bundle.items[0]

  const subscriptionData = productSubscriptionStore.getProductSubscriptions({
    productId: bundle.code,
  })
  const selectedSubscriptionId =
    firstItem?.autoShip.id || subscriptionData?.defaultSubscription.id
  const isSubscriptionSelected = !!firstItem?.autoShip.id
  const subscriptionOptions = subscriptionData?.subscriptions
  const isLoading =
    productSubscriptionStore.productSubscriptionLoadingMap[bundle.code]
  const subscriptionToggle = useToggle()
  useEffect(() => {
    if (isSubscriptionSelected) {
      subscriptionToggle.handleClose()
    }
  }, [isSubscriptionSelected])

  return (
    <ProductSubscription
      isLoading={isLoading}
      subscriptions={subscriptionOptions}
      selectedSubscriptionId={selectedSubscriptionId}
      isSubscriptionSelected={
        isSubscriptionSelected || subscriptionToggle.isOpen
      }
      onSubscriptionOptionChange={(id: number) =>
        bundleSubscriptionAction.handleUpdateProductSubscription({
          bundleId: bundle.id,
          bundleCode: bundle.code,
          subscriptionPlan: id,
          oldSubscriptionPlan: firstItem?.autoShip.id,
        })
      }
      onSubscriptionClose={() =>
        bundleSubscriptionAction.handleSubscriptionToggle({
          isToggle: false,
          isSubscriptionSelected,
          oldSubscriptionPlan: firstItem?.autoShip.id,
          bundleCode: bundle.code,
          bundleId: bundle.id,
          subscriptionToggle: subscriptionToggle,
        })
      }
      onSubscriptionOpen={() =>
        bundleSubscriptionAction.handleProductSubscriptionOpen({
          bundleId: bundle.id,
          bundleCode: bundle.code,
          subscriptionPlan: selectedSubscriptionId,
          oldSubscriptionPlan: firstItem?.autoShip.id,
          isSubscriptionSelected: !!selectedSubscriptionId,
          subscriptionToggle: subscriptionToggle,
        })
      }
    />
  )
})
