import { Store } from '@nori/di'

import type { ProductCartErrorsInstance } from '../../core/product-cart.errors'

@Store()
export class ProductCartStore {
  isRemovingFromCart = false
  isProductSwitchInCart = false
  isApplyingHostessDiscount = false
  error?: ProductCartErrorsInstance = undefined

  setIsApplyingHostessDiscount(value: boolean): void {
    this.isApplyingHostessDiscount = value
  }

  setIsRemovingFromCart(value: boolean): void {
    this.isRemovingFromCart = value
  }

  setIsProductSwitchInCart(value: boolean): void {
    this.isProductSwitchInCart = value
  }

  setError(error?: ProductCartErrorsInstance): void {
    this.error = error
  }
}
