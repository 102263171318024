import { getBaseUrl, RequestService, SERVICE_NAMES } from '@nori/app-kit'
import { Adapter, Inject } from '@nori/di'
import { resultErr, resultOk } from '@nori/result'
import { ContactErrors } from '~/modules/contacts/core'

import { ContactErrorMapper } from './mappers/contact-error.mapper'
import { ContactsMapper } from './mappers/contacts.mapper'

import type { PromiseResult } from '@nori/result'
import type { ContactErrorInstance } from '~/modules/contacts/core'

@Adapter()
export class ContactMailingAdapter {
  baseUrl = getBaseUrl(SERVICE_NAMES.MY_BUSINESS_SERVICE)

  constructor(
    @Inject() private readonly contactsMapper: ContactsMapper,
    @Inject() private readonly requestService: RequestService
  ) {}

  async setContactsToReceiveCatalog(
    contactsToReceiveCatalog: Record<number, boolean>
  ): PromiseResult<void, ContactErrorInstance> {
    const url = `${this.baseUrl}/contacts/set_opted_in`
    try {
      const body = this.contactsMapper.toContactsSetOptedIds(
        contactsToReceiveCatalog
      )
      const response = await this.requestService.secured.put(url, {
        body,
      })
      if (response.ok) return resultOk(undefined)
      return resultErr(
        ContactErrorMapper.toErrors(response.status, response.json)
      )
    } catch (err: unknown) {
      return resultErr(
        new ContactErrors.SetContactsToReceiveCatalogUnexpectedError(err)
      )
    }
  }
}
