import { useState } from 'react'

import { getMoneyWithIsoCurrency, useProfileContext } from '@nori/app-kit'
import { observer } from '@nori/di'
import { useTranslations } from '@nori/lang-i18n'
import { ConfirmationModal, useToggle } from '@nori/ui-kit'
import { useAllAutoSaveTable } from '~/modules/autosave/interface/use-all-auto-save-table'
import { useAutosaveContext } from '~/modules/autosave/interface/use-autosave-context'
import { useContactContext } from '~/modules/contacts/interface/use-contact-context'

import { NextOrderAddonTable } from './next-order-addon-table'
import { NextOrderAutoSaveItem } from './next-order-autosave-item'

type Props = {
  isContactDetailsPage?: boolean
}

export const NextOrderTable = observer(({ isContactDetailsPage }: Props) => {
  const t = useTranslations('autosaveSettings')
  const tRewards = useTranslations('autosaveSettings.rewards')
  const { nextOrderStore, nextOrderAction } = useAutosaveContext()
  const { contactDetailsStore } = useContactContext()
  const { userStore } = useProfileContext()

  const [autosaveId, setAutosaveId] = useState<number>()
  const skipToggle = useToggle()

  const {
    modalText,
    modalTitle,
    modalConfirmText,
    modalCancelText,
    bottomText,
  } = useAllAutoSaveTable(skipToggle.isOpen, true)

  const skipAtDate = nextOrderStore.subscription?.skipAt
  const modalTextDateMessage =
    skipAtDate && skipToggle.isOpen
      ? t('skipAutoSaveModal.dateMessage', {
          date: skipAtDate.formatInTZ('MMM dd, yyyy'),
        })
      : ''

  const handleSubmitModal = () => {
    if (!autosaveId) return

    if (skipToggle.isOpen) {
      nextOrderAction.handleSkipSubscription({
        id: autosaveId,
        userId: isContactDetailsPage
          ? contactDetailsStore.contact?.customerId
          : userStore.currentUser?.id,
      })
      return
    }

    nextOrderAction.handleCancelAutoSave({
      id: autosaveId,
    })
  }

  return (
    <>
      {nextOrderStore.totalSavings &&
        nextOrderStore.totalSavings.cents > 0 &&
        !isContactDetailsPage && (
          <div className="mb-3 ml-auto text-center text-sm font-medium text-primary-900 lg:text-right">
            {tRewards('youreSaving')}{' '}
            {getMoneyWithIsoCurrency(nextOrderStore.totalSavings)}{' '}
            {tRewards('withAutosave')}
          </div>
        )}

      <div className="flex flex-col gap-6">
        {nextOrderStore.autoSaveSubscriptions?.map((subscription) => (
          <NextOrderAutoSaveItem
            subscription={subscription}
            key={subscription.id}
            isContactDetailsPage={isContactDetailsPage}
            onChangeStatus={(skip) => {
              if (skip === true) {
                skipToggle.handleOpen()
              } else {
                skipToggle.handleClose()
              }
              setAutosaveId(subscription.id)
            }}
          />
        ))}
        <div>
          <NextOrderAddonTable />
        </div>
        <ConfirmationModal
          title={modalTitle}
          text={modalText}
          bottomText={bottomText}
          modalTextDateMessage={modalTextDateMessage}
          textConfirmButton={modalConfirmText}
          textCancelButton={modalCancelText}
          onSubmit={handleSubmitModal}
          onClose={() => {
            setAutosaveId(undefined)
            skipToggle.handleClose()
          }}
          isOpen={!!autosaveId}
          buttonContainerMinWidthClass="w-80 lg:w-[392px]"
          buttonSize="lg"
          buttonWidthType="full"
        />
      </div>
    </>
  )
})
