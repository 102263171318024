import type { FailedValidationValueObject } from '../../core/failed-validation.value-object'

export class ValidationMapper {
  static toValidationErrors(data: any): FailedValidationValueObject {
    return {
      address1: data.errors['address1']?.[0],
      address2: data.errors['address2']?.[0],
      city: data.errors['city']?.[0],
      country: data.errors['country']?.[0],
      state: data.errors['state']?.[0],
      zipCode: data.errors['zip_code']?.[0],
    }
  }
}
