import { LanguageSwitcher, NorwexLogo } from '@nori/app-kit'

export function UnauthenticatedHeader() {
  return (
    <div className="h-15 w-full bg-primary-50">
      <div className="relative mx-auto flex h-full max-w-top-section-content flex-row items-center justify-center  text-primary-900">
        <div className="absolute left-8 lg:relative lg:left-0">
          <NorwexLogo />
        </div>

        <div className="absolute right-8 flex items-center gap-1">
          <LanguageSwitcher
            skin="primary"
            data-test-id="unauth-language-selector"
            withAllLanguages
          />
        </div>
      </div>
    </div>
  )
}
