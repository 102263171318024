import { Inject, Service } from '@nori/di'
import { isErr } from '@nori/result'
import { createLogger } from '~/infra/create-logger'
import { NoteAdapter } from '~/modules/notes/infra/note.adapter'

import { ContactErrors, type SearchContactsInput } from '../../core'
import { ContactAdapter } from '../../infra/contact.adapter'
import { ContactMailingAdapter } from '../../infra/contact-mailing.adapter'
import { ContactManageMailingStore } from '../store/contact-manage-mailing.store'

const logger = createLogger('contacts-manage-mailing.service')

const INITAL_PAGE = 1
const MAILING_MESSAGE = '#Mailing'

@Service()
export class ContactsManageMailingService {
  constructor(
    @Inject()
    private readonly contactManageMailingStore: ContactManageMailingStore,
    @Inject()
    private readonly contactMailingAdapter: ContactMailingAdapter,
    @Inject()
    private readonly contactAdapter: ContactAdapter,
    @Inject()
    private readonly noteAdapter: NoteAdapter
  ) {}

  async searchContacts(input: SearchContactsInput): Promise<void> {
    const result = await this.contactAdapter.searchContacts({
      ...input,
      shouldReceivePrintCatalog:
        this.contactManageMailingStore.dataTableType === 'opt-in',
    })

    if (isErr(result)) {
      logger.error(result.error.key, result.error)
      this.contactManageMailingStore.setError(result.error)
      return
    }

    this.contactManageMailingStore.setContactsManageMailingData(
      result.data.contacts
    )
  }

  async setContactsToReceiveCatalog(): Promise<void> {
    const result = await this.contactMailingAdapter.setContactsToReceiveCatalog(
      this.contactManageMailingStore.contactsToReceiveCatalog
    )
    if (isErr(result)) {
      logger.error(result.error.key, result.error)
      this.contactManageMailingStore.setError(result.error)
      return
    }

    this.contactManageMailingStore.setContactsToReceiveCatalogToDefault()
  }

  async loadContacts({
    page,
    perPage,
    searchQuery,
  }: {
    page?: number
    perPage?: number
    searchQuery?: string
  }): Promise<void> {
    if (page === undefined) {
      this.contactManageMailingStore.setDataToDefault()
    }

    await this.fetchContacts({
      page,
      perPage,
      searchQuery,
    })
  }

  async loadNextPage(page: number): Promise<void> {
    await this.fetchContacts({ page })
  }

  async setNotesForContacts(): Promise<void> {
    const contacts = this.contactManageMailingStore.contactsToReceiveCatalog
    for (const [contactId, shouldCreateNote] of Object.entries(contacts)) {
      let result

      const mailingNote = this.contactManageMailingStore.getNotesForContact(
        Number(contactId)
      )
      if (shouldCreateNote) {
        result = await this.noteAdapter.createNote({
          contactId: Number(contactId),
          content: MAILING_MESSAGE,
        })

        if (isErr(result)) {
          logger.error(result.error.key, result.error)
          this.contactManageMailingStore.setError(result.error)
          return
        }
      }

      if (mailingNote) {
        result = await this.noteAdapter.deleteNote(mailingNote.id)
        if (isErr(result)) {
          logger.error(result.error.key, result.error)
          this.contactManageMailingStore.setError(result.error)
          return
        }
      }
    }
  }

  private async fetchContacts({
    page,
    perPage,
    searchQuery,
  }: {
    page?: number
    perPage?: number
    searchQuery?: string
  }): Promise<void> {
    if (searchQuery) {
      this.contactManageMailingStore.abortPreviousRequest()
    }

    const result = await this.contactAdapter.searchContacts({
      page:
        page ?? this.contactManageMailingStore.pagination?.page ?? undefined,
      perPage: perPage ?? this.contactManageMailingStore.pagination?.perPage,
      searchQuery: searchQuery ?? this.contactManageMailingStore.searchQuery,
      searchFilter: this.contactManageMailingStore.searchFilter,
      order: this.contactManageMailingStore.sortDirection,
      sortBy: this.contactManageMailingStore.sortBy,
      controller: this.contactManageMailingStore.abortController,
      shouldReceivePrintCatalog:
        this.contactManageMailingStore.dataTableType === 'opt-in',
    })

    if (isErr(result)) {
      if (result.error instanceof ContactErrors.RequestAborted) {
        return
      }
      logger.error(result.error.key, result.error)
      this.contactManageMailingStore.setError(result.error)
      return
    }

    result.data.pagination.page > INITAL_PAGE
      ? this.contactManageMailingStore.appendContactsManageMailingData(
          result.data.contacts
        )
      : this.contactManageMailingStore.setContactsManageMailingData(
          result.data.contacts
        )

    this.contactManageMailingStore.setPagination(result.data.pagination)
  }
}
