import { useEffect, useMemo } from 'react'

import { useQuery } from '@nori/app-kit'
import { observer } from '@nori/di'
import { useTranslations } from '@nori/lang-i18n'
import { formatPhone } from '@nori/phone'
import {
  Button,
  ContentBlock,
  Icon,
  Link,
  Loader,
  PageContainer,
  Swap,
} from '@nori/ui-kit'
import { useContactContext } from '~/modules/contacts/interface/use-contact-context'
import { PageLayout } from '~/modules/page-layout'
import { Routes } from '~/modules/routes'

import { ContactDetailsTabs } from './contact-details-tabs'
import { ErrorBlock } from './error-block'

type Query = { id: string }

export const ContactDetailsPage = observer(() => {
  const t = useTranslations('contactDetailsPage')
  const { id } = useQuery<Query>()
  const { contactDetailsStore, contactDetailsAction } = useContactContext()
  const { contact, isLoading, error } = contactDetailsStore

  useEffect(() => {
    if (!id) return
    contactDetailsAction.handleLoadContactDetails(id)
  }, [id, contactDetailsAction])

  const phoneNumber = useMemo(() => {
    if (!contact?.phone) return
    const { formattedPhoneNumber, isRegexPassed, rawValue } = formatPhone(
      contact.phone
    )
    if (!isRegexPassed) return rawValue

    return formattedPhoneNumber
  }, [contact?.phone])

  if (!contact) {
    return <ErrorBlock error={error} />
  }

  return (
    <PageLayout>
      <PageContainer>
        <ContentBlock>
          <Swap
            is={isLoading}
            isSlot={
              <div className="m-5">
                <Loader />
              </div>
            }
          >
            <div className="pt-5 md:px-28 md:pt-0">
              <Swap is={!contact} isSlot={<ErrorBlock error={error} />}>
                <>
                  <div className="flex flex-row items-center text-primary-900">
                    <Link
                      size="medium"
                      iconLeft="ArrowLeft"
                      href={Routes.contacts.path()}
                      isUnderline={false}
                    >
                      {t('backTo')}
                    </Link>
                  </div>

                  <div className="mb-2 flex flex-row justify-between text-lg font-bold text-primary-900 md:text-2xl">
                    <div>
                      <div
                        data-test-id="contact-details-full-name-div"
                        className="mt-2.5"
                      >
                        {contact.firstName} {contact.lastName}
                      </div>
                    </div>
                    {!contact.isMyself && (
                      <div className="hidden items-center md:flex">
                        <div className="w-[148px]">
                          <Button
                            widthType="full"
                            onClick={() =>
                              contactDetailsAction.handleOpenEditContactPage(
                                contact.id
                              )
                            }
                          >
                            {t('info.edit')}
                          </Button>
                        </div>
                      </div>
                    )}
                  </div>

                  <div className="mb-5 flex flex-col text-xs font-bold text-primary-900 md:mb-12 md:flex-row md:gap-5 lg:mt-3.5">
                    {contact.isEmailProvided !== false && (
                      <a
                        href={`mailto:${contact.email}`}
                        className="flex items-center"
                      >
                        <Icon name="MailOutline" />
                        <div className="ml-1">{contact.email}</div>
                      </a>
                    )}
                    {!!phoneNumber?.length && (
                      <a
                        href={`tel:${phoneNumber}`}
                        className="flex items-center"
                      >
                        <Icon name="PhoneOutline" />
                        <span className="ml-1">{phoneNumber}</span>
                      </a>
                    )}
                    <button className="flex hidden items-center">
                      <Icon name="Messenger" />
                      <div className="ml-1">{t('messenger')}</div>
                    </button>
                  </div>

                  <div className="flex items-center md:hidden">
                    <div className="w-[148px]">
                      <Button
                        widthType="full"
                        onClick={() =>
                          contactDetailsAction.handleOpenEditContactPage(
                            contact.id
                          )
                        }
                      >
                        {t('info.edit')}
                      </Button>
                    </div>
                  </div>

                  <ContactDetailsTabs />
                </>
              </Swap>
            </div>
          </Swap>
        </ContentBlock>
      </PageContainer>
    </PageLayout>
  )
})
