import { ProductTypeCode } from '@nori/app-kit'

import { ArrayCell } from './cells/array.cell'
import { BundleCell } from './cells/bundle.cell'
import { StyleCell } from './cells/style.cell'

import type {
  CommonBundleEntity,
  CommonProductListItemProps,
  CommonStyleEntity,
} from '@nori/app-kit'

export function ProductListItem({
  item,
}: CommonProductListItemProps<CommonBundleEntity, CommonStyleEntity>) {
  switch (item.typeCode) {
    case ProductTypeCode.Style:
      return <StyleCell item={item} />
    case ProductTypeCode.Bundle:
      return <BundleCell item={item} />
    case ProductTypeCode.Array:
      if (!item.styles.length) return null
      return <ArrayCell item={item} />
    default:
      return null
  }
}
