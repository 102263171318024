import { Service } from '@nori/di'

import type { ValidatorAddressOnlyType } from '@nori/app-kit'

@Service()
export class ContactFormService {
  async validateAddressForm(
    addressValidator: ValidatorAddressOnlyType
  ): Promise<boolean> {
    const keys = Object.keys(addressValidator.values) as Array<
      keyof typeof addressValidator.values
    >
    // Country is always filled
    const keysWithoutCountry = keys.filter((key) => key !== 'country')
    const isNeedValidateAddressBlock = keysWithoutCountry.some(
      (addressValidatorKey) => !!addressValidator.values[addressValidatorKey]
    )

    if (!isNeedValidateAddressBlock) {
      return true
    }

    const result = await addressValidator.submit()

    return result.isValid
  }
}
