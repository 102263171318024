import { useTranslations } from '@nori/lang-i18n'
import { Button } from '@nori/ui-kit'
import { useAutosaveContext } from '~/modules/autosave/interface/use-autosave-context'

export function NextOrderAddItem() {
  const { nextOrderAddItemAction } = useAutosaveContext()
  const t = useTranslations('autosaveSettings')

  return (
    <div className="text-sm">
      <div>
        <div className="w-auto">
          <Button
            widthType="full"
            size="lg"
            onClick={nextOrderAddItemAction.handleOpen}
          >
            {t('addItems')}
          </Button>
        </div>
      </div>
    </div>
  )
}
