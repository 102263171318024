import type { PropsWithChildren } from 'react'

export type HeaderDropdownBorderRadiusType = 'none' | 'sm'

export type HeaderDropdownVerticalAlignType =
  | 'top-9'
  | 'top-12'
  | 'top-13'
  | 'top-16'
  | 'top-18'
  | 'top-19'

export type HeaderDropdownWidth = 'lg'

type Props = PropsWithChildren<{
  align: 'left' | 'right'
  verticalAlign?: HeaderDropdownVerticalAlignType
  borderRadiusType?: HeaderDropdownBorderRadiusType
  width?: HeaderDropdownWidth
}>

const borderRadiusTypeMap: Record<HeaderDropdownBorderRadiusType, string> = {
  none: 'rounded-none',
  sm: 'rounded-sm',
}

const verticalAlignMap: Partial<
  Record<HeaderDropdownVerticalAlignType, string>
> = {
  'top-13': 'top-[3.25rem]',
  'top-18': 'top-[4.50rem]',
  'top-19': 'top-[4.75rem]',
}

const widthMap: Record<HeaderDropdownWidth, string> = {
  lg: 'w-80',
}

export function HeaderDropdown({
  align,
  children,
  verticalAlign,
  borderRadiusType = 'none',
  width,
}: Props) {
  const baseClass =
    'whitespace-nowrap absolute z-10 bg-white text-sm font-normal text-primary-900 drop-shadow-lg'
  const alignmentClass = align === 'left' ? 'left-0' : 'right-0'
  const borderRadiusClass = borderRadiusTypeMap[borderRadiusType]
  const widthClass = width ? widthMap[width] : ''
  const verticalAlignClass = verticalAlign
    ? verticalAlignMap[verticalAlign] || verticalAlign
    : ''
  const styleClass = `${baseClass} ${alignmentClass} ${borderRadiusClass} ${verticalAlignClass} ${widthClass}`

  return <div className={styleClass}>{children}</div>
}
