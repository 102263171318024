import { getTableCount, HistoryService } from '@nori/app-kit'
import { Inject, Store } from '@nori/di'

import type {
  PaginationValueObject,
  TableCountValueObject,
} from '@nori/app-kit'
import type { NoriDate } from '@nori/date'
import type { SortDirectionValueObject } from '~/core'
import type { PartyListSortBy } from '~/modules/parties/core/party-list-sort-by'
import type { PartyEntity } from '../../core/entities/party.entity'
import type { PartiesListErrorInstance } from '../../core/errors/parties-list.errors'
import type { PartyStatusValueObject } from '../../core/value-objects/party-status.value-object'
import type { PartyTypeValueObject } from '../../core/value-objects/party-type.value-object'
import type { PartiesTabsValueObject } from '../../core/value-objects/tabs.value-object'

const NEXT_PAGE_COUNTER = 1
const DEFAULT_PAGINATION: PaginationValueObject = {
  page: 1,
  totalPages: 1,
  totalCount: 0,
  perPage: 40,
}

@Store()
export class PartiesListStore {
  constructor(@Inject() private readonly historyService: HistoryService) {
    this.queryParams = this.historyService.getQuery()
  }

  queryParams: { tab?: PartiesTabsValueObject } = {}
  isLoading = false
  isLoadingNextPage = false

  partyType?: PartyTypeValueObject = 'open'

  error?: PartiesListErrorInstance = undefined

  sortBy?: PartyListSortBy = undefined
  sortOrder?: SortDirectionValueObject = undefined

  partiesList?: PartyEntity[] = undefined
  pagination = DEFAULT_PAGINATION

  dateRange: NoriDate[] = []
  selectedRange = ''
  searchParam = ''
  isWithAccurateTime?: boolean = undefined

  get activeTab(): PartiesTabsValueObject {
    return this.queryParams.tab ?? 'list-view'
  }

  get isAnyFilterApplied(): boolean {
    return this.dateRange.length > 0 || this.searchParam.length > 0
  }

  get usedPartiesList(): PartyEntity[] {
    if (!this.partiesList) return []
    return this.partiesList
  }

  get hasMore(): boolean {
    return (
      !this.partiesList ||
      (!!this.pagination?.totalPages &&
        this.pagination.totalPages > this.pagination.page)
    )
  }

  get nextPage(): number {
    return this.pagination.page + NEXT_PAGE_COUNTER
  }

  get tableCount(): TableCountValueObject {
    return getTableCount(this.pagination)
  }

  getPartyStatus(
    party: Pick<PartyEntity, 'openOn' | 'closedOn'>
  ): PartyStatusValueObject {
    const currentDate = new Date()
    if (party.closedOn < currentDate) return 'closed'
    if (party.openOn < currentDate) return 'open'
    return 'future-booked'
  }

  getSortOrder(sortBy: PartyListSortBy): SortDirectionValueObject | undefined {
    if (this.sortBy === sortBy) return this.sortOrder
    return undefined
  }

  setSortBy(value?: PartyListSortBy): void {
    this.sortBy = value
  }

  setSortOrder(value?: SortDirectionValueObject): void {
    this.sortOrder = value
  }

  setSearchParam(value: string): void {
    this.searchParam = value
  }

  setPartyType(value: PartyTypeValueObject): void {
    this.partyType = value
  }

  setPartiesList(parties: PartyEntity[]): void {
    this.partiesList = parties
  }

  appendPartiesList(parties: PartyEntity[]): void {
    this.partiesList = [...(this.partiesList ?? []), ...parties]
  }

  setPagination(pagination: PaginationValueObject): void {
    this.pagination = pagination
  }

  setIsLoading(value: boolean): void {
    this.isLoading = value
  }

  setIsLoadingNextPage(value: boolean): void {
    this.isLoadingNextPage = value
  }

  setError(value?: PartiesListErrorInstance): void {
    this.error = value
  }

  setActiveTab(value: PartiesTabsValueObject): void {
    const query = this.queryParams
    query.tab = value

    this.historyService.replace(
      {
        pathname: this.historyService.pathname,
        query,
      },
      undefined,
      { shallow: true }
    )
  }

  setDateRange(value: NoriDate[]): void {
    this.dateRange = value
  }

  setSelectedRange(value: string): void {
    this.selectedRange = value
  }

  setIsWithAccurateTime(value: string): void {
    this.isWithAccurateTime = value === 'this-month' || value === 'next-month'
  }

  resetSearchFilters(): void {
    this.setSelectedRange('')
    this.setDateRange([])
    this.setSearchParam('')
    this.setSortBy('party_at')
    this.setSortOrder('asc')
  }

  resetPagination(): void {
    this.setPagination(DEFAULT_PAGINATION)
  }
}
