import { hookContextFactory } from '@nori/di'

import { PartiesCalendarAction } from './actions/calendar-parties.action'
import { ContactPartiesListLoadAction } from './actions/contact-party.action'
import { PartiesListAction } from './actions/parties-list.action'
import { ContactPartiesListStore } from './stores/contact-party.store'
import { PartiesCalendarStore } from './stores/parties-calendar.store'
import { PartiesListStore } from './stores/parties-list.store'

export const { useModuleContext: usePartiesListContext } = hookContextFactory({
  partiesListAction: PartiesListAction,
  partiesListStore: PartiesListStore,
  partiesCalendarAction: PartiesCalendarAction,
  partiesCalendarStore: PartiesCalendarStore,
  contactPartiesListStore: ContactPartiesListStore,
  contactPartiesListLoadAction: ContactPartiesListLoadAction,
})
