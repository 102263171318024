import { useMemo } from 'react'

import { useTranslations } from '@nori/lang-i18n'
import { Icon, Link } from '@nori/ui-kit'

type Props = {
  title?: string
  description?: string
  onRemove?: () => void
  partyDate?: string
  partyAddress?: string
  hostCaption?: string
}

export const SelectedEventBlock = ({
  title,
  onRemove,
  partyDate,
  partyAddress,
  hostCaption,
}: Props) => {
  const description = useMemo(
    () => [partyDate, partyAddress].filter(Boolean).join(' / '),
    [partyDate, partyAddress]
  )
  const t = useTranslations('contactAndParty')

  if (!title) {
    return (
      <p className="bg-primary-50 p-5 font-bold text-primary-900">
        {t('editPartyOrHostess.noEventSelected')}
      </p>
    )
  }

  return (
    <div className="flex flex-row bg-primary-50 px-5 py-4">
      <div className="mr-5 flex h-10 min-w-[40px] items-center justify-center rounded-full bg-primary-900 text-white">
        <Icon name="CalendarOutline" />
      </div>
      <div className="flex flex-col gap-1">
        <div data-test-id="modal-selected-event-name" className="flex flex-col">
          <span className="text-sm font-bold text-primary-900">{title}</span>
          <span className="text-sm font-normal text-gray-500">
            {hostCaption}
          </span>
        </div>
        {description && (
          <div
            data-test-id="modal-selected-event-details"
            className="flex break-all text-sm text-gray-500"
          >
            <span>{description}</span>
          </div>
        )}
        <Link
          data-test-id="modal-remove-selected-event-button"
          onClick={onRemove}
          justifyContent={'normal'}
        >
          {t('remove')}
        </Link>
      </div>
    </div>
  )
}
