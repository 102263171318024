import { observer } from '@nori/di'
import { useTranslations } from '@nori/lang-i18n'
import { InfiniteScroll, Loader, Swap } from '@nori/ui-kit'
import { useAutosaveContext } from '~/modules/autosave/interface/use-autosave-context'

import { AddProductTableDesktop } from './add-product-table-desktop/add-product-table-desktop'
import { AddProductTableMobile } from './add-product-table-mobile/add-product-table-mobile'

export const AddProductSearchResults = observer(() => {
  const t = useTranslations('autosaveSettings.addItemModal')
  const { subscriptionsAddProductStore, subscriptionsAddProductAction } =
    useAutosaveContext()

  if (subscriptionsAddProductStore.products.isLoading) return <Loader />

  return (
    <Swap
      is={
        !subscriptionsAddProductStore.products?.data?.items?.length ||
        !subscriptionsAddProductStore.isAnyAutoShip
      }
      isSlot={<></>}
    >
      <InfiniteScroll
        next={subscriptionsAddProductAction.handleLoadMore}
        dataLength={
          subscriptionsAddProductStore.products?.data?.items?.length ?? 0
        }
        loader={
          subscriptionsAddProductStore.isLoadingMore && (
            <div className="m-4">
              <Loader />
            </div>
          )
        }
        hasMore={subscriptionsAddProductStore.hasMore}
        scrollThreshold={0.5}
        scrollableTarget="autosave-settings-add-product-modal"
      >
        <div className="hidden lg:block">
          <AddProductTableDesktop />
        </div>

        <div className="lg:hidden">
          <AddProductTableMobile />
        </div>
      </InfiniteScroll>
    </Swap>
  )
})
