import { useEffect, useState } from 'react'

import type { ReactNode } from 'react'

type Props = {
  children: ReactNode
}

// eslint-disable-next-line import/no-mutable-exports
let Mocks = ({ children }: Props) => {
  return <>{children}</>
}

if (process.env.NEXT_PUBLIC_API_MOCKING === 'enabled') {
  const log = (...args: any) => {
    // eslint-disable-next-line no-console
    console.log(...args)
  }
  // eslint-disable-next-line no-inner-declarations
  function handleClient() {
    const ClientMocks = ({ children }: Props) => {
      const [isReady, setReady] = useState(false)
      useEffect(() => {
        import('~/mocks/init-as-client')
          .then(async ({ initAsClient }) => {
            await initAsClient()
            log('mocks loaded')
            setReady(true)
          })
          .catch(() => {
            // eslint-disable-next-line no-console
            console.error('mocks init failed, please check mocks')
          })
      }, [])

      if (!isReady) return <></>

      return <>{children}</>
    }
    Mocks = ClientMocks
  }

  // eslint-disable-next-line no-inner-declarations
  function handleServer() {
    import('~/mocks/server').then(({ server }) => {
      server.listen()
    })
  }

  const isServer = typeof window === 'undefined'
  if (isServer) handleServer()
  else handleClient()

  log('NEXT_PUBLIC_API_MOCKING is enabled, using mocks')
  // eslint-disable-next-line @typescript-eslint/no-require-imports
}

export { Mocks }
