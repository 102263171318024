var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __name = (target, value) => __defProp(target, "name", { value, configurable: true });
var __publicField = (obj, key, value) => {
  __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
  return value;
};

// src/hook-context-factory.ts
import { createContext, useContext } from "react";

// src/ioc/get-instance-by-class.ts
import { Container } from "typedi";
function getInstanceByClass(type) {
  return Container.get(type);
}
__name(getInstanceByClass, "getInstanceByClass");

// src/hook-context-factory.ts
function createContextByFields(fields) {
  const keys = Object.keys(fields);
  const instances = keys.reduce((acc, fieldKey) => {
    const ClassName = fields[fieldKey];
    acc[fieldKey] = getInstanceByClass(ClassName);
    return acc;
  }, {});
  return createContext.call(this, instances);
}
__name(createContextByFields, "createContextByFields");
function hookContextFactory(fields) {
  let cachedContext = null;
  const useModuleContext = /* @__PURE__ */ __name(() => {
    if (cachedContext === null) {
      cachedContext = createContextByFields(fields);
    }
    return useContext(cachedContext);
  }, "useModuleContext");
  return {
    useModuleContext
  };
}
__name(hookContextFactory, "hookContextFactory");

// src/mobx/mobx-proxy.ts
import { makeAutoObservable as mobxMakeAutoObservable, makeObservable as mobxMakeObservable, observable as mobxObservable, observe as mobxObserve } from "mobx";
var makeAutoObservable = mobxMakeAutoObservable;
var makeObservable = mobxMakeObservable;
var observable = mobxObservable;
var observe = mobxObserve;

// src/interface/data-state.ts
var DataState = class {
  constructor(args) {
    __publicField(this, "data");
    __publicField(this, "isLoading", true);
    __publicField(this, "error");
    __publicField(this, "setData", /* @__PURE__ */ __name((data) => {
      this.data = data;
    }, "setData"));
    __publicField(this, "setIsLoading", /* @__PURE__ */ __name((value) => {
      if (value) {
        this.error = void 0;
      }
      this.isLoading = value;
    }, "setIsLoading"));
    __publicField(this, "setError", /* @__PURE__ */ __name((value) => {
      this.error = value;
    }, "setError"));
    var _a;
    this.data = args == null ? void 0 : args.data;
    this.isLoading = (_a = args == null ? void 0 : args.isLoading) != null ? _a : true;
    this.error = args == null ? void 0 : args.error;
    makeAutoObservable(this);
  }
  get isDataLoaded() {
    if (this.isLoading)
      return false;
    return !!this.data;
  }
  get isError() {
    return !!this.error;
  }
  reset() {
    this.data = void 0;
    this.error = void 0;
    this.isLoading = false;
  }
};
__name(DataState, "DataState");

// src/interface/toggle-popup.ts
var Toggle = class {
  constructor(isOpen = false) {
    __publicField(this, "isOpen");
    __publicField(this, "handleOpen", /* @__PURE__ */ __name(() => {
      this.isOpen = true;
    }, "handleOpen"));
    __publicField(this, "handleClose", /* @__PURE__ */ __name(() => {
      this.isOpen = false;
    }, "handleClose"));
    __publicField(this, "handleToggle", /* @__PURE__ */ __name(() => {
      if (this.isOpen) {
        this.handleClose();
        return;
      }
      this.handleOpen();
    }, "handleToggle"));
    this.isOpen = isOpen;
    makeAutoObservable(this);
  }
};
__name(Toggle, "Toggle");

// src/interface/value-state.ts
var ValueState = class {
  constructor(value) {
    __publicField(this, "value");
    __publicField(this, "setValue", /* @__PURE__ */ __name((value) => {
      this.value = value;
    }, "setValue"));
    this.value = value;
    makeAutoObservable(this);
  }
};
__name(ValueState, "ValueState");

// src/ioc/action.ts
import { Service } from "typedi";
function bindContext(context, field) {
  if (field === "constructor")
    return;
  if (typeof context[field] === "function") {
    context[field] = context[field].bind(context);
  }
}
__name(bindContext, "bindContext");
function autoBind(context) {
  for (const key in context) {
    bindContext(context, key);
  }
  const proto = Object.getPrototypeOf(context.constructor.prototype);
  const names = Object.getOwnPropertyNames(proto);
  names.forEach((field) => {
    bindContext(context, field);
  });
}
__name(autoBind, "autoBind");
function Action() {
  return /* @__PURE__ */ __name(function extend(Context) {
    const serviceFn = Service({
      id: Context
    });
    let SubClass = /* @__PURE__ */ __name(class SubClass extends Context {
      constructor(...args) {
        super(...args);
        autoBind(this);
      }
    }, "SubClass");
    return serviceFn(SubClass);
  }, "extend");
}
__name(Action, "Action");

// src/ioc/adapter.ts
import { Service as Service2 } from "typedi";
function Adapter() {
  return /* @__PURE__ */ __name(function extend(Context) {
    const serviceFn = Service2();
    return serviceFn(Context);
  }, "extend");
}
__name(Adapter, "Adapter");

// src/ioc/data-provider.ts
import { Service as Service3 } from "typedi";
function DataProvider() {
  return /* @__PURE__ */ __name(function extend(Context) {
    const serviceFn = Service3();
    return serviceFn(Context);
  }, "extend");
}
__name(DataProvider, "DataProvider");

// src/ioc/inject.ts
import { Inject as DiInject } from "typedi";
var Inject = DiInject;

// src/ioc/module.ts
import { Service as Service4 } from "typedi";
function Module() {
  return /* @__PURE__ */ __name(function extend(Context) {
    const serviceFn = Service4();
    return serviceFn(Context);
  }, "extend");
}
__name(Module, "Module");

// src/ioc/service.ts
import { Service as DiService } from "typedi";
var Service5 = DiService;

// src/ioc/store.ts
import { $mobx, isObservable, makeObservable as makeObservable2 } from "mobx";
import { Service as Service6 } from "typedi";
var annotationsSymbol = Symbol("annotationsSymbol");
var objectPrototype = Object.prototype;
function makeSimpleAutoObservable(target) {
  const overrides = {};
  const options = {};
  if (isObservable(target)) {
    throw new Error("Target must not be observable");
  }
  let annotations = target[annotationsSymbol];
  if (!annotations) {
    annotations = {};
    let current = target;
    while (current && current !== objectPrototype) {
      Reflect.ownKeys(current).forEach((key) => {
        if (key === $mobx || key === "constructor")
          return;
        annotations[key] = !overrides ? true : key in overrides ? overrides[key] : true;
      });
      current = Object.getPrototypeOf(current);
    }
    const proto = Object.getPrototypeOf(target);
    if (proto && proto !== objectPrototype) {
      Object.defineProperty(proto, annotationsSymbol, {
        value: annotations
      });
    }
  }
  return makeObservable2(target, annotations, options);
}
__name(makeSimpleAutoObservable, "makeSimpleAutoObservable");
function Store() {
  return /* @__PURE__ */ __name(function extend(Context) {
    const serviceFn = Service6({
      id: Context
    });
    let SubClass = /* @__PURE__ */ __name(class SubClass extends Context {
      constructor(...args) {
        super(...args);
        makeSimpleAutoObservable(this);
      }
    }, "SubClass");
    Object.defineProperty(SubClass, "name", {
      value: Context.name
    });
    return serviceFn(SubClass);
  }, "extend");
}
__name(Store, "Store");

// src/mobx/mobx-lite-proxy.ts
import { enableStaticRendering as mobxEnableStaticRendering, observer as mobxObserver } from "mobx-react-lite";
var enableStaticRendering = mobxEnableStaticRendering;
var observer = mobxObserver;
export {
  Action,
  Adapter,
  DataProvider,
  DataState,
  Inject,
  Module,
  Service5 as Service,
  Store,
  Toggle,
  ValueState,
  enableStaticRendering,
  getInstanceByClass,
  hookContextFactory,
  makeAutoObservable,
  makeObservable,
  observable,
  observe,
  observer
};
