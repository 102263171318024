import { HelperTextBlock } from '@nori/ui-kit'
import { ContactErrors } from '~/modules/contacts/core'

import type { ContactErrorInstance } from '~/modules/contacts/core'

type Props = {
  error?: ContactErrorInstance
}

function getErrorMessage(error: ContactErrorInstance) {
  if (error instanceof ContactErrors.DeleteForbiddenError) {
    return 'This contact cannot be deleted because the contact is a host of a past or current Event'
  }

  return error.message
}

export function ErrorBlock({ error }: Props) {
  if (!error) return null
  const errorMesasge = getErrorMessage(error)

  return <HelperTextBlock type="error">{errorMesasge}</HelperTextBlock>
}
