import { useCallback, useState } from 'react'

import {
  ProductListCell,
  ProductListCellContainer,
  StylePrices,
} from '@nori/app-kit'
import { Radio } from '@nori/ui-kit'
import { useShopForMyselfContext } from '~/modules/shop-for-myself/interface/use-shop-for-myself.context'

import { AddToCartBlock } from './add-to-cart-block'

import type { CommonStyleArrayEntity, CommonStyleEntity } from '@nori/app-kit'

type Props = {
  item: CommonStyleArrayEntity<CommonStyleEntity>
}

export function ArrayCell({ item }: Props) {
  const { styles } = item
  const { searchProductsAction } = useShopForMyselfContext()

  const [currentStyle, setCurrentStyle] = useState<
    CommonStyleEntity | undefined
  >(styles[0])

  const maxPriceSku = currentStyle?.maxPriceSku
  const hasMultipleStyles = styles.length > 1
  // @TODO: check for currentStyle?.skus?.[0] to exist
  const skuId = currentStyle?.skus?.[0]!.id

  const handleAddToCart = useCallback(
    (quantity: number) => {
      if (!skuId || !quantity) return

      searchProductsAction.handleAddStyleToCart({
        skuId,
        quantity,
      })
    },
    [skuId]
  )

  if (!currentStyle || !styles.length) return null
  return (
    <ProductListCellContainer>
      <ProductListCell item={currentStyle} onClick={() => {}} />
      <StylePrices
        prices={{
          prv: maxPriceSku?.currentPrv,
          retailPrice: maxPriceSku?.currentRetailPrice,
          salePrice: maxPriceSku?.currentSalePrice,
          stylistPrice: maxPriceSku?.currentStylistPrice,
        }}
      />

      {hasMultipleStyles && (
        <div className="my-4 flex flex-row justify-center">
          {styles.map((style) => (
            <Radio
              checked={style.id === currentStyle.id}
              value={style.id}
              key={style.id}
              onChange={() => setCurrentStyle(style)}
            />
          ))}
        </div>
      )}
      <AddToCartBlock id={skuId?.toString()} onAddToCart={handleAddToCart} />
    </ProductListCellContainer>
  )
}
