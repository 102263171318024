import {
  buildUrl,
  getBaseUrl,
  ProductErrorMapper,
  ProductErrors,
  RequestService,
  SERVICE_NAMES,
} from '@nori/app-kit'
import { Adapter, Inject } from '@nori/di'
import { resultErr, resultOk } from '@nori/result'
import { ProductListMapperService } from '~/modules/products'
import { SearchProductsMapper } from '~/modules/search-products/infra/mapper/search-products.mapper'

import type { ProductErrorInstance } from '@nori/app-kit'
import type { PromiseResult } from '@nori/result'
import type { ProductListValueObject } from '~/modules/products'
import type { SearchProductsInput } from './types/search-products.input'

@Adapter()
export class SearchProductsAdapter {
  baseUrl = getBaseUrl(SERVICE_NAMES.CATALOG_SERVICE)

  public constructor(
    @Inject() private readonly requestService: RequestService,
    @Inject() private readonly searchProductsMapper: SearchProductsMapper,
    @Inject()
    private readonly productListMapperService: ProductListMapperService
  ) {}

  public async searchProducts({
    pagination,
    searchQuery,
    isCustomerSearch,
    noShippingRestricted,
  }: SearchProductsInput): PromiseResult<
    ProductListValueObject,
    ProductErrorInstance
  > {
    try {
      const searchEndpoint = isCustomerSearch
        ? '/items/customer_search'
        : '/items/search'
      const url = buildUrl(`${this.baseUrl}${searchEndpoint}/`, {
        queryParams: this.searchProductsMapper.toSearchProductsRequest({
          pagination,
          searchQuery,
          noShippingRestricted,
        }),
      })

      const { json, ok, status } = await this.requestService.secured.get(url)

      if (ok) {
        return resultOk(
          this.productListMapperService.toProductListValueObject(json)
        )
      }

      return resultErr(ProductErrorMapper.toErrors({ json, status }))
    } catch (err: unknown) {
      return resultErr(new ProductErrors.UnexpectedError(err))
    }
  }
}
