import { ContactErrors } from '~/modules/contacts/core'

import type { ContactErrorInstance } from '~/modules/contacts/core'

export class DeleteContactErrorMapper {
  static toErrors(status: number, json: any): ContactErrorInstance {
    switch (status) {
      case 400:
        return new ContactErrors.BadRequest(json.error.message)

      case 403:
        return new ContactErrors.DeleteForbiddenError(json.errors)

      case 404:
        return new ContactErrors.NotFound(json.errors)

      case 422:
        return new ContactErrors.UnprocessedEntity(json.errors)

      default:
        return new ContactErrors.UnknownError(json)
    }
  }
}
