import { CartContactPartyErrors } from '../../core/cart-contact-part.errors'

import type { CartContactPartyErrorInstance } from '../../core/cart-contact-part.errors'

export class CartContactErrorMapper {
  static toContactErrors(
    status: number,
    json: any
  ): CartContactPartyErrorInstance {
    switch (status) {
      case 400:
        return new CartContactPartyErrors.BadRequestAddToContact(
          json.error.message
        )

      case 404:
        return new CartContactPartyErrors.NotFoundAddToContact(json.errors)

      default:
        return new CartContactPartyErrors.UnknownErrorAddToContact()
    }
  }

  static toMailingErrors(
    status: number,
    json: any
  ): CartContactPartyErrorInstance {
    switch (status) {
      case 400:
        return new CartContactPartyErrors.BadRequestAddToMailing(
          json.error.message
        )

      case 404:
        return new CartContactPartyErrors.NotFoundAddToMailing(json.errors)

      default:
        return new CartContactPartyErrors.UnknownErrorAddToMailing()
    }
  }
}
