import { useMemo } from 'react'

import { observer } from '@nori/di'
import { Icon } from '@nori/ui-kit'
import { useHeaderContext } from '~/modules/header/interface/use-header-context'
import { useOrdersListContext } from '~/modules/orders-list/interface/context/use-orders-list-context'

type Props = {
  isMobile?: boolean
}

export const CartIcon = observer(({ isMobile }: Props) => {
  const {
    ordersListAction: { handleRedirectToCreatePersonalOrder },
  } = useOrdersListContext()
  const [countTextColor, counterBgColor, borderColor] = useMemo(
    () => [
      isMobile ? 'text-white' : 'text-primary-900',
      isMobile ? 'bg-primary-900' : 'bg-white',
      isMobile ? 'border-primary-50' : 'border-primary-900',
    ],
    [isMobile]
  )
  const {
    headerStore: { personalCartItemsCounter },
  } = useHeaderContext()
  const counterClassNames = `absolute rounded-full ${counterBgColor} ${countTextColor} text-xxs
    h-[17px] w-[19px] flex items-center justify-center font-bold
    -top-[6px] -right-[6px] border-[1.5px] ${borderColor}`

  return (
    <button
      className="relative ml-auto mr-2 h-[26px] w-[26px] min-w-[26px] sm:mr-8"
      onClick={handleRedirectToCreatePersonalOrder}
    >
      <Icon name="Cart" size="auto" />
      {personalCartItemsCounter > 0 && (
        <div className={counterClassNames}>{personalCartItemsCounter}</div>
      )}
    </button>
  )
})
