import { ItemPrice } from '@nori/app-kit'
import { useTranslations } from '@nori/lang-i18n'

import { ProductItemTotalPrice } from './product-item-total-price'

import type { ItemPriceProps } from './product-item-price-types'

export function CartItemPriceMobile({
  retailPrice,
  salePrice,
  price,
  totalRetailPrice,
  totalSalePrice,
  promotionalDiscounts,
  combinedCredits,
  dsrDiscount,
  hostessDiscountPercentage,
  hostessDiscountValue,
  productCredits,
  totalPrice,
  currency,
  totalDiscounts,
}: ItemPriceProps) {
  const t = useTranslations('cart.productList')

  return (
    <div className="space-between flex w-full gap-4 font-normal">
      <div className="flex flex-1 flex-col gap-0.5 text-left">
        <span className="text-xs font-normal text-gray-500">
          {t('retail')}:
        </span>
        <div className="flex flex-col gap-0.5 text-sm">
          <ItemPrice
            price={price}
            retailPrice={retailPrice}
            salePrice={salePrice}
            data-test-id="item"
            isBold
            isCartPrice
          />
        </div>
      </div>
      <div className="flex flex-1 flex-col gap-0.5 text-right">
        <span className="text-xs font-normal text-gray-500">{t('total')}:</span>
        <ProductItemTotalPrice
          totalRetailPrice={totalRetailPrice}
          totalSalePrice={totalSalePrice}
          promotionalDiscounts={promotionalDiscounts}
          totalDiscounts={totalDiscounts}
          hostessDiscountValue={hostessDiscountValue}
          hostessDiscountPercentage={hostessDiscountPercentage}
          productCredits={productCredits}
          combinedCredits={combinedCredits}
          dsrDiscount={dsrDiscount}
          totalPrice={totalPrice}
          currency={currency}
        />
      </div>
    </div>
  )
}
