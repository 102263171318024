import { differenceInYears } from '@nori/date'
import { Service } from '@nori/di'
import { getBlock } from '@nori/lang-i18n'
import { type Result, resultErr, resultOk } from '@nori/result'

const MIN_VALID_DAY = 1
const MAX_VALID_DAY = 31

const MIN_VALID_MONTH = 1
const MAX_VALID_MONTH = 12

const MIN_VALID_YEAR = 1900
const MIN_VALID_AGE = 18

type BirthdayInput = { day: number; month: number; year: number }

@Service()
export class BirthdayGuardService {
  validateBirthday({
    day,
    month,
    year,
  }: BirthdayInput): Result<
    void,
    { field: keyof BirthdayInput; error: string }
  > {
    const error = getBlock('birthdayGuard.errors')('invalid')

    if (isNaN(day) || day < MIN_VALID_DAY || day > MAX_VALID_DAY) {
      return resultErr({ field: 'day', error })
    }

    if (isNaN(month) || month < MIN_VALID_MONTH || month > MAX_VALID_MONTH) {
      return resultErr({ field: 'month', error })
    }

    if (isNaN(year) || year < MIN_VALID_YEAR) {
      return resultErr({ field: 'year', error: 'Invalid' })
    }

    const isValidDayOfMonth = this.validateMaxDayOfMonth({ day, month, year })
    if (!isValidDayOfMonth) return resultErr({ field: 'day', error })

    if (
      differenceInYears(Date.now(), new Date(year, month, day)) < MIN_VALID_AGE
    ) {
      return resultErr({
        field: 'year',
        error: getBlock('birthdayGuard.errors')('minAge'),
      })
    }

    return resultOk(undefined)
  }

  validateMaxDayOfMonth({
    year,
    month,
    day,
  }: BirthdayInput): Result<void, { max: number }> {
    const daysInMonth = new Date(year, month, 0).getDate()
    if (day > daysInMonth) {
      return resultErr({ max: daysInMonth })
    }

    return resultOk(undefined)
  }
}
