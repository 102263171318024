import {
  type GetRequestPagination,
  type ProductErrorInstance,
  ProductTypeCode,
} from '@nori/app-kit'
import { Store } from '@nori/di'

import type { CommonBundleEntity } from '@nori/app-kit'
import type { ProductListValueObject } from '~/modules/products/core/product-list.value-object'

const INITIAL_PAGINATION_VALUE: GetRequestPagination = {
  page: 1,
  perPage: 10,
}

@Store()
export class SearchProductsStore {
  isLoading = false
  isLoadingNextPage = false
  error?: ProductErrorInstance = undefined

  list?: ProductListValueObject = undefined

  searchQuery = ''
  appliedSearchQuery = ''

  get pagination(): GetRequestPagination {
    return this.list?.pagination ?? INITIAL_PAGINATION_VALUE
  }

  get isDataLoaded(): boolean {
    if (this.isLoading) return false
    return !!this.list
  }

  get isDataEmpty(): boolean {
    if (!this.isDataLoaded) return false
    return this.list?.items.length === 0
  }

  get isLastPage(): boolean {
    if (!this.list) return false

    const totalPages = this.list.pagination.totalPages
    if (totalPages <= this.pagination.page) return true
    return false
  }

  getBundleById(id: number): CommonBundleEntity | undefined {
    return this.list?.items.find((product): product is CommonBundleEntity => {
      if (product.typeCode === ProductTypeCode.Bundle) {
        return product.id === id
      }
      return false
    })
  }

  setSearchQuery(searchQuery: string): void {
    this.searchQuery = searchQuery
  }

  setAppliedSearchQuery(searchQuery: string): void {
    this.appliedSearchQuery = searchQuery
  }

  setList(list?: ProductListValueObject): void {
    this.list = list
  }

  setIsLoading(isLoading: boolean): void {
    this.isLoading = isLoading
  }

  setIsLoadingNextPage(value: boolean): void {
    this.isLoadingNextPage = value
  }

  setError(error?: ProductErrorInstance): void {
    this.error = error
  }
}
