import { AddressMapperService, EventMapperService } from '@nori/app-kit'
import { NoriDate } from '@nori/date'
import { Inject, Service } from '@nori/di'

import type { EventHostessEntity } from '@nori/app-kit'
import type { HostessPartyEntity } from '../../core/entities/hostess-party.entity'
import type { PartyEntity } from './../../core/entities/party.entity'

@Service()
export class PartyMapper {
  constructor(
    @Inject() private readonly addressMapperService: AddressMapperService,
    @Inject() private readonly eventMapperService: EventMapperService
  ) {}

  toPartyEntity(party: any): PartyEntity {
    const hostess: EventHostessEntity =
      this.eventMapperService.toEventHostessEntity(party.hostess)

    return {
      id: party.id,
      address: this.addressMapperService.toEventAddressValueObject(
        party.address
      ),
      addressSameAsHostess: party.address_same_as_hostess,
      allowRsvp: party.allow_rsvp,
      closedOn: new NoriDate(party.closed_on),
      countdownStopsShowingAt: new NoriDate(party.countdown_stops_showing_at),
      createdAt: new NoriDate(party.created_at),
      dsrFullName: party.dsr_full_name,
      dsrId: party.dsr_id,
      extraUrl: party.extra_url,
      hostess,
      hostessContactEmail: party.hostess_contact_email || '',
      isHostessEmailProvided: party.hostess.email_provided,
      hostessContactId: party.hostess_contact_id,
      isFrozen: party.is_frozen,
      isSelfHosted: party.is_selfhosted,
      location: party.location,
      openOn: party.open_on ? new NoriDate(party.open_on) : party.open_on,
      ordersCount: party.orders_count,
      partyAt: party.party_at ? new NoriDate(party.party_at) : party.party_at,
      partyName: party.party_name,
      partyPath: party.party_path
        ? party.party_path.replace('sites/', '')
        : undefined,
      partyType: this.eventMapperService.toEventTypeValueObject(
        party.party_type
      ),
      placedOrdersCount: party.placed_orders_count,
      placedTotalRetailSales: party.placed_total_retail_sales,
      referral: party.referral,
      rsvpsCount: party.rsvps_count,
      rsvpsYesCount: party.rsvps_yes_count,
      store: party.store,
      updatedAt: new NoriDate(party.updated_at),
      urlCode: party.url_code,
      referringPartyId: party.referring_party_id ?? undefined,
      earnedRewards: party.earned_rewards?.reward_value,
      periodExtended: !!party.period_extended,
      bookingEvents:
        party.booking_events?.map((event: any) => ({
          eventId: event.event_id,
          hostName: `${event.host_first_name} ${event.host_last_name}`,
        })) ?? [],
      extraUrlLabel: party.extra_url_label ?? undefined,
    }
  }

  toHostessPartyEntity(data: any): HostessPartyEntity {
    return {
      id: data.id,
      expirationDate: data.expiration_date,
      hostessCredits: data.hostess_credits,
      hostessDiscounts: data.hostess_discounts,
      productCredits: data.product_credits,
    }
  }
}
