import { useMemo } from 'react';
export function useSwitch({ onChange , active  }) {
    const context = useMemo(()=>{
        return {
            handleChange: (id)=>{
                onChange == null ? void 0 : onChange(id);
            },
            active
        };
    }, [
        onChange,
        active
    ]);
    return {
        context
    };
}
