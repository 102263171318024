import { useEffect } from 'react'

import {
  StickyBottomButton,
  useI18nContext,
  useSearchParams,
} from '@nori/app-kit'
import { observer } from '@nori/di'
import { useTranslations } from '@nori/lang-i18n'
import {
  Button,
  ContentBlock,
  Loader,
  PageContainer,
  PageHeader,
  Swap,
} from '@nori/ui-kit'
import { useCartContext } from '~/modules/cart'
import { QuickAdd } from '~/modules/cart-contact-party/ui/quick-add/quick-add'
import { usefulLinksData } from '~/modules/dashboard/interface/dashboard-useful-links/useful-links-data'
import { PageLayout } from '~/modules/page-layout'
import { CartBanner } from '~/modules/penny/ui/cart-banner/cart-banner'
import { useShopForMyselfContext } from '~/modules/shop-for-myself/interface/use-shop-for-myself.context'
import { UserCredits } from '~/modules/user-credits'

import { OrderSummary } from './order-summary/order-summary'
import { ProductList } from './product-list'
import { SearchInput } from './search-input'
import { useInitSearchQuery } from './use-init-search-query'

type SearchParams = { 'with-personal-cart'?: 'true' }

export const ShopForMyselfPage = observer(() => {
  const t = useTranslations('shopForMyself')
  const { shopForMyselfAction, shopForMyselfStore } = useShopForMyselfContext()
  const { cartStore, cartAction } = useCartContext()
  useInitSearchQuery()
  const { i18nStore } = useI18nContext()

  const urlCode = cartStore.cart?.customerURLCode
  const isLoadPersonalCart =
    useSearchParams<SearchParams>()['with-personal-cart'] === 'true'

  const onCatalogButtonClick = () => {
    const link =
      usefulLinksData.productInfo.currentCatalog[i18nStore.currentLocale]
    window.open(link, '_blank')
  }

  useEffect(() => {
    shopForMyselfAction.handleInitialLoad(isLoadPersonalCart)
  }, [isLoadPersonalCart, urlCode])

  return (
    <PageLayout>
      {!cartStore.isCustomerCart && (
        <PageHeader
          title={t('quickAdd')}
          rightSplittedTitle={t('personalOrder')}
        />
      )}

      <Swap
        is={shopForMyselfStore.isLoading}
        isSlot={
          <div className="lg:mt-[104px]">
            <Loader />
          </div>
        }
      >
        {cartStore.isCustomerCart && (
          <div className=" bg-primary-50 p-5 lg:h-28">
            <div className="mx-auto my-0 flex h-full max-w-main-content max-md:flex-col md:items-center md:gap-24 lg:items-center lg:gap-32">
              <div className="flex flex-col no-underline">
                <p className="whitespace-nowrap text-lg font-bold text-primary-900 md:text-2xl">
                  {t('quickAdd')}
                </p>
              </div>
              <div className="flex min-w-0 max-md:mt-4 max-md:flex-col md:flex-1 md:gap-20 lg:flex-row lg:gap-32">
                <QuickAdd />
              </div>
            </div>
          </div>
        )}
        <div className="lg:mx-auto lg:max-w-main-content">
          <CartBanner
            catalogButtonSlot={
              <Button
                onClick={onCatalogButtonClick}
                size="sm"
                iconLeft="BookOpen"
                type="button"
              >
                {t('currentCatalog')}
              </Button>
            }
          />

          <div className="my-7 max-sm:my-0" />
          <UserCredits />
        </div>
        <PageContainer>
          <ContentBlock>
            <Swap is={cartStore.cartData.isLoading} isSlot={<Loader />}>
              <div className="mt-12 flex flex-row justify-between max-md:mt-0 max-md:flex-col">
                <div className="mb-6 flex flex-1 flex-col lg:mb-0 lg:mr-10">
                  <SearchInput />
                  <ProductList />
                </div>
                <OrderSummary />
              </div>
            </Swap>
          </ContentBlock>
        </PageContainer>
        <StickyBottomButton
          cart={cartStore.cart}
          totalItemsCount={cartStore.totalItemsCount}
          buttonSlot={
            <div className="min-w-32">
              <Button onClick={cartAction.handleViewCart} widthType="full">
                {t('cart')}
              </Button>
            </div>
          }
        />
      </Swap>
    </PageLayout>
  )
})
