function polarToCartesian({ centerX , centerY , angleInDegrees , radius  }) {
    const angleInRadians = (angleInDegrees - 90) * Math.PI / 180.0;
    return {
        x: centerX + radius * Math.cos(angleInRadians),
        y: centerY + radius * Math.sin(angleInRadians)
    };
}
export function describeArc({ x , y , startAngle , endAngle , radius  }) {
    const start = polarToCartesian({
        centerX: x,
        centerY: y,
        angleInDegrees: endAngle,
        radius
    });
    const end = polarToCartesian({
        centerX: x,
        centerY: y,
        angleInDegrees: startAngle,
        radius
    });
    const largeArcFlag = endAngle - startAngle <= 180 ? '0' : '1';
    const d = [
        'M',
        start.x,
        start.y,
        'A',
        radius,
        radius,
        0,
        largeArcFlag,
        0,
        end.x,
        end.y
    ].join(' ');
    return d;
}
