import { getStyleItemName } from '@nori/app-kit'
import { useTranslations } from '@nori/lang-i18n'
import { Swap } from '@nori/ui-kit'

import { NextOrderAddonEditor } from './next-order-addon-editor'

import type { SubscriptionEntity } from '~/modules/subscription'

type Props = {
  subscription: SubscriptionEntity
  isEditMode?: boolean
  itemQty: string
  onItemQtyChange?: (qty: string) => void
  handleSave?: () => void
  styleName?: string
}

export const NextOrderAddonInfo = ({
  subscription,
  isEditMode,
  itemQty,
  onItemQtyChange,
  handleSave,
  styleName,
}: Props) => {
  const t = useTranslations('autosaveSettings')
  return (
    <div className="flex flex-col">
      <span className="mb-2 text-base font-bold text-gray-700">
        {getStyleItemName({
          productName: subscription.productName,
          styleName,
        })}
      </span>
      <div className="max-lg:hidden">
        <Swap
          is={isEditMode}
          isSlot={
            <div className="mt-1">
              <NextOrderAddonEditor
                itemQty={itemQty}
                onItemQtyChange={onItemQtyChange}
                handleSave={handleSave}
              />
            </div>
          }
        >
          <div className="flex flex-col gap-1 text-sm font-medium text-gray-500">
            {subscription.bundle?.name && (
              <span>{subscription.bundle.name}</span>
            )}
            <span>{subscription.skuCode}</span>
            <span>{t('qty', { qty: itemQty })}</span>
          </div>
        </Swap>
      </div>
      <div className="lg:hidden">
        <div className="flex flex-col gap-1 text-sm font-medium text-gray-500">
          {subscription.bundle?.name && <span>{subscription.bundle.name}</span>}
          <span>{subscription.skuCode}</span>
          <span>{t('qty', { qty: itemQty })}</span>
        </div>
      </div>
    </div>
  )
}
