const LOCAL_DOMAIN = 'localhost';
const AUTO_TESTS_DOMAIN = 'nori-lounge';
const ALL_DOMAINS = [
    LOCAL_DOMAIN,
    AUTO_TESTS_DOMAIN
];
export function checkHostInBlacklist() {
    if (typeof window === 'undefined') return false;
    return ALL_DOMAINS.some((domain)=>window.location.host.includes(domain));
}
