import { Inject, Service } from '@nori/di'
import { isErr } from '@nori/result'
import { createLogger } from '~/infra/create-logger'

import { NoteAdapter } from '../infra/note.adapter'

import { NotesStore } from './notes.store'

import type { CreateNotePayloadValueObject } from '../core/create-note.value-object'
import type { UpdateNotePayloadValueObject } from '../core/update-note.value-object'

const logger = createLogger('update-note.service')

@Service()
export class NotesService {
  constructor(
    @Inject() private readonly noteAdapter: NoteAdapter,
    @Inject() private readonly notesStore: NotesStore
  ) {}

  async handleUpdateNote(contactId: number): Promise<void> {
    const editedNote = this.notesStore.editedNoteMap[contactId]
    const content = this.notesStore.contentMap[contactId] || ''

    if (!editedNote) return

    this.notesStore.setIsLoading(contactId, true)
    this.notesStore.setError(contactId, undefined)

    const payload: UpdateNotePayloadValueObject = {
      id: editedNote.id,
      content,
      createdAt: editedNote.createdAt,
      updatedAt: new Date(),
    }

    const response = await this.noteAdapter.updateNote(payload)

    if (isErr(response)) {
      logger.error(response.error.key, response.error)
      this.notesStore.setError(contactId, response.error)
      this.notesStore.setIsLoading(contactId, false)

      return
    }

    const updatedNotes =
      this.notesStore.notesMap[contactId]?.map((note) =>
        note.id === editedNote.id ? response.data : note
      ) || []

    this.notesStore.setNotes(contactId, updatedNotes)
    this.notesStore.setEditedNote(contactId, undefined)
    this.notesStore.setContent(contactId, '')
    this.notesStore.setIsLoading(contactId, false)
  }

  async handleCreateNote(contactId: number): Promise<void> {
    const content = this.notesStore.contentMap[contactId]
    if (!content) return

    this.notesStore.setIsLoading(contactId, true)
    this.notesStore.setError(contactId, undefined)

    const payload: CreateNotePayloadValueObject = {
      contactId,
      content,
    }

    const response = await this.noteAdapter.createNote(payload)

    if (isErr(response)) {
      logger.error(response.error.key, response.error)
      this.notesStore.setError(contactId, response.error)
      this.notesStore.setIsLoading(contactId, false)

      return
    }

    const updatedNotes = [
      ...(this.notesStore.notesMap[contactId] || []),
      response.data,
    ]

    this.notesStore.setNotes(contactId, updatedNotes)
    this.notesStore.setContent(contactId, '')
    this.notesStore.setIsLoading(contactId, false)
  }

  async handleDeleteNote(contactId: number, noteId: number): Promise<void> {
    this.notesStore.setIsLoading(contactId, true)
    this.notesStore.setError(contactId, undefined)

    const response = await this.noteAdapter.deleteNote(noteId)

    if (isErr(response)) {
      logger.error(response.error.key, response.error)
      this.notesStore.setError(contactId, response.error)
      this.notesStore.setIsLoading(contactId, false)

      return
    }

    const updatedNotes =
      this.notesStore.notesMap[contactId]?.filter(
        (item) => item.id !== noteId
      ) || []

    this.notesStore.setNotes(contactId, updatedNotes)
    this.notesStore.setIsLoading(contactId, false)
  }
}
