import { getBaseUrl, RequestService, SERVICE_NAMES } from '@nori/app-kit'
import { Adapter, Inject } from '@nori/di'
import { resultErr, resultOk } from '@nori/result'

import { CreateNoteErrors } from '../core/create-note.errors'
import { DeleteNoteErrors } from '../core/delete-note.errors'
import { UpdateNoteErrors } from '../core/update-note.errors'

import { CreateNoteErrorsMapper } from './mappers/create-note-errors.mapper'
import { CreateNoteRequestMapper } from './mappers/create-note-request.mapper'
import { DeleteNoteErrorsMapper } from './mappers/delete-note-errors.mapper'
import { NoteMapper } from './mappers/note.mapper'
import { UpdateNoteErrorsMapper } from './mappers/update-note-errors.mapper'
import { UpdateNoteRequestMapper } from './mappers/update-note-request.mapper'

import type { PromiseResult } from '@nori/result'
import type { CreateNoteErrorInstance } from '../core/create-note.errors'
import type { CreateNotePayloadValueObject } from '../core/create-note.value-object'
import type { DeleteNoteErrorInstance } from '../core/delete-note.errors'
import type { NoteEntity } from '../core/note.entity'
import type { UpdateNoteErrorInstance } from '../core/update-note.errors'
import type { UpdateNotePayloadValueObject } from '../core/update-note.value-object'

@Adapter()
export class NoteAdapter {
  private baseUrl = getBaseUrl(SERVICE_NAMES.MY_BUSINESS_SERVICE)

  constructor(
    @Inject() private readonly requestService: RequestService,
    @Inject() private readonly noteMapper: NoteMapper
  ) {}

  async createNote(
    data: CreateNotePayloadValueObject
  ): PromiseResult<NoteEntity, CreateNoteErrorInstance> {
    const url = `${this.baseUrl}/notes`
    const input = CreateNoteRequestMapper.toInput(data)

    try {
      const { ok, json, status } = await this.requestService.secured.post(url, {
        body: input,
      })

      if (!ok) {
        return resultErr(CreateNoteErrorsMapper.toErrors(json, status))
      }

      return resultOk(this.noteMapper.toNoteEntity(json))
    } catch (err: unknown) {
      return resultErr(new CreateNoteErrors.UnexpectedError(err))
    }
  }

  async deleteNote(id: number): PromiseResult<void, DeleteNoteErrorInstance> {
    const url = `${this.baseUrl}/notes/${id}`

    try {
      const { ok, json, status } = await this.requestService.secured.delete(url)

      if (!ok) {
        return resultErr(DeleteNoteErrorsMapper.toErrors(json, status))
      }

      return resultOk(undefined)
    } catch (err: unknown) {
      return resultErr(new DeleteNoteErrors.UnexpectedError(err))
    }
  }

  async updateNote(
    data: UpdateNotePayloadValueObject
  ): PromiseResult<NoteEntity, UpdateNoteErrorInstance> {
    const url = `${this.baseUrl}/notes/${data.id}`
    const input = UpdateNoteRequestMapper.toInput(data)

    try {
      const { ok, status, json } = await this.requestService.secured.put(url, {
        body: input,
      })

      if (!ok) {
        return resultErr(UpdateNoteErrorsMapper.toErrors(json, status))
      }

      return resultOk(this.noteMapper.toNoteEntity(json))
    } catch (err: unknown) {
      return resultErr(new UpdateNoteErrors.UnexpectedError(err))
    }
  }
}
