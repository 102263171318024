import { useTranslations } from '@nori/lang-i18n'
import { Input, Swap } from '@nori/ui-kit'
import {
  MAX_PRODUCT_QUANTITY,
  MIN_PRODUCT_QUANTITY,
} from '~/modules/cart/cart.constants'

type Props = {
  isEditMode: boolean
  currentQuantity: string
  productQuantity: number
  onQuantityChange?: (value: string) => void
  skuName?: string
  skuCode?: string
  maxInCart?: number
}

export function ProductItemQuantity({
  isEditMode,
  currentQuantity,
  productQuantity,
  onQuantityChange,
  maxInCart,
}: Props) {
  const t = useTranslations('cart.productList')

  return (
    <Swap
      is={isEditMode}
      isSlot={
        <div className="w-20 max-lg:w-[88px] lg:mt-2">
          <Input
            label={t('quantity')}
            size="small"
            type="integer"
            min={MIN_PRODUCT_QUANTITY}
            max={maxInCart ?? MAX_PRODUCT_QUANTITY}
            value={currentQuantity}
            onChange={onQuantityChange}
            hideHelperBlock
            data-test-id={'quantity-input'}
          />
        </div>
      }
    >
      <p
        data-test-id="item-qty"
        className="mt-2 text-sm font-medium text-gray-500"
      >
        {t('quantityFull')}: {productQuantity}
      </p>
    </Swap>
  )
}
