import { Store } from '@nori/di'

import type { ProductListValueObject } from '~/modules/products'
import type { SubscriptionErrorInstance } from '~/modules/subscription'

@Store()
export class NextOrderAddItemStore {
  readonly initialPage = 1
  readonly perPage = 10

  isOpen = false
  isLoading = false
  isLoadingMore = false

  isLoadingById: Record<number, boolean> = {}

  products?: ProductListValueObject = undefined
  error?: SubscriptionErrorInstance = undefined
  searchQuery = ''

  get page(): number {
    return this.products?.pagination.page ?? this.initialPage
  }

  get hasMore(): boolean {
    return !this.products || this.products.pagination.totalPages > this.page
  }

  setIsItemLoading(id: number, isLoading: boolean): void {
    this.isLoadingById[id] = isLoading
  }

  setIsLoadingMore(isLoadingMore: boolean): void {
    this.isLoadingMore = isLoadingMore
  }

  setSearchQuery(query: string): void {
    this.searchQuery = query
  }

  setProducts(products?: ProductListValueObject): void {
    this.products = products
  }

  setIsOpen(isOpen: boolean): void {
    this.isOpen = isOpen
  }

  setIsLoading(isLoading: boolean): void {
    this.isLoading = isLoading
  }

  setError(error?: SubscriptionErrorInstance): void {
    this.error = error
  }
}
