/* eslint-disable max-lines */
import { PaymentMethodService, PaymentMethodStore } from '@nori/app-kit'
import { Action, Inject } from '@nori/di'
import { getBlock } from '@nori/lang-i18n'
import { NotifyAction } from '@nori/notify'
import { isErr } from '@nori/result'
import { FailedValidationError } from '@nori/validator'
import { createLogger } from '~/infra/create-logger'
import { SubscriptionService } from '~/modules/subscription'

import { NextOrderService } from '../service/next-order.service'
import { NextOrderStore } from '../store/next-order.store'

import { SubscriptionsAction } from './subscriptions.actions'

import type { AddressValueObject } from '@nori/app-kit'
import type { NoriDate } from '@nori/date'
import type { DomainError } from '@nori/errors'
import type { PromiseResult } from '@nori/result'
import type { FailedValidationValueObject } from '~/modules/subscription/core/failed-validation.value-object'
import type { UpdateSubscriptionsInput } from '../../core/update-subscriptions.input'

const logger = createLogger('next-order.action')

@Action()
export class NextOrderAction {
  constructor(
    @Inject() private readonly subscriptionsAction: SubscriptionsAction,
    @Inject() private readonly subscriptionService: SubscriptionService,
    @Inject() private readonly nextOrderStore: NextOrderStore,
    @Inject() private readonly paymentMethodService: PaymentMethodService,
    @Inject() private readonly notifyAction: NotifyAction,
    @Inject() private readonly nextOrderService: NextOrderService,
    @Inject() private readonly paymentMethodStore: PaymentMethodStore
  ) {}

  async handleInitialLoad(userId?: number): Promise<void> {
    this.nextOrderStore.setIsLoading(true)

    await Promise.all([
      this.nextOrderService.loadNextOrder(userId),
      this.subscriptionsAction.handleInitialLoad(userId),
      this.loadPaymentMethods(),
    ])

    this.nextOrderStore.setUserId(userId)
    this.nextOrderStore.setIsLoading(false)
  }

  async handleNextOrderDateChange({
    dates,
    userId,
  }: {
    dates: NoriDate[]
    userId?: number
  }): Promise<void> {
    if (!dates[0]) return

    this.nextOrderStore.setIsLoading(true)

    await this.changeDate({ date: dates[0], userId })

    this.nextOrderStore.setIsLoading(false)
  }

  async handleToggleShouldApplyCredits(): Promise<void> {
    if (this.nextOrderStore.isShouldApplyCreditsPending) return
    this.nextOrderStore.setShouldApplyCreditsPending(true)

    await this.toggleShouldApplyCredits()

    this.nextOrderStore.setShouldApplyCreditsPending(false)
  }

  async handleSaveShipping(): Promise<void> {
    this.nextOrderStore.shippingAddressConfig.setIsLoading(true)

    await this.saveShipping()

    this.nextOrderStore.shippingAddressConfig.setIsLoading(false)
  }

  async handleRemoveSubscription(id: number): Promise<void> {
    this.nextOrderStore.setIsLoading(true)

    await this.removeSubscription(id)

    this.nextOrderStore.setIsLoading(false)
  }

  handleShippingModalOpen(): void {
    this.nextOrderStore.setIsShippingAddressModalOpen()
  }

  handleShippingModalClose(): void {
    this.nextOrderStore.setIsShippingAddressModalClose()
  }

  async handleUpdateSubscription(
    input: UpdateSubscriptionsInput
  ): Promise<void> {
    this.nextOrderStore.setIsLoading(true)

    await this.updateSubscription({
      ...input,
      userId: this.nextOrderStore.userId,
    })

    this.nextOrderStore.setIsLoading(false)
  }

  async handleCancelAutoSave({ id }: { id?: number }): Promise<void> {
    if (!id) return

    this.nextOrderStore.setIsLoading(true)

    await this.updateSubscriptionStatus({
      id,
      userId: this.nextOrderStore.userId,
      activeStatus: false,
    })

    this.nextOrderStore.setIsLoading(false)
  }

  async handleSkipSubscription({
    id,
    userId,
  }: {
    id?: number
    userId?: number
  }): Promise<void> {
    this.nextOrderStore.setIsLoading(true)

    await this.skipSubscription({ id, userId })

    this.nextOrderStore.setIsLoading(false)
  }

  private async updateSubscriptionStatus({
    id,
    userId,
    activeStatus,
  }: {
    id: number
    userId?: number
    activeStatus: boolean
  }): Promise<void> {
    if (!id) return

    const result = await this.subscriptionService.updateSubscriptionStatus(
      id,
      activeStatus
    )
    if (isErr(result)) {
      logger.error(result.error.key, result.error)
      this.notifyAction.handleAddNotify({
        type: 'danger',
        title: getBlock('autosaveSettings.errors')('updateSubscriptionError'),
      })
      return
    }

    await this.nextOrderService.loadNextOrder(userId)
  }

  private async updateSubscription(
    input: UpdateSubscriptionsInput
  ): Promise<void> {
    if (!input.ids) return

    const result = await this.subscriptionService.updateSubscriptions(input)

    if (isErr(result)) {
      logger.error(result.error.key, result.error)
      this.notifyAction.handleAddNotify({
        type: 'danger',
        title: getBlock('autosaveSettings.errors')('updateSubscriptionError'),
      })
      return
    }

    if (result.data.type === 'validationFailed') {
      FailedValidationError.handleValidatorErrors({
        errors: result.data.data,
        validator:
          this.nextOrderStore.shippingAddressConfig.shippingAddressValidator,
      })
      return
    }

    await this.nextOrderService.loadNextOrder(input.userId)
  }

  private async toggleShouldApplyCredits(): Promise<void> {
    if (!this.nextOrderStore.nextOrder) return

    const result = await this.subscriptionService.setShouldApplyCredits({
      applyCredits: !this.nextOrderStore.nextOrder.applyCredits,
    })

    if (isErr(result)) {
      logger.error(result.error.key, result.error)
      this.notifyAction.handleAddNotify({
        type: 'danger',
        title: getBlock('autosaveSettings.errors')('applyCreditsChangeError'),
      })
      return
    }

    this.nextOrderStore.setNextOrder({
      ...this.nextOrderStore.nextOrder,
      applyCredits: result.data,
    })
  }

  private async changeDate({
    date,
    userId,
  }: {
    date: NoriDate
    userId?: number
  }): Promise<void> {
    const ids = this.nextOrderStore.subscriptionIdsWithoutOneOffs
    if (!this.nextOrderStore.nextOrder || !ids?.length) return

    const result = await this.subscriptionService.updateSubscriptions({
      ids,
      nextCartAt: date,
    })

    if (isErr(result) || result.data.type !== 'response') {
      this.notifyAction.handleAddNotify({
        type: 'danger',
        title: getBlock('autosaveSettings.errors')('nextOrderDateChangeError'),
      })
      return
    }

    await this.nextOrderService.loadNextOrder(userId)
  }

  private async removeSubscription(id: number): Promise<void> {
    const result = await this.subscriptionService.removeSubscription(id)
    if (isErr(result)) {
      logger.error(result.error.key, result.error)
      this.notifyAction.handleAddNotify({
        type: 'danger',
        title: getBlock('autosaveSettings.errors')('removeSubscriptionError'),
      })
      return
    }

    await this.nextOrderService.loadNextOrder()
  }

  private async saveShipping(): Promise<void> {
    const isOneTimeShippingAddressUpdate =
      this.nextOrderStore.shippingAddressConfig.addressType === 'one-time'
    const validationResult = this.nextOrderStore.validateAddress()

    if (!validationResult.isValid) return

    const userId = this.nextOrderStore.isUseUserIdForShippingAddress
      ? this.nextOrderStore.userId
      : undefined
    const result = isOneTimeShippingAddressUpdate
      ? await this.saveOneTimeShippingAddress(validationResult.address, userId)
      : await this.saveCurrentUserShippingAddress()

    if (isErr(result)) {
      logger.error(result.error.key, result.error)
      this.notifyAction.handleAddNotify({
        type: 'danger',
        title: getBlock('autosaveSettings.errors')('saveShippingError'),
      })
      return
    }

    if (result.data?.type === 'validationFailed') {
      FailedValidationError.handleValidatorErrors({
        errors: result.data.data,
        validator:
          this.nextOrderStore.shippingAddressConfig.shippingAddressValidator,
      })
      return
    }

    this.handleShippingModalClose()
    await this.nextOrderService.loadNextOrder(this.nextOrderStore.userId)
  }

  private async saveOneTimeShippingAddress(
    address: AddressValueObject,
    userId?: number
  ): PromiseResult<
    void | {
      data: FailedValidationValueObject
      type: 'validationFailed'
    },
    DomainError
  > {
    const requestInput = {
      address1: address.address1,
      address2: address.address2,
      city: address.city,
      country: address.country,
      state: address.state,
      zipCode: address.zipCode,
      firstName: this.nextOrderStore.shippingAddressContactInfo.firstName,
      lastName: this.nextOrderStore.shippingAddressContactInfo?.lastName,
      phoneNumber: address.phoneNumber ?? '',
      isOneTime:
        this.nextOrderStore.shippingAddressConfig.addressType === 'one-time',
      userId: userId ? userId : undefined,
    }

    return this.nextOrderStore.isOneTimeShippingAddressExists
      ? this.subscriptionService.updateSubscriptionShippingAddress({
          id: this.nextOrderStore.oneTimeShippingAddress?.id || 0,
          ...requestInput,
        })
      : this.subscriptionService.addSubscriptionOneTimeShippingAddress(
          requestInput
        )
  }

  private async saveCurrentUserShippingAddress(): PromiseResult<
    void,
    DomainError
  > {
    const result = await this.subscriptionService.deleteOneTimeShippingAddress({
      id: this.nextOrderStore.oneTimeShippingAddress?.id || 0,
    })

    return result
  }

  private async loadPaymentMethods(): Promise<void> {
    await this.paymentMethodService.loadPaymentMethods()

    this.nextOrderStore.setPaymentMethod(
      this.paymentMethodStore.defaultPaymentMethod
    )
  }

  private async skipSubscription({
    id,
    userId,
  }: {
    id?: number
    userId?: number
  }): Promise<void> {
    if (!id) return

    const result = await this.subscriptionService.skipSubscription(id)

    if (isErr(result)) {
      logger.error(result.error.key, result.error)
      this.notifyAction.handleAddNotify({
        type: 'danger',
        title: getBlock('autosaveSettings.errors')('skipSubscriptionError'),
      })
      return
    }

    await this.nextOrderService.loadNextOrder(userId)
  }
}
/* eslint-enable max-lines */
