import { useCallback, useMemo, useState } from 'react'

import { useProfileContext } from '@nori/app-kit'
import { useTranslations } from '@nori/lang-i18n'
import { useToggle } from '@nori/ui-kit'
import { AutoSaveOrderActionsEnum } from '~/modules/autosave/core/autosave-order-actions.enum'
import { useAutosaveContext } from '~/modules/autosave/interface/use-autosave-context'
import { useContactContext } from '~/modules/contacts/interface/use-contact-context'

import type { SubscriptionEntity } from '~/modules/subscription'
import type { ComponentProps } from 'react'
import type { AutoSaveOrderRowActions } from '../../autosave-order-row/autosave-order-row-actions'

type Args = {
  isActiveAutosaveTable?: boolean
  isContactDetailsPage?: boolean
  subscription: SubscriptionEntity
  onChangeStatus: (skip?: boolean) => void
}

export function useAllAutoSavetableRow({
  isActiveAutosaveTable,
  isContactDetailsPage,
  subscription,
  onChangeStatus,
}: Args) {
  const t = useTranslations('autosaveSettings')
  const { subscriptionsAction, subscriptionsStore } = useAutosaveContext()
  const { contactDetailsStore } = useContactContext()
  const { userStore } = useProfileContext()
  const [itemQuantity, setItemQuantity] = useState(`${subscription.quantity}`)
  const [itemCycleId, setItemCycleId] = useState(subscription.cycleId)
  const [itemDate, setItemDate] = useState(subscription.nextCartAt)

  const toggleEditMode = useToggle(false)
  const productInfo = useMemo(
    () =>
      subscriptionsStore.subscriptionProducts.data?.find((product) => {
        if (
          product.id === subscription.productId ||
          product.id === subscription.bundleId
        ) {
          return product
        }
      }),
    [
      subscription.bundleId,
      subscription.productId,
      subscriptionsStore.subscriptionProducts.data,
    ]
  )
  const cycleName = useMemo(
    () =>
      itemCycleId
        ? productInfo?.productSubscriptions.find(
            (item) => item.id === itemCycleId
          )?.name
        : productInfo?.productSubscriptions[0]?.name,
    [productInfo?.productSubscriptions, itemCycleId]
  )

  const skuInfo = useMemo(() => {
    if (!productInfo) return
    if (!('styles' in productInfo)) return

    return productInfo.styles
      .map((style) => style.skus)
      .flat()
      .find((sku) => sku.code === subscription.skuCode)
  }, [productInfo, subscription.skuCode])

  const handleQuantityChange = (value: string): void => {
    setItemQuantity(value)
  }

  const handleFrequencyChange = (value: number): void => {
    setItemCycleId(value)
  }

  const handleSave = useCallback(() => {
    toggleEditMode.handleClose()
    subscriptionsAction.handleUpdateSubscription({
      ids: [subscription.id],
      quantity: Number(itemQuantity),
      nextCartAt: itemDate,
      cycleId: itemCycleId,
      userId: isContactDetailsPage
        ? contactDetailsStore.contact?.customerId
        : userStore.currentUser?.id,
    })
  }, [
    contactDetailsStore.contact?.customerId,
    isContactDetailsPage,
    itemCycleId,
    itemDate,
    itemQuantity,
    subscription.id,
    subscriptionsAction,
    toggleEditMode,
    userStore.currentUser?.id,
  ])

  const retailPrice = subscription.retailPrice
    ? subscription.retailPrice
    : { cents: 0, currency: userStore.currency }
  const subscriptionPrice = subscription.subscriptionPrice
    ? subscription.subscriptionPrice
    : { cents: 0, currency: userStore.currency }
  const price = subscription.price
    ? subscription.price
    : { cents: 0, currency: userStore.currency }

  const [isAutoSaveEnabled, isOutOfStock, isAvailable] = useMemo(() => {
    const { canBeAutoshipped, available } = productInfo || {}
    const isSkuAvailable = !!skuInfo?.available
    return [
      !!canBeAutoshipped,
      !available || !isSkuAvailable,
      canBeAutoshipped && available && isSkuAvailable,
    ]
  }, [productInfo])

  const unavailableMessage = useMemo(() => {
    if (isAvailable) return
    if (!isAutoSaveEnabled) {
      return isActiveAutosaveTable
        ? t('autosaveTable.unavailable')
        : t('autosaveTable.unavailableShort')
    }
    if (isOutOfStock) {
      return t('nextOrderTable.outOfStock')
    }
  }, [isAvailable, isAutoSaveEnabled, isOutOfStock, isActiveAutosaveTable, t])

  const actionsSlotProps = useMemo<
    ComponentProps<typeof AutoSaveOrderRowActions>
  >(() => {
    if (
      !isActiveAutosaveTable &&
      ((productInfo && isOutOfStock) || !isAutoSaveEnabled)
    ) {
      return {
        availableActions: AutoSaveOrderActionsEnum.None,
      }
    }
    if (productInfo && isOutOfStock) {
      return {
        availableActions: AutoSaveOrderActionsEnum.None,
      }
    }
    if (!isAutoSaveEnabled) {
      return {
        availableActions: AutoSaveOrderActionsEnum.CancelOnly,
        handleChangeStatus: () => onChangeStatus(true),
      }
    }
    if (isActiveAutosaveTable) {
      return {
        availableActions: AutoSaveOrderActionsEnum.EditSkip,
        handleEditMode: toggleEditMode.handleOpen,
        isEditMode: toggleEditMode.isOpen,
        handleChangeStatus: onChangeStatus,
        canBeSkipped: subscription.canBeSkipped,
      }
    }
    return {
      availableActions: AutoSaveOrderActionsEnum.Reactivate,
      handleChangeStatus: () => onChangeStatus(),
    }
  }, [
    productInfo,
    isOutOfStock,
    isAutoSaveEnabled,
    isActiveAutosaveTable,
    onChangeStatus,
    toggleEditMode.handleOpen,
    toggleEditMode.isOpen,
    subscription.canBeSkipped,
  ])

  return {
    productInfo,
    itemQuantity,
    handleQuantityChange,
    handleFrequencyChange,
    itemDate,
    handleDateChange: setItemDate,
    cycleName,
    retailPrice,
    subscriptionPrice,
    price,
    handleSave,
    toggleEditMode,
    actionsSlotProps,
    unavailableMessage,
    isAvailable,
  }
}
