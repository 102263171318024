import _async_to_generator from "@swc/helpers/src/_async_to_generator.mjs";
export function sleep(delayMs) {
    return _sleep.apply(this, arguments);
}
function _sleep() {
    _sleep = _async_to_generator(function*(delayMs) {
        return new Promise((res)=>{
            setTimeout(res, delayMs);
        });
    });
    return _sleep.apply(this, arguments);
}
