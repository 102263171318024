import { useTranslations } from '@nori/lang-i18n'

export const NextOrderAddonEmpty = () => {
  const t = useTranslations('autosaveSettings')

  return (
    <div className="flex flex-col items-center justify-center gap-1 pt-5">
      <span className="text-base font-bold text-gray-500">
        {t('nextOrderTable.addSomething')}
      </span>
      <span className="text-sm font-normal text-gray-500">
        {t('nextOrderTable.enjoyFreeShipping')}
      </span>
    </div>
  )
}
