import { Routes } from '~/modules/routes'

import type { LinkWithChildren } from './header-link.type'

const EVENTS: LinkWithChildren = {
  translationKey: 'header.nav.events',
  href: Routes.events.list.path(),
  dataTestId: 'create-an-event-from-template-option',
}

const ORDERS: LinkWithChildren = {
  translationKey: 'header.nav.orders',
  href: Routes.orders.list.path(),
  dataTestId: 'create-an-event-from-template-option',
}

const CONTACTS_LINK: LinkWithChildren = {
  translationKey: 'header.nav.contacts',
  href: Routes.contacts.path(),
  dataTestId: 'create-an-event-from-template-option',
}

const REPORTS_LINKS: LinkWithChildren = {
  dataTestId: 'reports-header-dropdown',
  translationKey: 'header.nav.reports',
  children: [
    {
      translationKey: 'header.reports.myTeamMe',
      href: Routes.reports.selectedReport.path('activity'),
      dataTestId: 'reports-my-team-me',
    },
    {
      translationKey: 'header.reports.myTeamBrightStart',
      href: Routes.reports.selectedReport.path('teamBrightStart'),
      dataTestId: 'reports-my-team-bright-start',
    },
    {
      translationKey: 'header.reports.teamRelationshipManager',
      href: Routes.reports.selectedReport.path('coaching'),
      dataTestId: 'reports-team-relationship-manager',
    },
    {
      translationKey: 'header.reports.recognitionDashboard',
      href: Routes.reports.selectedReport.path('recognition'),
      dataTestId: 'reports-team-relationship-manager',
      featureFlag: 'bo-reports-menu-access',
    },
    {
      translationKey: 'header.reports.partyPipeline',
      href: Routes.reports.selectedReport.path('pipeline'),
      dataTestId: 'reports-team-relationship-manager',
      featureFlag: 'bo-reports-menu-access',
    },
  ],
}

export const DESKTOP_LINKS = [EVENTS, ORDERS, CONTACTS_LINK, REPORTS_LINKS]
export const MOBILE_LINKS = [EVENTS, ORDERS, CONTACTS_LINK, REPORTS_LINKS]
