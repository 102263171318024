import { useCallback, useState } from 'react'

import { ProductImage } from '@nori/app-kit'
import { useTranslations } from '@nori/lang-i18n'
import { useNotifyContext } from '@nori/notify'
import { Input, useToggle } from '@nori/ui-kit'
import { useCartContext } from '~/modules/cart'
import { AddProductSubscription } from '~/modules/product-subscription/ui/add-product-subscription'
import { AddToCartButton } from '~/modules/products'
import { ConsultantPrice } from '~/modules/shop-for-myself/ui/product-list/cells/add-to-cart-block/consultant-price'
import { RetailPrice } from '~/modules/shop-for-myself/ui/product-list/cells/add-to-cart-block/retail-price'
import { WishButton } from '~/modules/wishlist/ui/wish-button'

import { SelectSku } from './select-sku'

import type { CommonStyleEntity, ProductSkuEntity } from '@nori/app-kit'
import type { SetStateAction } from 'react'

type Props = {
  item: CommonStyleEntity
  pictureUrl?: string
  skus?: ProductSkuEntity[]
  callout?: string
  available?: boolean
  onAddToCart: (quantity: number) => void
  selectedSku?: ProductSkuEntity
  setSelectedSku: (value: SetStateAction<ProductSkuEntity | undefined>) => void
  selectedSubscriptionId: number
  setSelectedSubscriptionId: (id: number) => void
  subscriptionToggle: {
    isOpen: boolean
    handleOpen: () => void
    handleClose: () => void
    handleToggle: () => void
  }
}

const MIN_PRODUCT_QUANTITY = 1

export function StyleMobileCell({
  item,
  pictureUrl,
  skus,
  callout,
  available,
  onAddToCart,
  selectedSku,
  setSelectedSku,
  selectedSubscriptionId,
  setSelectedSubscriptionId,
  subscriptionToggle,
}: Props) {
  const { cartStore } = useCartContext()
  const itemToggle = useToggle()
  const { isCustomerCart } = cartStore
  const inputContainerClass = 'mr-5 max-lg:m-0  lg:mr-0 lg:w-15 max-lg:w-1/3'

  const bottomBlockClass = skus && skus.length === 1 ? 'mt-4' : ''
  const t = useTranslations('shopForMyself.productList')
  const t2 = useTranslations('cart.productList')
  const { notifyAction } = useNotifyContext()
  const [cartQuantity, setCartQuantity] = useState(`${MIN_PRODUCT_QUANTITY}`)

  const handleAddToCart = useCallback(() => {
    const num = Number(cartQuantity)
    if (isNaN(num) || num < MIN_PRODUCT_QUANTITY) {
      notifyAction.handleAddNotify({
        type: 'danger',
        title: t('invalidQuantity'),
      })
      return
    }
    onAddToCart(num)
  }, [cartQuantity, t, onAddToCart, notifyAction])

  return (
    <>
      <div
        className="flex w-full gap-3.5 max-lg:flex-col"
        onClick={itemToggle.handleOpen}
      >
        <div className="flex w-full justify-between">
          <div className="flex">
            <div className="mr-5 flex min-w-20 flex-col items-center">
              <ProductImage
                src={pictureUrl}
                alt={item.product.name}
                width={80}
                height={80}
                styleName="h-full"
              />
            </div>
            <div className="flex flex-col justify-start text-sm font-medium text-gray-500">
              <div data-test-id="item-name" className="flex flex-col gap-1">
                {callout && (
                  <div className="text-xs font-bold text-black">{callout}</div>
                )}
                <div className="text-base font-bold text-gray-700">
                  {item.product.name}
                </div>
                <div className="text-sm font-normal capitalize text-primary-900">
                  {item.name}
                </div>
                {selectedSku?.code && (
                  <div className="mt-0.5 text-xs font-medium text-gray-600">
                    {selectedSku.code}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="flex min-w-[70px] flex-col gap-2.5">
            <RetailPrice
              salePrice={
                selectedSku?.currentSalePrice ||
                item.maxPriceSku.currentSalePrice
              }
              retailPrice={
                selectedSku?.currentRetailPrice ||
                item.maxPriceSku.currentRetailPrice
              }
              isCustomerCart={isCustomerCart}
            />
            {!isCustomerCart && (
              <ConsultantPrice
                consultantPrice={
                  selectedSku?.currentStylistPrice ||
                  item.maxPriceSku.currentStylistPrice
                }
                isFullHeight={
                  !!(
                    selectedSku?.currentSalePrice ||
                    item.maxPriceSku.currentSalePrice
                  )
                }
              />
            )}
          </div>
        </div>
        {itemToggle.isOpen && (
          <>
            <div className="flex w-full flex-wrap items-start gap-y-7">
              <div className={inputContainerClass}>
                <div className="w-[88px]">
                  <Input
                    disabled={false}
                    size="small"
                    type="integer"
                    min={MIN_PRODUCT_QUANTITY}
                    value={cartQuantity.toString()}
                    onChange={setCartQuantity}
                    hideHelperBlock
                    label={t2('quantityFull')}
                    data-test-id={'quantity-input'}
                  />
                </div>
              </div>
              <SelectSku
                skuVariation={item.product.skuVariation}
                skus={skus}
                selectedSku={selectedSku}
                onSelect={(sku) => {
                  setSelectedSku(sku)
                }}
              />
            </div>
            {!!item.canBeAutoShipped && itemToggle.isOpen && (
              <div className="mb-4">
                <AddProductSubscription
                  isSubscriptionSelected={subscriptionToggle.isOpen}
                  onSubscriptionClose={subscriptionToggle.handleClose}
                  onSubscriptionOpen={subscriptionToggle.handleOpen}
                  onSubscriptionChange={(id: number) =>
                    setSelectedSubscriptionId(id)
                  }
                  selectedSubscriptionId={selectedSubscriptionId}
                  item={item}
                />
              </div>
            )}
            <div
              className={`flex items-center justify-between ${bottomBlockClass}`}
            >
              <div className="mr-5 w-full">
                <AddToCartButton
                  id={selectedSku?.id.toString()}
                  isDisabled={!selectedSku}
                  isOutOfStock={!available}
                  onClick={handleAddToCart}
                />
              </div>
              <WishButton skuId={selectedSku?.id} />
            </div>
          </>
        )}
      </div>
    </>
  )
}
