import { dateFormat } from '@nori/app-kit'
import { observer } from '@nori/di'
import { useTranslations } from '@nori/lang-i18n'
import { Icon, Swap } from '@nori/ui-kit'
import { useContactContext } from '~/modules/contacts/interface/use-contact-context'

import type { ContactSelectorProps } from '../contact-selector'

type Props = Pick<ContactSelectorProps, 'onSelectContact'>

export const ContactList = observer(({ onSelectContact }: Props) => {
  const t = useTranslations('contact')
  const { contactSelectorStore, contactSelectorAction } = useContactContext()

  return (
    <Swap is={!contactSelectorStore.searchQuery} isSlot={null}>
      {contactSelectorStore.contacts?.contacts.map((contact) => {
        const address = [
          contact.currentAddress.city?.trim(),
          contact.currentAddress.state?.trim(),
        ]
          .filter((item) => !!item)
          .join(', ')

        return (
          <div
            key={contact.id}
            data-test-id={`contact-option_${contact.id}`}
            className="flex cursor-pointer flex-col gap-1 border-b border-gray-200 py-3 text-sm text-gray-500 hover:bg-gray-50"
            onClick={() => {
              onSelectContact(contact)
              contactSelectorAction.handleDropSearchQuery()
            }}
          >
            <div className="font-bold text-primary-900">
              {contact.firstName} {contact.lastName}
            </div>
            {contact.isEmailProvided !== false && <div>{contact.email}</div>}
            <div>{address}</div>

            {contact.endEarningPeriod && (
              <div className="flex gap-2">
                <Icon name="ClockOutline" />
                {t('earningPeriodEnds')}{' '}
                {dateFormat(contact.endEarningPeriod, 'MM/dd')}
              </div>
            )}
          </div>
        )
      })}
    </Swap>
  )
})
