import type { EditContactInput } from '~/modules/contacts/infra/types/edit/edit-contact.input'
import type { PartialDeep } from 'type-fest'
import type { EditContactRequest } from '../types/edit/edit-contact.request'

export class EditContactRequestMapper {
  static toRequestBody(
    input: EditContactInput
  ): PartialDeep<EditContactRequest> {
    return {
      id: input.id,
      first_name: input.firstName,
      last_name: input.lastName,
      email: input.email,
      phone: input.phone ?? null,
      potential_hostess: input.isPotentialHost,
      potential_stylist: input.isPotentialConsultant,
      receive_marketing_emails: input.shouldReceiveMarketingEmails,
      receive_print_catalog: input.shouldReceivePrintCatalog,
      user_type: input.userType ?? null,
      birthday: input.birth?.fullDate ?? null,
      birth_day: input.birth?.day ?? null,
      birth_month: input.birth?.month ?? null,
      placed_order: input.hasPlacedOrder,
      been_hostess: input.hasBeenHostess,
      last_active_at: input.lastActiveAt?.toJSON() ?? null,
      dsr_id: input.dsrId,
      source: input.source,
      customer_id: input.customerId,
      has_store_credit: input.hasStoreCredit,
      has_wish_list: input.hasWishList,
      created_at: input.createdAt?.toJSON(),
      updated_at: input.updatedAt?.toJSON(),
      preferred_customer_expires_at:
        input.preferredCustomerExpiresAt?.toJSON() ?? null,
      deleted_at: input.deletedAt?.toJSON() ?? null,
      access_token: input.accessToken,
      subscription_access_token: input.subscriptionAccessToken,
      url_code: input.urlCode,
      personal_link: input.personalLink,
      belongs_to_different_dsr: input.belongsToDifferentDsr,
      loyalty_enabled: input.isLoyaltyEnabled,
      loyalty_savings_cents: input.loyaltySavingsCents,
      loyalty_expiration: input.loyaltyExpiration ?? null,
      last_quiz_group: input.lastQuizGroup ?? null,
      last_quiz_version: input.lastQuizVersion ?? null,
      preferred_dsr: input.isPreferredDsr,
      completed_quiz: input.hasCompletedQuiz,
      preferred_customer: input.isPreferredCustomer,
      current_address: {
        address1: input.currentAddress?.address1,
        address2: input.currentAddress?.address2,
        city: input.currentAddress?.city,
        country: input.currentAddress?.country,
        state: input.currentAddress?.state,
        zip_code: input.currentAddress?.zipCode,
      },
      address_attributes: {
        address1: input.addressAttributes?.address1,
        address2: input.addressAttributes?.address2,
        city: input.addressAttributes?.city,
        country: input.addressAttributes?.country,
        state: input.addressAttributes?.state,
        zip_code: input.addressAttributes?.zipCode,
      },
      last_placed_order_date: input.lastPlacedOrderDate?.toJSON() ?? null,
      last_hosted_show_date: input.lastHostedShowDate?.toJSON() ?? null,
    }
  }
}
