import { format as noriFormat } from '@nori/date'

export function getDate({
  date,
  format,
  options,
}: {
  date?: Date
  format: string
  options?: {
    locale?: Locale
  }
}): string {
  if (!date) {
    return '---'
  }

  return noriFormat(date, format, options)
}
