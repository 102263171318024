import { observer } from '@nori/di'
import { useTranslations } from '@nori/lang-i18n'
import { Button, Loader, Swap } from '@nori/ui-kit'
import { useContactContext } from '~/modules/contacts/interface/use-contact-context'
import { PerksBlock } from '~/modules/contacts/ui/perks-block/perks-block'
import { NoteList } from '~/modules/notes/ui'

import { SubscriptionTable } from './subscription-table/subscription-table'
import { ContactEvent } from './contact-event'
import { EarningPeriod } from './earning-period'
import { LastOrder } from './last-order'

import type { ContactListItemEntity } from '~/modules/contacts/core'

type Props = {
  contact: ContactListItemEntity
}

export const RightColumn = observer(({ contact }: Props) => {
  const t = useTranslations('contacts.list.table.extended')
  const { contactDetailsAction, contactsStore } = useContactContext()
  const perks = contactsStore.getOrCreateContactPerks(contact.customerId)

  if (!contact.isPreferredDsr) {
    return (
      <>
        <NoteList contactId={contact.id} />
        <div className="flex justify-end">
          <div className="w-full lg:w-1/3">
            <Button
              size="lg"
              widthType="full"
              data-test-id={'contact-expanded-view-create-order-button'}
              onClick={() =>
                contactDetailsAction.handleNavigateToNewOrder(contact)
              }
            >
              {t('createOrder')}
            </Button>
          </div>
        </div>
      </>
    )
  }

  return (
    <div className="lg:w-[775px]">
      <Swap is={perks?.isLoading} isSlot={<Loader />}>
        <PerksBlock
          contact={contact}
          credits={perks?.data?.creditsInfo}
          hasFreeShipping={perks?.data?.hasFreeShipping}
        />
      </Swap>

      <NoteList contactId={contact.id} />

      <hr className="my-5" />

      <EarningPeriod
        contact={contact}
        referralEarningPeriod={perks?.data?.rewardsInfo.referralEarningPeriod}
      />

      <div className="mt-5 flex flex-wrap gap-5 lg:w-full">
        {contact.events.map((event) => (
          <ContactEvent key={event.id} contact={contact} event={event} />
        ))}

        <LastOrder contact={contact} />
      </div>

      <div className="mt-11 flex">
        <div className="w-full lg:w-1/3">
          <Button
            size="lg"
            widthType="full"
            data-test-id={'contact-expanded-view-create-order-button'}
            onClick={() =>
              contactDetailsAction.handleNavigateToNewOrder(contact)
            }
          >
            {t('createOrder')}
          </Button>
        </div>
      </div>

      {!!contact.customerId && (
        <div className="mt-6 flex flex-col">
          <SubscriptionTable contact={contact} />
        </div>
      )}
    </div>
  )
})
