import { Action, Inject } from '@nori/di'
import { CartStore } from '~/modules/cart/interface/store/cart-store/cart.store'

import { ApplyHostessCreditsAction } from './apply-hostess-credits.action'
import { ApplyProductCreditsAction } from './apply-product-credits.action'

import type { ApplyCreditsActionInterface } from './apply-credits-action.interface'

@Action()
export class ApplyCreditsAction implements ApplyCreditsActionInterface {
  constructor(
    @Inject()
    private readonly applyHostessCreditsAction: ApplyHostessCreditsAction,
    @Inject()
    private readonly applyProductCreditsAction: ApplyProductCreditsAction,
    @Inject()
    private readonly cartStore: CartStore
  ) {}

  private get creditsActionInstance():
    | ApplyHostessCreditsAction
    | ApplyProductCreditsAction {
    if (this.cartStore.isPersonalCart) return this.applyProductCreditsAction

    return this.applyHostessCreditsAction
  }

  handleApplyCreditsAndGoCheckout(): void {
    this.creditsActionInstance.handleApplyCreditsAndGoCheckout()
  }

  handleApplyCredit(creditValue: number): void {
    this.creditsActionInstance.handleApplyCredit(creditValue)
  }

  handleChangeValue(creditValue: string): void {
    this.creditsActionInstance.handleChangeValue(creditValue)
  }

  handleRemoveCredits(): void {
    this.creditsActionInstance.handleRemoveCredits()
  }
}
