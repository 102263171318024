import { Divider, Loader } from '@nori/ui-kit'
import { CartBundleSubscription } from '~/modules/product-subscription/ui/cart-bundle-subscription'

import { BundleDropDown } from '../bundle-dropdown/bundle-dropdown'
import { HostessDiscountButton } from '../hostess-discount-button'
import { ProductItemControlsMobile } from '../mobile/product-item-controls-mobile'
import { ProductItemNameMobile } from '../product-item-name'
import { CartItemPriceMobile } from '../product-item-price'
import { ProductItemQuantity } from '../product-item-quantity'
import { ProductItemThumbnail } from '../product-item-thumbnail'

import type { CartBundleEntity } from '@nori/app-kit'

type Props = {
  currentQuantity: string
  currency: string
  handleCancel: () => void
  handleItemQuantityChange: (value: string) => void
  isEditMode: boolean
  isProductLoading: boolean
  onAdd: () => void
  onEditSwitch: () => void
  onRemove: () => void
  bundle: CartBundleEntity
  canBeAutoShipped?: boolean
  isEditable?: boolean
  lastItemInBundle?: number
}

export const BundleItemMobile = ({
  currentQuantity,
  isEditable,
  handleCancel,
  handleItemQuantityChange,
  isEditMode,
  isProductLoading,
  onAdd,
  onEditSwitch,
  onRemove,
  bundle,
  canBeAutoShipped,
  currency,
  lastItemInBundle,
}: Props) => {
  return (
    <>
      <div className="relative py-6">
        {isProductLoading && <Loader viewType="filled-center" />}

        <div className="flex flex-col">
          <div className="flex w-full justify-between">
            <div className="flex flex-col gap-4">
              <div className="flex">
                <ProductItemThumbnail url={bundle.thumbnailUrl} size={80} />
                <div className="ml-4" />
                <ProductItemNameMobile
                  productName={bundle.name}
                  itemQuantity={bundle.quantity}
                />
              </div>
              <div className="flex flex-col gap-2.5">
                <BundleDropDown bundle={bundle} />
                {!isEditMode && lastItemInBundle && (
                  <div className="shrink-1 grow-0">
                    <HostessDiscountButton
                      productId={lastItemInBundle}
                      isDiscountApplied={!!bundle.hostessDiscount}
                      isDiscountDisallow={bundle.disallowHostessDiscounts}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
          <CartItemPriceMobile
            retailPrice={bundle.retailPrice}
            salePrice={bundle.salePrice}
            price={bundle.price}
            totalPrice={bundle.totalPrice}
            promotionalDiscounts={bundle.totalPromotionalDiscounts}
            totalRetailPrice={bundle.retailPrice}
            totalSalePrice={bundle.salePrice}
            hostessDiscountPercentage={bundle.hostessDiscountPercentage}
            combinedCredits={bundle.totalCombinedCredits}
            dsrDiscount={bundle.totalDsrDiscount}
            hostessDiscountValue={bundle.totalHostessDiscountValue}
            productCredits={bundle.totalProductCredits}
            currency={currency}
            totalDiscounts={bundle.totalDiscount}
          />
          <div className="flex-wrapitems-start mt-7 flex w-full gap-7">
            {isEditMode && (
              <ProductItemQuantity
                maxInCart={bundle.maxInCart}
                isEditMode={isEditMode}
                currentQuantity={currentQuantity}
                productQuantity={bundle.quantity}
                onQuantityChange={handleItemQuantityChange}
              />
            )}
          </div>
          <ProductItemControlsMobile
            id={bundle.id}
            isEditMode={isEditMode}
            isEditable={isEditable}
            onCancel={handleCancel}
            onAdd={onAdd}
            onRemove={onRemove}
            onEditSwitch={onEditSwitch}
          />
        </div>
      </div>

      <Divider />
    </>
  )
}
