import { StyleDetailsStore } from '@nori/app-kit'
import { Action, Inject } from '@nori/di'
import { AddToCartService } from '~/modules/cart/interface/services/add-to-cart.service'

@Action()
export class StyleDetailsAction {
  constructor(
    @Inject()
    private readonly styleDetailsStore: StyleDetailsStore,
    @Inject() private readonly addToCartService: AddToCartService
  ) {}

  async handleAddToCart({ quantity }: { quantity: number }): Promise<void> {
    const skuId = this.styleDetailsStore.skuId
    const statusId = this.styleDetailsStore.statusId
    if (!skuId || !statusId) return

    await this.addToCartService.handleAddStyleToCart({
      id: this.styleDetailsStore.product?.id,
      quantity,
      skuId,
      statusId,
    })
  }
}
