import { useMemo } from 'react'

import { CreditCardForm, usePaymentMethodsContext } from '@nori/app-kit'
import { observer } from '@nori/di'
import { useTranslations } from '@nori/lang-i18n'
import { Checkbox, Swap, Tabs } from '@nori/ui-kit'
import { useCartContext } from '~/modules/cart'

import { useBillingFormContext } from '../../interface/use-billing-form.context'
import { SavedCreditCardsDropdown } from '../saved-credit-cards-dropdown'

import { BillingAddressSection } from './billing-address-section'
import { SplitPaymentBlock } from './split-payment-block'

import type {
  CreditCardModeValueObject,
  CreditCardPriorityValueObject,
} from '../../core'

const DEFAULT_CREDIT_CARD_PRIORITY: CreditCardPriorityValueObject = 'main'

export const CreditCardSection = observer(() => {
  const t = useTranslations('billingForm')
  const { billingFormStore, billingFormAction } = useBillingFormContext()
  const { cartStore } = useCartContext()
  const { paymentMethodStore } = usePaymentMethodsContext()

  const isSavedCardsAvailable = useMemo(
    () => !!paymentMethodStore.creditCards?.length,
    [paymentMethodStore.creditCards]
  )

  const isSaveCardDisabled =
    cartStore.isCartHaveAutoshipItem &&
    !paymentMethodStore.isPaymentMethodsExists

  if (!billingFormStore.creditCardModeMap[DEFAULT_CREDIT_CARD_PRIORITY])
    return null

  return (
    <div className="border-b border-l border-r border-solid border-primary-500 max-sm:px-4 max-sm:py-8 sm:p-8">
      <Tabs<CreditCardModeValueObject>
        active={
          billingFormStore.creditCardModeMap[DEFAULT_CREDIT_CARD_PRIORITY]
        }
        onChange={billingFormAction.handleSetMainCreditCardMode}
      >
        {isSavedCardsAvailable && (
          <div className="mb-6">
            <Tabs.Titles>
              <div className="flex gap-12">
                <Tabs.Title name="saved">
                  {t('creditCard.yourSavedCard')}
                </Tabs.Title>
                <Tabs.Title name="new">
                  {t('creditCard.enterNewCard')}
                </Tabs.Title>
              </div>
            </Tabs.Titles>
          </div>
        )}

        <div>
          <Tabs.Panel name="saved">
            <SavedCreditCardsDropdown
              error={
                billingFormStore.selectedCardMap[DEFAULT_CREDIT_CARD_PRIORITY]
                  .error?.message
              }
              isVisible={
                billingFormStore.creditCardModeMap[
                  DEFAULT_CREDIT_CARD_PRIORITY
                ] === 'saved'
              }
              selectedCard={
                billingFormStore.selectedCardMap[DEFAULT_CREDIT_CARD_PRIORITY]
                  .data
              }
              onSelectCreditCard={(value) =>
                billingFormAction.handleSelectCreditCard(
                  DEFAULT_CREDIT_CARD_PRIORITY,
                  value
                )
              }
            />
          </Tabs.Panel>

          <Tabs.Panel name="new" isStayInDOM>
            <CreditCardForm
              data-test-id="primary-credit-card"
              isVisible={
                billingFormStore.creditCardModeMap[
                  DEFAULT_CREDIT_CARD_PRIORITY
                ] === 'new'
              }
              cardId={DEFAULT_CREDIT_CARD_PRIORITY}
              isLoading={
                billingFormStore.hostedFieldsMap[DEFAULT_CREDIT_CARD_PRIORITY]
                  .isLoading
              }
              cardholderValidator={
                billingFormStore.cardholderValidatorMap[
                  DEFAULT_CREDIT_CARD_PRIORITY
                ]
              }
              fields={
                billingFormStore.hostedFieldsMap[DEFAULT_CREDIT_CARD_PRIORITY]
                  .data
              }
              fieldsValidation={
                billingFormStore.hostedFieldsValidationMap[
                  DEFAULT_CREDIT_CARD_PRIORITY
                ]
              }
            >
              {billingFormStore.canSaveToProfile && (
                <Checkbox
                  value={
                    !!billingFormStore.saveToProfileMap[
                      DEFAULT_CREDIT_CARD_PRIORITY
                    ]
                  }
                  onChange={(value) =>
                    billingFormAction.handleSetSaveToProfile(
                      DEFAULT_CREDIT_CARD_PRIORITY,
                      value
                    )
                  }
                  disabled={isSaveCardDisabled}
                >
                  {t('creditCard.saveCardToProfile')}
                </Checkbox>
              )}
            </CreditCardForm>

            <BillingAddressSection />

            {billingFormStore.view === 'enrollment' && (
              <div className="text-gray-700">
                {t('enrollment.saveCCToProfileCaption')}
              </div>
            )}
          </Tabs.Panel>
          {billingFormStore.view !== 'enrollment' && <SplitPaymentBlock />}
        </div>
      </Tabs>
    </div>
  )
})
