import { Inject, Service } from '@nori/di'
import { isErr } from '@nori/result'
import { createLogger } from '~/infra/create-logger'

import { PartiesListAdapter } from '../../infra/parties-list.adapter'
import { PartiesListStore } from '../stores/parties-list.store'

import type { PartyTypeValueObject } from '../../core/value-objects/party-type.value-object'

const logger = createLogger('parties-list-load.service')

const INITIAL_PAGE = 1

type PartiesInitialLoadParams = {
  partyType: PartyTypeValueObject
  perPage?: number
}

@Service()
export class PartiesListLoadService {
  constructor(
    @Inject() private readonly partiesListStore: PartiesListStore,
    @Inject() private readonly partiesListAdapter: PartiesListAdapter
  ) {}

  async initialLoad(params: PartiesInitialLoadParams): Promise<void> {
    this.reset(params)

    await this.loadPage(INITIAL_PAGE)
  }

  reset({ partyType, perPage }: PartiesInitialLoadParams): void {
    if (partyType) this.partiesListStore.setPartyType(partyType)

    this.partiesListStore.resetSearchFilters()
    this.partiesListStore.resetPagination()
    if (perPage) {
      this.partiesListStore.setPagination({
        ...this.partiesListStore.pagination,
        perPage,
      })
    }

    this.partiesListStore.setPartiesList([])
  }

  async loadPage(page?: number): Promise<void> {
    this.partiesListStore.setIsLoading(true)

    await this.fetchPartyList({ page, isAppendItems: false })

    this.partiesListStore.setIsLoading(false)
  }

  async fetchPartyList({
    page,
    isAppendItems = false,
  }: {
    page?: number
    isAppendItems?: boolean
  }): Promise<void> {
    const partyType = this.partiesListStore.partyType
    const [from, to] = this.partiesListStore.dateRange
    this.partiesListStore.setError(undefined)

    const result = await this.partiesListAdapter.getPartyList({
      page: page ?? this.partiesListStore.pagination.page,
      perPage: this.partiesListStore.pagination.perPage,
      partyType,
      to: to?.backendTimestamp,
      from: from?.backendTimestamp,
      searchParam: this.partiesListStore.searchParam,
      sortBy: this.partiesListStore.sortBy,
      ordering: this.partiesListStore.sortOrder,
      isWithAccurateTime: this.partiesListStore.isWithAccurateTime,
    })

    if (isErr(result)) {
      logger.error(result.error.key, result.error)
      this.partiesListStore.setError(result.error)
      return
    }

    if (isAppendItems) {
      this.partiesListStore.appendPartiesList(result.data.parties)
    } else {
      this.partiesListStore.setPartiesList(result.data.parties)
    }

    this.partiesListStore.setPagination(result.data.pagination)
  }
}
