import { getLink } from '@nori/app-kit'
import { useTranslations } from '@nori/lang-i18n'

export function AutosaveTooltip() {
  const t = useTranslations('cart.productList.autosaveTooltipContent')
  return (
    <div className="min-w-52">
      <div>{t('title')}</div>
      <ul className="list-disc py-2 pl-6">
        <li>{t('option1')}</li>
        <li>{t('option2')}</li>
        <li>{t('option3')}</li>
      </ul>
      <div>
        {t('agree1')}{' '}
        <a
          className="underline"
          href={
            getLink({ type: 'bo', personalLink: 'terms-and-conditions' }).url
          }
          rel="noreferrer"
        >
          {t('termsAndCond')}
        </a>{' '}
        {t('agree2')}
      </div>
    </div>
  )
}
