import { Action, Inject } from '@nori/di'
import { isErr } from '@nori/result'
import { createLogger } from '~/infra/create-logger'
import { CartStore } from '~/modules/cart/interface/store/cart-store/cart.store'
import { ContactAndPartyAction } from '~/modules/cart-contact-party/interface/actions/contact-and-party.action'

import { ShareAdapter } from '../../infra/share.adapter'
import { ShareStore } from '../store/share.store'

const logger = createLogger('share.actions')

@Action()
export class ShareAction {
  constructor(
    @Inject() private readonly cartStore: CartStore,
    @Inject() private readonly contactAndPartyAction: ContactAndPartyAction,
    @Inject() private readonly shareStore: ShareStore,
    @Inject() private readonly shareAdapter: ShareAdapter
  ) {}

  async handleSelectShareParty(partyId?: number): Promise<void> {
    this.shareStore.setIsCreatingShareLink(true)

    if (partyId) {
      await this.contactAndPartyAction.handleAddPartyToOrder(partyId)
    }
    await this.createShareLink(partyId)

    this.shareStore.setIsCreatingShareLink(false)
  }

  async handleCreateShareLink(partyId?: number): Promise<void> {
    this.shareStore.setIsCreatingShareLink(true)
    await this.createShareLink(partyId)

    this.shareStore.setIsCreatingShareLink(false)
  }

  private async createShareLink(partyId?: number): Promise<void> {
    const { cart } = this.cartStore

    if (!cart?.id) return

    this.shareStore.setCreateShareLinkError(undefined)
    const result = await this.shareAdapter.createShareLink({
      cartId: cart.id,
      partyId,
    })

    if (isErr(result)) {
      logger.error(result.error.key, result.error)
      this.shareStore.setCreateShareLinkError(result.error)

      return
    }

    this.shareStore.setCartShareLinkObject(result.data)
  }
}
