var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __name = (target, value) => __defProp(target, "name", { value, configurable: true });
var __publicField = (obj, key, value) => {
  __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
  return value;
};

// src/interface/difference-in-calendar-days.ts
import { differenceInCalendarDays as fnsDifferenceInCalendarDays } from "date-fns";
function differenceInCalendarDays(date1, date2 = /* @__PURE__ */ new Date()) {
  const [earlierDate, laterDate] = date1 < date2 ? [
    date1,
    date2
  ] : [
    date2,
    date1
  ];
  return fnsDifferenceInCalendarDays(laterDate, earlierDate);
}
__name(differenceInCalendarDays, "differenceInCalendarDays");

// src/interface/difference-in-days.ts
import { differenceInCalendarDays as fnsDifferenceInCalendarDays2 } from "date-fns";
function differenceInCalendarDaysWithNegative({ firstDate, secondDate }) {
  return fnsDifferenceInCalendarDays2(firstDate, secondDate);
}
__name(differenceInCalendarDaysWithNegative, "differenceInCalendarDaysWithNegative");

// src/interface/nori-date/nori-date.ts
import { addDays, addMonths, addYears, endOfMonth, format, startOfDecade, startOfWeek } from "date-fns";
import { toDate } from "date-fns-tz";

// src/interface/nori-date/nori-date-base.ts
import { formatInTimeZone } from "date-fns-tz";
var _NoriDateBase = class extends Date {
  static get localTz() {
    return Intl.DateTimeFormat().resolvedOptions().timeZone;
  }
  get backendTimestamp() {
    return this.formatInTZ("yyyy-MM-dd'T'HH:mm:ss.SSSxxx");
  }
  static setTimezone(timezone) {
    this.businessTz = timezone;
  }
  static getTimezone() {
    return this.businessTz;
  }
  formatInTZ(formatPattern, locale) {
    return _NoriDateBase.formatInTZ(this, formatPattern, locale);
  }
  static formatInTZ(_date, formatPattern, locale) {
    const date = new Date(_date.getTime());
    const timezone = _NoriDateBase.getTimezone();
    return formatInTimeZone(date, timezone, formatPattern, {
      locale
    });
  }
};
var NoriDateBase = _NoriDateBase;
__name(NoriDateBase, "NoriDateBase");
__publicField(NoriDateBase, "businessTz", "America/Chicago");

// src/interface/nori-date/nori-date.ts
var yearMonthDayRegex = /^\d{4}-\d{2}-\d{2}$/;
var monthDayYearRegex = /^\d{2}\/\d{2}\/\d{4}$/;
var dateTimeRegex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}$/;
var stringRegexps = [
  yearMonthDayRegex,
  monthDayYearRegex,
  dateTimeRegex
];
var NoriDate = class extends NoriDateBase {
  /**
  * Creates a new date instance with provided value
  * If no date value is provided, creates a new date instance with current date in business timezone
  */
  constructor(input = /* @__PURE__ */ new Date()) {
    if (NoriDate.isRegexInput(input)) {
      const date = NoriDate.fromString(input);
      super(date);
      return;
    }
    super(input);
  }
  // The methods below are required since date-fns only operates in local timezone for most functions
  isSameDay(val) {
    return this.formatInTZ("yyyy-MM-dd") === val.formatInTZ("yyyy-MM-dd");
  }
  addDays(days) {
    const startDate = new Date(this.formatInTZ("yyyy-MM-dd'T'HH:mm:ss.SSS"));
    const endDate = addDays(startDate, days);
    const dateString = format(endDate, "yyyy-MM-dd'T'HH:mm:ss.SSS");
    const result = toDate(dateString, {
      timeZone: NoriDate.getTimezone()
    });
    return new NoriDate(result);
  }
  addMonths(months) {
    const startDate = new Date(this.formatInTZ("yyyy-MM-dd'T'HH:mm:ss.SSS"));
    const endDate = addMonths(startDate, months);
    const dateString = format(endDate, "yyyy-MM-dd'T'HH:mm:ss.SSS");
    const result = toDate(dateString, {
      timeZone: NoriDate.getTimezone()
    });
    return new NoriDate(result);
  }
  addYears(years) {
    const startDate = new Date(this.formatInTZ("yyyy-MM-dd'T'HH:mm:ss.SSS"));
    const endDate = addYears(startDate, years);
    const dateString = format(endDate, "yyyy-MM-dd'T'HH:mm:ss.SSS");
    const result = toDate(dateString, {
      timeZone: NoriDate.getTimezone()
    });
    return new NoriDate(result);
  }
  startOfDay() {
    const dateString = this.formatInTZ("yyyy-MM-dd'T'00:00:00.000");
    const date = toDate(dateString, {
      timeZone: NoriDate.getTimezone()
    });
    return new NoriDate(date);
  }
  endOfDay() {
    const dateString = this.formatInTZ("yyyy-MM-dd'T'23:59:59.999");
    const date = toDate(dateString, {
      timeZone: NoriDate.getTimezone()
    });
    return new NoriDate(date);
  }
  startOfWeek() {
    const date = new Date(this.formatInTZ("yyyy-MM-dd'T'00:00:00.000"));
    const startOfWeekResult = startOfWeek(date, {
      weekStartsOn: 0
    });
    const dateString = format(startOfWeekResult, "yyyy-MM-dd'T'00:00:00.000");
    const result = toDate(dateString, {
      timeZone: NoriDate.getTimezone()
    });
    return new NoriDate(result);
  }
  startOfMonth() {
    const dateString = this.formatInTZ("yyyy-MM-01'T'00:00:00.000");
    const date = toDate(dateString, {
      timeZone: NoriDate.getTimezone()
    });
    return new NoriDate(date);
  }
  endOfMonth() {
    const date = new Date(this.formatInTZ("yyyy-MM-dd'T'00:00:00.000"));
    const endOfMonthResult = endOfMonth(date);
    const dateString = format(endOfMonthResult, "yyyy-MM-dd'T'23:59:59.999");
    const result = toDate(dateString, {
      timeZone: NoriDate.getTimezone()
    });
    return new NoriDate(result);
  }
  startOfYear() {
    const dateString = this.formatInTZ("yyyy-01-01'T'00:00:00.000");
    const result = toDate(dateString, {
      timeZone: NoriDate.getTimezone()
    });
    return new NoriDate(result);
  }
  endOfYear() {
    const dateString = this.formatInTZ("yyyy-12-31'T'23:59:59.999");
    const result = toDate(dateString, {
      timeZone: NoriDate.getTimezone()
    });
    return new NoriDate(result);
  }
  startOfDecade() {
    const date = new Date(this.formatInTZ("yyyy-MM-dd'T'00:00:00.000"));
    const startOfDecadeResult = startOfDecade(date);
    const dateString = format(startOfDecadeResult, "yyyy-01-01'T'00:00:00.000");
    const result = toDate(dateString, {
      timeZone: NoriDate.getTimezone()
    });
    return new NoriDate(result);
  }
  static isRegexInput(input) {
    return typeof input === "string" && stringRegexps.some((regex) => regex.test(input));
  }
  static fromString(rawInput) {
    let input = rawInput;
    if (yearMonthDayRegex.test(input)) {
      input = `${input}T00:00:00.000`;
    }
    if (monthDayYearRegex.test(input)) {
      const [month, day, year] = input.split("/");
      input = `${year}-${month}-${day}T00:00:00.000`;
    }
    return toDate(input, {
      timeZone: NoriDate.getTimezone()
    });
  }
};
__name(NoriDate, "NoriDate");

// src/interface/format.ts
function noriFormat(date, formatPattern, options) {
  return new NoriDate(date).formatInTZ(formatPattern, options == null ? void 0 : options.locale);
}
__name(noriFormat, "noriFormat");

// src/locale.ts
import { es, fr } from "date-fns/locale";
var localeMap = {
  es,
  fr
};
function getLocale(locale) {
  return localeMap[locale];
}
__name(getLocale, "getLocale");

// src/index.ts
import { default as default2 } from "date-fns/add";
import { default as default3 } from "date-fns/addDays";
import { default as default4 } from "date-fns/addHours";
import { default as default5 } from "date-fns/addMinutes";
import { default as default6 } from "date-fns/addMonths";
import { default as default7 } from "date-fns/addYears";
import { default as default8 } from "date-fns/differenceInDays";
import { default as default9 } from "date-fns/differenceInYears";
import { default as default10 } from "date-fns/endOfDay";
import { default as default11 } from "date-fns/endOfMonth";
import { default as default12 } from "date-fns/endOfWeek";
import { default as default13 } from "date-fns/endOfYear";
import { default as default14 } from "date-fns/formatISO";
import { default as default15 } from "date-fns/getMonth";
import { default as default16 } from "date-fns/getYear";
import { default as default17 } from "date-fns/isDate";
import { default as default18 } from "date-fns/isSameDay";
import { default as default19 } from "date-fns/isSameMonth";
import { default as default20 } from "date-fns/isSameSecond";
import { default as default21 } from "date-fns/isSameYear";
import { default as default22 } from "date-fns/isWithinInterval";
import { default as default23 } from "date-fns/lastDayOfMonth";
import { default as default24 } from "date-fns/parse";
import { default as default25 } from "date-fns/parseISO";
import { default as default26 } from "date-fns/setYear";
import { default as default27 } from "date-fns/startOfDay";
import { default as default28 } from "date-fns/startOfDecade";
import { default as default29 } from "date-fns/startOfMonth";
import { default as default30 } from "date-fns/startOfWeek";
import { default as default31 } from "date-fns/startOfYear";
import { default as default32 } from "date-fns/subDays";
export {
  NoriDate,
  default2 as add,
  default3 as addDays,
  default4 as addHours,
  default5 as addMinutes,
  default6 as addMonths,
  default7 as addYears,
  differenceInCalendarDays,
  differenceInCalendarDaysWithNegative,
  default8 as differenceInDays,
  default9 as differenceInYears,
  default10 as endOfDay,
  default11 as endOfMonth,
  default12 as endOfWeek,
  default13 as endOfYear,
  noriFormat as format,
  default14 as formatISO,
  getLocale,
  default15 as getMonth,
  default16 as getYear,
  default17 as isDate,
  default18 as isSameDay,
  default19 as isSameMonth,
  default20 as isSameSecond,
  default21 as isSameYear,
  default22 as isWithinInterval,
  default23 as lastDayOfMonth,
  default24 as parse,
  default25 as parseISO,
  default26 as setYear,
  default27 as startOfDay,
  default28 as startOfDecade,
  default29 as startOfMonth,
  default30 as startOfWeek,
  default31 as startOfYear,
  default32 as subDays
};
