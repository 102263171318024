import { Store, Toggle } from '@nori/di'

import { contactFiltersOptions } from './contact-filters-options'

import type { TabOptions } from '../../core/filters/contact-filter-tab-option'
import type {
  ContactFilterOption,
  ContactFiltersKeys,
  FilterValue,
} from '../../core/filters/filter-options'

const customerFilters = [
  'social_this_month',
  'birthday_this_month',
  'birthday_next_month',
  'potential_hostess',
  'potential_designer',
  'customer_type_list[]',
  'corp_referral',
  'is_preferred_dsr',
]

const productFilters = ['easy_replenish_customer', 'has_wish_list']

const rewardFilters = [
  'expiring_rewards_this_month',
  'has_hostess_rewards',
  'has_store_credit',
  'has_available_coupons',
  'has_referral_earning_period',
]

const catalogFilters = ['catalog_mailing_type_list[]']

@Store()
export class ContactFiltersStore {
  filtersDropdownToggle = new Toggle()

  activeTab: TabOptions = 'customer'

  filtersOptions = [...contactFiltersOptions]

  appliedFilters: ContactFilterOption[] = []

  get isAnyFilterApplied(): boolean {
    return !!this.appliedFilters.length
  }

  get customerFilters(): ContactFilterOption[] {
    return this.filtersOptions.filter(
      (filterOption) =>
        customerFilters.includes(filterOption.filterKey) && filterOption.value
    )
  }

  get productFilters(): ContactFilterOption[] {
    return this.filtersOptions.filter(
      (filterOption) =>
        productFilters.includes(filterOption.filterKey) && filterOption.value
    )
  }

  get rewardFilters(): ContactFilterOption[] {
    return this.filtersOptions.filter(
      (filterOption) =>
        rewardFilters.includes(filterOption.filterKey) && filterOption.value
    )
  }

  get catalogFilters(): ContactFilterOption[] {
    return this.filtersOptions.filter(
      (filterOption) =>
        catalogFilters.includes(filterOption.filterKey) && filterOption.value
    )
  }

  setActiveTab(tab: TabOptions): void {
    this.activeTab = tab
  }

  setFilterByKey<T extends ContactFiltersKeys>({
    key,
    filterValue,
    isChecked,
  }: {
    key: T
    filterValue: FilterValue<T>
    isChecked: boolean
  }): void {
    const filterIndex = this.filtersOptions.findIndex(
      (filterOption) =>
        filterOption.filterKey === key && filterOption.value === filterValue
    )

    if (filterIndex === -1) {
      return
    }

    // @TODO: wrap inside if
    this.filtersOptions[filterIndex]!.isChecked = isChecked
  }

  clearAllFiltersOptions(): void {
    this.filtersOptions = [...contactFiltersOptions]
  }

  setAppliedFilters(filters: ContactFilterOption[]): void {
    this.appliedFilters = filters
  }

  fillFiltersOptionsByAppliedFilters(): void {
    this.appliedFilters.forEach((appliedFilter) => {
      this.setFilterByKey({
        key: appliedFilter.filterKey,
        filterValue: appliedFilter.value,
        isChecked: true,
      })
    })
  }

  removeAppliedFilter(filter: ContactFilterOption): void {
    const updatedApplied = this.appliedFilters.filter((appliedFilter) => {
      const isNotSameFilter = appliedFilter.filterKey !== filter.filterKey
      const isSameFilterButDifferentValue =
        appliedFilter.filterKey === filter.filterKey &&
        appliedFilter.value !== filter.value
      return isNotSameFilter || isSameFilterButDifferentValue
    })

    this.setAppliedFilters(updatedApplied)
  }
}
