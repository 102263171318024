import type { ContactDetailsEntity } from '../../core'
import type { EnsureContactEntity } from '../../core/ensure-contact.entity'
import type { EnsureContactRequrest } from '../types/ensure/ensure-contact.request'
import type { EnsureContactResponse } from '../types/ensure/ensure-contact.response'

export class EnsureContactMapper {
  static toEntity(response: EnsureContactResponse): EnsureContactEntity {
    return {
      user: {
        dsrLogin: response.user.dsr_login,
        email: response.user.email,
        firstName: response.user.first_name,
        id: response.user.id,
        lastName: response.user.last_name,
        isEmailProvided: response.user.email_provided,
        roles: response.user.roles.map((role) => ({
          role: role.role,
          roleName: role.role_name,
        })),
      },
    }
  }
}
