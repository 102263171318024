import { useMemo } from 'react'
import { useRouter } from 'next/router'

import { AddressDetails } from '@nori/app-kit'
import { observer } from '@nori/di'
import { useTranslations } from '@nori/lang-i18n'
import { Link } from '@nori/ui-kit'
import { useAutosaveContext } from '~/modules/autosave/interface/use-autosave-context'
import { useContactContext } from '~/modules/contacts/interface/use-contact-context'
import { Routes } from '~/modules/routes'

import { ShippingAddressModal } from '../../shipping-address-modal'
import { useFormatAddressToString } from '../../use-format-address-to-string'

type Props = {
  isContactDetailsPage?: boolean
}

export const NextOrderSettings = observer(({ isContactDetailsPage }: Props) => {
  const t = useTranslations()
  const router = useRouter()
  const { nextOrderStore, nextOrderAction } = useAutosaveContext()
  const { contactDetailsStore } = useContactContext()
  const { isShowCardPayment, paymentCardType, paymentLastDigits } =
    nextOrderStore

  const defaultShippingAddressTitle = useFormatAddressToString(
    isContactDetailsPage
      ? contactDetailsStore.contactAddress
      : nextOrderStore.defaultShippingAddress
  )

  const addressDetails = useMemo(
    () =>
      isContactDetailsPage
        ? nextOrderStore.contactSubscriptionShippingAddress
        : nextOrderStore.subscriptionShippingAddress,
    [
      isContactDetailsPage,
      nextOrderStore.contactSubscriptionShippingAddress,
      nextOrderStore.subscriptionShippingAddress,
    ]
  )

  if (!nextOrderStore.subscription) return null

  return (
    <div className="flex flex-col gap-5 text-sm text-primary-900 lg:w-2/3 lg:flex-row lg:justify-between lg:gap-24">
      <div>
        <div className="mb-2 flex gap-3 max-lg:justify-between">
          <div className="text-sm font-normal lg:text-base">
            {t('autosaveSettings.shipping')}
          </div>
          <Link
            size="small"
            weight="regular"
            iconLeft="PencilAlt"
            data-test-id="edit-shipping-address"
            onClick={nextOrderStore.shippingAddressModalToggle.handleOpen}
          >
            {t('basic.edit')}
          </Link>
        </div>

        <div className="font-normal">
          <AddressDetails address={addressDetails} />
        </div>

        <ShippingAddressModal
          isOpen={nextOrderStore.shippingAddressModalToggle.isOpen}
          onClose={nextOrderAction.handleShippingModalClose}
          onSubmit={nextOrderAction.handleSaveShipping}
          defaultShippingAddress={defaultShippingAddressTitle}
          config={nextOrderStore.shippingAddressConfig}
          isContactDetailsPage={isContactDetailsPage}
        />
      </div>

      {!isContactDetailsPage && (
        <div>
          <div className="mb-2 flex gap-3 max-lg:justify-between">
            <div className="text-sm font-normal lg:text-base">
              {t('autosaveSettings.billing')}
            </div>
            <Link
              size="small"
              weight="regular"
              iconLeft="PencilAlt"
              data-test-id="edit-payment-and-billing"
              onClick={() => router.push(Routes.settings.paymentMethods.path())}
            >
              {t('basic.edit')}
            </Link>
          </div>

          {nextOrderStore.billingAddress && (
            <div className="font-normal">
              <AddressDetails address={nextOrderStore.billingAddress} />
            </div>
          )}

          {isShowCardPayment && (
            <>
              <span className="capitalize">{paymentCardType}</span>{' '}
              <span>
                {t('autosaveSettings.cardNumber', {
                  digits: paymentLastDigits,
                })}
              </span>
            </>
          )}

          {nextOrderStore.paypalEmail && (
            <div>
              <div className="mb-1 font-medium">
                {t('autosaveSettings.paypal')}
              </div>
              <div className="text-sm text-gray-700">
                {nextOrderStore.paypalEmail}
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  )
})
