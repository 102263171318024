import { getMoneyWithIsoCurrency } from '@nori/app-kit'
import { observer } from '@nori/di'
import { formatPhone } from '@nori/phone'
import {
  Checkbox,
  Icon,
  Swap,
  TableCell,
  TableRow,
  useToggle,
} from '@nori/ui-kit'
import { useContactContext } from '~/modules/contacts/interface/use-contact-context'
import { useFeatureFlagsContext } from '~/modules/feature-flags/use-feature-flags'

import { ContactListRowExtended } from './contact-list-row-extended/contact-list-row-extended'
import { getDate } from './get-date'
import { useContactRewards } from './use-contact-rewards'

import type { ContactListItemEntity } from '~/modules/contacts/core'

type Props = {
  contact: ContactListItemEntity
}

export const ContactListRow = observer(({ contact }: Props) => {
  const { contactsAction, contactsStore } = useContactContext()
  const { handleToggle, isOpen } = useToggle()

  const { formattedPhoneNumber } = formatPhone(contact.phone)
  const { featureFlagsStore } = useFeatureFlagsContext()
  const multipleLanguageSupportEnabled = featureFlagsStore.getFlag(
    'bo-multiple-language-support-enabled'
  )

  const rewards = useContactRewards(contact)

  return (
    <>
      <TableRow data-test-id="contact-item">
        {contactsStore.getColVisibility('name') && (
          <TableCell>
            <div>
              <div className="flex items-start">
                <Checkbox
                  onChange={() =>
                    contactsAction.handleToggleSelectContact(contact.id)
                  }
                  value={contactsStore.selectedContactIds.has(contact.id)}
                />
                <button className="flex items-start" onClick={handleToggle}>
                  <div className="mr-0.5">
                    <Swap
                      is={isOpen}
                      isSlot={<Icon size="s" name="ChevronDown" />}
                    >
                      <Icon size="s" name="ChevronRight" />
                    </Swap>
                  </div>
                  <span
                    className="text-left"
                    data-test-id={`contact-option_${contact.id}`}
                  >
                    {contact.firstName} {contact.lastName}
                  </span>
                </button>
              </div>
              <div className="mt-1 flex items-center gap-1">
                {contact.isPreferredDsr && (
                  <Icon name="StarOutline" size="xs" />
                )}
                {contact.hasWishList && <Icon name="HeartOutline" size="xs" />}
                {contact.hasAutoship && <Icon name="Refresh" size="xs" />}
              </div>
            </div>
          </TableCell>
        )}
        {contactsStore.getColVisibility('contact_info') && (
          <TableCell data-test-id="contact-email-td">
            {contact.isEmailProvided !== false && (
              <div className="font-bold text-primary-900">{contact.email}</div>
            )}
            {contact.phone && (
              <div className="mt-2">{formattedPhoneNumber}</div>
            )}
          </TableCell>
        )}
        {contactsStore.getColVisibility('customer_type') && (
          <TableCell>{contact.customerTypeName}</TableCell>
        )}
        {contactsStore.getColVisibility('cityState') && (
          <TableCell>
            {[contact.currentAddress.city, contact.currentAddress.state]
              .filter(Boolean)
              .join(', ')}
          </TableCell>
        )}
        {contactsStore.getColVisibility('last_order_date') && (
          <TableCell>
            {getDate({ date: contact.lastOrderDate, format: 'MMM dd, yyyy' })}
          </TableCell>
        )}

        {contactsStore.getColVisibility('last_hosted_social_date') && (
          <TableCell>
            {getDate({
              date: contact.lastHostedSocialDate,
              format: 'MMM dd, yyyy',
            })}
          </TableCell>
        )}

        {contactsStore.getColVisibility('expiring_rewards') && (
          <TableCell>
            {getDate({ date: contact.expiringRewards, format: 'MMM dd, yyyy' })}
          </TableCell>
        )}
        {contactsStore.getColVisibility('norwex_credits') && (
          <TableCell>{rewards.norwexDiscounts}</TableCell>
        )}
        {contactsStore.getColVisibility('item_discount') && (
          <TableCell>{rewards.itemsOff}</TableCell>
        )}
        {contactsStore.getColVisibility('has_free_shipping') && (
          <TableCell>{rewards.freeShipping}</TableCell>
        )}
        {contactsStore.getColVisibility('lifetime_spend') && (
          <TableCell>
            {getMoneyWithIsoCurrency({
              cents: contact.lifetimeSpend.cents,
              currency: contact.lifetimeSpend.currency,
            })}
          </TableCell>
        )}
        {contactsStore.getColVisibility('created_at') && (
          <TableCell>
            {getDate({ date: contact.createdAt, format: 'MMM dd, yyyy' })}
          </TableCell>
        )}
        {multipleLanguageSupportEnabled &&
          contactsStore.getColVisibility('preferred_language') && (
            <TableCell>{contact.preferredLanguage}</TableCell>
          )}
      </TableRow>
      <ContactListRowExtended isOpen={isOpen} contact={contact} />
    </>
  )
})
