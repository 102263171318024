import { AddressBlock } from '@nori/app-kit'
import { observer } from '@nori/di'
import { useTranslations } from '@nori/lang-i18n'
import { Button, Form, Link, Modal, Radio, Swap } from '@nori/ui-kit'
import { useAutosaveContext } from '~/modules/autosave/interface/use-autosave-context'
import { Routes } from '~/modules/routes'

import type { ShippingAddressModalConfig } from '../interface/shipping-address-modal-config'

type Props = {
  onSubmit: () => void
  onClose: () => void
  defaultShippingAddress?: string
  isOpen: boolean
  isContactDetailsPage?: boolean
  config: ShippingAddressModalConfig
}

export const ShippingAddressModal = observer(
  ({
    onSubmit,
    onClose,
    defaultShippingAddress,
    isOpen,
    config,
    isContactDetailsPage,
  }: Props) => {
    const t = useTranslations()
    const { nextOrderStore } = useAutosaveContext()
    if (isContactDetailsPage) {
      nextOrderStore.useUserIdForShippingAddress()
    }

    return (
      <Modal isOpen={isOpen} onClose={onClose} size="3xl">
        <Modal.Header onClose={onClose} headerType="only-close-button" />
        <Modal.Body>
          <h1 className="mb-10 text-center text-2xl text-primary-900">
            {t('autosaveSettings.editShipping')}
          </h1>
          <Radio
            value="all"
            checked={config.addressType === 'current-user'}
            onChange={() => config.changeAddressType('current-user')}
          >
            <span className="mb-3 text-sm font-medium leading-none text-primary-900">
              {t('autosaveSettings.useDefaultShipping')}
            </span>
            <div className="mb-10 mt-2 flex items-baseline">
              <span className="text-grey-700 max-w-[220px] text-sm font-normal">
                {defaultShippingAddress}
              </span>
              {!isContactDetailsPage && (
                <div className="flex items-center self-start">
                  <Link
                    size="small"
                    weight="bold"
                    isUnderline={false}
                    iconLeft="PencilAlt"
                    href={Routes.settings.account.path()}
                    onClick={onClose}
                  >
                    {t('basic.edit')}
                  </Link>
                </div>
              )}
            </div>
          </Radio>

          <Radio
            value="all"
            checked={config.addressType === 'one-time'}
            onChange={() => config.changeAddressType('one-time')}
          >
            <span className="text-sm font-medium leading-none text-primary-900">
              {t('autosaveSettings.useDifferentShipping')}
            </span>
          </Radio>
          <Form onSubmit={onSubmit}>
            <div className="mt-7" />
            <Swap
              is={config.addressType === 'one-time'}
              isSlot={
                <AddressBlock
                  validator={config.shippingAddressValidator}
                  isPhonePartOfAddress
                  isWithHelperText
                />
              }
            ></Swap>
            <div className="flex flex-col">
              <Button size="xl" type="submit" isLoading={config.isLoading}>
                {t('basic.save')}
              </Button>
              <div className="mt-5 flex justify-center">
                <Link size="medium" weight="bold" isUnderline onClick={onClose}>
                  {t('basic.cancel')}
                </Link>
              </div>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    )
  }
)
