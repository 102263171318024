import { UserStore } from '@nori/app-kit'
import { Inject, Service } from '@nori/di'
import { isErr } from '@nori/result'
import { createLogger } from '~/infra/create-logger'
import { LoadContactsService } from '~/modules/contacts/interface/services/contact.service'

import { OrderAdapter } from '../../infra/order.adapter'
import { ArchivedOrderStore } from '../store/archived-order.store'
import { OrderStore } from '../store/order.store'

import { LoadOrderService } from './load-order.service'

import type { DomainError } from '@nori/errors'
import type { Result } from '@nori/result'
import type { ContactDetailsEntity } from '~/modules/contacts'

const logger = createLogger('order-init-load.service')

@Service()
export class OrderInitLoadService {
  constructor(
    @Inject() private readonly orderStore: OrderStore,
    @Inject() private readonly archivedOrderStore: ArchivedOrderStore,
    @Inject() private readonly orderAdapter: OrderAdapter,
    @Inject() private readonly loadOrderService: LoadOrderService,
    @Inject() private readonly userStore: UserStore,
    @Inject() private readonly loadContactService: LoadContactsService
  ) {}

  async handleOrderInitLoad(
    orderId: number,
    isArchived?: boolean
  ): Promise<void> {
    this.orderStore.setError(undefined)
    this.orderStore.setReferrerContact(undefined)
    this.orderStore.setIsLoading(true)

    isArchived
      ? await this.initArchivedOrderLoad(orderId)
      : await this.initOrderLoad(orderId)

    this.orderStore.setIsLoading(false)
  }

  private async initOrderLoad(orderId: number): Promise<void> {
    const result = await this.loadOrderService.loadOrder(orderId)

    if (isErr(result)) {
      logger.error(result.error.key, result.error)
      this.orderStore.setError(result.error)

      return
    }

    if (!result.data.partyId && result.data.referrerId) {
      await this.loadReferrerContact(result.data.referrerId)
    }

    this.orderStore.setOrder(result.data)
  }

  private async loadReferrerContact(customerId: number): Promise<void> {
    if (!this.userStore.id) return
    const referrerResult =
      await this.loadContactService.findContactByDsrAndCustomerIds({
        dsrId: this.userStore.id,
        customerId,
      })
    if (isErr(referrerResult)) {
      return
    }
    this.orderStore.setReferrerContact(referrerResult?.data)
  }

  private async initArchivedOrderLoad(orderId: number): Promise<void> {
    const result = await this.orderAdapter.getArchivedOrder(orderId)

    if (isErr(result)) {
      logger.error(result.error.key, result.error)
      this.orderStore.setError(result.error)

      return
    }

    this.archivedOrderStore.order.setData(result.data)
  }
}
