/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable max-lines */
import { getBaseUrl, RequestService, SERVICE_NAMES } from '@nori/app-kit'
import { Adapter, Inject } from '@nori/di'
import { resultErr, resultOk } from '@nori/result'

import { PendingOrdersErrors, SubscriptionErrors } from '../core'

import { ValidationMapper } from './mappers/failed-validation.mapper'
import { PendingOrderMapper } from './mappers/pending-order.mapper'
import { SubscriptionMapper } from './mappers/subscription.mapper'

import type { PromiseResult } from '@nori/result'
import type {
  AddShippingAddressInputValueObject,
  NextOrderValueObject,
  PendingOrderEntity,
  PendingOrdersErrorInstance,
  SubscriptionEntity,
  SubscriptionErrorInstance,
} from '../core'
import type { AddSubscriptionInput } from '../core/add-subscription.input'
import type { FailedValidationValueObject } from '../core/failed-validation.value-object'
import type { UpdateShippingAddressInputEntity } from '../core/update-shipping-address-input.entity'
import type { SetShouldApplyCreditsInput } from './types/set-should-apply-credits/set-should-apply-credits.input'
import type { UpdateSubscriptionsInput } from './types/update-subscriptions/update-subscriptions.input'

@Adapter()
export class SubscriptionAdapter {
  private readonly baseUrl = getBaseUrl(SERVICE_NAMES.SUBSCRIPTION_SERVICE)
  constructor(
    @Inject() private readonly requestService: RequestService,
    @Inject() private readonly subscriptionMapper: SubscriptionMapper,
    @Inject() private readonly pendingOrderMapper: PendingOrderMapper
  ) {}

  async addSubscription(
    input: AddSubscriptionInput
  ): PromiseResult<SubscriptionEntity[], SubscriptionErrorInstance> {
    try {
      const url = `${this.baseUrl}/subscriptions`

      const response = await this.requestService.secured.post<any>(url, {
        body: this.subscriptionMapper.toAddSubscriptionRequest(input),
      })

      if (response.ok)
        return resultOk(this.subscriptionMapper.toSubscriptions(response.json))

      return resultErr(
        new SubscriptionErrors.AddSubscriptionUnknownError(response.json)
      )
    } catch (err: unknown) {
      return resultErr(
        new SubscriptionErrors.UnexpectedAddSubscriptionError(err)
      )
    }
  }

  async removeSubscription(
    id: number
  ): PromiseResult<void, SubscriptionErrorInstance> {
    try {
      const url = `${this.baseUrl}/subscriptions/${id}`

      const response = await this.requestService.secured.delete<any>(url)

      if (response.ok) return resultOk(undefined)

      return resultErr(
        new SubscriptionErrors.UpdateSubscriptionsUnknownError(response.json)
      )
    } catch (err: unknown) {
      return resultErr(
        new SubscriptionErrors.UnexpectedRemoveSubscriptionError(err)
      )
    }
  }

  async updateSubscriptions(input: UpdateSubscriptionsInput): PromiseResult<
    | { data: SubscriptionEntity[]; type: 'response' }
    | {
        data: FailedValidationValueObject
        type: 'validationFailed'
      },
    SubscriptionErrorInstance
  > {
    try {
      const url = `${this.baseUrl}/subscriptions/update_all`

      const response = await this.requestService.secured.patch<any>(url, {
        body: this.subscriptionMapper.toUpdateSubscriptionsRequest(input),
      })

      if (response.ok)
        return resultOk({
          type: 'response',
          data: this.subscriptionMapper.toSubscriptions(response.json),
        })

      if (response.status === 422) {
        return resultOk({
          data: ValidationMapper.toValidationErrors(response.json),
          type: 'validationFailed',
        })
      }

      return resultErr(
        new SubscriptionErrors.UpdateSubscriptionsUnknownError(response.json)
      )
    } catch (err: unknown) {
      return resultErr(
        new SubscriptionErrors.UnexpectedUpdateSubscriptionsError(err)
      )
    }
  }

  async setShouldApplyCredits(
    input: SetShouldApplyCreditsInput
  ): PromiseResult<boolean, SubscriptionErrorInstance> {
    try {
      const url = `${this.baseUrl}/next_order/set_apply_credits`

      const response = await this.requestService.secured.patch<any>(url, {
        body: this.subscriptionMapper.toSetShouldApplyCreditsRequest(input),
      })

      if (response.ok) return resultOk(response.json.apply_credits)

      return resultErr(
        new SubscriptionErrors.SetShouldApplyCreditsUnknownError(response.json)
      )
    } catch (err: unknown) {
      return resultErr(
        new SubscriptionErrors.UnexpectedSetShouldApplyCreditsError(err)
      )
    }
  }

  async getSubscriptions(
    id?: number
  ): PromiseResult<SubscriptionEntity[], SubscriptionErrorInstance> {
    try {
      const query = new URLSearchParams()
      if (id) query.append('user_id', `${id}`)
      const url = `${this.baseUrl}/subscriptions?${query.toString()}`

      const response = await this.requestService.secured.get<any>(url)

      if (response.ok) {
        return resultOk(this.subscriptionMapper.toSubscriptions(response.json))
      }

      return resultErr(
        new SubscriptionErrors.SubscriptionsUnknownError(response.json)
      )
    } catch (err: unknown) {
      return resultErr(new SubscriptionErrors.UnexpectedSubscriptionsError(err))
    }
  }

  async getPendingOrders(
    id?: number
  ): PromiseResult<PendingOrderEntity[], PendingOrdersErrorInstance> {
    try {
      const query = new URLSearchParams()
      if (id) query.append('user_id', `${id}`)
      const url = `${
        this.baseUrl
      }/subscriptions/pending_orders?${query.toString()}`

      const response = await this.requestService.secured.get<any>(url)

      if (response.ok) {
        return resultOk(this.pendingOrderMapper.toEntityArray(response.json))
      }

      return resultErr(
        new PendingOrdersErrors.PendingOrdersUnknownError(response.json)
      )
    } catch (err: unknown) {
      return resultErr(
        new PendingOrdersErrors.UnexpectedPendingOrdersError(err)
      )
    }
  }

  async getNextOrder(
    id?: number
  ): PromiseResult<NextOrderValueObject, SubscriptionErrorInstance> {
    try {
      const query = new URLSearchParams()
      if (id) query.append('user_id', `${id}`)
      const url = `${this.baseUrl}/next_order?${query.toString()}`

      const response = await this.requestService.secured.get<any>(url)

      if (response.ok) {
        return resultOk(
          this.subscriptionMapper.toNextOrderValueObject(response.json)
        )
      }

      return resultErr(
        new SubscriptionErrors.NextOrderUnknownError(response.json)
      )
    } catch (err: unknown) {
      return resultErr(new SubscriptionErrors.UnexpectedNextOrderError(err))
    }
  }

  async updateSubscriptionStatus(
    id: number,
    status: boolean
  ): PromiseResult<void, SubscriptionErrorInstance> {
    try {
      const url = `${this.baseUrl}/subscriptions/${id}`

      const response = await this.requestService.secured.patch<any>(url, {
        body: {
          active: status,
        },
      })

      if (response.ok) {
        return resultOk(undefined)
      }

      return resultErr(
        new SubscriptionErrors.UpdateSubscriptionStatusUnknownError(
          response.json
        )
      )
    } catch (err: unknown) {
      return resultErr(
        new SubscriptionErrors.UnexpectedUpdateSubscriptionStatusError(err)
      )
    }
  }

  async addSubscriptionOneTimeShippingAddress(
    input: AddShippingAddressInputValueObject
  ): PromiseResult<void, SubscriptionErrorInstance> {
    try {
      const url = `${this.baseUrl}/shipping_addresses`
      const body = this.subscriptionMapper.toUpdateShippingAddressRequest(input)

      const response = await this.requestService.secured.post<any>(url, {
        body,
      })

      if (response.ok) {
        return resultOk(undefined)
      }

      return resultErr(
        new SubscriptionErrors.AddSubscriptionOneTimeShippingAddressUnknownError(
          response.json
        )
      )
    } catch (err: unknown) {
      return resultErr(
        new SubscriptionErrors.UnexpectedAddSubscriptionOneTimeShippingAddressError(
          err
        )
      )
    }
  }

  async updateSubscriptionOneTimeShippingAddress(
    input: UpdateShippingAddressInputEntity
  ): PromiseResult<
    void | {
      data: FailedValidationValueObject
      type: 'validationFailed'
    },
    SubscriptionErrorInstance
  > {
    try {
      const { id, ...shippingAddress } = input
      const url = `${this.baseUrl}/shipping_addresses/${id}`
      const body =
        this.subscriptionMapper.toUpdateShippingAddressRequest(shippingAddress)

      const response = await this.requestService.secured.put<any>(url, {
        body,
      })

      if (response.ok) {
        return resultOk(undefined)
      }

      if (response.status === 422) {
        return resultOk({
          data: ValidationMapper.toValidationErrors(response.json),
          type: 'validationFailed',
        })
      }

      return resultErr(
        new SubscriptionErrors.UpdateSubscriptionOneTimeShippingAddressUnknownError(
          response.json
        )
      )
    } catch (err: unknown) {
      return resultErr(
        new SubscriptionErrors.UnexpectedUpdateSubscriptionOneTimeShippingAddressError(
          err
        )
      )
    }
  }

  async deleteSubscriptionShippingAddress(
    id: number
  ): PromiseResult<void, SubscriptionErrorInstance> {
    try {
      const url = `${this.baseUrl}/shipping_addresses/${id}`

      const response = await this.requestService.secured.delete<any>(url)

      if (response.ok) {
        return resultOk(undefined)
      }

      return resultErr(
        new SubscriptionErrors.DeleteSubscriptionShippingAddressUnknownError(
          response.json
        )
      )
    } catch (err: unknown) {
      return resultErr(
        new SubscriptionErrors.UnexpectedDeleteSubscriptionShippingAddressError(
          err
        )
      )
    }
  }

  async skipSubscription(
    id: number
  ): PromiseResult<void, SubscriptionErrorInstance> {
    try {
      const url = `${this.baseUrl}/subscriptions/${id}/skip`

      const response = await this.requestService.secured.patch<any>(url)

      if (response.ok) {
        return resultOk(undefined)
      }

      return resultErr(
        new SubscriptionErrors.SkipSubscriptionUnknownError(response.json)
      )
    } catch (err: unknown) {
      return resultErr(
        new SubscriptionErrors.UnexpectedSkipSubscriptionError(err)
      )
    }
  }
}
