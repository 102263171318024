import { errorFactory } from '@nori/errors'

import type { KeyOfErrors } from '@nori/errors'

export const { CouponCodeErrors } = errorFactory('CouponCodeErrors', {
  EmptyCode: 'Coupon code is required',

  BadRequest: 'Coupon Code Submit Bad Request',
  NotFound: 'Coupon Code Not Found',
  UnprocessableEntity: 'UnprocessableEntity',
  UnexpectedError: 'Unexpected Coupon Code Submit Error',
  UnknownError: 'Unknown Coupon Code Submit  Error',
})

export type CouponCodeErrorInstance = InstanceType<
  KeyOfErrors<typeof CouponCodeErrors>
>
