import { ExportContactsCsvService } from '@nori/app-kit'
import { Action, Inject } from '@nori/di'

import { GetContactsRequestMapper } from '../../infra/mappers/get-contacts-request.mapper'
import { ContactFiltersStore } from '../store/contact-filters.store'
import { ContactsStore } from '../store/contacts.store'

const EXPORT_COLUMNS = [
  'customer_first_name',
  'customer_last_name',
  'contact_info',
  'birthday',
  'date_added',
  'is_preferred_dsr',
  'customer_type',
  'shopping_link',
  'last_order_date',
  'last_hosted_social_date',
  'expiring_rewards',
  'norwex_credits',
  'half_off_discounts',
  'free_shipping',
  'lifetime_spend',
  'notes',
]

@Action()
export class ContactsExportsAction {
  constructor(
    @Inject()
    private readonly exportContactsCsvService: ExportContactsCsvService,
    @Inject() private readonly contactsStore: ContactsStore,
    @Inject() private readonly contactFiltersStore: ContactFiltersStore,
    @Inject()
    private readonly getContactsRequestMapper: GetContactsRequestMapper
  ) {}

  async handleExportAll(): Promise<void> {
    const store = this.contactsStore

    const appliedFilters = this.contactFiltersStore.appliedFilters.reduce(
      (acc, filter) => {
        const filled = acc[filter.filterKey] || []
        filled.push(String(filter.value))
        acc[filter.filterKey] = filled

        return acc
      },
      {} as Record<string, string[]>
    )

    await this.exportContactsCsvService.exportCSVAsync({
      payload: {
        columns: EXPORT_COLUMNS,
        contactsId: [...this.contactsStore.selectedContactIds],
        query: this.getQuery(),
      },
    })
  }

  private getQuery(): string {
    return this.getContactsRequestMapper.toRequest({
      searchQuery:
        this.contactsStore.searchQuery.length >= 3
          ? this.contactsStore.searchQuery
          : undefined,
      searchFilter: this.contactsStore.searchFilter,
      filters: this.contactFiltersStore.appliedFilters,
      order: this.contactsStore.sortDirection,
      sortBy: this.contactsStore.sortBy,
    })
  }
}
