import { Inject, Service } from '@nori/di'
import { ProductListMapper } from '~/modules/products/infra/mappers/product-list.mapper'

import type { ProductListValueObject } from '~/modules/products/core'

@Service()
export class ProductListMapperService {
  constructor(
    @Inject() private readonly productListMapper: ProductListMapper
  ) {}

  toProductListValueObject(data: any): ProductListValueObject {
    return this.productListMapper.toProductListValueObject(data)
  }
}
