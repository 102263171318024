import { DiscountList, getMoneyWithIsoCurrency } from '@nori/app-kit'

import type { MoneyValueObject, PromotionValueObject } from '@nori/app-kit'

type Props = {
  totalRetailPrice?: MoneyValueObject
  totalSalePrice?: MoneyValueObject
  promotionalDiscounts: PromotionValueObject[]
  totalDiscounts?: number
  hostessDiscountValue?: MoneyValueObject
  hostessDiscountPercentage?: number
  productCredits?: MoneyValueObject
  combinedCredits?: MoneyValueObject
  dsrDiscount?: MoneyValueObject
  totalPrice: number
  currency: string
}

export function ProductItemTotalPrice({
  totalRetailPrice,
  totalSalePrice,
  promotionalDiscounts,
  totalDiscounts,
  hostessDiscountValue,
  hostessDiscountPercentage,
  productCredits,
  combinedCredits,
  dsrDiscount,
  totalPrice,
  currency,
}: Props) {
  if (!totalDiscounts) {
    return (
      <p
        data-test-id="item-total-price"
        className="text-sm font-bold text-gray-700"
      >
        {getMoneyWithIsoCurrency({
          cents: totalPrice,
          currency: currency,
        })}
      </p>
    )
  }

  return (
    <>
      <p className="text-sm text-gray-500">
        {getMoneyWithIsoCurrency(totalSalePrice ?? totalRetailPrice)}
      </p>
      <DiscountList
        containerClass="ml-2 text-primary-900 text-sm font-normal"
        priceClass="text-gray-500"
        promotionalDiscounts={promotionalDiscounts}
        combinedCredits={combinedCredits}
        dsrDiscount={dsrDiscount}
        hostessDiscountPercentage={hostessDiscountPercentage}
        hostessDiscountValue={hostessDiscountValue}
        productCredits={productCredits}
        isLastPriceUnderlined
      />
      <p
        data-test-id="item-total-price"
        className="mt-1 text-sm font-bold text-gray-700"
      >
        {getMoneyWithIsoCurrency({
          cents: totalPrice,
          currency: currency,
        })}
      </p>
    </>
  )
}
