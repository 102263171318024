export const dropdownSizesMap = {
    default: 'py-3 lg:py-5',
    medium: 'px-4 py-3 lg:py-5',
    small: 'py-3'
};
export const heightTypeMap = {
    default: 'md',
    medium: 'md',
    small: 'sm'
};
