import { observer } from '@nori/di'
import { useCartContext } from '~/modules/cart'
import { SelfCustomerCartCredits } from '~/modules/user-credits/ui/self-customer-cart-credits/self-customer-cart-credits'

import { useCartUserCreditsContext } from '../interface/cart-user-credits-context'

import { HostConsultantCartCredits } from './host-consultant-cart-credits/host-consultant-cart-credits'
import { PersonalCartCredits } from './personal-cart-credits/personal-cart-credits'

export const UserCredits = observer(() => {
  const { cartUserCreditsStore } = useCartUserCreditsContext()
  const { data } = cartUserCreditsStore.cartUserCredits
  const { cartStore } = useCartContext()

  if (!data) return null

  if (cartStore.isPersonalCart) {
    return (
      <PersonalCartCredits
        credits={data}
        customerId={cartStore.cart?.customerId}
      />
    )
  }

  if (cartStore.isSelfCustomerCart) {
    return (
      <SelfCustomerCartCredits
        credits={data}
        customerId={cartStore.cart?.customerId}
      />
    )
  }

  return <HostConsultantCartCredits />
})
