import { errorFactory } from '@nori/errors'

import type { KeyOfErrors } from '@nori/errors'

export const { PennyErrors } = errorFactory('PennyErrors', {
  GetCarBannerUnexpectedError: 'Get Car Banner Unexpected Error',
  GetCarBannerUnknownError: 'Get Car Banner Unknown Error',

  GetCheckoutSummaryUnexpectedError: 'Get Checkout Summary Unexpected Error',
  GetCheckoutSummaryUnknownError: 'Get Checkout Summary Unknown Error',

  GetRewardUnexpectedError: 'Get Reward Unexpected Error',
  GetRewardUnknownError: 'Get Reward Unknown Error',
})

export type CartErrorInstance = InstanceType<KeyOfErrors<typeof PennyErrors>>
