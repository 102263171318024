import { ContactErrors } from '~/modules/contacts/core'

import type { ContactErrorInstance } from '~/modules/contacts/core'

export class ContactErrorMapper {
  static toErrors(status: number, json: any): ContactErrorInstance {
    switch (status) {
      case 400:
        return new ContactErrors.BadRequest(json.error.message)

      case 404:
        return new ContactErrors.NotFound(json.errors)

      case 422:
        return new ContactErrors.UnprocessedEntity(json.errors)

      default:
        return this.getUnknownError(json)
    }
  }
  static getUnknownError(errors: any): ContactErrorInstance {
    const error: string = (errors?.name || '').trim().toLowerCase()

    if (!error) {
      return new ContactErrors.UnknownError(errors)
    }

    // If the request is aborted, return an AbortError
    if (error.includes('aborterror')) {
      return new ContactErrors.RequestAborted(error)
    }

    return new ContactErrors.UnknownError(errors)
  }
}
