import { useCallback } from 'react';
import { handleDates } from './handle-dates';
const MAX_DATES = 2;
export function useHandleChange({ minDate , maxDate , onChange , dateValue , type  }) {
    return useCallback((nextValue)=>{
        if (type !== 'range') {
            onChange([
                nextValue
            ]);
            return;
        }
        const usedValue = [
            nextValue,
            ...dateValue || []
        ];
        if (usedValue.length > MAX_DATES) {
            usedValue.splice(1, usedValue.length);
        }
        handleDates({
            minDate,
            maxDate,
            onChange,
            values: usedValue
        });
    }, [
        dateValue,
        onChange,
        type
    ]);
}
