import { errorFactory } from '@nori/errors'

import type { KeyOfErrors } from '@nori/errors'

export const { PaymentMethodErrors } = errorFactory('PaymentMethodErrors', {
  CreatePaymentMethodHostedFieldsUndefinedError:
    'Create Payment Methods Error: Hosted Fields Are Not Defined',
})

export type PaymentMethodErrorInstance = InstanceType<
  KeyOfErrors<typeof PaymentMethodErrors>
>
