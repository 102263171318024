import { useProfileContext } from '@nori/app-kit'
import { observer } from '@nori/di'
import { Icon, Spinner } from '@nori/ui-kit'
import { useCartContext } from '~/modules/cart'
import { useWishListContext } from '~/modules/wishlist/interface/use-wish-list.context'

type Props = {
  skuId?: number
}

export const WishButton = observer(function WishButton({ skuId }: Props) {
  const { wishListStore, wishListAction } = useWishListContext()
  const { userStore } = useProfileContext()
  const { cartStore } = useCartContext()

  if (!userStore.isOSEnabled && !cartStore.isPersonalCart) {
    return null
  }

  if (!skuId)
    return (
      <div className="h-5 w-5 cursor-not-allowed text-primary-200">
        <Icon name="HeartOutline" />
      </div>
    )

  if (wishListStore.processingItemMap[skuId]) {
    return (
      <div className="h-5 w-5" data-test-id="add-to-wishlist-loader">
        <Spinner />
      </div>
    )
  }

  if (wishListStore.isItemInWishList(skuId)) {
    return (
      <button
        className="flex items-center justify-center text-primary-900"
        data-test-id="in-wishlist-button"
        onClick={() => wishListAction.handleRemoveItemFromWishList(skuId)}
      >
        <Icon name="Heart" />
      </button>
    )
  }

  return (
    <button
      className="flex items-center justify-center text-primary-900"
      data-test-id="add-to-wishlist-button"
      onClick={() => wishListAction.handleAddItemToWishList(skuId)}
    >
      <Icon name="HeartOutline" />
    </button>
  )
})
