import { useRouter } from 'next/router'

import { useTranslations } from '@nori/lang-i18n'
import { Button, Loader } from '@nori/ui-kit'
import { useContactContext } from '~/modules/contacts/interface/use-contact-context'
import { Routes } from '~/modules/routes'

import { WishListItem } from './wish-list.item'

import type {
  ContactDetailsEntity,
  ContactEntity,
} from '~/modules/contacts/core'
import type { WishItemEntity } from '../core/entities/wish-item.entity'
import type { ProcessingItemValueObject } from '../core/value-object/processing-item.value-object'

type Props = {
  isLoading: boolean
  isError: boolean
  wishList?: WishItemEntity[]
  contact?: ContactDetailsEntity | undefined
  processingItemMap?: ProcessingItemValueObject
  isAddingToCart?: boolean
  isPersonal?: boolean
  onAddItemToBag?: (id: number, isFromWishList: boolean) => void
  onRemoveItemFromWishList: (id: number) => void
}

export const WishListContent = ({
  isLoading,
  isError,
  wishList,
  contact,
  processingItemMap,
  isAddingToCart,
  isPersonal = false,
  onAddItemToBag,
  onRemoveItemFromWishList,
}: Props) => {
  const { contactDetailsAction } = useContactContext()
  const t = useTranslations('wishList')
  const router = useRouter()
  const emptyWishlistText = isPersonal
    ? t('emptyWishlistPersonal')
    : t('emptyWishlist')
  const handleItemAdd = (id: number) => {
    onAddItemToBag?.(id, true)
  }
  const handleShopNowClick = () => {
    if (isPersonal) {
      return router.push(Routes.shop.isPersonal.path())
    }

    if (contact) {
      contactDetailsAction.handleNavigateToNewOrder({
        id: contact.id,
        customerId: contact.customerId,
        email: contact.email,
        firstName: contact.firstName,
        lastName: contact.lastName,
      } as ContactEntity)
    }
  }

  if (isLoading) {
    return (
      <div className="p-5">
        <Loader />
      </div>
    )
  }

  if (isError) {
    return <div>Error</div>
  }

  if (!wishList?.length) {
    return (
      <div className="mb-10 flex flex-col items-center">
        <p className="mb-5 mt-3 text-lg font-normal text-gray-500 lg:mb-8 lg:mt-4 lg:text-2xl">
          {emptyWishlistText}
        </p>
        <Button size="lg" onClick={handleShopNowClick}>
          {t('shopNow')}
        </Button>
      </div>
    )
  }

  return (
    <div className="flex flex-wrap content-center justify-center gap-x-14 pt-6 md:pt-11 lg:mb-5">
      {wishList?.map((item) => {
        return (
          <div key={item.id} className="mb-6">
            <WishListItem
              item={item}
              processingItemMap={processingItemMap}
              isAddingToCart={isAddingToCart}
              onAddItemToBag={handleItemAdd}
              onRemoveItemFromWishList={onRemoveItemFromWishList}
            />
          </div>
        )
      })}
    </div>
  )
}
