import { useTranslations } from '@nori/lang-i18n'

type Props = {
  children: React.ReactNode
}
export const NoteListItemEdit = ({ children }: Props) => {
  const t = useTranslations('contacts.notes.input')
  return (
    <div>
      <div className="text-sm text-gray-500">{t('editYourNote')}</div>

      <div className="mt-5">{children}</div>
    </div>
  )
}
