import { NextOrderContainer } from '../next-order-container'
import { NextOrderCredits } from '../next-order-credits'
import { NextOrderFailedPaymentMessage } from '../next-order-failed-payment-message'
import { NextOrderFreeShippingMessage } from '../next-order-free-shipping-message'
import { NextOrderSettings } from '../next-order-settings/next-order-settings'

import { NextOrderAddItem } from './next-order-add-items'
import { NextOrderTable } from './next-order-table'

type Props = {
  isContactDetailsPage?: boolean
}

export const NextOrderPanelContent = ({ isContactDetailsPage }: Props) => {
  return (
    <div className="mt-6">
      <div className="mb-8">
        <NextOrderCredits isContactDetailsPage={isContactDetailsPage} />
      </div>
      <NextOrderContainer isContactDetailsPage={isContactDetailsPage}>
        <NextOrderFailedPaymentMessage />
        <NextOrderFreeShippingMessage />
        <div className="gap-24 lg:flex lg:justify-between">
          <NextOrderSettings isContactDetailsPage={isContactDetailsPage} />
          <div className="w-full max-lg:mt-5 lg:max-w-[180px]">
            {!isContactDetailsPage && <NextOrderAddItem />}
          </div>
          <div className="mt-5 h-[1px] w-full bg-gray-100 lg:hidden" />
        </div>

        <div className="mt-5 lg:mt-10">
          <NextOrderTable isContactDetailsPage={isContactDetailsPage} />
        </div>
      </NextOrderContainer>
    </div>
  )
}
