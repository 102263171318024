import { hookContextFactory } from '@nori/di'

import { CartUserCreditsAction } from './actions/cart-user-credits.action'
import { CartUserCreditsStore } from './store/cart-user-credits.store'
import { CurrentUserCreditsStore } from './store/current-user-credits.store'

export const { useModuleContext: useCartUserCreditsContext } =
  hookContextFactory({
    userCreditsStore: CurrentUserCreditsStore,
    cartUserCreditsAction: CartUserCreditsAction,
    cartUserCreditsStore: CartUserCreditsStore,
  })
