import { getBaseUrl, RequestService, SERVICE_NAMES } from '@nori/app-kit'
import { Adapter, Inject } from '@nori/di'
import { isAbortControllerError } from '@nori/fetch-request'
import { resultErr, resultOk } from '@nori/result'

import { OrdersListErrors } from '../core'

import { OrdersListMapper } from './mappers/orders-list.mapper'

import type { PromiseResult } from '@nori/result'
import type {
  MoveOrderInput,
  OrdersListErrorInstance,
  OrdersListValueObject,
} from '../core'
import type orderListWithStatusMock from './mocks/order-list-with-status.mock.json'
import type { OrdersListInput } from './types/orders-list/orders-list.input'
import type { PartyOrdersListInput } from './types/party-orders-list.input'

@Adapter()
export class OrdersListAdapter {
  private readonly baseUrl = getBaseUrl(SERVICE_NAMES.OMS_SERVICE)
  private orderListAbortController?: AbortController
  private partyOrderListAbortController?: AbortController

  public constructor(
    @Inject() private readonly orderListMapper: OrdersListMapper,
    @Inject() private readonly requestService: RequestService
  ) {}

  public async getOrdersList({
    searchFilter,
    ordering = 'desc',
    page = 1,
    perPage = 20,
    sortBy,
    ...restArgs
  }: OrdersListInput): PromiseResult<
    OrdersListValueObject,
    OrdersListErrorInstance
  > {
    try {
      this.orderListAbortController?.abort()
      this.orderListAbortController = new AbortController()
      const query = this.orderListMapper.toOrderListRequest({
        searchFilter,
        ordering,
        page,
        perPage,
        sortBy,
        ...restArgs,
      })

      const url = `${this.baseUrl}/orders/search?${query}`

      const { ok, json } = await this.requestService.secured.get<
        typeof orderListWithStatusMock
      >(url, { abortController: this.orderListAbortController })

      if (ok) {
        return resultOk(this.orderListMapper.toOrderListValueObject(json))
      }

      return resultErr(new OrdersListErrors.GetOrders(json))
    } catch (err: unknown) {
      if (isAbortControllerError(err)) {
        return resultErr(new OrdersListErrors.AbortControllerError(err))
      }
      return resultErr(new OrdersListErrors.UnexpectedGetOrderList(err))
    }
  }

  public async getPartyOrdersList(
    partyId: number,
    {
      searchFilter,
      searchParam,
      ordering = 'desc',
      page = 1,
      perPage = 20,
      sortBy,
    }: PartyOrdersListInput
  ): PromiseResult<OrdersListValueObject, OrdersListErrorInstance> {
    try {
      this.partyOrderListAbortController?.abort()
      this.partyOrderListAbortController = new AbortController()
      const query = this.orderListMapper.toOrderListRequest({
        searchFilter,
        searchParam,
        ordering,
        page,
        perPage,
        sortBy,
      })

      const url = `${this.baseUrl}/orders/party_orders/${partyId}?${query}`

      const { ok, json } = await this.requestService.secured.get<
        typeof orderListWithStatusMock
      >(url, { abortController: this.partyOrderListAbortController })

      if (ok) {
        return resultOk(this.orderListMapper.toOrderListValueObject(json))
      }

      return resultErr(new OrdersListErrors.GetPartyOrders(json))
    } catch (err: unknown) {
      if (isAbortControllerError(err)) {
        return resultErr(new OrdersListErrors.AbortControllerError(err))
      }
      return resultErr(new OrdersListErrors.UnexpectedGetPartyOrders(err))
    }
  }

  public async moveOrder({
    publicOrderId,
    eventId,
  }: MoveOrderInput): PromiseResult<void> {
    try {
      const url = `${this.baseUrl}/orders/${publicOrderId}/move_party`

      const { ok, json } = await this.requestService.secured.patch(url, {
        body: this.orderListMapper.toMoveOrderRequest({
          publicOrderId,
          eventId,
        }),
      })

      if (ok) return resultOk(undefined)

      return resultErr(new OrdersListErrors.UnknownMoveOrderError(json))
    } catch (err: unknown) {
      return resultErr(new OrdersListErrors.UnexpectedMoveOrderError(err))
    }
  }
}
