import { Inject, Service } from '@nori/di'
import { getBlock } from '@nori/lang-i18n'
import { NotifyAction } from '@nori/notify'
import { isErr, resultErr, resultOk } from '@nori/result'
import { sleep } from '@nori/ui-kit'
import { createLogger } from '~/infra/create-logger'
import { AddToCartErrors, CartErrors } from '~/modules/cart/core'
import { CartStore } from '~/modules/cart/interface/store/cart-store/cart.store'

import { CartAdapter } from '../../infra/cart.adapter'

import type { BundleComponentEntity, CartEntity } from '@nori/app-kit'
import type { PromiseResult, Result } from '@nori/result'
import type { CartErrorInstance } from '~/modules/cart/core'
import type { AddManyToCartInput } from '~/modules/cart/infra/types/add-many-to-cart/add-many-to-cart.input'
import type {
  AddBundleToCartParams,
  AddStyleToCartParams,
} from '~/modules/cart/interface/actions/cart-action.types'

const logger = createLogger('add-to-cart.service')

const STATUS_RESET_TIMEOUT_MS = 2000

@Service()
export class AddToCartService {
  constructor(
    @Inject() private readonly cartAdapter: CartAdapter,
    @Inject() private readonly cartStore: CartStore,
    @Inject() private readonly notifyAction: NotifyAction
  ) {}

  getBundleSkuIds(bundleComponents?: BundleComponentEntity[]): number[] {
    return (
      bundleComponents?.flatMap((component) =>
        component.products.flatMap((product) =>
          product.skus.map((sku) => sku.id)
        )
      ) ?? []
    )
  }

  async handleAddBundleToCart({
    id,
    code,
    quantity,
    skuIds,
    statusId,
    subscriptionPlanId,
  }: AddBundleToCartParams): Promise<void> {
    const cartId = this.cartStore.cart?.id
    if (!cartId || !skuIds.length) return

    this.cartStore.setIsAddingToCart(id)
    this.cartStore.setAddToCartStatus(statusId, 'loading')
    this.cartStore.cartData.setError(undefined)

    const input = {
      cartId,
      code,
      quantity,
      skuIds,
      subscriptionPlanId,
    }
    const result = await this.cartAdapter.addBundleToCart(input)
    await this.processAddToCartResult(statusId, result, id)
  }

  async handleAddStyleToCart({
    id,
    skuId,
    quantity,
    statusId,
    subscriptionPlanId,
  }: AddStyleToCartParams): Promise<void> {
    const cartId = this.cartStore.cart?.id
    if (!cartId || !skuId) return

    this.cartStore.setIsAddingToCart(id)
    this.cartStore.setAddToCartStatus(statusId, 'loading')
    this.cartStore.cartData.setError(undefined)

    const input = { cartId, quantity, skuId, subscriptionPlanId }
    const result = await this.addStyleToCart(input)
    await this.processAddToCartResult(statusId, result, id)
  }

  async addStyleToCart({
    cartId,
    quantity,
    skuId,
    subscriptionPlanId,
  }: {
    cartId: number
    quantity: number
    skuId: number
    subscriptionPlanId?: number
  }): PromiseResult<CartEntity, CartErrorInstance> {
    const result = await this.cartAdapter.addStyleToCart({
      cartId,
      quantity,
      skuId,
      subscriptionPlanId,
    })
    return result
  }

  private async processAddToCartResult(
    statusId: string,
    result: Result<CartEntity, CartErrorInstance>,
    id?: number
  ): Promise<void> {
    if (isErr(result)) {
      logger.error(result.error.key, result.error)
      this.cartStore.cartData.setError(result.error)
      const addToCartStatusError =
        result.error instanceof AddToCartErrors.NotAvailableError
          ? 'outOfStock'
          : 'error'
      this.cartStore.setAddToCartStatus(statusId, addToCartStatusError)
      this.cartStore.setIsAddingToCart(id)
      const { addToCartErrorTitle } = this.cartStore

      if (addToCartErrorTitle) {
        this.notifyAction.handleAddNotify({
          type: 'danger',
          title: addToCartErrorTitle,
        })
      }

      await this.resetAddToCartStatus(statusId, STATUS_RESET_TIMEOUT_MS)
      return
    }

    this.cartStore.cartData.setData(result.data)
    this.cartStore.setAddToCartStatus(statusId, 'success')
    this.cartStore.setIsAddingToCart(id)
    await this.resetAddToCartStatus(statusId, STATUS_RESET_TIMEOUT_MS)
  }

  private async resetAddToCartStatus(
    statusId: string,
    timeoutMs: number
  ): Promise<void> {
    await sleep(timeoutMs)
    this.cartStore.setAddToCartStatus(statusId, 'default')
  }

  public async addManyToCart(
    input: AddManyToCartInput
  ): PromiseResult<CartEntity, CartErrorInstance> {
    const result = await this.cartAdapter.addManyToCart(input)

    if (isErr(result)) {
      this.notifyAction.handleAddNotify({
        type: 'danger',
        title: getBlock('cart.errors')('addToCartError'),
      })
      logger.error(result.error.key, result.error)
      return result
    }

    if (result.data.bundleErrors.length || result.data.skuErrors.length) {
      this.notifyAction.handleAddNotify({
        type: 'danger',
        title: getBlock('cart.errors')('addToCartError'),
      })
      const error = new CartErrors.UnknownError(result.data)
      logger.error(error.key, error)
      return resultErr(error)
    }

    this.cartStore.cartData.setData(result.data.cart)
    return resultOk(result.data.cart)
  }
}
