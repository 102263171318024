import { Inject, Store } from '@nori/di'
import { getBlock } from '@nori/lang-i18n'
import { CartStore } from '~/modules/cart/interface/store/cart-store/cart.store'

import { ApplyHostessCreditsStore } from './apply-hostess-credits.store'
import { ApplyProductCreditsStore } from './apply-product-credits.store'

import type { MoneyValueObject } from '@nori/app-kit'
import type { ApplyCreditsStoreInterface } from './apply-credits.interface'

@Store()
export class ApplyCreditsStore implements ApplyCreditsStoreInterface {
  constructor(
    @Inject()
    private readonly applyHostessCreditsStore: ApplyHostessCreditsStore,
    @Inject()
    private readonly applyProductCreditsStore: ApplyProductCreditsStore,
    @Inject()
    private readonly cartStore: CartStore
  ) {}

  get applicableCartCredits(): MoneyValueObject | undefined {
    if (
      !this.creditsStoreInstance.appliedCredits?.cents ||
      !this.creditsStoreInstance.applicableCartCredits?.cents
    ) {
      return this.creditsStoreInstance.applicableCartCredits
    }
    return {
      ...this.creditsStoreInstance.applicableCartCredits,
      cents: Number(this.creditsStoreInstance.applicableCartCredits?.cents),
    } as MoneyValueObject
  }

  get hasApplicableCredits(): boolean {
    return this.creditsStoreInstance.hasApplicableCredits
  }

  get isShowApplyCredits(): boolean {
    return this.creditsStoreInstance.isShowApplyCredits
  }

  get isShowConfirmationModal(): boolean {
    return this.creditsStoreInstance.isShowConfirmationModal
  }

  get creditAmount(): MoneyValueObject | undefined {
    return this.creditsStoreInstance.creditAmount
  }

  get creditError(): string | undefined {
    const error = this.creditsStoreInstance.updatedCredits.error
    if (!error) return

    return getBlock('cartApplyCredits')('applyCreditsError')
  }

  get isLoading(): boolean {
    return this.creditsStoreInstance.updatedCredits.isLoading
  }

  get creditInputValue(): string {
    return this.creditsStoreInstance.creditInputValue
  }

  get appliedCredits(): MoneyValueObject | undefined {
    return this.creditsStoreInstance.appliedCredits
  }

  setValue(value: string): void {
    this.creditsStoreInstance.setValue(value)
  }

  private get creditsStoreInstance():
    | ApplyProductCreditsStore
    | ApplyHostessCreditsStore {
    if (this.cartStore.isPersonalCart) return this.applyProductCreditsStore

    return this.applyHostessCreditsStore
  }
}
