import { getLink } from '@nori/app-kit'
import { observer } from '@nori/di'
import { useTranslations } from '@nori/lang-i18n'
import { Icon, LinkClipboardCopy } from '@nori/ui-kit'
import { useFormatAddressToString } from '~/modules/autosave/ui/use-format-address-to-string'
import { useContactContext } from '~/modules/contacts/interface/use-contact-context'
import { NoteList } from '~/modules/notes/ui'

import { getBirthdayString } from '../../interface/get-birthday-string'
import { getDate } from '../contacts-page/contact-list-row/get-date'

export const ContactInfo = observer(() => {
  const t = useTranslations('contactDetailsPage.info')
  const { contactDetailsStore } = useContactContext()
  const { contact } = contactDetailsStore
  const contactAddress = useFormatAddressToString(contact?.currentAddress)
  const personalLink = contact?.belongsToDifferentDsr
    ? { title: t('belongsToDifferentDsr'), url: '' }
    : getLink({
        type: 'b2c',
        personalLink: contact?.personalLink,
        isMask: true,
      })

  const shouldDisplayNotRegisteredWarning =
    contact?.isCustomerRegistered === false

  const shouldShowLink =
    !shouldDisplayNotRegisteredWarning && !!contact?.customerId

  const linkStyle = shouldShowLink ? 'flex' : 'hidden'

  if (!contact) return null

  return (
    <>
      <div>
        {contactDetailsStore.isCustomer && (
          <div className="hidden text-sm font-bold text-gray-500 md:block lg:mt-6">
            {t('hostActivity')}
          </div>
        )}

        {contact.isPreferredDsr && (
          <div className="mt-2.5 flex items-center text-primary-900 lg:mt-7">
            <span className="mr-1.5">
              <Icon name="StarOutline" />
            </span>
            <span className="text-center text-sm">
              {t('preferredConsultant')}
            </span>
          </div>
        )}

        {contact.isPotentialHost && (
          <div className="mt-3 flex items-center text-primary-900 lg:mt-2">
            <span className="mr-1.5">
              <Icon name="Check" />
            </span>
            <span className="text-center text-sm">
              {t('isPotentialHost', { name: contact.firstName })}
            </span>
          </div>
        )}

        {contact.isPotentialConsultant && (
          <div className="mt-3 flex items-center text-primary-900 lg:mt-2">
            <span className="mr-1.5">
              <Icon name="Check" />
            </span>
            <span className="text-center text-sm">
              {t('isPotentialConsultant', { name: contact.firstName })}
            </span>
          </div>
        )}

        <div className="mb-3 mt-7 text-sm text-primary-900 lg:mb-12 lg:mt-12">
          <div className="flex flex-row border-b-2 py-5">
            <div className="w-4/12 font-semibold lg:w-2/12">
              {t('birthday')}
            </div>
            <div className="w-8/12">{getBirthdayString(contact)}</div>
          </div>
          <div className="flex flex-row border-b-2 py-5 ">
            <div className="w-4/12 font-semibold lg:w-2/12">
              {t('contactAddress')}
            </div>
            <div className="w-8/12">{contactAddress}</div>
          </div>
          <div className="flex flex-row border-b-2 py-5">
            <div className="w-4/12 font-semibold lg:w-2/12">
              {t('dateAdded')}{' '}
            </div>
            <div className="w-8/12">
              {getDate({ date: contact.createdAt, format: 'MMM dd, yyyy' })}
            </div>
          </div>
          {shouldDisplayNotRegisteredWarning && (
            <div className="mb-3 flex flex-row border-b-2 py-5">
              <div className="w-4/12 font-semibold lg:w-2/12">
                {t('hostRewardsLink')}
              </div>
              <div className="w-8/12 break-words font-bold text-gray-500 lg:flex lg:gap-5">
                {t('mustHaveAnAccountToShoppingLink')}
              </div>
            </div>
          )}
          <div className={`${linkStyle} mb-3 flex-row border-b-2 py-5`}>
            <div className="w-4/12 font-semibold lg:w-2/12">
              {t('hostRewardsLink')}
            </div>
            <div className="w-8/12 break-words lg:flex lg:gap-5">
              <div data-test-id="contact-details-referral-link">
                {personalLink.title}
              </div>
              {personalLink.url && (
                <div className="mt-3 cursor-pointer lg:mt-0.5">
                  <LinkClipboardCopy value={personalLink.url} />
                </div>
              )}
            </div>
          </div>
        </div>

        <NoteList contactId={contact.id} />
      </div>
    </>
  )
})
