import { useTranslations } from '@nori/lang-i18n'
import { Button, Input } from '@nori/ui-kit'

type Props = {
  itemQty: string
  onItemQtyChange?: (qty: string) => void
  handleSave?: () => void
}

export const NextOrderAddonEditor = ({
  itemQty,
  onItemQtyChange,
  handleSave,
}: Props) => {
  const t = useTranslations('autosaveSettings.nextOrderTable')

  return (
    <div className="flex flex-wrap items-center gap-x-5 gap-y-6">
      <div className="w-full max-w-full lg:max-w-[100px]">
        <Input
          label={t('qty')}
          type="integer"
          min={1}
          value={itemQty}
          onChange={onItemQtyChange}
          hideHelperBlock
          data-test-id={'quantity-input'}
        />
      </div>
      <div className="h-full w-72 max-lg:hidden">
        <Button widthType="full" size="xl" onClick={handleSave}>
          {t('save')}
        </Button>
      </div>
      <div className="h-full w-full lg:hidden">
        <Button widthType="full" size="base" onClick={handleSave}>
          {t('save')}
        </Button>
      </div>
    </div>
  )
}
