import { observer } from '@nori/di'
import { useTranslations } from '@nori/lang-i18n'
import { Button, PageHeader } from '@nori/ui-kit'
import { useContactContext } from '~/modules/contacts/interface/use-contact-context'

export const ContactPageHeader = observer(() => {
  const { contactsAction } = useContactContext()
  const t = useTranslations()

  return (
    <PageHeader title={t('contacts.list.title')}>
      <div className="w-[130px] lg:w-[300px]">
        <Button
          onClick={contactsAction.handleOpenCreateContactPage}
          widthType="full"
        >
          <span className="text-xs font-bold lg:text-base">
            {
              <>
                <span className="lg:hidden">
                  {t('contacts.list.createNewContact.base')}
                </span>
                <span className="hidden lg:inline-block">
                  {t('contacts.list.createNewContact.lg')}
                </span>
              </>
            }
          </span>
        </Button>
      </div>
    </PageHeader>
  )
})
