import { CreateNoteErrors } from '../../core/create-note.errors'

import type { CreateNoteErrorInstance } from '../../core/create-note.errors'

export class CreateNoteErrorsMapper {
  static toErrors(json: any, status: number): CreateNoteErrorInstance {
    switch (status) {
      case 400:
        return new CreateNoteErrors.BadRequest(json.errors.message)

      default:
        return new CreateNoteErrors.UnknownError(json)
    }
  }
}
