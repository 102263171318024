import { Action, Inject } from '@nori/di'
import { isErr } from '@nori/result'
import { createLogger } from '~/infra/create-logger'
import { AddToCartService } from '~/modules/cart/interface/services/add-to-cart.service'
import { CartStore } from '~/modules/cart/interface/store/cart-store/cart.store'

import { BundleOptionsAdapter } from '../../infra/bundle-options.adapter'
import {
  CustomizeBundleStore,
  INITIAL_STEP,
} from '../store/customize-bundle.store'

const logger = createLogger('customize-bundle.actions')

const STEP_VALUE = 1

@Action()
export class CustomizeBundleAction {
  constructor(
    @Inject() private readonly customizeBundleStore: CustomizeBundleStore,
    @Inject() private readonly addToCartService: AddToCartService,
    @Inject() private readonly bundleOptionsAdapter: BundleOptionsAdapter,
    @Inject() private readonly cartStore: CartStore
  ) {}

  handleDisableEditMode(): void {
    this.customizeBundleStore.setBundleId(undefined)
    this.customizeBundleStore.setBundleCode(undefined)
    this.customizeBundleStore.editMode.handleClose()
  }

  async handleEditBundle({
    bundleCode,
    bundleId,
  }: {
    bundleId: number
    bundleCode: string
  }): Promise<void> {
    this.customizeBundleStore.editMode.handleOpen()
    this.customizeBundleStore.setBundleId(bundleId)
    this.customizeBundleStore.setBundleCode(bundleCode)
    await this.handleLoadBundleOptions()
    this.handleOpenModal()
  }

  handleOpenModal(): void {
    this.customizeBundleStore.modalToggle.handleOpen()
  }

  handleCloseModal(): void {
    this.customizeBundleStore.modalToggle.handleClose()
    this.customizeBundleStore.dropStore()
    this.customizeBundleStore.setBundleId(undefined)
    this.customizeBundleStore.setBundleCode(undefined)
    this.customizeBundleStore.editMode.handleClose()
  }

  handleCustomizeBundle({
    bundleId,
    subscriptionPlanId,
    bundleCode,
  }: {
    subscriptionPlanId?: number
    bundleId: number
    bundleCode: string
  }): void {
    this.customizeBundleStore.setSubscriptionPlanId(subscriptionPlanId)
    this.customizeBundleStore.setBundleId(bundleId)
    this.customizeBundleStore.setBundleCode(bundleCode)
    this.handleOpenModal()
  }

  handleNavigateBack(): void {
    if (this.customizeBundleStore.currentStep === INITIAL_STEP) return
    this.customizeBundleStore.setCurrentStep(
      this.customizeBundleStore.currentStep - STEP_VALUE
    )
  }

  handleNavigateNext(): void {
    this.customizeBundleStore.setCurrentStep(
      this.customizeBundleStore.currentStep + STEP_VALUE
    )
  }

  handleSelectSku(skuId: number): void {
    if (this.customizeBundleStore.isLastStep) return
    this.customizeBundleStore.setSelectedSkuId(skuId)
  }

  async handleAddBundleToCart(): Promise<void> {
    if (this.customizeBundleStore.isLastStep) {
      const action = this.customizeBundleStore.editMode.isOpen
        ? this.replaceBundleInCart
        : this.addToCart
      await action()
      this.handleCloseModal()
      this.customizeBundleStore.dropStore()
      return
    }

    this.handleNavigateNext()
  }

  private async replaceBundleInCart(): Promise<void> {
    const { bundleId, bundleCode, selectedSku } = this.customizeBundleStore

    const { cart, storeType } = this.cartStore

    if (!bundleId || !bundleCode) {
      return
    }

    if (!cart?.id || !storeType) {
      return
    }
    this.customizeBundleStore.setIsLoading(true)

    const skuIds = Object.values(selectedSku).filter((id): id is number => !!id)

    await this.bundleOptionsAdapter.replaceBundle({
      id: bundleId,
      quantity: 1,
      code: bundleCode,
      skuIds,
      cartId: cart.id,
      store: storeType,
    })

    this.customizeBundleStore.setIsLoading(false)
  }

  private async addToCart(): Promise<void> {
    const {
      bundleId,
      bundleCode,
      selectedSku,
      subscriptionPlanId,
      invisibleBundleComponents,
    } = this.customizeBundleStore
    if (!bundleId || !bundleCode) {
      return
    }
    this.customizeBundleStore.setIsLoading(true)

    const invisibleSkuIds: number[] = []
    if (invisibleBundleComponents) {
      invisibleBundleComponents.forEach((component, index) => {
        const isInvisible = component.products.every(
          (product) => product.product.visible === false
        )
        if (isInvisible) {
          invisibleSkuIds[index] = component.products?.[0]?.skus?.[0]?.id
            ? component?.products?.[0]?.skus?.[0]?.id
            : 0
        }
      })
    }
    const skuIds = Object.values(selectedSku)
      .filter((id): id is number => !!id)
      .concat(invisibleSkuIds)

    await this.addToCartService.handleAddBundleToCart({
      id: bundleId,
      quantity: 1,
      code: bundleCode,
      skuIds,
      statusId: bundleCode,
      subscriptionPlanId,
    })

    this.customizeBundleStore.setIsLoading(false)
  }

  async handleLoadBundleOptions(): Promise<void> {
    this.customizeBundleStore.bundleEditOptions.setIsLoading(true)
    this.customizeBundleStore.bundleEditOptions.setError(undefined)

    await this.loadBundleOptions()

    this.customizeBundleStore.bundleEditOptions.setIsLoading(false)
  }

  private async loadBundleOptions(): Promise<void> {
    const { bundleCode } = this.customizeBundleStore
    if (!bundleCode) {
      logger.error('bundle-code-not-defined')
      return
    }

    const result = await this.bundleOptionsAdapter.getBundleOptionsInCart(
      bundleCode
    )

    if (isErr(result)) {
      logger.error(result.error.key, result.error)
      return
    }

    this.customizeBundleStore.bundleEditOptions.setData(result.data)
  }
}
