import { getBaseUrl, RequestService, SERVICE_NAMES } from '@nori/app-kit'
import { Adapter, Inject } from '@nori/di'
import { resultErr, resultOk } from '@nori/result'
import { SkuErrors } from '~/modules/sku/core'

import { SkuMapper } from './mappers/sku.mapper'

import type { PromiseResult } from '@nori/result'
import type { SkuEntity, SkuErrorInstance } from '~/modules/sku/core'

@Adapter()
export class SkuAdapter {
  private readonly baseUrl = getBaseUrl(SERVICE_NAMES.CATALOG_SERVICE)

  constructor(
    @Inject() private readonly requestService: RequestService,
    @Inject() private readonly skuMapper: SkuMapper
  ) {}

  async getSkus(codes: string[]): PromiseResult<SkuEntity[], SkuErrorInstance> {
    try {
      const query = new URLSearchParams()
      codes.forEach((code) => query.append('codes[]', code))

      const url = `${getBaseUrl(
        SERVICE_NAMES.CATALOG_SERVICE
      )}/skus?${query.toString()}`

      const response = await this.requestService.secured.get<any>(url)

      if (response.ok) {
        return resultOk(this.skuMapper.toSkus(response.json))
      }

      return resultErr(new SkuErrors.GetSkusUnknownError(response.json))
    } catch (err: unknown) {
      return resultErr(new SkuErrors.GetSkusUnexpectedError(err))
    }
  }
}
