import { useState } from 'react'

import { getMoneyWithIsoCurrency, ProductImage } from '@nori/app-kit'
import { useTranslations } from '@nori/lang-i18n'
import { Icon } from '@nori/ui-kit'

import { AutosaveDropdown } from '../autosave-dropdown'

import { Row } from './row'

import type { CommonBundleEntity } from '@nori/app-kit'

type Props = {
  item: CommonBundleEntity
}

export function BundleRow({ item }: Props) {
  const t = useTranslations('autosaveSettings.addItemModal')
  const [subscriptionId, setSubscriptionId] = useState(
    item.defaultProductSubscription.id || -1
  )

  return (
    <Row item={item} subscriptionId={subscriptionId}>
      <div className="flex">
        <ProductImage
          alt="No Image Available"
          width={80}
          height={80}
          styleName="mr-3 h-20 w-20"
          src={item.pictures[0]?.thumbnailMedURL}
        />
        <div className="flex flex-col gap-1">
          <div className="break-all text-base font-bold text-gray-700">
            {item.name ?? item.brandName}
          </div>
          <div className="text-sm font-medium text-gray-500">{item.code}</div>
          {!!item.productSubscriptions?.length && (
            <div className="mt-3">
              <AutosaveDropdown
                subscriptionId={subscriptionId}
                onSubscriptionChange={setSubscriptionId}
                subscriptions={item.productSubscriptions}
              />
            </div>
          )}
          {item.maxDsrPrice && (
            <div className="mt-4 text-sm text-primary-900">
              <div className="font-medium">{t('subscription')}</div>
              <div className="mt-1 flex gap-1 font-bold">
                <Icon name="Refresh" />
                {getMoneyWithIsoCurrency({
                  cents: item.maxDsrPrice.cents,
                  currency: item.maxDsrPrice.currency,
                })}
              </div>
            </div>
          )}
        </div>
      </div>

      {item.maxPrice && (
        <div className="text-base text-primary-900">
          {getMoneyWithIsoCurrency({
            cents: item.maxPrice.cents,
            currency: item.maxPrice.currency,
          })}
        </div>
      )}
    </Row>
  )
}
