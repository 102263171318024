import { useRouter } from 'next/router'

import { NorwexIcon, NorwexLogo, useProfileContext } from '@nori/app-kit'
import { observer } from '@nori/di'
import { Avatar, Icon } from '@nori/ui-kit'
import { ChildrenList } from '~/modules/header/ui/authenticated-header/desktop/children-list'
import { HeaderLink } from '~/modules/header/ui/authenticated-header/desktop/header-link'
import { UserDropDown } from '~/modules/header/ui/authenticated-header/desktop/user-dropdown'
import { DESKTOP_LINKS } from '~/modules/header/ui/header-links/header-links'
import { USER_LINKS } from '~/modules/header/ui/header-links/user-links'
import { Routes } from '~/modules/routes'

import { CartIcon } from '../shared/cart-icon'

export const BarTop = observer(() => {
  const router = useRouter()
  const { userStore } = useProfileContext()

  let avatarIcon = (
    <div className="flex h-7 w-7 items-center justify-center rounded-full border-2 border-solid border-white p-1">
      <NorwexIcon />
    </div>
  )

  if (!userStore.currentUser.profilePhoto.isBlank) {
    avatarIcon = (
      <Avatar
        isBlank={userStore.currentUser.profilePhoto.isBlank}
        imageUrl={userStore.currentUser.profilePhoto.thumbnailSmallUrl}
        size="sm"
      />
    )
  }

  return (
    <>
      <div className="mx-auto flex h-20 w-full max-w-top-section-content flex-row items-center justify-between pl-6 pr-6 text-base font-normal text-white">
        <div className="flex h-full flex-row items-center gap-8">
          <button
            onClick={() => router.push(Routes.home.path())}
            className="text-white"
          >
            <NorwexLogo />
          </button>

          {DESKTOP_LINKS.map((item) => (
            <HeaderLink
              key={item.translationKey}
              link={item}
              data-test-id={item.dataTestId}
              verticalAlign="top-19"
              borderRadiusType="sm"
              isFullHeight
              listSlot={<ChildrenList linkChildren={item.children} />}
            />
          ))}
        </div>

        <CartIcon />

        <div className="flex h-full flex-row items-center gap-4">
          <HeaderLink
            link={USER_LINKS}
            align="right"
            verticalAlign="top-18"
            isFullHeight
            dropdownWidth="lg"
            hasListPadding={false}
            listSlot={<UserDropDown links={USER_LINKS} />}
            data-test-id={USER_LINKS.dataTestId}
          >
            <div className="gap flex flex-row items-center">
              {avatarIcon}
              <Icon name="ChevronDown" size="md" />
            </div>
          </HeaderLink>
        </div>
      </div>
    </>
  )
})
