import { useCallback, useState } from 'react'

import { Dropdown, DropdownContainer, Icon, useToggle } from '@nori/ui-kit'

import type { ProductSkuEntity } from '@nori/app-kit'
import type { PropsWithChildren } from 'react'

const MIN_SKUS_COUNT = 2

type Props = PropsWithChildren<{
  sku?: ProductSkuEntity
  skus?: ProductSkuEntity[]
  onChange: (sku: ProductSkuEntity) => void
}>

export function SkusDropdown({ sku, skus, onChange }: Props) {
  const { isOpen, handleClose, handleToggle } = useToggle()

  if (!skus || skus.length < MIN_SKUS_COUNT) return null
  return (
    <DropdownContainer
      onOutsideClick={handleClose}
      hideHelperBlock
      placeSlot={
        <button
          className="relative flex items-center text-xs font-bold text-primary-900"
          onClick={handleToggle}
        >
          <div>{sku?.name ?? '--'}</div>
          <Icon name="ChevronDown" size="md" />
        </button>
      }
      isOpen={isOpen}
    >
      {skus.map((_sku) => (
        <Dropdown.Option
          onClick={() => onChange(_sku)}
          key={_sku.id}
          selected={_sku.id === sku?.id}
        >
          {_sku.name}
        </Dropdown.Option>
      ))}
    </DropdownContainer>
  )
}
