import { mergeMessages } from '@nori/lang-i18n'

import appKitEnCaMessages from '../../../../../../packages/app-kit/i18n/messages/en-ca.json'
import appKitEnUsMessages from '../../../../../../packages/app-kit/i18n/messages/en-us.json'
import appKitEsUsMessages from '../../../../../../packages/app-kit/i18n/messages/es-us.json'
import appKitFrCaMessages from '../../../../../../packages/app-kit/i18n/messages/fr-ca.json'
import uiKitEnCaMessages from '../../../../../../packages/ui-kit/i18n/messages/en-ca.json'
import uiKitEnUsMessages from '../../../../../../packages/ui-kit/i18n/messages/en-us.json'
import uiKitEsUsMessages from '../../../../../../packages/ui-kit/i18n/messages/es-us.json'
import uiKitFrCaMessages from '../../../../../../packages/ui-kit/i18n/messages/fr-ca.json'
import backOfficeEnCaMessages from '../../../../i18n/messages/en-ca.json'
import backOfficeEnUsMessages from '../../../../i18n/messages/en-us.json'
import backOfficeEsUsMessages from '../../../../i18n/messages/es-us.json'
import backOfficeFrCaMessages from '../../../../i18n/messages/fr-ca.json'

import type { LangType } from '@nori/ui-kit'

const defaultMessages = mergeMessages([
  appKitEnUsMessages,
  uiKitEnUsMessages,
  backOfficeEnUsMessages,
])

const messages = {
  'en-US': defaultMessages,
  'es-US': mergeMessages([
    defaultMessages,
    appKitEsUsMessages,
    uiKitEsUsMessages,
    backOfficeEsUsMessages,
  ]),
  'en-CA': mergeMessages([
    defaultMessages,
    appKitEnCaMessages,
    uiKitEnCaMessages,
    backOfficeEnCaMessages,
  ]),
  'fr-CA': mergeMessages([
    defaultMessages,
    appKitFrCaMessages,
    uiKitFrCaMessages,
    backOfficeFrCaMessages,
  ]),
}

export function getMessages(): Record<LangType, IntlMessages> {
  return messages
}
