import { useMemo } from 'react'

import { observer } from '@nori/di'
import { useTranslations } from '@nori/lang-i18n'
import { Icon } from '@nori/ui-kit'

import { useAutosaveContext } from '../../interface/use-autosave-context'

type Props = {
  isAvailable?: boolean
  isCanBeAutoshipped?: boolean
}

export const AutoSaveUnavailableMessage = observer(
  ({ isAvailable, isCanBeAutoshipped }: Props) => {
    const { nextOrderStore } = useAutosaveContext()
    const t = useTranslations('autosaveSettings.nextOrderTable')

    const isShowUnavailableMessage = !isCanBeAutoshipped || !isAvailable

    if (!isShowUnavailableMessage) return null

    const unavailableMessage = () => {
      if (!isCanBeAutoshipped) return t('autosaveIsUnavailable')

      return nextOrderStore.unavailableMessage
    }

    return (
      <div className="mb-5 w-full lg:mb-2">
        <div className="flex items-center">
          <span className="mr-2 text-gray-600">
            <Icon name="Exclamation" size="lg" />
          </span>
          <span className="text-sm font-medium text-gray-600">
            {unavailableMessage()}
          </span>
        </div>
      </div>
    )
  }
)
