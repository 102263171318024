import { useEffect } from 'react'
import { useRouter } from 'next/router'

import { useI18nContext, useProfileContext } from '@nori/app-kit'
import { observer } from '@nori/di'
import { useInitialLoad } from '@nori/ui-kit'
import { useRootApp } from '~/modules/root-app/interface/use-root-app'

import { AuthorizedContent } from './authorized-content'

import type { ReactNode } from 'react'

type Props = {
  children: ReactNode
}

export const AuthGuard = observer(({ children }: Props) => {
  const router = useRouter()
  const currentRoute = router.asPath

  const { i18nAction } = useI18nContext()
  const { authGuardAction, authGuardStore } = useRootApp()
  const { userStore } = useProfileContext()
  const { isUserLoaded } = userStore

  useInitialLoad(i18nAction.handleUpdateLocale)

  useEffect(() => {
    if (!isUserLoaded) return
    authGuardAction.handleAuthRedirect(currentRoute)
  }, [isUserLoaded, currentRoute, authGuardAction])

  if (!authGuardStore.hasAuthorizedContent(currentRoute)) return <>{children}</>

  return <AuthorizedContent>{children}</AuthorizedContent>
})
