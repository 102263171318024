import { GetAgreementVersionErrors } from '../../../core/get-agreement-version.errors'

import type { GetAgreementVersionErrorInstance } from '../../../core/get-agreement-version.errors'

export class GetAgreementVersionErrorMapper {
  static toErrors(status: number, json: any): GetAgreementVersionErrorInstance {
    switch (status) {
      case 400:
        return new GetAgreementVersionErrors.BadRequest(json.error.message)

      case 401:
        return new GetAgreementVersionErrors.Unathorized(json.error.message)

      case 404:
        return new GetAgreementVersionErrors.NotFound(json.errors)

      default:
        return new GetAgreementVersionErrors.UnknownError(json)
    }
  }
}
