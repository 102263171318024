import { HistoryService } from '@nori/app-kit'
import { Action, Inject } from '@nori/di'
import { getBlock } from '@nori/lang-i18n'
import { NotifyAction } from '@nori/notify'
import { isErr } from '@nori/result'
import { createLogger } from '~/infra/create-logger'
import { LoadCartService } from '~/modules/cart/interface/services/create-cart.service'
import { FindOrCreateContactAction } from '~/modules/contacts/interface/action/find-or-create-contact.action'
import { Routes } from '~/modules/routes'

import { PartiesListLoadService } from '../services/parties-list-load.service'
import { PartiesListStore } from '../stores/parties-list.store'

import type { NoriDate } from '@nori/date'
import type { SortDirectionValueObject } from '~/core'
import type { PartyListSortBy } from '~/modules/parties/core/party-list-sort-by'
import type { PartyEntity } from '../../core/entities/party.entity'
import type { PartyTypeValueObject } from '../../core/value-objects/party-type.value-object'
import type { PartiesTabsValueObject } from '../../core/value-objects/tabs.value-object'

const logger = createLogger('parties-list.action')

const I18N_DASHBOARD_PATH = 'homePage.dashboardRightSide.openEvents.list.errors'
const INITIAL_PAGE = 1
@Action()
export class PartiesListAction {
  constructor(
    @Inject() private readonly partiesListStore: PartiesListStore,
    @Inject() private readonly historyService: HistoryService,
    @Inject() private readonly loadCartService: LoadCartService,
    @Inject() private readonly notifyAction: NotifyAction,
    @Inject() private readonly partiesListLoadService: PartiesListLoadService,
    @Inject()
    private readonly findOrCreateContactAction: FindOrCreateContactAction
  ) {}

  handleCreateEvent(): void {
    this.findOrCreateContactAction.handleResetContactForm()
    this.historyService.push(Routes.events.new.path())
  }

  async handleInitialLoad(partyType: PartyTypeValueObject): Promise<void> {
    await this.partiesListLoadService.initialLoad({ partyType })
  }

  async handleLoad(page: number): Promise<void> {
    await this.partiesListLoadService.loadPage(page)
  }

  handleSetSelectedRange(value: string): void {
    this.partiesListStore.setSelectedRange(value)
  }

  handleSetActiveTab(tab: PartiesTabsValueObject): void {
    this.partiesListStore.setActiveTab(tab)
  }

  async handleAddOrder(party: PartyEntity): Promise<void> {
    const result = await this.loadCartService.createCart({
      isLoungeCart: true,
      partyAt: party?.partyAt,
      partyId: party?.id,
      partyName: party?.partyName,
    })
    if (isErr(result)) {
      this.notifyAction.handleAddNotify({
        type: 'danger',
        title: getBlock(I18N_DASHBOARD_PATH)('addOrderError'),
      })
      return
    }
    this.historyService.push(Routes.shop.path())
  }

  async handleSortChange(
    sort: PartyListSortBy,
    order: SortDirectionValueObject
  ): Promise<void> {
    this.partiesListStore.setSortBy(sort)
    this.partiesListStore.setSortOrder(order)

    await this.partiesListLoadService.loadPage()
  }

  async handleDateRangeChange(dateRange: NoriDate[]): Promise<void> {
    this.partiesListStore.setDateRange(dateRange)
    this.partiesListStore.setIsWithAccurateTime(
      this.partiesListStore.selectedRange
    )

    await this.partiesListLoadService.loadPage(INITIAL_PAGE)
  }

  async handleSearchParamChange(searchParam: string): Promise<void> {
    this.partiesListStore.setSearchParam(searchParam)
    await this.partiesListLoadService.loadPage(INITIAL_PAGE)
  }

  async handleLoadMore(): Promise<void> {
    if (!this.partiesListStore.hasMore) return
    if (this.partiesListStore.isLoadingNextPage) return

    this.partiesListStore.setIsLoadingNextPage(true)

    await this.partiesListLoadService.fetchPartyList({
      page: this.partiesListStore.nextPage,
      isAppendItems: true,
    })

    this.partiesListStore.setIsLoadingNextPage(false)
  }
}
