import { createAddressValidator } from '@nori/app-kit'
import { makeAutoObservable } from '@nori/di'

type AddressTypeValueObject = 'current-user' | 'one-time'

export class ShippingAddressModalConfig {
  shippingAddressValidator = createAddressValidator(
    'with-required-address-and-phone'
  )
  addressType: AddressTypeValueObject = 'current-user'
  isLoading = false

  constructor() {
    makeAutoObservable(this)
  }

  changeAddressType(addressType: AddressTypeValueObject): void {
    this.addressType = addressType
  }

  setIsLoading(isLoading: boolean): void {
    this.isLoading = isLoading
  }
}
