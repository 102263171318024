export const buttonColorMap = {
    primary: 'border border-transparent text-white disabled:text-white bg-primary-900 disabled:bg-primary-200 focus-visible:ring-blue-200',
    dark: 'border border-transparent text-white bg-gray-800 max-lg:active:bg-gray-700  lg:hover:bg-gray-700 focus-visible:ring-gray-200',
    gray: 'border border-transparent text-gray-800 bg-gray-200 max-lg:active:bg-gray-300 lg:hover:bg-gray-300 lg:hover:ring-1 lg:hover:ring-gray-100 focus-visible:ring-gray-100',
    white: 'border border-transparent text-gray-800 bg-white max-lg:active:bg-gray-50 lg:hover:bg-gray-50 max-lg:active:text-primary-600 lg:hover:text-primary-600 focus-visible:text-primary-600 focus-visible:bg-gray-50 lg:hover:ring-1 lg:hover:ring-gray-100 focus-visible:ring-gray-100',
    green: 'border border-transparent text-white bg-green-700 max-lg:active:bg-green-800 lg:hover:bg-green-800 focus-visible:bg-green-800 focus-visible:ring-green-200',
    red: 'border border-transparent text-white bg-red-700 max-lg:active:bg-red-800 lg:hover:bg-red-800 focus-visible:bg-red-800 focus-visible:ring-red-200',
    alternative: 'border border-transparent ',
    'alternative-dark': 'border border-transparent ',
    retail: 'border border-transparent text-white disabled:text-white bg-retail-1 disabled:bg-primary-200 focus-visible:ring-blue-200'
};
export const buttonOutlineColorMap = {
    primary: 'text-primary-700 border border-primary-700 max-lg:active:border-primary-800 max-lg:active:bg-primary-800 max-lg:active:text-white  lg:hover:border-primary-800 lg:hover:bg-primary-800 lg:hover:text-white focus-visible:border-primary-800 focus-visible:bg-primary-800 focus-visible:text-white focus-visible:ring-blue-200',
    dark: 'text-gray-800 border border-gray-800 max-lg:active:border-gray-700 max-lg:active:bg-gray-700 max-lg:active:text-white lg:hover:border-gray-700 lg:hover:bg-gray-700 lg:hover:text-white focus-visible:border-gray-700 focus-visible:bg-gray-700 focus-visible:text-white focus-visible:ring-gray-200',
    gray: 'text-gray-800 border border-gray-200 max-lg:active:bg-gray-300 max-lg:active:border-gray-300 lg:hover:bg-gray-300 lg:hover:border-gray-300 focus-visible:bg-gray-300 focus-visible:border-gray-300 focus-visible:ring-gray-100',
    white: 'text-white border border-white max-lg:active:bg-gray-50 max-lg:active:border-gray-100 max-lg:active:text-primary-600 lg:hover:bg-gray-50 lg:hover:border-gray-100 lg:hover:text-primary-600 focus-visible:bg-gray-50 focus-visible:border-gray-50 focus-visible:text-primary-600 focus-visible:ring-gray-100',
    green: 'text-green-700 border border-green-700 max-lg:active:bg-green-800 max-lg:active:border-green-800 max-lg:active:text-white lg:hover:bg-green-800 lg:hover:border-green-800 lg:hover:text-white focus-visible:bg-green-800 focus-visible:border-green-800 focus-visible:text-white focus-visible:ring-green-200',
    red: 'text-red-700 border border-red-700 max-lg:active:bg-red-800 max-lg:active:border-red-800 max-lg:active:text-white lg:hover:bg-red-800 lg:hover:border-red-800 lg:hover:text-white focus-visible:bg-red-800 focus-visible:border-red-800 focus-visible:text-white focus-visible:ring-red-200',
    alternative: 'text-primary-900 border border-primary-900 max-lg:active:bg-gray-100 max-lg:active:text-primary-600 max-lg:active:border-gray-200 lg:hover:bg-gray-100 lg:hover:text-primary-600 lg:hover:border-gray-200 focused:text-primary-600 focused:bg-gray-100 focused:border-gray-200 focused:ring-gray-100',
    'alternative-dark': 'text-gray-400 bg-gray-800 border border-gray-600 max-lg:active:bg-gray-700 max-lg:active:border-gray-600 max-lg:active:text-white  lg:hover:bg-gray-700 lg:hover:border-gray-600 lg:hover:text-white focused:bg-gray-700 focused:border-gray-600 focused:text-white focused:ring-gray-700',
    retail: 'border border-transparent text-white disabled:text-white bg-retail-1 disabled:bg-primary-200 focus-visible:ring-blue-200'
};
export const disabledOutlineColor = 'border text-gray-400 border-gray-200';
export const disabledColor = 'text-white bg-primary-200';
