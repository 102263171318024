import { createAddressValidator } from '@nori/app-kit'
import { Store, Toggle } from '@nori/di'

import {
  createContactValidator,
  createContactWithoutEmailValidator,
} from '../create-contact-validator'

import type { ContactErrorInstance } from '~/modules/contacts/core'
import type {
  ContactValidatorSchemeType,
  ContactWithoutEmailValidatorSchemeType,
} from '../create-contact-validator'

@Store()
export class CreateContactStore {
  isLoading = false
  error?: ContactErrorInstance = undefined
  contactWithoutEmailToggle = new Toggle(false)

  contactValidator:
    | ContactValidatorSchemeType
    | ContactWithoutEmailValidatorSchemeType = createContactValidator()
  addressValidator = createAddressValidator()

  get validationErrors(): { field: string; error: string }[] {
    return [this.contactValidator.errors, this.addressValidator.errors].flatMap(
      (error) =>
        Object.entries(error).map((error) => ({
          field: error[0],
          error: error[1],
        }))
    )
  }

  updateContactValidator(): void {
    const currentContactValues = this.contactValidator.values
    if (this.contactWithoutEmailToggle.isOpen) {
      this.contactValidator =
        createContactWithoutEmailValidator(currentContactValues)
      return
    }

    this.contactValidator = createContactValidator(currentContactValues)
  }

  setError(error?: ContactErrorInstance): void {
    this.error = error
  }

  setIsLoading(value: boolean): void {
    this.isLoading = value
  }
}
