import { type ReactNode, useEffect } from 'react'

import { useProfileContext } from '@nori/app-kit'
import { observer } from '@nori/di'
import { LoaderLeaves, Swap, useInitialLoad } from '@nori/ui-kit'
import { AgreementModal } from '~/modules/agreement'
import { useAppcuesContext } from '~/modules/appcues/interface/appcues.context'
import { BirthdayGuardModal } from '~/modules/birthday-guard'
import { useCartContext } from '~/modules/cart'
import { useRootApp } from '~/modules/root-app/interface/use-root-app'
import { useWishListContext } from '~/modules/wishlist/interface/use-wish-list.context'

type Props = {
  children: ReactNode
}

export const AuthorizedContent = observer(({ children }: Props) => {
  const { rootAppAction, rootAppStore } = useRootApp()
  const { appcuesAction, appcuesStore } = useAppcuesContext()
  const { userStore } = useProfileContext()

  // reflection hack
  useCartContext()
  useWishListContext()

  useInitialLoad(rootAppAction.handleInitialLoad)

  useEffect(() => {
    if (!appcuesStore.isLoaded) return
    if (!userStore.currentUser.dsrLogin) return

    appcuesAction.handleIdentify()
  }, [userStore.currentUser.dsrLogin, appcuesStore.isLoaded])

  return (
    <Swap
      is={rootAppStore.isLoadingDetails}
      isSlot={
        <div className="flex h-screen w-full items-center justify-center">
          <LoaderLeaves />
        </div>
      }
    >
      <AgreementModal />
      <BirthdayGuardModal />
      {children}
    </Swap>
  )
})
