import { observer } from '@nori/di'
import { useTranslations } from '@nori/lang-i18n'
import { Link } from '@nori/ui-kit'
import { useCartContext } from '~/modules/cart'
import { QuickAdd } from '~/modules/cart-contact-party/ui/quick-add/quick-add'
import { Routes } from '~/modules/routes'

export const CartPageHeader = observer(() => {
  const {
    cartStore: { isCustomerCart, isPersonalCart },
  } = useCartContext()
  const t = useTranslations('cart')
  const t2 = useTranslations('shopForMyself')

  return (
    <>
      <div className=" bg-primary-50 p-5  lg:h-28 ">
        <div className="mx-auto my-0 flex h-full max-w-main-content max-lg:flex-col lg:items-center lg:gap-32">
          <div className="flex flex-col items-start no-underline">
            <Link
              iconLeft="ArrowLeft"
              weight="bold"
              data-test-id="clear-all-filters-link"
              size="medium"
              isUnderline={false}
              href={Routes.shop.path()}
            >
              {t('backLink')}
            </Link>
            <p className="mt-2 flex text-2xl font-bold text-primary-900">
              {t('shoppingBag')}{' '}
              {isPersonalCart && (
                <span className="flex items-center gap-4 text-xl font-normal">
                  <span className="ml-4 h-[28px] w-[1px] bg-gray-100" />{' '}
                  <span>{t2('personalOrder')}</span>
                </span>
              )}
            </p>
          </div>
          <div className="flex max-lg:mt-4 max-lg:flex-col lg:flex-row lg:gap-9">
            {isCustomerCart && <QuickAdd />}
          </div>
        </div>
      </div>
    </>
  )
})
