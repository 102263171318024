import { Inject, Service } from '@nori/di'
import { createLogger } from '~/infra/create-logger'

import { FeatureFlagsStore } from '../store/feature-flags.store'

import type { UserEntity } from '@nori/app-kit'

const logger = createLogger('feature-flags.service')

@Service()
export class FeatureFlagsService {
  constructor(
    @Inject() private readonly featureFlagsStore: FeatureFlagsStore
  ) {}

  async handleInitFeatureFlags(
    environment: string,
    user: UserEntity
  ): Promise<void> {
    logger.info('Initilizing BackOffice feature flags')

    const context = {
      kind: 'user',
      key: user.dsrLogin || 'guest',
      firstName: user.firstName,
      lastName: user.lastName,
      email: user.email,
      groups: user.roles.map((role) => role.roleName),
    }

    await this.featureFlagsStore.init(environment, context)
  }
}
