import { CollectionEndpointService } from '@nori/app-kit'
import { Service } from '@nori/di'

import type { CollectionEndpointServiceInterface } from '@nori/app-kit'

@Service({ id: CollectionEndpointService })
class RewriteCollectionEndpointService
  implements CollectionEndpointServiceInterface
{
  getEndpoint(): {
    endpoint: string
    withAuth: boolean
  } {
    const endpoint = '/collections?en-US'
    return {
      endpoint,
      withAuth: true,
    }
  }
}
