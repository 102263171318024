import { useCallback, useState } from 'react';
export function useTooltip() {
    const [isVisible, toggleVisible] = useState(false);
    const handleEnter = useCallback(()=>{
        toggleVisible(true);
    }, []);
    const handleLeave = useCallback(()=>{
        toggleVisible(false);
    }, []);
    const handleTouch = useCallback(()=>{
        toggleVisible((oldValue)=>!oldValue);
    }, []);
    return {
        handleEnter,
        handleLeave,
        handleTouch,
        isVisible
    };
}
