import { hookContextFactory } from '@nori/di'

import { AuthGuardAction } from './actions/auth-guard.action'
import { RootAppAction } from './actions/root-app.action'
import { AuthGuardStore } from './stores/auth-guard.store'
import { RootAppStore } from './stores/root-app.store'

export const { useModuleContext: useRootApp } = hookContextFactory({
  authGuardAction: AuthGuardAction,
  authGuardStore: AuthGuardStore,
  rootAppAction: RootAppAction,
  rootAppStore: RootAppStore,
})
