import { Store } from '@nori/di'
import { FeatureFlags } from '@nori/feature-flags'

import { FEATURE_FLAGS_LIST, type FeatureFlagListValueObject } from '../../core'

import type { FeatureFlagContextEntity } from '@nori/feature-flags'

type FlagName = keyof FeatureFlagListValueObject

@Store()
export class FeatureFlagsStore {
  private readonly featureFlagsList = new FeatureFlags(FEATURE_FLAGS_LIST)

  async init(
    environment: string,
    context: FeatureFlagContextEntity
  ): Promise<void> {
    await this.featureFlagsList.handleInitFeatureFlags(environment, context)
  }

  getFlag<T extends FlagName>(flagName: T): FeatureFlagListValueObject[T] {
    return this.featureFlagsList.getFlag(flagName)
  }

  getFlags(): FeatureFlagListValueObject {
    return this.featureFlagsList.getFlags()
  }

  on(
    flagName: FlagName,
    cb: (
      flagName: FlagName,
      flagValue: FeatureFlagListValueObject[FlagName]
    ) => void
  ): () => void {
    return this.featureFlagsList.on(flagName, cb)
  }
}
