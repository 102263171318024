import { useTranslations } from '@nori/lang-i18n'
import { Icon, Tooltip } from '@nori/ui-kit'

type Props = {
  isPlaced?: boolean
}

export function NextOrderDateTooltip({ isPlaced }: Props) {
  const t = useTranslations('autosaveSettings.autosaveTooltips')
  const title = isPlaced ? t('changePlacedDateTooltip') : t('changeDateTooltip')
  return (
    <Tooltip
      slot={<div className="min-w-36 text-left text-primary-900">{title}</div>}
      place="bottom"
    >
      <span className="text-primary-300">
        <Icon name="InformationCircleFill" />
      </span>
    </Tooltip>
  )
}
