import { Service } from '@nori/di'

import type { SearchContactsInput } from '~/modules/contacts/core'

@Service()
export class GetContactsRequestMapper {
  toRequest({
    searchQuery = '',
    searchFilter,
    order = 'asc',
    page,
    perPage,
    sortBy = 'first_name',
    filters,
    shouldReceivePrintCatalog,
  }: SearchContactsInput): string {
    const query = new URLSearchParams()
    query.append('sort_by', sortBy)
    query.append('order', order)
    if (page) query.append('page', `${page}`)
    if (perPage) query.append('per_page', `${perPage}`)
    if (searchFilter) query.append('search_filter', searchFilter)
    if (searchQuery) query.append('search_param', searchQuery)
    if (shouldReceivePrintCatalog)
      query.append('receive_print_catalog', String(shouldReceivePrintCatalog))

    filters?.forEach((filter) => {
      const value = filter.value

      query.append(filter.filterKey, String(value))
    })

    query.append('_', `${Date.now()}`)
    return query.toString()
  }
}
