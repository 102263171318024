import { Store } from '@nori/di'

import type { CartProductSubscriptionValueObject } from '../../core/cart-product-subscription.value-object'

@Store()
export class ProductSubscriptionStore {
  productSubscriptionLoadingMap: { [key: number | string]: boolean } = {}

  productSubscriptions: {
    [key: number | string]: CartProductSubscriptionValueObject
  } = {}

  setProductSubscriptionUpdateLoading({
    productId,
    isLoading,
  }: {
    productId: number | string
    isLoading: boolean
  }): void {
    if (!isLoading) {
      delete this.productSubscriptionLoadingMap[productId]
      return
    }

    this.productSubscriptionLoadingMap[productId] = isLoading
  }

  updateProductSubscriptions({
    productId,
    subscription,
  }: {
    productId: number | string
    subscription: CartProductSubscriptionValueObject
  }): void {
    this.productSubscriptions[productId] = subscription
  }

  getProductSubscriptions({
    productId,
  }: {
    productId: number | string
  }): CartProductSubscriptionValueObject | undefined {
    return this.productSubscriptions[productId]
  }

  getProductSubscriptionLoading() {
    let isLoading = false
    for (const key in this.productSubscriptionLoadingMap) {
      if (this.productSubscriptionLoadingMap[key]) {
        isLoading = true
        break
      }
    }
    return isLoading
  }
}
