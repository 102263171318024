import { Action, Inject } from '@nori/di'
import { isErr } from '@nori/result'
import { createLogger } from '~/infra/create-logger'
import { CartStore } from '~/modules/cart/interface/store/cart-store/cart.store'

import { CartSubscriptionAdapter } from '../../infra/cart-subscription.adapter'
import { SubscriptionAdapter } from '../../infra/product-subscription.adapter'
import { ProductSubscriptionStore } from '../store/product-subscription.store'

const logger = createLogger('product-subscription.actions')

@Action()
export class BundleSubscriptionAction {
  constructor(
    @Inject()
    private readonly productSubscriptionStore: ProductSubscriptionStore,
    @Inject() private readonly cartSubscriptionAdapter: CartSubscriptionAdapter,
    @Inject()
    private readonly productSubscriptionAdapter: SubscriptionAdapter,
    @Inject() private readonly cartStore: CartStore
  ) {}

  async handleGetBundleSubscriptionOptions({
    bundleCode,
  }: {
    bundleCode: string
  }): Promise<void> {
    if (this.productSubscriptionStore.productSubscriptions[bundleCode]) {
      return
    }

    const result =
      await this.productSubscriptionAdapter.getBundleSubscriptionOptions({
        bundleCode,
      })

    if (isErr(result)) {
      logger.error(result.error.key, result.error)
      return
    }

    this.productSubscriptionStore.updateProductSubscriptions({
      productId: bundleCode,
      subscription: result.data,
    })
  }

  handleSubscriptionToggle({
    bundleId,
    bundleCode,
    oldSubscriptionPlan,
    isToggle,
    isSubscriptionSelected,
    subscriptionToggle,
  }: {
    bundleId: number
    bundleCode: string
    oldSubscriptionPlan?: number
    isToggle: boolean
    isSubscriptionSelected: boolean
    subscriptionToggle: {
      isOpen: boolean
      handleOpen: () => void
      handleClose: () => void
      handleToggle: () => void
    }
  }): void {
    if (isToggle && isSubscriptionSelected) {
      return
    }
    if (!isToggle && !isSubscriptionSelected) {
      return
    }

    const subscriptionData =
      this.productSubscriptionStore.getProductSubscriptions({
        productId: bundleCode,
      })

    this.handleUpdateProductSubscription({
      bundleId,
      bundleCode,
      subscriptionPlan: isToggle
        ? subscriptionData?.defaultSubscription.id
        : undefined,
      oldSubscriptionPlan: oldSubscriptionPlan,
    })
    subscriptionToggle.handleClose()
  }

  async handleUpdateProductSubscription({
    bundleCode,
    bundleId,
    oldSubscriptionPlan,
    subscriptionPlan,
  }: {
    bundleCode: string
    bundleId?: number
    subscriptionPlan?: number
    oldSubscriptionPlan?: number
  }): Promise<void> {
    const cartId = this.cartStore.cart?.id

    if (!cartId || !bundleId) {
      logger.error('Missing data for subscription update')
      return
    }

    this.productSubscriptionStore.setProductSubscriptionUpdateLoading({
      productId: bundleCode,
      isLoading: true,
    })

    await this.updateProductSubscription({
      cartId,
      bundleId,
      oldSubscriptionPlan,
      subscriptionPlan,
    })

    this.productSubscriptionStore.setProductSubscriptionUpdateLoading({
      productId: bundleCode,
      isLoading: false,
    })
  }

  async handleProductSubscriptionOpen({
    bundleCode,
    bundleId,
    oldSubscriptionPlan,
    subscriptionPlan,
    isSubscriptionSelected,
    subscriptionToggle,
  }: {
    bundleCode: string
    bundleId?: number
    subscriptionPlan?: number
    oldSubscriptionPlan?: number
    isSubscriptionSelected: boolean
    subscriptionToggle: {
      isOpen: boolean
      handleOpen: () => void
      handleClose: () => void
      handleToggle: () => void
    }
  }): Promise<void> {
    subscriptionToggle.handleOpen()

    if (!isSubscriptionSelected) {
      return
    }

    const cartId = this.cartStore.cart?.id

    if (!cartId || !bundleId) {
      logger.error('Missing data for subscription update')
      return
    }

    this.productSubscriptionStore.setProductSubscriptionUpdateLoading({
      productId: bundleCode,
      isLoading: true,
    })

    await this.updateProductSubscription({
      cartId,
      bundleId,
      oldSubscriptionPlan,
      subscriptionPlan,
    })

    this.productSubscriptionStore.setProductSubscriptionUpdateLoading({
      productId: bundleCode,
      isLoading: false,
    })
  }

  private async updateProductSubscription({
    bundleId,
    oldSubscriptionPlan,
    subscriptionPlan,
    cartId,
  }: {
    bundleId: number
    subscriptionPlan?: number
    oldSubscriptionPlan?: number
    cartId: number
  }): Promise<void> {
    const result = await this.cartSubscriptionAdapter.updateBundleSubscription({
      cartId,
      bundleId,
      oldSubscriptionPlan,
      subscriptionPlan,
    })

    if (isErr(result)) {
      logger.error(result.error.key, result.error)
      return
    }

    this.cartStore.cartData.setData(result.data)
  }
}
