import { errorFactory } from '@nori/errors'

import type { KeyOfErrors } from '@nori/errors'

export const { GetAgreementVersionErrors } = errorFactory(
  'GetAgreementVersionErrors',
  {
    BadRequest: 'Bad Request',
    NotFound: 'Not Found',
    Unathorized: 'Unathorized',
    UnexpectedError: 'Unexpected Error',
    UnknownError: 'Unknown Error',
  }
)

export type GetAgreementVersionErrorInstance = InstanceType<
  KeyOfErrors<typeof GetAgreementVersionErrors>
>
