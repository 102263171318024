import { getFullPath } from './get-full-path';
export function removeFromUrl({ key , currentSearch , id  }) {
    const values = currentSearch.getAll(key);
    const filtered = values.filter((val)=>id !== val);
    currentSearch.delete(key);
    filtered == null ? void 0 : filtered.forEach((value)=>{
        currentSearch.append(key, value);
    });
    window.history.replaceState(window.history.state, '', getFullPath(currentSearch));
}
