import { getStyleItemName, ProductImage } from '@nori/app-kit'
import { AddProductSubscription } from '~/modules/product-subscription/ui/add-product-subscription'
import { WishButton } from '~/modules/wishlist/ui/wish-button'

import { AddToCartBlock } from './add-to-cart-block'
import { SelectSku } from './select-sku'

import type { CommonStyleEntity, ProductSkuEntity } from '@nori/app-kit'
import type { SetStateAction } from 'react'

type Props = {
  item: CommonStyleEntity
  pictureUrl?: string
  skus?: ProductSkuEntity[]
  callout?: string
  available?: boolean
  handleAddToCart: (quantity: number) => void
  selectedSku?: ProductSkuEntity
  setSelectedSku: (value: SetStateAction<ProductSkuEntity | undefined>) => void
  selectedSubscriptionId: number
  setSelectedSubscriptionId: (id: number) => void
  subscriptionToggle: {
    isOpen: boolean
    handleOpen: () => void
    handleClose: () => void
    handleToggle: () => void
  }
}

export function StyleDesktopCell({
  item,
  pictureUrl,
  skus,
  callout,
  available,
  handleAddToCart,
  selectedSku,
  setSelectedSku,
  selectedSubscriptionId,
  setSelectedSubscriptionId,
  subscriptionToggle,
}: Props) {
  return (
    <>
      <div className="flex w-full gap-4 max-lg:flex-col">
        <div className="flex w-full items-center justify-between">
          <div className="flex ">
            <div className="mr-5 flex min-w-20 flex-col items-center">
              <ProductImage
                src={pictureUrl}
                alt={item.product.name}
                width={80}
                height={80}
                styleName="h-full"
              />
            </div>
            <div className="flex flex-col justify-start text-sm font-medium text-gray-500">
              <div data-test-id="item-name" className="text-base font-bold ">
                {getStyleItemName({
                  productName: item.product.name,
                  styleName: item.name,
                })}
              </div>
              <div className="mt-2">
                <SelectSku
                  skuVariation={item.product.skuVariation}
                  skus={skus}
                  selectedSku={selectedSku}
                  onSelect={(sku) => {
                    setSelectedSku(sku)
                  }}
                />
                {selectedSku?.code && (
                  <div className="mt-0.5 text-xs font-medium text-gray-600">
                    {selectedSku.code}
                  </div>
                )}
                {callout && <div>{callout}</div>}
              </div>
            </div>
          </div>
        </div>
        <div className="mt-5 flex w-full items-center max-lg:flex-wrap max-lg:items-start max-lg:justify-end max-lg:gap-y-5 lg:mt-0 lg:justify-end lg:justify-around">
          <AddToCartBlock
            id={selectedSku?.id.toString()}
            isAvailable={available}
            isDisabled={!selectedSku}
            salePrice={
              selectedSku?.currentSalePrice || item.maxPriceSku.currentSalePrice
            }
            retailPrice={
              selectedSku?.currentRetailPrice ||
              item.maxPriceSku.currentRetailPrice
            }
            consultantPrice={
              selectedSku?.currentStylistPrice ||
              item.maxPriceSku.currentStylistPrice
            }
            onAddToCart={handleAddToCart}
          />
          <div>
            <WishButton skuId={selectedSku?.id} />
          </div>
        </div>
      </div>
      {!!item.canBeAutoShipped && (
        <div className="mt-6">
          <AddProductSubscription
            isSubscriptionSelected={subscriptionToggle.isOpen}
            onSubscriptionClose={subscriptionToggle.handleClose}
            onSubscriptionOpen={subscriptionToggle.handleOpen}
            onSubscriptionChange={(id: number) => setSelectedSubscriptionId(id)}
            selectedSubscriptionId={selectedSubscriptionId}
            item={item}
          />
        </div>
      )}
    </>
  )
}
