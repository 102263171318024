import type { EventLocationValueObject } from '@nori/app-kit'
import type { IconName } from '@nori/ui-kit'

export const partyIconMap: Record<
  Partial<EventLocationValueObject>,
  IconName
> = {
  online: 'DesktopComputer',
  different_address: 'WalkingSolid',
  same_as_hostess_address: 'WalkingSolid',
  qualified: 'CheckCircle',
}
