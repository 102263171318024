import { HistoryService } from '@nori/app-kit'
import { Action, Inject } from '@nori/di'
import { getBlock } from '@nori/lang-i18n'
import { NotifyAction } from '@nori/notify'
import { isErr } from '@nori/result'
import { createLogger } from '~/infra/create-logger'
import { DropCartService } from '~/modules/cart/interface/services/drop-cart.service'
import { GtmAction } from '~/modules/google-tag-manager/action/gtm.action'
import { RemoveFromCartService } from '~/modules/product-cart/interface/service/remove-from-cart.service'
import { ProductCartStore } from '~/modules/product-cart/interface/store/product-cart.store'
import { Routes } from '~/modules/routes'

import { CartAdapter } from '../../infra/cart.adapter'
import { LoadCartService } from '../services/create-cart.service'
import { CartStore } from '../store/cart-store/cart.store'

import type { CartBundleEntity, CartItemEntity } from '@nori/app-kit'
import type { ApplyHostessDiscountParams } from './cart-action.types'

const logger = createLogger('cart.actions')

@Action()
export class CartAction {
  constructor(
    @Inject() private readonly cartStore: CartStore,
    @Inject() private readonly cartAdapter: CartAdapter,
    @Inject() private readonly historyService: HistoryService,
    @Inject() private readonly removeFromCartService: RemoveFromCartService,
    @Inject() private readonly loadCartService: LoadCartService,
    @Inject() private readonly notifyAction: NotifyAction,
    @Inject() private readonly productCartStore: ProductCartStore,
    @Inject() private readonly dropCartService: DropCartService,
    @Inject() private readonly gtmAction: GtmAction
  ) {}

  handleNavigateToCheckout(): void {
    this.historyService.push(Routes.checkout.path())
  }

  handleViewCart(): void {
    this.historyService.push(Routes.cart.path())
  }

  handleSaveAsContactAddress(value: boolean): void {
    this.cartStore.setSaveAsContactAddress(value)
  }

  async handleInitLoad(): Promise<void> {
    if (this.cartStore.isCartExists) {
      return
    }

    this.cartStore.cartData.setIsLoading(true)
    this.cartStore.cartData.setError(undefined)
    this.cartStore.cartData.setData(undefined)

    await this.loadCartService.loadCartById({})

    if (this.cartStore.isCartImported) {
      await this.loadCartService.createCart()
    }

    this.cartStore.cartData.setIsLoading(false)
  }

  async handleCancelOrder(): Promise<void> {
    this.cartStore.setIsCancellingOrder(true)

    await this.dropCartService.handleDropCart()

    this.cartStore.setIsCancellingOrder(false)
  }

  async handleRemoveFromCart(product: CartItemEntity): Promise<void> {
    if (product.hostessDiscount) {
      await this.handleApplyHostessDiscount({
        productId: product.id,
        isDiscountApply: false,
      })
    }
    this.gtmAction.handleTrackRemoveItemFromCart(product)

    return this.removeFromCartService.handleRemoveFromCart(product)
  }

  async handleRemoveBundleFromCart(bundle: CartBundleEntity): Promise<void> {
    if (bundle.hostessDiscount) {
      await this.handleApplyHostessDiscount({
        productId: bundle.id,
        isDiscountApply: false,
      })
    }
    this.gtmAction.handleTrackRemoveBundleFromCart(bundle)

    await this.removeFromCartService.handleRemoveBundleFromCart(bundle)
  }

  async handleApplyHostessDiscount({
    productId,
    isDiscountApply,
  }: ApplyHostessDiscountParams): Promise<void> {
    this.productCartStore.setIsApplyingHostessDiscount(true)

    await this.applyHostessDiscount({ productId, isDiscountApply })

    this.productCartStore.setIsApplyingHostessDiscount(false)
  }

  private async applyHostessDiscount({
    productId,
    isDiscountApply,
  }: ApplyHostessDiscountParams): Promise<void> {
    const { cart } = this.cartStore
    if (!cart?.id) return

    const result = await this.cartAdapter.applyHostessDiscount({
      cartId: cart.id,
      productId,
      isDiscountApply,
    })

    if (isErr(result)) {
      const { error, message } = result.error
      logger.error(error.key, error)
      const defaultMessage = getBlock('cart.errors')(
        'hostessDiscountApplicationError'
      )
      this.notifyAction.handleAddNotify({
        type: 'danger',
        title: message || defaultMessage,
      })
      return
    }

    await this.loadCartService.loadCartById({})
  }
}
