import { useCallback, useState } from 'react'

import { type CommonBundleEntity, ProductImage } from '@nori/app-kit'
import { useTranslations } from '@nori/lang-i18n'
import { useNotifyContext } from '@nori/notify'
import { Input, useToggle } from '@nori/ui-kit'
import { useCartContext } from '~/modules/cart'
import { AddProductSubscription } from '~/modules/product-subscription/ui/add-product-subscription'
import { AddToCartButton } from '~/modules/products'
import { RetailPrice } from '~/modules/shop-for-myself/ui/product-list/cells/add-to-cart-block/retail-price'

import type { MoneyValueObject } from '@nori/app-kit'

type Props = {
  item: CommonBundleEntity
  picture: string
  name?: string
  maxRetailPrice?: MoneyValueObject
  maxPrice?: MoneyValueObject
  onAddToCart: (quantity: number) => void
  isBundleCustomizable?: boolean
  selectedSubscriptionId: number
  setSelectedSubscriptionId: (id: number) => void
  subscriptionToggle: {
    isOpen: boolean
    handleOpen: () => void
    handleClose: () => void
    handleToggle: () => void
  }
}

const MIN_PRODUCT_QUANTITY = 1

export function BundleMobileCell({
  item,
  picture,
  name,
  maxRetailPrice,
  maxPrice,
  onAddToCart,
  isBundleCustomizable,
  selectedSubscriptionId,
  setSelectedSubscriptionId,
  subscriptionToggle,
}: Props) {
  const itemToggle = useToggle()
  const { cartStore } = useCartContext()
  const { isCustomerCart } = cartStore
  const inputContainerClass = 'mr-5 max-lg:m-0  lg:mr-0 lg:w-15 max-lg:w-1/3'
  const t = useTranslations('shopForMyself.productList')
  const t2 = useTranslations('cart.productList')
  const { notifyAction } = useNotifyContext()
  const [cartQuantity, setCartQuantity] = useState(`${MIN_PRODUCT_QUANTITY}`)

  const handleAddToCart = useCallback(() => {
    const num = Number(cartQuantity)
    if (isNaN(num) || num < MIN_PRODUCT_QUANTITY) {
      notifyAction.handleAddNotify({
        type: 'danger',
        title: t('invalidQuantity'),
      })
      return
    }
    onAddToCart(num)
  }, [cartQuantity, t, onAddToCart, notifyAction])

  return (
    <>
      <div
        className="flex w-full flex-wrap items-center justify-between gap-6"
        onClick={itemToggle.handleOpen}
      >
        <div className="flex w-full justify-between">
          <div className="flex">
            <div className="mr-5 flex min-w-20 flex-col items-center">
              <ProductImage
                src={picture}
                alt="Product image not found!"
                width={80}
                height={80}
                styleName="h-full"
              />
            </div>
            <div className="flex flex-col justify-start text-sm font-medium">
              {item.callout && (
                <div className="text-xs font-bold text-black">
                  {item.callout}
                </div>
              )}
              {name && (
                <div className="text-base font-bold text-gray-700">{name}</div>
              )}
            </div>
          </div>
          <div className="flex min-w-[70px] flex-col items-start gap-2.5">
            <RetailPrice
              salePrice={maxPrice}
              retailPrice={maxRetailPrice}
              isCustomerCart={isCustomerCart}
            />
          </div>
        </div>
        {itemToggle.isOpen && (
          <div className="flex w-full flex-col gap-7">
            <div className="flex-wrapitems-start flex w-full gap-y-5">
              <div className={inputContainerClass}>
                <div className="w-[88px]">
                  <Input
                    disabled={true}
                    size="small"
                    type="integer"
                    min={MIN_PRODUCT_QUANTITY}
                    value={cartQuantity.toString()}
                    onChange={setCartQuantity}
                    hideHelperBlock
                    label={t2('quantityFull')}
                    data-test-id={'quantity-input'}
                  />
                </div>
              </div>
            </div>
            {!!item.canBeAutoShipped && itemToggle.isOpen && (
              <div className="mb-4">
                <AddProductSubscription
                  isSubscriptionSelected={subscriptionToggle.isOpen}
                  onSubscriptionClose={subscriptionToggle.handleClose}
                  onSubscriptionOpen={subscriptionToggle.handleOpen}
                  onSubscriptionChange={(id: number) =>
                    setSelectedSubscriptionId(id)
                  }
                  selectedSubscriptionId={selectedSubscriptionId}
                  item={item}
                />
              </div>
            )}
            <AddToCartButton
              id={item.code}
              isOutOfStock={!item.available}
              onClick={handleAddToCart}
            />
          </div>
        )}
      </div>
    </>
  )
}
