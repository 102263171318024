import { useRouter } from 'next/router'

export function BackButton() {
  const router = useRouter()

  return (
    <div
      className="cursor-pointer text-base uppercase underline"
      onClick={() => router.back()}
    >
      {'<'} Back to catalog
    </div>
  )
}
