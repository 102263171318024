import { getMoneyWithIsoCurrency } from '@nori/app-kit'
import { format } from '@nori/date'
import { observer } from '@nori/di'
import { useTranslations } from '@nori/lang-i18n'
import { Link } from '@nori/ui-kit'
import { useContactContext } from '~/modules/contacts/interface/use-contact-context'

import { Separator } from './separator'
import { SummaryItem } from './summary-item'

export const ReferralSummary = observer(() => {
  const t = useTranslations('contactDetailsPage.norwexPerks')

  const { contactDetailsStore } = useContactContext()

  if (!contactDetailsStore.rewards.data) {
    return null
  }

  if (!contactDetailsStore.contact?.isPreferredDsr) {
    return null
  }

  const {
    startAt,
    endAt,
    referralOrdersCount,
    totalRetailSalesCents,
    totalReferralCreditsEarned,
    currency,
  } = contactDetailsStore.rewards.data.referralEarningPeriod

  return (
    <div className="bg-primary-50 p-5 lg:p-10">
      <div className="text-lg font-bold text-black">
        {t('referralSummary.referral')}
      </div>
      <div className="mt-4 items-center justify-between bg-white p-4 lg:flex">
        <div>
          <div className="text-lg font-bold text-primary-900">
            {t('referralSummary.referralEarningPeriod')}
          </div>
          <div className="gap-2 text-sm text-gray-500 lg:flex">
            {startAt && (
              <div>
                {t('referralSummary.started')}: {format(startAt, 'MM/dd/yyyy')}
              </div>
            )}
            {endAt && (
              <div>
                {t('referralSummary.ends')}: {format(endAt, 'MM/dd/yyyy')}
              </div>
            )}
          </div>
          {!!contactDetailsStore.personaLink && (
            <div className="hidden lg:block">
              <Link
                target="_blank"
                href={contactDetailsStore.personaLink}
                weight="bold"
                size="large"
              >
                {t('referralSummary.shoppingLink')}
              </Link>
            </div>
          )}
          {!!contactDetailsStore.personaLink && (
            <div className="lg:hidden">
              <Link
                target="_blank"
                href={contactDetailsStore.personaLink}
                weight="bold"
                size="medium"
              >
                {t('referralSummary.shoppingLink')}
              </Link>
            </div>
          )}
        </div>
        <div className="mt-5 h-full items-center gap-4 text-sm text-black lg:mt-0 lg:flex">
          <SummaryItem
            label={t('summaryItem.totalOrders')}
            value={String(referralOrdersCount)}
          />
          <Separator />
          <SummaryItem
            label={t('summaryItem.referralSales')}
            value={getMoneyWithIsoCurrency({
              cents: totalRetailSalesCents,
              currency,
            })}
          />
          <Separator />
          <SummaryItem
            label={t('summaryItem.earnedCredit')}
            value={getMoneyWithIsoCurrency({
              cents: totalReferralCreditsEarned,
              currency,
            })}
          />
        </div>
      </div>
    </div>
  )
})
