import {
  createAddressValidator,
  createCreditCardHolderValidator,
  HOSTED_FIELDS_VALIDATION_VALUE,
  isCreditCardPaymentMethod,
  isPaypalPaymentMethod,
} from '@nori/app-kit'
import { DataState, Store } from '@nori/di'

import type {
  CreditCardHolderValidatorSchemeType,
  CreditCardPaymentMethodEntity,
  HostedFields,
  HostedFieldsValidationValueObject,
  PaymentMethodEntity,
  PaymentMethodValueObject,
  ValidatorAddressWithUserNamesAndPhoneType,
} from '@nori/app-kit'
import type { DomainError } from '@nori/errors'
import type {
  CreditCardModeValueObject,
  CreditCardPriorityValueObject,
  PayPalTokenValueObject,
  SecondBillingAddressVariantValueObject,
} from '../../core'
import type { BillingFormViewValueObject } from '../../core/billing-form-view.value-object'

const DEFAULT_PAYMENT_METHOD = 'card'

const DEFAULT_CREDIT_CARD_MODE = {
  main: 'saved',
  second: undefined,
} as const

const DEFAULT_SECOND_BILLING_ADDRESS_VARIANT = 'shipping'

const DEFAULT_BILLING_FORM_VIEW = 'personalOrder'

@Store()
export class BillingFormStore {
  view: BillingFormViewValueObject = DEFAULT_BILLING_FORM_VIEW

  payPalToken = new DataState<PayPalTokenValueObject, DomainError>()

  creditCardModeMap: Record<
    CreditCardPriorityValueObject,
    CreditCardModeValueObject | undefined
  > = { ...DEFAULT_CREDIT_CARD_MODE }

  selectedCardMap: Record<
    CreditCardPriorityValueObject,
    DataState<CreditCardPaymentMethodEntity, DomainError>
  > = {
    main: new DataState({ isLoading: false }),
    second: new DataState({ isLoading: false }),
  }

  hostedFieldsMap: Record<
    CreditCardPriorityValueObject,
    DataState<HostedFields, DomainError>
  > = {
    main: new DataState({ isLoading: false }),
    second: new DataState({ isLoading: false }),
  }

  hostedFieldsValidationMap: Record<
    CreditCardPriorityValueObject,
    HostedFieldsValidationValueObject | undefined
  > = {
    main: { ...HOSTED_FIELDS_VALIDATION_VALUE },
    second: { ...HOSTED_FIELDS_VALIDATION_VALUE },
  }

  cardholderValidatorMap: Record<
    CreditCardPriorityValueObject,
    CreditCardHolderValidatorSchemeType
  > = {
    main: createCreditCardHolderValidator(),
    second: createCreditCardHolderValidator(),
  }

  billingAddressValidatorMap: Record<
    CreditCardPriorityValueObject,
    ValidatorAddressWithUserNamesAndPhoneType
  > = {
    main: createAddressValidator('with-user-and-phone'),
    second: createAddressValidator('with-user-and-phone'),
  }

  selectedPaymentMethod: PaymentMethodValueObject = DEFAULT_PAYMENT_METHOD

  isBillingAddressSameAsShipping = true

  secondBillingAddressVariant: SecondBillingAddressVariantValueObject =
    DEFAULT_SECOND_BILLING_ADDRESS_VARIANT

  canSaveToProfile = false

  private _saveToProfileMap: Record<CreditCardPriorityValueObject, boolean> = {
    main: false,
    second: false,
  }

  get saveToProfileMap(): Record<CreditCardPriorityValueObject, boolean> {
    if (!this.canSaveToProfile)
      return {
        main: false,
        second: false,
      }

    return this._saveToProfileMap
  }

  get isSplitPayment(): boolean {
    return !!this.creditCardModeMap.second
  }

  get payPalPaymentOption(): { nonce: string } | { token: string } | undefined {
    const payPalData = this.payPalToken.data
    if (!payPalData) return
    if (payPalData.isSaved) return { token: payPalData?.token }
    return { nonce: payPalData?.token }
  }

  handleDefaultPaymentMethod(paymentMethod?: PaymentMethodEntity): void {
    if (!paymentMethod) return

    if (isCreditCardPaymentMethod(paymentMethod)) {
      this.selectedPaymentMethod = 'card'
      this.creditCardModeMap.main = 'saved'
      this.selectedCardMap.main.setData(paymentMethod)
      return
    }

    if (isPaypalPaymentMethod(paymentMethod)) {
      this.selectedPaymentMethod = 'paypal'
    }
  }

  cleanUpCards(): void {
    this.cardholderValidatorMap.main.reset()
    this.cardholderValidatorMap.second.reset()
  }

  setSaveToProfile(card: CreditCardPriorityValueObject, value: boolean): void {
    this._saveToProfileMap[card] = value
  }

  setSelectedPaymentMethod(paymentMethod: PaymentMethodValueObject): void {
    this.selectedPaymentMethod = paymentMethod
  }

  resetSelectedPaymentMethod(): void {
    this.selectedPaymentMethod = DEFAULT_PAYMENT_METHOD
    this.isBillingAddressSameAsShipping = true
  }

  resetBillingAddresses(): void {
    this.billingAddressValidatorMap.main.reset()
    this.billingAddressValidatorMap.second.reset()
  }

  resetCardHolders(): void {
    this.cardholderValidatorMap.main.reset()
    this.cardholderValidatorMap.second.reset()
  }

  setCreditCardMode(
    card: CreditCardPriorityValueObject,
    value?: CreditCardModeValueObject
  ): void {
    this.creditCardModeMap[card] = value
  }

  setCanSaveToProfile(value: boolean): void {
    this.canSaveToProfile = value
  }

  setIsBillingAddressSameAsShipping(value: boolean): void {
    this.isBillingAddressSameAsShipping = value
  }

  setSecondBillingAddressVariant(
    value: SecondBillingAddressVariantValueObject
  ): void {
    this.secondBillingAddressVariant = value
  }

  setHostedFieldsValidation(
    card: CreditCardPriorityValueObject,
    value: HostedFieldsValidationValueObject
  ): void {
    this.hostedFieldsValidationMap[card] = value
  }

  setView(value: BillingFormViewValueObject): void {
    this.view = value
  }
}
