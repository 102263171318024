import { getLink } from '@nori/app-kit'
import { format } from '@nori/date'
import { useTranslations } from '@nori/lang-i18n'
import { Link, Swap, useClipboardCopyAction } from '@nori/ui-kit'
import { Routes } from '~/modules/routes'

import type {
  ContactEventEntity,
  ContactListItemEntity,
} from '~/modules/contacts/core'

type Props = {
  contact: ContactListItemEntity
  event: ContactEventEntity
}

export function ContactEvent({ contact, event }: Props) {
  const t = useTranslations('contacts.list.table.extended.event')
  const { copyTitle, handler } = useClipboardCopyAction()
  const title = event.isCurrentEvent ? t('currentEvent') : t('upcomingEvent')

  return (
    <div className="w-full cursor-pointer bg-white p-4 text-primary-900 shadow-xs lg:w-[245px]">
      <div className="flex justify-between font-bold">
        <div className="text-sm">{title}</div>
        <Swap
          is={event.isCurrentEvent}
          isSlot={
            <Link href={Routes.events.view.path(event.id)} weight="bold">
              {t('viewEvent')}
            </Link>
          }
        >
          <Link href={Routes.events.edit.path(event.id)} weight="bold">
            {t('edit')}
          </Link>
        </Swap>
      </div>
      <div className="mt-2 flex justify-between text-xs">
        <div
          data-test-id={'contact-expanded-view-last-order-button text-gray-500'}
        >
          {format(event.openOn, 'MMM dd, yyyy')}
        </div>
        {event.isCurrentEvent && (
          <Link
            weight="bold"
            isUnderline={false}
            iconLeft="PaperClip"
            onClick={() =>
              handler(
                getLink({ type: 'bo', personalLink: contact.personalLink }).url
              )
            }
          >
            {copyTitle}
          </Link>
        )}
      </div>
    </div>
  )
}
