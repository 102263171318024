import { useEffect } from 'react'

import { observer } from '@nori/di'

import { useGhostLoginContext } from '../../interface/context/user-ghost-login.context'

export const GhostLogin = observer(() => {
  const { ghostLoginAction } = useGhostLoginContext()

  useEffect(() => {
    ghostLoginAction.handleGhostLogin()
  }, [ghostLoginAction])
  return null
})
