import type { LangType } from '@nori/ui-kit'
import type { UsefulLinksSectionTitleValueObject } from '../../core/useful-links-section-title.value-object'

export type UsefulLinksData = Record<
  UsefulLinksSectionTitleValueObject,
  { [key: string]: Record<LangType, string> }
>

export const usefulLinksData = {
  trainingInTheKnow: {
    consultantTraining: {
      'en-US': 'https://training.norwex.com/products/new-consultant-training',
      'es-US':
        'https://traininges.norwex.com/products/entrenamiento-para-la-nueva-consultora',
      'en-CA': 'https://training.norwex.com/products/new-consultant-training',
      'fr-CA':
        'https://trainingfr.norwex.com/products/nouvelles-conseilleres-ers',
    },
    backOfficeTraining: {
      'en-US': 'https://theresource.norwex.com/nori-hub/',
      'es-US': 'https://theresource.norwex.com/es_us/nori-hub/',
      'en-CA': 'https://theresource.norwex.com/en_ca/nori-hub/',
      'fr-CA': 'https://theresource.norwex.com/fr_ca/nori-hub/',
    },
    eventStyles: {
      'en-US': 'https://training.norwex.com/products/party-styles-training',
      'es-US':
        'https://traininges.norwex.com/products/entrenamiento-de-estilos-de-demostracion',
      'en-CA': 'https://training.norwex.com/products/party-styles-training',
      'fr-CA': 'https://trainingfr.norwex.com/products/presentations',
    },
    bookingTips: {
      'en-US': 'https://theresource.norwex.com/bookings/',
      'es-US':
        'https://traininges.norwex.com/products/entrenamiento-de-reservaciones',
      'en-CA': 'https://theresource.norwex.com/bookings/',
      'fr-CA': 'https://trainingfr.norwex.com/products/reservations',
    },
    productEducation: {
      'en-US': 'https://training.norwex.com/products/product-training',
      'es-US':
        'https://traininges.norwex.com/products/entrenamiento-de-estilos-de-demostracion',
      'en-CA': 'https://training.norwex.com/products/product-training',
      'fr-CA': 'https://trainingfr.norwex.com/products/produits',
    },
    teamBuilding: {
      'en-US': 'https://training.norwex.com/products/team-building-training',
      'es-US':
        'https://traininges.norwex.com/products/entrenamiento-desarrollo-de-equipo',
      'en-CA': 'https://training.norwex.com/products/team-building-training',
      'fr-CA': 'https://trainingfr.norwex.com/products/developpement-d-equipe',
    },
    onboardingChecklist: {
      'en-US':
        'https://training.norwex.com/checklist-to-onboard-new-consultants',
      'es-US':
        'https://traininges.norwex.com/products/entrenamiento-para-un-buen-comienzo',
      'en-CA':
        'https://training.norwex.com/checklist-to-onboard-new-consultants',
      'fr-CA': 'https://trainingfr.norwex.com/products/accompagnement',
    },
    socialDigital: {
      'en-US':
        'https://training.norwex.com/products/social-media-digital-training',
      'es-US':
        'https://traininges.norwex.com/products/redes-sociales-y-entrenamiento-digital',
      'en-CA':
        'https://training.norwex.com/products/social-media-digital-training',
      'fr-CA':
        'https://trainingfr.norwex.com/products/reseaux-sociaux-et-numerique',
    },
    leadershipDevelopment: {
      'en-US': 'https://training.norwex.com/products/leadership-training',
      'es-US':
        'https://traininges.norwex.com/products/entrenamiento-de-liderazgo',
      'en-CA': 'https://training.norwex.com/products/leadership-training',
      'fr-CA': 'https://trainingfr.norwex.com/products/leadership',
    },
  },
  promotions: {
    allCurrentPromotions: {
      'en-US': 'https://theresource.norwex.com/specials-new/',
      'es-US': 'https://theresource.norwex.com/es_us/specials-new/ ',
      'en-CA': 'https://theresource.norwex.com/en_ca/specials-new/',
      'fr-CA': 'https://theresource.norwex.com/fr_ca/specials-new/ ',
    },
  },
  productInfo: {
    currentCatalog: {
      'en-US': 'https://online.fliphtml5.com/ksbyd/akqm/#p=1',
      'es-US': 'https://online.fliphtml5.com/ksbyd/fmju/#p=1',
      'en-CA': 'https://online.fliphtml5.com/ksbyd/eiov/#p=1',
      'fr-CA': 'https://online.fliphtml5.com/ksbyd/csuy/#p=1',
    },
    productResources: {
      'en-US': 'https://theresource.norwex.com/product-resources/',
      'es-US': 'https://theresource.norwex.com/es_us/product-resources/',
      'en-CA': 'https://theresource.norwex.com/en_ca/product-resources/',
      'fr-CA': 'https://theresource.norwex.com/fr_ca/product-resources/',
    },
    productInformationSheets: {
      'en-US':
        'https://theresource.norwex.com/product-resources/?open=product_information_sheets',
      'es-US':
        'https://theresource.norwex.com/es_us/product-resources/?open=product_information_sheets',
      'en-CA':
        'https://theresource.norwex.com/en_ca/product-resources/?open=product_information_sheets',
      'fr-CA':
        'https://theresource.norwex.com/fr_ca/product-resources/?open=product_information_sheets',
    },
  },
  printCenter: {
    norwexVistaPrint: {
      'en-US': 'https://norwex.go.customprintcenter.com/LandingPage',
      'es-US': 'https://norwex.go.customprintcenter.com/LandingPage',
      'en-CA': 'https://norwex.go.customprintcenter.com/LandingPage',
      'fr-CA': 'https://norwex.go.customprintcenter.com/LandingPage',
    },
    norwexNowMerch: {
      'en-US': 'http://www.awardsworldwide.com/norwexnow/',
      'es-US': 'http://www.awardsworldwide.com/norwexnow/',
      'en-CA': 'http://www.awardsworldwide.com/norwexnow/',
      'fr-CA': 'http://www.awardsworldwide.com/norwexnow/',
    },
  },
  celebrate: {
    dreamBuilders: {
      'en-US': 'https://theresource.norwex.com/dream-builders/',
      'es-US': 'https://theresource.norwex.com/es_us/dream-builders/',
      'en-CA': 'https://theresource.norwex.com/en_ca/dream-builders/',
      'fr-CA': 'https://theresource.norwex.com/fr_ca/dream-builders/',
    },
    conferenceRewards: {
      'en-US': 'https://theresource.norwex.com/conference-recognition/',
      'es-US': 'https://theresource.norwex.com/es_us/conference-recognition/',
      'en-CA': 'https://theresource.norwex.com/en_ca/conference-recognition/',
      'fr-CA': 'https://theresource.norwex.com/fr_ca/conference-recognition/',
    },
    norwexTopLeaders: {
      'en-US': 'https://theresource.norwex.com/top-norwex-leaders/',
      'es-US': 'https://theresource.norwex.com/es_us/top-norwex-leaders/',
      'en-CA': 'https://theresource.norwex.com/en_ca/top-norwex-leaders/',
      'fr-CA': 'https://theresource.norwex.com/fr_ca/top-norwex-leaders/',
    },
  },
} satisfies UsefulLinksData
