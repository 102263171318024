import { Action, Inject } from '@nori/di'
import { isErr } from '@nori/result'
import { createLogger } from '~/infra/create-logger'

import { PartiesListAdapter } from '../../infra/parties-list.adapter'
import { ContactPartiesListStore } from '../stores/contact-party.store'

const logger = createLogger('contact-parties-list-load.action')

const MINIMUM_SEARCH_QUERY_LENGTH = 3

@Action()
export class ContactPartiesListLoadAction {
  constructor(
    @Inject() private readonly contactPartiesListStore: ContactPartiesListStore,
    @Inject() private readonly partiesListAdapter: PartiesListAdapter
  ) {}

  handleDropSearch(): void {
    this.contactPartiesListStore.contactPartyList.setError(undefined)
    this.contactPartiesListStore.contactPartyList.setData(undefined)
    this.contactPartiesListStore.contactPartyList.setData(undefined)
    this.contactPartiesListStore.setSearchQuery('')
  }

  async handleSetSearchQuery(searchQuery: string): Promise<void> {
    this.contactPartiesListStore.setSearchQuery(searchQuery)
    await this.handleFetchPartyListByContactName()
  }

  async handleFetchPartyListByContactName(): Promise<void> {
    if (
      this.contactPartiesListStore.searchQuery.length <
      MINIMUM_SEARCH_QUERY_LENGTH
    ) {
      return
    }

    this.contactPartiesListStore.contactPartyList.setIsLoading(true)

    await this.loadPartiesByName()

    this.contactPartiesListStore.contactPartyList.setIsLoading(false)
  }

  private async loadPartiesByName(): Promise<void> {
    this.contactPartiesListStore.contactPartyList.setError(undefined)

    const result = await this.partiesListAdapter.getPartyListByContact({
      searchParam: this.contactPartiesListStore.searchQuery,
    })

    if (isErr(result)) {
      logger.error(result.error.key, result.error)
      this.contactPartiesListStore.contactPartyList.setError(result.error)
      return
    }

    this.contactPartiesListStore.contactPartyList.setData(result.data.parties)
  }
}
