import { Inject, Store } from '@nori/di'
import { CartStore } from '~/modules/cart/interface/store/cart-store/cart.store'

@Store()
export class ShopForMyselfStore {
  constructor(
    @Inject()
    private readonly cartStore: CartStore
  ) {}
  _isLoading = false

  setIsLoading(isLoading: boolean): void {
    this._isLoading = isLoading
  }

  get isLoading(): boolean {
    return this._isLoading || this.cartStore.cartData.isLoading
  }
}
