import { useMemo } from 'react';
export function useAccordionContext({ active , onChange  }) {
    const context = useMemo(()=>({
            onChange,
            active
        }), [
        onChange,
        active
    ]);
    return {
        context
    };
}
