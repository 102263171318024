import Link from 'next/link'

import { useTranslations } from '@nori/lang-i18n'
import { Routes } from '~/modules/routes'

import { HeaderDropdown } from './header-dropdown'

import type { HTMLAttributeAnchorTarget, PropsWithChildren } from 'react'
import type { LinkWithChildren } from '../../header-links/header-link.type'
import type {
  HeaderDropdownBorderRadiusType,
  HeaderDropdownVerticalAlignType,
  HeaderDropdownWidth,
} from './header-dropdown'

type Props = PropsWithChildren & {
  align?: 'left' | 'right'
  link: LinkWithChildren
  'data-test-id'?: string
  listSlot?: React.ReactNode
  verticalAlign?: HeaderDropdownVerticalAlignType
  borderRadiusType?: HeaderDropdownBorderRadiusType
  isFullHeight?: boolean
  hasListPadding?: boolean
  dropdownWidth?: HeaderDropdownWidth
  target?: HTMLAttributeAnchorTarget | undefined
}

export function HeaderLink({
  align = 'left',
  link,
  'data-test-id': dataTestId,
  children,
  listSlot,
  verticalAlign,
  borderRadiusType = 'none',
  isFullHeight = false,
  hasListPadding = true,
  dropdownWidth,
  target,
}: Props) {
  const t = useTranslations()

  const hasLinkChildren = !!link.children?.length
  const heightClass = isFullHeight ? 'h-full flex items-center' : ''
  const listPaddingClass = hasListPadding ? 'px-3 py-3' : ''

  return (
    <div className={`relative ${heightClass} group`} data-test-id={dataTestId}>
      {link.translationKey && (
        <Link target={target} href={link.href || '#'}>
          {t(link.translationKey)}
        </Link>
      )}

      {children && (
        <Link className="cursor-pointer" href={Routes.settings.account.path()}>
          {children}
        </Link>
      )}
      <div className="invisible group-hover:visible">
        {hasLinkChildren && (
          <HeaderDropdown
            borderRadiusType={borderRadiusType}
            align={align}
            width={dropdownWidth}
            verticalAlign={verticalAlign}
          >
            <div className={listPaddingClass}>{listSlot}</div>
          </HeaderDropdown>
        )}
      </div>
    </div>
  )
}
