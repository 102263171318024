import Link from 'next/link'

import { getLink } from '@nori/app-kit'
import { useTranslations } from '@nori/lang-i18n'

export function RightBlock() {
  const currentYear = new Date().getFullYear()
  const t = useTranslations('footer')

  return (
    <div className="flex flex-col gap-2 max-lg:items-center max-lg:gap-4 lg:items-end lg:gap-1">
      <div className="flex items-baseline gap-4 max-lg:flex-col-reverse max-lg:items-center max-lg:gap-4 lg:gap-2">
        <div className="font-normal max-lg:text-xs lg:text-xxs">
          © {currentYear}. Norwex USA All rights reserved.
        </div>
        <div className="flex items-center gap-2 max-sm:flex-col">
          <Link
            target="_blank"
            href={getLink({ type: 'bo', personalLink: 'privacy-policy' }).url}
            className="font-normal underline max-lg:text-sm lg:text-xs"
          >
            {t('privacyPolicy')}
          </Link>
          <span className="max-sm:hidden">|</span>
          <Link
            target="_blank"
            href={
              getLink({ type: 'bo', personalLink: 'terms-and-conditions' }).url
            }
            className="font-normal underline max-lg:text-sm lg:text-xs"
          >
            {t('termsAndConditions')}
          </Link>
          <span className="max-sm:hidden">|</span>
          <Link
            target="_blank"
            href={getLink({ type: 'b2c', personalLink: 'accessibility' }).url}
            className="font-normal underline max-lg:text-sm lg:text-xs"
          >
            {t('accessibility')}
          </Link>
        </div>
      </div>
      <div className="font-normal max-lg:text-center max-lg:text-xs lg:text-xxs">
        {t('trademarksDescription')}
      </div>
    </div>
  )
}
