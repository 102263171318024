import { UserStore } from '@nori/app-kit'
import { Action, Inject } from '@nori/di'
import { LoadCartService } from '~/modules/cart/interface/services/create-cart.service'
import { CartStore } from '~/modules/cart/interface/store/cart-store/cart.store'
import { ShopForMyselfStore } from '~/modules/shop-for-myself/interface/store/shop-for-myself.store'
import { WishListService } from '~/modules/wishlist/interface/service/wish-list.service'

@Action()
export class ShopForMyselfAction {
  constructor(
    @Inject() private readonly loadCartService: LoadCartService,
    @Inject() private readonly shopForMyselfStore: ShopForMyselfStore,
    @Inject() private readonly wishListService: WishListService,
    @Inject() private readonly cartStore: CartStore,
    @Inject() private readonly userStore: UserStore
  ) {}

  async handleInitialLoad(isLoadPersonalCart: boolean): Promise<void> {
    if (this.shopForMyselfStore.isLoading) return

    this.shopForMyselfStore.setIsLoading(true)

    await this.loadPersonalCart(isLoadPersonalCart)

    await this.loadWishList()

    this.shopForMyselfStore.setIsLoading(false)
  }

  private async loadWishList(): Promise<void> {
    if (!this.userStore.isOSEnabled) return

    this.wishListService.dropWishListStore()

    await this.wishListService.loadWishList({
      customerCode: this.cartStore.isPersonalCart
        ? undefined
        : this.cartStore.cart?.customerURLCode,
    })
  }

  private async loadPersonalCart(isLoadPersonalCart: boolean): Promise<void> {
    if (isLoadPersonalCart && this.cartStore.isPersonalCart) return
    if (!isLoadPersonalCart && this.cartStore.isCartExists) return

    await this.loadCartService.loadCartByType('dsr')
  }
}
