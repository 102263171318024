import { observer } from '@nori/di'
import { useTranslations } from '@nori/lang-i18n'
import { Icon, Swap } from '@nori/ui-kit'

import { useAutosaveContext } from '../../interface/use-autosave-context'

export const NextOrderFreeShippingMessage = observer(() => {
  const t = useTranslations('autosaveSettings')
  const { nextOrderStore } = useAutosaveContext()
  const isFreeShipping = !!nextOrderStore.nextOrder?.isFreeShipping
  const textCenterClass = isFreeShipping
    ? ''
    : 'justify-start lg:justify-center text-left lg:text-center'

  return (
    <div
      className={`mb-6 flex flex-col items-start gap-3 rounded-sm bg-primary-100 px-4 py-4 lg:flex-row lg:items-center ${textCenterClass}`}
    >
      <Swap
        is={isFreeShipping}
        isSlot={
          <>
            <div className="flex items-start gap-1 whitespace-nowrap text-sm font-bold text-primary-900 lg:items-center lg:text-base">
              <Icon name="Check" size="s" /> {t('freeShipping')}
            </div>
            <div className="hidden h-6 w-px bg-primary-300 lg:block" />
            <div className="text-sm font-medium text-primary-700 lg:font-normal">
              {t('freeShippingDescription')}
            </div>
          </>
        }
      >
        <div className="flex items-start gap-1 text-sm font-bold text-primary-900 lg:items-center lg:text-base">
          <Icon name="Discount" size="s" /> {t('freeShippingOver')}
        </div>
      </Swap>
    </div>
  )
})
