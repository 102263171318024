import { useTranslations } from '@nori/lang-i18n'
import { Icon, Swap, useToggle } from '@nori/ui-kit'

import type { CartBundleEntity } from '@nori/app-kit'

type Props = {
  bundle: CartBundleEntity
}

export const BundleDropDown = ({ bundle }: Props) => {
  const t = useTranslations('cart.productList')
  const detailsToggle = useToggle()

  const detailsHeight = detailsToggle.isOpen ? 'max-h-full' : 'max-h-0'

  return (
    <div>
      <div
        className="flex cursor-pointer items-center"
        onClick={detailsToggle.handleToggle}
      >
        <div className=" text-sm font-bold text-primary-900">
          {t('productDetails')}
        </div>
        <div>
          <Swap is={detailsToggle.isOpen} isSlot={<Icon name="ChevronUp" />}>
            <Icon name="ChevronDown" />
          </Swap>
        </div>
      </div>

      <div className={`${detailsHeight} overflow-hidden`}>
        <ul className="mt-2">
          {bundle.items.map((item) => {
            return (
              <li key={item.id} className="text-sm font-medium text-gray-500">
                ● {item.productName}
              </li>
            )
          })}
        </ul>
      </div>
    </div>
  )
}
