import { useTranslations } from '@nori/lang-i18n'
import { Link, Swap } from '@nori/ui-kit'

type Props = {
  isEditMode: boolean
  onRemove: () => void
  onEdit: () => void
}

export const NextOrderAddonActions = ({
  isEditMode,
  onRemove,
  onEdit,
}: Props) => {
  const t = useTranslations('autosaveSettings.nextOrderTable')

  return (
    <div>
      <Swap
        is={isEditMode}
        isSlot={
          <Link
            size="small"
            weight="bold"
            isUnderline={false}
            iconLeft="Trash"
            onClick={onRemove}
          >
            {t('removeAddOn')}
          </Link>
        }
      >
        <Link
          size="small"
          weight="bold"
          isUnderline={false}
          iconLeft="PencilAlt"
          onClick={onEdit}
        >
          {t('editAddOn')}
        </Link>
      </Swap>
    </div>
  )
}
