import { ShareCartErrors } from '../../core/share-cart.errors'

import type { ShareCartErrorInstance } from '../../core/share-cart.errors'

export class ShareCartErrorMapper {
  static toShareCartErrors(status: number, json: any): ShareCartErrorInstance {
    switch (status) {
      case 400:
        return new ShareCartErrors.BadRequest(json.error.message)

      case 404:
        return new ShareCartErrors.NotFound(json.errors)

      default:
        return new ShareCartErrors.UnknownError(json)
    }
  }
}
