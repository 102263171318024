import { CommonProductList } from '@nori/app-kit'
import { observer } from '@nori/di'
import { useTranslations } from '@nori/lang-i18n'
import { Loader } from '@nori/ui-kit'
import { useCartContext } from '~/modules/cart/interface/context/use-cart-context'
import { CustomizeBundleModal } from '~/modules/customize-bundle/ui'
import { useShopForMyselfContext } from '~/modules/shop-for-myself/interface/use-shop-for-myself.context'

import { ProductListItem } from './product-list-item'

export const ProductList = observer(() => {
  const t = useTranslations('shopForMyself.productList')

  const { searchProductsAction, searchProductsStore } =
    useShopForMyselfContext()
  const { cartStore } = useCartContext()
  const {
    error,
    isLoading,
    list,
    isLastPage,
    isLoadingNextPage,
    appliedSearchQuery: searchQuery,
  } = searchProductsStore

  if (isLoading)
    return (
      <div className="mt-4">
        <Loader />
      </div>
    )
  if (!list || searchQuery.length === 0) return null
  return (
    <>
      <CommonProductList
        hasInfiniteScroll
        isLastPage={isLastPage}
        isLoadingNextPage={isLoadingNextPage}
        handleLoadNextPage={searchProductsAction.handleLoadNextItems}
        list={list}
        addingToCartState={cartStore.addingToCartState}
        onClick={() => {}}
        renderItem={ProductListItem}
      />

      {error && <div className="mt-4">{t('error')}</div>}

      <CustomizeBundleModal />
    </>
  )
})
