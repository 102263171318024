import { type PropsWithChildren, useState } from 'react'

import { observer } from '@nori/di'
import { useTranslations } from '@nori/lang-i18n'
import { Button, TableRow, useToggle } from '@nori/ui-kit'
import { useAutosaveContext } from '~/modules/autosave/interface/use-autosave-context'
import { AddItemProductSubscription } from '~/modules/product-subscription/ui/add-item-product-subscription'

import type { CommonBundleEntity, CommonStyleEntity } from '@nori/app-kit'

export const Row = observer(
  ({
    item,
    children,
  }: PropsWithChildren<{
    item: CommonStyleEntity | CommonBundleEntity
  }>) => {
    const t = useTranslations('basic')
    const { subscriptionsAddProductStore, subscriptionsAddProductAction } =
      useAutosaveContext()
    const [subscriptionId, setSubscriptionId] = useState(
      item.defaultProductSubscription.id || -1
    )
    const subscriptionToggle = useToggle(item.canBeAutoShipped)
    const [selectedSubscriptionId, setSelectedSubscriptionId] = useState(
      item.defaultProductSubscription.id
    )
    return (
      <>
        <TableRow>{children}</TableRow>
        <TableRow>
          {!!item.productSubscriptions?.length && (
            <div className="mt-6 w-full min-w-[600px]">
              <AddItemProductSubscription
                subscriptions={item.productSubscriptions}
                selectedSubscriptionId={selectedSubscriptionId}
                isSubscriptionSelected={subscriptionToggle.isOpen}
                onSubscriptionOptionChange={(id: number) => {
                  setSelectedSubscriptionId(id)
                  setSubscriptionId(id)
                }}
                onSubscriptionOpen={subscriptionToggle.handleOpen}
                onSubscriptionClose={subscriptionToggle.handleClose}
                itemId={item.id}
                isCanBeAutoshipped={item.canBeAutoShipped}
              />
            </div>
          )}
          <div className="mt-4 flex w-full min-w-[600px] justify-end">
            <div className="w-full">
              <Button
                onClick={() =>
                  subscriptionsAddProductAction.handleAddProduct({
                    product: item,
                    subscriptionId,
                    oneOff: !subscriptionToggle.isOpen,
                  })
                }
                isLoading={
                  !!subscriptionsAddProductStore.isLoadingById[item.id]
                }
                size="xl"
                rounded="unset"
                widthType="full"
              >
                {t('add')}
              </Button>
            </div>
          </div>
        </TableRow>
      </>
    )
  }
)
