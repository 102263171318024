import { useMemo } from 'react'

import { ProductImage } from '@nori/app-kit'

import { AutoSaveUnavailableMessage } from '../../../autosave-order-row/autosave-unavailable-message'

import { NextOrderAddonActions } from './next-order-addon-actions'
import { NextOrderAddonEditor } from './next-order-addon-editor'
import { NextOrderAddonInfo } from './next-order-addon-info'
import { NextOrderAddonPricing } from './next-order-addon-pricing'

import type {
  BundleDetailsEntity,
  MoneyValueObject,
  StyleDetailsEntity,
} from '@nori/app-kit'
import type { SubscriptionEntity } from '~/modules/subscription'

type Props = {
  subscription: SubscriptionEntity
  retailPrice?: MoneyValueObject
  salePrice?: MoneyValueObject
  consultantPrice?: MoneyValueObject
  totalPrice?: MoneyValueObject
  onRemove: () => void
  handleSave: () => void
  productInfo?: BundleDetailsEntity | StyleDetailsEntity
  itemQty: string
  onItemQtyChange?: (qty: string) => void
  isEditMode: boolean
  onEdit: () => void
  styleName?: string
}

export const NextOrderAddonItem = ({
  subscription,
  retailPrice,
  salePrice,
  consultantPrice,
  totalPrice,
  onRemove,
  handleSave,
  productInfo,
  itemQty,
  onItemQtyChange,
  isEditMode,
  onEdit,
  styleName,
}: Props) => {
  const skuInfo = useMemo(() => {
    if (!productInfo) return
    if (!('styles' in productInfo)) return

    return productInfo.styles
      .map((style) => style.skus)
      .flat()
      .find((sku) => sku.code === subscription.skuCode)
  }, [productInfo, subscription.skuCode])

  const isAvailable = skuInfo?.available && productInfo?.available

  const availabilityClass = isAvailable ? '' : 'opacity-40'

  return (
    <div className="flex w-full max-w-4xl flex-col gap-5">
      <div className="flex flex-wrap gap-x-4">
        <AutoSaveUnavailableMessage
          isAvailable={isAvailable}
          isCanBeAutoshipped={true}
        />
        <div className={`${availabilityClass} max-lg:hidden`}>
          <ProductImage
            width={128}
            height={128}
            styleName="h-32 w-32"
            src={subscription.thumbnailUrl}
            alt={subscription.productName}
          />
        </div>
        <div className={`${availabilityClass} lg:hidden`}>
          <ProductImage
            width={80}
            height={80}
            styleName="h-20 w-20"
            src={subscription.thumbnailUrl}
            alt={subscription.productName}
          />
        </div>
        <div className={`${availabilityClass} flex-1`}>
          <NextOrderAddonInfo
            subscription={subscription}
            itemQty={itemQty}
            onItemQtyChange={onItemQtyChange}
            handleSave={handleSave}
            isEditMode={isEditMode}
            styleName={styleName}
          />
        </div>
        <div>
          <NextOrderAddonPricing
            quantity={subscription?.quantity}
            retailPrice={retailPrice}
            salePrice={salePrice}
            consultantPrice={consultantPrice}
            totalPrice={totalPrice}
            isEditMode={isEditMode}
            onRemove={onRemove}
            isUnavailable={!isAvailable}
            onEdit={onEdit}
          />
        </div>
      </div>
      <div className="lg:hidden">
        {isEditMode && (
          <NextOrderAddonEditor
            itemQty={itemQty}
            onItemQtyChange={onItemQtyChange}
            handleSave={handleSave}
          />
        )}
      </div>
      {isAvailable && (
        <div className="self-center lg:hidden">
          <NextOrderAddonActions
            isEditMode={isEditMode}
            onRemove={onRemove}
            onEdit={onEdit}
          />
        </div>
      )}
    </div>
  )
}
