export function getIntegerValue({ value , min , max  }) {
    const pattern = /\d+/g;
    const matches = value.match(pattern);
    const match = (matches == null ? void 0 : matches[0]) || '';
    if (!match) return '';
    const num = Number(match);
    if (isNaN(num)) return '';
    if (min !== undefined && num < min) return `${min}`;
    if (max !== undefined && num > max) return `${max}`;
    return `${num}`;
}
