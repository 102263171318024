import { errorFactory } from '@nori/errors'

import type { KeyOfErrors } from '@nori/errors'

export const { AcceptAgreementErrors } = errorFactory('AcceptAgreementErrors', {
  BadRequest: 'Bad Request',
  NotFound: 'Not Found',
  Unathorized: 'Unathorized',
  UnexpectedError: 'Unexpected Error',
  UnknownError: 'Unknown Error',
  UnprocessableEntity: 'UnprocessableEntity',
})

export type AcceptAgreementErrorInstance = InstanceType<
  KeyOfErrors<typeof AcceptAgreementErrors>
>
