import { errorFactory } from '@nori/errors'

import type { KeyOfErrors } from '@nori/errors'

export const { SubscriptionErrors } = errorFactory('SubscriptionErrors', {
  SubscriptionsUnknownError: 'Error while getting subscriptions',
  UnexpectedSubscriptionsError: 'Unexpected error while getting subscriptions',

  NextOrderUnknownError: 'Error while getting next order',
  UnexpectedNextOrderError: 'Unexpected error while getting next order',

  SetShouldApplyCreditsUnknownError: 'Set Should Apply Credits Unknown Error',
  UnexpectedSetShouldApplyCreditsError:
    'Unexpected Set Should Apply Credits Error',

  UpdateSubscriptionsUnknownError: 'Update Subscriptions Unknown Error',
  UnexpectedUpdateSubscriptionsError: 'Unexpected Update Subscriptions Error',

  RemoveSubscriptionUnknownError: 'Remove Subscription Unknown Error',
  UnexpectedRemoveSubscriptionError: 'Unexpected Remove Subscription Error',

  AddSubscriptionUnknownError: 'Add Subscription Unknown Error',
  UnexpectedAddSubscriptionError: 'Unexpected Add Subscription Error',

  UpdateSubscriptionStatusUnknownError:
    'Update Subscription Status Unknown Error',
  UnexpectedUpdateSubscriptionStatusError:
    'Unexpected Update Subscription Status Error',

  AddSubscriptionOneTimeShippingAddressUnknownError:
    'Add Subscription One Time Shipping Address Unknown Error',
  UnexpectedAddSubscriptionOneTimeShippingAddressError:
    'Unexpected Add Subscription One Time Shipping Address Error',

  UpdateSubscriptionOneTimeShippingAddressUnknownError:
    'Update Subscription One Time Shipping Address Unknown Error',
  UnexpectedUpdateSubscriptionOneTimeShippingAddressError:
    'Unexpected Update Subscription One Time Shipping Address Error',

  DeleteSubscriptionShippingAddressUnknownError:
    'Delete Subscription Shipping Address Unknown Error',
  UnexpectedDeleteSubscriptionShippingAddressError:
    'Unexpected Delete Subscription Shipping Address Error',

  SkipSubscriptionUnknownError: 'Error while skipping subscription',
  UnexpectedSkipSubscriptionError:
    'Unexpected error while skipping subscription',
})

export type SubscriptionErrorInstance = InstanceType<
  KeyOfErrors<typeof SubscriptionErrors>
>
