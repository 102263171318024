import { Action, Inject, observe } from '@nori/di'
import { isErr } from '@nori/result'
import { createLogger } from '~/infra/create-logger'
import { CartStore } from '~/modules/cart/interface/store/cart-store/cart.store'

import { UserCreditsAdapter } from '../../infra/user-credits.adapter'
import { CartUserCreditsStore } from '../store/cart-user-credits.store'

const logger = createLogger('cart-user-credits.action')

@Action()
export class CartUserCreditsAction {
  constructor(
    @Inject() private readonly cartUserCreditsStore: CartUserCreditsStore,
    @Inject() private readonly userCreditsAdapter: UserCreditsAdapter,
    @Inject() private readonly cartStore: CartStore
  ) {
    observe(this.cartStore, 'customerId', () => {
      this.handleLoadUserCredits(this.cartStore.customerId)
    })
    this.handleLoadUserCredits(this.cartStore.customerId)
  }

  async handleLoadUserCredits(userId?: number): Promise<void> {
    if (!userId) {
      this.cartUserCreditsStore.cartUserCredits.setData(undefined)
      return
    }
    this.cartUserCreditsStore.cartUserCredits.setIsLoading(true)

    await this.loadUserCredits(userId)

    this.cartUserCreditsStore.cartUserCredits.setIsLoading(false)
  }

  private async loadUserCredits(userId: number): Promise<void> {
    this.cartUserCreditsStore.cartUserCredits.setError(undefined)

    const result = await this.userCreditsAdapter.getCredits(userId)
    if (isErr(result)) {
      logger.error(result.error.key, result.error)
      this.cartUserCreditsStore.cartUserCredits.setError(result.error)
      return
    }
    this.cartUserCreditsStore.cartUserCredits.setData(result.data)
  }
}
