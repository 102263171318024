import { useMemo } from 'react'

import { observer } from '@nori/di'
import { useTranslations } from '@nori/lang-i18n'
import { Button, useMount, useToggle } from '@nori/ui-kit'
import { useCartContext } from '~/modules/cart'

import { usePennyContext } from '../../interface/use-penny.context'

import { CartBannerItem } from './cart-banner-item'

import type { ReactNode } from 'react'

const DEFAULT_MAX_SHOWN_BANNERS_LG = 3
const DEFAULT_MAX_SHOWN_BANNERS_SM = 1

type Props = {
  catalogButtonSlot?: ReactNode
}

export const CartBanner = observer(({ catalogButtonSlot }: Props) => {
  const { cartStore } = useCartContext()
  const { cartBannerStore, cartBannerAction } = usePennyContext()
  const t = useTranslations('penny')

  const { isOpen, handleToggle } = useToggle()

  const viewMoreTitle = useMemo(
    () =>
      isOpen
        ? t('viewLess')
        : t('viewAllOffers', { total: cartBannerStore.messagesNumber }),
    [cartBannerStore.messagesNumber, isOpen, t]
  )
  const showMoreStyles = useMemo(
    () =>
      isOpen
        ? '[&>*]:block'
        : 'lg:[&>*:nth-child(-n+3)]:block max-lg:[&>*:nth-child(1)]:block',
    [isOpen]
  )

  useMount(() => {
    cartBannerAction.handleSubscribe()
    return () => {
      cartBannerAction.handleUnsubscribe()
    }
  })

  const isBannerShown = !!cartBannerStore.messagesNumber || !!catalogButtonSlot

  if (!isBannerShown) {
    return null
  }

  if (!cartBannerStore.messagesNumber) {
    return (
      <div className="mt-6 flex justify-end max-lg:mx-2 max-sm:mb-5 max-sm:mt-0">
        {catalogButtonSlot}
      </div>
    )
  }

  return (
    <div className="mt-12 flex flex-col max-sm:mb-5 max-sm:mt-0">
      <div className="mb-7 flex flex-row items-center justify-between max-lg:hidden">
        <div className="flex w-full items-center justify-between">
          <div className="flex flex-row items-center gap-10">
            <h3 className="text-xl font-medium text-primary-900">
              {t('specialOffers')}
            </h3>
            {cartBannerStore.messagesNumber > DEFAULT_MAX_SHOWN_BANNERS_LG && (
              <Button size="sm" onClick={handleToggle}>
                <div className="px-5">{viewMoreTitle}</div>
              </Button>
            )}
          </div>
          {catalogButtonSlot}
        </div>
      </div>
      <div className="mx-2 flex  justify-between lg:hidden">
        {cartBannerStore.messagesNumber > DEFAULT_MAX_SHOWN_BANNERS_SM && (
          <div className="mt-5 flex items-center justify-between">
            <Button widthType="full" size="sm" onClick={handleToggle}>
              {viewMoreTitle}
            </Button>
          </div>
        )}
        {catalogButtonSlot && (
          <div className="mt-5 flex items-center justify-between">
            {catalogButtonSlot}
          </div>
        )}
      </div>
      <div
        className={`flex flex-row flex-wrap gap-10 max-sm:gap-5 ${showMoreStyles} max-lg:px-2 max-lg:pt-5`}
      >
        {cartBannerStore.cartBanner?.messages?.map((message) => {
          return (
            <CartBannerItem
              data-test-id={`special-offer-item_${message.id}`}
              key={message.id}
              message={message}
            />
          )
        })}
      </div>
    </div>
  )
})
