import { getBaseUrl, RequestService, SERVICE_NAMES } from '@nori/app-kit'
import { Adapter, Inject } from '@nori/di'
import { resultErr, resultOk } from '@nori/result'

import { type ArchivedOrderErrorInstance, ArchivedOrderErrors } from '../core'
import { OrderErrors } from '../core/order.errors'

import { ArchivedOrderMapper } from './mappers/archived-order.mapper'
import { OrderMapper } from './mappers/order.mapper'
import { OrderErrorMapper } from './mappers/order-error.mapper'

import type { PromiseResult } from '@nori/result'
import type { GetOrderPDFInput } from '../core'
import type { ArchivedOrderValueObject } from '../core/archived-order.value-object'
import type { OrderEntity } from '../core/order.entity'
import type { OrderErrorInstance } from '../core/order.errors'
import type { RetailSalesValueObject } from '../core/retail-sales.value-object'

@Adapter()
export class OrderAdapter {
  baseUrl = getBaseUrl(SERVICE_NAMES.OMS_SERVICE)

  constructor(
    @Inject() private readonly requestService: RequestService,
    @Inject() private readonly orderMapper: OrderMapper,
    @Inject() private readonly archivedOrderMapper: ArchivedOrderMapper,
    @Inject() private readonly orderErrorMapper: OrderErrorMapper
  ) {}

  async getOrderPDF(
    input: GetOrderPDFInput
  ): PromiseResult<ArrayBuffer, OrderErrorInstance> {
    try {
      let url = `${getBaseUrl(SERVICE_NAMES.OMS_SERVICE)}/orders/${
        input.publicOrderId
      }/pdf`
      if (input.withDetails) url += '?detailed_totals=true'

      const { ok, json } = await this.requestService.secured.get<ArrayBuffer>(
        url,
        { resultType: 'array-buffer' }
      )

      if (ok) return resultOk(json)

      return resultErr(new OrderErrors.GetOrderPDFUnknownError(json))
    } catch (err: unknown) {
      return resultErr(new OrderErrors.GetOrderPDFUnexpectedError(err))
    }
  }

  async getOrder(
    orderId: number
  ): PromiseResult<OrderEntity, OrderErrorInstance> {
    const url = `${this.baseUrl}/orders/${orderId}`

    try {
      const response = await this.requestService.secured.get<any>(url)

      if (response.ok) {
        return resultOk(this.orderMapper.toOrderEntity(response.json))
      }

      return resultErr(
        this.orderErrorMapper.toGetOrderError(response.status, response.json)
      )
    } catch (err: unknown) {
      return resultErr(new OrderErrors.GetOrderUnexpectedError(err))
    }
  }

  async getArchivedOrder(
    orderId: number
  ): PromiseResult<ArchivedOrderValueObject, ArchivedOrderErrorInstance> {
    const url = `${this.baseUrl}/orders/archived/${orderId}`

    try {
      const response = await this.requestService.secured.get<any>(url)

      if (response.ok) {
        return resultOk(this.archivedOrderMapper.toOrderEntity(response.json))
      }

      return resultErr(
        this.orderErrorMapper.toGetOrderError(response.status, response.json)
      )
    } catch (err: unknown) {
      return resultErr(
        new ArchivedOrderErrors.GetArchivedOrderUnexpectedError(err)
      )
    }
  }

  async cancelOrder(
    orderId: number
  ): PromiseResult<undefined, OrderErrorInstance> {
    const url = `${this.baseUrl}/orders/${orderId}`

    try {
      const response = await this.requestService.secured.delete<any>(url)

      if (response.ok) {
        return resultOk(undefined)
      }

      return resultErr(
        this.orderErrorMapper.toCancelOrderError(response.status, response.json)
      )
    } catch (err: unknown) {
      return resultErr(new OrderErrors.CancelOrderUnexpectedError(err))
    }
  }

  async getPartyOrdersRetailSales(
    partyId: number
  ): PromiseResult<RetailSalesValueObject, OrderErrorInstance> {
    const url = `${this.baseUrl}/orders/retail_sales/${partyId}.json`

    try {
      const response = await this.requestService.secured.get<any>(url)

      if (response.ok) {
        return resultOk(
          this.orderMapper.toRetailSalesValueObject(response.json)
        )
      }

      return resultErr(
        this.orderErrorMapper.toGetPartyOrdersRetailSalesError(
          response.status,
          response.json
        )
      )
    } catch (err: unknown) {
      return resultErr(
        new OrderErrors.GetPartyOrdersRetailSalesUnexpectedError(err)
      )
    }
  }
}
