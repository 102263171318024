var __defProp = Object.defineProperty;
var __name = (target, value) => __defProp(target, "name", { value, configurable: true });

// src/interface/handlers/check-raw-data.ts
function checkRawData(body) {
  return body instanceof FormData || body instanceof URLSearchParams;
}
__name(checkRawData, "checkRawData");

// src/interface/handlers/get-abort-controller.ts
function getAbortController({ abortController, timeout }) {
  if (!timeout)
    return {
      signal: abortController == null ? void 0 : abortController.signal,
      stop: () => {
      }
    };
  const controller = abortController || new AbortController();
  const id = setTimeout(() => {
    timeout && controller.abort();
  }, timeout);
  return {
    signal: controller.signal,
    stop: () => {
      clearTimeout(id);
    }
  };
}
__name(getAbortController, "getAbortController");

// src/interface/handlers/clean-undefined-in-headers.ts
function cleanUndefinedInHeaders(obj) {
  Object.keys(obj).forEach((key) => {
    if (obj[key] === void 0) {
      delete obj[key];
    }
  });
  return obj;
}
__name(cleanUndefinedInHeaders, "cleanUndefinedInHeaders");

// src/interface/handlers/get-platform-value.ts
var platformId = "";
function getPlatformValue() {
  if (platformId)
    return platformId;
  const isStelladot = window.location.hostname.includes("dyn.stelladot.com");
  if (isStelladot)
    return "stelladot";
  return "norwex";
}
__name(getPlatformValue, "getPlatformValue");

// src/interface/handlers/get-headers.ts
function getHeaders(headers = {}) {
  const extraHeaders = {
    "X-Rosi-Platform": getPlatformValue(),
    "X-Rosi-Auth": "guest",
    ...headers,
    "Content-Type": "Content-Type" in headers ? headers["Content-Type"] : "application/json"
  };
  return cleanUndefinedInHeaders(extraHeaders);
}
__name(getHeaders, "getHeaders");

// src/interface/handlers/get-query-search-params.ts
function getParams({ params, withCache = false, url = "" }) {
  const cacheParam = withCache ? `_=${Date.now()}` : "";
  if (!params && url)
    return `?${url}&${cacheParam}`;
  if (!params)
    return `?${cacheParam}`;
  const searchParams = new URLSearchParams(`${url}&${cacheParam}`);
  params && Object.keys(params).forEach((key) => {
    const paramValue = params[key];
    if (Array.isArray(paramValue)) {
      paramValue.forEach((subParam) => {
        searchParams.append(key, subParam);
      });
      return;
    }
    if (paramValue === void 0)
      return;
    searchParams.append(key, String(paramValue));
  });
  return `?${searchParams.toString()}`;
}
__name(getParams, "getParams");
function getQuerySearchParams({ params, withCache = false, url = "" }) {
  const result = getParams({
    params,
    withCache,
    url
  });
  if (result === "?")
    return "";
  return result;
}
__name(getQuerySearchParams, "getQuerySearchParams");

// src/interface/fetch-request.service.ts
async function fetchRequestService(url, args = {}) {
  var _a, _b;
  const { type = "GET" } = args;
  if ((type === "POST" || type === "PUT") && !args.body) {
    throw new Error("Body does not exist for this method, please add it");
  }
  const body = checkRawData(args.body) ? args.body : JSON.stringify(args.body);
  const abortController = getAbortController({
    timeout: args.timeout,
    abortController: args.abortController
  });
  const [baseUrl, querySearchParamsUrl] = url.split("?");
  const searchParams = getQuerySearchParams({
    url: querySearchParamsUrl,
    params: args.querySearchParams,
    withCache: args.withCache
  });
  const fullUrl = `${baseUrl}${searchParams}`;
  const result = await fetch(fullUrl, {
    headers: getHeaders(args.headers),
    method: type,
    body,
    signal: (_b = abortController.signal) != null ? _b : (_a = args == null ? void 0 : args.controller) == null ? void 0 : _a.signal
  });
  abortController.stop();
  return result;
}
__name(fetchRequestService, "fetchRequestService");

// src/interface/is-abort-controller-error.ts
function isAbortControllerError(error) {
  return !!error && error instanceof DOMException && error.name === "AbortError";
}
__name(isAbortControllerError, "isAbortControllerError");
export {
  fetchRequestService,
  getPlatformValue,
  isAbortControllerError
};
