import { Store } from '@nori/di'

@Store()
export class AppcuesStore {
  isLoaded = false

  setIsLoaded(isLoaded: boolean): void {
    this.isLoaded = isLoaded
  }
}
