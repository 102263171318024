import { usePaymentMethodsContext } from '@nori/app-kit'
import { observer } from '@nori/di'
import { useTranslations } from '@nori/lang-i18n'
import { Dropdown, Loader, Swap } from '@nori/ui-kit'
import { usePaymentMethodsContext as useConsultantPaymentMethodsContext } from '~/modules/payment-methods'

import { CreditCardDropdownItem } from './credit-card-dropdown-item'

import type { CreditCardPaymentMethodEntity } from '@nori/app-kit'

type Props = {
  selectedCard?: CreditCardPaymentMethodEntity
  error?: string
  onSelectCreditCard: (card: CreditCardPaymentMethodEntity) => void
  isVisible: boolean
  size?: 'small' | 'default'
}

export const SavedCreditCardsDropdown = observer(
  ({ selectedCard, onSelectCreditCard, isVisible, error, size }: Props) => {
    const t = useTranslations('appkit.braintree.payments')

    const { paymentMethodStore } = usePaymentMethodsContext()
    const { consultantPaymentMethodStore } =
      useConsultantPaymentMethodsContext()

    if (!isVisible || !paymentMethodStore.creditCards?.length) return null
    if (consultantPaymentMethodStore.isLoading) return <Loader />

    return (
      <div className="my-3">
        <Dropdown
          size={size}
          hideHelperBlock
          error={error}
          label={t('creditCard')}
          title={
            <Swap is={!selectedCard} isSlot={t('selectSavedCard')}>
              {selectedCard && (
                <CreditCardDropdownItem paymentMethod={selectedCard} />
              )}
            </Swap>
          }
        >
          {paymentMethodStore.creditCards?.map((card, index) => {
            return (
              <Dropdown.Option
                key={`${card.value}-${index}`}
                onClick={() => onSelectCreditCard(card)}
                selected={selectedCard?.value === card.value}
              >
                <CreditCardDropdownItem paymentMethod={card} />
              </Dropdown.Option>
            )
          })}
        </Dropdown>
      </div>
    )
  }
)
