import { Service } from '@nori/di'

import type { CreateShareLinkRequest } from '../types/create-share-link.request'

@Service()
export class CreateShareLinkRequestMapper {
  toRequestInput(partyId?: number): CreateShareLinkRequest {
    return {
      party_id: partyId,
    }
  }
}
