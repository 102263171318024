import {
  RouteAction,
  RouteStore
} from "./chunk-P4TY67WJ.mjs";

// src/modules/report-type-dashboard/interface/view-type.ts
var VIEW_TYPE_KEY = "view-type";
var VIEW_TYPE_TEAM_STATS = "team-stats";
var VIEW_TYPE_COMMISSION_STATEMENT = "commission-statement";

// src/modules/route/use-route-context.ts
import { hookContextFactory } from "@nori/di";
var { useModuleContext: useRouteContext } = hookContextFactory({
  routeStore: RouteStore,
  routeAction: RouteAction
});

export {
  VIEW_TYPE_KEY,
  VIEW_TYPE_TEAM_STATS,
  VIEW_TYPE_COMMISSION_STATEMENT,
  useRouteContext
};
