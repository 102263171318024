import { useState } from 'react'

import { useTranslations } from '@nori/lang-i18n'
import { Divider, Icon, Loader } from '@nori/ui-kit'
import { CartProductSubscription } from '~/modules/product-subscription/ui/cart-product-subscription'
import { SelectSku } from '~/modules/shop-for-myself/ui/product-list/cells/select-sku'

import { HostessDiscountButton } from '../hostess-discount-button'
import { ProductItemNameMobile } from '../product-item-name'
import { CartItemPriceMobile } from '../product-item-price'
import { ProductItemQuantity } from '../product-item-quantity'
import { ProductItemThumbnail } from '../product-item-thumbnail'

import { ProductItemControlsMobile } from './product-item-controls-mobile'

import type { ProductSkuEntity } from '@nori/app-kit'
import type { CartItemEntity, StyleDetailsStyleEntity } from '@nori/app-kit'

type Props = {
  currentQuantity: string
  currency: string
  handleCancel: () => void
  handleItemQuantityChange: (value: string) => void
  isEditMode: boolean
  isProductLoading: boolean
  onAdd: () => void
  onAddWishlist: () => void
  onEditSwitch: () => void
  onRemove: () => void
  product: CartItemEntity
  canBeAutoShipped?: boolean
  error?: string
  isWishButtonEnabled?: boolean
  isPromoGenerated: boolean
  selectedStyle?: StyleDetailsStyleEntity
}

export const ProductItemMobile = ({
  currentQuantity,
  handleCancel,
  handleItemQuantityChange,
  isEditMode,
  isProductLoading,
  onAdd,
  onAddWishlist,
  onEditSwitch,
  onRemove,
  product,
  canBeAutoShipped,
  error,
  isWishButtonEnabled,
  isPromoGenerated,
  selectedStyle,
  currency,
}: Props) => {
  const t = useTranslations('cart.productList')
  const initialSku = selectedStyle?.skus.find((sku) => sku.id === product.skuId)
  const [selectedSku, setSelectedSku] = useState<ProductSkuEntity | undefined>()

  const onAddToWishlist = isPromoGenerated ? undefined : onAddWishlist

  function updateSelectedSku(sku: ProductSkuEntity) {
    product.newSkuId = sku.id
    setSelectedSku(sku)
  }

  return (
    <>
      <div className="relative py-6">
        {isProductLoading && <Loader viewType="filled-center" />}
        <div className="flex flex-col">
          <div className="flex w-full justify-between">
            <div className="flex flex-col gap-4">
              <div className="flex">
                <ProductItemThumbnail url={product.thumbnailUrl} size={80} />
                <div className="ml-4" />
                <div className="flex flex-col gap-1">
                  <ProductItemNameMobile
                    productName={product.productName}
                    skuVariation={product.skuVariation}
                    styleName={product.styleName}
                    skuName={product.skuName}
                    skuCode={product.skuCode}
                    itemQuantity={product.quantity}
                  />
                  {product.autoShip.name && (
                    <div className="flex items-start gap-0.5 font-bold text-gray-500">
                      <Icon size="xs" name="Refresh" />
                      <div className="text-xs font-bold">
                        {t('autoSave')} <br />({t('every')}
                        {product.autoShip.name})
                      </div>
                    </div>
                  )}
                </div>
              </div>
              {!isEditMode && !isPromoGenerated && (
                <div className="shrink-1 grow-0">
                  <HostessDiscountButton
                    productId={product.id}
                    isDiscountApplied={product.hostessDiscount}
                    isDiscountDisallow={product.disallowHostessDiscounts}
                  />
                </div>
              )}
            </div>
          </div>
          <CartItemPriceMobile
            currency={currency}
            totalPrice={product.totalPrice}
            retailPrice={product.retailPrice}
            salePrice={product.salePrice}
            promotionalDiscounts={product.totalPromotionalDiscounts}
            price={product.price}
            totalRetailPrice={product.totalRetailPrice}
            totalSalePrice={product.totalSalePrice}
            combinedCredits={product.totalCombinedCredits}
            dsrDiscount={product.totalDsrDiscount}
            hostessDiscountPercentage={product.hostessDiscountPercentage}
            hostessDiscountValue={product.totalHostessDiscountValue}
            productCredits={product.totalProductCredits}
            totalDiscounts={product.totalDiscount}
          />

          <div className="mt-7 flex w-full flex-wrap items-start gap-7">
            {isEditMode && (
              <ProductItemQuantity
                isEditMode={isEditMode}
                currentQuantity={currentQuantity}
                productQuantity={product.quantity}
                onQuantityChange={handleItemQuantityChange}
              />
            )}
            {isEditMode && (
              <div className="mt-2">
                <SelectSku
                  skuVariation={product.skuVariation}
                  skus={selectedStyle?.skus}
                  selectedSku={selectedSku || initialSku}
                  onSelect={(sku) => {
                    updateSelectedSku(sku)
                  }}
                />
              </div>
            )}
            {error && (
              <p className="mt-2 text-xs font-normal text-red-600">{error}</p>
            )}
          </div>

          {canBeAutoShipped && isEditMode && (
            <div className="mt-3">
              <CartProductSubscription product={product} />
            </div>
          )}

          <div className="mt-7">
            <ProductItemControlsMobile
              id={product.skuId}
              isEditable={!product.hostessDiscount && !isPromoGenerated}
              isEditMode={isEditMode}
              onCancel={handleCancel}
              onAdd={onAdd}
              onRemove={onRemove}
              onEditSwitch={onEditSwitch}
              isWishButtonEnabled={isWishButtonEnabled}
              onAddWishlist={onAddToWishlist}
            />
          </div>
        </div>
      </div>

      <Divider />
    </>
  )
}
