import _extends from "@swc/helpers/src/_extends.mjs";
class LoggerConfigManager {
    loadConfig() {
        const defaultConfig = {
            loggerTransportType: process.env.LOGGER_TRANSPORT_TYPE,
            rollbarAccessToken: process.env.LOGGER_ROLLBAR_ACCESS_TOKEN,
            environment: process.env.NEXT_PUBLIC_ENVIRONMENT_CONFIG
        };
        // load config from window object if it exists, used in next js to retrieve runtime variables
        if (typeof window !== 'undefined' && window._loggerConfig) {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            const { loggerTransportType , rollbarAccessToken , environment  } = window._loggerConfig;
            return {
                loggerTransportType: loggerTransportType || defaultConfig.loggerTransportType,
                rollbarAccessToken: rollbarAccessToken || defaultConfig.rollbarAccessToken,
                environment: environment || defaultConfig.environment
            };
        }
        return defaultConfig;
    }
    get loggerConfig() {
        return this.config;
    }
    setLoggerConfig(config) {
        this.config = _extends({}, this.config, config);
    }
    constructor(){
        this.config = this.loadConfig();
    }
}
const loggerConfigManager = new LoggerConfigManager();
export { loggerConfigManager };
