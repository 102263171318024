import { HistoryService } from '@nori/app-kit'
import { Action, Inject, observe } from '@nori/di'
import { isErr } from '@nori/result'
import { createLogger } from '~/infra/create-logger'
import { AddToCartService } from '~/modules/cart/interface/services/add-to-cart.service'
import { CartStore } from '~/modules/cart/interface/store/cart-store/cart.store'
import { Routes } from '~/modules/routes'
import { ShopForMyselfService } from '~/modules/shop-for-myself'

import { PennyAdapter } from '../../infra/penny.adapter'
import { CartBannerService } from '../services/cart-banner.service'

import type { Lambda } from '@nori/di'
import type {
  CartBannerMessageLinkEntity,
  ItemSelectionLinkEntity,
} from '../../core/entity/cart-banner-link.entity'
import type {} from '../../core/entity/cart-banner-message.entity'

const logger = createLogger('cart-banner.action')

@Action()
export class CartBannerAction {
  private cartBannerDispose?: Lambda = undefined

  constructor(
    @Inject() private readonly shopForMyselfService: ShopForMyselfService,
    @Inject() private readonly pennyAdapter: PennyAdapter,
    @Inject() private readonly addToCartService: AddToCartService,
    @Inject() private readonly cartStore: CartStore,
    @Inject() private readonly cartBannerService: CartBannerService,
    @Inject() private readonly historyService: HistoryService
  ) {}

  handleLinkClick(link: CartBannerMessageLinkEntity): void {
    if (link.type === 'search') {
      if (this.historyService.route === Routes.cart.path()) {
        this.historyService.replace(Routes.shop.path())
      }
      this.shopForMyselfService.handleSearchQueryChange(link.key)
    }

    if (link.type === 'item_selection') {
      this.linkItemSelection(link)
    }

    if (link.type === 'url') {
      window.open(link.key, '_blank')
    }
  }

  handleSubscribe(): void {
    this.cartBannerDispose?.()

    this.cartBannerService.loadCartBanner()

    this.cartBannerDispose = observe(this.cartStore, 'cart', () => {
      this.cartBannerService.loadCartBanner()
    })
  }

  handleUnsubscribe(): void {
    this.cartBannerDispose?.()
    this.cartBannerDispose = undefined
    this.cartBannerService.dropCartBanner()
  }

  private async linkItemSelection(
    link: ItemSelectionLinkEntity
  ): Promise<void> {
    const linkItems = link.key.items.map((item) => item.sku)
    const result = await this.pennyAdapter.getReward({
      items: linkItems,
      visibility: this.cartStore.isCustomerCart ? 'customer' : 'dsr',
    })
    if (isErr(result)) {
      logger.error(result.error.key, result.error)
      return
    }

    const addItemsPromise = result.data.map(async (item) => {
      await this.addToCartService.handleAddStyleToCart({
        quantity: 1,
        skuId: item.id,
        statusId: item.id.toString(),
      })
    })

    await Promise.allSettled(addItemsPromise)
  }
}
