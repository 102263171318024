import { useCallback, useMemo, useState } from 'react'

import { type CommonBundleEntity, ProductImage } from '@nori/app-kit'
import { useToggle } from '@nori/ui-kit'
import { useBundleContext } from '~/modules/customize-bundle/interface/use-bundle.context'
import { useGtmContext } from '~/modules/google-tag-manager/interface/use-gtm-context'
import { useShopForMyselfContext } from '~/modules/shop-for-myself/interface/use-shop-for-myself.context'
import { BundleDesktopCell } from '~/modules/shop-for-myself/ui/product-list/cells/bundle-desktop.cell'
import { BundleMobileCell } from '~/modules/shop-for-myself/ui/product-list/cells/bundle-mobile.cell'

type Props = {
  item: CommonBundleEntity
}

export function BundleCell({ item }: Props) {
  const { searchProductsAction } = useShopForMyselfContext()
  const { customizeBundleAction } = useBundleContext()
  const { gtmAction } = useGtmContext()
  const { maxPrice, maxRetailPrice, pictures, name } = item
  const picture = pictures[0]?.thumbnailMedURL ?? ''

  const [selectedSubscriptionId, setSelectedSubscriptionId] = useState(
    item.defaultProductSubscription.id
  )
  const subscriptionToggle = useToggle()

  const isBundleCustomizable = useMemo(() => {
    const isMoreThenOneInComponent = item.components?.some(
      (component) => component.products.length > 1
    )
    return isMoreThenOneInComponent
  }, [item])

  const handleAddToCart = useCallback(
    (quantity: number) => {
      if (!item || !quantity) return

      if (isBundleCustomizable) {
        customizeBundleAction.handleCustomizeBundle({
          bundleId: item.id,
          bundleCode: item.code,
          subscriptionPlanId: subscriptionToggle.isOpen
            ? selectedSubscriptionId
            : undefined,
        })
        return
      }

      gtmAction.handleTrackAddBundleToCart(item, quantity)

      searchProductsAction.handleAddBundleToCart({
        quantity,
        item,
        subscriptionPlanId: subscriptionToggle.isOpen
          ? selectedSubscriptionId
          : undefined,
      })
    },
    [
      item,
      isBundleCustomizable,
      searchProductsAction,
      subscriptionToggle.isOpen,
      selectedSubscriptionId,
    ]
  )

  return (
    <div className="flex w-full flex-col justify-between border-b border-primary-200 p-2 pb-5 pt-5">
      <div className="lg:invisible lg:hidden">
        <BundleMobileCell
          item={item}
          picture={picture}
          name={name}
          maxRetailPrice={maxRetailPrice}
          maxPrice={maxPrice}
          onAddToCart={handleAddToCart}
          isBundleCustomizable={isBundleCustomizable}
          selectedSubscriptionId={selectedSubscriptionId}
          setSelectedSubscriptionId={(id: number) =>
            setSelectedSubscriptionId(id)
          }
          subscriptionToggle={subscriptionToggle}
        />
      </div>
      <div className="invisible hidden lg:visible lg:block">
        <BundleDesktopCell
          item={item}
          picture={picture}
          name={name}
          maxRetailPrice={maxRetailPrice}
          maxPrice={maxPrice}
          onAddToCart={handleAddToCart}
          isBundleCustomizable={isBundleCustomizable}
          selectedSubscriptionId={selectedSubscriptionId}
          setSelectedSubscriptionId={(id: number) =>
            setSelectedSubscriptionId(id)
          }
          subscriptionToggle={subscriptionToggle}
        />
      </div>
    </div>
  )
}
