import type { ContactTabsType } from '../../core/contact-tabs.value-object'

export const customerTabs: ContactTabsType[] = [
  {
    key: 'info',
    translationKey: 'contactDetailsPage.tabs.info',
    dataTestId: 'contact-details-info-tab',
  },
  {
    key: 'credits',
    translationKey: 'contactDetailsPage.tabs.credits',
    dataTestId: 'contact-details-credits-tab',
  },
  {
    key: 'next-order',
    translationKey: 'contactDetailsPage.tabs.autosave',
    dataTestId: 'contact-details-autosave-tab',
  },
  {
    key: 'wishlist',
    translationKey: 'contactDetailsPage.tabs.wishlist',
    dataTestId: 'contact-details-wishlist-tab',
  },
]

export const contactTabs: ContactTabsType[] = [
  {
    key: 'info',
    translationKey: 'contactDetailsPage.tabs.info',
    dataTestId: 'contact-details-info-tab',
  },
]
