import { useMemo } from 'react';
export function useSettings(params) {
    const { totalPages , maxVisible , currentPage , disabled  } = params;
    return useMemo(()=>{
        const list = new Array(Math.min(totalPages, maxVisible)).fill(0);
        const startIndexRaw = Math.min(currentPage, Math.floor(currentPage - maxVisible / 2 + 1), totalPages - maxVisible);
        const startIndex = Math.max(startIndexRaw, 0);
        return {
            list,
            isLeftDisabled: disabled || currentPage <= 0,
            isRightDisabled: disabled || currentPage >= totalPages - 1,
            startIndex: startIndex < 0 ? 0 : startIndex
        };
    }, [
        totalPages,
        currentPage,
        maxVisible,
        disabled
    ]);
}
