import { Store } from '@nori/di'
import { getBlock } from '@nori/lang-i18n'

import type { ContactDetailsEntity } from '~/modules/contacts'
import type { OrderEntity } from '~/modules/order/core'
import type {
  OrderDetailsItemEntity,
  OrdersListErrorInstance,
} from '../../core'

type ExtendedOrderValueObject = {
  order: OrderEntity
  contact?: ContactDetailsEntity
}
@Store()
export class OrderDetailsStore {
  private orderById: Record<number, ExtendedOrderValueObject> = {}
  private isLoadingById: Record<number, boolean> = {}
  private errorById: Record<number, OrdersListErrorInstance> = {}

  getOrderDetails(id: number): {
    extendedOrder: ExtendedOrderValueObject | undefined
    isLoading: boolean
    error: string
  } {
    return {
      error: this.getErrorTitleById(id),
      // @TODO: check for this.isLoadingById[id] to exist
      isLoading: this.isLoadingById[id]!,
      extendedOrder: this.orderById[id],
    }
  }

  private getErrorTitleById(id: number): string {
    if (!this.errorById[id]) return ''
    const t = getBlock('orders.list.table.extendedOrderListRow.extended.errors')
    return t('loadingError')
  }

  getContact(orderId: number): ContactDetailsEntity | undefined {
    return this.orderById[orderId]?.contact
  }

  setOrderDetails(id: number, order: ExtendedOrderValueObject): void {
    this.orderById[id] = order
  }

  setIsLoading(id: number, value: boolean): void {
    this.isLoadingById[id] = value
  }

  setError(id: number, error?: OrdersListErrorInstance): void {
    if (!error) {
      delete this.errorById[id]
      return
    }

    this.errorById[id] = error
  }
}
