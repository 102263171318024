import { observer } from '@nori/di'
import { useTranslations } from '@nori/lang-i18n'
import { useContactContext } from '~/modules/contacts/interface/use-contact-context'

type Props = {
  id: number
}

export const NextCartAt = observer(({ id }: Props) => {
  const t = useTranslations('contacts.list.table.extended')
  const { contactSubscriptionStore } = useContactContext()
  const nextCartAt = contactSubscriptionStore.getNextCartAt(id)

  if (!nextCartAt) return null
  return (
    <div data-test-id={'contact-expanded-view-next-order-date'}>
      <span className="mr-2.5 font-bold text-primary-900">
        {t('nextCartAt')}
      </span>
      <span className="text-gray-500">
        - {nextCartAt.formatInTZ('MMM dd, yyyy')}
      </span>
    </div>
  )
})
