export const inputColorsMap = {
    normal: 'text-gray-900 placeholder-gray-500',
    failure: 'text-gray-900 placeholder-red-600',
    disabled: 'disabled:bg-gray-50 disabled:text-gray-400 disabled:placeholder-gray-400 disabled:cursor-not-allowed'
};
export const fieldSetColorsMap = {
    normal: 'border-primary-300  peer-focus:border-primary-600',
    failure: 'border-red-600 peer-focus:border-red-600',
    disabled: 'peer-disabled:border-gray-300'
};
