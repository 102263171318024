import { BraintreeService, PaymentMethodService } from '@nori/app-kit'
import { Inject, Service } from '@nori/di'
import { getBlock } from '@nori/lang-i18n'
import { NotifyAction } from '@nori/notify'
import { isErr } from '@nori/result'

import { ConsultantPaymentMethodStore } from '../store/consultant-payment-method.store'

import type { PaymentMethodValueObject } from '@nori/app-kit'

@Service()
export class CreatePaymentMethodService {
  constructor(
    @Inject()
    private readonly paymentMethodStore: ConsultantPaymentMethodStore,
    @Inject() private readonly paymentMethodService: PaymentMethodService,
    @Inject() private readonly notifyAction: NotifyAction,
    @Inject() private readonly braintreeService: BraintreeService
  ) {}

  async handleCreatePaymentMethod(
    type: PaymentMethodValueObject
  ): Promise<void> {
    this.paymentMethodStore.setIsLoading(true)

    await this.createPaymentMethod(type)

    this.paymentMethodStore.setIsLoading(false)
  }

  private async createPaymentMethod(
    type: PaymentMethodValueObject
  ): Promise<void> {
    if (!this.validate(type)) return

    this.paymentMethodStore.setError(undefined)

    await (type === 'card' ? this.createCard() : this.createPayPal())
  }

  private validate(type: PaymentMethodValueObject): boolean {
    const validationResult = [
      this.paymentMethodStore.billingAddressValidator.submit().isValid,
    ]
    if (type === 'card') {
      validationResult.push(
        this.paymentMethodStore.creditCardHolderValidator.submit().isValid
      )
    }
    if (!validationResult.every(Boolean)) return false

    if (type === 'paypal') return true

    const hostedFields = this.paymentMethodStore.hostedFields.data
    if (!hostedFields) return false

    const validation = this.braintreeService.validateHostedFields({
      hostedFields,
    })

    this.paymentMethodStore.setHostedFieldsValidationMap(validation)
    if (validation.invalidFields.length) return false

    return true
  }

  private async createCard(): Promise<void> {
    const params = this.paymentMethodStore.cardFormPayload
    const hostedFields = this.paymentMethodStore.hostedFields.data
    if (!hostedFields) {
      this.notifyAction.handleAddNotify({
        type: 'danger',
        title: getBlock('paymentMethodsPage.errors')(
          'createPaymentMethodHostedFieldsUndefined'
        ),
      })

      return
    }

    const result = await this.paymentMethodService.createPaymentMethod({
      ...params,
      hostedFields,
    })

    if (!isErr(result) && result.data.status === 'success') {
      this.handleCloseForm()
    }
  }

  private async createPayPal(): Promise<void> {
    const payPalParams = this.paymentMethodStore.payPalToken.data
    if (!payPalParams) {
      this.notifyAction.handleAddNotify({
        type: 'danger',
        title: getBlock('appkit.paymentMethods.errors')('missingPayPalAccount'),
      })
      return
    }

    await this.paymentMethodService.createPaymentMethod({
      nonce: payPalParams.token,
      billingAddress: {
        ...this.paymentMethodStore.cardFormPayload.billingAddress,
        firstName: payPalParams.firstName,
        lastName: payPalParams.lastName,
      },
      email: payPalParams.email,
    })
    this.handleCloseForm()
    this.paymentMethodStore.payPalToken.setData(undefined)
  }

  private handleCloseForm(): void {
    this.paymentMethodStore.resetForm()
    this.paymentMethodStore.setIsFormOpen(false)
  }
}
