import { observer } from '@nori/di'
import { useTranslations } from '@nori/lang-i18n'
import { HideSwap, Link } from '@nori/ui-kit'
import { useBillingFormContext } from '~/modules/billing-form/interface/use-billing-form.context'

import { CreditCardModeTabs } from './credit-cards-mode-tabs'
import { SplitAmountFields } from './split-amount-fields'

export const SplitPaymentBlock = observer(() => {
  const t = useTranslations('billingForm')

  const { billingFormStore, billingFormAction } = useBillingFormContext()

  return (
    <div className="flex flex-col">
      <div className="flex gap-10">
        <div className="my-4 text-xl font-normal text-primary-900">
          {t('splitPayment')}
        </div>
        {billingFormStore.isSplitPayment && (
          <Link
            size="medium"
            onClick={() =>
              billingFormAction.handleSetSecondCreditCardMode(undefined)
            }
          >
            {t('remove')}
          </Link>
        )}
      </div>
      {!billingFormStore.isSplitPayment && (
        <div>
          <Link
            size="large"
            weight="bold"
            isUnderline
            onClick={() =>
              billingFormAction.handleSetSecondCreditCardMode('saved')
            }
          >
            {t('creditCard.addAnotherCreditCard')}
          </Link>
        </div>
      )}

      <HideSwap
        is={!billingFormStore.isSplitPayment}
        isSlot={null}
        containerClass="flex flex-col flex-1"
      >
        <SplitAmountFields />
        <div
          className="flex flex-col"
          data-test-id="split-payment-secondary-credit-card"
        >
          <p className="my-4 text-xl font-normal text-primary-900">
            {t('creditCard.secondaryCreditCard')}
          </p>

          <CreditCardModeTabs />
        </div>
      </HideSwap>
    </div>
  )
})
