import { NorwexLogo } from '@nori/app-kit'

import { LogoTitle } from './logo-title'
import { RightBlock } from './right-block'
import { SocialLinks } from './social-links'

export function DesktopContent() {
  return (
    <div className="flex h-full items-center gap-4 px-10">
      <div className="flex flex-1 gap-6 max-xl:flex-col">
        <div className="flex items-end gap-4">
          <NorwexLogo />
          <div className="-mb-0.5">
            <LogoTitle />
          </div>
        </div>

        <div className="flex flex-1 xl:justify-center">
          <SocialLinks />
        </div>
      </div>

      <div className="xl:ml-auto">
        <RightBlock />
      </div>
    </div>
  )
}
