import { observer } from '@nori/di'

import { Contact } from './contact'
import { Party } from './party'

export const QuickAdd = observer(() => {
  return (
    <>
      <Contact />
      <Party />
    </>
  )
})
