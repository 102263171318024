import { useState } from 'react'

import { observer } from '@nori/di'
import { useTranslations } from '@nori/lang-i18n'
import { ConfirmationModal, Swap } from '@nori/ui-kit'
import { useAutosaveContext } from '~/modules/autosave/interface/use-autosave-context'

import { NextOrderAddonItemRow } from '../next-order-table/next-order-addon-item/next-order-addon-item-row'

import { NextOrderAddonEmpty } from './next-order-addon-empty'

export const NextOrderAddonTable = observer(() => {
  const t = useTranslations('autosaveSettings')

  const { nextOrderStore, nextOrderAction } = useAutosaveContext()

  const [removingId, setRemovingId] = useState<number>()

  return (
    <div className="flex flex-col rounded-sm border border-gray-100 p-3 lg:px-10 lg:py-4">
      <div className="flex border-b border-gray-100 pb-3 lg:pb-5">
        <div className="flex items-center">
          <span className="text-sm font-bold text-primary-700">
            {t('nextOrderTable.addOnItem')}
          </span>
          <div className="mx-3 h-full w-[2px] bg-gray-100" />
          <span className="text-sm font-medium text-primary-500">
            {t('nextOrderTable.shipsOnce')}
          </span>
        </div>
      </div>
      <div>
        <Swap
          is={!nextOrderStore.addOnItems?.length}
          isSlot={<NextOrderAddonEmpty />}
        >
          <div className="flex flex-col">
            <div>
              {nextOrderStore.addOnItems?.map((addOn) => (
                <div
                  className="border-b border-gray-100 pb-5 pt-5 last:border-b-0 last:pb-0"
                  key={addOn.id}
                >
                  <NextOrderAddonItemRow
                    subscription={addOn}
                    onRemove={() => setRemovingId(addOn.id)}
                    totalPrice={addOn.total}
                  />
                </div>
              ))}
            </div>
            <span className="mt-5 text-center text-xs font-medium text-gray-500 lg:text-right">
              {t('nextOrderTable.pricesAdjusted')}
            </span>
            <ConfirmationModal
              title={t('removeSubscriptionTitle')}
              text={t('removeSubscriptionText')}
              onSubmit={() =>
                removingId &&
                nextOrderAction.handleRemoveSubscription(removingId)
              }
              onClose={() => setRemovingId(undefined)}
              onCancel={() => setRemovingId(undefined)}
              isOpen={!!removingId}
              buttonContainerMinWidthClass="w-80"
              buttonSize="xs"
              buttonWidthType="full"
            />
          </div>
        </Swap>
      </div>
    </div>
  )
})
