import { Service } from '@nori/di'

import type { CheckoutSummaryEntity } from '../../core/entity/checkout-summary-message.entity'
import type { CheckoutSummaryValueObject } from '../../core/value-objects/checkout-summary.value-object'
import type CheckoutSummaryResponseMock from '../mocks/checkout-summary.mock.json'

@Service()
export class CheckoutSummaryMapper {
  toValueObject(
    response: typeof CheckoutSummaryResponseMock
  ): CheckoutSummaryValueObject {
    const messages = response.messages.map((message) => {
      return {
        id: message.id,
        body: message.body,
        promotionName: message.promotion_name,
        link: this._getMessageLink(message),
      }
    })
    return { messages }
  }

  _getMessageLink(
    message: (typeof CheckoutSummaryResponseMock)['messages'][0]
  ): CheckoutSummaryEntity['link'] {
    if (message?.link && message.link.type === 'url') {
      return {
        text: message.link.name,
        href: message.link.key,
      }
    }
  }
}
