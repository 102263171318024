import { AutosaveEmptyState } from '@nori/app-kit'
import { Routes } from '~/modules/routes'

export function NextOrderEmpty({ userId }: { userId?: number | null }) {
  const linkToShop = Routes.shop.isPersonalAutosave.path()

  if (!userId) return null

  return <AutosaveEmptyState linkToShop={linkToShop} userId={userId} />
}
