import { HistoryService, I18nAction } from '@nori/app-kit'
import { Action, Inject } from '@nori/di'
import { RootAppService } from '~/modules/root-app/interface/services/root-app.service'
import { RootAppStore } from '~/modules/root-app/interface/stores/root-app.store'
import { Routes } from '~/modules/routes'

@Action()
export class LoginAccountAction {
  constructor(
    @Inject() private readonly historyService: HistoryService,
    @Inject() private readonly i18nAction: I18nAction,
    @Inject() private readonly rootAppService: RootAppService,
    @Inject() private readonly rootAppStore: RootAppStore
  ) {}

  async handleLogin(): Promise<void> {
    await this.rootAppService.loadLoginContext()

    if (this.rootAppStore.isDataLoaded) {
      this.historyService.push(Routes.home.path())
      this.i18nAction.handleUpdateLocale()
    }

    // TODO: show error while loading?
  }
}
