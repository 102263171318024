import { Inject, Service } from '@nori/di'
import { isErr } from '@nori/result'
import { CartStore } from '~/modules/cart/interface/store/cart-store/cart.store'
import { GtmAction } from '~/modules/google-tag-manager/action/gtm.action'
import { SearchProductsService } from '~/modules/search-products'
import { SearchProductsStore } from '~/modules/shop-for-myself/interface/store/search-products.store'

import type { ProductListValueObject } from '~/modules/products'

const MIN_SEARCH_QUERY_LENGTH = 3

@Service()
export class ShopForMyselfService {
  constructor(
    @Inject() private readonly searchProductsStore: SearchProductsStore,
    @Inject() private readonly cartStore: CartStore,
    @Inject() private readonly searchProductsService: SearchProductsService,
    @Inject() private readonly gtmAction: GtmAction
  ) {}

  async handleSearchQueryChange(_searchQuery: string): Promise<void> {
    const searchQuery = _searchQuery.trim()
    if (searchQuery.length === 0) {
      this.searchProductsStore.setList(undefined)
      this.searchProductsStore.setAppliedSearchQuery('')
    }

    if (searchQuery.length < MIN_SEARCH_QUERY_LENGTH) return

    this.searchProductsStore.setIsLoading(true)
    this.searchProductsStore.setError(undefined)
    this.searchProductsStore.setSearchQuery(searchQuery)
    this.searchProductsStore.setAppliedSearchQuery(searchQuery)

    const initialPage = 0
    await this.loadProducts(initialPage)

    this.searchProductsStore.setIsLoading(false)
  }

  async loadProducts(page: number): Promise<void> {
    const { appliedSearchQuery: searchQuery, pagination } =
      this.searchProductsStore

    const result = await this.searchProductsService.searchProducts({
      searchQuery,
      isCustomerSearch: this.cartStore.isCustomerCart,
      pagination: {
        page,
        perPage: pagination.perPage,
      },
    })

    if (isErr(result)) {
      this.searchProductsStore.setError(result.error)
      return
    }

    this.gtmAction.handleTrackViewItemList(result.data, searchQuery)

    result.data.pagination.page === 1
      ? this.searchProductsStore.setList(result.data)
      : this.appendList(result.data)
  }

  private appendList(result: ProductListValueObject): void {
    const list = {
      pagination: result.pagination,
      filters: result.filters,
      items: [...(this.searchProductsStore.list?.items ?? []), ...result.items],
    }
    this.searchProductsStore.setList(list)
  }
}
