import { HistoryService, ProfileService } from '@nori/app-kit'
import { Action, Inject } from '@nori/di'
import { getBlock } from '@nori/lang-i18n'
import { NotifyAction } from '@nori/notify'
import { isErr } from '@nori/result'
import { Routes } from '~/modules/routes'

import { BirthdayGuardService } from '../service/birthday-guard.service'
import { BirthdayGuardStore } from '../store/birthday-guard.store'

@Action()
export class BirthdayGuardAction {
  constructor(
    @Inject() private readonly birthdayGuardStore: BirthdayGuardStore,
    @Inject() private readonly profileService: ProfileService,
    @Inject() private readonly notifyAction: NotifyAction,
    @Inject() private readonly historyService: HistoryService,
    @Inject() private readonly birthdayGuardService: BirthdayGuardService
  ) {}

  handleDayChange(day: string): void {
    this.birthdayGuardStore.birthdayValidator.handlers.day(day)
    this.handleMaxDayOfMonth()
  }

  handleMonthChange(month: number): void {
    this.birthdayGuardStore.birthdayValidator.handlers.month(month)
    this.handleMaxDayOfMonth()
  }

  handleYearChange(year: string): void {
    this.birthdayGuardStore.birthdayValidator.handlers.year(year)
    this.handleMaxDayOfMonth()
  }

  private handleMaxDayOfMonth(): { nextValue: string } | undefined {
    const { day, month, year } =
      this.birthdayGuardStore.birthdayValidator.values

    if (!day?.length || !year?.length || !month) return

    const result = this.birthdayGuardService.validateMaxDayOfMonth({
      year: parseInt(day),
      month: month,
      day: parseInt(day),
    })

    if (isErr(result)) {
      const nextValue = result.error.max.toString()
      this.birthdayGuardStore.birthdayValidator.handlers.day(nextValue)
      return { nextValue }
    }
  }

  async handleSubmit(): Promise<void> {
    this.handleMaxDayOfMonth()

    const { isValid } = this.birthdayGuardStore.birthdayValidator.submit()
    if (!isValid) return

    const { values } = this.birthdayGuardStore.birthdayValidator
    const month = values.month
    const year = parseInt(values.year)
    const day = parseInt(values.day)

    const result = this.birthdayGuardService.validateBirthday({
      month,
      year,
      day,
    })
    if (isErr(result)) {
      this.birthdayGuardStore.birthdayValidator.setError(
        result.error.field,
        result.error.error
      )
      return
    }

    this.birthdayGuardStore.setIsSubmitting(true)
    await this.updateBirthday({ month, year, day })
    this.birthdayGuardStore.setIsSubmitting(false)
  }

  private async updateBirthday({
    month,
    year,
    day,
  }: {
    month: number
    year: number
    day: number
  }): Promise<void> {
    const result = await this.profileService.handleUpdateProfile({
      birthday: `${month}/${day}/${year}`,
    })

    if (isErr(result) || result.data.type !== 'response') {
      this.notifyAction.handleAddNotify({
        type: 'danger',
        title: getBlock('birthdayGuard.errors')('apiError'),
      })
      return
    }

    await this.historyService.push(Routes.home.path())
  }
}
