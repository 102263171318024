import { Store } from '@nori/di'

import type {
  GetRequestPagination,
  ProductErrorInstance,
  ProductListType,
} from '@nori/app-kit'
import type { ProductListValueObject } from '~/modules/products/core/product-list.value-object'

const INITIAL_PAGINATION_VALUE: GetRequestPagination = {
  page: 1,
  perPage: 12,
}

@Store()
export class ProductsStore {
  isLoading = false
  isLoadingNextPage = false
  error?: ProductErrorInstance = undefined
  currentId?: string = undefined
  currentType?: ProductListType = undefined

  list?: ProductListValueObject = undefined

  get pagination(): GetRequestPagination {
    return this.list?.pagination ?? INITIAL_PAGINATION_VALUE
  }

  get isLastPage(): boolean {
    if (!this.list) return false

    const totalPages = this.list.pagination.totalPages
    if (totalPages <= this.pagination.page) return true
    return false
  }

  setList(list: ProductListValueObject): void {
    this.list = list
  }

  setCurrentId(id: string): void {
    this.currentId = id
  }

  setCurrentType(type: ProductListType): void {
    this.currentType = type
  }

  setIsLoading(value: boolean): void {
    this.isLoading = value
  }

  setIsLoadingNextPage(value: boolean): void {
    this.isLoadingNextPage = value
  }

  setError(value?: ProductErrorInstance): void {
    this.error = value
  }
}
