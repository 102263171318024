import { Inject, Service } from '@nori/di'
import { SubscriptionAdapter } from '~/modules/subscription/infra/subscription.adapter'

import type { PromiseResult } from '@nori/result'
import type {
  AddShippingAddressInputValueObject,
  NextOrderValueObject,
  PendingOrderEntity,
  PendingOrdersErrorInstance,
  SubscriptionEntity,
  SubscriptionErrorInstance,
  UpdateShippingAddressInputEntity,
} from '~/modules/subscription/core'
import type { AddSubscriptionInput } from '../../core/add-subscription.input'
import type { FailedValidationValueObject } from '../../core/failed-validation.value-object'
import type { SetShouldApplyCreditsInput } from '../../infra/types/set-should-apply-credits/set-should-apply-credits.input'
import type { UpdateSubscriptionsInput } from '../../infra/types/update-subscriptions/update-subscriptions.input'

@Service()
export class SubscriptionService {
  constructor(
    @Inject() private readonly subscriptionAdapter: SubscriptionAdapter
  ) {}

  getNextOrder(
    id?: number
  ): PromiseResult<NextOrderValueObject, SubscriptionErrorInstance> {
    return this.subscriptionAdapter.getNextOrder(id)
  }

  getSubscriptions(
    id?: number
  ): PromiseResult<SubscriptionEntity[], SubscriptionErrorInstance> {
    return this.subscriptionAdapter.getSubscriptions(id)
  }

  getPendingOrders(
    id?: number
  ): PromiseResult<PendingOrderEntity[], PendingOrdersErrorInstance> {
    return this.subscriptionAdapter.getPendingOrders(id)
  }

  addSubscription(
    input: AddSubscriptionInput
  ): PromiseResult<SubscriptionEntity[], SubscriptionErrorInstance> {
    return this.subscriptionAdapter.addSubscription(input)
  }

  removeSubscription(
    id: number
  ): PromiseResult<void, SubscriptionErrorInstance> {
    return this.subscriptionAdapter.removeSubscription(id)
  }

  setShouldApplyCredits(
    input: SetShouldApplyCreditsInput
  ): PromiseResult<boolean, SubscriptionErrorInstance> {
    return this.subscriptionAdapter.setShouldApplyCredits(input)
  }

  updateSubscriptions(input: UpdateSubscriptionsInput): PromiseResult<
    | { data: SubscriptionEntity[]; type: 'response' }
    | {
        data: FailedValidationValueObject
        type: 'validationFailed'
      },
    SubscriptionErrorInstance
  > {
    return this.subscriptionAdapter.updateSubscriptions(input)
  }

  updateSubscriptionStatus(
    id: number,
    status: boolean
  ): PromiseResult<void, SubscriptionErrorInstance> {
    return this.subscriptionAdapter.updateSubscriptionStatus(id, status)
  }

  addSubscriptionOneTimeShippingAddress(
    input: AddShippingAddressInputValueObject
  ): PromiseResult<void, SubscriptionErrorInstance> {
    return this.subscriptionAdapter.addSubscriptionOneTimeShippingAddress(input)
  }

  updateSubscriptionShippingAddress(
    input: UpdateShippingAddressInputEntity
  ): PromiseResult<
    void | {
      data: FailedValidationValueObject
      type: 'validationFailed'
    },
    SubscriptionErrorInstance
  > {
    return this.subscriptionAdapter.updateSubscriptionOneTimeShippingAddress(
      input
    )
  }

  deleteOneTimeShippingAddress({
    id,
  }: {
    id: number
  }): PromiseResult<void, SubscriptionErrorInstance> {
    return this.subscriptionAdapter.deleteSubscriptionShippingAddress(id)
  }

  skipSubscription(id: number): PromiseResult<void, SubscriptionErrorInstance> {
    return this.subscriptionAdapter.skipSubscription(id)
  }
}
