import {
  CartService,
  getBaseUrl,
  RequestService,
  SERVICE_NAMES,
} from '@nori/app-kit'
import { Adapter, Inject } from '@nori/di'
import { resultErr, resultOk } from '@nori/result'

import { BundleErrors } from '../core/errors'

import { BundleRequestMapper } from './mapper/bundle-request.mapper'
import { BundleResponseMapper } from './mapper/bundle-response.mapper'

import type { BundleComponentEntity, CartEntity } from '@nori/app-kit'
import type { PromiseResult } from '@nori/result'
import type { BundleErrorInstance } from '../core/errors'
import type { ReplaceBundleInput } from './types/replace-bundle.input'

@Adapter()
export class BundleOptionsAdapter {
  baseUrl = getBaseUrl(SERVICE_NAMES.CATALOG_SERVICE)
  cartUrl = getBaseUrl(SERVICE_NAMES.CART_SERVICE)

  public constructor(
    @Inject() private readonly requestService: RequestService,
    @Inject() private readonly bundleRequestMapper: BundleRequestMapper,
    @Inject() private readonly bundleResponseMapper: BundleResponseMapper,
    @Inject() private readonly cartService: CartService
  ) {}

  async getBundleOptionsInCart(
    bundleCode: string
  ): PromiseResult<BundleComponentEntity[], BundleErrorInstance> {
    const input = this.bundleRequestMapper.getBundleOptionsRequest()
    const url = `${this.baseUrl}/bundles/${bundleCode}?${input}`

    try {
      const response = await this.requestService.secured.get<any>(url, {
        withCache: true,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      })

      if (response.ok) {
        return resultOk(this.bundleResponseMapper.toComponents(response.json))
      }

      return resultErr(
        new BundleErrors.UnknownError(response.status, response.json)
      )
    } catch (err: unknown) {
      return resultErr(new BundleErrors.UnexpectedError(err))
    }
  }

  async replaceBundle(
    input: ReplaceBundleInput
  ): PromiseResult<CartEntity, BundleErrorInstance> {
    const body = this.bundleRequestMapper.replaceBundleRequest(input)
    const url = `${this.cartUrl}/carts/${input.cartId}/replace_bundle`

    try {
      const response = await this.requestService.secured.patch<any>(url, {
        body,
      })

      if (response.ok) {
        return resultOk(this.cartService.toCartEntity(response.json))
      }

      return resultErr(
        new BundleErrors.ReplaceBundleUnknownError(
          response.status,
          response.json
        )
      )
    } catch (err: unknown) {
      return resultErr(new BundleErrors.ReplaceBundleUnexpectedError(err))
    }
  }
}
