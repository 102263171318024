import { observer } from '@nori/di'
import { useTranslations } from '@nori/lang-i18n'
import { Input, Radio, useDebounce } from '@nori/ui-kit'

import { useContactManageMailingContext } from '../../interface/use-contact-manage-mailing-context'

export const ContactManageMailingModalHeaderControls = observer(() => {
  const t = useTranslations('contacts.manageMailingModal')
  const { сontactManageMailingStore, contactManageMailingAction } =
    useContactManageMailingContext()

  const { onChangeValue, value } = useDebounce({
    initialValue: сontactManageMailingStore.searchQuery,
    onChangeDebounce: contactManageMailingAction.handleChangeSearchQuery,
  })

  return (
    <div className="relative mt-5 flex max-md:flex-col">
      <div className="w-2/3 max-md:w-full">
        <Input
          data-test-id={'search-opt-incontact-input'}
          type="search"
          placeholder={t('searchPlaceholder')}
          value={value}
          onChange={onChangeValue}
          hideHelperBlock
          size="small"
        />
      </div>

      <div className="flex w-1/3 items-center justify-around text-primary-900 max-md:mt-5 max-md:w-fit max-md:justify-between">
        <Radio
          value="all"
          checked={сontactManageMailingStore.dataTableType === 'opt-in'}
          onChange={() =>
            contactManageMailingAction.handleChangeDataTableType('opt-in')
          }
        >
          {t('showOptedIn')}
          {сontactManageMailingStore.dataTableType === 'opt-in' && (
            <span className="ml-2">
              ({сontactManageMailingStore.totalOptedInContacts})
            </span>
          )}
        </Radio>
        <div className="max-md:ml-5">
          <Radio
            value="all"
            checked={сontactManageMailingStore.dataTableType === 'all'}
            onChange={() =>
              contactManageMailingAction.handleChangeDataTableType('all')
            }
          >
            {t('showAllContacts')}
          </Radio>
        </div>
      </div>
    </div>
  )
})
