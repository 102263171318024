import { observer } from '@nori/di'
import { useTranslations } from '@nori/lang-i18n'
import { Divider } from '@nori/ui-kit'

import { useBundleContext } from '../interface/use-bundle.context'

import { ProductItem } from './product-item'

export const StepPageBundle = observer(() => {
  const t = useTranslations('productList.bundleModal')

  const { customizeBundleStore } = useBundleContext()

  const {
    visibleBundleComponents,
    lastStepTitle,
    title,
    currentStep,
    currentStepTitle,
  } = customizeBundleStore

  const componentTitle = title || t('selectProduct')

  return (
    <>
      <div className="w-full text-center text-sm font-medium uppercase text-primary-800">
        {t('step', { step: currentStepTitle, total: lastStepTitle })}
      </div>
      <div className="mt-2 w-full text-center text-3xl font-bold text-primary-900">
        {componentTitle}
      </div>
      <div className="mt-7">
        <Divider />
      </div>
      <div className="mt-12 flex flex-wrap justify-around overflow-auto lg:max-h-[500px]">
        {visibleBundleComponents?.[currentStep]?.products.map((product) => {
          return (
            <>
              {product.product.visible && (
                <ProductItem
                  key={`${product.product.id}-${product.style.name}`}
                  product={product}
                />
              )}
            </>
          )
        })}
      </div>
    </>
  )
})
