import {
  createAddressValidator,
  createCreditCardHolderValidator,
  HOSTED_FIELDS_VALIDATION_VALUE,
  PaymentMethodErrors,
  PaymentMethodStore,
} from '@nori/app-kit'
import { DataState, Inject, Store } from '@nori/di'

import type {
  CreateCardPaymentMethodInput,
  HostedFields,
  HostedFieldsValidationValueObject,
  PaymentMethodErrorInstance,
  PaymentMethodValueObject,
} from '@nori/app-kit'
import type { DomainError } from '@nori/errors'
import type { PayPalMethodValueObject } from '../../core'

const DEFAULT_NEW_PAYMENT_METHOD = 'paypal'
const MINIMUM_PAYMENT_METHODS = 1

@Store()
export class ConsultantPaymentMethodStore {
  constructor(
    @Inject()
    private readonly paymentMethodStore: PaymentMethodStore
  ) {}

  error?: PaymentMethodErrorInstance = undefined
  isLoading = false
  isFormOpen = false
  selectedNewPaymentMethod: PaymentMethodValueObject =
    DEFAULT_NEW_PAYMENT_METHOD
  payPalToken = new DataState<PayPalMethodValueObject, DomainError>({
    isLoading: false,
  })

  hostedFields = new DataState<HostedFields, DomainError>({ isLoading: false })

  billingAddressValidator = createAddressValidator(
    'with-required-address-and-phone'
  )
  creditCardHolderValidator = createCreditCardHolderValidator()

  hostedFieldsValidationMap: HostedFieldsValidationValueObject = {
    ...HOSTED_FIELDS_VALIDATION_VALUE,
  }

  get cardFormPayload(): Omit<CreateCardPaymentMethodInput, 'hostedFields'> {
    return {
      firstname: this.creditCardHolderValidator.values.firstName,
      lastname: this.creditCardHolderValidator.values.lastName,
      billingAddress: {
        firstName: this.creditCardHolderValidator.values.firstName,
        lastName: this.creditCardHolderValidator.values.lastName,
        address1: this.billingAddressValidator.values.address1,
        address2: this.billingAddressValidator.values.address2 || '',
        zipCode: this.billingAddressValidator.values.zipCode,
        city: this.billingAddressValidator.values.city,
        country: this.billingAddressValidator.values.country,
        state: this.billingAddressValidator.values.state,
        phoneNumber: this.billingAddressValidator.values.phone,
      },
    }
  }

  get errorTitle(): string {
    if (!this.error) return ''

    switch (true) {
      case this.error instanceof PaymentMethodErrors.GetUnknownError:
        return 'Unable to get payment methods.'

      case this.error instanceof PaymentMethodErrors.SetDefaultUnknownError:
        return 'Unable to set new default payment method.'

      case this.error instanceof PaymentMethodErrors.DeleteUnknownError:
        return 'Unable to delete payment method.'

      case this.error instanceof PaymentMethodErrors.UnexpectedGetError:
      case this.error instanceof PaymentMethodErrors.UnexpectedSetDefaultError:
      case this.error instanceof PaymentMethodErrors.UnexpectedDeleteError:
      default:
        return 'Sorry. Something went wrong.'
    }
  }

  get isMethodCanBeDeleted(): boolean {
    return (
      this.paymentMethodStore.paymentMethodsList?.length !==
      MINIMUM_PAYMENT_METHODS
    )
  }

  setSelectedNewPaymentMethod(paymentMethod: PaymentMethodValueObject): void {
    this.selectedNewPaymentMethod = paymentMethod
  }

  setHostedFieldsValidationMap(
    hostedFieldsValidationMap: HostedFieldsValidationValueObject
  ): void {
    this.hostedFieldsValidationMap = hostedFieldsValidationMap
  }

  clearHostedFields(): void {
    this.hostedFields.data?.clear('number')
    this.hostedFields.data?.clear('cvv')
    this.hostedFields.data?.clear('expirationMonth')
    this.hostedFields.data?.clear('expirationYear')
  }

  setIsFormOpen(value: boolean): void {
    this.isFormOpen = value
  }

  setIsLoading(isLoading: boolean): void {
    this.isLoading = isLoading
  }

  setError(error?: PaymentMethodErrorInstance): void {
    this.error = error
  }

  resetForm(): void {
    this.creditCardHolderValidator.reset()
    this.billingAddressValidator.reset()
    this.clearHostedFields()
    this.resetHostedFieldsValidation()
  }

  resetHostedFieldsValidation(): void {
    this.hostedFieldsValidationMap = { ...HOSTED_FIELDS_VALIDATION_VALUE }
  }
}
