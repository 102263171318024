import Link from 'next/link'

import { Icon } from '@nori/ui-kit'

export function SocialLinks() {
  return (
    <div className="flex items-center gap-8">
      <Link href="https://www.facebook.com/Norwex" target="_blank">
        <Icon name="FacebookOutline" />
      </Link>
      <Link href="https://twitter.com/norwex" target="_blank">
        <Icon name="Twitter" />
      </Link>
      <Link href="https://www.instagram.com/norwex/" target="_blank">
        <Icon name="Instagram" />
      </Link>
      <Link href="https://www.youtube.com/norwex" target="_blank">
        <Icon name="Youtube" />
      </Link>
      <Link href="https://www.pinterest.com/norwex/" target="_blank">
        <Icon name="Pinterest" />
      </Link>
    </div>
  )
}
