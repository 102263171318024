import { hookContextFactory } from '@nori/di'

import {
  BundleDetailsAction,
  StyleDetailsAction,
} from './actions/product-details'
import { ProductsAction } from './actions/products/products.action'
import { ProductsStore } from './store/products.store'

export const { useModuleContext: useProductsLoungeContext } =
  hookContextFactory({
    productsAction: ProductsAction,
    productsStore: ProductsStore,
    styleDetailsAction: StyleDetailsAction,
    bundleDetailsAction: BundleDetailsAction,
  })
