import { ContactConsultantLink } from './contact-consultant.link'
import { LiveChatLink } from './live-chat.link'

export const HelpLinks = () => {
  return (
    <>
      <div className="flex flex-col">
        <ContactConsultantLink />
        <LiveChatLink />
      </div>
    </>
  )
}
