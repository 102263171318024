import { getMoneyWithIsoCurrency } from '@nori/app-kit'
import { observer } from '@nori/di'
import { useTranslations } from '@nori/lang-i18n'
import { Button, Modal } from '@nori/ui-kit'

import type { MoneyValueObject } from '@nori/app-kit'

type Props = {
  isOpen: boolean
  isLoading: boolean
  applicableCredits?: MoneyValueObject
  onClose: () => void
  onApply: () => void
  onContinue: () => void
}

export const ApplyCreditsConfirmationModal = observer(
  ({
    isOpen,
    onClose,
    onApply,
    onContinue,
    applicableCredits,
    isLoading,
  }: Props) => {
    const t = useTranslations('cartApplyCredits')

    return (
      <Modal
        data-test-id="search-or-create-contact-modal"
        size="2xl"
        isOpen={isOpen}
        onClose={onClose}
      >
        <Modal.Header onClose={onClose} title={t('remainingRewards')} />
        <Modal.Body>
          <div className="mb-5 text-center text-primary-900 lg:mb-4">
            {t('youCanApply')}
            <b>{getMoneyWithIsoCurrency(applicableCredits)}</b>
            {t('toOrder')}
          </div>
          <div className="flex min-w-[] flex-col-reverse justify-center gap-3 lg:flex-row lg:gap-2">
            <Button onClick={onContinue} size="lg" widthType="full" isOutline>
              {t('continueCheckout')}
            </Button>

            <div className="mx-2" />
            <Button
              isLoading={isLoading}
              onClick={onApply}
              size="lg"
              widthType="full"
            >
              {t('applyCreditsAndCheckout')}
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    )
  }
)
