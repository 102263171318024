import {
  CollectionAction as CollectionActionCommon,
  CollectionByIdStore,
  CollectionStore,
} from '@nori/app-kit'
import { hookContextFactory } from '@nori/di'

import { CollectionAction } from './actions/collection.action'

export const { useModuleContext: useCollectionContext } = hookContextFactory({
  collectionActionCommon: CollectionActionCommon,
  collectionAction: CollectionAction,
  collectionStore: CollectionStore,
  collectionByIdStore: CollectionByIdStore,
})
