import { Routes } from '~/modules/routes'

import type { LinkWithChildren } from './header-link.type'

export const USER_LINKS: LinkWithChildren = {
  dataTestId: 'header-user-dropdown',
  children: [
    {
      translationKey: 'header.settings.accountSettings',
      href: Routes.settings.account.path(),
      dataTestId: 'account-settings-option',
    },
  ],
}
