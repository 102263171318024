import { UpdateNoteErrors } from '../../core/update-note.errors'

import type { UpdateNoteErrorInstance } from '../../core/update-note.errors'

export class UpdateNoteErrorsMapper {
  static toErrors(json: any, status: number): UpdateNoteErrorInstance {
    switch (status) {
      case 400:
        return new UpdateNoteErrors.BadRequest(json.errors.message)

      default:
        return new UpdateNoteErrors.UnknownError(json)
    }
  }
}
