import { ProductImage } from '@nori/app-kit'

import { AutoSaveOrderRowEditor } from './autosave-order-row-editor'
import { AutoSaveOrderRowHeader } from './autosave-order-row-header'
import { AutoSaveOrderRowInfo } from './autosave-order-row-info'
import { AutoSaveOrderRowPricing } from './autosave-order-row-pricing'
import { AutoSaveUnavailableMessage } from './autosave-unavailable-message'

import type {
  BundleDetailsEntity,
  MoneyValueObject,
  StyleDetailsEntity,
} from '@nori/app-kit'
import type { NoriDate } from '@nori/date'
import type { SubscriptionEntity } from '~/modules/subscription'

type Props = {
  subscription: SubscriptionEntity
  productInfo: BundleDetailsEntity | StyleDetailsEntity | undefined
  skuVariation?: string
  itemQty: string
  itemDate?: NoriDate
  onItemQtyChange?: (qty: string) => void
  onItemCycleIdChange?: (cycleId: number) => void
  onDateChange?: (date: NoriDate) => void
  handleSave?: () => void
  cycleName?: string
  retailPrice?: MoneyValueObject
  subscriptionPrice?: MoneyValueObject
  price?: MoneyValueObject
  isAvailable?: boolean
  isCancelSkip?: boolean
  actionsSlot: React.ReactNode
  isEditMode?: boolean
  styleName?: string
  isContactDetailsPage?: boolean
}

export const AutoSaveOrderRow = ({
  subscription,
  skuVariation,
  productInfo,
  itemQty,
  itemDate,
  onItemQtyChange,
  onItemCycleIdChange,
  onDateChange,
  handleSave,
  cycleName,
  retailPrice,
  subscriptionPrice,
  price,
  isAvailable = false,
  isEditMode = false,
  actionsSlot,
  styleName,
  isContactDetailsPage,
}: Props) => {
  const availabilityClass =
    !isAvailable || !productInfo?.canBeAutoshipped ? 'opacity-40' : ''

  return (
    <div className="flex w-full max-w-4xl flex-col gap-5 rounded border border-solid border-gray-100 bg-gray-50 px-3 py-3 lg:px-10 lg:py-4">
      <AutoSaveOrderRowHeader cycleName={cycleName} itemDate={itemDate} />
      <div className="flex flex-wrap gap-x-4">
        <AutoSaveUnavailableMessage
          isAvailable={isAvailable}
          isCanBeAutoshipped={productInfo?.canBeAutoshipped}
        />
        <div className={`${availabilityClass} max-lg:hidden`}>
          <ProductImage
            width={128}
            height={128}
            styleName="h-32 w-32"
            src={subscription.thumbnailUrl}
            alt={subscription.productName}
          />
        </div>
        <div className={`${availabilityClass} lg:hidden`}>
          <ProductImage
            width={80}
            height={80}
            styleName="h-20 w-20"
            src={subscription.thumbnailUrl}
            alt={subscription.productName}
          />
        </div>
        <div className={`${availabilityClass} flex-1`}>
          <AutoSaveOrderRowInfo
            productName={subscription.productName}
            skuCode={subscription.skuCode}
            skuVariation={skuVariation}
            itemQty={itemQty}
            styleName={styleName}
            cycleName={cycleName}
            productSubscriptions={productInfo?.productSubscriptions}
            onItemQtyChange={onItemQtyChange}
            onDateChange={onDateChange}
            onFrequencyChange={onItemCycleIdChange}
            itemDate={itemDate}
            handleSave={handleSave}
            isEditMode={isEditMode}
          />
        </div>
        <AutoSaveOrderRowPricing
          quantity={subscription.quantity}
          isEditMode={isEditMode}
          retailPrice={retailPrice}
          subscriptionPrice={subscriptionPrice}
          price={price}
          isUnavailable={!isAvailable}
          actionsSlot={actionsSlot}
          isContactDetailsPage={isContactDetailsPage}
        />
      </div>
      <div className="lg:hidden">
        {isEditMode && (
          <AutoSaveOrderRowEditor
            itemQty={itemQty}
            onItemQtyChange={onItemQtyChange}
            cycleName={cycleName}
            onFrequencyChange={onItemCycleIdChange}
            productSubscriptions={productInfo?.productSubscriptions}
            itemDate={itemDate}
            handleSave={handleSave}
            onDateChange={onDateChange}
          />
        )}
      </div>
      <div className="self-center lg:hidden lg:self-end">{actionsSlot}</div>
    </div>
  )
}
