import { getMoneyWithIsoCurrency } from '@nori/app-kit'
import { DataState, Store } from '@nori/di'

import type { MoneyValueObject } from '@nori/app-kit'
import type { UserCreditsEntity, UserCreditsErrorInstance } from '../../core'

const CURRENT_DISCOUNT = 40

const ZERO_AMOUNT = '0'

@Store()
export class CurrentUserCreditsStore {
  userCredits = new DataState<UserCreditsEntity, UserCreditsErrorInstance>()

  get accountBalanceApplicableAmount(): MoneyValueObject | undefined {
    const userCredits = this.userCredits.data
    const accountBalance = userCredits?.accountBalance

    if (!accountBalance || accountBalance < 0) return

    return {
      cents: accountBalance,
      currency: userCredits.currency,
    }
  }

  get accountBalanceAmount(): string {
    if (!this.userCredits.data) return ZERO_AMOUNT

    return getMoneyWithIsoCurrency({
      cents: this.userCredits.data.accountBalance,
      currency: this.userCredits.data.currency,
    })
  }

  get hostessDiscountPercentage(): number {
    return (
      this.userCredits.data?.hostessDiscountsHash.percentage || CURRENT_DISCOUNT
    )
  }
}
