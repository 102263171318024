import { observer } from '@nori/di'
import { Table, TableBody } from '@nori/ui-kit'

import { useContactManageMailingContext } from '../../interface/use-contact-manage-mailing-context'

import { ContactManageMailingModalTableRow } from './contact-manage-mailing-modal-table-row'

export const ContactManageMailingModalTable = observer(() => {
  const { сontactManageMailingStore } = useContactManageMailingContext()

  return (
    <Table type="fixed" widthType="min-1194" withEmpty>
      <TableBody>
        {сontactManageMailingStore.contacts?.map((contact) => (
          <ContactManageMailingModalTableRow
            contact={contact}
            key={`${contact.firstName}-${contact.id}-${contact.dsrId}`}
          />
        ))}
      </TableBody>
    </Table>
  )
})
