import { useEffect } from 'react'

import { observer } from '@nori/di'
import { Modal } from '@nori/ui-kit'

import { useBundleContext } from '../interface/use-bundle.context'

import { CustomizeBundle } from './customize-bundle'

export const CustomizeBundleModal = observer(() => {
  const { customizeBundleAction, customizeBundleStore } = useBundleContext()

  return (
    <Modal
      size="7xl"
      isOpen={customizeBundleStore.modalToggle.isOpen}
      onClose={customizeBundleAction.handleCloseModal}
    >
      <Modal.Header onClose={customizeBundleAction.handleCloseModal} />
      <Modal.Body>
        <CustomizeBundle />
      </Modal.Body>
    </Modal>
  )
})
