import { useMemo, useState } from 'react'

import { useProfileContext } from '@nori/app-kit'
import { observer } from '@nori/di'
import { useTranslations } from '@nori/lang-i18n'
import { ConfirmationModal, useToggle } from '@nori/ui-kit'
import { useContactContext } from '~/modules/contacts/interface/use-contact-context'

import { useAllAutoSaveTable } from '../../interface/use-all-auto-save-table'
import { useAutosaveContext } from '../../interface/use-autosave-context'
import { PendingOrderRow } from '../pending-order-row'

import { AllAutosaveTableRow } from './all-autosave-table-row/all-autosave-table-row'

type Props = {
  isContactDetailsPage?: boolean
}

export const AllAutosaveTable = observer(({ isContactDetailsPage }: Props) => {
  const t = useTranslations('autosaveSettings')
  const { subscriptionsStore, subscriptionsAction } = useAutosaveContext()

  const isActiveAutosaveTable =
    subscriptionsStore.subsriptionStatusTab === 'active'

  const { contactDetailsStore } = useContactContext()
  const { userStore } = useProfileContext()
  const [autosaveId, setAutosaveId] = useState<number>()
  const skipToggle = useToggle()
  const subscriptionsToShow = useMemo(
    () =>
      isActiveAutosaveTable
        ? subscriptionsStore.activeSubscriptions
        : subscriptionsStore.inactiveSubscriptions,
    [
      isActiveAutosaveTable,
      subscriptionsStore.activeSubscriptions,
      subscriptionsStore.inactiveSubscriptions,
    ]
  )

  const { modalText, modalTitle, modalConfirmText, modalCancelText } =
    useAllAutoSaveTable(skipToggle.isOpen, isActiveAutosaveTable)

  const handleSubmitModal = () => {
    if (!autosaveId) return

    if (skipToggle.isOpen) {
      subscriptionsAction.handleSkipSubscription({
        id: autosaveId,
        userId: isContactDetailsPage
          ? contactDetailsStore.contact?.customerId
          : userStore.currentUser?.id,
      })
      return
    }

    subscriptionsAction.handleUpdateStatus({
      id: autosaveId,
      userId: isContactDetailsPage
        ? contactDetailsStore.contact?.customerId
        : userStore.currentUser?.id,
      activeStatus: !isActiveAutosaveTable,
    })
  }

  return (
    <>
      {isActiveAutosaveTable && subscriptionsStore.pendingOrders.data && (
        <div className="mt-10">
          {subscriptionsStore.pendingOrders.data?.map((order) => {
            return <PendingOrderRow pendingOrder={order} key={order.orderId} />
          })}
        </div>
      )}
      <div>
        <div className={'max-xl:overflow-x-auto'}>
          {subscriptionsToShow?.map((subscription) => (
            <AllAutosaveTableRow
              key={subscription.id}
              subscription={subscription}
              isActiveAutosaveTable={isActiveAutosaveTable}
              isContactDetailsPage={isContactDetailsPage}
              onChangeStatus={(skip) => {
                if (skip === true) {
                  skipToggle.handleOpen()
                } else {
                  skipToggle.handleClose()
                }
                setAutosaveId(subscription.id)
              }}
            />
          ))}
        </div>
      </div>

      <ConfirmationModal
        title={modalTitle}
        text={modalText}
        textConfirmButton={modalConfirmText}
        textCancelButton={modalCancelText}
        onSubmit={handleSubmitModal}
        onClose={() => {
          setAutosaveId(undefined)
          skipToggle.handleClose()
        }}
        isOpen={!!autosaveId}
        buttonContainerMinWidthClass="w-80 lg:w-[392px]"
        buttonSize="lg"
        buttonWidthType="full"
      />
    </>
  )
})
