import { getFullPath } from './get-full-path';
export function cleanAll(key) {
    if (typeof window === 'undefined') return;
    const currentSearch = new URLSearchParams(window.location.search);
    if (!currentSearch.has(key)) {
        return;
    }
    currentSearch.delete(key);
    window.history.replaceState(window.history.state, '', getFullPath(currentSearch));
}
