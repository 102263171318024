export function NorwexPerksLogo() {
  return (
    <>
      <div className="lg:hidden">
        <svg
          width="255"
          height="39"
          viewBox="0 0 255 39"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M170.331 13.3375H172.161L172.194 17.1704C172.852 15.7892 173.788 14.714 175.012 13.9447C176.235 13.1753 177.612 12.7906 179.15 12.7906C180.689 12.7906 182.135 13.1707 183.359 13.9261C184.582 14.6862 185.542 15.7244 186.241 17.0499C186.941 18.3754 187.293 19.8955 187.293 21.6149C187.293 23.3344 186.927 24.7618 186.19 26.0966C185.454 27.4314 184.452 28.4881 183.187 29.2713C181.922 30.0499 180.504 30.4439 178.947 30.4439C177.477 30.4439 176.166 30.0777 175.012 29.3408C173.858 28.6039 172.954 27.5704 172.296 26.2356V38.2161H170.326V13.3375H170.331ZM182.126 27.7604C183.108 27.1487 183.882 26.3144 184.452 25.2485C185.018 24.1871 185.301 22.9729 185.301 21.6196C185.301 20.2663 185.018 19.0242 184.452 17.9721C183.887 16.9201 183.113 16.0905 182.126 15.4787C181.143 14.867 180.017 14.5611 178.747 14.5611C177.477 14.5611 176.351 14.867 175.355 15.4787C174.358 16.0905 173.58 16.9201 173.014 17.9721C172.449 19.0242 172.166 20.2385 172.166 21.6196C172.166 23.0007 172.449 24.1871 173.014 25.2485C173.58 26.3098 174.358 27.1487 175.355 27.7604C176.351 28.3722 177.482 28.6781 178.747 28.6781C180.013 28.6781 181.139 28.3722 182.126 27.7604Z"
            fill="#6D9BAA"
          />
          <path
            d="M200.368 30.4434C198.514 30.4434 196.906 30.0866 195.548 29.3729C194.19 28.6591 193.142 27.6441 192.41 26.3186C191.673 24.9931 191.307 23.4406 191.307 21.6516C191.307 19.8626 191.659 18.4074 192.359 17.068C193.059 15.7332 194.023 14.6858 195.242 13.9303C196.465 13.1749 197.856 12.7949 199.417 12.7949C200.863 12.7949 202.138 13.1239 203.236 13.7774C204.335 14.4355 205.188 15.3485 205.799 16.5257C206.411 17.7029 206.717 19.084 206.717 20.6644C206.717 20.9147 206.712 21.1557 206.698 21.3921C206.689 21.6284 206.671 21.8833 206.647 22.1568H193.244C193.355 24.2145 194.051 25.8181 195.33 26.9582C196.609 28.103 198.31 28.673 200.437 28.673C202.564 28.673 204.363 28.2096 206.105 27.2826L206.379 28.9789C205.632 29.4099 204.719 29.7575 203.649 30.031C202.574 30.3044 201.484 30.4388 200.372 30.4388L200.368 30.4434ZM195.191 16.141C194.069 17.2626 193.42 18.7503 193.24 20.6042H204.812C204.812 20.3354 204.798 20.0851 204.78 19.858C204.687 18.2081 204.145 16.8965 203.153 15.9232C202.156 14.95 200.877 14.4633 199.32 14.4633C197.763 14.4633 196.317 15.0241 195.195 16.141H195.191Z"
            fill="#6D9BAA"
          />
          <path
            d="M211.871 13.3355H213.702L213.734 17.1684C214.188 15.8799 214.888 14.8789 215.838 14.1651C216.788 13.4514 217.91 13.0945 219.198 13.0945C219.536 13.0945 219.889 13.1224 220.25 13.178C220.612 13.2336 220.885 13.3077 221.066 13.4004L220.862 15.2311C220.297 15.004 219.676 14.8928 218.994 14.8928C217.409 14.8928 216.153 15.4906 215.226 16.691C214.299 17.8914 213.836 19.532 213.836 21.613V29.895H211.866V13.3355H211.871Z"
            fill="#6D9BAA"
          />
          <path
            d="M225.173 2.40845H227.142V20.159L235.967 13.3369H238.645L229.177 20.5993L240.003 29.8963H237.223L227.142 21.2064V29.8963H225.173V2.40845Z"
            fill="#6D9BAA"
          />
          <path
            d="M253.234 29.1378C252.057 30.0091 250.519 30.4447 248.618 30.4447C247.576 30.4447 246.542 30.2964 245.513 30.0044C244.484 29.7125 243.631 29.3278 242.95 28.8504L243.256 27.0846C243.956 27.5805 244.799 27.9884 245.787 28.3082C246.769 28.6233 247.715 28.7855 248.618 28.7855C249.976 28.7855 251.047 28.5121 251.825 27.9698C252.604 27.4276 252.998 26.7046 252.998 25.7962C252.998 24.8878 252.692 24.2622 252.08 23.7755C251.469 23.2889 250.315 22.8069 248.618 22.3342C246.695 21.7919 245.332 21.1431 244.531 20.383C243.729 19.6276 243.326 18.6265 243.326 17.3798C243.326 15.9755 243.863 14.8632 244.938 14.0382C246.014 13.2133 247.446 12.8008 249.23 12.8008C250.134 12.8008 251.033 12.9213 251.927 13.1576C252.822 13.394 253.587 13.7045 254.217 14.0892L253.879 15.7855C253.22 15.3544 252.484 15.03 251.654 14.8029C250.829 14.5758 249.995 14.4646 249.161 14.4646C247.937 14.4646 246.978 14.7056 246.278 15.1922C245.578 15.6789 245.226 16.3509 245.226 17.2129C245.226 18.075 245.527 18.7099 246.125 19.1965C246.723 19.6832 247.803 20.142 249.365 20.5684C251.445 21.157 252.905 21.8336 253.744 22.603C254.583 23.3723 255 24.4012 255 25.6896C255 27.1171 254.412 28.2618 253.234 29.1331V29.1378Z"
            fill="#6D9BAA"
          />
          <path
            d="M157.66 20.206L160.562 17.3047L157.66 14.4035C156.057 12.7999 153.462 12.7999 151.858 14.4035C150.254 16.0071 150.254 18.6024 151.858 20.206C153.462 21.8096 156.057 21.8096 157.66 20.206Z"
            fill="#6D9BAA"
          />
          <path
            d="M155.259 16.686C155.259 17.1587 155.644 17.5434 156.117 17.5434C156.589 17.5434 156.974 17.1587 156.974 16.686C156.974 16.2133 156.589 15.8286 156.117 15.8286C155.644 15.8286 155.259 16.2133 155.259 16.686Z"
            fill="#6D9BAA"
          />
          <path
            d="M135.697 30.5887C140.23 34.9637 147.446 34.9267 151.918 30.4543C156.391 25.9819 156.432 18.7658 152.053 14.2332L135.692 30.5933L135.697 30.5887Z"
            fill="#6D9BAA"
          />
          <path
            d="M136.123 38.6431H127.637L136.123 30.1572V38.6431Z"
            fill="#6D9BAA"
          />
          <path
            d="M144.141 25.3652L135.688 16.9117C131.345 12.5691 131.345 5.52445 135.688 1.18182L136.869 0L144.141 7.2717C149.137 12.2678 149.137 20.3691 144.141 25.3652Z"
            fill="#6D9BAA"
          />
          <mask
            id="mask0_10314_197378"
            style={{ maskType: 'luminance' }}
            maskUnits="userSpaceOnUse"
            x="0"
            y="11"
            width="124"
            height="21"
          >
            <path
              d="M123.679 11.2618H0V31.2369H123.679V11.2618Z"
              fill="white"
            />
          </mask>
          <g mask="url(#mask0_10314_197378)">
            <path
              d="M42.573 23.1088V30.4037H43.1616C43.8104 30.4037 44.1951 30.2971 44.4917 29.9819C44.7744 29.6853 44.8718 29.3377 44.8718 28.7074V23.2247C44.8718 20.3976 45.3491 18.9933 46.7812 17.5612C48.0835 16.2589 49.803 15.6517 51.7681 15.6517V13.4178C49.0754 13.4178 46.7395 14.3818 45.1128 16.0086C43.2775 17.8485 42.5684 19.7441 42.5684 23.1042"
              fill="#4C5F7F"
            />
            <path
              d="M109.59 21.699L116.036 13.6069H114.131C113.408 13.6069 112.964 13.783 112.486 14.3809L108.13 19.8683L103.773 14.3809C103.291 13.7877 102.851 13.6116 102.128 13.6116H100.158L106.642 21.7453L99.7642 30.3749H101.669C102.397 30.3749 102.837 30.1988 103.314 29.601L108.097 23.576L112.88 29.601C113.362 30.1942 113.802 30.3749 114.525 30.3749H116.5L109.59 21.7036V21.699Z"
              fill="#4C5F7F"
            />
            <path
              d="M73.9953 13.9916C73.7126 14.2882 73.6106 14.6358 73.6106 15.2615V23.3859C73.6106 24.9385 73.1657 26.0555 72.2898 26.936C71.3443 27.8861 70.0142 28.2615 68.7072 28.2615H67.4327V13.7228H65.1571V28.2615H63.8826C62.5756 28.2615 61.2455 27.8861 60.3 26.936C59.4241 26.0555 58.9792 24.9385 58.9792 23.3859V15.2615C58.9792 14.6311 58.8772 14.2836 58.5991 13.9916C58.2979 13.6764 57.9132 13.5698 57.269 13.5698H56.6804V23.4647C56.6804 25.4483 57.2041 26.9592 58.3257 28.2105C59.7393 29.7863 61.6951 30.4583 64.2117 30.4583H68.3921C70.9087 30.4583 72.8598 29.7863 74.278 28.2105C75.4042 26.9592 75.9279 25.4437 75.9279 23.4647V13.5698H75.3347C74.6859 13.5698 74.3012 13.6764 74.0046 13.9916"
              fill="#4C5F7F"
            />
            <path
              d="M83.5343 15.8084C82.2551 17.157 81.6665 18.9321 81.6665 21.0779V22.1671C81.6665 24.8227 82.3431 26.6255 83.817 28.1225C85.4298 29.7585 87.381 30.3703 89.9207 30.3703H93.7953V30.0505C93.7953 29.0263 92.9564 28.1596 91.9275 28.1596H89.8188C88.0576 28.1596 86.6533 27.6961 85.5132 26.5653C84.577 25.6337 83.9467 24.2387 83.9467 23.0059L88.4284 23.0013C91.1674 23.0013 92.7988 22.5007 94.3468 20.8647C95.6584 19.479 96.2099 17.7317 96.2099 15.3356V13.6069H89.5592C86.848 13.6069 84.9988 14.2558 83.5296 15.8037M92.581 19.4419C91.5892 20.4476 90.5325 20.8091 88.8548 20.8091H83.9421C83.9421 19.2658 84.3499 18.0979 85.2722 17.1663C86.176 16.2487 87.2651 15.7991 88.9938 15.7991H93.9065C93.9065 17.3424 93.4987 18.5103 92.581 19.4419Z"
              fill="#4C5F7F"
            />
            <path
              d="M28.9139 13.4229C24.1866 13.4229 20.3445 17.2604 20.3445 21.9923C20.3445 26.7242 24.1866 30.5617 28.9139 30.5617C33.6412 30.5617 37.4879 26.7242 37.4879 21.9923C37.4879 17.2604 33.6504 13.4229 28.9139 13.4229ZM28.9139 28.3417C25.4565 28.3417 22.6479 25.4961 22.6479 21.9877C22.6479 18.4793 25.4565 15.6383 28.9139 15.6383C32.3713 15.6383 35.1799 18.4839 35.1799 21.9877C35.1799 25.4914 32.3759 28.3417 28.9139 28.3417Z"
              fill="#4C5F7F"
            />
            <path
              d="M6.83963 13.6146H0.47168V30.4104H1.06027C1.70912 30.4104 2.09379 30.3038 2.39041 29.9886C2.67312 29.692 2.77508 29.3444 2.77508 28.7187V15.8067H7.25675C9.10596 15.8067 10.4083 16.2146 11.4511 17.2574C12.6236 18.4299 12.9573 19.8713 12.9573 21.6046V28.7187C12.9573 29.3491 13.0593 29.6966 13.3374 29.9886C13.6386 30.3038 14.0233 30.4104 14.6675 30.4104H15.2607V21.5212C15.2607 18.9305 14.6628 17.2342 13.1427 15.7511C11.5299 14.1753 9.80115 13.6146 6.83963 13.6146Z"
              fill="#4C5F7F"
            />
            <path
              d="M123.318 26.4015C123.081 25.9983 122.757 25.6739 122.354 25.4375C121.946 25.2011 121.492 25.0806 120.982 25.0806C120.472 25.0806 120.027 25.2011 119.619 25.4375C119.211 25.6739 118.892 25.9983 118.655 26.4015C118.419 26.8047 118.298 27.2635 118.298 27.7733C118.298 28.2831 118.419 28.7281 118.655 29.1359C118.892 29.5438 119.216 29.8682 119.619 30.1045C120.027 30.3409 120.481 30.4614 120.982 30.4614C121.482 30.4614 121.95 30.3409 122.354 30.1045C122.761 29.8682 123.081 29.5438 123.318 29.1359C123.554 28.7281 123.674 28.2692 123.674 27.7733C123.674 27.2774 123.554 26.8093 123.318 26.4015ZM122.989 28.9598C122.78 29.312 122.502 29.5947 122.154 29.8079C121.802 30.0211 121.413 30.1231 120.982 30.1231C120.551 30.1231 120.157 30.0165 119.809 29.8079C119.462 29.5947 119.179 29.312 118.975 28.9598C118.766 28.6076 118.664 28.209 118.664 27.7687C118.664 27.3284 118.771 26.9345 118.975 26.5776C119.184 26.2254 119.462 25.9427 119.809 25.7295C120.161 25.5163 120.551 25.4143 120.982 25.4143C121.413 25.4143 121.807 25.5209 122.154 25.7295C122.502 25.9427 122.785 26.2254 122.989 26.5776C123.192 26.9298 123.299 27.3284 123.299 27.7687C123.299 28.209 123.197 28.6076 122.989 28.9598Z"
              fill="#4C5F7F"
            />
            <path
              d="M121.371 27.8992C121.519 27.8575 121.639 27.774 121.732 27.6443C121.825 27.5145 121.871 27.3662 121.871 27.1901C121.871 26.9491 121.792 26.7683 121.635 26.6386C121.477 26.5088 121.264 26.4439 121 26.4439H120.142V28.9512H120.481V27.9594H121.055L121.713 28.9512H122.089L121.38 27.8945L121.371 27.8992ZM121.023 27.6628H120.476V26.7313H121.023C121.171 26.7313 121.292 26.7683 121.384 26.8425C121.477 26.9166 121.523 27.0371 121.523 27.204C121.523 27.5099 121.357 27.6628 121.028 27.6628"
              fill="#4C5F7F"
            />
          </g>
        </svg>
      </div>

      <div className="hidden lg:block">
        <svg
          width="430"
          height="66"
          viewBox="0 0 430 66"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M287.224 22.4924H290.311L290.366 28.9556C291.476 26.6266 293.054 24.8135 295.118 23.5162C297.181 22.2189 299.502 21.5702 302.096 21.5702C304.691 21.5702 307.129 22.211 309.193 23.4849C311.256 24.7666 312.874 26.5172 314.054 28.7524C315.234 30.9875 315.828 33.5509 315.828 36.4504C315.828 39.3498 315.21 41.7569 313.968 44.0077C312.725 46.2584 311.037 48.0403 308.904 49.3611C306.77 50.674 304.379 51.3383 301.753 51.3383C299.275 51.3383 297.063 50.7209 295.118 49.4783C293.172 48.2357 291.648 46.4929 290.538 44.2421V64.4444H287.216V22.4924H287.224ZM307.114 46.8133C308.771 45.7817 310.076 44.375 311.037 42.5775C311.991 40.7878 312.467 38.7402 312.467 36.4582C312.467 34.1761 311.991 32.0816 311.037 30.3076C310.084 28.5335 308.778 27.1346 307.114 26.103C305.457 25.0714 303.558 24.5556 301.417 24.5556C299.275 24.5556 297.376 25.0714 295.696 26.103C294.016 27.1346 292.703 28.5335 291.749 30.3076C290.796 32.0816 290.319 34.1292 290.319 36.4582C290.319 38.7871 290.796 40.7878 291.749 42.5775C292.703 44.3672 294.016 45.7817 295.696 46.8133C297.376 47.8449 299.283 48.3607 301.417 48.3607C303.55 48.3607 305.449 47.8449 307.114 46.8133Z"
            fill="#6D9BAA"
          />
          <path
            d="M337.875 51.3373C334.749 51.3373 332.037 50.7355 329.747 49.532C327.457 48.3284 325.691 46.6169 324.456 44.3818C323.214 42.1466 322.596 39.5285 322.596 36.5118C322.596 33.4952 323.19 31.0412 324.37 28.7826C325.55 26.5318 327.176 24.7656 329.231 23.4917C331.295 22.2178 333.639 21.577 336.273 21.577C338.711 21.577 340.86 22.1318 342.713 23.2338C344.565 24.3435 346.003 25.8831 347.034 27.8682C348.066 29.8533 348.582 32.1822 348.582 34.8472C348.582 35.2692 348.574 35.6756 348.551 36.0742C348.535 36.4728 348.504 36.9026 348.465 37.3637H325.863C326.051 40.8337 327.223 43.5377 329.38 45.4603C331.537 47.3906 334.405 48.3519 337.992 48.3519C341.579 48.3519 344.612 47.5704 347.55 46.0073L348.011 48.8677C346.753 49.5945 345.213 50.1806 343.408 50.6417C341.595 51.1028 339.758 51.3295 337.883 51.3295L337.875 51.3373ZM329.145 27.2195C327.254 29.1108 326.16 31.6195 325.855 34.7456H345.37C345.37 34.2923 345.346 33.8703 345.315 33.4873C345.159 30.7051 344.244 28.4934 342.572 26.8522C340.892 25.211 338.735 24.3904 336.109 24.3904C333.483 24.3904 331.044 25.3361 329.153 27.2195H329.145Z"
            fill="#6D9BAA"
          />
          <path
            d="M357.272 22.4884H360.359L360.414 28.9516C361.18 26.779 362.36 25.0909 363.962 23.8873C365.564 22.6838 367.455 22.082 369.628 22.082C370.199 22.082 370.793 22.1289 371.402 22.2227C372.012 22.3165 372.473 22.4415 372.778 22.5978L372.434 25.6848C371.48 25.3019 370.433 25.1143 369.284 25.1143C366.611 25.1143 364.493 26.1225 362.93 28.1466C361.367 30.1708 360.586 32.9374 360.586 36.4464V50.4122H357.264V22.4884H357.272Z"
            fill="#6D9BAA"
          />
          <path
            d="M379.702 4.06311H383.024V33.9954L397.904 22.4914H402.421L386.455 34.7378L404.711 50.4151H400.022L383.024 35.7616V50.4151H379.702V4.06311Z"
            fill="#6D9BAA"
          />
          <path
            d="M427.023 49.1352C425.038 50.6045 422.443 51.3391 419.239 51.3391C417.48 51.3391 415.737 51.089 414.002 50.5967C412.268 50.1043 410.83 49.4557 409.681 48.6507L410.196 45.6731C411.377 46.5093 412.799 47.1971 414.464 47.7363C416.12 48.2677 417.715 48.5413 419.239 48.5413C421.529 48.5413 423.334 48.0802 424.647 47.1658C425.96 46.2514 426.624 45.0322 426.624 43.5005C426.624 41.9687 426.108 40.9136 425.077 40.093C424.045 39.2724 422.099 38.4597 419.239 37.6625C415.995 36.7481 413.698 35.654 412.346 34.3723C410.994 33.0984 410.314 31.4103 410.314 29.308C410.314 26.94 411.22 25.0644 413.033 23.6733C414.847 22.2822 417.261 21.5866 420.27 21.5866C421.794 21.5866 423.31 21.7898 424.819 22.1884C426.327 22.587 427.617 23.1106 428.679 23.7592L428.109 26.6196C426.999 25.8928 425.757 25.3457 424.358 24.9628C422.967 24.5798 421.56 24.3923 420.153 24.3923C418.09 24.3923 416.472 24.7987 415.292 25.6193C414.112 26.4399 413.518 27.5731 413.518 29.0267C413.518 30.4803 414.026 31.551 415.034 32.3716C416.042 33.1922 417.863 33.9659 420.497 34.6849C424.006 35.6774 426.468 36.8185 427.882 38.1158C429.297 39.4131 430 41.1481 430 43.3207C430 45.7278 429.008 47.6582 427.023 49.1274V49.1352Z"
            fill="#6D9BAA"
          />
          <path
            d="M265.857 34.0724L270.75 29.1801L265.857 24.2878C263.153 21.5837 258.777 21.5837 256.073 24.2878C253.369 26.9918 253.369 31.3683 256.073 34.0724C258.777 36.7765 263.153 36.7765 265.857 34.0724Z"
            fill="#6D9BAA"
          />
          <path
            d="M261.808 28.136C261.808 28.9332 262.457 29.5818 263.254 29.5818C264.051 29.5818 264.7 28.9332 264.7 28.136C264.7 27.3388 264.051 26.6902 263.254 26.6902C262.457 26.6902 261.808 27.3388 261.808 28.136Z"
            fill="#6D9BAA"
          />
          <path
            d="M228.821 51.5812C236.464 58.9588 248.633 58.8963 256.174 51.3546C263.716 43.8129 263.786 31.6446 256.401 24.0013L228.813 51.589L228.821 51.5812Z"
            fill="#6D9BAA"
          />
          <path
            d="M229.539 65.1637H215.229L229.539 50.8541V65.1637Z"
            fill="#6D9BAA"
          />
          <path
            d="M243.06 42.7726L228.805 28.5177C221.482 21.1949 221.482 9.31573 228.805 1.99288L230.798 0L243.06 12.2621C251.485 20.6869 251.485 34.3478 243.06 42.7726Z"
            fill="#6D9BAA"
          />
          <mask
            id="mask0_10314_197086"
            style={{ maskType: 'luminance' }}
            maskUnits="userSpaceOnUse"
            x="-1"
            y="18"
            width="210"
            height="35"
          >
            <path
              d="M208.557 18.9913H-0.00012207V52.6749H208.557V18.9913Z"
              fill="white"
            />
          </mask>
          <g mask="url(#mask0_10314_197086)">
            <path
              d="M71.79 38.9683V51.2695H72.7826C73.8767 51.2695 74.5254 51.0897 75.0255 50.5583C75.5023 50.0581 75.6664 49.472 75.6664 48.4091V39.1637C75.6664 34.3964 76.4714 32.0284 78.8863 29.6135C81.0823 27.4174 83.9818 26.3936 87.2954 26.3936V22.6267C82.7548 22.6267 78.8159 24.2523 76.0728 26.9954C72.978 30.0981 71.7822 33.2945 71.7822 38.9605"
              fill="#4C5F7F"
            />
            <path
              d="M184.798 36.5904L195.669 22.945H192.457C191.238 22.945 190.487 23.242 189.683 24.2501L182.336 33.5034L174.99 24.2501C174.177 23.2498 173.435 22.9528 172.215 22.9528H168.894L179.828 36.6685L168.23 51.2204H171.442C172.669 51.2204 173.411 50.9235 174.216 49.9153L182.281 39.7555L190.347 49.9153C191.16 50.9156 191.902 51.2204 193.121 51.2204H196.45L184.798 36.5982V36.5904Z"
              fill="#4C5F7F"
            />
            <path
              d="M124.776 23.5933C124.3 24.0935 124.128 24.6796 124.128 25.7347V39.4348C124.128 42.0529 123.378 43.9363 121.9 45.4212C120.306 47.0233 118.063 47.6564 115.859 47.6564H113.71V23.14H109.873V47.6564H107.724C105.52 47.6564 103.277 47.0233 101.683 45.4212C100.205 43.9363 99.4552 42.0529 99.4552 39.4348V25.7347C99.4552 24.6718 99.2833 24.0857 98.8144 23.5933C98.3064 23.0619 97.6577 22.8821 96.5714 22.8821H95.5789V39.5676C95.5789 42.9125 96.462 45.4603 98.3532 47.5704C100.737 50.2276 104.035 51.3608 108.279 51.3608H115.328C119.572 51.3608 122.862 50.2276 125.253 47.5704C127.152 45.4603 128.035 42.9047 128.035 39.5676V22.8821H127.035C125.941 22.8821 125.292 23.0619 124.792 23.5933"
              fill="#4C5F7F"
            />
            <path
              d="M140.861 26.6572C138.704 28.9315 137.712 31.9247 137.712 35.5431V37.3797C137.712 41.8578 138.853 44.8979 141.338 47.4223C144.058 50.181 147.348 51.2126 151.631 51.2126H158.164V50.6734C158.164 48.9462 156.75 47.4848 155.015 47.4848H151.459C148.489 47.4848 146.121 46.7033 144.199 44.7963C142.62 43.2255 141.557 40.8731 141.557 38.7943L149.114 38.7864C153.733 38.7864 156.484 37.9424 159.094 35.1836C161.306 32.8469 162.236 29.9005 162.236 25.8601V22.945H151.021C146.449 22.945 143.331 24.0391 140.854 26.6494M156.117 32.7844C154.444 34.4803 152.662 35.0898 149.833 35.0898H141.549C141.549 32.4874 142.237 30.5179 143.792 28.9471C145.316 27.3997 147.153 26.6416 150.068 26.6416H158.352C158.352 29.2441 157.664 31.2135 156.117 32.7844Z"
              fill="#4C5F7F"
            />
            <path
              d="M48.757 22.6354C40.7855 22.6354 34.3066 29.1064 34.3066 37.0857C34.3066 45.065 40.7855 51.536 48.757 51.536C56.7285 51.536 63.2151 45.065 63.2151 37.0857C63.2151 29.1064 56.7441 22.6354 48.757 22.6354ZM48.757 47.7925C42.9268 47.7925 38.1908 42.994 38.1908 37.0779C38.1908 31.1618 42.9268 26.371 48.757 26.371C54.5871 26.371 59.3231 31.1696 59.3231 37.0779C59.3231 42.9862 54.5949 47.7925 48.757 47.7925Z"
              fill="#4C5F7F"
            />
            <path
              d="M11.5331 22.9576H0.795044V51.2799H1.78757C2.8817 51.2799 3.53037 51.1002 4.03054 50.5687C4.50727 50.0685 4.67921 49.4824 4.67921 48.4274V26.6542H12.2365C15.3548 26.6542 17.5509 27.3419 19.3093 29.1003C21.2865 31.0776 21.8492 33.5081 21.8492 36.431V48.4274C21.8492 49.4902 22.0212 50.0764 22.4901 50.5687C22.9981 51.1002 23.6467 51.2799 24.733 51.2799H25.7334V36.2903C25.7334 31.9216 24.7252 29.0613 22.1618 26.5604C19.4421 23.9032 16.5271 22.9576 11.5331 22.9576Z"
              fill="#4C5F7F"
            />
            <path
              d="M207.947 44.5207C207.548 43.8407 207.001 43.2937 206.321 42.8951C205.633 42.4965 204.868 42.2933 204.008 42.2933C203.148 42.2933 202.398 42.4965 201.71 42.8951C201.023 43.2937 200.483 43.8407 200.085 44.5207C199.686 45.2006 199.483 45.9743 199.483 46.834C199.483 47.6936 199.686 48.4439 200.085 49.1316C200.483 49.8194 201.03 50.3664 201.71 50.765C202.398 51.1636 203.164 51.3668 204.008 51.3668C204.852 51.3668 205.641 51.1636 206.321 50.765C207.009 50.3664 207.548 49.8194 207.947 49.1316C208.345 48.4439 208.549 47.6702 208.549 46.834C208.549 45.9977 208.345 45.2084 207.947 44.5207ZM207.392 48.8347C207.04 49.4286 206.571 49.9054 205.985 50.2649C205.391 50.6244 204.735 50.7963 204.008 50.7963C203.281 50.7963 202.617 50.6165 202.031 50.2649C201.445 49.9054 200.968 49.4286 200.624 48.8347C200.272 48.2407 200.1 47.5686 200.1 46.8262C200.1 46.0837 200.28 45.4194 200.624 44.8176C200.976 44.2237 201.445 43.747 202.031 43.3875C202.625 43.028 203.281 42.856 204.008 42.856C204.735 42.856 205.399 43.0358 205.985 43.3875C206.571 43.747 207.048 44.2237 207.392 44.8176C207.736 45.4116 207.916 46.0837 207.916 46.8262C207.916 47.5686 207.744 48.2407 207.392 48.8347Z"
              fill="#4C5F7F"
            />
            <path
              d="M204.664 47.0472C204.914 46.9769 205.117 46.8362 205.273 46.6174C205.43 46.3986 205.508 46.1485 205.508 45.8515C205.508 45.4451 205.375 45.1403 205.109 44.9215C204.844 44.7027 204.484 44.5933 204.039 44.5933H202.593V48.8213H203.163V47.1488H204.132L205.242 48.8213H205.875L204.679 47.0394L204.664 47.0472ZM204.078 46.6487H203.155V45.0778H204.078C204.328 45.0778 204.531 45.1403 204.687 45.2654C204.844 45.3904 204.922 45.5936 204.922 45.875C204.922 46.3908 204.64 46.6487 204.085 46.6487"
              fill="#4C5F7F"
            />
          </g>
        </svg>
      </div>
    </>
  )
}
