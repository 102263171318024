import { PaymentMethodStore } from '@nori/app-kit'
import { Action, Inject } from '@nori/di'

import { BillingFormStore } from '../store/billing-form.store'

import type {
  CreditCardPaymentMethodEntity,
  PaymentMethodValueObject,
} from '@nori/app-kit'
import type {
  CreditCardModeValueObject,
  CreditCardPriorityValueObject,
  SecondBillingAddressVariantValueObject,
} from '../../core'

@Action()
export class BillingFormAction {
  constructor(
    @Inject() private readonly billingFormStore: BillingFormStore,
    @Inject() private readonly paymentMethodStore: PaymentMethodStore
  ) {}

  handleSelectPaymentMethod(paymentMethod: PaymentMethodValueObject): void {
    this.billingFormStore.setSelectedPaymentMethod(paymentMethod)
  }

  handleSetMainCreditCardMode(mode: CreditCardModeValueObject): void {
    if (mode === 'saved') {
      if (!this.paymentMethodStore.creditCards?.length) return
      this.billingFormStore.setCreditCardMode('second', undefined)
    }

    this.billingFormStore.setCreditCardMode('main', mode)
  }

  handleSetSecondCreditCardMode(mode?: CreditCardModeValueObject): void {
    if (mode === 'saved' && !this.paymentMethodStore.creditCards?.length) {
      this.billingFormStore.setCreditCardMode('second', 'new')
      return
    }

    this.billingFormStore.setCreditCardMode('second', mode)
  }

  handleSelectCreditCard(
    card: CreditCardPriorityValueObject,
    paymentMethod: CreditCardPaymentMethodEntity
  ): void {
    this.billingFormStore.selectedCardMap[card].setError(undefined)
    this.billingFormStore.selectedCardMap[card].setData(paymentMethod)
  }

  handleSetSaveToProfile(
    card: CreditCardPriorityValueObject,
    value: boolean
  ): void {
    this.billingFormStore.setSaveToProfile(card, value)
  }

  handleSetBillingAddressSameAsShipping(value: boolean): void {
    this.billingFormStore.setIsBillingAddressSameAsShipping(value)
  }

  handleSetSecondBillingAddressVariant(
    variant: SecondBillingAddressVariantValueObject
  ): void {
    this.billingFormStore.setSecondBillingAddressVariant(variant)
  }
}
