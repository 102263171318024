import { useMemo, useState } from 'react';
export function useTable({ skin , withEmpty  }) {
    const [totalColumns, setTotalColumns] = useState(0);
    const context = useMemo(()=>{
        const res = {
            skin,
            totalColumns,
            withEmpty: !!withEmpty,
            setTotalColumns
        };
        return res;
    }, [
        skin,
        withEmpty,
        totalColumns
    ]);
    return {
        context
    };
}
