import { Action, Inject, observe } from '@nori/di'
import { CartStore } from '~/modules/cart/interface/store/cart-store/cart.store'

import { PennyCheckoutSummaryService } from '../services/penny-checkout-summary.service'

import type { Lambda } from '@nori/di'

@Action()
export class PennyCheckoutSummaryAction {
  private pennyCheckoutSummaryDispose?: Lambda = undefined

  constructor(
    @Inject() private readonly cartStore: CartStore,
    @Inject()
    private readonly pennyCheckoutSummaryService: PennyCheckoutSummaryService
  ) {}

  handleSubscribe(): void {
    this.pennyCheckoutSummaryDispose?.()

    this.pennyCheckoutSummaryService.loadMessages()

    this.pennyCheckoutSummaryDispose = observe(this.cartStore, 'cart', () => {
      this.pennyCheckoutSummaryService.loadMessages()
    })
  }

  handleUnsubscribe(): void {
    this.pennyCheckoutSummaryDispose?.()
    this.pennyCheckoutSummaryDispose = undefined
    this.pennyCheckoutSummaryService.loadMessages()
  }
}
