import { ProductCartErrors } from '../../core/product-cart.errors'

import type { ProductCartErrorsInstance } from '../../core/product-cart.errors'

export class ProductCartErrorMapper {
  static toErrors(status: number, json: any): ProductCartErrorsInstance {
    switch (status) {
      case 422: {
        return new ProductCartErrors.SwitchUnprocessableEntity(json.errors)
      }

      default:
        return new ProductCartErrors.SwitchBadRequest(status, json)
    }
  }
}
