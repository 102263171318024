import { useState } from 'react'

import { getMoneyWithIsoCurrency, ProductImage } from '@nori/app-kit'
import { useTranslations } from '@nori/lang-i18n'
import { Icon, Swap, TableCell } from '@nori/ui-kit'

import { SkusDropdown } from '../skus-dropdown'

import { Row } from './row'

import type { CommonStyleEntity } from '@nori/app-kit'

type Props = {
  item: CommonStyleEntity
}
const CUSTOMER_PRICE_RATIO = 0.9

export function StyleRow({ item }: Props) {
  const t = useTranslations()
  const [sku, setSku] = useState(item.skus?.[0])

  return (
    <Row item={item}>
      <TableCell noBorder>
        <div className="flex items-center">
          <ProductImage
            alt="No Image Available"
            width={80}
            height={80}
            styleName="mr-3 h-20 w-20"
            src={item.pictures[0]?.thumbnailMedURL}
          />
          <div className="flex-col gap-1">
            <div className="text-base font-bold text-gray-700">
              {item.product.name}
            </div>
            <SkusDropdown sku={sku} skus={item.skus} onChange={setSku} />
            <div className="text-sm font-medium text-gray-500">
              {item.maxPriceSku.code}
            </div>
          </div>
        </div>
      </TableCell>

      <TableCell noBorder>
        <Swap
          is={!item.canBeAutoShipped}
          isSlot={
            <div className="text-sm font-normal text-primary-900">
              {t('autosaveSettings.addItemModal.notForAutoship')}
            </div>
          }
        >
          <Swap is={!sku} isSlot={<div className="text-black">-</div>}>
            <div className="flex">
              <div className="text-base font-normal text-primary-900">
                {getMoneyWithIsoCurrency({
                  cents: Math.round(
                    sku!.currentRetailPrice.cents * CUSTOMER_PRICE_RATIO
                  ),
                  currency: sku!.currentRetailPrice.currency,
                })}
              </div>
              <div className="ml-2">
                <Icon name="AutoShip" />
              </div>
            </div>
          </Swap>
        </Swap>
      </TableCell>

      <TableCell noBorder>
        <Swap is={!sku} isSlot={<div className="text-black">-</div>}>
          {sku && (
            <div className="text-base font-normal text-primary-900">
              {getMoneyWithIsoCurrency({
                cents: sku.currentRetailPrice.cents,
                currency: sku.currentRetailPrice.currency,
              })}
            </div>
          )}
        </Swap>
      </TableCell>
    </Row>
  )
}
