import { observer } from '@nori/di'
import { Footer } from '~/modules/footer'
import { Header } from '~/modules/header'
import { useHeaderContext } from '~/modules/header/interface/use-header-context'
import { MobileHeaderMenu } from '~/modules/header/ui/authenticated-header/mobile/menu'

import type { ReactNode } from 'react'

type Props = {
  children: ReactNode
}

export const PageLayout = observer(({ children }: Props) => {
  const { headerStore } = useHeaderContext()

  return (
    <div
      className={`flex min-h-screen flex-col ${
        (headerStore.isMenuVisible || headerStore.isUserMenuVisible) &&
        'h-screen overflow-hidden'
      }  `}
    >
      <div className="sticky top-0 z-40">
        <Header />
        {headerStore.isMenuVisible && <MobileHeaderMenu />}
      </div>
      <div className="flex-1">{children}</div>
      <div>
        <Footer />
      </div>
    </div>
  )
})
