import type { FindOrCreateContactInput } from '../types/find-or-create/find-or-create-contact.input'
import type { FindOrCreateContactRequest } from '../types/find-or-create/find-or-create-contact.request'

export class FindOrCreateContactRequestMapper {
  static toRequestBody(
    input: FindOrCreateContactInput
  ): FindOrCreateContactRequest {
    return {
      address_attributes: {
        address1: input.address1,
        address2: input.address2,
        country: input.country,
        city: input.city,
        state: input.state,
        zip_code: input.zipCode,
      },
      first_name: input.firstName,
      last_name: input.lastName,
      email: input.isEmailProvided ? input.email : undefined,
      phone: input.phone,
      email_provided: input.isEmailProvided,
    }
  }
}
