import { CommonBundleEntity } from '@nori/app-kit'
import { format } from '@nori/date'
import { Action, Inject } from '@nori/di'
import { getBlock } from '@nori/lang-i18n'
import { NotifyAction } from '@nori/notify'
import { isErr } from '@nori/result'
import { createLogger } from '~/infra/create-logger'
import { NextOrderStore } from '~/modules/autosave/interface/store/next-order.store'
import { SearchProductsService } from '~/modules/search-products'
import { SubscriptionService } from '~/modules/subscription'

import { NextOrderAddItemStore } from '../store/next-order-add-item.store'

import { NextOrderAction } from './next-order.action'

import type { AddSubscriptionInput } from '~/modules/subscription'
import type { AutoSaveAddItemInput } from '../../core/autosave-add-item.input'

const MIN_SEARCH_QUERY_LENGTH = 3

const logger = createLogger('next-order-add-item.action')

@Action()
export class NextOrderAddItemAction {
  constructor(
    @Inject() private readonly searchProductsService: SearchProductsService,
    @Inject() private readonly addItemStore: NextOrderAddItemStore,
    @Inject() private readonly subscriptionService: SubscriptionService,
    @Inject() private readonly nextOrderStore: NextOrderStore,
    @Inject() private readonly nextOrderAction: NextOrderAction,
    @Inject() private readonly notifyAction: NotifyAction
  ) {}

  async handleSearchQuery(query: string): Promise<void> {
    const searchQuery = query.trim()
    if (searchQuery === this.addItemStore.searchQuery) return

    this.addItemStore.setSearchQuery(searchQuery)

    if (searchQuery.length < MIN_SEARCH_QUERY_LENGTH) {
      if (!searchQuery.length) this.addItemStore.setProducts(undefined)
      return
    }

    this.addItemStore.setIsLoading(true)

    await this.loadProducts()

    this.addItemStore.setIsLoading(false)
  }

  async handleLoadMore(): Promise<void> {
    if (!this.addItemStore.hasMore) return
    if (this.addItemStore.isLoadingMore) return

    this.addItemStore.setIsLoadingMore(true)

    await this.loadProducts(this.addItemStore.page + 1)

    this.addItemStore.setIsLoadingMore(false)
  }

  async handleAddItem(addData: AutoSaveAddItemInput): Promise<void> {
    const { item } = addData
    const isLoading = this.addItemStore.isLoadingById[item.id]
    if (isLoading) return

    this.addItemStore.setIsItemLoading(item.id, true)

    await this.addItem(addData)

    this.addItemStore.setIsItemLoading(item.id, false)
  }

  private async addItem(addData: AutoSaveAddItemInput): Promise<void> {
    const result = await this.subscriptionService.addSubscription(
      this.getAddItemInput(addData)
    )

    if (isErr(result)) {
      this.notifyAction.handleAddNotify({
        title: getBlock('autosaveSettings.errors')('addItemError'),
        type: 'danger',
      })
      return
    }

    this.notifyAction.handleAddNotify({
      title: getBlock('autosaveSettings')('addItemSuccess'),
      type: 'success',
    })
    this.nextOrderAction.handleInitialLoad()
  }

  private getAddItemInput(addData: AutoSaveAddItemInput): AddSubscriptionInput {
    const { oneOff, item, nextCartAt } = addData

    let { cycleId } = addData

    cycleId = oneOff ? 0 : cycleId

    const base = {
      oneOff,
      quantity: 1,
      cycleId,
      nextCartAt: nextCartAt?.formatInTZ('yyyy-MM-dd'),
    }

    switch (item.typeCode) {
      case 'bundle':
        return {
          ...base,
          bundleCode: item.code,
          skuIds: item.components?.flatMap((component) =>
            component.products.flatMap((product) =>
              product.skus.map((sku) => sku.id)
            )
          ),
        }
      case 'style':
        return {
          ...base,
          skuId: item.skus?.[0]?.id ?? item.id,
        }
      default:
        return base
    }
  }

  private async loadProducts(page = 1): Promise<void> {
    this.addItemStore.setError(undefined)

    const result = await this.searchProductsService.searchProducts({
      searchQuery: this.addItemStore.searchQuery,
      pagination: {
        page,
        perPage: this.addItemStore.perPage,
      },
      isCustomerSearch: false,
      noShippingRestricted: true,
    })

    if (isErr(result)) {
      logger.error(result.error.key, result.error)
      this.addItemStore.setError(result.error)
      return
    }

    this.addItemStore.setProducts({
      filters: result.data.filters,
      pagination: result.data.pagination,
      items:
        result.data.pagination.page > this.addItemStore.initialPage
          ? [...(this.addItemStore.products?.items ?? []), ...result.data.items]
          : result.data.items,
    })
  }

  handleOpen(): void {
    this.addItemStore.setIsOpen(true)
  }

  handleClose(): void {
    this.addItemStore.setSearchQuery('')
    this.addItemStore.setProducts(undefined)
    this.addItemStore.setIsOpen(false)
    this.nextOrderStore.resetNextOrderDateHashMap()
  }
}
