import { hookContextFactory } from '@nori/di'

import { PaymentMethodAction } from './action/payment-method.action'
import { ConsultantPaymentMethodStore } from './store/consultant-payment-method.store'

export const { useModuleContext: usePaymentMethodsContext } =
  hookContextFactory({
    consultantPaymentMethodStore: ConsultantPaymentMethodStore,
    paymentMethodAction: PaymentMethodAction,
  })
