import type { CartProductSubscriptionValueObject } from '../../core/cart-product-subscription.value-object'
import type productSubscriptionMock from '../mock/product-subsrcription.json'

export class ProductSubscriptionMapper {
  static toValueObject(
    data: typeof productSubscriptionMock
  ): CartProductSubscriptionValueObject {
    return {
      canBeAutoshipped: data.can_be_autoshipped,
      subscriptions: data.subscriptions.map((subscription) => ({
        id: subscription.id,
        name: subscription.name,
        planCycle: subscription.plan_cycle,
        planCycleUnit: subscription.plan_cycle_unit,
      })),
      defaultSubscription: data.default_subscription,
    }
  }
}
