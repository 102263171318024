import { getMoneyWithIsoCurrency, type MoneyValueObject } from '@nori/app-kit'
import { useTranslations } from '@nori/lang-i18n'

type Props = {
  retailPrice?: MoneyValueObject
  subscriptionPrice?: MoneyValueObject
  price?: MoneyValueObject
  isEditMode: boolean
  handleEditMode?: (value: boolean) => void
  handleChangeStatus?: () => void
  isUnavailable: boolean
  actionsSlot: React.ReactNode
  isContactDetailsPage?: boolean
  quantity?: number
}

export const AutoSaveOrderRowPricing = ({
  retailPrice,
  subscriptionPrice,
  price,
  isUnavailable,
  actionsSlot,
  isEditMode,
  isContactDetailsPage,
  quantity,
}: Props) => {
  const t = useTranslations('autosaveSettings')
  const availabilityClass = isUnavailable ? 'opacity-40' : ''

  const priceLabelClass = 'text-xs font-normal text-gray-500 lg:text-sm'
  const priceClass =
    'text-sm font-medium text-gray-500 line-through lg:text-base'

  const actionsSlotClass = isEditMode ? 'mb-10' : ''

  const highlightedPriceLabelClass =
    'lg;text-gray-600 text-xs font-normal text-gray-500 lg:text-sm'
  const highlightedPriceClass = 'text-sm font-semibold text-gray-600 lg:text-xl'

  const subscriptionPriceLabelClass = price
    ? priceLabelClass
    : highlightedPriceLabelClass
  const subscriptionPriceClass =
    price && !isContactDetailsPage ? priceClass : highlightedPriceClass

  const retailPriceLabelClass = subscriptionPrice
    ? priceLabelClass
    : highlightedPriceLabelClass
  const retailPriceClass = subscriptionPrice
    ? priceClass
    : highlightedPriceClass

  return (
    <div className="flex flex-col items-end justify-between">
      <div
        className={`${availabilityClass} flex flex-col gap-1 max-lg:items-baseline lg:mb-6 lg:items-end`}
      >
        <div className="max-lg:flex max-lg:flex-col">
          <span className={retailPriceLabelClass}>{t('retailPrice')}</span>{' '}
          <span className={retailPriceClass}>
            {getMoneyWithIsoCurrency(retailPrice, {
              quantity,
            })}
          </span>
        </div>
        {subscriptionPrice && (
          <div className="max-lg:flex max-lg:flex-col">
            <span className={subscriptionPriceLabelClass}>
              {t('subscriptionPrice')}
            </span>{' '}
            <span className={subscriptionPriceClass}>
              {getMoneyWithIsoCurrency(subscriptionPrice, {
                quantity,
              })}
            </span>
          </div>
        )}
        {price && !isContactDetailsPage && (
          <div className="max-lg:flex max-lg:flex-col">
            <span className={highlightedPriceLabelClass}>
              {t('consultantPrice')}
            </span>{' '}
            <span className={highlightedPriceClass}>
              {getMoneyWithIsoCurrency(price, {
                quantity,
              })}
            </span>
          </div>
        )}
      </div>
      <div>
        <div className={`${actionsSlotClass} max-lg:hidden`}>{actionsSlot}</div>
      </div>
    </div>
  )
}
