import { BarNav } from './bar-nav'
import { BarTop } from './bar-top'

export function DesktopAuthenticatedHeader() {
  return (
    <div>
      <div className="h-20 w-full bg-primary-900 ">
        <BarTop />
      </div>

      <div className="h-14 border-b bg-white ">
        <BarNav />
      </div>
    </div>
  )
}
