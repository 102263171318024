import { useProfileContext } from '@nori/app-kit'
import { observer } from '@nori/di'
import { useTranslations } from '@nori/lang-i18n'
import { Icon, useToggle } from '@nori/ui-kit'
import { useAutosaveContext } from '~/modules/autosave/interface/use-autosave-context'
import { useContactContext } from '~/modules/contacts/interface/use-contact-context'
import { IntlDatePickerTZ } from '~/modules/i18n/ui/intl-date-picker-tz'

import { NextOrderDateTooltip } from './next-order-date-tooltip'

type Props = {
  isContactDetailsPage?: boolean
}

export const AutosavePanelNextOrderDate = observer(
  ({ isContactDetailsPage }: Props) => {
    const t = useTranslations('autosaveSettings')
    const { nextOrderStore, nextOrderAction } = useAutosaveContext()
    const { contactDetailsStore } = useContactContext()
    const { userStore } = useProfileContext()

    const { nextCartAt, lastDayToEdit } = nextOrderStore

    const userId = isContactDetailsPage
      ? contactDetailsStore.contact?.customerId
      : userStore.currentUser?.id

    const calendarToggle = useToggle()

    if (!nextCartAt) return null

    return (
      <div className="flex w-full flex-col flex-wrap justify-between lg:flex-row">
        <div className="flex items-center">
          <span className="mr-1 text-xl font-medium text-white lg:text-2xl">
            {nextCartAt.formatInTZ('EEE, MMM d')}
          </span>
          <NextOrderDateTooltip />
          <div className="mx-3 h-full w-[2px] bg-primary-500" />
          <IntlDatePickerTZ
            value={nextCartAt}
            isOpen={calendarToggle.isOpen}
            onClose={calendarToggle.handleClose}
            minDate={nextOrderStore.autoSaveMinDate}
            maxDate={nextOrderStore.autoSaveMaxDate}
            withClear={false}
            withToday={false}
            onChange={(date) =>
              nextOrderAction.handleNextOrderDateChange({ dates: date, userId })
            }
          >
            <button
              className="flex min-w-32 items-center text-xs font-normal text-white lg:text-base"
              onClick={calendarToggle.handleToggle}
            >
              <span className="mr-1 cursor-pointer underline">
                {t('changeDate')}
              </span>
              <span className="lg:hidden">
                <Icon name="PencilAlt" size="s" />
              </span>
              <span className="max-lg:hidden">
                <Icon name="PencilAlt" />
              </span>
            </button>
          </IntlDatePickerTZ>
        </div>
        <span className="flex items-center text-xs font-normal text-white max-lg:mt-2 lg:text-sm">
          {t('nextOrderTable.lastDayToEdit', { date: lastDayToEdit })}
        </span>
      </div>
    )
  }
)
