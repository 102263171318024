import type {
  CommonBundleEntity,
  CommonProductListValueObject,
  CommonStyleArrayEntity,
  CommonStyleEntity,
} from '@nori/app-kit'

export type ProductListItemStyleArray =
  CommonStyleArrayEntity<CommonStyleEntity>

export type ProductListItem =
  | CommonBundleEntity
  | CommonStyleEntity
  | ProductListItemStyleArray

export type ProductListValueObject =
  CommonProductListValueObject<ProductListItem>

export function checkIsProductArray(
  product: ProductListItem
): product is ProductListItemStyleArray {
  return Array.isArray(product)
}
