import { Inject, Service } from '@nori/di'
import { isErr } from '@nori/result'
import { createLogger } from '~/infra/create-logger'
import { LoadCartService } from '~/modules/cart/interface/services/create-cart.service'
import { CartStore } from '~/modules/cart/interface/store/cart-store/cart.store'

import { CartContactAdapter } from '../../infra/cart-contact.adapter'
import { CartPartyAdapter } from '../../infra/cart-party.adapter'
import { CartContactPartyStore } from '../store/cart-contact-party.store'

import type { ContactEntity } from '~/modules/contacts'

const logger = createLogger('contact-and-party.service')

@Service()
export class ContactAndPartyService {
  constructor(
    @Inject() private readonly cartContactPartyStore: CartContactPartyStore,
    @Inject() private readonly cartStore: CartStore,
    @Inject() private readonly cartPartyAdapter: CartPartyAdapter,
    @Inject() private readonly cartContactAdapter: CartContactAdapter,
    @Inject() private readonly loadCartService: LoadCartService
  ) {}

  async handleContactSelect(contactId?: number): Promise<void> {
    this.cartContactPartyStore.setIsLoading(true)

    await this.selectContact(contactId)

    this.cartContactPartyStore.setIsLoading(false)
  }

  async handleAddPartyToOrder(partyId: number): Promise<void> {
    this.cartContactPartyStore.setIsLoading(true)

    await this.addPartyToOrder(partyId)

    this.cartContactPartyStore.setIsLoading(false)
  }

  async handleRemovePartyFromOrder(): Promise<void> {
    this.cartContactPartyStore.setIsLoading(true)

    await this.removePartyFromOrder()

    this.cartContactPartyStore.setIsLoading(false)
  }

  async handleRemoveHostessFromOrder(): Promise<void> {
    this.cartContactPartyStore.setIsLoading(true)

    await this.removeHostessFromOrder()

    this.cartContactPartyStore.setIsLoading(false)
  }

  private async removeHostessFromOrder(): Promise<void> {
    const { cart } = this.cartStore

    if (!cart?.id) {
      logger.error('remove-hostess-from-order_cart-is-not-defined')
      return
    }

    const result = await this.cartPartyAdapter.removeHostessFromOrder({
      cartId: cart.id,
    })

    if (isErr(result)) {
      logger.error(result.error.key, result.error)

      return
    }
    this.cartStore.cartData.setData(result.data)
    this.cartContactPartyStore.editHostessToggle.handleClose()
  }

  private async removePartyFromOrder(): Promise<void> {
    const { cart } = this.cartStore

    if (!cart?.id) {
      logger.error('remove-party-from-order_cart-is-not-defined')
      return
    }

    const result = await this.cartPartyAdapter.removePartyFromOrder({
      cartId: cart.id,
    })

    if (isErr(result)) {
      logger.error(result.error.key, result.error)

      return
    }
    this.cartStore.cartData.setData(result.data)
    this.cartContactPartyStore.editHostessToggle.handleClose()
  }

  private async addPartyToOrder(partyId: number): Promise<void> {
    if (this.cartStore.cart?.partyId === partyId) {
      this.cartContactPartyStore.editHostessToggle.handleClose()
      return
    }

    const { cart } = this.cartStore

    if (!cart?.id) {
      logger.error('add-party-to-order_cart-is-not-defined')
      return
    }

    const result = await this.cartPartyAdapter.addPartyToOrder({
      cartId: cart.id,
      partyId,
    })

    if (isErr(result)) {
      logger.error(result.error.key, result.error)

      return
    }
    this.cartStore.cartData.setData(result.data)
    this.cartContactPartyStore.editHostessToggle.handleClose()
  }

  private async addHostessToOrder(contact: ContactEntity): Promise<void> {
    if (this.cartStore.cart?.referrerId === contact.customerId) {
      this.cartContactPartyStore.editHostessToggle.handleClose()
      return
    }

    const { cart } = this.cartStore

    if (!cart?.id || !contact.customerId) {
      logger.error('add-hostess-to-order_cart-is-not-defined')
      return
    }

    const result = await this.cartPartyAdapter.addHostessToOrder({
      cartId: cart.id,
      hostessId: contact.customerId,
    })

    if (isErr(result)) {
      logger.error(result.error.key, result.error)

      return
    }
    this.cartStore.cartData.setData(result.data)
    this.cartContactPartyStore.editHostessToggle.handleClose()
  }

  private async selectContact(contactId?: number): Promise<void> {
    if (!this.cartStore.cart) {
      logger.error('select-contact-to-order_cart-is-not-defined')
      return
    }

    const { id: cartId } = this.cartStore.cart

    const result = await this.cartContactAdapter.addContactToOrder({
      contactId,
      cartId,
    })

    if (isErr(result)) {
      logger.error(result.error.key, result.error)
      return
    }

    this.cartContactPartyStore.setIsContactEdit(false)

    await this.loadCartService.loadCartById({ cartId: result.data.id })
    this.cartContactPartyStore.editContactToggle.handleClose()
  }
}
