import { Store } from '@nori/di'

import type { GetRequestPagination } from '@nori/app-kit'
import type {
  ContactErrorInstance,
  ContactsValueObject,
} from '~/modules/contacts/core'

const INITIAL_PAGINATION: GetRequestPagination = {
  page: 1,
  perPage: 12,
}

export type ContactSelectorTab = 'search' | 'create'

@Store()
export class ContactSelectorStore {
  activeTab: ContactSelectorTab = 'search'

  searchQuery = ''
  contacts?: ContactsValueObject = undefined
  isContactsLoading = false
  isCreateContactLoading = false
  contactsLoadingError?: ContactErrorInstance = undefined
  selfContactId?: number = undefined
  selectedContactId?: number = undefined

  get pagination(): GetRequestPagination {
    return this.contacts?.pagination ?? INITIAL_PAGINATION
  }

  setIsContactsLoading(value: boolean): void {
    this.isContactsLoading = value
  }

  setIsCreateContactLoading(value: boolean): void {
    this.isCreateContactLoading = value
  }

  setContactsLoadingError(error?: ContactErrorInstance): void {
    this.contactsLoadingError = error
  }

  setSearchQuery(value: string): void {
    this.searchQuery = value
  }

  setContacts(value?: ContactsValueObject): void {
    this.contacts = value
  }

  setActiveTab(value: ContactSelectorTab): void {
    this.activeTab = value
  }

  setSelfContactId(value: number | undefined): void {
    this.selfContactId = value
  }

  setSelectedContactId(value: number | undefined): void {
    this.selectedContactId = value
  }

  get isSelfContactSelected(): boolean {
    return !!(
      this.selfContactId &&
      this.selectedContactId &&
      this.selfContactId === this.selectedContactId
    )
  }
}
