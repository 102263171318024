import { getMoneyWithIsoCurrency } from '@nori/app-kit'
import { useTranslations } from '@nori/lang-i18n'
import { Icon } from '@nori/ui-kit'

import { usePerksBlock } from './use-perks-block'

import type { CreditsInfoValueObject } from '@nori/app-kit'
import type { ContactDetailsEntity, ContactListItemEntity } from '../../core'

type Props = {
  contact: ContactListItemEntity | ContactDetailsEntity
  credits?: CreditsInfoValueObject
  hasFreeShipping?: boolean
}

export function PerksBlock({
  contact,
  credits,
  hasFreeShipping = false,
}: Props) {
  const t = useTranslations('contact.perksBlock')

  const { sanitizedCredits } = usePerksBlock({
    contact,
    credits,
    hasFreeShipping,
  })

  if (!sanitizedCredits || !credits) return null
  return (
    <div className="mb-4 border-b border-gray-100 pb-4">
      <div className="text-black">
        <div className="flex flex-col gap-2 text-start lg:flex-row lg:gap-10">
          <div className="flex flex-col items-start gap-1.5">
            <div className="text-sm font-medium">{t('creditsAvailable')}:</div>
            <div
              className="text-2xl font-bold"
              data-test-id="contact-credits-header-combined-credits-amount"
            >
              {getMoneyWithIsoCurrency({
                cents: sanitizedCredits.combinedCredits,
                currency: credits.currency,
              })}
            </div>
            {sanitizedCredits.expiringHostessCredits && (
              <div className="font-sm text-sm text-gray-600">
                {getMoneyWithIsoCurrency({
                  cents: sanitizedCredits.expiringHostessCredits.cents,
                  currency: credits.currency,
                })}{' '}
                {t('expiring')}{' '}
                {sanitizedCredits.expiringHostessCredits.expiringAt.formatInTZ(
                  'M/d/y'
                )}
              </div>
            )}
          </div>

          <div className="h-px self-stretch bg-gray-100 lg:h-auto lg:w-px" />

          <div className="flex flex-col items-start gap-1.5">
            <div className="text-sm font-medium">{t('itemDiscounts')}:</div>
            <div className="text-2xl font-bold">
              {sanitizedCredits.itemDiscounts}
            </div>
            {sanitizedCredits.itemDiscountsExpiringAt && (
              <div className="font-sm text-sm capitalize text-gray-600">
                {t('expiring')}{' '}
                {sanitizedCredits.itemDiscountsExpiringAt.formatInTZ('M/d/y')}
              </div>
            )}
          </div>

          {sanitizedCredits.hasFreeShipping && (
            <>
              <div className="h-px self-stretch bg-gray-100 lg:h-auto lg:w-px" />
              <div className="flex flex-col items-start justify-center gap-1.5">
                <div className="text-lg font-medium">{t('freeShipping')}</div>
                <div className="mt-1.5 flex items-center gap-1 text-xs text-gray-600">
                  {t('onYourNextOrder')} <Icon name="InformationCircleFill" />
                </div>
              </div>
            </>
          )}

          <div className="h-px w-full self-stretch bg-gray-100 lg:h-auto lg:w-px" />

          <div
            className="flex flex-col items-start justify-center gap-1.5"
            data-test-id="contact-credits-header-store-credits-amount"
          >
            <div className="text-sm font-medium">{t('storeCredit')}:</div>
            <div className="text-2xl font-bold">
              {getMoneyWithIsoCurrency({
                cents: sanitizedCredits.storeCredits,
                currency: credits.currency,
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
