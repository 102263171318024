import { ProductDetailsService } from '@nori/app-kit'
import { Inject, Service } from '@nori/di'
import { isErr } from '@nori/result'
import { createLogger } from '~/infra/create-logger'
import { SubscriptionService } from '~/modules/subscription'

import { NextOrderStore } from '../store/next-order.store'

import type {
  AddressValueObject,
  BundleDetailsEntity,
  StyleDetailsEntity,
} from '@nori/app-kit'

const logger = createLogger('next-order.service')

@Service()
export class NextOrderService {
  constructor(
    @Inject() private readonly nextOrderStore: NextOrderStore,
    @Inject() private readonly subscriptionService: SubscriptionService,
    @Inject() private readonly productDetailsService: ProductDetailsService
  ) {}

  async loadNextOrder(userId?: number): Promise<void> {
    this.nextOrderStore.setError(undefined)

    const result = await this.subscriptionService.getNextOrder(userId)
    if (isErr(result)) {
      logger.error(result.error.key, result.error)
      this.nextOrderStore.setError(result.error)
      return
    }

    const stylesDetailsIds: number[] = []
    const bundleDetailsIds: number[] = []
    for (const subscription of result.data.subscriptions) {
      if (!subscription.active) continue
      if (subscription.bundleId) {
        bundleDetailsIds.push(subscription.bundleId)
      }
      if (subscription.productId) {
        stylesDetailsIds.push(subscription.productId)
      }
    }

    const [stylesDetails, bundleDetails] = await Promise.all([
      Promise.all(
        stylesDetailsIds.map((id) => this.loadStyleDetails(id.toString()))
      ),
      Promise.all(
        bundleDetailsIds.map((id) => this.loadBundleDetails(id.toString()))
      ),
    ])

    this.nextOrderStore.setNextOrder(result.data)

    if (this.nextOrderStore.isOneTimeShippingAddressExists) {
      this.nextOrderStore.shippingAddressConfig.changeAddressType('one-time')
      this.fillShipping(this.nextOrderStore.nextOrder?.oneTimeShippingAddress)
    }

    this.nextOrderStore.subscriptionProducts.setData([
      ...stylesDetails.filter((e): e is StyleDetailsEntity => e !== undefined),
      ...bundleDetails.filter((e): e is BundleDetailsEntity => e !== undefined),
    ])
  }

  private fillShipping(address?: AddressValueObject): void {
    if (!address) return

    this.nextOrderStore.shippingAddressConfig.shippingAddressValidator.handlers.address1(
      address.address1
    )
    this.nextOrderStore.shippingAddressConfig.shippingAddressValidator.handlers.address2(
      address.address2
    )
    this.nextOrderStore.shippingAddressConfig.shippingAddressValidator.handlers.city(
      address.city
    )
    this.nextOrderStore.shippingAddressConfig.shippingAddressValidator.handlers.state(
      address.state
    )
    this.nextOrderStore.shippingAddressConfig.shippingAddressValidator.handlers.zipCode(
      address.zipCode
    )

    if (address.phoneNumber) {
      this.nextOrderStore.shippingAddressConfig.shippingAddressValidator.handlers.phone(
        address.phoneNumber
      )
    }
  }

  private async loadStyleDetails(
    id: string
  ): Promise<StyleDetailsEntity | undefined> {
    const result = await this.productDetailsService.getStyleDetails(id)

    if (isErr(result)) {
      logger.error(result.error.key, result.error)
      this.nextOrderStore.subscriptionProducts.setError(result.error)
      return undefined
    }

    return result.data
  }

  private async loadBundleDetails(
    id: string
  ): Promise<BundleDetailsEntity | undefined> {
    const result = await this.productDetailsService.getBundleDetails(id)

    if (isErr(result)) {
      logger.error(result.error.key, result.error)
      this.nextOrderStore.subscriptionProducts.setError(result.error)
      return undefined
    }

    return result.data
  }
}
