import { ProductGroupsDropdown } from '@nori/app-kit'
import { observer } from '@nori/di'
import { useCategoryContext } from '~/modules/categories'
import { useCollectionContext } from '~/modules/collections'

export const ProductGroups = observer(() => {
  const { categoryStore, categoryAction } = useCategoryContext()
  const { collectionStore, collectionAction } = useCollectionContext()

  return (
    <div className="my-5 flex flex-row justify-between">
      <div className="flex-1">
        <ProductGroupsDropdown
          isLoading={categoryStore.isLoading}
          items={categoryStore.categories}
          title="Categories"
          error={categoryStore.error}
          onSelectGroup={(category) => categoryAction.openCategory(category.id)}
        />
      </div>
      <div className="w-20" />
      <div className="flex-1">
        <ProductGroupsDropdown
          isLoading={collectionStore.isLoading}
          items={collectionStore.collections}
          title="Collections"
          error={collectionStore.error}
          onSelectGroup={(collection) =>
            collectionAction.openCollection(collection.id)
          }
        />
      </div>
    </div>
  )
})
