import { Service } from '@nori/di'

import { escapeSpecialCharacters } from '../../interface/escape-especial-characters'

import type { NoteEntity } from '../../core/note.entity'

@Service()
export class NoteMapper {
  toNoteEntity(data: any): NoteEntity {
    return {
      id: data.id,
      contactId: data.contact_id,
      content: escapeSpecialCharacters(data.content),
      isEditable: data.type !== 'system_generated_immutable',
      createdAt: new Date(data.created_at),
      updatedAt: new Date(data.updated_at),
    }
  }
}
