import { getMoneyWithIsoCurrency, ProductImage } from '@nori/app-kit'
import { Swap, TableCell } from '@nori/ui-kit'

import { Row } from './row'

import type { CommonBundleEntity } from '@nori/app-kit'

type Props = {
  item: CommonBundleEntity
}

export function BundleRow({ item }: Props) {
  if (!item.canBeAutoShipped) return null

  return (
    <Row item={item}>
      <TableCell noBorder>
        <div className="flex items-center">
          <ProductImage
            width={80}
            height={80}
            styleName="mr-3 h-20 w-20"
            src={item.pictures[0]?.thumbnailMedURL}
          />

          <div className="flex flex-col gap-1">
            <div className="text-base font-bold text-gray-700">
              {item.name ?? item.brandName}
            </div>
            <div className="text-sm font-medium text-gray-500">{item.code}</div>
          </div>
        </div>
      </TableCell>

      <TableCell noBorder>
        <Swap is={!item.maxPrice} isSlot={<div className="text-black">-</div>}>
          <div className="text-base text-primary-900">
            {getMoneyWithIsoCurrency({
              cents: item.maxPrice!.cents,
              currency: item.maxPrice!.currency,
            })}
          </div>
        </Swap>
      </TableCell>

      <TableCell noBorder>
        <Swap
          is={!item.maxDsrPrice}
          isSlot={<div className="text-black">-</div>}
        >
          <div className="text-base text-primary-900">
            {getMoneyWithIsoCurrency({
              cents: item.maxDsrPrice!.cents,
              currency: item.maxDsrPrice!.currency,
            })}
          </div>
        </Swap>
      </TableCell>
    </Row>
  )
}
