import { useTranslations } from '@nori/lang-i18n'
import { Button, Link, Swap } from '@nori/ui-kit'

type Props = {
  id: number
  isEditMode: boolean
  isEditable?: boolean
  isWishButtonEnabled?: boolean
  onCancel: () => void
  onAdd: () => void
  onRemove: () => void
  onEditSwitch: () => void
  onAddWishlist?: () => void
}

export function ProductItemControlsMobile({
  id,
  isEditMode,
  isEditable = true,
  onCancel,
  onAdd,
  onRemove,
  onEditSwitch,
  onAddWishlist,
  isWishButtonEnabled,
}: Props) {
  const t = useTranslations('cart.productList')

  return (
    <div className="flex flex-row-reverse gap-6">
      <Swap
        is={isEditMode}
        isSlot={
          <>
            <Button
              size="base"
              onClick={onAdd}
              data-test-id={`update-item-mobile-button_${id}`}
              widthType="full"
            >
              {t('update')}
            </Button>
            <Link
              size="medium"
              weight="bold"
              isUnderline={false}
              onClick={onCancel}
              data-test-id={`cancel-update-item-mobile-button_${id}`}
            >
              {t('cancel')}
            </Link>
          </>
        }
      >
        <Link
          onClick={onRemove}
          data-test-id={`remove-from-cart-mobile-button_${id}`}
          isUnderline={false}
        >
          {t('removeFromCart')}
        </Link>
        {isEditable && (
          <Link
            onClick={onEditSwitch}
            data-test-id={`edit-product-mobile-button_${id}`}
          >
            {t('edit')}
          </Link>
        )}
        {isWishButtonEnabled && onAddWishlist && (
          <Link
            data-test-id={`move-to-wishlist-mobile-button_${id}`}
            onClick={onAddWishlist}
          >
            {t('moveToWishlist')}
          </Link>
        )}
      </Swap>
    </div>
  )
}
