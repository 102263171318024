import {
  centsToMainUnit,
  getMoneyWithIsoCurrency,
  ProductImage,
} from '@nori/app-kit'
import { observer } from '@nori/di'
import { useTranslations } from '@nori/lang-i18n'
import { Icon } from '@nori/ui-kit'
import { useContactContext } from '~/modules/contacts/interface/use-contact-context'

import { AutoshipPrice } from './autoship-price'

import type { SkuEntity } from '~/modules/sku'

type Props = {
  id: number
  sku: SkuEntity
  isFirst: boolean
}

export const SubscriptionTableRow = observer(({ id, sku, isFirst }: Props) => {
  const t = useTranslations(
    'contacts.list.table.extended.subscription.tableRow'
  )
  const { contactSubscriptionStore } = useContactContext()
  const subscription = contactSubscriptionStore.getSubscription(id, sku.code)

  if (!subscription) return null

  const total = subscription.total
    ? `${getMoneyWithIsoCurrency({
        cents: subscription.total.cents,
        currency: subscription.total.currency,
      })}`
    : null

  const separatorStyleClass = isFirst ? 'lg:table-cell lg:py-6 hidden' : 'py-6'

  return (
    <>
      <tr>
        <td colSpan={4} className={separatorStyleClass}>
          <hr />
        </td>
      </tr>
      <tr key={subscription.id}>
        <td className="w-4/5 lg:w-2/5">
          <div className="flex">
            <ProductImage
              styleName="mr-5 h-20 w-20"
              alt="No image available"
              src={
                sku.images[0]?.thumbnailSmallURL ?? subscription.thumbnailUrl
              }
              width={80}
              height={80}
            />
            <div className="flex flex-col gap-0.5 text-xs font-medium text-gray-500">
              <div className="text-base font-bold text-gray-700">
                {subscription.bundle?.name ?? subscription.productName}
              </div>
              {subscription.bundle?.name && (
                <div>{subscription.productName}</div>
              )}
              <div>{subscription.code}</div>
              {subscription.price && (
                <div>
                  {subscription.quantity}x
                  {centsToMainUnit({ cents: subscription.price.cents })}
                </div>
              )}
              {subscription.cycleDuration && (
                <div className="flex items-center gap-0.5 font-bold">
                  <Icon name="Refresh" />
                  <div>
                    {t('autoshipAndSave')} ({t('cycleDuration')}{' '}
                    {subscription.cycleName})
                  </div>
                </div>
              )}
            </div>
          </div>
        </td>
        <td className="hidden w-1/5 text-right align-top text-sm font-medium text-gray-700 lg:table-cell">
          {subscription.quantity}
        </td>
        <td className="hidden w-1/5 text-right align-top lg:table-cell">
          <AutoshipPrice subscription={subscription} />
        </td>
        <td className="w-1/5 text-end align-top lg:w-1/5">
          <div className="font-bold text-gray-700">{total}</div>
          {subscription.salePrice && (
            <div className="text-gray-500 line-through">
              {getMoneyWithIsoCurrency({
                cents: subscription.salePrice.cents,
                currency: subscription.salePrice.currency,
              })}
            </div>
          )}
        </td>
      </tr>
    </>
  )
})
