import { getMoneyWithIsoCurrency, useCreditsContext } from '@nori/app-kit'
import { format } from '@nori/date'
import { useTranslations } from '@nori/lang-i18n'
import {
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableRow,
} from '@nori/ui-kit'

export function AvailableCredits() {
  const t = useTranslations('contactDetailsPage.norwexPerks.availableCredits')

  const { creditsStore } = useCreditsContext()

  return (
    <div className="w-full">
      <div className="text-sm font-bold text-black lg:text-xl">
        {t('availableCredits')}
      </div>
      <div className="mt-4 overflow-x-auto">
        <div className="min-w-[800px]">
          <Table
            data-test-id="customer-details-credits-and-rewards-history-table"
            widthType="full"
            withEmpty
          >
            <TableHeader>
              <TableCell>
                <p className="text-sm font-bold text-sky-900">{t('type')}</p>
              </TableCell>
              <TableCell>
                <p className=" text-sm font-bold text-sky-900">
                  {t('expirationDate')}
                </p>
              </TableCell>
              <TableCell>
                <p className=" text-sm font-bold text-sky-900">
                  {t('amountAvailable')}
                </p>
              </TableCell>
            </TableHeader>

            <TableBody>
              {creditsStore.multipleCredits?.map((item) => {
                const amount = item.currency
                  ? getMoneyWithIsoCurrency({
                      cents: item.amount,
                      currency: item.currency,
                    })
                  : item.amount
                // TODO: fix me. Add translation path to item
                const title =
                  item.key === 'hostess_credits'
                    ? t('hostessCredits')
                    : t('hostessDiscounts')
                return (
                  <TableRow key={item.key}>
                    <TableCell>
                      <p
                        data-test-id={`available-table-reward-type_${item.key}`}
                        className="text-xs font-bold text-sky-900"
                      >
                        {title}
                      </p>
                    </TableCell>
                    <TableCell>
                      <p
                        data-test-id={`available-table-reward-expiration_${item.key}`}
                        className="textgray-600 text-xs"
                      >
                        {format(Date.parse(item.expiresAt), 'MMMM dd, yyyy')}
                      </p>
                    </TableCell>
                    <TableCell>
                      <p
                        data-test-id={`available-table-reward-amount_${item.key}`}
                        className="textgray-600 text-xs"
                      >
                        {amount}
                      </p>
                    </TableCell>
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </div>
      </div>
    </div>
  )
}
