import { hookContextFactory } from '@nori/di'

import { BundleSubscriptionAction } from './action/bundle-subscription.action'
import { ProductSubscriptionAction } from './action/prdocut-subscription.action'
import { ProductSubscriptionStore } from './store/product-subscription.store'

export const { useModuleContext: useProductSubscriptionContext } =
  hookContextFactory({
    productSubscriptionAction: ProductSubscriptionAction,
    productSubscriptionStore: ProductSubscriptionStore,
    bundleSubscriptionAction: BundleSubscriptionAction,
  })
