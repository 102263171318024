import { Action, Inject } from '@nori/di'
import { isErr } from '@nori/result'
import { createLogger } from '~/infra/create-logger'
import { CartAdapter } from '~/modules/cart/infra/cart.adapter'
import { CartAction } from '~/modules/cart/interface/actions/cart.action'
import { LoadCartService } from '~/modules/cart/interface/services/create-cart.service'
import { CartStore } from '~/modules/cart/interface/store/cart-store/cart.store'

import { ApplyHostessCreditsStore } from '../../store/apply-credits.store/apply-hostess-credits.store'

import type { ApplyCreditsActionInterface } from './apply-credits-action.interface'

const logger = createLogger('apply-hostess-credits.actions')

@Action()
export class ApplyHostessCreditsAction implements ApplyCreditsActionInterface {
  constructor(
    @Inject() private readonly cartStore: CartStore,
    @Inject() private readonly cartAction: CartAction,
    @Inject() private readonly loadCartService: LoadCartService,
    @Inject() private readonly cartAdapter: CartAdapter,
    @Inject()
    private readonly applyHostessCreditStore: ApplyHostessCreditsStore
  ) {}

  async handleApplyCreditsAndGoCheckout(): Promise<void> {
    if (!this.applyHostessCreditStore.creditsToApply) return

    await this.handleApplyCredit(
      this.applyHostessCreditStore.creditsToApply.cents
    )
    this.cartAction.handleNavigateToCheckout()
  }

  handleChangeValue(value: string): void {
    const maximumAppliableValue =
      this.applyHostessCreditStore.applicableCartCredits?.cents || 0

    if (Number(value) > maximumAppliableValue) {
      this.applyHostessCreditStore.setValue(maximumAppliableValue.toString())
      return
    }

    this.applyHostessCreditStore.setValue(value)
  }

  async handleRemoveCredits(): Promise<void> {
    if (!this.cartStore.cart) {
      return
    }
    const { id } = this.cartStore.cart
    this.applyHostessCreditStore.updatedCredits.setIsLoading(true)

    await this.applyHostessCredit({
      id,
      value: 0,
    })

    this.applyHostessCreditStore.updatedCredits.setIsLoading(false)
  }

  async handleApplyCredit(productCreditsValue: number): Promise<void> {
    if (!this.cartStore.cart) {
      return
    }
    const { id } = this.cartStore.cart
    this.applyHostessCreditStore.updatedCredits.setIsLoading(true)

    await this.applyHostessCredit({
      id,
      value: productCreditsValue,
    })

    this.applyHostessCreditStore.updatedCredits.setIsLoading(false)
  }

  private async applyHostessCredit({
    id,
    value,
  }: {
    id: number
    value: number
  }): Promise<void> {
    this.applyHostessCreditStore.updatedCredits.setError(undefined)

    const result = await this.cartAdapter.applyHostessCredits({
      cartId: id,
      amount: value,
    })

    if (isErr(result)) {
      logger.error(result.error.key, result.error)
      this.applyHostessCreditStore.updatedCredits.setError(result.error)
      return
    }

    await this.loadCartService.loadCartById({
      cartId: id,
    })

    this.applyHostessCreditStore.setValue('')
  }
}
