import { HistoryService } from '@nori/app-kit'
import { Action, Inject } from '@nori/di'
import { isErr } from '@nori/result'
import { PartiesListLoadService } from '~/modules/parties/interface/services/parties-list-load.service'
import { PartiesListStore } from '~/modules/parties/interface/stores/parties-list.store'
import { Routes } from '~/modules/routes'

import { OrdersListAdapter } from '../../infra/orders-list.adapter'
import { LoadOrdersService } from '../service/load-orders.service'
import { MoveOrderStore } from '../stores/move-order.store'
import { OrdersListStore } from '../stores/orders-list.store'

import type { PartyEntity } from '~/modules/parties/core/entities/party.entity'
import type { OrderEntity } from '../../core'

const INITIAL_PAGE = 1

@Action()
export class MoveOrderAction {
  constructor(
    @Inject() private readonly moveOrderStore: MoveOrderStore,
    @Inject() private readonly partiesListLoadService: PartiesListLoadService,
    @Inject() private readonly partiesListStore: PartiesListStore,
    @Inject() private readonly ordersListAdapter: OrdersListAdapter,
    @Inject() private readonly historyService: HistoryService,
    @Inject() private readonly ordersListStore: OrdersListStore,
    @Inject() private readonly loadOrdersService: LoadOrdersService
  ) {}

  async handleInitialLoad(): Promise<void> {
    this.moveOrderStore.setIsLoading(true)

    await this.initialLoad()

    this.moveOrderStore.setIsLoading(false)
  }

  private async initialLoad(): Promise<void> {
    this.moveOrderStore.setNoEventsAvailable(false)
    this.moveOrderStore.setSelectedEvent(undefined)

    await this.partiesListLoadService.initialLoad({ partyType: 'open' })
    if (!this.partiesListStore.pagination.totalCount) {
      this.moveOrderStore.setNoEventsAvailable(true)
    }

    this.partiesListLoadService.reset({ partyType: 'open' })
  }

  async handleSearchParamChange(val: string): Promise<void> {
    const searchParam = val.trim()
    if (!searchParam.length) {
      this.partiesListLoadService.reset({ partyType: 'open' })
      return
    }

    this.partiesListStore.setSearchParam(searchParam)
    await this.partiesListLoadService.loadPage(INITIAL_PAGE)
  }

  handleOpenModal(order: OrderEntity): void {
    this.moveOrderStore.setStep('selectEvent')
    this.moveOrderStore.modalToggle.handleOpen()
    this.moveOrderStore.setSelectedOrder(order)
  }

  async handleCloseModal(): Promise<void> {
    this.moveOrderStore.modalToggle.handleClose()
    if (this.moveOrderStore.step !== 'success') return

    const ordersRoute = Routes.orders.list.path()
    if (!this.historyService.asPath.includes(ordersRoute)) {
      this.historyService.push(ordersRoute)
    } else {
      this.ordersListStore.resetFilters()
      await this.loadOrdersService.loadOrderList({ page: INITIAL_PAGE })
    }
  }

  handleSelectEvent(event?: PartyEntity): void {
    this.moveOrderStore.setSelectedEvent(event)
    this.moveOrderStore.setStep('confirm')
  }

  async handleMoveOrder(): Promise<void> {
    if (this.moveOrderStore.isMovingOrder) return

    this.moveOrderStore.setIsMovingOrder(true)

    await this.moveOrder()

    this.moveOrderStore.setIsMovingOrder(false)
  }

  private async moveOrder(): Promise<void> {
    if (
      !this.moveOrderStore.selectedOrder ||
      !this.moveOrderStore.selectedEvent
    ) {
      return
    }

    const result = await this.ordersListAdapter.moveOrder({
      publicOrderId: this.moveOrderStore.selectedOrder.publicId,
      eventId: this.moveOrderStore.selectedEvent.id,
    })

    if (isErr(result)) {
      this.moveOrderStore.setStep('fail')
      return
    }

    this.moveOrderStore.setStep('success')
  }
}
