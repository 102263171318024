import { BraintreeService, PaymentMethodStore } from '@nori/app-kit'
import { Action, Inject } from '@nori/di'
import { getBlock } from '@nori/lang-i18n'
import { NotifyAction } from '@nori/notify'
import { isErr } from '@nori/result'

import { BillingFormStore } from '../store/billing-form.store'

@Action()
export class BillingFormPayPalAction {
  constructor(
    @Inject() private readonly billingFormStore: BillingFormStore,
    @Inject() private readonly paymentMethodStore: PaymentMethodStore,
    @Inject() private readonly braintreeService: BraintreeService,
    @Inject() private readonly notifyAction: NotifyAction
  ) {}

  handleInitialLoad(): void {
    this.billingFormStore.payPalToken.setIsLoading(true)

    if (this.paymentMethodStore.payPalAccount) {
      this.billingFormStore.payPalToken.setData({
        email: this.paymentMethodStore.payPalAccount.email,
        token: this.paymentMethodStore.payPalAccount.value,
        isSaved: true,
      })
    }

    this.billingFormStore.payPalToken.setIsLoading(false)
  }

  async handleChangePayPal(): Promise<void> {
    this.billingFormStore.payPalToken.setIsLoading(true)

    await this.changePayPal()

    this.billingFormStore.payPalToken.setIsLoading(false)
  }

  private async changePayPal(): Promise<void> {
    const payPalResult = await this.braintreeService.tokenizePaypal({
      flow: 'vault',
      enableShippingAddress: true,
    })

    if (isErr(payPalResult)) {
      this.notifyAction.handleAddNotify({
        type: 'danger',
        title: getBlock('appkit.braintree.errors')(
          'unexpectedTokenizePaypalError'
        ),
      })
      return
    }

    if (payPalResult.data.status === 'PAYPAL_POPUP_CLOSED') return

    this.billingFormStore.payPalToken.setData({
      email: payPalResult.data.value.details.email,
      token: payPalResult.data.value.nonce,
      isSaved: false,
    })
  }
}
