export const defaultIconLeftMap = ({ type , defaultName  })=>{
    switch(type){
        case 'search':
            return 'Search';
        default:
            return defaultName;
    }
};
export function defaultIconRightMap({ type , defaultName , passwordName , value , isRightIconHidden  }) {
    if (isRightIconHidden) return undefined;
    switch(type){
        case 'password':
        case 'password-numeric':
            return passwordName;
        case 'search':
            if (!value) return undefined;
            return 'CloseLine';
        default:
            return defaultName;
    }
}
