import { Icon, type IconName } from '@nori/ui-kit'

import type { PaymentMethodValueObject } from '@nori/app-kit'

type Props = {
  value: PaymentMethodValueObject
}

const cardIconNames: IconName[] = [
  'MasterCard',
  'VisaCard',
  'DiscoverCard',
  'AmericanExpressCard',
]

export function FormHeaderIcons({ value }: Props) {
  switch (value) {
    case 'paypal':
      return (
        <div>
          <Icon name="PaypalCard" size="lg" />
        </div>
      )
    case 'card':
      return (
        <div className="flex items-center gap-4">
          {cardIconNames.map((iconName) => (
            <Icon key={iconName} name={iconName} size="lg" />
          ))}
        </div>
      )
  }
}
