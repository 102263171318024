import type { WishItemEntity } from '../../core/entities/wish-item.entity'
import type { WishListValueObject } from '../../core/value-object/wish-list.value-object'
import type wishListResponseMock from '../mocks/wish-list.mock.json'

export class WishListMapper {
  static toValueObject(
    response: typeof wishListResponseMock
  ): WishListValueObject {
    const items: WishItemEntity[] = response.objects.map((item) => {
      return {
        id: item.id,
        productName: item.product_name,
        skuName: item.name === 'Nil' ? undefined : item.name || '',
        styleName: item.style_name ?? undefined,
        isAvailable: item.available,
        skuId: item.sku_id,
        catalogImage: {
          id: item.catalog_image.id,
          fullSizeURL: item.catalog_image.full_size_url,
          thumbnailLargeURL: item.catalog_image.thumbnail_large_url,
          thumbnailMedURL: item.catalog_image.thumbnail_med_url,
          thumbnailSmallURL: item.catalog_image.thumbnail_small_url,
          iphoneThumbnail: item.catalog_image.iphone_thumbnail,
          iphoneMedium: item.catalog_image.iphone_medium,
          iphoneLarge: item.catalog_image.iphone_large,
          ipadThumbnail: item.catalog_image.ipad_thumbnail,
          ipadMedium: item.catalog_image.ipad_medium,
          ipadLarge: item.catalog_image.ipad_large,
          alternate: item.catalog_image.alternate,
          position: item.catalog_image.position,
        },
        currentPrice: item.current_price,
        addedAt: item.added_at,
        styleKey: item.style_key,
      }
    })

    const pagination = {
      page: response.pagination.current_page,
      perPage: response.pagination.per_page,
      totalCount: response.pagination.total_count,
      totalPages: response.pagination.total_pages,
    }

    return {
      items,
      pagination,
    }
  }
}
