import { useEffect, useState } from 'react';
import { useDebounce as useDebounceExternal } from 'use-debounce';
export function useDebounce({ debounce =500 , initialValue ='' , onChangeDebounce  }) {
    const [value, setValue] = useState(initialValue);
    const [debouncedValue] = useDebounceExternal(value, debounce);
    useEffect(()=>{
        if (debouncedValue === initialValue) {
            return;
        }
        onChangeDebounce(debouncedValue);
    }, [
        debouncedValue
    ]);
    useEffect(()=>{
        if (debouncedValue !== initialValue) {
            setValue(initialValue);
        }
    }, [
        initialValue
    ]);
    return {
        value,
        onChangeValue: setValue
    };
}
