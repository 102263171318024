import { Inject, Service } from '@nori/di'
import { NoteMapper } from '~/modules/notes/infra/mappers/note.mapper'

import type {
  ContactDetailsEntity,
  ContactDetailsEventEntity,
  ContactEventEntity,
  ContactListItemEntity,
} from '~/modules/contacts/core'
import type { ContactAddressValueObject } from '~/modules/contacts/core/contact-address.value-object'
import type { ContactsValueObject } from '../../core/contacts.value-object'

@Service()
export class ContactsMapper {
  constructor(@Inject() readonly noteMapper: NoteMapper) {}
  toContactsValueObject(data: any): ContactsValueObject {
    const contacts = data.objects.map((contact: any): ContactListItemEntity => {
      return {
        belongsToDifferentDsr: contact.belongs_to_different_dsr,
        hasCompletedQuiz: contact.completed_quiz,
        isCorpReferral: contact.corp_referral,
        createdAt: new Date(contact.created_at),
        customerId: contact.customer_id ?? undefined,
        customerType: contact.customer_type,
        customerTypeName: contact.customer_type_public_name,
        dsrId: contact.dsr_id,
        isEasyReplenishCustomer: contact.easy_replenish_customer,
        isCustomerRegistered: contact.is_customer_registered,
        email: contact.email,
        isEmailProvided: contact.email_provided,
        firstName: contact.first_name,
        hasAvailableCoupons: contact.has_available_coupons,
        hasCoupons: contact.has_coupons,
        hasHostessRewards: contact.has_hostess_rewards,
        hasStoreCredit: contact.has_store_credit,
        currentAddress: this.toContactAddressEntity(contact.current_address),
        hasWishList: contact.has_wish_list,
        hasAutoship: !!contact.has_autoship,
        hostessDiscounts: contact.hostess_discounts,
        id: contact.id,
        isPotentialHost: contact.potential_hostess,
        isPotentialConsultant: contact.potential_stylist,
        isPreferredDsr: contact.is_preferred_dsr,
        lastHostedSocialDate: contact.last_hosted_social_date
          ? new Date(contact.last_hosted_social_date)
          : undefined,
        lastName: contact.last_name,
        lastOrderDate: contact.last_order_date
          ? new Date(contact.last_order_date)
          : undefined,
        lastOrderId: contact.last_order_id ?? undefined,
        lifetimeSpend: contact.lifetime_spend,
        preferredLanguage: contact.preferred_language,
        isLoyaltyEnabled: contact.loyalty_enabled,
        loyaltySavingsCents: contact.loyalty_savings_cents,
        isNewCustomerLead: contact.new_customer_lead,
        notes: contact.notes.map((note: any) =>
          this.noteMapper.toNoteEntity(note)
        ),
        shouldReceivePrintCatalog: contact.receive_print_catalog,
        store: contact.store,
        totalRewardsCredits: contact.total_rewards_credits,
        birth: {
          day: contact.birth_day ?? undefined,
          month: contact.birth_month ?? undefined,
          fullDate: contact.birthday,
        },
        endEarningPeriod: contact.end_earning_period
          ? new Date(contact.end_earning_period)
          : undefined,
        expiringRewards: contact.expiring_rewards
          ? new Date(contact.expiring_rewards)
          : undefined,
        lastQuizGroup: contact.last_quiz_group ?? undefined,
        lastQuizVersion: contact.last_quiz_version ?? undefined,
        loyaltyExpiration: contact.loyalty_expiration ?? undefined,
        nextHostedSocialDate: contact.next_hosted_social_date ?? undefined,
        personalLink: contact.personal_link ?? undefined,
        phone: contact.phone ?? undefined,
        hasFreeShipping: !!contact.has_free_shipping,
        isMyself: contact.customer_id === contact.dsr_id,
        events: this.toContactEvents(contact),
      }
    })

    const pagination = {
      page: data.pagination.current_page,
      perPage: data.pagination.per_page,
      totalPages: data.pagination.total_pages,
      totalCount: data.pagination.total_count,
    }

    return { contacts, pagination }
  }

  toContactDetailsEntity(data: any): ContactDetailsEntity {
    return {
      id: data.id,
      firstName: data.first_name,
      lastName: data.last_name,
      email: data.email,
      isEmailProvided: data.email_provided,
      phone: data.phone ?? undefined,
      isPotentialHost: data.potential_hostess,
      isPotentialConsultant: data.potential_stylist,
      shouldReceiveMarketingEmails: data.receive_marketing_emails,
      shouldReceivePrintCatalog: data.receive_print_catalog,
      isCustomerRegistered: data.is_customer_registered,
      userType: data.user_type ?? undefined,
      birth: {
        fullDate: data.birthday,
        day: data.birth_day ?? undefined,
        month: data.birth_month ?? undefined,
      },
      hasPlacedOrder: data.placed_order,
      hasBeenHostess: data.been_hostess,
      lastActiveAt: data.last_active_at
        ? new Date(data.last_active_at)
        : undefined,
      dsrId: data.dsr_id,
      source: data.source,
      customerId: data.customer_id,
      hasStoreCredit: data.has_store_credit,
      hasWishList: data.has_wish_list,
      createdAt: new Date(data.created_at),
      updatedAt: new Date(data.updated_at),
      preferredCustomerExpiresAt: data.preferred_customer_expires_at
        ? new Date(data.preferred_customer_expires_at)
        : undefined,
      deletedAt: data.deleted_at ? new Date(data.deleted_at) : undefined,
      accessToken: data.access_token,
      subscriptionAccessToken: data.subscription_access_token,
      urlCode: data.url_code,
      personalLink: data.personal_link,
      belongsToDifferentDsr: data.belongs_to_different_dsr,
      isLoyaltyEnabled: data.loyalty_enabled,
      store: data.store,
      loyaltySavingsCents: data.loyalty_savings_cents,
      loyaltyExpiration: data.loyalty_expiration ?? undefined,
      lastQuizGroup: data.last_quiz_group ?? undefined,
      lastQuizVersion: data.last_quiz_version ?? undefined,
      isPreferredDsr: data.preferred_dsr,
      hasCompletedQuiz: data.completed_quiz,
      isPreferredCustomer: data.preferred_customer,
      currentAddress: this.toContactAddressEntity(data.current_address),
      lastPlacedOrderDate: data.last_placed_order_date
        ? new Date(data.last_placed_order_date)
        : undefined,
      lastHostedShowDate: data.last_hosted_show_date
        ? new Date(data.last_hosted_show_date)
        : undefined,
      notes: data.notes.map((note: any) => this.noteMapper.toNoteEntity(note)),
      isNewRecord: data.is_new_record,
      isMyself: data.customer_id === data.dsr_id,
      events: this.toContactDetailsEvents(data),
    }
  }

  toContactAddressEntity(address: any): ContactAddressValueObject {
    return {
      id: address.id ?? undefined,
      address1: address.address1 ?? undefined,
      address2: address.address2 ?? undefined,
      city: address.city ?? undefined,
      country: address.country ?? undefined,
      state: address.state ?? undefined,
      zipCode: address.zip_code ?? undefined,
    }
  }

  toContactsSetOptedIds(data: Record<number, boolean>): any {
    return {
      contacts: Object.keys(data).map((contactId: any) => ({
        receive_print_catalog: data[contactId],
        id: contactId,
      })),
    }
  }

  private toContactEvents(contact: any): ContactEventEntity[] {
    if (!contact.events?.length) return []

    const events: ContactEventEntity[] = contact.events.map(
      (event: any): ContactEventEntity => ({
        id: event.id,
        openOn: new Date(event.open_on),
        closedOn: new Date(event.closed_on),
        isCurrentEvent: false,
      })
    )

    events.sort((a, b) => a.openOn.getTime() - b.openOn.getTime())
    if (events[0]) events[0].isCurrentEvent = true

    return events
  }

  private toContactDetailsEvents(contact: any): ContactDetailsEventEntity[] {
    if (!contact.events?.length) return []

    const events: ContactDetailsEventEntity[] = contact.events.map(
      (event: any): ContactDetailsEventEntity => ({
        id: event.party_id,
        openOn: new Date(event.open_on),
        closedOn: new Date(event.closed_on),
        partyAt: new Date(event.party_at),
        partyPath: event.party_path,
        isCurrentEvent: false,
      })
    )

    events.sort((a, b) => a.openOn.getTime() - b.openOn.getTime())
    if (events[0]) events[0].isCurrentEvent = true

    return events
  }
}
