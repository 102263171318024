import { errorFactory } from '@nori/errors'

import type { KeyOfErrors } from '@nori/errors'

export const { OrdersListErrors } = errorFactory('OrdersListErrors', {
  GetPartyOrders: 'Error while getting party order list',
  UnexpectedGetPartyOrders: 'Unexpected Error while getting party order list',

  GetOrders: 'Error while getting order list',
  UnexpectedGetOrderList: 'Unexpected Error while getting order list',

  GetArchiveOrders: 'Error while getting archived order list',
  UnexpectedGetArchiveOrders:
    'Unexpected Error while getting archived order list',

  GetOrderDetails: 'Error while getting order details',
  UnexpectedGetOrderDetails: 'Unexpected Error while getting order details',

  UnknownMoveOrderError: 'Error while moving order',
  UnexpectedMoveOrderError: 'Unexpected error while moving order',

  GetAutosaveOrders: 'Error while getting autosave orders',
  UnexpectedGetAutosaveOrders: 'Unexpected Error while getting autosave orders',
  GetAutosaveOrderDetails: 'Error while getting autosave order details',
  UnexpectedGetAutosaveOrderDetails:
    'Unexpected Error while getting autosave order details',
  AbortControllerError: 'AbortControllerError',
})

export type OrdersListErrorInstance = InstanceType<
  KeyOfErrors<typeof OrdersListErrors>
>

export const ORDER_LIST_IGNORED_ERRORS = [OrdersListErrors.AbortControllerError]
