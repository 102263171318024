import clsx from 'clsx'

import { getMoneyWithIsoCurrency } from '@nori/app-kit'

type Props = {
  title: string
  subTitle: string
  credit?: number
  currency?: string
  isWithLine?: boolean
  isCreditNA?: boolean
  'data-test-id'?: string
}

export const CreditItem = ({
  title,
  subTitle,
  credit,
  currency,
  isWithLine = false,
  isCreditNA = false,
  'data-test-id': dataTestId,
}: Props) => {
  if (credit === undefined && !isCreditNA) return null

  const creditStyle = clsx('text-lg font-bold text-white lg:text-xl', {
    'opacity-50': credit !== undefined && credit < 0,
  })

  return (
    <>
      {isWithLine && <div className="mx-5 w-px bg-primary-700 lg:mx-10" />}
      <div className="flex">
        <div className="flex flex-col gap-2">
          <p className="text-xs font-normal uppercase text-white">{title}</p>
          <p data-test-id={dataTestId} className={creditStyle}>
            {isCreditNA && '-'}
            {!currency && credit}
            {currency &&
              credit &&
              getMoneyWithIsoCurrency({
                cents: credit,
                currency,
              })}
          </p>
          <p className="text-xs font-light text-primary-300">{subTitle}</p>
        </div>
      </div>
    </>
  )
}
