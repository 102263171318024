import { CouponsAction, CreditsAction } from '@nori/app-kit'
import { Inject, Service } from '@nori/di'

import type { CreditParamsValueObject } from '../../core/credit-params-value-object'

@Service()
export class RewardsCreditsService {
  constructor(
    @Inject() private readonly creditsAction: CreditsAction,
    @Inject() private readonly couponsAction: CouponsAction
  ) {}

  async loadRewardsAndCreditsInfo(
    params: CreditParamsValueObject
  ): Promise<void> {
    Promise.all([
      await this.creditsAction.handleLoadCreditsInfo(params.customerId),
      await this.creditsAction.handleLoadCredits({
        customerId: params.customerId,
        accessToken: params.accessToken,
      }),
      await this.couponsAction.handleLoadCoupons({
        customerId: params.customerId,
        filter: 'available',
      }),
    ])
  }
}
