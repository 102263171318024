import { observer } from '@nori/di'
import { useTranslations } from '@nori/lang-i18n'
import { Icon } from '@nori/ui-kit'

type Props = {
  selectedContact?: string
  contactEmail?: string
  onRemoveContact?: () => void
}

export const SelectedContactBlock = observer(
  ({ selectedContact, contactEmail, onRemoveContact }: Props) => {
    const t = useTranslations('contactSelector')

    if (!selectedContact) {
      return <></>
    }

    return (
      <div className="flex flex-row items-center bg-primary-50 px-5 py-4">
        <div className="mr-5 flex h-10 min-w-[40px] items-center justify-center rounded-full bg-primary-900 text-white">
          <Icon name="UserOutline" />
        </div>
        <div className="flex flex-col gap-1">
          <div data-test-id="modal-selected-contact-full-name">
            <span className="text-sm font-bold text-primary-900">
              {selectedContact}
            </span>
          </div>

          {contactEmail && (
            <div
              data-test-id="modal-selected-contact-email"
              className="flex break-all text-sm text-gray-500"
            >
              <span>{contactEmail}</span>
            </div>
          )}
          <div
            data-test-id="modal-remove-selected-contact-button"
            onClick={onRemoveContact}
          >
            <span className="cursor-pointer text-xs font-normal text-primary-900 underline">
              {t('remove')}
            </span>
          </div>
        </div>
      </div>
    )
  }
)
