import { Store } from '@nori/di'

import type { CheckoutSummaryValueObject } from '../../core/value-objects/checkout-summary.value-object'

@Store()
export class PennyCheckoutSummaryStore {
  checkoutSummary?: CheckoutSummaryValueObject = undefined
  isLoading = false
  isError = false

  get messagesNumber(): number | undefined {
    return this.checkoutSummary?.messages.length
  }

  setCheckoutSummary(value?: CheckoutSummaryValueObject): void {
    this.checkoutSummary = value
  }

  setIsLoading(value: boolean): void {
    this.isLoading = value
  }

  setIsError(value: boolean): void {
    this.isError = value
  }

  handleDrop(): void {
    this.checkoutSummary = undefined
    this.isError = false
  }
}
