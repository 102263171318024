import { Inject, Service } from '@nori/di'
import { getBlock } from '@nori/lang-i18n'
import { NotifyAction } from '@nori/notify'
import { isErr } from '@nori/result'
import { createLogger } from '~/infra/create-logger'
import { CartStore } from '~/modules/cart/interface/store/cart-store/cart.store'

import { WishListAdapter } from '../../infra/wish-list.adapter'
import { WishListStore } from '../store/wish-list.store'

import type { PromiseResult } from '@nori/result'
import type { ToggleWishlistInput } from '~/modules/wishlist/core/input/toggle-wishlist.input'
import type { WishListInput } from '~/modules/wishlist/core/input/wishlist.input'
import type { WishListErrorInstance } from '../../core/error/wish-list.errors'

const logger = createLogger('wish-list.service')

@Service()
export class WishListService {
  constructor(
    @Inject() private readonly wishListAdapter: WishListAdapter,
    @Inject() private readonly wishListStore: WishListStore,
    @Inject() private readonly notifyAction: NotifyAction,
    @Inject() private readonly cartStore: CartStore
  ) {}

  dropWishListStore(): void {
    this.wishListStore.wishlist.setData(undefined)
    this.wishListStore.wishlist.setError(undefined)
  }

  setCustomerCode(customerCode?: string): void {
    this.wishListStore.setCustomerCode(customerCode)
  }

  async reloadWishList(): Promise<void> {
    await this.loadWishList({
      page: this.wishListStore.wishlist.data?.pagination.page,
      perPage: this.wishListStore.wishlist.data?.pagination.perPage,
      customerCode: this.wishListStore.customerCode,
    })
  }

  async loadWishList({
    page,
    perPage,
    customerCode,
  }: WishListInput): Promise<void> {
    this.setCustomerCode(customerCode)

    this.wishListStore.wishlist.setError(undefined)

    const response = await this.wishListAdapter.getWishList({
      page,
      perPage,
      customerCode,
    })

    if (isErr(response)) {
      this.wishListStore.wishlist.setError(response.error)
      logger.error(response.error.key, response.error)
      return
    }

    this.wishListStore.wishlist.setData(response.data)
  }

  async addItemToWishList(
    input: ToggleWishlistInput
  ): PromiseResult<undefined, WishListErrorInstance> {
    this.wishListStore.setProcessingItem(input.skuId, true)
    const customerCode = this.cartStore.isCustomerCart
      ? this.cartStore.cart?.customerURLCode
      : undefined

    const response = await this.wishListAdapter.addItemToWishList({
      skuId: input.skuId,
      customerCode,
    })

    if (isErr(response)) {
      this.notifyAction.handleAddNotify({
        title: getBlock('wishList.errors')('addError'),
        type: 'danger',
      })
      logger.error(response.error.key, response.error)
    }

    this.wishListStore.setProcessingItem(input.skuId, false)
    return response
  }

  async removeItemFromWishList(
    input: Omit<ToggleWishlistInput, 'customerCode'>
  ): PromiseResult<undefined, WishListErrorInstance> {
    this.wishListStore.setProcessingItem(input.skuId, true)
    const customerCode = this.cartStore.isCustomerCart
      ? this.cartStore.cart?.customerURLCode
      : undefined

    const response = await this.wishListAdapter.removeItemFromWishList({
      ...input,
      customerCode,
    })

    if (isErr(response)) {
      this.notifyAction.handleAddNotify({
        title: getBlock('wishList.errors')('removeError'),
        type: 'danger',
      })
      logger.error(response.error.key, response.error)
    }

    this.wishListStore.setProcessingItem(input.skuId, false)
    return response
  }
}
