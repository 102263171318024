import { dateFormat, isSameSecond } from '@nori/app-kit'
import { observer } from '@nori/di'
import { useTranslations } from '@nori/lang-i18n'
import { ConfirmationModal, DropdownMenu, useToggle } from '@nori/ui-kit'

import { useNotesContext } from '../interface/use-notes-context'

import type { NoteEntity } from '../core/note.entity'

type Props = {
  note: NoteEntity
  contactId: number
}

export const NoteListItemView = observer(({ note, contactId }: Props) => {
  const { notesAction } = useNotesContext()
  const t = useTranslations('contacts.notes')
  const isNoteUpdated = !isSameSecond(note.createdAt, note.updatedAt)
  const { isOpen, handleOpen, handleClose } = useToggle()

  return (
    <>
      <div>
        <div className="flex items-center justify-between text-sm text-gray-500">
          <div>
            {dateFormat(note.updatedAt, 'MMM d, y')}{' '}
            {isNoteUpdated && `(${t('item.updated')})`}
          </div>

          <div>
            {note.isEditable && (
              <DropdownMenu placement="right" iconName="PencilAlt">
                <DropdownMenu.Option
                  onClick={() =>
                    notesAction.handleShowEditMode(contactId, note)
                  }
                >
                  {t('item.edit')}
                </DropdownMenu.Option>
                <DropdownMenu.Option onClick={handleOpen}>
                  {t('item.delete')}
                </DropdownMenu.Option>
              </DropdownMenu>
            )}
          </div>
        </div>

        <div className="mt-1.5 break-words text-sm font-medium text-primary-900">
          {note.content}
        </div>
      </div>

      <ConfirmationModal
        title={t('confirmation.title')}
        text={t('confirmation.text')}
        isOpen={isOpen}
        onClose={handleClose}
        onSubmit={() => notesAction.handleDeleteNote(contactId, note.id)}
      />
    </>
  )
})
