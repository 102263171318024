import { getLink } from '@nori/app-kit'

import type { ProductTypeCode } from '@nori/app-kit'
import type { ReportNameValueObject } from '@nori/ibo-dashboards'
import type { ContactTabsLabelValueObject } from '~/modules/contacts/core/contact-tabs-label.value-object'
import type { OrdersListTabsType } from '~/modules/orders-list/core'
import type { CreditNavigationValueObject } from '~/modules/settings-credits-coupons/core/credit-navigation.value-object'
import type { OfficeSuiteNavigationValueObject } from '~/modules/settings-office-suite-subscription/core/office-suite-navigation.value-object'

type ViewType = 'view-type' | 'team-stats' | 'commission-statement'

export const Routes = {
  home: {
    path: () => '/',
  },
  checkout: { path: () => '/checkout' },
  cart: { path: () => '/cart' },
  orders: {
    create: { path: () => '/orders/create' },
    list: {
      path: () => '/orders/list',
      selectedTab: {
        path: (id: OrdersListTabsType) => `${Routes.orders.list.path()}/${id}`,
      },
    },
    view: { path: (orderId: number) => `/orders/${orderId}` },
    confirmation: {
      path: (cartId: number) => `/orders/confirmation?id=${cartId}`,
    },
    returns: {
      path: () => '/orders/list/returns',
    },
    archived: {
      path: (orderId: number) => `/orders/archived/${orderId}`,
    },
  },
  productCatalog: {
    path: () => '/product-catalog',
  },
  reports: {
    base: { path: () => '/reports' },
    selectedReport: {
      path: (id: ReportNameValueObject, viewType?: ViewType) => {
        const query = new URLSearchParams()
        query.append('report-name', id)
        if (viewType) query.append('view-type', viewType)
        return `${Routes.reports.base.path()}?${query.toString()}`
      },
    },
  },
  version: {
    path: () => '/version',
  },
  category: {
    path: (id: number) => `${Routes.productCatalog.path()}/categories/${id}`,
  },
  collection: {
    path: (id: number) => `${Routes.productCatalog.path()}/collections/${id}`,
  },
  product: {
    path: ({ id, type }: { id: string; type: ProductTypeCode }) =>
      `/products/${type}/${id}`,
  },
  contacts: {
    path: () => '/contacts',
    createContact: {
      path: () => `${Routes.contacts.path()}/create`,
    },
    editContact: {
      path: (id: number) => `${Routes.contacts.path()}/${id}/edit`,
    },
    contactDetails: {
      path: ({
        id,
        tab,
      }: {
        id: number
        tab?: ContactTabsLabelValueObject
      }) => {
        const query = new URLSearchParams()
        if (tab) query.append('tab', tab)

        return `${Routes.contacts.path()}/${id}?${query.toString()}`
      },
    },
  },
  events: {
    list: {
      path: () => '/events/list',
    },
    view: {
      path: (partyId: number) => `/events/${partyId}`,
    },
    new: {
      path: () => '/events/new',
    },
    edit: {
      path: (partyId: number) => `/events/${partyId}/edit`,
    },
  },
  shop: {
    path: () => '/orders/add-items',
    isPersonal: {
      path: () => '/orders/add-items?with-personal-cart=true',
    },
    isPersonalAutosave: {
      path: () => '/orders/add-items?with-personal-cart=true&search=autosave',
    },
  },
  settings: {
    chargesAndFees: {
      path: () => '/settings/charges-and-fees',
    },
    account: {
      path: () => '/settings/account-settings',
    },
    password: {
      path: () => '/settings/password',
    },
    paymentMethods: {
      path: () => '/settings/payment-methods',
    },
    creditsCoupons: {
      default: () => Routes.settings.creditsCoupons.path('product'),
      path: (tabId: CreditNavigationValueObject) =>
        `/settings/credits/${tabId}`,
    },
    officeSuite: {
      default: () => Routes.settings.officeSuite.path('personal-website'),
      path: (tabId: OfficeSuiteNavigationValueObject) =>
        `/settings/office-suite/${tabId}`,
    },
    subscriptions: {
      path: () => '/settings/officesuite/billinghistory',
    },
    consultantOffers: {
      path: () => '/settings/office-suite/shop-offers',
    },
    bankingInformation: {
      path: () => '/settings/banking-information',
    },
    hostRewards: {
      path: () => '/settings/host-rewards',
    },
    wishlist: {
      path: () => '/settings/wishlist',
    },
    accountBalance: {
      path: () => '/settings/account-balance',
    },
    autosave: {
      path: () => '/settings/subscriptions',
    },
    nextOrder: {
      path: () => '/settings/subscriptions/next-order',
    },
    importantDocuments: {
      path: () => '/settings/important-documents',
    },
    taxInformation: {
      path: () => '/settings/tax-information',
    },
  },
  auth: {
    signIn: {
      path: () => '/sign-in',
    },
    signUp: {
      path: () => '/sign-up',
    },
    enrollment: {
      path: () => '/enrollment',
    },
    enrollmentConfirmation: {
      path: () => '/enrollment/confirmation',
    },
    forgotPassword: {
      path: () => '/forgot-password',
    },
    resetPassword: {
      path: () => '/reset-password',
    },
  },
  returns: {
    returnOrderDetails: {
      path: (rmasId: string) => `/rmas/${rmasId}`,
    },
  },
  www: {
    join: {
      path: () => getLink({ type: 'bo', personalLink: 'join' }).url,
    },
  },
}
