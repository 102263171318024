import { useEffect } from 'react'

import { observer } from '@nori/di'
import { useTranslations } from '@nori/lang-i18n'
import { Icon, Swap } from '@nori/ui-kit'

import { useCartContactPartyContext } from '../../interface/use-cart-contact-part.context'
import { EditContactModal } from '../edit-contact-modal/edit-contact-modal'

export const Contact = observer(() => {
  const t = useTranslations('contactAndParty')
  const { cartContactPartyStore, contactAndPartyAction } =
    useCartContactPartyContext()

  useEffect(() => {
    contactAndPartyAction.handleInitialLoad()
  }, [])

  return (
    <div className="flex items-center overflow-hidden md:max-w-[50%] lg:max-w-[50%]">
      <div className="mr-5 flex h-10 min-w-[40px] items-center justify-center rounded-full bg-primary-900 text-white">
        <Icon name="UserOutline" />
      </div>
      <div className="flex flex-col gap-1 overflow-hidden">
        <div className="flex flex-col items-start justify-between gap-1">
          <Swap
            is={cartContactPartyStore.isContactSelected}
            isSlot={
              <div
                data-test-id="selected-contact-full-name-title"
                className="grid"
              >
                <span className="truncate text-sm font-bold text-primary-900">
                  {cartContactPartyStore.customerFullName}
                </span>
              </div>
            }
          >
            <span
              data-test-id="no-contact-selected-title"
              className="text-primary-900"
            >
              {t('noContactSelected')}
            </span>
          </Swap>
        </div>
        {cartContactPartyStore.isContactSelected &&
          cartContactPartyStore.isEmailProvided && (
            <div
              data-test-id="selected-contact-email-title"
              className="flex text-sm text-primary-900"
            >
              <Icon name="MailOutline" />
              <p className="ml-1 truncate">
                {cartContactPartyStore.customerEmail}
              </p>
            </div>
          )}
        <div
          data-test-id="edit-contact-button"
          className=" flex cursor-pointer text-sm font-bold text-primary-900"
          onClick={contactAndPartyAction.handleOpenContactModal}
        >
          <Icon name="PencilAlt" />
          <span className="ml-1">{t('edit')}</span>
        </div>
      </div>
      <EditContactModal />
    </div>
  )
})
