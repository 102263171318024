import { useCallback, useEffect, useRef } from 'react';
export function useClickOutside(onClick) {
    const ref = useRef(null);
    const onClickHandler = useCallback((event)=>{
        if (!onClick) return;
        const targetEl = event.target;
        if (!(ref == null ? void 0 : ref.current)) return;
        if (!document.body.contains(targetEl)) return;
        if (!(ref == null ? void 0 : ref.current.contains(targetEl))) {
            onClick();
        }
    }, [
        ref,
        onClick
    ]);
    useEffect(()=>{
        if (!onClick) return;
        // We add this handler at the end of the JS stack
        // So ongoing click event doesn't trigger right away
        const timerId = setTimeout(()=>{
            document.addEventListener('click', onClickHandler);
        }, 0);
        return ()=>{
            clearTimeout(timerId);
            document.removeEventListener('click', onClickHandler);
        };
    }, [
        onClickHandler
    ]);
    return {
        ref
    };
}
