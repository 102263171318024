import { Inject, Store, Toggle } from '@nori/di'
import { FeatureFlagsStore } from '~/modules/feature-flags/interface/store/feature-flags.store'

import { ordersFiltersOptions } from './orders-list-filters-options'

import type { NoriDate } from '@nori/date'
import type {
  FilterValue,
  OrderFilterOption,
  OrderFiltersKeys,
} from '../../core/orders-list-filters'
import type { OrderListFilterTabOption } from '../../core/orders-list-filters-tab-options'

const statusFilters = ['group_status_list[]', 'can_be_moved']

const detailsFilters = [
  'order_type_list[]',
  'rewards',
  'shop_offer',
  'referral_order',
  'sample_order',
]

@Store()
export class OrdersListFiltersStore {
  constructor(
    @Inject() private readonly featureFlagsStore: FeatureFlagsStore
  ) {}

  filterModalToggle = new Toggle()

  dateRange: NoriDate[] = []
  selectedRange = ''

  selectedFilterTab: OrderListFilterTabOption = 'status'

  filtersOptions = [...ordersFiltersOptions]

  appliedFilters: OrderFilterOption[] = []

  get dateRangeSection(): { from: NoriDate; to: NoriDate } | undefined {
    if (!this.dateRange?.length) return

    const [from, to] = this.dateRange

    if (!from || !to) return
    return { from, to }
  }

  get statusFilters(): OrderFilterOption[] {
    return this.filtersOptions.filter((option) => {
      if (!statusFilters.includes(option.filterKey)) return false

      if (option.filterKey === 'can_be_moved') {
        return !!this.featureFlagsStore.getFlag('bo-move-order-enabled')
      }

      return true
    })
  }

  get detailsFilters(): OrderFilterOption[] {
    return this.filtersOptions.filter((option) =>
      detailsFilters.includes(option.filterKey)
    )
  }

  get isAnyFilterApplied(): boolean {
    return !!this.appliedFilters.length
  }

  setSelectedRange(value: string): void {
    this.selectedRange = value
  }

  setDateRange(value: NoriDate[]): void {
    this.dateRange = value
  }

  setSelectedFilterTab(tab: OrderListFilterTabOption): void {
    this.selectedFilterTab = tab
  }

  clearAllFiltersOptions(): void {
    this.filtersOptions = [...ordersFiltersOptions]
  }

  setAppliedFilters(filters: OrderFilterOption[]): void {
    this.appliedFilters = filters
  }

  fillFiltersOptionsByAppliedFilters(): void {
    this.appliedFilters.forEach((appliedFilter) => {
      this.setFilterByKey({
        key: appliedFilter.filterKey,
        filterValue: appliedFilter.value,
        isChecked: true,
      })
    })
  }

  removeAppliedFilter(filter: OrderFilterOption): void {
    const updatedApplied = this.appliedFilters.filter((appliedFilter) => {
      const isNotSameFilter = appliedFilter.filterKey !== filter.filterKey
      const isSameFilterButDifferentValue =
        appliedFilter.filterKey === filter.filterKey &&
        appliedFilter.value !== filter.value
      return isNotSameFilter || isSameFilterButDifferentValue
    })

    this.setAppliedFilters(updatedApplied)
  }

  setFilterByKey<T extends OrderFiltersKeys>({
    key,
    filterValue,
    isChecked,
  }: {
    key: T
    filterValue: FilterValue<T>
    isChecked: boolean
  }): void {
    const filterIndex = this.filtersOptions.findIndex(
      (filterOption) =>
        filterOption.filterKey === key && filterOption.value === filterValue
    )

    if (filterIndex === -1) {
      return
    }

    // @TODO: check for this.filtersOptions[filterIndex] to exist
    this.filtersOptions[filterIndex]!.isChecked = isChecked
  }
}
