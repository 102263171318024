import { useEffect } from 'react'

import { AddressBlock, useQuery } from '@nori/app-kit'
import { observer } from '@nori/di'
import { useTranslations } from '@nori/lang-i18n'
import {
  Button,
  ContentBlock,
  Form,
  Link,
  Loader,
  PageContainer,
  Swap,
} from '@nori/ui-kit'
import { useContactContext } from '~/modules/contacts/interface/use-contact-context'
import { ErrorBlock } from '~/modules/contacts/ui/contact-details-page/error-block'
import { DeleteContactButton } from '~/modules/contacts/ui/edit-contact-page/delete-contact-button'
import { PageLayout } from '~/modules/page-layout'

import { ContactBlock } from '../contact-block'

type Query = {
  id: string
}

export const EditContactPage = observer(() => {
  const t = useTranslations('contacts.edit')
  const { id } = useQuery<Query>()
  const { editContactStore, editContactAction } = useContactContext()

  useEffect(() => {
    if (!id) return
    editContactAction.handleInitialLoad(id)
  }, [id])

  return (
    <PageLayout>
      <PageContainer>
        <ContentBlock>
          <Swap
            is={
              editContactStore.isLoading || !editContactStore.isAvailableToEdit
            }
            isSlot={
              (editContactStore.isLoading && (
                <div className="m-5">
                  <Loader />
                </div>
              )) ||
              (!editContactStore.isAvailableToEdit && (
                <div className="m-5">
                  <ErrorBlock error={editContactStore.error} />
                </div>
              ))
            }
          >
            <div className=" text-2xl font-bold text-primary-900">
              {t('title')}
            </div>
            <hr className="mb-6 mt-2" />
            <Form onSubmit={editContactAction.handleSave}>
              <ContactBlock
                validator={editContactStore.contactValidator}
                isEmailEditable={false}
                isEmailProvided={
                  editContactStore.contact?.isEmailProvided !== false
                }
              />
              <AddressBlock validator={editContactStore.addressValidator} />

              <div className="flex flex-row justify-end md:mt-5 lg:gap-x-16">
                <DeleteContactButton />
                <div className="mr-5 lg:hidden"></div>
                <Link size="large" onClick={editContactAction.handleCancel}>
                  {t('cancel')}
                </Link>
                <div className="mr-5 lg:hidden"></div>
                <div className="w-28 lg:w-52">
                  <Button
                    widthType="full"
                    type="submit"
                    size="lg"
                    isLoading={editContactStore.isSaving}
                    disabled={editContactStore.isDeleting}
                  >
                    {t('save')}
                  </Button>
                </div>
              </div>
            </Form>
          </Swap>
        </ContentBlock>
      </PageContainer>
    </PageLayout>
  )
})
