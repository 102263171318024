import { useMemo } from 'react'

import { type AddressValueObject, formatAddressToString } from '@nori/app-kit'
import { type ContactAddressValueObject } from '~/modules/contacts/core/contact-address.value-object'

export function useFormatAddressToString(
  address?: AddressValueObject | ContactAddressValueObject
): string {
  return useMemo(() => formatAddressToString(address), [address])
}
