import { useState } from 'react'

import { getMoneyWithIsoCurrency, ProductImage } from '@nori/app-kit'
import { useTranslations } from '@nori/lang-i18n'
import { Icon } from '@nori/ui-kit'

import { SkusDropdown } from '../skus-dropdown'

import { Row } from './row'

import type { CommonStyleEntity } from '@nori/app-kit'

type Props = {
  item: CommonStyleEntity
}

export function StyleRow({ item }: Props) {
  const t = useTranslations('autosaveSettings.addItemModal')
  const [sku, setSku] = useState(item.skus?.[0])
  const [subscriptionId, setSubscriptionId] = useState(
    item.defaultProductSubscription.id || -1
  )

  return (
    <Row item={item} subscriptionId={subscriptionId}>
      <div className="flex">
        <ProductImage
          alt="No Image Available"
          width={80}
          height={80}
          styleName="mr-3 h-20 w-20"
          src={item.pictures[0]?.thumbnailMedURL}
        />

        <div className="flex flex-col gap-1">
          <div className="break-all text-base font-bold text-gray-700">
            {item.product.name}
          </div>
          <SkusDropdown sku={sku} skus={item.skus} onChange={setSku} />
          <div className="text-sm font-medium text-gray-500">
            {item.maxPriceSku.code}
          </div>
        </div>
      </div>
      <div className="flex flex-col gap-1">
        {sku && (
          <div className="mt-4 text-sm text-primary-900">
            <div className="text-xs font-normal">{t('subscription')}</div>
            <div className="mt-1 flex gap-1 font-normal">
              {getMoneyWithIsoCurrency({
                cents: sku.currentStylistPrice.cents,
                currency: sku.currentStylistPrice.currency,
              })}
              <Icon name="Refresh" />
            </div>
          </div>
        )}
        {sku && (
          <div className="text-base text-primary-900">
            <div className="text-xs font-normal">{t('oneTime')}</div>
            <div className="font-normal">
              {getMoneyWithIsoCurrency({
                cents: sku.currentRetailPrice.cents,
                currency: sku.currentRetailPrice.currency,
              })}
            </div>
          </div>
        )}
      </div>
    </Row>
  )
}
