import { hookContextFactory } from '@nori/di'
import { SearchProductsAction } from '~/modules/shop-for-myself/interface/action/search-products.action'
import { ShopForMyselfAction } from '~/modules/shop-for-myself/interface/action/shop-for-myself.action'
import { SearchProductsStore } from '~/modules/shop-for-myself/interface/store/search-products.store'
import { ShopForMyselfStore } from '~/modules/shop-for-myself/interface/store/shop-for-myself.store'

export const { useModuleContext: useShopForMyselfContext } = hookContextFactory(
  {
    searchProductsAction: SearchProductsAction,
    searchProductsStore: SearchProductsStore,
    shopForMyselfAction: ShopForMyselfAction,
    shopForMyselfStore: ShopForMyselfStore,
  }
)
