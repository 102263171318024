import { useRouter } from 'next/router'

import { NorwexLogo, useProfileContext } from '@nori/app-kit'
import { observer } from '@nori/di'
import { Avatar, Icon } from '@nori/ui-kit'
import { useHeaderContext } from '~/modules/header/interface/use-header-context'
import { Routes } from '~/modules/routes'

import { CartIcon } from '../shared/cart-icon'

import { UserMenu } from './user-menu'

export const MobileAuthenticatedHeader = observer(() => {
  const { headerAction } = useHeaderContext()
  const { userStore } = useProfileContext()
  const router = useRouter()

  return (
    <div>
      <div className="relative flex h-15 w-full flex-row bg-primary-50">
        <button
          className="flex h-15 w-15 shrink-0 cursor-pointer flex-row items-center justify-center bg-primary-900 text-white"
          onClick={headerAction.handleToggleMenu}
        >
          <Icon name="MenuAlt2" />
        </button>
        <div className="flex h-full w-full flex-row items-center justify-between pl-6 pr-3.5 text-primary-900">
          <button
            onClick={() => router.push(Routes.home.path())}
            className="min-w-20 max-w-[149px]"
          >
            <NorwexLogo size="auto" />
          </button>

          <CartIcon isMobile />

          <div className="flex flex-row items-center">
            <div className="w-2" />
            <Avatar
              isBlank={userStore.currentUser.profilePhoto.isBlank}
              imageUrl={userStore.currentUser.profilePhoto.thumbnailSmallUrl}
              size="sm"
            />
            <div className="w-0" />
            <button onClick={headerAction.handleToggleUserMenu}>
              <Icon name="ChevronDown" size="lg" />
            </button>
          </div>
        </div>
      </div>
      <UserMenu />
    </div>
  )
})
