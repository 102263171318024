import {
  CartService,
  getBaseUrl,
  RequestService,
  SERVICE_NAMES,
} from '@nori/app-kit'
import { Adapter, Inject } from '@nori/di'
import { resultErr, resultOk } from '@nori/result'
import { CartErrors } from '~/modules/cart/core'
import { AddManyToCartMapper } from '~/modules/cart/infra/mappers/add-many-to-cart.mapper'
import { AddToCartErrorMapper } from '~/modules/cart/infra/mappers/add-to-cart-error.mapper'

import { AddToCartRequestMapper } from './mappers/add-to-cart-request.mapper'
import { CartErrorMapper } from './mappers/cart-error.mapper'
import { ChangeCartShippingAddressRequestMapper } from './mappers/change-cart-shipping-address-request.mapper'
import { ValidationMapper } from './mappers/failed-validation.mapper'
import { GetCartMapper } from './mappers/get-cart.mapper'

import type { CartEntity, CartItemEntity } from '@nori/app-kit'
import type { PromiseResult } from '@nori/result'
import type {
  AddManyToCartResponseValueObject,
  CartErrorInstance,
} from '~/modules/cart/core'
import type { AddManyToCartInput } from '~/modules/cart/infra/types/add-many-to-cart/add-many-to-cart.input'
import type { ApplyProductCreditsInput } from '~/modules/cart/infra/types/apply-product-credits.input'
import type { CartErrorWithMessageValueObject } from '../core/cart-error-with-message.value-object'
import type { CreateCartInput } from '../core/create-cart.input'
import type { FailedValidationValueObject } from '../core/failed-validation'
import type {
  BundleAddToCartInput,
  ByCartIdRequest,
  StyleAddToCartInput,
} from './types'
import type { ApplyHostessCreditsInput } from './types/apply-hostess-credits.input'
import type { ApplyHostessDiscountInput } from './types/apply-hostess-discount.input'
import type { ChangeCartShippingInput } from './types/change-cart-shipping.input'

@Adapter()
export class CartAdapter {
  baseUrl = getBaseUrl(SERVICE_NAMES.CART_SERVICE)

  public constructor(
    @Inject() private readonly requestService: RequestService,
    @Inject() private readonly cartService: CartService,
    @Inject() private readonly getCartMapper: GetCartMapper,
    @Inject()
    private readonly addManyToCartMapper: AddManyToCartMapper,
    @Inject()
    private readonly changeCartShippingAddressRequestMapper: ChangeCartShippingAddressRequestMapper
  ) {}

  public async createCart(
    data?: Partial<CreateCartInput>
  ): PromiseResult<CartEntity, CartErrorInstance> {
    try {
      const url = `${this.baseUrl}/carts.json`
      const input = this.getCartMapper.toRequestInput(data)

      const { ok, json, status } = await this.requestService.secured.post(url, {
        body: input,
      })

      if (ok) return resultOk(this.cartService.toCartEntity(json))

      return resultErr(CartErrorMapper.toErrors(status, json))
    } catch (err: unknown) {
      return resultErr(new CartErrors.UnexpectedError(err))
    }
  }

  public async getCartById({
    cartId,
  }: ByCartIdRequest): PromiseResult<CartEntity, CartErrorInstance> {
    try {
      const url = `${this.baseUrl}/carts/${cartId}.json`
      const { ok, status, json } = await this.requestService.secured.get(url)

      if (ok) return resultOk(this.cartService.toCartEntity(json))

      return resultErr(CartErrorMapper.toErrors(status, json))
    } catch (err: unknown) {
      return resultErr(new CartErrors.UnexpectedError(err))
    }
  }

  public async addStyleToCart(
    args: StyleAddToCartInput
  ): PromiseResult<CartEntity, CartErrorInstance> {
    try {
      const url = `${this.baseUrl}/carts/${args.cartId}/add_sku.json`
      const { json, ok, status } = await this.requestService.secured.post(url, {
        body: AddToCartRequestMapper.toStyleAddToCartBody(args),
      })

      if (ok) return resultOk(this.cartService.toCartEntity(json))

      return resultErr(AddToCartErrorMapper.toErrors(status, json))
    } catch (err: unknown) {
      return resultErr(new CartErrors.UnexpectedError(err))
    }
  }

  public async addBundleToCart(
    args: BundleAddToCartInput
  ): PromiseResult<CartEntity, CartErrorInstance> {
    const url = `${this.baseUrl}/carts/${args.cartId}/add_bundle`

    try {
      const { ok, json, status } = await this.requestService.secured.post(url, {
        body: AddToCartRequestMapper.toBundleAddToCartBody(args),
      })

      if (ok) return resultOk(this.cartService.toCartEntity(json))

      return resultErr(AddToCartErrorMapper.toErrors(status, json))
    } catch (err: unknown) {
      return resultErr(new CartErrors.UnexpectedError(err))
    }
  }

  async applyProductCredits({
    cartId,
    amount,
  }: ApplyProductCreditsInput): PromiseResult<CartEntity, CartErrorInstance> {
    const url = `${this.baseUrl}/carts/${cartId}/product_credits`

    try {
      const { ok, json, status } = await this.requestService.secured.post(url, {
        body: { amount, id: cartId },
      })

      if (ok) return resultOk(this.cartService.toCartEntity(json))

      return resultErr(
        CartErrorMapper.toApplyProductCreditsErrors(status, json)
      )
    } catch (err: unknown) {
      return resultErr(new CartErrors.UnexpectedError(err))
    }
  }

  async applyStoreCredits({
    cartId,
    amount,
  }: ApplyProductCreditsInput): PromiseResult<CartEntity, CartErrorInstance> {
    try {
      const url = `${this.baseUrl}/carts/${cartId}/store_credits`
      const { ok, json, status } = await this.requestService.secured.patch(
        url,
        { body: { amount, id: cartId } }
      )

      if (ok) return resultOk(this.cartService.toCartEntity(json))

      return resultErr(CartErrorMapper.toApplyStoreCreditsErrors(status, json))
    } catch (err: unknown) {
      return resultErr(new CartErrors.UnexpectedErrorApplyStoreCredits(err))
    }
  }

  async applyHostessDiscount({
    cartId,
    productId,
    isDiscountApply,
  }: ApplyHostessDiscountInput): PromiseResult<
    CartItemEntity,
    CartErrorWithMessageValueObject
  > {
    try {
      const url = `${this.baseUrl}/carts/${cartId}/items/${productId}/hostess_discount`
      const { ok, json, status } = await this.requestService.secured.post(url, {
        body: { discount: isDiscountApply, id: productId },
      })

      if (ok) return resultOk(this.cartService.toCartItemEntity(json))

      return resultErr(
        CartErrorMapper.toApplyHostessDiscountError(status, json)
      )
    } catch (err: unknown) {
      return resultErr({
        error: new CartErrors.UnexpectedErrorApplyHostessDiscount(err),
      })
    }
  }

  async applyHostessCredits({
    cartId,
    amount,
  }: ApplyHostessCreditsInput): PromiseResult<CartEntity, CartErrorInstance> {
    try {
      const url = `${this.baseUrl}/carts/${cartId}/hostess_multiple_credits`
      const { ok, json, status } = await this.requestService.secured.post(url, {
        body: { amount, id: cartId },
      })

      if (ok) return resultOk(this.cartService.toCartEntity(json))

      return resultErr(
        CartErrorMapper.toApplyHostessCreditsErrors(status, json)
      )
    } catch (err: unknown) {
      return resultErr(new CartErrors.UnexpectedErrorApplyHostessCredits(err))
    }
  }

  async changeCartShipping(input: ChangeCartShippingInput): PromiseResult<
    | { data: CartEntity; type: 'response' }
    | {
        data: FailedValidationValueObject
        type: 'validationFailed'
      },
    CartErrorInstance
  > {
    try {
      const url = `${this.baseUrl}/carts/${input.cart.id}/shipping`
      const body =
        this.changeCartShippingAddressRequestMapper.toRequestBody(input)

      const { ok, json, status } = await this.requestService.secured.patch(
        url,
        { body }
      )

      if (ok)
        return resultOk({
          data: this.cartService.toCartEntity(json),
          type: 'response',
        })

      if (status === 422) {
        return resultOk({
          data: ValidationMapper.toValidationErrors(json),
          type: 'validationFailed',
        })
      }

      return resultErr(
        CartErrorMapper.toChangeCartShippingAddressErrors(status, json)
      )
    } catch (err: unknown) {
      return resultErr(
        new CartErrors.UnexpectedErrorChangeCartShippingAddress(err)
      )
    }
  }

  async addManyToCart(
    input: AddManyToCartInput
  ): PromiseResult<AddManyToCartResponseValueObject, CartErrorInstance> {
    try {
      const url = `${this.baseUrl}/carts/${input.cartId}/add_all.json`
      const { ok, json } = await this.requestService.secured.post(url, {
        body: this.addManyToCartMapper.toRequestBody(input),
      })

      if (ok)
        return resultOk(this.addManyToCartMapper.toResponseValueObject(json))

      return resultErr(new CartErrors.AddManyToCartUnknownError(json))
    } catch (err: unknown) {
      return resultErr(new CartErrors.UnexpectedAddManyToCartError(err))
    }
  }

  async deleteCart(cartId: number): PromiseResult<void, CartErrorInstance> {
    try {
      const url = `${this.baseUrl}/carts/${cartId}`

      const { ok, json, status } = await this.requestService.secured.delete(url)

      if (ok) return resultOk(undefined)
      return resultErr(CartErrorMapper.toErrors(status, json))
    } catch (err: unknown) {
      return resultErr(new CartErrors.UnexpectedDeleteCartError(err))
    }
  }
}
