import { getMoneyWithIsoCurrency, useProfileContext } from '@nori/app-kit'
import { observer } from '@nori/di'
import { useTranslations } from '@nori/lang-i18n'
import { Button } from '@nori/ui-kit'
import { CancelOrderLink } from '~/modules/cart'
import { useCartContext } from '~/modules/cart/interface/context/use-cart-context'

import { CartBundle } from './cart-bundle'
import { CartItem } from './cart-item'

export const OrderSummary = observer(() => {
  const t = useTranslations('shopForMyself.orderSummary')

  const { userStore } = useProfileContext()
  const { cartStore, cartAction } = useCartContext()
  const { cart } = cartStore

  return (
    <div className="h-fit bg-primary-50 p-6 max-lg:hidden lg:min-w-[335px] lg:max-w-[335px]">
      <div className="mb-2 flex items-center justify-between">
        <div className="text-lg font-semibold text-gray-600">{t('title')}</div>
        <div
          className="text-xs font-normal text-gray-600"
          data-test-id="order-summary-item-count"
        >
          {t('items', { count: cartStore.totalItemsCount || 0 })}
        </div>
      </div>
      <div className="my-1 h-px w-full bg-gray-100" />
      <div data-test-id="order-summary-item-list">
        {cart?.items.map((item) => {
          if (item.type === 'bundle')
            return <CartBundle key={`bundle-${item.id}`} bundle={item} />
          return <CartItem key={item.id} item={item} />
        })}
      </div>

      <div className="my-4 flex items-center justify-between text-gray-700">
        <span className="text-base font-medium">{t('subtotal')}</span>
        <span
          className="text-base font-bold"
          data-test-id="order-summary-subtotal"
        >
          {getMoneyWithIsoCurrency(
            cart?.itemTotal || { cents: 0, currency: userStore.currency }
          )}
        </span>
      </div>
      {!!cart?.promotions.length && (
        <div className="my-2 w-full">
          {cart.promotions.map((promotion) => (
            <div
              className="flex items-center justify-between text-gray-700"
              key={promotion.key}
            >
              <span className="text-sm font-medium">{promotion.label}</span>
              <span
                className="text-base font-bold"
                data-test-id={`order-summary-${promotion.label}`}
              >
                {getMoneyWithIsoCurrency(promotion.amount, {
                  isNegative: true,
                })}
              </span>
            </div>
          ))}
        </div>
      )}

      <div className="mb-4 h-px w-full bg-gray-100" />
      <div
        data-test-id="retail-sales"
        className="mb-4 flex items-center justify-center text-sm text-gray-600"
      >
        {getMoneyWithIsoCurrency({
          cents: cart?.retailSales?.cents || 0,
          currency: cart?.retailSales?.currency || userStore.currency,
        })}{' '}
        {t('inRetailSales')}
      </div>

      <div className="flex flex-col">
        <Button
          disabled={!cart?.items?.length}
          onClick={cartAction.handleViewCart}
          size="lg"
          data-test-id={'view-cart-button'}
        >
          {t('viewCart')}
        </Button>

        <div className="mt-6 flex items-center justify-center">
          <CancelOrderLink onCancel={cartAction.handleCancelOrder} />
        </div>
      </div>
    </div>
  )
})
