import { useTranslations } from '@nori/lang-i18n'
import { Button, HelperTextBlock, Link } from '@nori/ui-kit'
import { CancelOrderLink } from '~/modules/cart/ui/cancel-order-link'

import { HelperTextBlockShareCart } from '../helper-text-block-cart-share'

type Props = {
  isShareLinkLoading: boolean
  isCheckoutDisabled: boolean
  isHelperBlockVisible: boolean
  shareErrorTitle: string
  isShareDisabled: boolean
  onCheckout: () => void
  onShare?: () => void
  onSave?: () => void
  onCancel: () => void
}

export function CartSummaryControls({
  isShareLinkLoading,
  isCheckoutDisabled,
  isHelperBlockVisible,
  shareErrorTitle,
  isShareDisabled,
  onCheckout,
  onShare,
  onSave,
  onCancel,
}: Props) {
  const t = useTranslations('cart.summary')

  return (
    <div className="flex flex-col gap-4">
      <Button
        size="base"
        disabled={isCheckoutDisabled}
        onClick={onCheckout}
        data-test-id={'checkout-button'}
      >
        {t('checkout')}
      </Button>
      {isHelperBlockVisible && <HelperTextBlockShareCart onShare={onShare} />}
      {onShare && (
        <Button
          isOutline
          size="base"
          onClick={onShare}
          isLoading={isShareLinkLoading}
          iconLeft="Share"
          data-test-id={'share-bag-button'}
          disabled={isShareDisabled}
        >
          {t('shareThisCart')}
        </Button>
      )}
      {shareErrorTitle && (
        <HelperTextBlock type="error">{shareErrorTitle}</HelperTextBlock>
      )}
      {onSave && (
        <Button
          isOutline
          size="base"
          onClick={onSave}
          data-test-id={'save-bag-for-later-button'}
        >
          {t('saveCartForLater')}
        </Button>
      )}
      <div className="text-center">
        <CancelOrderLink onCancel={onCancel} />
      </div>
    </div>
  )
}
