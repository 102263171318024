import { getBaseUrl, RequestService, SERVICE_NAMES } from '@nori/app-kit'
import { Adapter, Inject } from '@nori/di'
import { resultErr, resultOk } from '@nori/result'
import { ContactErrors } from '~/modules/contacts/core'
import { FindOrCreateContactRequestMapper } from '~/modules/contacts/infra/mappers/find-or-create-contact-request.mapper'

import { ContactErrorMapper } from './mappers/contact-error.mapper'
import { ContactsMapper } from './mappers/contacts.mapper'
import { CreateContactRequestMapper } from './mappers/create-contact-request.mapper'
import { DeleteContactErrorMapper } from './mappers/delete-contact-error.mapper'
import { EditContactRequestMapper } from './mappers/edit-contact-request.mapper'
import { EnsureContactMapper } from './mappers/ensure-contact.mapper'
import { ValidationMapper } from './mappers/failed-validation.mapper'
import { GetContactsRequestMapper } from './mappers/get-contacts-request.mapper'

import type { PromiseResult } from '@nori/result'
import type {
  ContactDetailsEntity,
  ContactErrorInstance,
  SearchContactsInput,
} from '~/modules/contacts/core'
import type { ContactsValueObject } from '~/modules/contacts/core/contacts.value-object'
import type { EditContactInput } from '~/modules/contacts/infra/types/edit/edit-contact.input'
import type { FindOrCreateContactInput } from '~/modules/contacts/infra/types/find-or-create/find-or-create-contact.input'
import type { EnsureContactEntity } from '../core/ensure-contact.entity'
import type { FailedValidationValueObject } from '../core/failed-validation.value-object'
import type { CreateContactInput } from './types/create/create-contact.input'
import type { EnsureContactResponse } from './types/ensure/ensure-contact.response'
import type { FindContactsByDsrAndCustomerIdsInput } from './types/find-contact-by-dsr-customer-ids.input'

@Adapter()
export class ContactAdapter {
  baseUrl = getBaseUrl(SERVICE_NAMES.MY_BUSINESS_SERVICE)

  constructor(
    @Inject() private readonly requestService: RequestService,
    @Inject() private readonly contactsMapper: ContactsMapper,
    @Inject()
    private readonly getContactsRequestMapper: GetContactsRequestMapper
  ) {}

  async findOrCreateContact(input: FindOrCreateContactInput): PromiseResult<
    | { data: ContactDetailsEntity; type: 'response' }
    | {
        data: FailedValidationValueObject
        type: 'validationFailed'
      },
    ContactErrorInstance
  > {
    const body = FindOrCreateContactRequestMapper.toRequestBody(input)
    const url = `${this.baseUrl}/contacts/find_or_create`
    try {
      const { ok, json, status } = await this.requestService.secured.post<any>(
        url,
        { body }
      )
      if (ok) {
        return resultOk({
          data: this.contactsMapper.toContactDetailsEntity(json),
          type: 'response',
        })
      }
      if (status === 422) {
        return resultOk({
          data: ValidationMapper.toValidationErrors(json),
          type: 'validationFailed',
        })
      }
      return resultErr(ContactErrorMapper.toErrors(status, json))
    } catch (err: unknown) {
      return resultErr(new ContactErrors.UnexpectedError(err))
    }
  }

  async ensureContact(
    contactId: number
  ): PromiseResult<EnsureContactEntity, ContactErrorInstance> {
    const url = `${this.baseUrl}/contacts/${contactId}/ensure_customer`
    try {
      const { ok, json, status } =
        await this.requestService.secured.post<EnsureContactResponse>(url, {
          body: { id: contactId },
        })
      if (ok) {
        return resultOk(EnsureContactMapper.toEntity(json))
      }
      return resultErr(ContactErrorMapper.toErrors(status, json))
    } catch (err: unknown) {
      return resultErr(new ContactErrors.UnexpectedError(err))
    }
  }

  async getContactById(
    id: string
  ): PromiseResult<ContactDetailsEntity, ContactErrorInstance> {
    const url = `${this.baseUrl}/contacts/${id}`

    try {
      const response = await this.requestService.secured.get<any>(url)

      if (response.ok) {
        return resultOk(
          this.contactsMapper.toContactDetailsEntity(response.json)
        )
      }

      return resultErr(new ContactErrors.GetContactById(response.json))
    } catch (err: unknown) {
      return resultErr(new ContactErrors.UnexpectedGetContactById(err))
    }
  }

  async searchContacts({
    page = 1,
    perPage = 20,
    ...args
  }: SearchContactsInput): PromiseResult<
    ContactsValueObject,
    ContactErrorInstance
  > {
    try {
      const query = this.getContactsRequestMapper.toRequest({
        page,
        perPage,
        ...args,
      })
      const url = `${this.baseUrl}/contacts/search?${query}`
      const response = await this.requestService.secured.get<any>(url, {
        controller: args?.controller,
      })

      if (response.ok) {
        return resultOk(
          this.contactsMapper.toContactsValueObject(response.json)
        )
      }

      return resultErr(new ContactErrors.GetContacts(response.json))
    } catch (err: unknown) {
      return resultErr(ContactErrorMapper.toErrors(0, err))
    }
  }

  async createContact(input: CreateContactInput): PromiseResult<
    | { data: ContactDetailsEntity; type: 'response' }
    | {
        data: FailedValidationValueObject
        type: 'validationFailed'
      },
    ContactErrorInstance
  > {
    const url = `${this.baseUrl}/contacts`

    try {
      const response = await this.requestService.secured.post(url, {
        body: CreateContactRequestMapper.toRequestBody(input),
      })

      if (response.ok) {
        return resultOk({
          data: this.contactsMapper.toContactDetailsEntity(response.json),
          type: 'response',
        })
      }
      if (response.status === 422) {
        return resultOk({
          data: ValidationMapper.toValidationErrors(response.json),
          type: 'validationFailed',
        })
      }

      return resultErr(
        ContactErrorMapper.toErrors(response.status, response.json)
      )
    } catch (err: unknown) {
      return resultErr(new ContactErrors.UnexpectedError(err))
    }
  }

  async createContactWithMinimumParams(
    input: FindOrCreateContactInput
  ): PromiseResult<
    | { data: ContactDetailsEntity; type: 'response' }
    | {
        data: FailedValidationValueObject
        type: 'validationFailed'
      },
    ContactErrorInstance
  > {
    const url = `${this.baseUrl}/contacts`
    try {
      const response = await this.requestService.secured.post(url, {
        body: FindOrCreateContactRequestMapper.toRequestBody(input),
      })
      if (response.ok) {
        return resultOk({
          data: this.contactsMapper.toContactDetailsEntity(response.json),
          type: 'response',
        })
      }
      if (response.status === 422) {
        return resultOk({
          data: ValidationMapper.toValidationErrors(response.json),
          type: 'validationFailed',
        })
      }
      return resultErr(
        ContactErrorMapper.toErrors(response.status, response.json)
      )
    } catch (err: unknown) {
      return resultErr(new ContactErrors.UnexpectedCreateMinimumError(err))
    }
  }
  async editContact(input: EditContactInput): PromiseResult<
    | { data: ContactDetailsEntity; type: 'response' }
    | {
        data: FailedValidationValueObject
        type: 'validationFailed'
      },
    ContactErrorInstance
  > {
    const url = `${this.baseUrl}/contacts/${input.id}`
    try {
      const response = await this.requestService.secured.put<any>(url, {
        body: EditContactRequestMapper.toRequestBody(input),
      })
      if (response.ok) {
        return resultOk({
          data: this.contactsMapper.toContactDetailsEntity(response.json),
          type: 'response',
        })
      }
      if (response.status === 422) {
        return resultOk({
          data: ValidationMapper.toValidationErrors(response.json),
          type: 'validationFailed',
        })
      }
      return resultErr(
        ContactErrorMapper.toErrors(response.status, response.json)
      )
    } catch (err: unknown) {
      return resultErr(new ContactErrors.UnexpectedError(err))
    }
  }
  async deleteContact(id: number): PromiseResult<void, ContactErrorInstance> {
    const url = `${this.baseUrl}/contacts/${id}`
    try {
      const response = await this.requestService.secured.delete<any>(url)
      if (response.ok) return resultOk(undefined)

      return resultErr(
        DeleteContactErrorMapper.toErrors(response.status, response.json)
      )
    } catch (err: unknown) {
      return resultErr(new ContactErrors.UnexpectedError(err))
    }
  }
  async findContactByDsrAndCustomerIds(
    input: FindContactsByDsrAndCustomerIdsInput
  ): PromiseResult<ContactDetailsEntity, ContactErrorInstance> {
    const url = `${this.baseUrl}/contacts/find?dsr_id=${input.dsrId}&customer_id=${input.customerId}`
    try {
      const response = await this.requestService.secured.get(url)

      if (response.ok) {
        return resultOk(
          this.contactsMapper.toContactDetailsEntity(response.json)
        )
      }
      return resultErr(
        ContactErrorMapper.toErrors(response.status, response.json)
      )
    } catch (err: unknown) {
      return resultErr(
        new ContactErrors.UnexpectedFindContactByDsrAndCustomerIds(err)
      )
    }
  }
}
