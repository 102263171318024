import { useProfileContext, useSignOutContext } from '@nori/app-kit'
import { observer } from '@nori/di'
import { useTranslations } from '@nori/lang-i18n'
import { Avatar, Icon, Link as HyperLink } from '@nori/ui-kit'

import { ChildrenList } from './children-list'

import type { LinkWithChildren } from '../../header-links/header-link.type'

type Props = {
  links: LinkWithChildren
}

export const UserDropDown = observer(({ links }: Props) => {
  const t = useTranslations('header')
  const { signOutAction } = useSignOutContext()
  const { userStore } = useProfileContext()

  return (
    <div className="flex flex-col">
      <div className="flex flex-row bg-primary-50 px-5 py-5">
        <div className="flex w-full flex-col items-start gap-1.5">
          <div className="flex w-full flex-row items-center">
            <Avatar
              imageUrl={userStore.currentUser.profilePhoto.thumbnailSmallUrl}
              size="md"
              isBlank={userStore.currentUser.profilePhoto.isBlank}
            />
            <div className="ml-4 w-full truncate text-lg font-normal text-primary-900">
              {userStore.currentUser.displayName}
            </div>
          </div>
          <div className="w-full truncate text-lg font-normal text-primary-900">
            <span className="text-xs font-bold text-gray-600">
              {t('userMenu.status')}:{' '}
            </span>
            <span className="text-xs font-normal capitalize text-gray-900">
              {userStore.nemusStatus} | {userStore.careerRank}
            </span>
          </div>
          {userStore.personalLink && (
            <HyperLink
              data-test-id="header-user-dropdown-pws-link"
              href={userStore.personalLink?.url}
              isTruncate
              justifyContent="normal"
            >
              {userStore.personalLink?.title}
            </HyperLink>
          )}
          <div className="text-xs font-light text-gray-600">
            {t('userMenu.id')}: {userStore.currentUser.dsrLogin}
          </div>
        </div>
      </div>
      <div className="mt-5 px-3 pb-5">
        <ChildrenList linkChildren={links.children} />
        <button
          onClick={signOutAction.handleSignOut}
          className="flex w-full flex-row items-center gap-1 px-2 py-2 text-sm font-bold text-primary-900 hover:bg-primary-50"
          data-test-id="sign-out-option"
        >
          <Icon size="xs" name="Logout" />
          {t('userMenu.signOut')}
        </button>
      </div>
    </div>
  )
})
