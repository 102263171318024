import { Action, Inject } from '@nori/di'
import { isErr } from '@nori/result'
import { createLogger } from '~/infra/create-logger'
import { ContactSelectorStore } from '~/modules/contacts/interface/store/contact-selector.store'

import { LoadContactsService } from '../services/contact.service'

const logger = createLogger('contact-selector.action')

const MIN_SEARCH_QUERY_LENGTH = 3

@Action()
export class ContactSelectorAction {
  constructor(
    @Inject() private readonly contactSelectorStore: ContactSelectorStore,
    @Inject() private readonly contactService: LoadContactsService
  ) {}

  handleGoBack(): void {
    this.contactSelectorStore.setActiveTab('search')
  }

  async handleSearchQueryChange(query: string): Promise<void> {
    this.contactSelectorStore.setSearchQuery(query)
    if (query.length < MIN_SEARCH_QUERY_LENGTH) return

    this.contactSelectorStore.setIsContactsLoading(true)
    this.contactSelectorStore.setContactsLoadingError(undefined)

    await this.getContactsBySearchQuery()

    this.contactSelectorStore.setIsContactsLoading(false)
  }

  handleDropSearchQuery(): void {
    this.contactSelectorStore.setSearchQuery('')
  }

  private async getContactsBySearchQuery(): Promise<void> {
    const { searchQuery } = this.contactSelectorStore
    const result = await this.contactService.searchContacts({
      searchQuery,
      sortBy: '_score',
      order: 'desc',
      searchFilter: 'customer_name_email_address',
    })

    if (isErr(result)) {
      logger.error(result.error.key, result.error)
      this.contactSelectorStore.setContactsLoadingError(result.error)
      return
    }

    this.contactSelectorStore.setContacts(result.data)
  }
}
