import {
  BundlePrices,
  ProductListCell,
  ProductListCellContainer,
} from '@nori/app-kit'

import type { CommonBundleEntity } from '@nori/app-kit'

type Props = {
  item: CommonBundleEntity
  onClick: (item: CommonBundleEntity) => void
}

export function BundleCell({ item, onClick }: Props) {
  const { maxPrice, maxRetailPrice, maxDsrPrice } = item

  return (
    <ProductListCellContainer>
      <ProductListCell item={item} onClick={() => onClick(item)} />
      <BundlePrices
        prices={{
          maxPrice,
          maxRetailPrice,
          maxDsrPrice,
        }}
      />
    </ProductListCellContainer>
  )
}
