import { useState } from 'react'

import {
  ProductListCell,
  ProductListCellContainer,
  StylePrices,
} from '@nori/app-kit'
import { Radio } from '@nori/ui-kit'

import type { CommonStyleArrayEntity, CommonStyleEntity } from '@nori/app-kit'

type Props = {
  item: CommonStyleArrayEntity<CommonStyleEntity>
  onClick: (item: CommonStyleEntity) => void
}

export function ArrayCell({ item, onClick }: Props) {
  const { styles } = item
  const [currentStyle, setCurrentStyle] = useState<
    CommonStyleEntity | undefined
  >(styles[0])

  const maxPriceSku = currentStyle?.maxPriceSku
  const hasMultipleStyles = styles.length > 1

  if (!currentStyle || !styles.length) return null
  return (
    <ProductListCellContainer>
      <ProductListCell
        item={currentStyle}
        onClick={() => onClick(currentStyle)}
      />
      <StylePrices
        prices={{
          prv: maxPriceSku?.currentPrv,
          retailPrice: maxPriceSku?.currentRetailPrice,
          salePrice: maxPriceSku?.currentSalePrice,
          stylistPrice: maxPriceSku?.currentStylistPrice,
        }}
      />
      {hasMultipleStyles && (
        <div className="my-4 flex flex-row justify-center">
          {styles.map((style) => (
            <Radio
              checked={style.id === currentStyle.id}
              value={style.id}
              key={style.id}
              onChange={() => setCurrentStyle(style)}
            />
          ))}
        </div>
      )}
    </ProductListCellContainer>
  )
}
