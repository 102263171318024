import { Service } from '@nori/di'

import type { ChangeCartShippingInput } from '../types/change-cart-shipping.input'
import type {
  ChangeCartShippingRequest,
  ShippingAddressAttributes,
} from '../types/change-cart-shipping.request'

@Service()
export class ChangeCartShippingAddressRequestMapper {
  toRequestBody({
    cart,
    shippingAttributes,
    shippingRate,
  }: ChangeCartShippingInput): ChangeCartShippingRequest {
    const request: ChangeCartShippingRequest = {
      new_shipping_rate_key: shippingRate?.key,
      default_shipping_address: cart.defaultShippingAddress,
      save_as_contact_address: cart.saveAsContactAddress,
      gift_message: [],
    }

    if (shippingAttributes) {
      request.shipping_address_attributes =
        this.toShippingAddressAttributes(shippingAttributes)
    }

    return request
  }

  private toShippingAddressAttributes(
    shippingAttributes: ChangeCartShippingInput['shippingAttributes']
  ): ShippingAddressAttributes {
    return {
      address1: shippingAttributes?.address1,
      address2: shippingAttributes?.address2,
      city: shippingAttributes?.city,
      state: shippingAttributes?.state,
      zip_code: shippingAttributes?.zipCode,
      country: shippingAttributes?.country,
      first_name: shippingAttributes?.firstName,
      last_name: shippingAttributes?.lastName,
      phone: shippingAttributes?.phone,
      sequence_number: shippingAttributes?.sequenceNumber,
    }
  }
}
