import { SignInForm as SignInFormKit } from '@nori/app-kit'
import { useLoginAccountContext } from '~/modules/auth/interface/context/use-login-account.context'
import { Routes } from '~/modules/routes'

export function SignInForm() {
  const { loginAccountAction } = useLoginAccountContext()
  return (
    <SignInFormKit
      createAccountPath={Routes.www.join.path()}
      onSuccess={loginAccountAction.handleLogin}
    />
  )
}
