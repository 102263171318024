import { observer } from '@nori/di'
import { useTranslations } from '@nori/lang-i18n'
import { Button, Icon, Input, useToggle } from '@nori/ui-kit'
import { useCartContext } from '~/modules/cart'

import { useCouponCodeContext } from '../interface/use-coupon-code-context'

export const CartCouponCodeForm = observer(() => {
  const t = useTranslations()
  const { couponCodeStore, couponCodeAction } = useCouponCodeContext()
  const { cartStore } = useCartContext()

  const { isOpen, handleToggle } = useToggle()

  const iconName = isOpen ? 'ChevronUp' : 'ChevronDown'

  return (
    <div className="flex flex-col">
      <div
        onClick={handleToggle}
        className="flex cursor-pointer items-center justify-between text-gray-600"
      >
        <p className="text-sm font-normal">{t('CouponCode.haveACouponCode')}</p>
        <Icon name={iconName} />
      </div>

      {isOpen && (
        <form className="mt-3 flex items-start gap-3">
          <Input
            size="small"
            placeholder={t('CouponCode.promoCode')}
            type="text"
            value={couponCodeStore.couponCodeValidator.values.couponCode}
            onChange={couponCodeAction.couponCodeHandler.couponCode}
            error={couponCodeStore.couponCodeValidator.errors.couponCode}
            disabled={couponCodeStore.couponCodeState.isLoading}
          />
          <Button
            size="base"
            isLoading={couponCodeStore.couponCodeState.isLoading}
            onClick={couponCodeAction.handleApplyCouponCode}
          >
            {t('CouponCode.apply')}
          </Button>
        </form>
      )}
    </div>
  )
})
