import { useEffect } from 'react';
export function useToggleClassName(element, className, isApply) {
    useEffect(()=>{
        if (!isApply) {
            return;
        }
        if (isApply) {
            element.classList.add(className);
        }
        return ()=>{
            element.classList.remove(className);
        };
    }, [
        isApply
    ]);
}
