import { HistoryService } from '@nori/app-kit'
import { Action, Inject } from '@nori/di'
import { OrderWizardService } from '~/modules/order-wizard/interface/service/order-wizard.service'
import { Routes } from '~/modules/routes'

import { LoadOrdersService } from '../service/load-orders.service'
import { OrdersListStore } from '../stores/orders-list.store'

import type { SortDirectionType } from '@nori/ui-kit'
import type {
  OrderListSortBy,
  OrdersListTabsType,
} from '~/modules/orders-list/core'
import type { OrderListColumnsSelectionMapValueObject } from '~/modules/orders-list/core/order-list-columns-selection-map.value-object'

const INITIAL_PAGE = 1
const MIN_SEARCH_QUERY_LENGTH = 3

@Action()
export class OrdersListAction {
  constructor(
    @Inject() private readonly loadOrdersService: LoadOrdersService,
    @Inject() private readonly ordersListStore: OrdersListStore,
    @Inject() private readonly historyService: HistoryService,
    @Inject() private readonly orderWizardService: OrderWizardService
  ) {}

  async handleSearchQueryChange(searchQuery: string): Promise<void> {
    const shouldResetSearchQuery =
      this.ordersListStore.queryFilters.values.query && searchQuery === ''

    const isLengthNotEnough = searchQuery.length < MIN_SEARCH_QUERY_LENGTH

    if (isLengthNotEnough && !shouldResetSearchQuery) return

    await this.ordersListStore.queryFilters.handlers.query(searchQuery)
    await this.handleLoad({
      page: INITIAL_PAGE,
    })
  }

  async handleSortChange({
    sort,
    order,
  }: {
    sort: OrderListSortBy
    order: SortDirectionType
  }): Promise<void> {
    this.ordersListStore.setSortBy(sort)
    this.ordersListStore.setSortOrder(order)

    await this.handleLoad({})
  }

  async handleInitialLoad(partyId?: number): Promise<void> {
    this.ordersListStore.resetFilters()
    this.ordersListStore.setPartyId(partyId)

    await this.handleLoad({ page: INITIAL_PAGE })
  }

  async handleLoad({ page }: { page?: number }): Promise<void> {
    await this.loadOrdersService.loadOrderList({ page })
  }

  handleApplyColumnsVisibility(
    value: OrderListColumnsSelectionMapValueObject
  ): void {
    this.ordersListStore.applyColumnsVisibility(value)
  }

  handleRedirectToCreatePersonalOrder(): void {
    this.historyService.push(Routes.shop.isPersonal.path())
  }

  handleResetFilters(): void {
    this.ordersListStore.resetFilters()
  }

  async handleRedirectToCreateOrder(): Promise<void> {
    await this.orderWizardService.initNewCustomerOrder()
  }
}
