import { hookContextFactory } from '@nori/di'

import { CartBannerAction } from './actions/cart-banner.action'
import { PennyCheckoutSummaryAction } from './actions/penny-checkout-summary.action'
import { CartBannerStore } from './store/cart-banner.store'
import { PennyCheckoutSummaryStore } from './store/penny-checkout-summary.store'

export const { useModuleContext: usePennyContext } = hookContextFactory({
  cartBannerStore: CartBannerStore,
  cartBannerAction: CartBannerAction,

  pennyCheckoutSummaryStore: PennyCheckoutSummaryStore,
  pennyCheckoutSummaryAction: PennyCheckoutSummaryAction,
})
