import { errorFactory } from '@nori/errors'

import type { KeyOfErrors } from '@nori/errors'

export const { ArchivedOrderErrors } = errorFactory('ArchivedOrderErrors', {
  GetArchivedOrderBadRequest: 'Bad Request Get Archived Order',
  GetArchivedOrderUnexpectedError: 'Unexpected Error Get Archived Order',
  GetArchivedOrderUnknownError: 'Unknown Error Get Archived Order',
})

export type ArchivedOrderErrorInstance = InstanceType<
  KeyOfErrors<typeof ArchivedOrderErrors>
>
