import { ExportCsvService, I18nStore, StoreEnum } from '@nori/app-kit'
import { Action, Inject } from '@nori/di'

import { OrdersListStore } from '../stores/orders-list.store'
import { OrdersListFiltersStore } from '../stores/orders-list-filters.store'

const EXPORT_COLUMNS = [
  'customer_first_name',
  'customer_last_name',
  'date',
  'order_number',
  'type',
  'order_total',
  'total_discount_earned',
  'shop_offer_fee',
  'retail_sales',
  'status',
  'social',
  'earnings',
  'amount_due',
  'total_earned_redeemed',
  'net_discount_earned',
  'party_host',
]

@Action()
export class OrdersListExportsAction {
  constructor(
    @Inject() private readonly exportCsvService: ExportCsvService,
    @Inject() private readonly ordersListStore: OrdersListStore,
    @Inject() private readonly ordersListFiltersStore: OrdersListFiltersStore,
    @Inject() private readonly i18nStore: I18nStore
  ) {}

  async handleExportAll(): Promise<void> {
    const orderStore = this.ordersListStore

    const appliedFilters = this.ordersListFiltersStore.appliedFilters.reduce(
      (acc, filter) => {
        const filled = acc[filter.filterKey] || []
        filled.push(String(filter.value))
        acc[filter.filterKey] = filled

        return acc
      },
      {} as Record<string, string[]>
    )

    const formatDates = this.ordersListFiltersStore.dateRangeSection

    const fieldId = orderStore.partyId
    const isPlaceOrders = !!fieldId

    await this.exportCsvService.exportCSV({
      fieldId: isPlaceOrders ? String(fieldId) : undefined,
      type: isPlaceOrders ? 'place-orders' : 'orders',
      locale: this.i18nStore.currentLocale,
      storeValue: StoreEnum.US,
      payload: {
        pagination: {
          page: 1,
          perPage: orderStore.pagination?.totalCount,
        },
        sort: {
          direction: orderStore.sortOrder,
          by: orderStore.sortBy,
        },
        search: {
          filter: 'customer_hostess_order_id', // orderStore.searchFilterKey
          params: this.ordersListStore.queryFilters.values.query,
        },
        columns: EXPORT_COLUMNS,
        extraFields: {
          from: formatDates?.from.backendTimestamp,
          to: formatDates?.to.backendTimestamp,
          ...appliedFilters,
        },
      },
    })
  }
}
