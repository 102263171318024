import {
  CategoryAction as CategoryActionCommon,
  CategoryByIdStore,
  CategoryStore,
} from '@nori/app-kit'
import { hookContextFactory } from '@nori/di'
import { CategoryAction } from '~/modules/categories/interface/actions/category.action'

export const { useModuleContext: useCategoryContext } = hookContextFactory({
  categoryActionCommon: CategoryActionCommon,
  categoryAction: CategoryAction,
  categoryStore: CategoryStore,
  categoryByIdStore: CategoryByIdStore,
})
