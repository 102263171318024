import { useEffect } from 'react'

import { useCouponsContext, useCreditsContext } from '@nori/app-kit'
import { observer } from '@nori/di'
import { useContactContext } from '~/modules/contacts/interface/use-contact-context'

import { PerksBlock } from '../../perks-block/perks-block'

export const PerksSummary = observer(() => {
  const { couponsAction } = useCouponsContext()
  const { creditsStore, creditsAction } = useCreditsContext()
  const { contactDetailsStore, contactDetailsAction } = useContactContext()
  const customerId = contactDetailsStore.contact?.customerId

  useEffect(() => {
    if (!customerId) return

    contactDetailsAction.handleLoadFreeShipping(customerId)
    creditsAction.handleLoadCreditsInfo(customerId)
    couponsAction.handleLoadCouponsCount()
  }, [customerId])

  if (
    !contactDetailsStore.contact ||
    !creditsStore.creditsInfo ||
    contactDetailsStore.isLoadingFreeShipping
  )
    return null

  return (
    <div className="bg-primary-50 p-5 lg:p-10">
      <PerksBlock
        contact={contactDetailsStore.contact}
        credits={creditsStore.creditsInfo}
        hasFreeShipping={contactDetailsStore.hasFreeShipping}
      />
    </div>
  )
})
