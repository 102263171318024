import { DataState, Inject, Store } from '@nori/di'
import { CartStore } from '~/modules/cart/interface/store/cart-store/cart.store'
import { CartUserCreditsStore } from '~/modules/user-credits/interface/store/cart-user-credits.store'

import type { MoneyValueObject } from '@nori/app-kit'
import type { DomainError } from '@nori/errors'
import type { ApplyCreditsStoreInterface } from './apply-credits.interface'

@Store()
export class ApplyProductCreditsStore implements ApplyCreditsStoreInterface {
  constructor(
    @Inject()
    private readonly cartStore: CartStore,
    @Inject()
    private readonly cartUserCreditsStore: CartUserCreditsStore
  ) {}

  updatedCredits = new DataState<null, DomainError>({
    isLoading: false,
  })

  creditInputValue = ''

  get creditsToApply(): MoneyValueObject | undefined {
    if (this.applicableCartCredits && this.creditAmount)
      return this.applicableCartCredits.cents > this.creditAmount.cents
        ? this.creditAmount
        : this.applicableCartCredits
  }

  get applicableCartCredits(): MoneyValueObject | undefined {
    const creditLimits = this.cartStore.cart?.creditLimits
    if (!creditLimits) return

    const productCredits = this.creditAmount
    const maxProductCreditLimits = creditLimits.product_credits.max

    if (productCredits && maxProductCreditLimits)
      return productCredits.cents < maxProductCreditLimits.cents
        ? productCredits
        : maxProductCreditLimits
  }

  get hasApplicableCredits(): boolean {
    return (
      (this.applicableCartCredits?.cents &&
        this.applicableCartCredits?.cents > 0) ||
      false
    )
  }

  get creditAmount(): MoneyValueObject | undefined {
    return {
      cents:
        this.cartUserCreditsStore.cartUserCredits.data?.productCredits || 0,
      currency:
        this.cartUserCreditsStore.cartUserCredits.data?.currency || 'USD',
    }
  }

  get creditError(): DomainError | undefined {
    return this.updatedCredits.error
  }

  get isLoading(): boolean {
    return this.updatedCredits.isLoading
  }

  get isShowApplyCredits(): boolean {
    const productCredits =
      this.cartUserCreditsStore.cartUserCredits.data?.productCredits

    return !!(this.hasApplicableCredits && productCredits && productCredits > 0)
  }

  get appliedCredits(): MoneyValueObject | undefined {
    return this.cartStore.cart?.productCreditsTotal
  }

  get isShowConfirmationModal(): boolean {
    const productCredits =
      this.cartUserCreditsStore.cartUserCredits.data?.productCredits

    const isAppliedCreditsFullyApplied =
      this.appliedCredits?.cents === this.creditAmount?.cents ||
      this.appliedCredits?.cents === this.applicableCartCredits?.cents

    return !!(
      productCredits &&
      productCredits > 0 &&
      this.hasApplicableCredits &&
      !isAppliedCreditsFullyApplied
    )
  }

  setValue(value: string): void {
    this.creditInputValue = value
  }
}
