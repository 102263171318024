import { getMoneyWithIsoCurrency } from '@nori/app-kit'

import type { SubscriptionEntity } from '~/modules/subscription'

type Props = {
  subscription: SubscriptionEntity
}

export function AutoshipPrice({ subscription }: Props) {
  const priceStyleClass = subscription.price ? 'line-through' : ''
  const oldPrice = subscription.salePrice ?? subscription.retailPrice

  if (subscription.oneOff) {
    return <></>
  }

  return (
    <>
      {subscription.price && (
        <div className="text-sm text-gray-700">
          {getMoneyWithIsoCurrency({
            cents: subscription.price.cents,
            currency: subscription.price.currency,
          })}
        </div>
      )}
      {oldPrice && (
        <div className={`${priceStyleClass} text-sm text-gray-500`}>
          {getMoneyWithIsoCurrency({
            cents: oldPrice.cents,
            currency: oldPrice.currency,
          })}
        </div>
      )}
    </>
  )
}
