import { ContentBlock, PageContainer } from '@nori/ui-kit'
import { PageLayout } from '~/modules/page-layout'
import { CartBanner } from '~/modules/penny/ui/cart-banner/cart-banner'
import { UserCredits } from '~/modules/user-credits'

import { Cart } from './cart'
import { CartPageHeader } from './cart-page-header'

export const CartPage = () => {
  return (
    <PageLayout>
      <CartPageHeader />
      <ContentBlock>
        <CartBanner />

        <div className="my-7 max-sm:my-0" />
        <UserCredits />
        <div className="mt-13 max-sm:my-0" />
      </ContentBlock>
      <Cart />
    </PageLayout>
  )
}
