import { Action, Inject } from '@nori/di'

import { LoadContactsService } from '../services/contact.service'
import { ContactFiltersStore } from '../store/contact-filters.store'

import type { TabOptions } from '../../core/filters/contact-filter-tab-option'
import type {
  ContactFilterOption,
  ContactFiltersKeys,
  FilterValue,
} from '../../core/filters/filter-options'

const START_PAGE = 1

@Action()
export class ContactFiltersAction {
  constructor(
    @Inject() private readonly contactFiltersStore: ContactFiltersStore,
    @Inject() private readonly loadContactsService: LoadContactsService
  ) {}

  handleOpenFilters(): void {
    this.contactFiltersStore.clearAllFiltersOptions()
    this.contactFiltersStore.fillFiltersOptionsByAppliedFilters()

    this.contactFiltersStore.filtersDropdownToggle.handleOpen()
  }

  handleCloseFilters(): void {
    this.contactFiltersStore.filtersDropdownToggle.handleClose()
  }

  handleSetActiveTab(tab: TabOptions): void {
    this.contactFiltersStore.setActiveTab(tab)
  }

  async handleApplyFilters(): Promise<void> {
    const checkedFilters = this.contactFiltersStore.filtersOptions.filter(
      (filterOption) => filterOption.isChecked
    )
    this.contactFiltersStore.setAppliedFilters(checkedFilters)

    this.handleCloseFilters()
    await this.loadContactsService.loadContacts({ page: START_PAGE })
  }

  async handleRemoveOneFilter(
    appliedFilter: ContactFilterOption
  ): Promise<void> {
    this.contactFiltersStore.removeAppliedFilter(appliedFilter)

    await this.loadContactsService.loadContacts({ page: START_PAGE })
  }

  handleToggleFilter<T extends ContactFiltersKeys>({
    key,
    filterValue,
    value,
  }: {
    key: T
    filterValue: FilterValue<T>
    value: boolean
  }): void {
    this.contactFiltersStore.setFilterByKey({
      key,
      filterValue,
      isChecked: value,
    })
  }

  handleClearAllFiltersOptions(): void {
    this.contactFiltersStore.clearAllFiltersOptions()
  }

  async handleClearAllAppliedFilters(): Promise<void> {
    this.contactFiltersStore.setAppliedFilters([])
    await this.loadContactsService.loadContacts({ page: START_PAGE })
  }
}
