import { HistoryService, RewardsInfoService } from '@nori/app-kit'
import { Action, Inject } from '@nori/di'
import { isErr } from '@nori/result'
import { createLogger } from '~/infra/create-logger'
import { LoadCartService } from '~/modules/cart/interface/services/create-cart.service'
import { ContactAdapter } from '~/modules/contacts/infra/contact.adapter'
import { NotesStore } from '~/modules/notes/interface/notes.store'
import { PartyService } from '~/modules/parties/interface/services/party.service'
import { Routes } from '~/modules/routes'
import { WishListService } from '~/modules/wishlist/interface/service/wish-list.service'

import { EnsureContactService } from '../services/ensure-contact.service'
import { RewardsCreditsService } from '../services/reward-credits-info.service'
import { ContactDetailsStore } from '../store/contact-details.store'

import type { ContactEntity } from '../../core'
import type { ContactTabsLabelValueObject } from '../../core/contact-tabs-label.value-object'

const logger = createLogger('contact-details.action')

@Action()
export class ContactDetailsAction {
  constructor(
    @Inject() private readonly contactAdapter: ContactAdapter,
    @Inject() private readonly contactDetailsStore: ContactDetailsStore,
    @Inject() private readonly notesStore: NotesStore,
    @Inject() private readonly historyService: HistoryService,
    @Inject() private readonly rewardsCreditsService: RewardsCreditsService,
    @Inject() private readonly loadCartService: LoadCartService,
    @Inject() private readonly wishListService: WishListService,
    @Inject() private readonly ensureContactService: EnsureContactService,
    @Inject() private readonly rewardsInfoService: RewardsInfoService
  ) {}

  async handleLoadFreeShipping(id: number): Promise<void> {
    this.contactDetailsStore.setIsLoadingFreeShipping(true)
    this.contactDetailsStore.setHasFreeShipping(false)

    const result = await this.rewardsInfoService.getFreeShipping(id)
    if (!isErr(result)) {
      this.contactDetailsStore.setHasFreeShipping(result.data)
    }

    this.contactDetailsStore.setIsLoadingFreeShipping(false)
  }

  async handleLoadRewards(): Promise<void> {
    this.contactDetailsStore.rewards.setIsLoading(true)
    await this.loadRewards()
    this.contactDetailsStore.rewards.setIsLoading(false)
  }

  private async loadRewards(): Promise<void> {
    const customerId = this.contactDetailsStore.contact?.customerId
    if (!customerId) return
    this.contactDetailsStore.rewards.setError(undefined)
    this.contactDetailsStore.rewards.setData(undefined)

    const result = await this.rewardsInfoService.fetchRewardsInfo(customerId)
    if (isErr(result)) {
      logger.error(result.error.key, result.error)
      this.contactDetailsStore.rewards.setError(result.error)
      return
    }

    this.contactDetailsStore.rewards.setData(result.data)
  }

  handleOpenEditContactPage(id: number): void {
    this.historyService.push(Routes.contacts.editContact.path(id))
  }

  async handleSetActiveTab(tab: ContactTabsLabelValueObject): Promise<void> {
    if (!this.contactDetailsStore.contact) return
    await this.historyService.push(
      Routes.contacts.contactDetails.path({
        id: this.contactDetailsStore.contact.id,
        tab,
      })
    )
  }

  private async loadRewardsAndCreditsInfo(): Promise<void> {
    const contact = this.contactDetailsStore.contact
    if (!contact) return
    if (!contact.customerId) return

    this.contactDetailsStore.setIsLoading(true)
    await this.rewardsCreditsService.loadRewardsAndCreditsInfo({
      customerId: contact.customerId,
      accessToken: contact.accessToken,
    })
    this.contactDetailsStore.setIsLoading(false)
  }

  async handleLoadContactDetails(id: string): Promise<void> {
    this.contactDetailsStore.setIsLoading(true)
    this.contactDetailsStore.setError(undefined)
    this.wishListService.dropWishListStore()

    await this.loadContactDetails(id)

    if (this.contactDetailsStore.isWishlistAvailable) {
      await this.handleLoadWishList()
    }

    if (!this.contactDetailsStore.isCustomer) {
      await this.handleSetActiveTab('info')
    }

    this.contactDetailsStore.setIsLoading(false)
  }

  async handleLoadWishList(page?: number): Promise<void> {
    this.contactDetailsStore.setIsWishlistLoading(true)
    await this.wishListService.loadWishList({
      customerCode: this.contactDetailsStore.contact?.urlCode,
      page,
    })
    this.contactDetailsStore.setIsWishlistLoading(false)
  }

  private async loadContactDetails(id: string): Promise<void> {
    const result = await this.contactAdapter.getContactById(id)
    if (isErr(result)) {
      logger.error(result.error.key, result.error)
      this.contactDetailsStore.setError(result.error)
      return
    }

    this.contactDetailsStore.setContact(result.data)

    this.notesStore.setNotes(result.data.id, result.data.notes)

    await this.loadRewardsAndCreditsInfo()
  }

  async handleNavigateToNewOrder(contact: ContactEntity): Promise<void> {
    const contactForCreateOrder = await this.checkContactForCreateOrder(contact)

    await this.loadCartService.createCart({
      ...contactForCreateOrder,
      typeCode: 'dsr_customer',
    })

    this.historyService.push(Routes.shop.path())
  }

  private async checkContactForCreateOrder(contact: ContactEntity) {
    if (contact.customerId) {
      return this.getSelectedContactFields({
        ...contact,
      })
    }

    const ensuredContact = await this.ensureContactService.ensureContact(
      contact.id
    )

    if (isErr(ensuredContact)) {
      return
    }

    return this.getSelectedContactFields({
      ...contact,
      customerId: ensuredContact.data.customerId,
    })
  }

  private getSelectedContactFields(contact: ContactEntity) {
    if (!contact) return {}

    return {
      contactId: contact.id,
      customerId: contact.customerId,
      customerEmail: contact.email,
      customerFirstName: contact.firstName,
      customerLastName: contact.lastName,
    }
  }
}
