import { observer } from '@nori/di'
import { useTranslations } from '@nori/lang-i18n'
import { Button } from '@nori/ui-kit'

import type { ProcessingItemValueObject } from '../core/value-object/processing-item.value-object'

type Props = {
  isAvailable: boolean
  skuId: number
  processingItemMap: ProcessingItemValueObject
  isAddingToCart?: boolean
  onAddItemToBag: (id: number) => void
}

export const WishListItemButton = observer(
  ({
    isAvailable,
    skuId,
    processingItemMap,
    isAddingToCart,
    onAddItemToBag,
  }: Props) => {
    const t = useTranslations('wishList')

    const buttonText = isAvailable ? t('addToBag') : t('outOfStock')

    if (processingItemMap[skuId]) {
      return (
        <Button isOutline disabled={true} widthType="full">
          <p className="text-base">Loading...</p>
        </Button>
      )
    }

    if (isAddingToCart) {
      return (
        <Button isOutline disabled={true} widthType="full">
          <p className="text-base">{buttonText}</p>
        </Button>
      )
    }

    if (isAvailable) {
      return (
        <Button
          disabled={isAddingToCart}
          onClick={() => onAddItemToBag(skuId)}
          widthType="full"
        >
          <p className="text-base">{buttonText}</p>
        </Button>
      )
    }

    return (
      <>
        <Button disabled={true} widthType="full">
          <p className="text-base">{buttonText}</p>
        </Button>
      </>
    )
  }
)
