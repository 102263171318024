// eslint-disable-next-line eslint-comments/no-restricted-disable
/* eslint-disable no-console */ function getArgs({ name , section , message , details  }) {
    const title = section ? `[${name} in ${section}] ${message}` : `[${name}] ${message}`;
    const args = [
        title,
        details
    ].filter((item)=>item !== undefined);
    return args;
}
export const createConsoleTransport = ()=>{
    return {
        name: 'console',
        log (params) {
            const args = getArgs(params);
            console.log(...args);
        },
        info (params) {
            const args = getArgs(params);
            console.info(...args);
        },
        error (params) {
            const args = getArgs(params);
            console.error(...args);
        },
        warn (params) {
            const args = getArgs(params);
            console.warn(...args);
        }
    };
} /* eslint-enable no-console */ ;
