import { HistoryService } from '@nori/app-kit'
import { Inject, Service } from '@nori/di'
import { Routes } from '~/modules/routes'

import { OrderWizardStore } from '../store/order-wizard.store'

@Service()
export class OrderWizardService {
  constructor(
    @Inject() private readonly orderWizardStore: OrderWizardStore,
    @Inject() private readonly historyService: HistoryService
  ) {}

  async initNewCustomerOrder(): Promise<void> {
    this.orderWizardStore.setSelectedContact(undefined)
    this.orderWizardStore.setSelectedParty(undefined)
    this.orderWizardStore.setSelectedHost(undefined)

    await this.historyService.push(Routes.orders.create.path())
  }
}
