import { createErrorClass } from './create-error-class';
export class ErrorBlock {
    constructor(namespace, errorRecord){
        this.errorRecord = errorRecord;
        const keys = Object.keys(errorRecord);
        // eslint-disable-next-line eslint-comments/no-restricted-disable
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        this.errors = keys.reduce((acc, key)=>{
            const message = errorRecord[key];
            const fullKey = `${namespace}.${String(key)}`;
            // eslint-disable-next-line eslint-comments/no-restricted-disable
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-expect-error
            acc[key] = createErrorClass(fullKey, message);
            return acc;
        // eslint-disable-next-line eslint-comments/no-restricted-disable
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        }, {});
    }
}
