import { observer } from '@nori/di'
import { Link } from '@nori/ui-kit'

import { usePennyContext } from '../../interface/use-penny.context'

import type { CartBannerMessageEntity } from '../../core/entity/cart-banner-message.entity'

type Props = {
  message: CartBannerMessageEntity
  'data-test-id'?: string
}

export const CartBannerItem = observer(
  ({ message, 'data-test-id': dataTestId }: Props) => {
    const { cartBannerAction } = usePennyContext()
    const link = message.link

    return (
      <div className="border-1 hidden w-full border border-solid border-primary-500 p-5 lg:max-w-[370px]">
        <div
          data-test-id={dataTestId}
          dangerouslySetInnerHTML={{ __html: message.body }}
          className="mb-2 text-sm font-normal text-gray-500"
        ></div>
        {link && (
          <Link
            weight="bold"
            onClick={() => cartBannerAction.handleLinkClick(link)}
          >
            {link.name}
          </Link>
        )}
      </div>
    )
  }
)
