import { hookContextFactory } from '@nori/di'

import { NextOrderAction } from './action/next-order.action'
import { NextOrderAddItemAction } from './action/next-order-add-item.action'
import { SubscriptionsAction } from './action/subscriptions.actions'
import { SubscriptionsAddProductAction } from './action/subscriptions-add-product.action'
import { NextOrderStore } from './store/next-order.store'
import { NextOrderAddItemStore } from './store/next-order-add-item.store'
import { SubscriptionsStore } from './store/subscriptions.store'
import { SubscriptionsAddProductStore } from './store/subscriptions-add-product.store'

export const { useModuleContext: useAutosaveContext } = hookContextFactory({
  nextOrderAction: NextOrderAction,
  nextOrderStore: NextOrderStore,

  nextOrderAddItemAction: NextOrderAddItemAction,
  nextOrderAddItemStore: NextOrderAddItemStore,

  subscriptionsAction: SubscriptionsAction,
  subscriptionsStore: SubscriptionsStore,

  subscriptionsAddProductAction: SubscriptionsAddProductAction,
  subscriptionsAddProductStore: SubscriptionsAddProductStore,
})
