import { useMemo } from 'react'

import { observer } from '@nori/di'
import { useTranslations } from '@nori/lang-i18n'
import { Button, Input, useClickOutside, useToggle } from '@nori/ui-kit'

import { escapeSpecialCharacters } from '../interface/escape-especial-characters'
import { useNotesContext } from '../interface/use-notes-context'

import { NOTE_CONTENT_MAX_CHARACTERS } from './note.constants'

type Props = {
  onSubmit: () => void
  contactId: number
  notesAmount: number
}

export const NoteInput = observer(
  ({ contactId, onSubmit, notesAmount }: Props) => {
    const { notesAction, notesStore } = useNotesContext()
    const { isOpen, handleOpen, handleClose } = useToggle()
    const { ref } = useClickOutside(handleClose)
    const t = useTranslations('contacts.notes.input')
    const inputValue = notesStore.contentMap[contactId] ?? ''
    const escapedInputValue = useMemo(
      () => escapeSpecialCharacters(inputValue),
      [inputValue]
    )

    return (
      <div ref={ref}>
        <div className="mb-3 flex items-center justify-between">
          <div className="ml-auto text-xs text-gray-500">
            {notesStore.contentMap[contactId]?.length ?? 0} /{' '}
            {NOTE_CONTENT_MAX_CHARACTERS}
          </div>
        </div>

        <Input
          maxLength={NOTE_CONTENT_MAX_CHARACTERS}
          placeholder={t('writeANote')}
          value={escapedInputValue}
          onChange={(value) =>
            notesAction.handleChangeContent(contactId, value)
          }
          onFocus={handleOpen}
        />

        {isOpen && (
          <div className="mb-2 flex items-center justify-end">
            <div
              className="mr-9 text-sm text-primary-900 underline"
              onClick={() => {
                notesAction.handleHideEditMode(contactId)
                handleClose()
              }}
            >
              {t('cancel')}
            </div>

            <Button
              disabled={!!notesStore.isLoadingMap[contactId]}
              isLoading={!!notesStore.isLoadingMap[contactId]}
              size="lg"
              onClick={onSubmit}
            >
              {t('save')}
            </Button>
          </div>
        )}
      </div>
    )
  }
)
