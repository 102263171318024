import { NoriDate } from '@nori/date'
import { DataState, Store } from '@nori/di'

import type { PartyEntity } from '../../core/entities/party.entity'
import type { PartiesListErrorInstance } from '../../core/errors/parties-list.errors'
import type { CalendarListValueObject } from '../../core/value-objects/calendar-list.value-object'

const DAYS_IN_WEEK = 7
const WEEKS = 6

const cellsMap: number[][] = Array(WEEKS).fill(Array(DAYS_IN_WEEK).fill(0))

@Store()
export class PartiesCalendarStore {
  calendarList = new DataState<
    CalendarListValueObject,
    PartiesListErrorInstance
  >()

  viewDate = new NoriDate()

  selectedDate?: NoriDate = undefined

  get weeksMap(): NoriDate[][] {
    return cellsMap.map((week, weekIndex) =>
      week.map((_, index) =>
        this.startDay.addDays(weekIndex * DAYS_IN_WEEK + index)
      )
    )
  }

  get startDay(): NoriDate {
    return this.viewDate.startOfMonth().startOfWeek()
  }

  get viewMonth(): string {
    return this.startDay.addDays(14).formatInTZ('MM')
  }

  setSelectedDate(date?: NoriDate): void {
    this.selectedDate = date
  }

  setViewDate(date: NoriDate): void {
    this.viewDate = date
  }

  getCalendarParties(date: NoriDate): PartyEntity[] | undefined {
    const formatDay = date.formatInTZ('yyyy-MM-dd')
    if (!this.calendarList.data?.[formatDay]) {
      return
    }
    return this.calendarList.data[formatDay]
  }
}
