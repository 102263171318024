var __defProp = Object.defineProperty;
var __name = (target, value) => __defProp(target, "name", { value, configurable: true });

// react-import.js
import React from "react";

// src/ui/google-autocomplete.tsx
import { useMemo, useRef, useState } from "react";
import { v4 } from "uuid";
import { Dropdown, DropdownContainer, Input, StyledInputHelperText, useToggle } from "@nori/ui-kit";

// src/infra/google-config.ts
var GOOGLE_API_KEY = "AIzaSyDp0ODMCQMYn0CWar58Pj2LHS0A_NAB7tM";

// src/infra/autocomplete/google-autocompletion.api.ts
var PLACE_TYPES = [
  "street_address",
  "subpremise",
  "route",
  "street_number",
  "post_box"
];
var fetchPlaceCompletions = /* @__PURE__ */ __name(async ({ input, sessionToken, region }) => {
  const body = {
    includedRegionCodes: [
      region
    ],
    includedPrimaryTypes: PLACE_TYPES,
    sessionToken,
    input
  };
  const response = await fetch("https://places.googleapis.com/v1/places:autocomplete", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "X-Goog-Api-Key": GOOGLE_API_KEY
    },
    body: JSON.stringify(body)
  });
  const { suggestions } = await response.json();
  if (!suggestions) {
    return [];
  }
  return suggestions.map((item) => item.placePrediction);
}, "fetchPlaceCompletions");

// src/infra/validation/google-validation.api.ts
var constructAddress = /* @__PURE__ */ __name((addressComponents = []) => {
  const address = {
    address1: {
      state: "complete",
      value: ""
    },
    city: {
      state: "complete",
      value: ""
    },
    state: {
      state: "complete",
      value: ""
    },
    zipCode: {
      state: "complete",
      value: ""
    }
  };
  addressComponents.forEach((component) => {
    var _a;
    if (!((_a = component.componentName) == null ? void 0 : _a.text)) {
      return;
    }
    const value = component.componentName.text;
    switch (component.componentType) {
      case "street_number": {
        address.address1.value = `${value} ${address.address1.value}`;
        break;
      }
      case "route": {
        address.address1.value += value;
        break;
      }
      case "postal_code": {
        address.zipCode.value = `${value}${address.zipCode.value}`;
        break;
      }
      case "postal_code_suffix": {
        address.zipCode.value = `${address.zipCode.value}-${value}`;
        break;
      }
      case "administrative_area_level_1": {
        address.state.value = value;
        break;
      }
      case "locality": {
        address.city.value = value;
        break;
      }
    }
  });
  return address;
}, "constructAddress");
var getAddressVerdict = /* @__PURE__ */ __name((verdict) => {
  if (!verdict) {
    return "failure";
  }
  const isInvalidAddress = verdict.validationGranularity === "OTHER";
  const isAddressCorrected = (verdict.hasInferredComponents || verdict.hasReplacedComponents) && verdict.addressComplete;
  if (isInvalidAddress) {
    return "failure";
  }
  if (isAddressCorrected) {
    return "needs-confirmation";
  }
  return "success";
}, "getAddressVerdict");
var validateAddress = /* @__PURE__ */ __name(async ({ addressLine, sessionToken, region = "us" }) => {
  const body = {
    sessionToken,
    address: {
      regionCode: region,
      addressLines: addressLine
    }
  };
  const response = await fetch(`https://addressvalidation.googleapis.com/v1:validateAddress?key=${GOOGLE_API_KEY}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify(body)
  });
  const { result } = await response.json();
  if (!result) {
    throw new Error("Result is empty");
  }
  const { address, verdict } = result;
  const validationStatus = getAddressVerdict(verdict);
  if (validationStatus === "failure") {
    return {
      status: validationStatus
    };
  }
  return {
    status: validationStatus,
    address: constructAddress(address == null ? void 0 : address.addressComponents)
  };
}, "validateAddress");

// src/ui/google-autocomplete.tsx
var MIN_SEARCH_QUERY_LENGTH = 3;
var GoogleAutocomplete = /* @__PURE__ */ __name(({ onValidationComplete, onChange, error, hideHelperBlock, labelMode, isDisabled, isDynamicHelperHeight, color, placeholder, label, value, helperText, maxLength, "data-test-id": dataTestId, country = "US", size = "default" }) => {
  const sessionId = useRef(v4());
  const [placeCompletions, setPlaceCompletions] = useState([]);
  const { isOpen, handleClose, handleOpen } = useToggle();
  const usedColor = useMemo(() => {
    switch (true) {
      case !!error:
        return "failure";
      case isDisabled:
        return "disabled";
      default:
        return color;
    }
  }, [
    isDisabled,
    error,
    color
  ]);
  const handleValidate = /* @__PURE__ */ __name(async (prediction) => {
    handleClose();
    onChange == null ? void 0 : onChange(prediction);
    try {
      const data = await validateAddress({
        addressLine: prediction,
        sessionToken: sessionId.current,
        region: country
      });
      onValidationComplete(data);
      sessionId.current = v4();
    } catch (e) {
    }
  }, "handleValidate");
  const handleChange = /* @__PURE__ */ __name(async (value2) => {
    handleOpen();
    onChange == null ? void 0 : onChange(value2);
    const query = value2.trim();
    if (!query.length) {
      setPlaceCompletions([]);
      return;
    }
    if (query.length < MIN_SEARCH_QUERY_LENGTH) {
      return;
    }
    try {
      const suggestions = await fetchPlaceCompletions({
        input: value2,
        sessionToken: sessionId.current,
        region: country
      });
      setPlaceCompletions(suggestions);
    } catch (e) {
      setPlaceCompletions([]);
    }
  }, "handleChange");
  return /* @__PURE__ */ React.createElement(DropdownContainer, {
    color: usedColor,
    placement: "right",
    isOpen,
    helperSlot: /* @__PURE__ */ React.createElement("div", {
      className: "mt-1 empty:hidden"
    }, /* @__PURE__ */ React.createElement(StyledInputHelperText, {
      error,
      isDynamicHelperHeight,
      helperText,
      hideHelperBlock
    })),
    onOutsideClick: handleClose,
    error,
    heightType: "lg",
    placeSlot: /* @__PURE__ */ React.createElement(Input, {
      "data-test-id": dataTestId,
      maxLength,
      type: "text",
      autoComplete: "off",
      hideHelperBlock: true,
      labelMode,
      value,
      label,
      size,
      error,
      placeholder,
      onChange: handleChange,
      disabled: isDisabled
    })
  }, placeCompletions.map((place) => /* @__PURE__ */ React.createElement(Dropdown.Option, {
    onClick: () => handleValidate(place.text.text),
    key: place.placeId
  }, place.text.text)));
}, "GoogleAutocomplete");
export {
  GoogleAutocomplete
};
