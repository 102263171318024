import type {
  CardTypeValueObject,
  CreditCardPaymentMethodEntity,
} from '@nori/app-kit'
import type { IconName } from '@nori/ui-kit'

type Props = {
  paymentMethod: CreditCardPaymentMethodEntity
}

const cardTypeMap = (cardType: CardTypeValueObject): IconName | undefined => {
  switch (cardType) {
    case 'visa':
      return 'VisaCard'
    case 'master-card':
      return 'MasterCard'
    case 'american-express':
      return 'AmericanExpressCard'
    case 'discover':
      return 'DiscoverCard'

    default:
      return undefined
  }
}

export function CreditCardDropdownItem({ paymentMethod }: Props) {
  return (
    <div className="flex gap-2">
      <p className="text-right text-sm font-normal">
        {cardTypeMap(paymentMethod.cardType)}
      </p>
      <p className="flex-no-wrap flex text-sm font-normal">
        **** **** **** {paymentMethod.lastDigits}
      </p>
    </div>
  )
}
