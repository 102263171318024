import { observer } from '@nori/di'
import { useTranslations } from '@nori/lang-i18n'
import { Divider, Icon, Swap } from '@nori/ui-kit'
import { EditHostessModal } from '~/modules/cart-contact-party/ui/edit-hostess-modal/edit-hostess-modal'

import { useCartContactPartyContext } from '../../interface/use-cart-contact-part.context'

export const Party = observer(() => {
  const t = useTranslations('contactAndParty')
  const { contactAndPartyAction, cartContactPartyStore } =
    useCartContactPartyContext()

  return (
    <div className="flex items-center overflow-hidden max-lg:mt-4 lg:max-w-[50%]">
      <div className="mr-5 flex h-10 w-10 min-w-[40px] items-center justify-center rounded-full bg-primary-900 text-white">
        <Icon name="CalendarOutline" />
      </div>

      <div className="flex flex-col items-start gap-1 overflow-hidden">
        <Swap
          is={!!cartContactPartyStore.partyOrHostessName}
          isSlot={
            <div className="flex w-full flex-col gap-1">
              <div
                data-test-id="party-or-hostes-selected-title"
                className="grid"
              >
                <span className="truncate text-sm font-bold text-primary-900">
                  {cartContactPartyStore.partyOrHostessName}
                </span>
              </div>

              <div className="flex items-center gap-2.5 text-sm font-normal text-primary-900">
                <div className="truncate">{cartContactPartyStore.hostName}</div>
                {cartContactPartyStore.partyDate && (
                  <>
                    <Divider direction="vertical" shade="200" />
                    <div className="flex">
                      <Icon name="CalendarOutline" />
                      <p className="ml-1">
                        {cartContactPartyStore.partyDate.formatInTZ('MM/dd')}
                      </p>
                    </div>
                  </>
                )}
              </div>
            </div>
          }
        >
          <span
            data-test-id="no-party-or-hostes-selected-title"
            className="text-primary-900"
          >
            {t('editPartyOrHostess.noEventSelected')}
          </span>
        </Swap>
        <p
          data-test-id="edit-party-or-hostes-button"
          className="flex cursor-pointer text-sm font-bold text-primary-900"
          onClick={contactAndPartyAction.handleOpenHostessModal}
        >
          <Icon name="PencilAlt" />
          <span className="ml-1">{t('edit')}</span>
        </p>
      </div>
      <EditHostessModal />
    </div>
  )
})
