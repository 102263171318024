import { Inject, Service } from '@nori/di'
import { isErr } from '@nori/result'
import { createLogger } from '~/infra/create-logger'
import { CartStore } from '~/modules/cart/interface/store/cart-store/cart.store'

import { PennyAdapter } from '../../infra/penny.adapter'
import { CartBannerStore } from '../store/cart-banner.store'

const logger = createLogger('load-cart-banner.service')

@Service()
export class CartBannerService {
  constructor(
    @Inject() private readonly cartStore: CartStore,
    @Inject() private readonly cartBannerStore: CartBannerStore,
    @Inject() private readonly pennyAdapter: PennyAdapter
  ) {}

  dropCartBanner(): void {
    this.cartBannerStore.handleDrop()
  }

  async loadCartBanner(): Promise<void> {
    const { cart } = this.cartStore
    if (!cart) {
      return
    }

    if (!this.cartBannerStore.cartBanner) {
      this.initialLoadCartBanner()
      return
    }

    const { id } = cart

    const result = await this.pennyAdapter.getCartBanner({
      cartId: id,
    })

    if (isErr(result)) {
      logger.error(result.error.key, result.error)
      return
    }

    this.cartBannerStore.setCartBanner(result.data)
  }

  private async initialLoadCartBanner(): Promise<void> {
    const { cart } = this.cartStore
    if (!cart) {
      return
    }
    this.cartBannerStore.setIsLoading(true)
    this.cartBannerStore.setCartBanner(undefined)
    this.cartBannerStore.setIsError(false)

    const { id } = cart

    const result = await this.pennyAdapter.getCartBanner({
      cartId: id,
    })

    if (isErr(result)) {
      logger.error(result.error.key, result.error)
      this.cartBannerStore.setIsError(true)
      return
    }

    this.cartBannerStore.setCartBanner(result.data)
    this.cartBannerStore.setIsLoading(false)
  }
}
